import { B2B_LOCALE_PREFERENCE_KEY } from "../constants";

/**
 * Get full language with country, if exists. Check local storage first since
 * some tenants (Nequi or Volaris) can select their language. If there's nothing in local
 * storage, check the navigator, or undefined
 */
export function getLangFromStorage(useNavigator = true): string | undefined {
  let fromStorage;
  try {
    fromStorage = localStorage?.getItem(B2B_LOCALE_PREFERENCE_KEY);
  } catch (e) {
    //console.warn("storage unavailable")
  }

  return fromStorage || (useNavigator && navigator?.language) || undefined;
}

/**
 * Get full language with country, if exists. Check local storage first since
 * some tenants (Nequi or Volaris) can select their language. If there's nothing in local
 * storage, check the navigator, then defaultLng, fallback to en
 */
export const getLang = (lang?: string, useNavigator = true): string => {
  const languageFromStorage = getLangFromStorage(useNavigator);

  return languageFromStorage || lang || "en";
};

export const setLang = (lang: string) => {
  localStorage.setItem(B2B_LOCALE_PREFERENCE_KEY, lang);
};

export const updateLang = () => {
  setLang(getLang());
};

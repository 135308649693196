import { assign } from "xstate";

import { getObjectKeysAsObject, setContextWithKey } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { SelectBaggage } from "./events";
import { FlightContext, FlightSelectors } from "@checkout/states";
import { Product } from "@b2bportal/purchase-api";
import { BaggageProduct } from "@checkout/states/Products/Flight/states/Baggage/context";
import { BaggageCharge } from "@b2bportal/air-booking-api";
import { FiatPrice } from "@b2bportal/air-shopping-api";
import { ExtraBags } from "@b2bportal/air-baggage-api";

export const actions = {
  setCheckedBagsPrices: assign((context: FlightContext, _event) => {
    const flightProduct = context[
      ParentState.cartQuote
    ].quoteBreakdown?.products.find((p) => p.product.type === Product.Flight);
    const extraBaggage =
      flightProduct?.product?.value?.pricingSummary?.extraBaggage;
    const chargedCheckedBags = extraBaggage?.checkedBag?.charges;
    const checkedBagsPrices =
      chargedCheckedBags
        ?.sort(
          (a: BaggageCharge, b: BaggageCharge) => a.firstPiece - b.firstPiece
        )
        .flatMap((charge: BaggageCharge) => {
          const bagsCharges: Omit<FiatPrice, "currencySymbol">[] = [];
          for (let i = charge.firstPiece; i <= charge.lastPiece; i++) {
            bagsCharges.push({
              currencyCode: charge.currency,
              value: charge.amount,
            });
          }
          return bagsCharges;
        }) ?? [];
    setContextWithKey(
      context,
      `${ParentState.bagsSelection}.checkedBagsPrices`,
      checkedBagsPrices
    );
    return context;
  }),

  selectBaggage: assign(
    (
      context: FlightContext,
      { passengerId, numCheckedBags }: SelectBaggage
    ) => {
      const passengerBagsMap: Map<string, ExtraBags> =
        context[ParentState.bagsSelection].passengerBagsMap;

      // Does not support extra carryOnBags or personalItems at the moment.
      passengerBagsMap.set(passengerId, {
        checkedBags: numCheckedBags,
        carryOnBags: 0,
        personalItems: 0,
      });

      const shoppedTripId = FlightSelectors.getQuotedShoppedTripId({
        context,
      });
      const selectedFareId = FlightSelectors.getSelectedFareId({
        context,
      });
      const productsToAdd = context[ParentState.cartUpdate].addProducts;
      const filterOutBagsFromProducts =
        productsToAdd?.filter((p) => p.type !== Product.AirBaggage) || [];
      const newBagProduct: BaggageProduct = {
        type: Product.AirBaggage,
        value: {
          tripId: shoppedTripId ?? "",
          fareId: selectedFareId,
          selection: {
            ExtraBagSelection: "PerPassengerExtraBagSelection",
            bagsPerPassenger: Object.fromEntries(passengerBagsMap),
          },
        },
      };

      filterOutBagsFromProducts.push(newBagProduct);
      context[ParentState.cartUpdate].addProducts = filterOutBagsFromProducts;
      return context;
    }
  ),

  clearBaggageError: assign((context: FlightContext, _event) =>
    setContextWithKey(context, `${ParentState.bagsSelection}.error`, undefined)
  ),

  initSelectedBaggage: assign((context: FlightContext, _event) => {
    if (context[ParentState.bagsSelection].passengerBagsMap == null) {
      setContextWithKey(context, ParentState.bagsSelection, {
        ...context[ParentState.bagsSelection],
        passengerBagsMap: new Map(),
      });
    }
    return context;
  }),

  resetSelectedBaggage: assign((context: FlightContext, _event) => {
    setContextWithKey(context, ParentState.bagsSelection, {
      ...context[ParentState.bagsSelection],
      passengerBagsMap: new Map(),
    });
    return context;
  }),

  resetEditBaggageFromReview: assign((context: FlightContext, _event) =>
    setContextWithKey(
      context,
      `${ParentState.review}.editBaggageFromReview`,
      false
    )
  ),

  setBaggageVisited: assign((context: FlightContext, _event) =>
    setContextWithKey(context, `${ParentState.bagsSelection}.visited`, true)
  ),

  setSkipBaggageSelection: assign((context: FlightContext, _event) =>
    setContextWithKey(
      context,
      `${ParentState.seatSelection}.skipBaggageSelection`,
      true
    )
  ),
};

export const ActionTypes = getObjectKeysAsObject(actions);

import {
  priceFreezeApi,
  PriceFreezeOfferDataRequest,
  PriceFreezeOfferDataResponse,
} from "@b2bportal/price-freeze-api";
import axios from "axios";
import dayjs from "dayjs";

import { getTripId, getFareId, getDepartureDate } from "../../selectors";
import { AirPriceFreezeContext } from "../../context";

export const fetchOfferData = (
  context: AirPriceFreezeContext,
  _event: unknown
) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const tripId = getTripId({ context });
    const fareId = getFareId({ context });
    const departureDate0 = getDepartureDate({ context });
    if (departureDate0) {
      const departureDate = dayjs(departureDate0).format("YYYY-MM-DD");
      const req: PriceFreezeOfferDataRequest = {
        tripId,
        fareId,
        departureDate,
      };

      try {
        const res = await priceFreezeApi(
          axios
        ).apiV0PricefreezeOfferCustomDataPost(req);
        resolve(res.data as PriceFreezeOfferDataResponse);
      } catch (e) {
        reject(e);
      }
    } else {
      reject("Missing departure date");
    }
  });

import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import Restriction, { IRestrictionProps } from "./Restriction";
import { useI18nContext } from "@hopper-b2b/i18n";
import { isDescriptionShort } from "./SelectedFareRestrictions";

interface IAmenityProps {
  restriction: IRestrictionProps;
  index?: number;
  isLast?: boolean;
  isMobile?: boolean;
  count?: number;
  onSeeMoreOnClick: () => void;
}

const Amenity = ({
  restriction,
  index,
  isLast,
  isMobile,
  count,
  onSeeMoreOnClick,
}: IAmenityProps) => {
  const { t } = useI18nContext();
  return isLast ? (
    <Box
      className={clsx("last-item", {
        long: !isDescriptionShort(restriction.description, !!isMobile),
      })}
      key={restriction.description}
    >
      <Restriction
        symbol={restriction.symbol}
        description={restriction.description}
      />
      <Typography
        aria-owns={
          index && `trip-fare-details-${index}`
            ? "mouse-over-popover"
            : undefined
        }
        aria-haspopup="true"
        id={`trip-fare-details-${index}`}
        className="show-more"
        variant="h6"
        onClick={onSeeMoreOnClick}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSeeMoreOnClick();
          }
        }}
        tabIndex={0}
      >
        {t("viewAllAmenities", { count })}
      </Typography>
    </Box>
  ) : (
    <Restriction
      key={restriction.description}
      symbol={restriction.symbol}
      description={restriction.description}
    />
  );
};

export default Amenity;

import { Product, TrackingProperties } from "@b2bportal/purchase-api";
import { ParentState } from "../../../types";

import {
  DisruptionQuoteRequestV1,
  DisruptionUpdateStateTypeV1,
  DisruptionUpdateStateWithoutValueV1,
} from "./types";
import { FlightSelectors } from "../Flight";
import { DisruptionOfferQuoteSelected } from "@b2bportal/core-types";
import { cartQuoteSelectors } from "@checkout/index";
import { DisruptionProductType } from "@hopper-b2b/types";
import { DisruptionQuoteData } from "@b2bportal/air-disruption-api";

export const getDisruptionCartUpdateParams = (
  state: DisruptionUpdateStateWithoutValueV1
): DisruptionQuoteRequestV1 => {
  const selectedTrip = FlightSelectors.getSelectedTripParent(state);
  const tripId = selectedTrip.tripId ?? "";
  const fareId = selectedTrip.returnFareId || selectedTrip.outgoingFareId || "";
  const quoteIds = [getDisruptionQuoteId(state)];
  return {
    quoteIds,
    tripId,
    fareId,
  };
};

export const getDisruptionQuoteId = (
  state: DisruptionUpdateStateTypeV1 | DisruptionUpdateStateWithoutValueV1
): string =>
  state.context[ParentState.disruptionV1].selectedOfferQuote.quote.quoteId;

export const getPurchasedDisruptionProducts = (
  state: DisruptionUpdateStateTypeV1 | DisruptionUpdateStateWithoutValueV1
) => {
  const products = state.context[ParentState.cartFulfill].fulfilledProducts;
  const purchasedDisruptionProducts = products?.find(
    (p) => p.type === Product.AirDisruption
  );
  const purchasedDisruptionContractIds =
    purchasedDisruptionProducts?.value?.contractIds;
  if (purchasedDisruptionContractIds == null) {
    return null;
  }
  const cartQuoteDisruptionProducts =
    state.context[ParentState.cartQuote]?.quoteBreakdown?.products;
  const quotedDisruptionProductTypes = cartQuoteDisruptionProducts?.find(
    (product) => product?.product?.type === Product.AirDisruption
  )?.product?.value?.quotes;
  const purchasedDisruptionProductTypes = quotedDisruptionProductTypes?.filter(
    (product) =>
      purchasedDisruptionContractIds.includes(product.value?.quotes[0].quoteId)
  );

  return purchasedDisruptionProductTypes || null;
};

export const getSelectedDisruptionOfferQuote = (
  state: DisruptionUpdateStateTypeV1 | DisruptionUpdateStateWithoutValueV1
): DisruptionOfferQuoteSelected =>
  state.context[ParentState.disruptionV1].selectedOfferQuote;

export const getCompleteBuyAirDisruptionTrackingProperties = (
  state: DisruptionUpdateStateTypeV1 | DisruptionUpdateStateWithoutValueV1
): TrackingProperties => {
  // Quotes offered to users during the flight shopping flow
  const providedOfferQuotes =
    state.context[ParentState.disruptionV1]?.providedOfferQuotes ?? [];

  if (!providedOfferQuotes.length) {
    return { properties: {} };
  }

  const quotedProducts = cartQuoteSelectors.getQuoteBreakdown(state)?.products;
  const quotedDisruptionProduct = quotedProducts.find(
    (p) => p.product.type === Product.AirDisruption
  )?.product.value as DisruptionQuoteData;

  const trackingProperties =
    state.context[ParentState.disruptionV1].providedOfferQuotes[0]
      ?.trackingProperties ?? {};

  // Check if a quote of the given product type is offered, and if so, if the offered quote is selected/attached
  const getProtectionChoice = (
    type: DisruptionProductType
  ): 0 | 1 | undefined => {
    if (providedOfferQuotes.map((quote) => quote.productType).includes(type)) {
      if (
        quotedDisruptionProduct?.quotes.find(
          (quote) => quote.productType === type
        )
      ) {
        // offered + attached
        return 1;
      } else {
        // offered + not attached
        return 0;
      }
    }
    // not offered
    return undefined;
  };

  return {
    properties: {
      ...(trackingProperties.properties ?? {}),
      disruption_protection_choice: getProtectionChoice(
        DisruptionProductType.AirDisruptionProtection
      ),
    },
    encryptedProperties: trackingProperties.encryptedProperties,
  };
};

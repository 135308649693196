import {
  GetPriceFreezeRequestEnum,
  GetPriceFreezeResponseV2,
  priceFreezeApi,
} from "@b2bportal/price-freeze-api";
import axios, { AxiosResponse } from "axios";
import { getPriceFreezeId } from "../selectors";
import { ParentContextWithPF } from "../types";

export const fetchPriceFreezeService = (
  context: ParentContextWithPF,
  _event: unknown
) =>
  new Promise((resolve) => {
    const priceFreezeID = getPriceFreezeId({ context });

    if (priceFreezeID === undefined) {
      resolve(null);
    }

    priceFreezeApi(axios)
      .apiV2PricefreezeGetPost({
        id: priceFreezeID,
        GetPriceFreezeRequest: GetPriceFreezeRequestEnum.ByPriceFreezeId,
      })
      .then((result: AxiosResponse<GetPriceFreezeResponseV2>) => {
        resolve(result.data);
      })
      .catch((e) => {
        resolve(null);
      });
  });

import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { PartialParentContextWithAirCfarV1 } from "./types";

export const guards = {
  hasCfarIdAndEnabled: (
    ctx: PartialParentContextWithAirCfarV1,
    _event: unknown
  ) => ctx[ParentState.cancelForAnyReasonV1]?.selectedOffer != null,
};

export const GuardTypes = getObjectKeysAsObject(guards);

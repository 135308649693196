import { CreateSessionResponseEnum } from "@b2bportal/auth-api";
import { getLang } from "@hopper-b2b/i18n";
import { useCallback } from "react";
import { getRefreshToken } from "../api";
import { useAuthProvider } from "../context";
import {
  createAnonymousSession,
  fetchSessionAndSetSession,
  refreshSessionFromToken,
} from "./session";
import { useLogin } from "./useLogin";

export type UseRefreshResultType = {
  refreshToken: string;
  refreshFromToken: () => void;
};

export const useRefreshToken = (): UseRefreshResultType => {
  const { state } = useAuthProvider();
  const lang = getLang();

  const onLogin = useLogin();

  const userInfo = state?.sessionInfo?.userInfo;

  const refreshToken = getRefreshToken();

  // Check if a Refresh Token exists and load session
  const refreshFromToken = useCallback(() => {
    if (userInfo) return;

    try {
      if (refreshToken) {
        refreshSessionFromToken(refreshToken, onLogin, async () => {
          const res = await createAnonymousSession(lang);

          if (
            res.CreateSessionResponse ===
            CreateSessionResponseEnum.CreateSessionSuccess
          ) {
            fetchSessionAndSetSession(onLogin);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [onLogin]);

  return { refreshToken, refreshFromToken };
};

import { getEnvVariables, useUberBridge } from "@hopper-b2b/utilities";
import { AppBar, Box, Toolbar } from "@material-ui/core";
import clsx from "clsx";
import { useEffect } from "react";
import "./styles.scss";

export interface IHeaderProps {
  left?: JSX.Element;
  center?: JSX.Element;
  right?: JSX.Element;
  fullWidth?: boolean;
  isMobile?: boolean;
  shadow?: boolean;
  className?: string;
  toolbarClassName?: string;
  title?: string;
}

const Header = (props: IHeaderProps) => {
  const { setHeader } = useUberBridge();
  const {
    left,
    center,
    right,
    fullWidth,
    isMobile,
    shadow,
    className,
    toolbarClassName,
    title,
  } = props;

  useEffect(() => {
    if (title && getEnvVariables("clientName") === "uber") {
      setHeader({
        title,
      });
    }
  }, [setHeader, title]);

  return (
    <Box
      className={clsx(
        "app-header",
        { "full-width": fullWidth },
        { shadow: shadow },
        { mobile: isMobile },
        className
      )}
    >
      <AppBar className="app-bar" position="static">
        <Toolbar className={clsx("toolbar", toolbarClassName)}>
          <Box className="left-section">{left}</Box>
          <Box className="center-section">{center}</Box>
          <Box className="right-section">{right}</Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
export default Header;

import { MouseEvent, useCallback } from "react";
import { getRewardsString } from "@hopper-b2b/utilities";
import { Box, Button, Typography } from "@material-ui/core";
import {
  AlgomerchTag,
  FareDetails,
  FareScore,
  FlightTags,
  getTags,
  mapI18nAlgomerchText,
} from "@hopper-b2b/types";
import clsx from "clsx";
import { FlightsFareSlice } from "@b2bportal/air-shopping-api";
import { useI18nContext, useI18nCurrency } from "@hopper-b2b/i18n";
import { Slot, Tag } from "../../../index";

interface IFareDetailsItemProps {
  fare: FareDetails;
  index: number;
  className?: string;
  isOutgoing: boolean;
  inExchange?: boolean;
  isMobile?: boolean;
  rewardsKey?: string;
  selectedFareDetails?: FareDetails;
  getFlightShopFareSlice: (fareId: string) => FlightsFareSlice;
  onAlgomerchClick?: (label: string) => void;
  onFareClicked: (
    e: Event | MouseEvent,
    fare: FareDetails,
    index: number
  ) => void;
}

const FareDetailsItem = ({
  fare,
  index,
  className,
  isOutgoing,
  rewardsKey,
  inExchange,
  isMobile,
  selectedFareDetails,
  getFlightShopFareSlice,
  onAlgomerchClick,
  onFareClicked,
}: IFareDetailsItemProps) => {
  const { t } = useI18nContext();
  const { formatFiatCurrency } = useI18nCurrency();
  const fareIndex = fare.slices.findIndex((slice) =>
    isOutgoing ? slice.outgoing : !slice.outgoing
  );
  const fareSlice = fare.slices[fareIndex];

  const shopFareSlice = getFlightShopFareSlice(fare.id);

  const getTagText = (fareScore: FareScore | FlightTags) => {
    const tagLimit = 2;
    const tagSeperator = ", ";
    const tags = getTags(fareScore);
    const filteredTags = tags
      .filter((tag: AlgomerchTag) => tag !== AlgomerchTag.Fastest)
      .map((tag: AlgomerchTag) => t(mapI18nAlgomerchText[tag]))
      .slice(0, tagLimit);
    return filteredTags.join(tagSeperator);
  };
  const tagText = shopFareSlice ? getTagText(shopFareSlice.tags) : "";
  const tagLength =
    shopFareSlice && shopFareSlice.tags
      ? getTagText(shopFareSlice.tags).split(",").length
      : 1;

  const handleFareSelect = useCallback(
    (e: Event | MouseEvent) => {
      onFareClicked(e, fare, index);
    },
    [fare, index, onFareClicked]
  );

  const {
    baseAmount: {
      fiat: { currencyCode: baseCurr },
    },
    total,
  } = fare.paxPricings[0].pricing;
  let totalPriceString = formatFiatCurrency({
    currencyCode: baseCurr,
    value: 0,
  });
  let totalRewardsString = "";

  if (total) {
    const {
      fiat: { currencyCode, currencySymbol, value },
      rewards,
    } = total;
    const totalReward = rewards[rewardsKey];

    totalPriceString = formatFiatCurrency(
      { currencyCode, value },
      { maximumFractionDigits: 0, minimumFractionDigits: 0 }
    );
    totalRewardsString = totalReward
      ? getRewardsString({
          currency: totalReward.currency,
          value: totalReward.value,
        })
      : "";

    if (inExchange) {
      // exchange shop returns prices as deltas from original flight
      if (value >= 0) totalPriceString = `+${totalPriceString}`;
      else
        totalPriceString = t("exchangeable.shop.minDelta", {
          currencySymbol,
        });
    }
  }

  return (
    <Box
      key={`trip-fare-details-${index}`}
      className={clsx(
        `trip-fare-details-${index}`,
        "trip-fare-details",
        "mini"
      )}
    >
      <Box className="fare-details-fare-class-container">
        <Button
          className={clsx(
            selectedFareDetails?.id === fare.id
              ? "selected-fare-button"
              : "select-fare-button",
            "mini",
            className
          )}
          onClick={handleFareSelect}
          variant="contained"
        >
          <span className="fare-class-wrapper">
            <div
              className={clsx(
                "fare-radio-button",
                selectedFareDetails?.id === fare.id ? "checked" : "unchecked"
              )}
            ></div>
            <div className="fare-class">
              {`${fareSlice.fareShelf?.shortBrandName}`}
            </div>
          </span>
          <Box
            className={clsx("fare-details-title", {
              "with-tag": tagText,
              "two-tags": tagLength === 2,
              mobile: isMobile,
            })}
          >
            <Typography variant="body1" className="fare-price">
              <strong>{totalPriceString}</strong>
              {totalRewardsString && (
                <>
                  <Typography variant="inherit" className="separator">
                    /
                  </Typography>
                  <Typography variant="inherit" className="fare-reward">
                    {totalRewardsString}
                  </Typography>
                </>
              )}
              <Slot id="fare-details-card-policy" fareId={fare.id} inline />
            </Typography>
            {tagText && !isMobile ? (
              <Box className="fare-details-badge">
                <Tag
                  className={clsx("fare-tag", className)}
                  label={tagText}
                  onClick={() => onAlgomerchClick && onAlgomerchClick(tagText)}
                />
              </Box>
            ) : null}
          </Box>
        </Button>
      </Box>
    </Box>
  );
};

export default FareDetailsItem;

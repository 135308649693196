import { assign } from "xstate";
import { getObjectKeysAsObject } from "../../../helpers";
import { PartialParentWithContact } from "./context";
import {
  SetContactInfoEmailEvent,
  SetContactInfoEvent,
  SetContactInfoPhoneNumberEvent,
} from "./events";

export const actions = {
  setContactVisited: assign((ctx: PartialParentWithContact, _event) => {
    ctx.contactInformation.visited = true;
    return ctx;
  }),
  setContactInfo: assign(
    (ctx: PartialParentWithContact, event: SetContactInfoEvent) => {
      ctx.contactInformation.contactInfo = event.contactInfo;
      return ctx;
    }
  ),
  setContactInfoPhoneNumber: assign(
    (ctx: PartialParentWithContact, event: SetContactInfoPhoneNumberEvent) => {
      ctx.contactInformation.contactInfo.countryCode = event.data.countryCode;
      ctx.contactInformation.contactInfo.phoneNumber = event.data.phoneNumber;
      return ctx;
    }
  ),
  setContactInfoEmail: assign(
    (ctx: PartialParentWithContact, event: SetContactInfoEmailEvent) => {
      ctx.contactInformation.contactInfo.email = event.data.email;
      return ctx;
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject(actions);

import { PropsWithChildren, createContext } from "react";

export enum FeatureFlags {
  AIR,
  LODGING,
  CALENDAR_PREDICTION,
  ROOMS,
}

export type FeatureFlagsType = Record<FeatureFlags, boolean>;

export const defaultContext = {
  [FeatureFlags.AIR]: false,
  [FeatureFlags.LODGING]: false,
  [FeatureFlags.CALENDAR_PREDICTION]: false,
  [FeatureFlags.ROOMS]: false,
};

export const FeatureFlagsContext =
  createContext<FeatureFlagsType>(defaultContext);

export type FeatureFlagsProviderProps = {
  flags: FeatureFlagsType;
};

export const FeatureFlagsProvider = ({
  flags,
  children,
}: FeatureFlagsProviderProps & PropsWithChildren) => (
  <FeatureFlagsContext.Provider value={flags} children={children} />
);

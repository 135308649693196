import {
  CipherText,
  QuoteBreakdownResponse,
  QuoteState,
} from "@b2bportal/purchase-api";

import { cartQuoteSelectors } from "../../CartQuote";
import { CartUpdateErrorModal } from "../../../../types";
import { getQuoteBreakdown } from "@hopper-b2b/api";
import { CartContext } from "../../context";

export const getQuoteBreakdownService = (
  context: CartContext,
  _event: unknown
) =>
  new Promise((resolve, reject) => {
    const cipherText = cartQuoteSelectors.getPriceQuoteCipherText({ context });

    if (!cipherText) {
      reject({ type: [CartUpdateErrorModal.NoCipherText] });
    }

    getQuoteBreakdown(cipherText as CipherText)
      .then((result: QuoteBreakdownResponse) => {
        const cartState = result.quoteBreakdown.state;
        if (cartState === QuoteState.Unavailable) {
          reject({
            type: [CartUpdateErrorModal.UnavailableCartState],
          });
        } else {
          resolve(result);
        }
      })
      .catch((e) => {
        reject({
          type: [CartUpdateErrorModal.Unknown],
        });
      });
  });

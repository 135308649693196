import {
  LogsEvent,
  LogsInitConfiguration,
  datadogLogs,
} from "@datadog/browser-logs";
import { CLIENT_TOKEN, DATADOG_SITE } from "./constants";

export type InitializeLogsProps = Partial<LogsInitConfiguration> & {
  service: string;
  sessionId?: string;
};

export const initializeLogs = ({
  sessionId,
  service,
  ...rest
}: InitializeLogsProps) => {
  datadogLogs.init({
    clientToken: CLIENT_TOKEN,
    site: DATADOG_SITE,
    service,
    sessionSampleRate: 100,
    forwardErrorsToLogs: true,
    trackSessionAcrossSubdomains: true,
    beforeSend: (log: LogsEvent) => {
      log.browser_session_id = sessionId;
      log.path = window.location.pathname;

      return true;
    },
    ...rest,
  });
};

import { ParentState } from "@checkout/types";
import { PassengerTypes } from "@hopper-b2b/types";
import { State } from "xstate";
import { ParentContextWithPF } from "./types";
import { FlightSelectors } from "@checkout/states/Products";
import { Person } from "@b2bportal/air-booking-api";
import {
  PriceFreezeOfferData,
  TrackingProperties,
} from "@b2bportal/price-freeze-api";

type StateType = State<ParentContextWithPF>;
type StateWithoutValue = Pick<StateType, "context">;

export const getPriceFreezeId = (state: StateWithoutValue) =>
  state.context[ParentState.priceFreeze].priceFreezeId;

export const getPriceFreezePassengers = (state: StateWithoutValue) => {
  const paxList =
    state.context[ParentState.priceFreeze].priceFreezeView?.frozenFare
      .paxPricings;

  if (!paxList) return 0;

  return paxList.reduce((numberOfPassengers, pax) => {
    return numberOfPassengers + pax.quantity;
  }, 0);
};

export const getPriceFreezeTotalAmount = (state: StateWithoutValue) =>
  state.context[ParentState.priceFreeze].priceFreezeView?.priceFreeze.offer
    .totalAmount;

export const getPriceFreezePerPaxSavingAmount = (state: StateWithoutValue) => {
  const adultPricing = state.context[
    ParentState.priceFreeze
  ].priceFreezeView?.frozenFare.paxPricings.find(
    (p) => p.paxType === PassengerTypes.Adult
  );

  const isSavingsZero = adultPricing?.pricing.savingsAmount.fiat.value === 0;
  return isSavingsZero ? undefined : adultPricing?.pricing.savingsAmount;
};

interface PersonWithPassengerType extends Person {
  passengerType: PassengerTypes;
}

export const getPriceFreezeRedirectUrl = (state: StateWithoutValue) => {
  const purchaseParams =
    state.context[ParentState.priceFreeze].priceFreezePurchaseParams;
  const urlPrefix = purchaseParams?.urlPrefix;

  const selectedTripId = FlightSelectors.getSelectedTripId(state);
  const selectedFareId = FlightSelectors.getSelectedFareId(state);

  const selectedPassengersWithTypes =
    FlightSelectors.mergePassengersAndBreakdownPersons(state);

  const paxCounts = selectedPassengersWithTypes.reduce(
    (counts, person) => {
      const passengerType = (person as PersonWithPassengerType).passengerType;
      if (!passengerType) return counts;
      switch (passengerType) {
        case PassengerTypes.Adult:
          return { ...counts, adultsCount: counts.adultsCount + 1 };
        case PassengerTypes.InfantInLap:
          return { ...counts, infantsOnLapCount: counts.infantsOnLapCount + 1 };
        case PassengerTypes.InfantInSeat:
          return {
            ...counts,
            infantsInSeatCount: counts.infantsInSeatCount + 1,
          };
        default:
          return { ...counts, childrenCount: counts.childrenCount + 1 };
      }
    },
    {
      adultsCount: 0,
      childrenCount: 0,
      infantsInSeatCount: 0,
      infantsOnLapCount: 0,
    } as {
      adultsCount: number;
      childrenCount: number;
      infantsInSeatCount: number;
      infantsOnLapCount: number;
    }
  );

  if (!selectedTripId || !selectedFareId) return "";

  const urlParams: Record<string, string> = {
    tripId: selectedTripId,
    fareId: selectedFareId,
  };

  if (paxCounts?.adultsCount) {
    urlParams.adultsCount = `${paxCounts.adultsCount}`;
  }
  if (paxCounts?.childrenCount) {
    urlParams.childrenCount = `${paxCounts.childrenCount}`;
  }
  if (paxCounts?.infantsInSeatCount) {
    urlParams.infantsInSeatCount = `${paxCounts.infantsInSeatCount}`;
  }
  if (paxCounts?.infantsOnLapCount) {
    urlParams.infantsOnLapCount = `${paxCounts.infantsOnLapCount}`;
  }

  const stringifiedParams = new URLSearchParams(urlParams).toString();
  return `${urlPrefix}?${stringifiedParams}`;
};

export const getCheapestPriceFreezeOfferData = (
  state: StateWithoutValue
): PriceFreezeOfferData | undefined => {
  return state.context[ParentState.priceFreeze].cheapestPriceFreezeOfferData;
};

export const getCompleteBuyAirPriceFreezeProperties = (
  state: StateWithoutValue
): TrackingProperties => {
  return state.context[ParentState.priceFreeze].priceFreezeView
    ?.trackingProperties;
};

import { assign } from "xstate";
import { getObjectKeysAsObject, setContextWithKey } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { PartialParentContextWithReview } from "./context";

export const actions = {
  setReviewVisited: assign((ctx: PartialParentContextWithReview, _event) =>
    setContextWithKey(ctx, `${ParentState.review}.visited`, true)
  ),
  setEditSeatsFromReview: assign(
    (ctx: PartialParentContextWithReview, _event) =>
      setContextWithKey(ctx, `${ParentState.review}.editSeatsFromReview`, true)
  ),
};

export const ActionTypes = getObjectKeysAsObject(actions);

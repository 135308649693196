import {
  Gender,
  FrequentFlyerMap,
  AirlineCode,
  FrequentFlyerNumber,
  HotelLoyaltyMap,
} from "@hopper-b2b/types";
import { IOption, StyledMenuItem } from "@hopper-b2b/ui";
import { hotelLoyaltyProgramList } from "./components/HotelLoyaltySection/hotelLoyaltyProgramList";

export interface IFrequentFlyer {
  key: AirlineCode;
  value: FrequentFlyerNumber;
  trackingId: number;
}

export type IFrequentFlyerList = IFrequentFlyer[];

export const getFrequentFlyerList = (
  frequentFlyerMap: FrequentFlyerMap
): IFrequentFlyerList => {
  return Object.entries(frequentFlyerMap).map((frequentFlyer, index) => {
    return {
      key: frequentFlyer[0] as AirlineCode,
      value: frequentFlyer[1].value as FrequentFlyerNumber,
      trackingId: index,
    };
  });
};

export const getFrequentFlyerMap = (
  frequentFlyerList: IFrequentFlyerList
): FrequentFlyerMap => {
  const frequentFlyerMap: FrequentFlyerMap = {};
  for (const frequentFlyer of frequentFlyerList) {
    frequentFlyerMap[frequentFlyer.key] = {
      value: frequentFlyer.value,
    };
  }

  return frequentFlyerMap;
};

export interface IHotelLoyalty {
  key: string;
  value: string;
  trackingId: number;
}

export type IHotelLoyaltyList = IHotelLoyalty[];

export const getHotelLoyaltyList = (
  hotelLoyaltyMap: HotelLoyaltyMap
): IHotelLoyaltyList =>
  Object.entries(hotelLoyaltyMap).map((hotelLoyalty, index) => ({
    key: hotelLoyalty[0],
    value: hotelLoyalty[1].value,
    trackingId: index,
  }));

export const getHotelLoyaltyMap = (
  hotelLoyaltyList: IHotelLoyaltyList
): HotelLoyaltyMap => {
  const hotelLoyaltyMap: HotelLoyaltyMap = {};
  hotelLoyaltyList.forEach((hotelLoyalty) => {
    hotelLoyaltyMap[hotelLoyalty.key] = {
      value: hotelLoyalty.value,
    };
  });

  return hotelLoyaltyMap;
};

export const formattedHotelLoyaltyOptions = hotelLoyaltyProgramList.map(
  (option) => ({
    label: <p className="frequent-flyer-option">{option.loyaltyProgramName}</p>,
    ...option,
  })
);

export const getNextTrackingId = (
  list: IFrequentFlyerList | IHotelLoyaltyList
): number => {
  return list.length > 0
    ? Math.max(...list.map((item) => item.trackingId)) + 1
    : 0;
};

export const renderMenu = (options: IOption[]): JSX.Element[] =>
  options.map(({ label, value }, index) => (
    <StyledMenuItem key={`${value}-${index}`} value={value}>
      {label}
    </StyledMenuItem>
  ));

export const genderOptions: IOption[] = [
  { label: "Male", value: Gender.M },
  { label: "Female", value: Gender.F },
];

export interface IFlyerOption {
  program: string;
  value: string;
  label?: JSX.Element;
}

// TODO: find out how to generate all flyer codes (2-character airline codes) and full names;
// also, it has to filter out codes in frequentFlyerMap
// List pulled from Hopper App https://github.com/hopper-org/Newark/blob/master/newark-mobile-api/src/main/scala/com/hopper/newark/mobileapi/v2/FrequentFlyerController.scala#L30
export const flyerOptions: IFlyerOption[] = [
  { program: "Aegean Airlines Miles+Bonus", value: "A3" },
  { program: "Aer Lingus AerClub", value: "EI" },
  { program: "Aerolíneas Plus", value: "AR" },
  { program: "AeroMexico Club Premier", value: "AM" },
  { program: "Air Algérie Plus", value: "AH" },
  { program: "Air Arabia AirRewards", value: "G9" },
  { program: "Air Canada Aeroplan", value: "AC" },
  { program: "Air China PhoenixMiles", value: "CA" },
  { program: "Air Dolomiti Miles & More", value: "EN" },
  { program: "Air Europa SUMA", value: "UX" },
  { program: "Air France Flying Blue", value: "AF" },
  { program: "Air India Flying Returns", value: "AI" },
  { program: "Air Mauritius Kestrelflyer", value: "MK" },
  { program: "Air New Zealand Airpoints", value: "NZ" },
  { program: "Air Portugal Miles&Go", value: "TP" },
  { program: "Alaska Airlines Mileage Plan", value: "AS" },
  { program: "Alitalia MilleMiglia", value: "AZ" },
  { program: "All Nippon Airways Mileage Club", value: "NH" },
  { program: "American AAdvantage", value: "AA" },
  { program: "Asiana Club", value: "OZ" },
  { program: "Astra Miles", value: "A2" },
  { program: "Austrian Miles & More", value: "OS" },
  { program: "Avianca LifeMiles", value: "AV" },
  { program: "Avior Plus", value: "9V" },
  { program: "Bamboo Club", value: "QH" },
  { program: "Bangkok Airways FlyerBonus", value: "PG" },
  { program: "Braathens Regional Airlines Vänner", value: "DC" },
  { program: "British Airways Executive Club", value: "BA" },
  { program: "Brussels Airlines Miles & More", value: "SN" },
  // { program: "Cathay Pacific Asia Miles", value: "CX" },
  { program: "Cathay Pacific The Marco Polo Club", value: "CX" },
  { program: "China Eastern Miles", value: "MU" },
  { program: "China Southern Sky Pearl Club", value: "CZ" },
  { program: "Copa ConnectMiles", value: "CM" },
  { program: "Corsair Club Corsair", value: "SS" },
  { program: "Delta SkyMiles", value: "DL" },
  { program: "EgyptAir Plus", value: "MS" },
  { program: "El Al Matmid Club", value: "LY" },
  { program: "Emirates Skywards", value: "EK" },
  { program: "Ethiopian Airlines ShebaMiles", value: "ET" },
  { program: "Etihad Guest", value: "EY" },
  { program: "Eurowings Boomerang Club", value: "EW" },
  { program: "Finnair Plus", value: "AY" },
  { program: "Hong Kong Airlines Fortune Wing Club", value: "HX" },
  { program: "Hong Kong Express Asia Miles", value: "UO" },
  { program: "Frontier Miles", value: "F9" },
  { program: "Garuda Indonesia Miles", value: "GA" },
  { program: "Gol Smiles", value: "G3" },
  { program: "Gulf Air Falcon Flyer", value: "GF" },
  { program: "Hawaiian Miles", value: "HA" },
  { program: "Iberia Plus", value: "IB" },
  { program: "Icelandair Saga Club", value: "FI" },
  { program: "IranAir SkyGift", value: "EP" },
  { program: "Iraqi Airways SkyMiles", value: "IA" },
  { program: "JAL Mileage Bank", value: "JL" },
  { program: "Jazeera Airways Jazeerati", value: "J9" },
  { program: "jetBlue TrueBlue", value: "B6" },
  { program: "KLM Flying Blue", value: "KL" },
  { program: "Korean Air Skypass", value: "KE" },
  { program: "Kuwait Airways Oasis Club", value: "KU" },
  { program: "LATAM Pass", value: "JJ" },
  { program: "Lot Polish Miles & More", value: "LO" },
  { program: "Lufthansa Miles & More", value: "LH" },
  { program: "Malaysia Airlines Enrich", value: "MH" },
  { program: "Norwegian Reward", value: "D8" },
  { program: "Pegasus BolBol", value: "PC" },
  { program: "Oman Air Sindbad", value: "WY" },
  { program: "Philippine Airlines Mabuhay Miles", value: "PR" },
  { program: "Porter Airlines VIPorter", value: "PD" },
  { program: "Qantas Frequent Flyer", value: "QF" },
  { program: "Qatar Airways Privilege Club", value: "QR" },
  { program: "Royal Brunei Royal Skies", value: "BI" },
  { program: "SAS EuroBonus", value: "SK" },
  { program: "Saudia Alfursan", value: "SV" },
  { program: "Singapore Airlines KrisFlyer", value: "SQ" },
  { program: "South African Airways Voyager", value: "SA" },
  { program: "Southwest Airlines Rapid Rewards", value: "WN" },
  { program: "Spirit Airlines Free Spirit", value: "NK" },
  { program: "Sri Lankan Airlines FlySmiLes", value: "UL" },
  { program: "Sun Country Rewards", value: "SY" },
  { program: "SWISS Miles & More", value: "LX" },
  { program: "Thai Airways Royal Orchid Plus", value: "TG" },
  { program: "Turkish Airlines Miles&Smiles", value: "TK" },
  { program: "United Airlines Mileage Plus", value: "UA" },
  { program: "Vietnam Airlines Lotusmiles", value: "VN" },
  { program: "Virgin Atlantic Flying Club", value: "VS" },
  { program: "Virgin Australia Velocity", value: "VA" },
  { program: "Vistara Club Vistara", value: "UK" },
  { program: "Vueling Club", value: "VY" },
  { program: "WestJet Rewards", value: "WS" },
];

export const jetBlueFlyerOptions = [
  { program: "jetBlue TrueBlue", value: "B6" },
  { program: "American AAdvantage", value: "AA" },
  { program: "Emirates Skywards", value: "EK" },
  { program: "Icelandair Saga Club", value: "FI" },
  { program: "Hawaiian Miles", value: "HA" },
  { program: "Qatar Airways Privilege Club", value: "QR" },
  { program: "South African Airways Voyager", value: "SA" },
  { program: "Singapore Airlines KrisFlyer", value: "SQ" },
];

const getFormattedFlyerOptions = (options: IFlyerOption[]) =>
  options.map((option) => ({
    label: (
      <p className="frequent-flyer-option">
        <strong>{option.program}</strong>
      </p>
    ),
    ...option,
  }));

export const formattedFlyerOptions = getFormattedFlyerOptions(flyerOptions);
export const formattedJetBlueFlyerOptions =
  getFormattedFlyerOptions(jetBlueFlyerOptions);

import { Box } from "@material-ui/core";
import { Trans } from "@hopper-b2b/i18n";

import { IconName } from "../../../Icon";
import { IconComponent } from "../../../IconComponent";
import styles from "./styles.module.scss";

export const DegradedPerformanceNotice = () => (
  <Box className={styles.degradedPerformanceNotice}>
    <IconComponent name={IconName.InfoCircle} />
    <div>
      <Trans i18nKey={"degradedPerformanceNotice"} components={[<span />]} />
    </div>
  </Box>
);

import { AsYouTypeFormatter, PhoneNumberUtil } from "google-libphonenumber";

import { phoneRegex } from "./regex-constants";

export const isPhoneNumberValid = (phone: string) => phoneRegex.test(phone);

// full validation of a phone number for a region using length and prefix information
// ideally used before creating a session as this should catch invalid number such as 444-123-1234
// and we can display better FE validation message to users.
export const isPhoneNumberValidForRegion = (
  phone: string,
  regionCode: string
) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedPhone = phoneUtil.parseAndKeepRawInput(phone, regionCode);
    return phoneUtil.isValidNumber(parsedPhone);
  } catch (e) {
    return false;
  }
};

export const parsePhoneNumber = (phone?: string) => {
  if (!phone) {
    return {
      nationalNumber: "",
      countryCode: "",
    };
  }

  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedPhone = phoneUtil.parseAndKeepRawInput(phone);
    return {
      nationalNumber: `${parsedPhone.getNationalNumber()}`,
      countryCode: `${parsedPhone.getCountryCode()}`,
    };
  } catch (e) {
    console.warn(`Could not parse phone number ${phone}`, e);
    return {
      nationalNumber: "",
      countryCode: "",
    };
  }
};

export const formatPhoneNumber = (phone: string, countryCode: string) => {
  // Remove non-digit characters
  const cleanedPhone = phone.replace(/\D/g, "");

  // Add in length constraint if needed. This does not comply with people inputting local numbers
  // eg: GB - 024 7XXX XXXX
  // const trimmedInput = cleanedPhone.substring(0, 10);

  // Get country code without the "+"
  const trimmedCountryCode = countryCode.substring(1);
  const countryCodeNumber = parseInt(trimmedCountryCode, 10);

  // Get Region code from country code
  const phoneUtil = PhoneNumberUtil.getInstance();
  const regionCode = phoneUtil.getRegionCodeForCountryCode(countryCodeNumber);

  // Get formatted phone number string
  const formatter = new AsYouTypeFormatter(regionCode);
  // AsYouTypeFormatter does not expose an way to get the formatted string after adding values in
  // On adding a digit, it returns the formatted string, so the last index will be used
  const formattedString = cleanedPhone
    .split("")
    .map((char) => formatter.inputDigit(char));
  const lastChar =
    formattedString.length > 0
      ? formattedString[formattedString.length - 1]
      : "";

  return lastChar;
};

import { useI18nContext } from "@hopper-b2b/i18n";
import { HeaderMenuDropdown, IconComponent, IconName } from "@hopper-b2b/ui";

import clsx from "clsx";
import { useCallback } from "react";

import "./styles.scss";

interface ILanguageSelectDropdownProps {
  arrowIcon?: React.ReactElement;
}

const LanguageSelectDropdown = ({
  arrowIcon,
}: ILanguageSelectDropdownProps) => {
  const { brand, language, setLanguage } = useI18nContext();

  const handleLocaleSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLanguage(e.target.value);
    },
    [setLanguage]
  );

  // The component renders something only when the app supports more than a language
  if (brand.supportedLanguages == null || brand.supportedLanguages.length < 2) {
    return null;
  }

  const { label } = brand.supportedLanguages.find((l) => l.key === language);

  return (
    <HeaderMenuDropdown
      className="b2b-LanguageSelectDropdown"
      content={
        <legend className="b2b-LanguageSelectDropdownLabel">
          {label}
          {arrowIcon ? (
            arrowIcon
          ) : (
            <IconComponent
              name={IconName.Dropdown}
              className="chevron-expand-icon b2b-LanguageSelectDropdownLabelIcon"
            />
          )}
        </legend>
      }
      dropdownContent={
        <fieldset className="b2b-LanguageSelectDropdownList">
          {brand.supportedLanguages.map((l, index) => (
            <div
              key={l.key}
              className={clsx("b2b-LanguageSelectDropdownItem", {
                selected: language === l.key,
                last: index === brand.supportedLanguages.length - 1,
              })}
            >
              <input
                type="radio"
                name="locale"
                id={l.key}
                value={l.key}
                defaultChecked={language === l.key}
                onChange={handleLocaleSelect}
              />
              <label htmlFor={l.key}>{l.label}</label>
            </div>
          ))}
        </fieldset>
      }
    />
  );
};

export default LanguageSelectDropdown;

import {
  ActiveExperimentsResponse,
  ActiveExperimentsRequest,
  Experiment,
  ExperimentState,
  ExperimentsType,
  IApiConfig,
  NETWORK_CALL_FAILED,
} from "@hopper-b2b/types";
import { axiosInstance } from "../AxiosInterceptor";
import { experimentsApiPrefix } from "../paths";
import { trackEvent } from "../tracking/trackEvent";

export const postFetchActiveExperiments = async (
  config?: IApiConfig,
  partnerExperiments?: string
): Promise<ExperimentState> => {
  const experimentPrefix = config?.experimentsApiPrefix || experimentsApiPrefix;
  const END_POINT = `${experimentPrefix}/active`;

  try {
    const res = await axiosInstance.post<ActiveExperimentsResponse>(END_POINT, {
      partnerExperiments: partnerExperiments ?? "",
    } as ActiveExperimentsRequest);

    const { experiments: activeExperiments, ...rest } = res.data;

    const experiments: ExperimentsType = activeExperiments?.reduce(
      (map: ExperimentsType, experiment: Experiment) => {
        map[experiment.id] = experiment.variant;
        return map;
      },
      {}
    );

    return {
      experiments: experiments,
      ...rest,
    };
  } catch (e) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      config
    );
    throw e;
  }
};

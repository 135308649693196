import {
  UpdateQuoteRequest,
  UpdateQuoteResponse,
  CipherText,
  Product,
} from "@b2bportal/purchase-api";
import { updateQuotedCart } from "@hopper-b2b/api";

import {
  CartUpdateError,
  CartUpdateErrorModal,
  PartialParentContext,
} from "@checkout/types";
import { CartContext, cartQuoteSelectors } from "@checkout/states/Cart";
import { ContactSelectors } from "@checkout/states/common";
import { CartUpdateParams } from "../context";
import { getCartUpdateParams } from "../selectors";
import {
  PollingResult,
  PollingType,
  endPollingPerformance,
  startPollingPerformance,
} from "@hopper-b2b/utilities";

export interface CartContextWithContact
  extends CartContext,
    PartialParentContext {}

export const updateQuoteService = (
  context: CartContextWithContact,
  _event: unknown
) =>
  new Promise((resolve, reject) => {
    const cipherText = cartQuoteSelectors.getPriceQuoteCipherText({ context });
    const emailAddress = ContactSelectors.getContactInformation({
      context,
    }).email;

    if (!cipherText) {
      reject({
        type: CartUpdateErrorModal.NoCipherText,
      });
    }
    if (!emailAddress) {
      reject({
        type: CartUpdateErrorModal.NoEmailForProduct,
      });
    }

    const cartUpdateParams = getCartUpdateParams({ context });

    const request = getUpdateQuoteRequest(
      cartUpdateParams,
      cipherText as CipherText,
      emailAddress
    );

    const returnValue = isSeatProductToBeAdded(request)
      ? { type: Product.Seats }
      : null;

    //Polling happens on the backend
    const startPerformance = startPollingPerformance();

    updateQuotedCart(request)
      .then((result: UpdateQuoteResponse) => {
        const possibleError: CartUpdateError = {
          type: [],
          data: {
            failedPaymentAdds: undefined,
            failedProductAdds: undefined,
            failedPaymentRemoves: undefined,
            failedProductRemoves: undefined,
          },
        };

        if (result.failedPaymentAdds.length > 0) {
          possibleError.type.push(CartUpdateErrorModal.FailedPaymentAdd);
          possibleError.data.failedPaymentAdds = result.failedPaymentAdds;
        }
        if (result.failedProductAdds.length > 0) {
          possibleError.type.push(CartUpdateErrorModal.FailedProductAdds);
          possibleError.data.failedProductAdds = result.failedProductAdds;
        }
        if (result.failedPaymentRemoves.length > 0) {
          possibleError.type.push(CartUpdateErrorModal.FailedPaymentRemoves);
          possibleError.data.failedPaymentRemoves = result.failedPaymentRemoves;
        }
        if (result.failedProductRemoves.length > 0) {
          possibleError.type.push(CartUpdateErrorModal.FailedProductRemoves);
          possibleError.data.failedProductRemoves = result.failedProductRemoves;
        }
        if (possibleError.type.length > 0) {
          endPollingPerformance(
            startPerformance,
            PollingType.UPDATE,
            PollingResult.FAILURE
          );
          reject(possibleError);
        } else {
          endPollingPerformance(
            startPerformance,
            PollingType.UPDATE,
            PollingResult.SUCCESS
          );
          resolve(returnValue);
        }
      })
      .catch((e) => {
        reject({
          type: [CartUpdateErrorModal.Unknown],
        });
      });
  });

export const getUpdateQuoteRequest = (
  cartUpdateParams: CartUpdateParams,
  cipherText: CipherText,
  emailAddress: string
): UpdateQuoteRequest => {
  const request: UpdateQuoteRequest = { token: cipherText };
  if (cartUpdateParams.addProducts) {
    request.addProducts = {
      emailAddress,
      products: cartUpdateParams.addProducts,
    };
  }
  if (cartUpdateParams.addPayments) {
    request.addPayments = {
      payments: cartUpdateParams.addPayments,
    };
  }
  if (cartUpdateParams.removeProducts) {
    request.removeProducts = {
      products: cartUpdateParams.removeProducts,
    };
  }
  if (cartUpdateParams.removePayments) {
    request.removePayments = {
      payments: cartUpdateParams.removePayments,
    };
  }
  return request;
};

const isSeatProductToBeAdded = (req: UpdateQuoteRequest) =>
  req.addProducts?.products.some((p) => p.type === Product.Seats);

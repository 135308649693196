import { assign, type DoneInvokeEvent } from "xstate";
import type {
  Failure,
  PersonListSuccess,
  TripPricingSummary,
} from "@b2bportal/air-booking-api";

import { setContextWithKey, getObjectKeysAsObject } from "@checkout/helpers";
import {
  ParentState,
  type PassengerError,
  PassengerErrorModalTypes,
} from "@checkout/types";
import type { FlightContextV2 } from "../../types";
import type {
  OnInfantModalContinue,
  SelectPassengerEvent,
  SetCurrentPassengerEvent,
} from "./events";
import type { DeleteUserPassengerSuccessResponse } from "./services/deleteUserPassenger";
import { getIsLapInfant } from "./selectors";
import type { ValidateAndFetchTripPricingEvent } from "../PassengerInformation/events";
import { isAdult } from "@hopper-b2b/utilities";

export const actions = {
  selectPassenger: assign(
    (ctx: FlightContextV2, event: SelectPassengerEvent) => {
      const selectedFareId =
        ctx.flightShop.selectedTrip.returnFareId ||
        ctx.flightShop.selectedTrip.outgoingFareId;
      const fareDetails = ctx.flightShop.tripDetails.fareDetails;
      const excludeINS = fareDetails
        ?.filter((fare) => fare.id === selectedFareId)
        ?.some((fare) => !!fare.excludeINS);

      const { singleTravelerWorkflow } = event;
      const onUpdate = event?.data?.onUpdate || false;

      let passengerId: string;
      if (event.data) {
        passengerId = event.data.id;
      } else {
        passengerId = event.passengerId;
      }
      const selectedPassengerIds =
        ctx[ParentState.passengerInformation].selectedPassengerIds;
      const selectedLapInfantIds =
        ctx[ParentState.passengerInformation].selectedLapInfantIds;

      const newPassengerInfoCtx = { ...ctx[ParentState.passengerInformation] };

      // Remove passenger from appropriate list if exists and action is not triggered onUpdate
      if (!onUpdate && selectedPassengerIds.includes(passengerId)) {
        const newSelectedPassengerIds = selectedPassengerIds.filter(
          (id) => passengerId !== id
        );
        newPassengerInfoCtx.selectedPassengerIds = newSelectedPassengerIds;
      } else if (!onUpdate && selectedLapInfantIds.includes(passengerId)) {
        const newSelectedLapInfantIds = selectedLapInfantIds.filter(
          (id) => passengerId !== id
        );
        newPassengerInfoCtx.selectedLapInfantIds = newSelectedLapInfantIds;
      } else {
        // If passenger not in either list, add to appropriate list
        newPassengerInfoCtx.lastSelectedPassengerId = passengerId;

        const userPassengers =
          ctx[ParentState.passengerInformation].userPassengers;
        const newPassenger = userPassengers.find(
          (passenger) => passenger.id === passengerId
        );
        if (!newPassenger) {
          return setContextWithKey(
            ctx,
            ParentState.passengerInformation,
            newPassengerInfoCtx
          );
        }
        const isNewPassengerInfant = getIsLapInfant(newPassenger);
        if (isNewPassengerInfant) {
          // TODO: Figure out if singleTravelerWorkflow is needed
          // Handle adding infants, if singleTravelerWorkflow then we should throw an error
          if (selectedPassengerIds.length === 0 || singleTravelerWorkflow) {
            // Show Infant only error
            // TODO REMOVE: From my testing, this doesn't seem needed anymore since adding an Infant
            // with no Adult will result in a InvalidPassenger error same as with Children
            newPassengerInfoCtx.infantOnlyError = true;
          }
          if (excludeINS) {
            // If the excludeINS is true, we only allow infants to be added as an INF
            newPassengerInfoCtx.selectedLapInfantIds = [
              ...selectedLapInfantIds,
              passengerId,
            ];
          } else {
            newPassengerInfoCtx.currentInfantId = newPassenger.id;
          }
        } else {
          // Add userPassengers (i.e. Not infants)

          // Hacky way to ensure user can only select a single traveler.
          if (singleTravelerWorkflow) {
            newPassengerInfoCtx.selectedPassengerIds = [passengerId];
          } else {
            newPassengerInfoCtx.selectedPassengerIds = [
              ...selectedPassengerIds.filter((id) => id !== passengerId),
              passengerId,
            ];
          }
        }
      }

      return setContextWithKey(
        ctx,
        ParentState.passengerInformation,
        newPassengerInfoCtx
      );
    }
  ),

  setUserPassengers: assign(
    (ctx: FlightContextV2, event: DoneInvokeEvent<PersonListSuccess>) =>
      setContextWithKey(
        ctx,
        `${ParentState.passengerInformation}.userPassengers`,
        event.data?.value || event.data
      )
  ),

  setEditPassengers: assign((ctx: FlightContextV2) => {
    ctx[ParentState.passengerInformation].isEditing = true;
    return ctx;
  }),

  resetEditPassengers: assign((ctx: FlightContextV2) => {
    ctx[ParentState.passengerInformation].isEditing = false;
    return ctx;
  }),

  handleDeletePassenger: assign(
    (
      ctx: FlightContextV2,
      event: DoneInvokeEvent<DeleteUserPassengerSuccessResponse>
    ) => {
      const selectedPassengerIds =
        ctx[ParentState.passengerInformation].selectedPassengerIds;
      const newSelectedPassengerIds = selectedPassengerIds.filter(
        (id) => event.data.deletedPassengerId !== id
      );
      ctx[ParentState.passengerInformation].selectedPassengerIds =
        newSelectedPassengerIds;
      ctx[ParentState.passengerInformation].userPassengers =
        event.data.userPassengers;
      return ctx;
    }
  ),

  setSingleDefaultPassenger: assign((ctx: FlightContextV2) => {
    if (ctx[ParentState.passengerInformation].selectedPassengerIds.length > 0) {
      return ctx;
    }

    if (ctx[ParentState.passengerInformation].userPassengers.length === 1) {
      ctx[ParentState.passengerInformation].selectedPassengerIds = [
        ctx[ParentState.passengerInformation].userPassengers[0].id,
      ];
    }
    return ctx;
  }),

  setSingleDefaultAdult: assign((ctx: FlightContextV2) => {
    if (ctx[ParentState.passengerInformation].selectedPassengerIds.length > 0) {
      return ctx;
    }

    const adults = ctx[ParentState.passengerInformation].userPassengers.filter(
      (u) => isAdult(u.dateOfBirth)
    );

    if (adults.length === 1) {
      ctx[ParentState.passengerInformation].selectedPassengerIds = [
        adults[0].id,
      ];
    }
    return ctx;
  }),

  setCurrentPassenger: assign(
    (ctx: FlightContextV2, event: SetCurrentPassengerEvent) =>
      setContextWithKey(
        ctx,
        `${ParentState.passengerInformation}.currentUserPassenger`,
        event.passenger
      )
  ),

  dismissNumPassengerAlert: assign(
    (ctx: FlightContextV2, _event: SetCurrentPassengerEvent) =>
      setContextWithKey(
        ctx,
        `${ParentState.passengerInformation}.numPassengerAlertDismissed`,
        true
      )
  ),

  setPassengersError: assign(
    (ctx: FlightContextV2, event: DoneInvokeEvent<PassengerError>) =>
      setContextWithKey(
        ctx,
        `${ParentState.passengerInformation}.error`,
        event.data
      )
  ),

  clearCurrentInfantId: assign((ctx: FlightContextV2, _event) =>
    setContextWithKey(
      ctx,
      `${ParentState.passengerInformation}.currentInfantId`,
      undefined
    )
  ),

  onInfantModalContinue: assign(
    (ctx: FlightContextV2, { seatType }: OnInfantModalContinue) => {
      let selectedLapInfantIds =
        ctx[ParentState.passengerInformation].selectedLapInfantIds;
      let selectedPassengerIds =
        ctx[ParentState.passengerInformation].selectedPassengerIds;
      const currentInfantId =
        ctx[ParentState.passengerInformation].currentInfantId;

      if (seatType === "OwnSeat") {
        selectedLapInfantIds = selectedLapInfantIds.filter(
          (id) => id !== (currentInfantId as string)
        );

        selectedPassengerIds = [
          ...selectedPassengerIds,
          currentInfantId as string,
        ];
      } else {
        selectedLapInfantIds = [
          ...selectedLapInfantIds,
          currentInfantId as string,
        ];
      }

      const updatedContext = ctx[ParentState.passengerInformation];
      updatedContext.selectedPassengerIds = selectedPassengerIds;
      updatedContext.selectedLapInfantIds = selectedLapInfantIds;

      return ctx;
    }
  ),

  onValidateAndTripPricingError: assign(
    (
      ctx: FlightContextV2,
      event: DoneInvokeEvent<PassengerError | Failure>
    ) => {
      const errorData = event.data;
      const isValidateError = (errorData as PassengerError)?.type;
      const tripPricingErrors = (errorData as Failure)?.errors;
      if (isValidateError) {
        return setContextWithKey(
          ctx,
          `${ParentState.passengerInformation}.error`,
          {
            type: (errorData as PassengerError).type,
            data: (errorData as PassengerError).data,
          }
        );
      } else if (tripPricingErrors) {
        return setContextWithKey(
          ctx,
          `${ParentState.passengerInformation}.error`,
          {
            type: PassengerErrorModalTypes.TripPricing,
            data: tripPricingErrors,
          }
        );
      }
      return ctx;
    }
  ),

  clearPassengerInformationError: assign((ctx: FlightContextV2, _event) => {
    ctx[ParentState.passengerInformation].infantOnlyError = false;
    ctx[ParentState.passengerInformation].error = undefined;
    return ctx;
  }),
  setPassengerVisited: assign((ctx: FlightContextV2, _event) =>
    setContextWithKey(ctx, `${ParentState.passengerInformation}.visited`, true)
  ),

  setTripPricingFromData: assign(
    (ctx: FlightContextV2, event: DoneInvokeEvent<TripPricingSummary>) =>
      setContextWithKey(ctx, "common.tripPricing", event.data)
  ),
  setTripPricingError: assign(
    (ctx: FlightContextV2, event: DoneInvokeEvent<Failure>) =>
      setContextWithKey(ctx, "common.tripPricingError", event.data)
  ),
  setValidated: assign(
    (ctx: FlightContextV2, event: DoneInvokeEvent<Failure>) =>
      setContextWithKey(
        ctx,
        `${ParentState.passengerInformation}.isValidated`,
        true
      )
  ),
  resetValidated: assign(
    (ctx: FlightContextV2, event: DoneInvokeEvent<Failure>) =>
      setContextWithKey(
        ctx,
        `${ParentState.passengerInformation}.isValidated`,
        false
      )
  ),
  onFetchTripPricing: assign(
    (
      ctx: FlightContextV2,
      event: DoneInvokeEvent<ValidateAndFetchTripPricingEvent>
    ) => {
      const [, tripPricingEvent] = event.data;
      return setContextWithKey(ctx, "common.tripPricing", tripPricingEvent);
    }
  ),
  clearLastSelectedPassengerId: assign((ctx: FlightContextV2, _event) =>
    setContextWithKey(
      ctx,
      `${ParentState.passengerInformation}.lastSelectedPassengerId`,
      undefined
    )
  ),
};

export const ActionTypes = getObjectKeysAsObject(actions);

import { RewardsPrice, FiatPrice } from "../../common";

export type RewardsAccountEarnKeys =
  | "hotelsMultiplier"
  | "carsMultiplier"
  | "flightsMultiplier"
  | "luxuryHotelsMultiplier";

type RewardsAccountEarn = {
  [key in RewardsAccountEarnKeys]: number;
};

export interface RewardsAccount {
  accountDisplayName: string;
  accountReferenceId: string;
  cardArtUrl: string;
  earn: RewardsAccountEarn;
  isTiered: boolean;
  lastFour: string;
  productDisplayName: string;
  redemptionMin: RewardsPrice;
  redemptionMinCashEquivalent: FiatPrice;
  rewardsBalance: RewardsPrice;
  rewardsCashEquivalent: FiatPrice;
}

export type RewardsAccountsResponse = RewardsAccount[];

export interface ConvertRewardsToUsdRequest {
  rewardsAmount: number;
  accountReferenceId: string;
}

export type ConvertRewardsToUsdResponse =
  | RewardsResponseErrorCode
  | ConvertRewardsToUsdSuccess;

export interface ConvertRewardsToUsdSuccess {
  value: number;
}

export interface ConvertUsdToAllRewardsRequest {
  amount: number;
}

export interface ConvertUsdToAllRewardsResponse {
  value: { [key: string]: RewardsPrice };
}

export interface ConvertUsdToRewardsRequest {
  amount: number;
  accountReferenceId: string;
}

export type ConvertUsdToRewardsResponse =
  | RewardsResponseErrorCode
  | ConvertUsdToRewardsSuccess;

export interface ConvertUsdToRewardsSuccess {
  value: RewardsPrice;
}

export interface RewardsResponseErrorCode {
  code: string;
  details?: string;
}

export interface EarnRequest {
  account: string;
  travelProduct: TravelProduct;
  amountUsd: number;
}

export interface EarnResponse {
  multiplier: number;
  correlatedCurrency: string;
  amount: number;
}

export type TravelProduct =
  | TravelProductEnum.Hotels
  | TravelProductEnum.Cars
  | TravelProductEnum.Flights;

export enum TravelProductEnum {
  Hotels = "Hotels",
  Cars = "Cars",
  Flights = "Flights",
}

export const cardsToShowWithPriorityOnRewardsBanner = [
  "Venture X",
  "Venture",
  "VentureOne",
  "Spark Classic Miles",
  "Spark Miles",
  "Spark Miles Select",
].map((card) => card.toLowerCase());

export const cardsToShowLastFourOnRewardsBanner = [
  "Capital One Miles",
  "Spark Business",
  "Capital One Small Business Card",
  "Card",
].map((card) => card.toLowerCase());

export const cardsToNotShowBannerOn = ["Walmart"].map((card) =>
  card.toLowerCase()
);

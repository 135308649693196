import { ClientName } from "@hopper-b2b/types";
import { getEnvVariables, useDeviceTypes } from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { ReactNode } from "react";
import "./styles.scss";

export interface FloatingBoxProps {
  className?: string;
  children: ReactNode;
  floating?: boolean;
}

export const FloatingBox = ({ className, children }: FloatingBoxProps) => {
  const { matchesMobile } = useDeviceTypes();
  const usePlaceholder =
    getEnvVariables("clientName") === ClientName.HOPPER ||
    getEnvVariables("clientName") === ClientName.LLOYDS;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {matchesMobile ? (
        <>
          {usePlaceholder && (
            <div
              className={clsx(
                "mobile-floating-section",
                "floating-section-placeholder"
              )}
              aria-hidden={true}
            >
              {children}
            </div>
          )}
          <Box
            className={clsx(
              "floating-box",
              getEnvVariables("clientName"),
              className
            )}
          >
            {children}
          </Box>
        </>
      ) : (
        children
      )}
    </>
  );
};

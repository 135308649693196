import {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
  PropsWithChildren,
} from "react";

const UTM_MEDIUM_PARAM = "utm_medium";
export const USER_MEDIUM_KEY = "user_medium";

const UTM_CAMPAIGN_PARAM = "utm_campaign";
export const USER_CAMPAIGN_KEY = "user_campaign";

const UTM_SOURCE_PARAM = "utm_source";
const USER_SOURCE_KEY = "user_source";

export const UTM_CONTENT_PARAM = "utm_content";
export const USER_CONTENT_KEY = "user_content";

export interface UTMParams {
  userMedium: string | null;
  userCampaign: string | null;
  userSource: string | null;
  userContent: string | null;
}

const UtmTrackingContext = createContext<UTMParams | null | undefined>(
  undefined
);

export function useUtmTracking(): UTMParams | null {
  const ctx = useContext(UtmTrackingContext);
  if (ctx === undefined)
    throw new Error(`must be used within a UserMediumProvider`);
  return ctx;
}

export const UtmTrackingProvider: FC<PropsWithChildren> = ({ children }) => {
  const [userMedium, setUserMedium] = useState(
    sessionStorage.getItem(USER_MEDIUM_KEY)
  );
  const [userCampaign, setUserCampaign] = useState(
    sessionStorage.getItem(USER_CAMPAIGN_KEY)
  );
  const [userSource, setUserSource] = useState(
    sessionStorage.getItem(USER_SOURCE_KEY)
  );
  const [userContent, setUserContent] = useState(
    sessionStorage.getItem(USER_CONTENT_KEY)
  );

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const param = new URLSearchParams(location?.search).get(UTM_MEDIUM_PARAM);
    if (param && !userMedium) {
      setUserMedium(param);
      sessionStorage.setItem(USER_MEDIUM_KEY, param);
    }
  }, [userMedium]);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const param = new URLSearchParams(location?.search).get(UTM_CAMPAIGN_PARAM);
    if (param && !userCampaign) {
      setUserCampaign(param);
      sessionStorage.setItem(USER_CAMPAIGN_KEY, param);
    }
  }, [userCampaign]);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const param = new URLSearchParams(location?.search).get(UTM_SOURCE_PARAM);
    if (param && !userSource) {
      setUserSource(param);
      sessionStorage.setItem(USER_SOURCE_KEY, param);
    }
  }, [userSource]);

  useEffect(() => {
    const param = new URLSearchParams(window.location?.search).get(
      UTM_CONTENT_PARAM
    );
    if (param && !userContent) {
      setUserContent(param);
      sessionStorage.setItem(USER_CONTENT_KEY, param);
    }
  }, [userContent]);

  return (
    <UtmTrackingContext.Provider
      value={{ userMedium, userCampaign, userSource, userContent }}
    >
      {children}
    </UtmTrackingContext.Provider>
  );
};

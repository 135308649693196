import { ReactNode } from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";

import { ChoiceButton } from "../ChoiceButton";
import "./styles.scss";

export interface IcheckboxCard {
  checked: boolean;
  disabled?: boolean;
  content?: ReactNode;
  message: string;
  onClick: () => void;
  className?: string;
}

export const CheckboxCard = (props: IcheckboxCard) => {
  const { checked, content, disabled, onClick, className, message } = props;

  return (
    <Box className={clsx("checkbox-card", className)}>
      <Box className="checkbox-card-body">
        <Box className="checkbox-card-content">{content}</Box>
      </Box>
      <Box className="checkbox-card-checkbox-container">
        <ChoiceButton
          disabled={disabled || false}
          isChosen={checked}
          onClick={onClick}
          message={message}
          className={clsx({ disabled })}
        />
      </Box>
    </Box>
  );
};

import common_translations from "../common.json";
import app from "./app.json";
import checkout from "./checkout.json";
import exchange from "./exchange.json";
import fintech from "./fintech.json";
import flights from "./flights.json";
import hopper from "./hopper.translation.json";
import hotels from "./hotels.json";
import nubank from "./nubank.translation.json";
import trips from "./trips.json";
import ui from "./ui.json";
import volaris from "./volaris.translation.json";
import wallet from "./wallet.json";
import blue from "./blue.translation.json";
import medellin from "./medellin.translation.json";
import banorte from "./banorte.translation.json";

const bundle = {
  ...common_translations,
  ...app,
  ...flights,
  ...hotels,
  ...ui,
  ...checkout,
  ...trips,
  ...fintech,
  ...exchange,
  ...wallet,
};
export default bundle;

export const es_defaultTranslations = bundle;

export const es_volarisTranslations = volaris;

export const es_hopperTranslations = hopper;

export const es_blueTranslations = blue;

export const es_medellinTranslations = medellin;

export const es_banorteTranslations = banorte;

import React from "react";
import { SvgrComponent } from "@hopper-b2b/ui";

import { ReactComponent as Airplane } from "../../assets/client/airplane.svg";
import { ReactComponent as CirclePlane } from "../../assets/client/circle-plane.svg";
import { ReactComponent as CloseModal } from "../../assets/client/close-modal.svg";
import { ReactComponent as CrossOutSignIcon } from "../../assets/client/cross-out-sign.svg";
import { ReactComponent as DropdownIcon } from "../../assets/client/dropdown.svg";
import { ReactComponent as ErrorAlertIcon } from "../../assets/client/alert-error.svg";
import { ReactComponent as WarningAlertIcon } from "../../assets/client/alert-warning.svg";
import { ReactComponent as BrandAlertIcon } from "../../assets/client/alert-brand.svg";
import { ReactComponent as FilledClock } from "../../assets/client/filled-clock.svg";
import { ReactComponent as FlightArrival } from "../../assets/client/flight-arrival.svg";
import { ReactComponent as FlightDeparture } from "../../assets/client/flight-departure.svg";
import { ReactComponent as InfoCircleIcon } from "../../assets/client/info-icon.svg";
import { ReactComponent as InfoCircleBrandIcon } from "../../assets/client/info-circle-brand.svg";
import { ReactComponent as SeatSelection } from "../../assets/client/seat-selection.svg";
import { ReactComponent as SelfCheckBag } from "../../assets/client/self-check-bag.svg";
import { ReactComponent as CarryOnBaggage } from "../../assets/client/carry-on-baggage.svg";
import { ReactComponent as TripProtection } from "../../assets/client/trip-protection.svg";
import { ReactComponent as RestrictionsChecked } from "../../assets/client/check-circle.svg";
import { ReactComponent as RestrictionsClose } from "../../assets/client/close.svg";
import { ReactComponent as RestrictionsPayment } from "../../assets/client/payment.svg";
import { ReactComponent as RestrictionsGeneric } from "../../assets/client/format-list-bulleted.svg";
import { ReactComponent as LocationMarker } from "../../assets/client/location-marker.svg";
import { ReactComponent as Guest } from "../../assets/client/guest.svg";
import { ReactComponent as ErrorStateIcon } from "../../assets/client/error-state.svg";
import { ReactComponent as MultiPlanes } from "../../assets/client/multi-planes-icon.svg";
import { ReactComponent as B2BAirplane } from "../../assets/client/b2b-airplane-icon.svg";
import { ReactComponent as ExternalLink } from "../../assets/client/external-link.svg";
import { ReactComponent as Add } from "../../assets/client/add.svg";
import { ReactComponent as Subtract } from "../../assets/client/subtract.svg";
import { ReactComponent as AirplaneDepart } from "../../assets/client/airplane-depart.svg";
import { ReactComponent as AirplaneArrive } from "../../assets/client/airplane-arrive.svg";
import { ReactComponent as Star } from "../../assets/client/star.svg";
import { ReactComponent as Money } from "../../assets/client/money.svg";
import { ReactComponent as QuestionCircled } from "../../assets/client/help-center-faq.svg";
import { ReactComponent as LostFlight } from "../../assets/client/lost-flight-faq.svg";

import { IconName } from "../Icon";
import "./styles.scss";

type IconComponentMap = {
  [name in IconName]: SvgrComponent;
};

export const iconComponentMap: IconComponentMap = {
  [IconName.Airplane]: Airplane,
  [IconName.CirclePlane]: CirclePlane,
  [IconName.CloseModal]: CloseModal,
  [IconName.CrossOutSign]: CrossOutSignIcon,
  [IconName.Dropdown]: DropdownIcon,
  [IconName.ErrorAlert]: ErrorAlertIcon,
  [IconName.ErrorState]: ErrorStateIcon,
  [IconName.WarningAlert]: WarningAlertIcon,
  [IconName.BrandAlert]: BrandAlertIcon,
  [IconName.FilledClock]: FilledClock,
  [IconName.FlightArrival]: FlightArrival,
  [IconName.FlightDeparture]: FlightDeparture,
  [IconName.InfoCircle]: InfoCircleIcon,
  [IconName.InfoCircleBrand]: InfoCircleBrandIcon,
  [IconName.SeatSelection]: SeatSelection,
  [IconName.SelfCheckBag]: SelfCheckBag,
  [IconName.CarryOnBaggage]: CarryOnBaggage,
  [IconName.TripProtection]: TripProtection,
  [IconName.RestrictionsChecked]: RestrictionsChecked,
  [IconName.RestrictionsClose]: RestrictionsClose,
  [IconName.RestrictionsPayment]: RestrictionsPayment,
  [IconName.RestrictionsGeneric]: RestrictionsGeneric,
  [IconName.LocationMarker]: LocationMarker,
  [IconName.Guest]: Guest,
  [IconName.MultiPlanes]: MultiPlanes,
  [IconName.B2BAirplaneIcon]: B2BAirplane,
  [IconName.ExternalLink]: ExternalLink,
  [IconName.Add]: Add,
  [IconName.Subtract]: Subtract,
  [IconName.AirplaneDepart]: AirplaneDepart,
  [IconName.AirplaneArrive]: AirplaneArrive,
  [IconName.CloseModalPrimary]: CloseModal,
  [IconName.Star]: Star,
  [IconName.Money]: Money,
  [IconName.QuestionCircled]: QuestionCircled,
  [IconName.LostFlight]: LostFlight,
};

export interface IconComponentProps {
  name: IconName;
  className?: string;
  ariaLabel?: string | null;
}

export const IconComponent = ({
  name,
  ariaLabel,
  ...props
}: IconComponentProps): React.ReactElement => {
  const IconComponent = iconComponentMap[name] as SvgrComponent;
  return (
    <IconComponent
      {...props}
      className={`${props.className} icon-component`}
      aria-label={ariaLabel ?? name}
    />
  );
};

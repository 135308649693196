import { PropsWithChildren } from "react";
import { Box } from "@material-ui/core";
import {
  TagValue,
  Tag,
  TagType,
  FrozenPriceTag,
  AlgomerchTag,
  mapI18nAlgomerchText,
} from "@hopper-b2b/types";
import clsx from "clsx";

import { MoreInfoButton } from "../../../MoreInfoButton";
import { useI18nContext } from "@hopper-b2b/i18n";

export interface ITagProps {
  className?: string;
  onClick?: () => void;
}

const TagComponent = ({
  className,
  onClick,
  children,
}: PropsWithChildren<ITagProps>) => (
  <span
    className={clsx("tag", className)}
    onClick={(event) => {
      if (typeof onClick === "function") {
        event.stopPropagation();
        onClick();
      }
    }}
  >
    {children}
  </span>
);
interface FlightLabelProps {
  tag: Tag;
  label?: string;
  onClick?: () => void;
}

const labelToText: {
  [key in TagValue]: string;
} = {
  [AlgomerchTag.BestFlight]: "Best Flight",
  [AlgomerchTag.Cheapest]: "Cheapest",
  [AlgomerchTag.BestQuality]: "Best Quality",
  [AlgomerchTag.Fastest]: "Fastest",
  [FrozenPriceTag]: "Frozen Price",
};

export const FlightTag = ({ tag, label, onClick }: FlightLabelProps) => {
  const clsName = tag.type === TagType.PriceFreeze ? "frozen" : "default";
  return (
    <TagComponent className={clsName} onClick={onClick}>
      {label ?? labelToText[tag.value]}
    </TagComponent>
  );
};

interface IFlightTagsProps {
  tags: Tag[];
  mergeTags?: boolean;
  onClickTag?: (label: string) => void;
  onClickMoreInfo?: () => void;
  showFastest?: boolean;
}
export const FlightTags = ({
  tags,
  mergeTags,
  onClickTag,
  onClickMoreInfo,
  showFastest,
}: IFlightTagsProps) => {
  const { t } = useI18nContext();
  const algomerchTags = tags.filter((tag) => tag.type === TagType.Algomerch);
  const priceFreezeTags = tags.filter(
    (tag) => tag.type === TagType.PriceFreeze
  );

  const renderAlgomerchTags = () => {
    if (mergeTags) {
      return (
        <FlightTag
          tag={tags[0]}
          label={tags
            .filter(
              (tag: Tag) => showFastest || tag.value !== AlgomerchTag.Fastest
            )
            .map((tag: Tag) =>
              mapI18nAlgomerchText[tag.value as AlgomerchTag]
                ? t(mapI18nAlgomerchText[tag.value as AlgomerchTag])
                : tag.value
            )
            .slice(0, 2)
            .join(", ")}
          onClick={() => {
            if (typeof onClickTag === "function") {
              onClickTag(tags[0].value);
            }
          }}
        />
      );
    } else {
      return algomerchTags.map((tag, i) => (
        <FlightTag
          key={`tag-${i}`}
          tag={tag}
          onClick={() => {
            if (typeof onClickTag === "function") {
              onClickTag(tag.value);
            }
          }}
        />
      ));
    }
  };

  const renderPriceFreezeTags = () =>
    priceFreezeTags.map((tag, i) => (
      <FlightTag
        key={`price-freeze-tag-${i}`}
        tag={tag}
        onClick={() => {
          if (typeof onClickTag === "function") {
            onClickTag(tag.value);
          }
        }}
      />
    ));

  return (
    <>
      {algomerchTags.length > 0 && (
        <Box className="tags">{renderAlgomerchTags()}</Box>
      )}
      {priceFreezeTags.length > 0 && (
        <Box className="tags">{renderPriceFreezeTags()}</Box>
      )}
      {algomerchTags.length > 0 && onClickMoreInfo && (
        <MoreInfoButton onClick={onClickMoreInfo} />
      )}
    </>
  );
};

import { Gender } from "@b2bportal/air-booking-api";
import { Passengers, FrozenPricePaxPricing } from "@hopper-b2b/types";
import dayjs from "dayjs";

export const getTotalPassenger = (passenger: Passengers): number => {
  return Object.values(passenger).reduce((passengerValueA, passengerValueB) => {
    return passengerValueA + passengerValueB;
  }, 0);
};

export const getTotalPassengerFromPaxPricing = (
  paxPricings: FrozenPricePaxPricing[]
): number => {
  if (paxPricings.length === 0) return 0;
  return paxPricings.reduce((acc, pax) => {
    return acc + pax.quantity;
  }, 0);
};

export const getGender = (gender: Gender) =>
  gender === Gender.F ? "female" : "male";

/**
 * Returns boolean indicating whether a person's an adult
 *
 * @param {string} dateOfBirth Date of birth in format YYYY-MM-DD
 * @param {string} adultAge Age when a person is considered an adult, defaults to 18
 * @returns {boolean} true if date of birth indicates that person is above adult age
 */
export const isAdult = (dateOfBirth: string, adultAge = 18) => {
  const age = dayjs().diff(dayjs(dateOfBirth, "YYYY-MM-DD"), "year");
  return age >= adultAge;
};

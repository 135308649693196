import { assign } from "xstate";
import { Product, ProductOpaqueValue } from "@b2bportal/purchase-api";
import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { getPriceDropCartUpdateRequest } from "./selectors";
import {
  AirPriceDropProduct,
  PartialParentContextWithAirPriceDrop,
} from "./types";

export const actions = {
  addPriceDropProduct: assign(
    (context: PartialParentContextWithAirPriceDrop, _event: unknown) => {
      if (context[ParentState.priceDrop].candidateId) {
        const filterOutPriceDropFromProducts = (
          context[ParentState.cartUpdate].addProducts ?? []
        ).filter((p: ProductOpaqueValue) => p.type !== Product.AirPriceDrop);

        const request = getPriceDropCartUpdateRequest({ context });
        const newPriceDropProduct: AirPriceDropProduct = {
          type: Product.AirPriceDrop,
          value: request,
        };

        context[ParentState.cartUpdate].addProducts = [
          ...filterOutPriceDropFromProducts,
          newPriceDropProduct,
        ];
      }
      return context;
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject({
  ...actions,
});

import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import "./styles.scss";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface ISecurePaymentsBannerProps {
  className?: string;
}

export const SecurePaymentsBanner = ({
  className,
}: ISecurePaymentsBannerProps) => (
  <Box className={clsx(className, "secure-payments-banner")}>
    <FontAwesomeIcon icon={faLock as IconProp} />
    <Typography variant="subtitle2">Payments are 100% secure.</Typography>
  </Box>
);

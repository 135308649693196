import {
  Lodging,
  LodgingPrice,
  RoomProduct,
  FiatPrice,
  RewardsPrice,
} from "@hopper-b2b/types";

// If discount hunting exists, this implies the price we see is lower than web prices.
// We only show whole-number prices, so we have to specifically handle the case when the rounded prices are the same (i.e. texts are the same)
// to avoid showing something like "$100 per night - Was $100".
export const isSignificantSavings = (price?: LodgingPrice): boolean => {
  const discountHuntingValue = price?.discountHunting?.savingsAmount.fiat.value;

  return !!(discountHuntingValue && discountHuntingValue > 1);
};

// This method is similar to the above, but takes into account the room rate. We should not show the deal branding if the room rate does not equate to savings.
export const dealBrandingIsValid = (
  room: RoomProduct | null,
  lodging: Lodging | null
) => {
  if (!room || !lodging) return false;

  const roomRate = room?.perNightSellRate.fiat.value;
  const dealSavings = lodging?.price?.discountHunting?.savingsAmount.fiat.value;
  const webPrice = lodging?.price?.discountHunting?.webPrice.fiat.value;

  return (
    webPrice &&
    roomRate &&
    dealSavings &&
    Math.abs(webPrice - (roomRate + dealSavings)) < 1
  );
};

export const savingsAmountBasedOnCheapestProduct = (
  lodgingPrice: LodgingPrice,
  cheapestProduct: RoomProduct
): string | null => {
  const comparePrices = lodgingPrice.comparePrices;

  const filteredComparePrices = comparePrices.filter(
    (comparePrice) => comparePrice.nightlyPrice.fiat.value
  );

  const minCompetitorPrice = Math.min(
    ...(filteredComparePrices.map(
      (comparePrice) => comparePrice.nightlyPrice.fiat.value
    ) as number[])
  );

  const { value: hopperPrice, currencySymbol } =
    cheapestProduct.perNightSellRate.fiat;
  const savings = minCompetitorPrice - hopperPrice;
  const roundedSavings = Math.round(minCompetitorPrice - hopperPrice);

  if (filteredComparePrices.length === 0 || savings < 1) return null;

  return `${currencySymbol}${roundedSavings}`;
};

export const hotelPriceFormatter = (price: number): string =>
  Math.round(price).toString();

export const getPriceText = ({
  price,
  priceFormatter,
}: {
  price: FiatPrice;
  priceFormatter?: (price: number) => string;
}): string => {
  return price
    ? getPriceString({
        price: price.value,
        currencySymbol: price.currencySymbol,
        priceFormatter,
      })
    : "";
};

export const getPricesWithComma = (price: string | number): string => {
  if (typeof price == "string") {
    const currencyRegex = /^[^0-9.]*/g;
    const currency = price.match(currencyRegex);
    price = price.replace(/[^0-9.]*/g, "");
    // TODO: change for i18n
    return `${currency}${Number(price).toLocaleString("en-US")}`;
  }
  return price.toLocaleString("en-US");
};

export const getPriceString = ({
  price,
  currencySymbol,
  priceFormatter,
}: {
  price: number;
  currencySymbol?: string;
  priceFormatter?: (price: number) => string;
}): string => {
  return `${currencySymbol ?? "$"}${
    priceFormatter ? priceFormatter(price) : Math.round(price)
  }`;
};

export const getRewardsString = (reward: RewardsPrice) => {
  if (reward) {
    const currency = reward.currency.toLowerCase();
    const value = Math.ceil(reward.value).toLocaleString();
    return `${value} ${currency}`;
  } else {
    return "";
  }
};

export const getRewardText = ({
  reward,
  rewardFormatter,
}: {
  reward: RewardsPrice;
  rewardFormatter?: (value: number) => string;
}): string => {
  // TODO: rewards text mapping logic will be revisited once we know what the possible `currency` values are
  if (!reward) {
    return "";
  }
  const isFiatCurrency = reward.currency === "$";

  const formattedValue = rewardFormatter
    ? rewardFormatter(reward.value)
    : isFiatCurrency
    ? twoDecimalFormatter(reward.value)
    : Math.ceil(reward.value).toLocaleString();

  return isFiatCurrency
    ? `${reward.currency}${formattedValue}`
    : `${formattedValue} ${reward.currency.toLocaleLowerCase()}`;
};

export const twoDecimalFormatter = (price: string | number) => {
  if (typeof price == "string") {
    const currencyRegex = /^[^0-9.]*/g;
    const currency = price.match(currencyRegex);
    price = price.replace(/[^0-9.]*/g, "");
    return `${currency}${Number(price).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  }
  return price.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const roundToTwoDecimals = (num: number) => {
  return num ? Math.round((num + Number.EPSILON) * 100) / 100 : 0;
};

interface IGetRoundedDiscountProps {
  priceWithDiscount: number;
  priceBeforeDiscount: number;
  discount: number;
}

export const getRoundedDiscount = ({
  priceWithDiscount,
  priceBeforeDiscount,
  discount,
}: IGetRoundedDiscountProps): number => {
  const getRoundedValue = (value: number) => Math.round(value);

  const roundedDifference =
    getRoundedValue(priceBeforeDiscount) - getRoundedValue(priceWithDiscount);

  const differenceOfDiscounts = Math.abs(roundedDifference - discount);
  if (differenceOfDiscounts > 1) {
    console.log(
      `Error in getRoundedDiscount: roundedDifference=${roundedDifference} is more than $1 different from discount=${discount}`
    );
    return Math.round(discount);
  } else return roundedDifference;
};

interface FormatterMap {
  formatters: { [key: string]: Intl.NumberFormat };
  get: (currency?: string) => Intl.NumberFormat;
  add: (
    currency?: string,
    options?: Intl.NumberFormatOptions
  ) => Intl.NumberFormat;
}

/**
 * @description A "singleton" to reuse NumberFormat instances
 * @param {string} [currency] A unique currency code to save/look-up the formatter by. Defaults to `"USD"`.
 * @param {Intl.NumberFormatOptions} [options] Options to pass in the formatter.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#parameters
 * @method add Creates a new formatter or overwrites an existing formatter with new options
 * @method get Gets an existing formatter or creates one if it doesn't exist
 * @example CurrencyFormatters.get().format(123.456) => "$123.46"
 * @example CurrencyFormatters.get("CAD").format(123.456) => "CA$123.46"
 * @example CurrencyFormatters.add("CAD", { maximumSignificantDigits: 2 }).format(123.456) => "CA$120"
 * @see https://stackoverflow.com/a/16233919
 */
export const CurrencyFormatters: FormatterMap = {
  formatters: {},
  add(currency = "USD", options?: Intl.NumberFormatOptions) {
    this.formatters[currency] = new Intl.NumberFormat(undefined, {
      ...(options ?? {}),
      currency,
      style: "currency",
    });

    return this.formatters[currency];
  },
  get(currency = "USD", options?: Intl.NumberFormatOptions) {
    if (!this.formatters[currency]) {
      return this.add(currency, options);
    }

    return this.formatters[currency];
  },
};

export const getPriceValueFromString = (price: string) => {
  return Number(price.replace(/[^0-9.-]+/g, ""));
};

import { Box } from "@material-ui/core";
import clsx from "clsx";

import {
  Header as GenericHeader,
  IconComponent,
  IconName,
} from "@hopper-b2b/ui";
import { LanguageSelectDropdown } from "@virtual-interline/ui";

import { HeaderLogos } from "../MobileHeader";
import "./styles.scss";
import { CurrencySelectDropdown } from "../../../Currency";
import { DegradedPerformanceNotice } from "../DegradedPerformanceNotice";

type DesktopHeaderProps = {
  displayPerfNotice?: boolean;
};

const DesktopHeader = ({ displayPerfNotice = false }: DesktopHeaderProps) => {
  return (
    <>
      <GenericHeader
        className={clsx("b2b volaris-generic-header", {
          "with-performance-notice": displayPerfNotice,
        })}
        fullWidth
        left={<HeaderLogos />}
        toolbarClassName="b2b"
        right={
          <Box className={clsx("home-page-menu-links", "right-section", "b2b")}>
            <LanguageSelectDropdown
              arrowIcon={
                <IconComponent
                  name={IconName.Dropdown}
                  className="chevron-expand-icon b2b-LanguageSelectDropdownLabelIcon"
                />
              }
            />
            <CurrencySelectDropdown />
          </Box>
        }
      />
      {displayPerfNotice && <DegradedPerformanceNotice />}
    </>
  );
};

export default DesktopHeader;

export enum DiscountType {
  PRICE_FREEZE = "PriceFreeze",
  PRICE_DROP = "PriceDrop",
  PRICE_DROP_AND_PRICE_FREEZE = "PriceDropAndPriceFreeze",
  CARROT_CASH = "CarrotCash",
  CHFAR_REDEMPTION = "ChfarRedemption",
}

export const PriceFreezeDiscountTypes = [
  DiscountType.PRICE_FREEZE,
  DiscountType.PRICE_DROP_AND_PRICE_FREEZE,
];

export enum TagType {
  Algomerch = "algomerch",
  PriceFreeze = "pricefreeze",
}

export enum AlgomerchTag {
  BestFlight = "BestFlight",
  Cheapest = "Cheapest",
  BestQuality = "BestQuality",
  Fastest = "Fastest",
}

export enum SearchParams {
  Origin = "origin",
  Destination = "destination",
  TripCategory = "tripCategory",
  AdultsCount = "adultsCount",
  ChildrenCount = "childrenCount",
  InfantsInSeatCount = "infantsInSeatCount",
  InfantsOnLapCount = "infantsOnLapCount",
}

export const FrozenPriceTag = "FrozenPrice";
export type FrozenPriceTag = typeof FrozenPriceTag;

export type TagValue = AlgomerchTag | FrozenPriceTag;

export enum FlightShopQueryParams {
  ORIGIN = "origin",
  DESTINATION = "destination",
  DEPARTURE_DATE = "departureDate",
  RETURN_DATE = "returnDate",
  TRIP_CATEGORY = "tripCategory",
  STOP_OPTIONS = "stopsOption",
}

export enum FlightSearchQueryParams {
  ORIGIN = "origin",
  DESTINATION = "destination",
  DEPARTURE_DATE = "departureDate",
  RETURN_DATE = "returnDate",
  TRIP_CATEGORY = "tripCategory",
  STOP_OPTIONS = "stopsOption",
  ADULT_COUNT = "adultsCount",
  CHILD_COUNT = "childrenCount",
  INFANT_IN_SEAT_COUNT = "infantsInSeatCount",
  INFANT_IN_LAP_COUNT = "infantsOnLapCount",
}

import { PATH_HOME, PATH_AUTH } from "@hopper-b2b/common-utils";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useLocation } from "react-router-dom";
import { ErrorModal } from "../ErrorModal";
import { FlightError } from "@b2bportal/air-booking-api";
import { ModalScreenTypes } from "../../modules/flight-checkout/types";

const ExpiredSession = () => {
  const { t } = useI18nContext();
  const location = useLocation();
  const showModal =
    location.pathname === `${PATH_HOME}${PATH_AUTH}invalidsession/`;

  const onClick = () => {
    // History navigation is not enough to refresh the session, we need to do a hard navigation
    window.location.href = PATH_HOME;
  };

  return (
    <ErrorModal
      open={showModal}
      title={t("expiredSessionModal.title")}
      subtitle={t("expiredSessionModal.description")}
      primaryButtonText={t("expiredSessionModal.confirm")}
      primaryOnClick={onClick}
      modalType={`FlightError${FlightError.SessionClosed}`}
      modalScreen={ModalScreenTypes.InvalidSession}
    />
  );
};

export default ExpiredSession;

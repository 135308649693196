import {
  InteractiveAuthResponse,
  ResponseEnum,
  cardApi,
} from "@b2bportal/card-api";
import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { axiosInstance } from "../AxiosInterceptor";
import { trackEvent } from "../tracking/trackEvent";

export const pollInteractivePaymentAuth = async (
  req: string,
  apiConfig?: IApiConfig
): Promise<InteractiveAuthResponse> => {
  try {
    const res = await cardApi(
      axiosInstance
    ).apiV0PaymentMethodsPollInteractiveAuthPut(req);
    if (res.data.Response === ResponseEnum.Success) {
      return res.data.value;
    } else {
      throw res.data;
    }
  } catch (e) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      apiConfig
    );
    throw e;
  }
};

import { assign, DoneInvokeEvent } from "xstate";
import {
  ParentState,
  PartialParentContext,
  PassportError,
} from "../../../../../types";
import {
  getObjectKeysAsObject,
  setContextWithKey,
} from "../../../../../helpers";
import { ClearPassportError } from "./events";
import {
  FetchTripPricing,
  FlightPassengerEventTypes,
  ValidateAndFetchTripPricingEvent,
} from "../PassengerInformation/events";

export const actions = {
  setPassportError: assign(
    (ctx: PartialParentContext, event: DoneInvokeEvent<PassportError>) =>
      setContextWithKey(ctx, `${ParentState.passport}.error`, event.data)
  ),
  setTripPricing: assign(
    (
      ctx: PartialParentContext,
      event:
        | DoneInvokeEvent<ValidateAndFetchTripPricingEvent>
        | FetchTripPricing
    ) => {
      const tripPricing = isFetchTripPricing(event)
        ? event.tripPricing
        : event.data[1];

      return setContextWithKey(ctx, "common.tripPricing", tripPricing);
    }
  ),
  clearPassportError: assign(
    (ctx: PartialParentContext, _event: ClearPassportError) =>
      setContextWithKey(ctx, `${ParentState.passport}.error`, undefined)
  ),
};

function isFetchTripPricing(
  event: DoneInvokeEvent<ValidateAndFetchTripPricingEvent> | FetchTripPricing
): event is FetchTripPricing {
  return event.type === FlightPassengerEventTypes.FETCH_TRIP_PRICING;
}

export const ActionTypes = getObjectKeysAsObject(actions);

import { State } from "xstate";
import { CartUpdateError, CartUpdateState, ParentState } from "@checkout/types";
import { CartContext } from "../context";
import { getChildState } from "../../../helpers";

type CartUpdateStateType = State<CartContext>;
type CartUpdateStateWithoutValue = Pick<CartUpdateStateType, "context">;

export const getCartUpdateParams = ({
  context,
}: CartUpdateStateWithoutValue) => ({
  addPayments: context[ParentState.cartUpdate].addPayments,
  addProducts: context[ParentState.cartUpdate].addProducts,
  removeProducts: context[ParentState.cartUpdate].removeProducts,
  removePayments: context[ParentState.cartUpdate].removePayments,
});

export const getCartUpdateCallState = (state: CartUpdateStateType) =>
  state.context[ParentState.cartUpdate].callState;

export const getCartUpdateError = (
  state: CartUpdateStateType
): CartUpdateError | undefined => state.context[ParentState.cartUpdate].error;

export const getOpenCartUpdateModal = (state: CartUpdateStateType) =>
  [CartUpdateState.update, CartUpdateState.getBreakdown].includes(
    getChildState(state.value) as CartUpdateState
  ) && Object.keys(state.value).includes(ParentState.cartUpdate);

import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState, PartialParentContext } from "@checkout/types";

export const guards = {
  hasPriceFreezeId: (ctx: PartialParentContext, _event: unknown) => {
    return !!ctx[ParentState.priceFreeze]?.priceFreezeId;
  },
};

export const GuardTypes = getObjectKeysAsObject(guards);

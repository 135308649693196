import { Person } from "@b2bportal/air-booking-api";
import { trackEvent, updateUserPassenger } from "@hopper-b2b/api";
import { ADD_PASSPORT } from "@hopper-b2b/types";

import { UpdatePassengerEvent } from "@checkout/states/common/PassengerInformation/events";
import {
  PartialParentContext,
  PassengerErrorModalTypes,
} from "@checkout/types";
import { UpdatePassportAndContinue } from "../events";

export const updatePassengerWithPassportService = (
  _ctx: PartialParentContext,
  event: UpdatePassportAndContinue | UpdatePassengerEvent
) =>
  new Promise((resolve, reject) => {
    const currentPassenger = event.person;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { passport: initialPassport, ...restOfPayload } = currentPassenger;
    const personToUpdate: Person = { ...restOfPayload };
    if (currentPassenger.passport?.countryOfIssue?.length === 2) {
      personToUpdate.passport = initialPassport;
    }

    updateUserPassenger({ person: personToUpdate })
      .then(() => {
        trackEvent({
          eventName: ADD_PASSPORT,
          properties: { success: true },
        });
        resolve(null);
      })
      .catch((e) => {
        trackEvent({
          eventName: ADD_PASSPORT,
          properties: { success: false },
        });
        reject({
          type: PassengerErrorModalTypes.UpdatePassenger,
          data: e,
        });
      });
  });

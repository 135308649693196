import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { ReactNode } from "react";

import {
  Airline,
  Airport,
  BookedFlightItineraryWithDepartureTime,
  FlightItinerarySlice,
} from "@b2bportal/air-booking-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  getDepartureSlice,
  getReturnSlice,
  getStopsFromSegments,
} from "@hopper-b2b/types";
import {
  getIsMixedClass,
  getSkchSliceTime,
  getSliceDuration,
  getSliceTime,
} from "@hopper-b2b/utilities";

import { AirlineIcon, MixedCabinToolTip } from "../..";

import "./styles.scss";

const DATE_FORMAT = "ddd, MMM DD";

type FlightSummaryProps = {
  flight: BookedFlightItineraryWithDepartureTime;
};

type SliceSummaryProps = {
  slice: FlightItinerarySlice;
};

// Support passing in either a whole flight or a single slice
export type ItinerarySummaryProps = (FlightSummaryProps | SliceSummaryProps) & {
  airlineMap: Record<string, Airline>;
  airportMap: Record<string, Airport>;
  className?: string;
  hasScheduleChange?: boolean;
  hideMixedCabinTooltip?: boolean;
  isOutgoing: boolean;
  statusTag?: ReactNode;
};

const defaultProps: Partial<ItinerarySummaryProps> = {
  isOutgoing: false,
  hasScheduleChange: false,
};

export const ItinerarySummary = (props: ItinerarySummaryProps) => {
  const {
    airlineMap,
    airportMap,
    className,
    hasScheduleChange,
    hideMixedCabinTooltip,
    isOutgoing,
    statusTag,
  } = props;
  const { t } = useI18nContext();
  let slice;

  if ("flight" in props) {
    slice = isOutgoing
      ? getDepartureSlice(props.flight.bookedItinerary)
      : getReturnSlice(props.flight.bookedItinerary);
  } else {
    slice = props.slice;
  }

  // no return summary for one-way flights
  if (!slice) return null;

  const airline = slice.segments[0].marketingAirline;
  const isMixedCabinClass = getIsMixedClass(slice);
  const numStops = getStopsFromSegments(slice.segments);

  const getItinerarySummaryHeader = (airportMap: {
    [key: string]: Airport | undefined;
  }) => {
    const time = isOutgoing
      ? dayjs(slice?.segments[0].scheduledDeparture)
      : dayjs(slice?.segments[0].scheduledArrival);

    if (slice) {
      const { segments } = slice;
      const { locationCode } = segments[segments.length - 1].destination;
      const cityName = airportMap[locationCode]?.cityName ?? locationCode;

      return t("itineraryDetailsHeaderSimple", {
        destination: cityName,
        date: time.format(DATE_FORMAT),
      });
    }

    // if no slice, show just "outbound" or "return"
    return "";
  };

  return (
    <Box className={clsx("itinerary-summary-container", className)}>
      {statusTag}
      <Typography variant="subtitle2" className="itinerary-summary-header">
        <span className="itinerary-summary-label">
          {isOutgoing ? t("outbound") : t("return")}{" "}
        </span>
        {getItinerarySummaryHeader(airportMap)}
        {isMixedCabinClass && !hideMixedCabinTooltip && <MixedCabinToolTip />}
      </Typography>
      <Box className="itinerary-summary-details-section">
        <Box className={clsx("time-and-airline", "section-area")}>
          <Typography variant="body1" className="itinerary-times">
            {hasScheduleChange ? (
              <s>{getSkchSliceTime(slice)}</s>
            ) : (
              getSliceTime(slice)
            )}
          </Typography>
          <Box className="itinerary-airline">
            <AirlineIcon airlineCode={airline.code} />
            <Typography variant="body2">
              {airlineMap[airline.code]?.displayName}
            </Typography>
          </Box>
        </Box>
        <Box className={clsx("duration-and-stop", "section-area")}>
          <Typography variant="body1" className="itinerary-duration">
            {getSliceDuration(slice)}
          </Typography>
          <Typography variant="body2" className="itinerary-stops">
            {numStops === 0
              ? t("stopDetails.nonstop")
              : t("stopDetails.stop", {
                  count: numStops,
                })}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

ItinerarySummary.defaultProps = defaultProps;

export default ItinerarySummary;

import { getObjectKeysAsObject } from "../../../helpers";
import { CartState } from "../types";
import { CartContext } from "../context";

export const guards = {
  isCartLocked: (ctx: CartContext, _event: unknown) =>
    ctx[CartState.cartQuote].cartLocked === true,

  shouldDiscardCart: (ctx: CartContext, _event: unknown) => {
    const quoteAlreadyExists = !!ctx[CartState.cartQuote].priceQuoteCipherText;
    return quoteAlreadyExists;
  },
};

export const GuardTypes = getObjectKeysAsObject(guards);

import {
  CipherText,
  QuoteFailure,
  QuoteResponse,
  QuoteResponseEnum,
  QuoteSuccess,
} from "@b2bportal/purchase-api";
import {
  CartQuoteErrorModal,
  CartQuoteError,
  CartQuoteState,
} from "@checkout/types";
import { pollQuote } from "@hopper-b2b/api";
import { CartContext } from "../../context";
import { getPriceQuoteCipherText } from "../selectors";
import {
  PollingResult,
  PollingType,
  endPollingPerformance,
  startPollingPerformance,
} from "@hopper-b2b/utilities";

export const pollPriceQuoteService = (context: CartContext, _event: unknown) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const cartQuoteError: Omit<CartQuoteError, "type"> = {
      failedAt: CartQuoteState.polling,
    };
    try {
      const cipherText = getPriceQuoteCipherText({ context });

      if (!cipherText) {
        reject({ ...cartQuoteError, type: CartQuoteErrorModal.NoCipherText });
      }

      const delayTimes = [...Array(16).fill(500), 1000];
      let pollFinished = false;
      let index = 0;

      const startPerformance = startPollingPerformance();

      while (!pollFinished) {
        const currentDelayTime = delayTimes[index];
        if (index !== delayTimes.length - 1) {
          index++;
        }

        // This creates a polling effect and retrying the call with a delay=currentDelayTime
        await new Promise((resolve) => setTimeout(resolve, currentDelayTime));

        const response: QuoteResponse = await pollQuote(
          cipherText as CipherText
        );
        switch (response.QuoteResponse) {
          case QuoteResponseEnum.Failure: {
            endPollingPerformance(
              startPerformance,
              PollingType.QUOTE,
              PollingResult.FAILURE
            );
            pollFinished = true;
            const failedResponse = response as QuoteFailure;
            if (failedResponse.errors.length > 0) {
              reject({
                ...cartQuoteError,
                type: CartQuoteErrorModal.FailedPolling,
                data: failedResponse.errors,
              });
            } else {
              reject({
                ...cartQuoteError,
                type: CartQuoteErrorModal.FailedPollingNoErrorCode,
              });
            }
            break;
          }
          case QuoteResponseEnum.Pending:
            continue;
            break;
          case QuoteResponseEnum.Success: {
            endPollingPerformance(
              startPerformance,
              PollingType.QUOTE,
              PollingResult.SUCCESS
            );
            pollFinished = true;
            const priceQuoteResponse = response as QuoteSuccess;
            resolve(priceQuoteResponse);
          }
        }
      }
    } catch (e) {
      reject({
        ...cartQuoteError,
        type: CartQuoteErrorModal.Unknown,
        data: e,
      });
    }
  });

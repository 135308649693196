import { assign } from "xstate";
import { Product, ProductOpaqueValue } from "@b2bportal/purchase-api";
import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { getCfarCartUpdateRequest } from "./selectors";
import { AirCfarProduct, PartialParentContextWithAirCfar } from "./types";

export const actions = {
  addCfarProduct: assign(
    (context: PartialParentContextWithAirCfar, _event: unknown) => {
      if (context[ParentState.cancelForAnyReason].cfarQuoteId) {
        const filterOutCfarFromProducts = (
          context[ParentState.cartUpdate].addProducts ?? []
        ).filter((p: ProductOpaqueValue) => p.type !== Product.AirCfar);

        const request = getCfarCartUpdateRequest({ context });
        const newCfarProduct: AirCfarProduct = {
          type: Product.AirCfar,
          value: request,
        };

        context[ParentState.cartUpdate].addProducts = [
          ...filterOutCfarFromProducts,
          newCfarProduct,
        ];
      }
      return context;
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject({
  ...actions,
});

export enum FintechProductType {
  Disruption = "disruption",
  MissedConnection = "missedConnection",
  Chfar = "chfar",
  CfarPurchase = "cfarPurchase",
  CfarExercise = "cfarExercise",
}

export enum FintechCoverageType {
  Full = "full",
  Partial = "partial",
  None = "none",
}

export interface FintechProductOption {
  value: string;
  title: string;
  selected: boolean;
  onClick: () => void;
  description?: string | string[];
  cost?: string | null;
  ctaLabel?: string;
  recommended?: string | null;
  boldTitle?: boolean;
}

export enum SupportStatus {
  "vip" = "vip",
  "basic" = "basic",
}

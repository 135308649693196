import { assign } from "xstate";

import {
  CartState,
  FlightContext,
  LodgingContext,
  ParentState,
  PartialParentContext,
  setContextWithKey,
  WalletContext,
} from "../../../index";
import { FlightPassengerActionTypesV2 } from "../../Products/Flight/states/PassengerInformationV2";
import { getObjectKeysAsObject } from "../../../helpers";
import {
  SetCartQuoteContext,
  SetContactInformationContext,
  SetFlightSearchContext,
  SetFlightSearchDestination,
  SetFlightSearchOrigin,
  SetFlightShopContext,
  SetFlightShopTripDetails,
  SetLodgingShopContext,
  SetPassengerInformationContext,
  SetPriceDropContext,
  SetTripPricing,
  SetWalletContext,
} from "./events";
import dayjs from "dayjs";
import { parsePhoneNumber } from "@hopper-b2b/utilities";

const ResumeSharedActions = {
  setContactInformationContext: assign(
    (context: PartialParentContext, event: SetContactInformationContext) => {
      const { email, phone } = event;

      const { countryCode, nationalNumber } = parsePhoneNumber(phone);

      context[ParentState.contactInformation] = {
        ...context[ParentState.contactInformation],
        contactInfo: {
          email: email,
          phoneNumber: nationalNumber,
          countryCode: `+${countryCode}`,
        },
        visited: true,
      };

      return context;
    }
  ),
  setPassengerInformationContext: assign(
    (context: PartialParentContext, event: SetPassengerInformationContext) => {
      const { userPassengers, selectedLapInfantIds, selectedPassengerIds } =
        event;

      context[ParentState.passengerInformation] = {
        ...context[ParentState.passengerInformation],
        userPassengers,
        selectedLapInfantIds,
        selectedPassengerIds,
      };

      return context;
    }
  ),
  setCartQuoteContext: assign(
    (ctx: PartialParentContext, event: SetCartQuoteContext) => {
      const quoteContext = ctx.cartQuote;
      quoteContext.priceQuoteCipherText = event.cipherText;
      quoteContext.quoteBreakdown = event.breakdown.quoteBreakdown;
      quoteContext.unavailableProducts = event.breakdown.unavailableProducts;
      return setContextWithKey(ctx, CartState.cartQuote, quoteContext);
    }
  ),
  setPriceDropContext: assign(
    (ctx: PartialParentContext, event: SetPriceDropContext) => {
      const priceDropProtectionId = { candidateId: event.candidateId };
      return setContextWithKey(
        ctx,
        ParentState.priceDrop,
        priceDropProtectionId
      );
    }
  ),
  setWalletContext: assign(
    (context: WalletContext, { type, ...walletContext }: SetWalletContext) => {
      context[ParentState.wallet] = {
        ...context[ParentState.wallet],
        ...walletContext,
      };

      return context;
    }
  ),
};

export const ResumeFlightActions = {
  ...ResumeSharedActions,
  setFlightSearchContext: assign(
    (context: FlightContext, event: SetFlightSearchContext) => {
      context.flightSearch = {
        ...context.flightSearch,
        tripCategory: event.tripCategory,
        departureDate: dayjs(event.departureDate).toDate(),
        returnDate: event.returnDate
          ? dayjs(event.returnDate).toDate()
          : undefined,
      };

      return context;
    }
  ),
  setFlightSearchOrigin: assign(
    (context: FlightContext, event: SetFlightSearchOrigin) => {
      return setContextWithKey(context, "flightSearch.origin", event.origin);
    }
  ),
  setFlightSearchDestination: assign(
    (context: FlightContext, event: SetFlightSearchDestination) => {
      return setContextWithKey(
        context,
        "flightSearch.destination",
        event.destination
      );
    }
  ),
  setFlightShopContext: assign(
    (context: FlightContext, event: SetFlightShopContext) => {
      context.flightShop = {
        ...context.flightShop,
        selectedTrip: {
          ...context.flightShop.selectedTrip,
          tripId: event.tripId,
          outgoingFareId: event.outgoingFareId,
          returnFareId: event.returnFareId,
        },
      };

      return context;
    }
  ),
  setFlightShopTripDetails: assign(
    (context: FlightContext, event: SetFlightShopTripDetails) => {
      const { fareDetails, slices } = event.tripDetails;
      const selectedFare = fareDetails.find(
        (fare) => fare.id === event.selectedFareId
      );
      context.flightShop = {
        ...context.flightShop,
        tripDetails: event.tripDetails,
        selectedTrip: {
          ...context.flightShop.selectedTrip,
          outgoingFareId: event.selectedFareId,
          returnFareId: event.selectedFareId,
          outgoingFareRating: selectedFare.slices[0].fareShelf.rating,
          returnFareRating: selectedFare.slices[1]?.fareShelf?.rating,
          outgoingSliceId: slices[0].id,
          returnSliceId: slices[1]?.id,
        },
      };

      return context;
    }
  ),
  setPassengerValidated: FlightPassengerActionTypesV2.setValidated,
  setTripPricing: (context: FlightContext, event: SetTripPricing) => {
    return setContextWithKey(context, "common.tripPricing", event.tripSummary);
  },
};

export const ResumeLodgingActions = {
  ...ResumeSharedActions,
  setLodgingShopContext: assign(
    (context: LodgingContext, event: SetLodgingShopContext) => {
      const { countryCode, nationalNumber } = parsePhoneNumber(event.phone);

      context.lodgingShop = {
        ...context.lodgingShop,
        availabilityLink: event.availabilityLink,
        fromDate: event.fromDate,
        untilDate: event.untilDate,
        guests: event.guests,
        lodgingCity: event.lodgingCity,
        roomMediaUrl: event.roomMediaUrl,
        roomMaxAdults: event.roomMaxAdults,
        selectedOpaqueQuoteRequest: event.quoteRequest,
      };

      context.contactInformation = {
        ...context.contactInformation,
        contactInfo: {
          email: event.email,
          phoneNumber: nationalNumber,
          countryCode: `+${countryCode}`,
        },
        visited: true,
      };

      context.cartQuote = {
        ...context.cartQuote,
        cartLocked: true,
      };

      return context;
    }
  ),
};

export const ResumeSharedActionTypes =
  getObjectKeysAsObject(ResumeSharedActions);
export const ResumeFlightActionTypes =
  getObjectKeysAsObject(ResumeFlightActions);
export const ResumeLodgingActionsTypes =
  getObjectKeysAsObject(ResumeLodgingActions);

import { assign } from "xstate";
import { Product, ProductOpaqueValue } from "@b2bportal/purchase-api";
import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { getChfarCartUpdateRequest } from "./selectors";
import { PartialParentContextWithAirChfar, AirChfarProduct } from "./types";

export const actions = {
  addChfarProduct: assign(
    (context: PartialParentContextWithAirChfar, _event: unknown) => {
      if (context[ParentState.changeForAnyReason].chfarQuoteId) {
        const filterOutChfarFromProducts = (
          context[ParentState.cartUpdate].addProducts ?? []
        ).filter((p: ProductOpaqueValue) => p.type !== Product.AirChfar);

        const request = getChfarCartUpdateRequest({ context });
        const newChfarProduct: AirChfarProduct = {
          type: Product.AirChfar,
          value: request,
        };

        context[ParentState.cartUpdate].addProducts = [
          ...filterOutChfarFromProducts,
          newChfarProduct,
        ];
      }
      return context;
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject({
  ...actions,
});

import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { PartialParentContextWithAirCfarV2 } from "./types";
import { SelectedStatus } from "@overrides/types";

export const guards = {
  hasCfarIdAndEnabled: (
    ctx: PartialParentContextWithAirCfarV2,
    _event: unknown
  ) => {
    const selectedOffer = ctx[ParentState.cancelForAnyReasonV2]?.selectedOffer;
    return selectedOffer?.status === SelectedStatus.SELECTED;
  },
};

export const GuardTypes = getObjectKeysAsObject(guards);

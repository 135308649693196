import { AirChfarSchedulePriceQuoteRequest } from "@b2bportal/air-chfar-api";
import { ParentState } from "@checkout/types";
import { CompleteBuyAirChfarChoices } from "@hopper-b2b/types";
import { isEmpty } from "lodash-es";
import { State } from "xstate";
import { FlightSelectors } from "../Flight";
import { PartialParentContextWithAirChfar } from "./types";

type ChfarUpdateStateType = State<PartialParentContextWithAirChfar>;
type ChfarUpdateStateWithoutValue = Pick<ChfarUpdateStateType, "context">;

export const getChfarCartUpdateRequest = (
  state: ChfarUpdateStateWithoutValue
) => {
  const selectedTrip = FlightSelectors.getSelectedTripParent(state);
  const tripId = selectedTrip.tripId ?? "";
  const chfarQuoteId = getChfarQuoteId(state) ?? "";
  const seatedPaxCount =
    state.context.passengerInformation.selectedPassengerIds.length;
  const params: AirChfarSchedulePriceQuoteRequest = {
    tripId,
    chfarQuoteId,
    seatedPaxCount,
  };

  return params;
};

export const getChfarQuoteId = (
  state: ChfarUpdateStateType | ChfarUpdateStateWithoutValue
) => {
  return state.context[ParentState.changeForAnyReason].chfarQuoteId;
};

export const getChfarOffers = (
  state: ChfarUpdateStateType | ChfarUpdateStateWithoutValue
) => {
  return state.context[ParentState.changeForAnyReason].chfarOffers;
};

export const getCompleteBuyAirChfarProperties = (
  state: ChfarUpdateStateWithoutValue
) => {
  const chfarProduct = state.context[
    ParentState.changeForAnyReason
  ]?.chfarOffers?.find(
    (chfarOffer) => chfarOffer?.quoteId === getChfarQuoteId(state)
  );

  return chfarProduct?.trackingProperties;
};

export const getSelectedChfarOffer = (
  state: ChfarUpdateStateType | ChfarUpdateStateWithoutValue
) => {
  return state.context[ParentState.changeForAnyReason].selectedOffer;
};

export const getCompleteBuyAirChfarChoice = (
  state: ChfarUpdateStateType | ChfarUpdateStateWithoutValue
): CompleteBuyAirChfarChoices => {
  const chfarOffers = getChfarOffers(state);
  const chfarQuoteId = getChfarQuoteId(state);

  if (isEmpty(chfarOffers)) {
    return CompleteBuyAirChfarChoices.NOT_OFFERED;
  }

  return chfarQuoteId
    ? CompleteBuyAirChfarChoices.ATTACHED
    : CompleteBuyAirChfarChoices.DECLINED;
};

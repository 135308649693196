import { Box, Typography, Divider } from "@material-ui/core";
import { Airport } from "@b2bportal/air-booking-api";
import { SelectedSeatsSegment } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";

import { SelectedSeatCard } from "./SelectedSeatCard";
import "./styles.scss";

export interface ISelectedSeatsConfirmationProps {
  onEditClick?: (idx: number) => void;
  outboundSeatSegments: SelectedSeatsSegment[];
  returnSeatSegments?: SelectedSeatsSegment[];
  isMobile?: boolean;
  outboundOriginCode: string;
  returnOriginCode?: string;
  airports: { [key: string]: Airport | undefined };
}

export const SelectedSeatsConfirmation = (
  props: ISelectedSeatsConfirmationProps
) => {
  const {
    outboundSeatSegments,
    returnSeatSegments,
    onEditClick,
    isMobile,
    airports,
    outboundOriginCode,
    returnOriginCode,
  } = props;

  const { t } = useI18nContext();

  const isLayover = (flightSegment: SelectedSeatsSegment) => {
    return flightSegment.isOutgoing
      ? flightSegment.originCode !== outboundOriginCode
      : flightSegment.originCode !== returnOriginCode;
  };

  const getLocationString = (location: string) => {
    return airports?.[location]
      ? `${airports[location]?.cityName} (${location})`
      : location;
  };
  return (
    <Box className={clsx("selected-seats-confirmation-container")}>
      <Box className="outbound-selected-seats-container">
        {outboundSeatSegments.map((segment) => {
          return (
            <Box key={segment.flightNumber} className="segment-wrapper">
              {isLayover(segment) && (
                <Box className="flight-change-text-wrapper">
                  <Typography className="flight-change-text" variant="body1">
                    {t("flightChangeIn", {
                      location: getLocationString(segment.seats.originCode),
                    })}
                  </Typography>
                </Box>
              )}
              <SelectedSeatCard
                selectedSeats={segment.seats}
                onEditClick={onEditClick}
                isMobile={isMobile}
                airports={airports}
                idx={segment.idx}
              />
            </Box>
          );
        })}
      </Box>
      {returnSeatSegments?.length > 0 ? (
        <>
          <Divider className="selected-seats-divider" />
          <Box className="return-selected-seats-container">
            {returnSeatSegments.map((segment) => {
              return (
                <Box key={segment.flightNumber} className="segment-wrapper">
                  {isLayover(segment) && (
                    <Box className="flight-change-text-wrapper">
                      <Typography
                        className="flight-change-text"
                        variant="body1"
                      >
                        {t("flightChangeIn", {
                          location: getLocationString(segment.seats.originCode),
                        })}
                      </Typography>
                    </Box>
                  )}
                  <SelectedSeatCard
                    selectedSeats={segment.seats}
                    onEditClick={onEditClick}
                    isMobile={isMobile}
                    airports={airports}
                    idx={segment.idx}
                  />
                </Box>
              );
            })}
          </Box>
        </>
      ) : null}
    </Box>
  );
};

import { TranslationLanguage } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";

import { EnglishTermsAndConditions } from "./EnglishTermsAndConditions";
import { SpanishTermsAndConditions } from "./SpanishTermsAndConditions";
import styles from "./styles.module.scss";

const termsAndConditionsLangMapping = {
  [TranslationLanguage.en]: <EnglishTermsAndConditions />,
  [TranslationLanguage.es]: <SpanishTermsAndConditions />,
};

export const TermsAndConditions = () => {
  const { language } = useI18nContext();

  return (
    <div className={styles.termsandConditionsContainer}>
      {termsAndConditionsLangMapping[language]}
    </div>
  );
};

import {
  Failure,
  Person,
  PersonListResponse,
  PersonListSuccess,
} from "@b2bportal/air-booking-api";

import { PassengerErrorModalTypes } from "@checkout/types";
import {
  fetchUserPassengers,
  trackEvent,
  updateUserPassenger,
} from "@hopper-b2b/api";
import { SAVE_TRAVELER } from "@hopper-b2b/types";
import { PartialParentWithPassengerContext } from "../context";
import { UpdatePassengerEvent } from "@checkout/states/common/PassengerInformation/events";

export const updateUserPassengerService = (
  _context: PartialParentWithPassengerContext,
  event: UpdatePassengerEvent
) =>
  new Promise((resolve, reject) => {
    const { person, onUpdate, singleTravelerWorkflow } = event;
    updateUserPassenger({ person })
      .then(() => {
        trackEvent({ eventName: SAVE_TRAVELER, properties: {} });
        fetchUserPassengers({}).then((response: PersonListResponse) => {
          if ((response as Failure).Response === "Failure") {
            reject({
              type: PassengerErrorModalTypes.UpdatePassenger,
              data: (response as Failure).errors,
            });
          } else {
            resolve({
              id: person.id,
              value: (response as PersonListSuccess).value,
              onUpdate,
              singleTravelerWorkflow,
            });
          }
        });
      })
      .catch((e) => {
        reject({
          type: PassengerErrorModalTypes.UpdatePassenger,
          data: e,
        });
      });
  });

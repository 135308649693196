import AirplaneCalendar from "./client/airplane-calendar.svg";
import Airplane from "./client/airplane.svg";
import Bell from "./client/bell.svg";
import BlackLock from "./client/black-lock.svg";
import Calendar from "./client/calendar.svg";
import Card from "./client/card.svg";
import CheckmarkFilled from "./client/checkmark-filled.svg";
import CheckmarkSimple from "./client/checkmark-simple.svg";
import ChevronLeft from "./client/chevron-left.svg";
import Filter from "./client/filter.svg";
import Flights from "./client/flights.svg";
import GreenShieldCheck from "./client/green-shield-check.svg";
import GreenBolt from "./client/green-bolt.svg";
import Guest from "./client/guest.svg";
import Info from "./client/info.svg";
import LocationMarker from "./client/location-marker.svg";
import Lock from "./client/lock.svg";
import MissedConnection from "./client/missed-connection.svg";
import OneWay from "./client/oneway.svg";
import Passenger from "./client/passenger.svg";
import RoundTrip from "./client/roundtrip.svg";
import Cfar from "./client/cfar.svg";
import Check from "./client/check.svg";
import Close from "./client/close.svg";
import FlightDelay from "./client/flight-delay.svg";
import InfoQuestion from "./client/info-question.svg";
import InfoWhite from "./client/info-white.svg";
import Radio from "./client/radio.svg";
import RadioSelected from "./client/radio-selected.svg";
import Star from "./client/star.svg";
import Copy from "./client/copy.svg";
import Headset from "./client/headset.svg";
import Hamburguer from "./client/hamburguer.svg";
import AirplaneDepart from "./client/airplane-depart.svg";
import AirplaneArrive from "./client/airplane-arrive.svg";
import Dropdown from "./client/dropdown.svg";
import ErrorState from "./client/error-state.svg";

import { IIconSet } from "@hopper-b2b/utilities";

export const lightModeIcons: IIconSet = {
  airplane: Airplane,
  airplaneDepart: AirplaneDepart,
  airplaneArrive: AirplaneArrive,
  airplaneCalendar: AirplaneCalendar,
  bell: Bell,
  blackLock: BlackLock,
  calendar: Calendar,
  card: Card,
  checkmarkFilled: CheckmarkFilled,
  checkmarkSimple: CheckmarkSimple,
  chevron: Dropdown,
  chevronLeft: ChevronLeft,
  error: ErrorState,
  filter: Filter,
  flights: Flights,
  info: Info,
  locationMarker: LocationMarker,
  lock: Lock,
  missedConnection: MissedConnection,
  oneway: OneWay,
  passenger: Passenger,
  roundtrip: RoundTrip,
  star: Star,
  close: Close,
  infoWhite: InfoWhite,
  greenShieldCheck: GreenShieldCheck,
  copy: Copy,
  cfar: Cfar,
  check: Check,
  flightDelay: FlightDelay,
  infoQuestion: InfoQuestion,
  vipIcon: GreenBolt,
  headset: Headset,
  radio: Radio,
  radioSelected: RadioSelected,
  guest: Guest,
  hamburguer: Hamburguer,
  dropdown: Dropdown,
};

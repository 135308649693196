import { ClientName, LandingScreen } from "@hopper-b2b/types";

export const getLandingScreen = (client?: ClientName): LandingScreen => {
  const path = window.location.pathname;

  if (path.includes("flights")) {
    return path.includes("search") ? "flight_search" : "flight_shop";
  }
  if (path.includes("cars")) {
    return path.includes("search") ? "cars_search" : "cars_list";
  }
  if (path.includes("hotels")) {
    return path.includes("search") ? "hotel_search" : "hotel_list";
  }
  if (path.includes("trips")) {
    return "my_trips";
  }
  if (path.includes("exchange")) {
    return "exchange";
  }
  if (path.includes("travel-wallet")) {
    return "travel_wallet";
  }
  if (path === "/" && client === ClientName.HOPPER) {
    return "home_page";
  }
  return "flight_search";
};

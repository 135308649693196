import { Link } from "@material-ui/core";
import clsx from "clsx";
import {
  ButtonHTMLAttributes,
  PropsWithChildren,
  ReactNode,
  RefObject,
} from "react";
import "./styles.scss";

export interface IActionLinkProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  content: ReactNode;
  label?: string;
  linkRef?: RefObject<HTMLButtonElement>;
  onClick?: (ev: any) => void;
  showTappableArea?: boolean;
  useStopPropagation?: boolean;
  ariaProps?: { [key: string]: unknown };
}

const ActionLink = ({
  className,
  content,
  disabled,
  label,
  linkRef,
  onClick,
  showTappableArea,
  useStopPropagation,
  ariaProps,
  id,
}: PropsWithChildren<IActionLinkProps>): JSX.Element => {
  return (
    <Link
      component="button"
      className={clsx(
        "action-link",
        { "show-tap-area": showTappableArea },
        className
      )}
      aria-label={label}
      disabled={disabled}
      onClick={(e: any) => {
        if (useStopPropagation && e.stopPropagation) {
          e.stopPropagation();
        }
        if (onClick) {
          onClick(e);
        }
      }}
      ref={linkRef}
      tabIndex={0}
      id={id}
      {...ariaProps}
    >
      <div className="content-container">{content}</div>
    </Link>
  );
};

export default ActionLink;

import { SupportStatus } from "@hopper-b2b/types";

import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { PartialParentContextWithVipSupport } from "./types";

export const guards = {
  hasVipSupportAddedAndEnabled: (
    ctx: PartialParentContextWithVipSupport,
    _event: unknown
  ) =>
    ctx.featureFlags.enableVipSupport &&
    ctx[ParentState.vipSupport].supportStatus === SupportStatus.vip,
};

export const GuardTypes = getObjectKeysAsObject({
  ...guards,
});

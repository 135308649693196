import { assign } from "xstate";
import { Product, ProductOpaqueValue } from "@b2bportal/purchase-api";
import { SupportStatus } from "@hopper-b2b/types";

import { getObjectKeysAsObject, setContextWithKey } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { getQuoteBreakdown } from "@checkout/states/Cart/CartQuote/selectors";
import { ToggleSupportStatusEvent } from "./events";
import {
  getVipSupportCartUpdateRequest,
  getVipSupportStatus,
} from "./selectors";
import { PartialParentContextWithVipSupport } from "./types";

export const actions = {
  addVipSupport: assign(
    (context: PartialParentContextWithVipSupport, _event: unknown) => {
      const filterOutVipSupportFromProducts = (
        context[ParentState.cartUpdate].addProducts ?? []
      ).filter((p: ProductOpaqueValue) => p.type !== Product.VipSupport);
      const request = getVipSupportCartUpdateRequest({ context });
      const newVipSupportProduct = {
        type: Product.VipSupport,
        value: request,
      };

      context[ParentState.cartUpdate].addProducts = [
        ...filterOutVipSupportFromProducts,
        newVipSupportProduct,
      ];
      return context;
    }
  ),
  toggleVipSupport: assign(
    (
      context: PartialParentContextWithVipSupport,
      event: ToggleSupportStatusEvent
    ) =>
      setContextWithKey(
        context,
        `${ParentState.vipSupport}.supportStatus`,
        event.supportStatus
      )
  ),
  removeVipProductFromCart: assign(
    (context: PartialParentContextWithVipSupport, _event: unknown) => {
      const isVipEnabled = context.featureFlags.enableVipSupport;
      if (!isVipEnabled) return context;

      const vipStatus = getVipSupportStatus({ context });
      if (vipStatus === SupportStatus.basic) {
        const quoteBreakdown = getQuoteBreakdown({ context });
        const vipSupportProduct = quoteBreakdown?.products.find(
          (p) => p.product.type === Product.VipSupport
        );
        if (!vipSupportProduct) {
          return context;
        }
        const vipProductToRemove = {
          shoppedProductId: vipSupportProduct.shoppedProductId,
          product: Product.VipSupport,
        };
        const productsToRemove = context[ParentState.cartUpdate].removeProducts;
        const filteredProductsToRemove = productsToRemove.filter(
          (p) => p.shoppedProductId !== vipSupportProduct.shoppedProductId
        );
        return setContextWithKey(
          context,
          `${ParentState.cartUpdate}.removeProducts`,
          [...filteredProductsToRemove, vipProductToRemove]
        );
      }

      return context;
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject({
  ...actions,
});

// Imported as MiniFlightCard
import { UIEvent, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { HalfSheetHashType, IFlightCardProps } from "@hopper-b2b/types";
import { removeTimezone } from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  FlightCardFields,
  Airline,
  PriceDisplay,
  RewardDisplay,
  StopSummary,
  FlightTiming,
  FlightDuration,
  FlightLocations,
} from "../../FlightCard/components/FlightCardFields";
import { IconComponent } from "../../IconComponent";
import { AlgomerchTags } from "../../AlgomerchTags";
import { IconName } from "../../Icon";
import "./styles.scss";

export const FlightCard = (props: IFlightCardProps) => {
  const {
    duration,
    currentPriceText,
    rewardText,
    originCode,
    destinationCode,
    departureTime,
    arrivalTime,
    airports,
    brandName,
    primaryCarrier,
    airlineName,
    additionalInfo,
    tags,
    tagsLimit,
    onClickTag,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const { t } = useI18nContext();

  const onClickHandler = useCallback(
    (e: UIEvent) => {
      // There may be a parent onclick event that would collapse
      // the flight card, don't let the parent know of the event.
      e.stopPropagation();
      history.push(
        `${location.pathname}${location.search}#${HalfSheetHashType.FLIGHT_DETAILS}`
      );
    },
    [history, location.pathname, location.search]
  );

  return (
    <FlightCardFields
      className={clsx("mini", additionalInfo.isExpanded && "is-expanded")}
    >
      <div className="flight-card-left">
        <Airline code={primaryCarrier} name={airlineName} iconOnly />
      </div>
      <div className="flight-card-center">
        <FlightTiming
          className="flight-card-center-row"
          departureTime={removeTimezone(departureTime)}
          arrivalTime={removeTimezone(arrivalTime)}
        />
        <div className="flight-card-airline-brand flight-card-center-row">
          {airlineName}
          {brandName ? ` - ${brandName}` : ""}
        </div>
        <div className="flight-card-center-row destination-duration">
          <FlightLocations
            originCode={originCode}
            destinationCode={destinationCode}
          />
          {" - "}
          <FlightDuration duration={duration} />
          {" ("}
          <StopSummary airports={airports} hideAirports={true} />
          {")"}
        </div>
        <div className="flight-card-center-row badges">
          <AlgomerchTags
            tags={tags}
            tagsLimit={tagsLimit}
            onClickTag={onClickTag}
          />
        </div>
        {additionalInfo.isExpanded ? (
          <div className="additional-flight-info">
            {additionalInfo.layoverText ? (
              <p>{additionalInfo.layoverText}</p>
            ) : null}
            {additionalInfo.plusDays && (
              <div className="next-day-container">
                <IconComponent
                  className="alert-icon"
                  name={IconName.CircleAlert}
                />
                <Typography variant="body2" className="plus-days">
                  {additionalInfo.plusDays}
                </Typography>
              </div>
            )}
            <div onClick={onClickHandler} className={"see-more-info"}>
              {t("seeMoreInfo")}
            </div>
          </div>
        ) : null}
      </div>
      <div className="flight-card-right">
        <PriceDisplay currentPrice={currentPriceText} />
        {rewardText ? <RewardDisplay reward={rewardText} /> : null}
      </div>
    </FlightCardFields>
  );
};

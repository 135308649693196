import {
  FlightItinerarySegment,
  OrderedFlight,
} from "@b2bportal/air-booking-api";
import {
  AccountPrice,
  FiatPrice,
  Flights,
  TrackingProperties,
} from "@b2bportal/air-shopping-api";
import { CipherText } from "@b2bportal/purchase-api";
import { Prices, RewardsPrice } from "../../common/rewards";
import { TripDetails } from "./trip-details";

export interface DisruptionFetchOfferRequest {
  tripId: string;
  fareId: string;
  numberOfPassengers: number;
}

export interface DisruptionPricing {
  accountSpecific: { [key: string]: AccountPrice };
  rewards?: { [key: string]: RewardsPrice };
  fiat: FiatPrice;
}

export interface DisruptionOfferQuote {
  members: number;
  quoteId: string;
  productType: DisruptionProductType;
  premiumAmount: DisruptionPricing;
  pricePerPax: DisruptionPricing;
  trackingProperties: TrackingProperties;
}

export interface DisruptionOffer {
  offerId: string;
  quotes: DisruptionOfferQuote[];
}

export enum DisruptionProductType {
  ScheduleChange = "ScheduleChangeDisruptionProtection",
  MissedConnection = "MissedConnectionDisruptionProtection",
  AirDisruptionProtection = "AirDisruptionProtection",
}

export enum DisruptionProtectionType {
  Delay = "delay",
  MissedConnection = "missedConnection",
}

export enum DisruptionOffersResponseEnum {
  Available = "Available",
  InvalidOffer = "InvalidOffer",
  NotAvailable = "NotAvailable",
}

export interface DisruptionFetchOfferFailure {
  DisruptionOffersResponse:
    | DisruptionOffersResponseEnum.InvalidOffer
    | DisruptionOffersResponseEnum.NotAvailable;
}

export interface DisruptionFetchOfferSuccess {
  DisruptionOffersResponse: DisruptionOffersResponseEnum.Available;
  offers: Array<DisruptionOffer>;
}

export type DisruptionFetchOfferResponse =
  | DisruptionFetchOfferFailure
  | DisruptionFetchOfferSuccess;

export enum DisruptionFetchOfferResponseEnum {
  Failure = "Failure",
  Success = "Success",
  RebookPlanSuccess = "RebookPlanSuccess",
  RebookPlanFailure = "RebookPlanFailure",
  RebookShopSuccess = "RebookShopSuccess",
  RebookShopFailure = "RebookShopFailure",
}

export interface DisruptionContractId {
  value: string;
}

export interface DisruptionRefundPlanRequest {
  contractId: string;
}

export interface DisruptionRefundFulfillRequest {
  itineraryId: string;
  contractId: string;
}

export interface DisruptionRefundPlanResponse {
  isEligible: boolean;
  reimbursementAmount: Prices;
  productType: DisruptionProductType;
}

export enum DisruptionRefundFulfillPollResponseEnum {
  Failure = "Failure",
  Pending = "Pending",
  Success = "Success",
  RefundFulfillPollFailure = "RefundFulfillPollFailure",
  RefundFulfillPollSuccess = "RefundFulfillPollSuccess",
  RefundFulfillPollPending = "RefundFulfillPollPending",
}

export enum DisruptionRefundFulfillResponseEnum {
  Failure = "Failure",
  Pending = "Pending",
  Success = "Success",
  RefundFulfillFailure = "RefundFulfillFailure",
  RefundFulfillSuccess = "RefundFulfillSuccess",
  RefundFulfillPending = "RefundFulfillPending",
}

export interface DisruptionRefundFulfillResponse {
  quoteId: string;
  trackingProperties: TrackingProperties;
}

export interface DisruptionRebookShopRequest {
  contractId: string;
  origin: string;
  destination: string;
}

export interface DisruptionRebookShopResponse {
  DisruptionRebookShopResponse: DisruptionFetchOfferResponseEnum;
  flights: Flights;
}

export interface DisruptionRebookPlanRequest {
  contractId: string;
}

export interface DisruptionRebookPlanResponse {
  DisruptionRebookPlanResponse: DisruptionFetchOfferResponseEnum;
  isEligible: boolean;
  productType: DisruptionProductType;
  slices: Array<{ segments: Array<FlightItinerarySegment> }>;
}

export enum DisruptionContractStatus {
  // TODO: Obsolete once BE has been updated
  REDEEM = "Redeem",
  ELIGIBLE = "Eligible",
  CONCLUDE = "Conclude",
  CANCEL = "Cancel",
  PURCHASE = "Purchase",
  // future-proof contract statuses
  ANY_EXERCISE_ELIGIBLE = "AnyExerciseEligible",
  EXERCISE_NOT_ELIGIBLE = "ExerciseNotEligible",
  REBOOK_ONLY_ELIGIBLE = "RebookOnlyEligible",
  ALREADY_EXERCISED = "AlreadyExercised",
}

export enum EventCovered {
  DELAYS = "Delays",
  MISSED_CONNECTIONS = "MissedConnections",
  CANCELLATIONS = "Cancellations",
}

export interface DisruptionRebookFulfillRequest {
  itineraryId: string;
  contractId: string;
}

export enum DisruptionFulfillErrorEnum {
  AuthorizeFailure = "AuthorizeFailure",
  CaptureFailure = "CaptureFailure",
  Empty = "Empty",
  FailedPreFulfillment = "FailedPreFulfillment",
  FailedProduct = "FailedProduct",
  FailedRefund = "FailedRefund",
  FraudFailure = "FraudFailure",
  InvalidStep = "InvalidStep",
  InvalidToken = "InvalidToken",
  LedgerError = "LedgerError",
  NotFoundInStore = "NotFoundInStore",
  Unknown = "Unknown",
}

export enum DisruptionFailureEnum {
  AlreadyExercised = "AlreadyExercised",
  ContractNotFound = "ContractNotFound",
  FulfillFailure = "FulfillFailure",
  InvalidProduct = "InvalidProduct",
  InvalidState = "InvalidState",
  QuoteFareNotFound = "QuoteFareNotFound",
  QuoteTripNotFound = "QuoteTripNotFound",
  Rejected = "Rejected",
  ServerError = "ServerError",
  ShopFailure = "ShopFailure",
  Unknown = "Unknown",
}

export interface DisruptionFulfillError {
  message: string;
  DisruptionFailure: DisruptionFulfillErrorEnum | DisruptionFailureEnum;
}

export interface DisruptionRebookPQRequest {
  contractId: string;
  tripId: string;
  fareId: string;
  emailAddress: string;
  phoneNumber: string;
}

// TODO: fix before merge

export interface Failure {
  reason: DisruptionFailureEnum;
  DisruptionRebookPQResponseEnum: DisruptionRebookPQResponseEnum.Failure;
}

export interface Success {
  token: CipherText;
  DisruptionRebookPQResponseEnum: DisruptionRebookPQResponseEnum.Success;
}

export enum DisruptionRebookPQResponseEnum {
  Failure = "Failure",
  Success = "Success",
  RebookPQSuccess = "RebookPQSuccess",
  RebookPQFailure = "RebookPQFailure",
}

export enum DisruptionRebookPollPQResponseEnum {
  Failure = "Failure",
  Pending = "Pending",
  Success = "Success",
  RebookPollPQFailure = "RebookPollPQFailure",
  RebookPollPQSuccess = "RebookPollPQSuccess",
  RebookPollPQPending = "RebookPollPQPending",
}

export enum RebookPollFulfillResponseEnum {
  Failure = "Failure",
  Pending = "Pending",
  Success = "Success",
  PollFulfillFailure = "PollFulfillFailure",
  PollFulfillPending = "PollFulfillPending",
  PollFulfillSuccess = "PollFulfillSuccess",
}

export enum RebookScheduleFulfillResponseEnum {
  Failure = "Failure",
  Pending = "Pending",
  Success = "Success",
  ScheduleFulfillFailure = "ScheduleFulfillFailure",
  ScheduleFulfillPending = "ScheduleFulfillPending",
  ScheduleFulfillSuccess = "ScheduleFulfillSuccess",
}

export type DisruptionRebookPQResponse =
  | ({ DisruptionRebookPQResponse: "Failure" } & Failure)
  | ({ DisruptionRebookPQResponse: "Success" } & Success);

export interface DisruptionRebookPollPQRequest {
  contractId: string;
  token: CipherText;
}

export interface DisruptionRebookPollPQError {
  DisruptionRebookPollPQResponse:
    | DisruptionRebookPollPQResponseEnum.Failure
    | DisruptionRebookPollPQResponseEnum.RebookPollPQFailure;
  reason: DisruptionRebookFailureReason;
}

export interface DisruptionRebookPollPQSuccess {
  DisruptionRebookPollPQResponse:
    | DisruptionRebookPollPQResponseEnum.Success
    | DisruptionRebookPollPQResponseEnum.RebookPollPQSuccess;
  tripDetails: TripDetails;
  token: CipherText;
}

export type DisruptionRebookPollPQResponse =
  | DisruptionRebookPollPQError
  | DisruptionRebookPollPQSuccess;

export interface DisruptionRebookFulfillResponseSuccess {
  flightOrderedProductId: string;
  rebookedFlight?: OrderedFlight;
  RebookPollFulfillResponse: RebookPollFulfillResponseEnum.Success;
  trackingProperties: TrackingProperties;
}

export interface DisruptionRebookFailureReason {
  DisruptionFailure: DisruptionFailureEnum;
  code: {
    DisruptionFulfillError: string;
  };
}

export interface DisruptionRebookFulfillResponseFailure {
  reason: DisruptionRebookFailureReason;
  RebookPollFulfillResponse: RebookPollFulfillResponseEnum.Failure;
  trackingProperties: TrackingProperties;
}

export type DisruptionRebookFulfillResponse =
  | DisruptionRebookFulfillResponseSuccess
  | DisruptionRebookFulfillResponseFailure;

export interface RebookScheduleFulfillRequest {
  contractId: string;
  token: CipherText;
}

export interface RebookPollFulfillRequest {
  contractId: string;
  token: CipherText;
}

import { Grid } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom-v5-compat";

import {
  ProtectedRoute,
  SessionInfo,
  UPDATE_SESSION,
  useAuthProvider,
} from "@hopper-b2b/hopper-auth";

import styles from "./App.module.scss";
import { Body } from "./components/Body";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { DEFAULT_LANG } from "./utils/constants";

export const App = () => {
  const { state, dispatch } = useAuthProvider();

  // Sending data to sift with each pathname/search load
  const location = useLocation();
  useEffect(() => {
    const _sift = (window._sift = window._sift || []);
    _sift.push(["_trackPageview"]);
  }, [location.pathname, location.search]);

  const onLogin = useCallback(
    (sessionInfo: SessionInfo) => {
      dispatch({
        type: UPDATE_SESSION,
        sessionInfo: sessionInfo,
      });
    },
    [dispatch]
  );

  return (
    <Grid
      container
      direction="column"
      className={clsx(styles.container, "app-container")}
    >
      <Header />
      <Grid item xs className={styles.bodyWrapper}>
        <Routes>
          <Route
            path="/*"
            element={
              <ProtectedRoute
                locale={DEFAULT_LANG}
                onLogin={onLogin}
                sessionInfo={state?.sessionInfo}
              >
                <Body />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Grid>
      <Grid item>
        <Footer />
      </Grid>
    </Grid>
  );
};

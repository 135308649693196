import React from "react";
import { useHistory } from "react-router";

export interface Path {
  pathname: string;
  search: string;
  hash: string;
}
export type RelativeRoutingType = "route" | "path";
export type To = string | Partial<Path>;
export interface NavigateOptions {
  replace?: boolean;
  state?: any;
  preventScrollReset?: boolean;
  relative?: RelativeRoutingType;
}
export interface NavigateFunction {
  (to: To, options?: NavigateOptions): void;
  (delta: number): void;
}

/**
 * @deprecated
 * Expose a similar api that uses router 5 History to make moving to router 6 easier
 */
export function useNavigate(): NavigateFunction {
  const history = useHistory();

  return React.useCallback(
    (to: To | number, options: NavigateOptions = {}) => {
      if (typeof to === "number") {
        history.go(to);
        return;
      }

      if (options.replace) {
        if (options.state) {
          history.replace(to, options.state);
        } else {
          history.replace(to);
        }
      } else {
        if (options.state) {
          history.push(to, options.state);
        } else {
          history.push(to);
        }
      }
    },
    [history]
  );
}

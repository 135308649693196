import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { PartialParentContextWithReview } from "./context";

export const guards = {
  visitedReview: (ctx: PartialParentContextWithReview) =>
    ctx[ParentState.review].visited,
  notVisitedReview: (ctx: PartialParentContextWithReview) =>
    !ctx[ParentState.review].visited,
};

export const GuardTypes = getObjectKeysAsObject(guards);

import {
  PassengersResponse,
  Person,
  TripPricingSummary,
} from "@b2bportal/air-booking-api";
import { PersonId } from "@hopper-b2b/types";

import { ChildPassengerInformationContext } from "./context";

export enum FlightPassengerEventTypes {
  ADD_PASSENGER = "ADD_PASSENGER",
  CLEAR_CURRENT_INFANT_ID = "CLEAR_CURRENT_INFANT_ID",
  CLEAR_PASSENGER_INFORMATION_ERROR = "CLEAR_PASSENGER_INFORMATION_ERROR",
  COMPLETE = "COMPLETE",
  DELETE_PASSENGER = "DELETE_PASSENGER",
  GO_IDLE = "GO_IDLE",
  NEXT = "NEXT",
  ON_INFANT_MODAL_CONTINUE = "ON_INFANT_MODAL_CONTINUE",
  OPEN_PASSENGER_FORM = "OPEN_PASSENGER_FORM",
  OPEN_PASSENGER_PICKER = "OPEN_PASSENGER_PICKER",
  OPEN_PASSPORT_FORM = "OPEN_PASSPORT_FORM",
  SELECT_PASSENGER = "SELECT_PASSENGER",
  SET_CURRENT_PASSENGER = "SET_CURRENT_PASSENGER",
  UPDATE_PASSENGER = "UPDATE_PASSENGER",
  PREVIOUS = "PREVIOUS",
  FETCH_TRIP_PRICING = "FETCH_TRIP_PRICING",
  OPEN_FORM_AND_SET_PASSENGER = "OPEN_FORM_AND_SET_PASSENGER",
}

export type ValidateUserPassengerEventType =
  | FlightPassengerEventTypes.ADD_PASSENGER
  | FlightPassengerEventTypes.CLEAR_CURRENT_INFANT_ID
  | FlightPassengerEventTypes.CLEAR_PASSENGER_INFORMATION_ERROR
  | FlightPassengerEventTypes.COMPLETE
  | FlightPassengerEventTypes.DELETE_PASSENGER
  | FlightPassengerEventTypes.GO_IDLE
  | FlightPassengerEventTypes.NEXT
  | FlightPassengerEventTypes.ON_INFANT_MODAL_CONTINUE
  | FlightPassengerEventTypes.OPEN_PASSENGER_FORM
  | FlightPassengerEventTypes.OPEN_PASSENGER_PICKER
  | FlightPassengerEventTypes.OPEN_PASSPORT_FORM
  | FlightPassengerEventTypes.SELECT_PASSENGER
  | FlightPassengerEventTypes.SET_CURRENT_PASSENGER
  | FlightPassengerEventTypes.UPDATE_PASSENGER
  | FlightPassengerEventTypes.PREVIOUS
  | FlightPassengerEventTypes.FETCH_TRIP_PRICING
  | FlightPassengerEventTypes.OPEN_FORM_AND_SET_PASSENGER;

export interface SelectPassengerEvent {
  type: FlightPassengerEventTypes.SELECT_PASSENGER;
  singleTravelerWorkflow?: boolean;
  passengerId: PersonId;
  data?: { id: string; value: Array<Person>; onUpdate?: boolean };
}
export interface AddPassengerEvent {
  type: FlightPassengerEventTypes.ADD_PASSENGER;
}
export interface UpdatePassengerEvent {
  type: FlightPassengerEventTypes.UPDATE_PASSENGER;
  person: Person;
}
export interface DeletePassengerEvent {
  type: FlightPassengerEventTypes.DELETE_PASSENGER;
  personId: PersonId;
}

export interface ValidateUserPassengerEvent {
  type: ValidateUserPassengerEventType;
  passengerId?: string;
  singleTravelerWorkflow?: boolean;
}

export interface SetCurrentPassengerEvent {
  type: FlightPassengerEventTypes.SET_CURRENT_PASSENGER;
  passenger: Person;
}

export interface OpenPassengerFormEvent {
  type: FlightPassengerEventTypes.OPEN_PASSENGER_FORM;
}

export interface OpenPassengerPickerEvent {
  type: FlightPassengerEventTypes.OPEN_PASSENGER_PICKER;
}

export interface OpenPassportFormEvent {
  type: FlightPassengerEventTypes.OPEN_PASSPORT_FORM;
}

export interface GoIdleEvent {
  type: FlightPassengerEventTypes.GO_IDLE;
}

export interface SetCurrentPassengerEvent {
  type: FlightPassengerEventTypes.SET_CURRENT_PASSENGER;
  passenger: Person;
}

export interface ClearCurrentInfantId {
  type: FlightPassengerEventTypes.CLEAR_CURRENT_INFANT_ID;
}

export interface OnInfantModalContinue {
  type: FlightPassengerEventTypes.ON_INFANT_MODAL_CONTINUE;
  seatType: "OwnSeat" | "OnLap";
}

export interface ClearPassengerInformationError {
  type: FlightPassengerEventTypes.CLEAR_PASSENGER_INFORMATION_ERROR;
}

export interface PassengerInformationCompleteEvent {
  type: FlightPassengerEventTypes.COMPLETE;
  passengerContext: ChildPassengerInformationContext;
}

export interface NextEventType {
  type: FlightPassengerEventTypes.NEXT;
}

export type ValidateAndFetchTripPricingEvent = [
  PassengersResponse,
  TripPricingSummary
];

export interface PreviousEventType {
  type: FlightPassengerEventTypes.PREVIOUS;
}

export interface FetchTripPricing {
  type: FlightPassengerEventTypes.FETCH_TRIP_PRICING;
  tripPricing: TripPricingSummary;
}

export interface OpenPassengerFormAndSetCurrentPassenger {
  type: FlightPassengerEventTypes.OPEN_FORM_AND_SET_PASSENGER;
  passenger: Person;
}

export type FlightPassengerEvents =
  | AddPassengerEvent
  | ClearCurrentInfantId
  | ClearPassengerInformationError
  | DeletePassengerEvent
  | GoIdleEvent
  | NextEventType
  | OnInfantModalContinue
  | OpenPassengerFormEvent
  | OpenPassengerPickerEvent
  | OpenPassportFormEvent
  | PassengerInformationCompleteEvent
  | SelectPassengerEvent
  | SetCurrentPassengerEvent
  | SetCurrentPassengerEvent
  | UpdatePassengerEvent
  | PreviousEventType
  | OpenPassengerFormAndSetCurrentPassenger;

import { ClientName, ExperimentsType } from "@hopper-b2b/types";
import { useCallback, useEffect, useState } from "react";
import { getEnvVariables } from "../envVariableHelpers";

type UberIcons =
  | "ACCESSIBILITY"
  | "AIRPLANE"
  | "AIRPLANEARRIVE"
  | "AIRPLANEDEPART"
  | "AIRPLANEFLY"
  | "ALERT"
  | "ALIGNCENTER"
  | "ALIGNLEFT"
  | "ALIGNRIGHT"
  | "ANDROID"
  | "ARROWCIRCULAR"
  | "ARROWCLOCKWISE"
  | "ARROWCLOCKWISEPILL"
  | "ARROWCOUNTERCLOCKWISE"
  | "ARROWDIFFERENTIAL"
  | "ARROWDIVERT"
  | "ARROWDOTRIGHT"
  | "ARROWDOWN"
  | "ARROWDROPOFF"
  | "ARROWEXPAND"
  | "ARROWLANECLOSED"
  | "ARROWLAUNCH"
  | "ARROWLEFT"
  | "ARROWLEFTDOWN"
  | "ARROWLEFTEXIT"
  | "ARROWLEFTRETURN"
  | "ARROWLEFTRIGHT"
  | "ARROWLEFTSHARP"
  | "ARROWLEFTSLIGHT"
  | "ARROWLEFTUP"
  | "ARROWMERGERIGHT"
  | "ARROWMERGEUP"
  | "ARROWPICKUP"
  | "ARROWRETURNDELIVERY"
  | "ARROWRETURNLEFT"
  | "ARROWRIGHT"
  | "ARROWRIGHTDOWN"
  | "ARROWRIGHTEXIT"
  | "ARROWRIGHTRETURN"
  | "ARROWRIGHTSHARP"
  | "ARROWRIGHTSLIGHT"
  | "ARROWRIGHTUP"
  | "ARROWROTATELEFT"
  | "ARROWROTATERIGHT"
  | "ARROWROUNDABOUTLEFT"
  | "ARROWROUNDABOUTLEFTRETURN"
  | "ARROWROUNDABOUTLEFTSHARP"
  | "ARROWROUNDABOUTLEFTSLIGHT"
  | "ARROWROUNDABOUTRIGHT"
  | "ARROWROUNDABOUTRIGHTRETURN"
  | "ARROWROUNDABOUTRIGHTSHARP"
  | "ARROWROUNDABOUTRIGHTSLIGHT"
  | "ARROWROUNDABOUTSTRAIGHT"
  | "ARROWTHREEDOWN"
  | "ARROWTHREEUP"
  | "ARROWTURNLEFT"
  | "ARROWTURNLEFTRIGHT"
  | "ARROWTURNRIGHT"
  | "ARROWUP"
  | "ARROWUPDOWN"
  | "ARROWZIGZAGDOWN"
  | "ARROWZIGZAGUP"
  | "BACKSPACE"
  | "BADGECHECKMARK"
  | "BADGEPERSON"
  | "BANK"
  | "BARCODE"
  | "BASKETBALL"
  | "BATTERYEMPTY"
  | "BATTERYFULL"
  | "BEACON"
  | "BED"
  | "BEHANCE"
  | "BELL"
  | "BELLOFF"
  | "BETA"
  | "BIKE"
  | "BIKEBASKET"
  | "BIKEBRAKES"
  | "BIKECHECK"
  | "BIKEFRAME"
  | "BIKEJUMP"
  | "BIKEJUMPCHECK"
  | "BIKEJUMPLOCK"
  | "BIKEPEDALS"
  | "BIKEPERSON"
  | "BIKERACK"
  | "BIKESEAT"
  | "BIKEULOCK"
  | "BLUETOOTH"
  | "BONE"
  | "BONVOY"
  | "BOOKCLOSED"
  | "BOOKOPEN"
  | "BOOKMARK"
  | "BOOKMARKOUTLINE"
  | "BOOSTCONSECUTIVE"
  | "BOOSTRIDE"
  | "BOWTIE"
  | "BOX"
  | "BOXHEART"
  | "BOXISOMETRIC"
  | "BOXISOMETRICPACKAGE"
  | "BRAIN"
  | "BREAD"
  | "BRIEFCASE"
  | "BUDDHISTTEMPLE"
  | "BUG"
  | "BURGER"
  | "BURST"
  | "BUS"
  | "BUSENTER"
  | "BUSEXIT"
  | "BUSSTOP"
  | "CABLECAR"
  | "CALCULATOR"
  | "CALENDAR"
  | "CAMERA"
  | "CAMERAFLIP"
  | "CARAUTONOMOUS"
  | "CARBUS"
  | "CARCHECK"
  | "CARCHECKFRONT"
  | "CARCLOCK"
  | "CARCURB"
  | "CARDOOR"
  | "CARDROPOFF"
  | "CARFRONT"
  | "CARFRONTPLUS"
  | "CARPICKUP"
  | "CARSEAT"
  | "CARSEATBABY"
  | "CARSEATPOWER"
  | "CARSIDE"
  | "CARSIDECRASH"
  | "CARSKIRACK"
  | "CARSUNROOF"
  | "CARTRANSMISSION"
  | "CARGOBIKE"
  | "CD"
  | "CENTER"
  | "CHARTBAR"
  | "CHARTBARASCENDING"
  | "CHARTFLAME"
  | "CHARTLINE"
  | "CHARTPIE"
  | "CHARTSCATTER"
  | "CHARTTREE"
  | "CHARTTREEHORIZONTAL"
  | "CHECKPLUS"
  | "CHECKBOX"
  | "CHECKBOXCHECKED"
  | "CHECKBOXMINUS"
  | "CHECKMARK"
  | "CHECKMARKDOUBLE"
  | "CHECKMARKSMALL"
  | "CHEESE"
  | "CHEFSKNIFE"
  | "CHEVRONDOWN"
  | "CHEVRONDOWNSMALL"
  | "CHEVRONDOWNTWO"
  | "CHEVRONDOWNUPSMALL"
  | "CHEVRONLEFT"
  | "CHEVRONLEFTRIGHT"
  | "CHEVRONLEFTSMALL"
  | "CHEVRONLEFTTWO"
  | "CHEVRONRIGHT"
  | "CHEVRONRIGHTSMALL"
  | "CHEVRONRIGHTTHREE"
  | "CHEVRONRIGHTTWO"
  | "CHEVRONUP"
  | "CHEVRONUPDOWN"
  | "CHEVRONUPDOWNSMALL"
  | "CHEVRONUPSMALL"
  | "CHURCHCROSS"
  | "CIRCLE"
  | "CIRCLECHECK"
  | "CIRCLECHECKUNSELECTED"
  | "CIRCLECHEVRONLEFT"
  | "CIRCLECHEVRONRIGHT"
  | "CIRCLEEXCLAMATIONPOINT"
  | "CIRCLEI"
  | "CIRCLEMINUS"
  | "CIRCLEPLUS"
  | "CIRCLEQUESTIONMARK"
  | "CIRCLESLASH"
  | "CIRCLESMALL"
  | "CIRCLESTOP"
  | "CIRCLESTOPCROSS"
  | "CIRCLEX"
  | "CLIPBOARD"
  | "CLIPBOARDPENCIL"
  | "CLOCHE"
  | "CLOCK"
  | "CLOCKADD"
  | "CLOCKARROWRIGHT"
  | "CLOCKCROSS"
  | "COATHANGER"
  | "COINSTAR"
  | "COINTIP"
  | "COINSNUMBER"
  | "COMPASS"
  | "COMPASSNORTH"
  | "COMPOST"
  | "COMPUTERCHIP"
  | "CONCERTHALL"
  | "CONFLUENCE"
  | "CONSOLE"
  | "CONTACTS"
  | "COPTER"
  | "COPY"
  | "CREDIT"
  | "CREDITCARD"
  | "CREDITCARDFRONT"
  | "CRISIS"
  | "CRUISECONTROL"
  | "CUPCOFFEE"
  | "CUPCAKE"
  | "CURRENCYEUR"
  | "CURRENCYGBP"
  | "CURRENCYUBERMONEY"
  | "CURRENCYUSD"
  | "DASHCAM"
  | "DASHBOARD"
  | "DATABASE"
  | "DELIVERYBAG"
  | "DELIVERYBAGALERT"
  | "DELIVERYBAGCHECK"
  | "DELIVERYBAGMOVE"
  | "DELIVERYBAGREMOVE"
  | "DELIVERYBAGTWO"
  | "DESKCHAIR"
  | "DESKTOP"
  | "DIAMOND"
  | "DIAMONDEMPTY"
  | "DIAMONDHALF"
  | "DOCUMENT"
  | "DOLLY"
  | "DOOR"
  | "DOTGRID"
  | "DOWNLOAD"
  | "DRIBBBLE"
  | "DRIVE4WD"
  | "DRIVEAWD"
  | "DUMBBELL"
  | "EAR"
  | "EGGS"
  | "EIGHTEENPLUS"
  | "ELEVATORDOWN"
  | "ELEVATORPEOPLE"
  | "ELEVATORUP"
  | "ENTER"
  | "ENVELOPE"
  | "ENVELOPEOPEN"
  | "EQUALIZER"
  | "ESCALATORDOWN"
  | "ESCALATORUP"
  | "EXIT"
  | "FACEHAPPY"
  | "FACEMASK"
  | "FACENEUTRAL"
  | "FACESAD"
  | "FACESCAN"
  | "FACEVERYHAPPY"
  | "FACEVERYSAD"
  | "FACEBOOK"
  | "FAN"
  | "FEED"
  | "FERRY"
  | "FERRYENTER"
  | "FERRYEXIT"
  | "FESTIVALTENT"
  | "FIGMA"
  | "FILTER"
  | "FINGERSWIPE"
  | "FINGERPRINT"
  | "FIRE"
  | "FISH"
  | "FLAG"
  | "FLAGSTICK"
  | "FLASHLIGHT"
  | "FLASK"
  | "FLATTRUCK"
  | "FLOPPYDISK"
  | "FOLDER"
  | "FOLDEROPEN"
  | "FREIGHT"
  | "FUELDIESEL"
  | "FUELEV"
  | "FUELPUMP"
  | "FULLSCREEN"
  | "FULLSCREENEXIT"
  | "FUNICULAR"
  | "GAMECONTROLLER"
  | "GEAR"
  | "GEARS"
  | "GEOFENCE"
  | "GIFTBOX"
  | "GITHUB"
  | "GLASSBEER"
  | "GLASSCOCKTAIL"
  | "GLASSWINE"
  | "GLOBE"
  | "GONDOLA"
  | "GRAIN"
  | "GREENLIGHTHUB"
  | "GRID"
  | "HALAL"
  | "HAMMER"
  | "HANDPOLYGON"
  | "HANDSTOP"
  | "HANDTIP"
  | "HANDWAVE"
  | "HANDLEBARBELL"
  | "HEADSET"
  | "HEART"
  | "HEARTOUTLINE"
  | "HELMET"
  | "HIDE"
  | "HINDUTEMPLE"
  | "HOME"
  | "HOURGLASS"
  | "IOS"
  | "ICECREAM"
  | "IDCHECK"
  | "INSTAGRAM"
  | "ISLANDTREE"
  | "JOBBUSSER"
  | "JOBDISHWASHER"
  | "KEY"
  | "KEYBOARD"
  | "KEYLESS"
  | "KEYSCAR"
  | "LANDMARK"
  | "LAPTOP"
  | "LAYERS"
  | "LEAF"
  | "LEATHER"
  | "LIFEBUOY"
  | "LIGHTBULB"
  | "LIGHTNING"
  | "LIGHTNINGCROSS"
  | "LIGHTNINGPLUS"
  | "LIGHTS"
  | "LINK"
  | "LINKUNLINK"
  | "LINKEDIN"
  | "LIST"
  | "LISTNUMBERED"
  | "LISTREORDER"
  | "LOCATIONMARKER"
  | "LOCATIONMARKEROFF"
  | "LOCATIONPIN"
  | "LOCATIONRETURN"
  | "LOCATIONSHARE"
  | "LOCK"
  | "LOCKOPEN"
  | "LOCKPRIVACY"
  | "LUGGAGE"
  | "MASKS"
  | "MASSAGECHAIR"
  | "MEAT"
  | "MEDAL"
  | "MEDIAPLAYER"
  | "MEGAPHONE"
  | "MICROMOBILITY"
  | "MICROPHONE"
  | "MICROPHONE2"
  | "MICROPHONEOFF"
  | "MICROPHONESOUND"
  | "MINUS"
  | "MINUSSMALL"
  | "MISSINGGLYPH"
  | "MONEY"
  | "MOPED"
  | "MOSQUE"
  | "MOTHERSROOM"
  | "MOTORCYCLE"
  | "MULTISTOP"
  | "MUSEUM"
  | "MUSICNOTE"
  | "NAVIGATERIGHTUP"
  | "NAVIGATEUP"
  | "NEM"
  | "NETWORK"
  | "NOPARKING"
  | "NOODLES"
  | "OCTAGONALSTAR"
  | "OFFLINE"
  | "OTHER"
  | "PACKAGESTACKED"
  | "PANELRIGHT"
  | "PAPERAIRPLANE"
  | "PAPERFOLD"
  | "PAPERCLIP"
  | "PARKING"
  | "PEANUT"
  | "PENCIL"
  | "PENCILLINE"
  | "PERCENTAGE"
  | "PERSON"
  | "PERSONADD"
  | "PERSONBAG"
  | "PERSONCHECK"
  | "PERSONCONSTRUCTIONWORKER"
  | "PERSONCROSS"
  | "PERSONDRIVER"
  | "PERSONEMERGENCYCONTACT"
  | "PERSONFEMALE"
  | "PERSONGROUP"
  | "PERSONMALE"
  | "PERSONMULTIPLE"
  | "PERSONSCAN"
  | "PERSONSHARE"
  | "PERSONSPEECHBUBBLE"
  | "PERSONUNACCOMPANIEDMINOR"
  | "PERSONWALK"
  | "PET"
  | "PHONE"
  | "PHONEARROWLEFT"
  | "PHONEARROWRIGHT"
  | "PHONEASTERISK"
  | "PHONECROSS"
  | "PHONEPIN"
  | "PHONESPEECHBUBBLE"
  | "PHONEVIBRATE"
  | "PHOTOLANDSCAPE"
  | "PHOTOS"
  | "PILL"
  | "PIN"
  | "PINENTRY"
  | "PININPUT"
  | "PIZZA"
  | "PLAYERINCREMENTALFORWARD"
  | "PLAYERNEXT"
  | "PLAYERPAUSE"
  | "PLAYERPLAY"
  | "PLAYERPREVIOUS"
  | "PLUS"
  | "PLUSMINUS"
  | "PLUSSMALL"
  | "PODIUM"
  | "POWER"
  | "PRESENTATIONSCREEN"
  | "PRINTER"
  | "QRCODE"
  | "RADIOBUTTONSELECTED"
  | "RADIOBUTTONUNSELECTED"
  | "RADIOACTIVE"
  | "RAILS"
  | "RECEIPT"
  | "RECYCLE"
  | "REPORT"
  | "REPORTMAP"
  | "RESIZE"
  | "RESTAURANT"
  | "RESTROOM"
  | "ROAD"
  | "ROCKET"
  | "ROUTE"
  | "ROUTEFLAG"
  | "SATELLITE"
  | "SCANMASK"
  | "SCANPLAY"
  | "SCANQRCODE"
  | "SCHOOL"
  | "SCISSORS"
  | "SCOOTER"
  | "SCOOTERCHECK"
  | "SCOOTERFOOTBREAK"
  | "SCOOTERPERSON"
  | "SCREENRESIZE"
  | "SEARCH"
  | "SEARCHBROWSE"
  | "SEATBELT"
  | "SEVENELEVEN"
  | "SHAREANDROID"
  | "SHAREIOS"
  | "SHELLFISH"
  | "SHIELD"
  | "SHIELDCHECK"
  | "SHOP"
  | "SHOPPINGBASKET"
  | "SHOPPINGCART"
  | "SHOW"
  | "SIGNAL"
  | "SIRENLIGHT"
  | "SKI"
  | "SKIBOOTS"
  | "SLACK"
  | "SLIDERS"
  | "SLIDERSFILLED"
  | "SMARTPHONE"
  | "SNAPCHAT"
  | "SNOWFLAKE"
  | "SOUND"
  | "SOUNDOFF"
  | "SOY"
  | "SPEECHBUBBLE"
  | "SPEECHBUBBLETHREEDOTS"
  | "SPEECHBUBBLES"
  | "SPEEDOMETER"
  | "SQUARE"
  | "SQUARECHEVRONUPDOWN"
  | "SQUARESMALL"
  | "SQUARESTOP"
  | "STADIUM"
  | "STAIRCASEDOWN"
  | "STAIRCASEUP"
  | "STAIRS"
  | "STAR"
  | "STAROUTLINE"
  | "STEERINGWHEEL"
  | "STETHOSCOPE"
  | "STOP"
  | "STOPLEFT"
  | "STOPRIGHT"
  | "STOPWATCH"
  | "STOREFRONT"
  | "SUBWAY"
  | "SUBWAYENTER"
  | "SUBWAYEXIT"
  | "SUNSET"
  | "SURGE"
  | "SUSHI"
  | "SYNAGOGUE"
  | "TABLET"
  | "TACO"
  | "TAG"
  | "TEXTBOLD"
  | "TEXTITALIC"
  | "TEXTSTRIKETHROUGH"
  | "TEXTUNDERLINE"
  | "THERMOMETER"
  | "THREEDOTS"
  | "THREEDOTSHORIZONTAL"
  | "THREEDOTSHORIZONTALCIRCLE"
  | "THREELINES"
  | "THUMBDOWN"
  | "THUMBDOWNOUTLINE"
  | "THUMBUP"
  | "THUMBUPOUTLINE"
  | "TICKET"
  | "TIREFLAT"
  | "TOILET"
  | "TOLLGATE"
  | "TOLLGATECROSS"
  | "TRAFFIC"
  | "TRAFFICCONE"
  | "TRAFFICLIGHTS"
  | "TRAILER"
  | "TRAIN"
  | "TRAINENTER"
  | "TRAINEXIT"
  | "TRAM"
  | "TRAMENTER"
  | "TRAMEXIT"
  | "TRANSLATE"
  | "TRASH"
  | "TRASHCAN"
  | "TREE"
  | "TREE2"
  | "TREENUT"
  | "TROPHY"
  | "TRUCK"
  | "TRUCKCHECK"
  | "TRUCKENTER"
  | "TRUCKEXIT"
  | "TURTLE"
  | "TWITTER"
  | "TWOLINES"
  | "UBERONE"
  | "UBERONEACCOUNT"
  | "UBERPRO"
  | "ULTRASOUND"
  | "ULTRASOUNDOFF"
  | "UMBRELLA"
  | "UPLOAD"
  | "VEGAN"
  | "VIDEOCAMERA"
  | "VIDEOCAMERA2"
  | "VIDEOCAMERAARROW"
  | "VIDEOCAMERAOFF"
  | "WALLET"
  | "WAREHOUSE"
  | "WARNINGROUTE"
  | "WEATHERCLOUDS"
  | "WEATHERFOG"
  | "WEATHERLIGHTNING"
  | "WEATHERMOON"
  | "WEATHERPARTLYCLOUDY"
  | "WEATHERRAIN"
  | "WEATHERSLEET"
  | "WEATHERSNOW"
  | "WEATHERSUN"
  | "WEATHERWIND"
  | "WHATSAPP"
  | "WHEELCHAIR"
  | "WHEELS"
  | "WIFI"
  | "WIFISTRENGTH1"
  | "WIFISTRENGTH2"
  | "WINDMILL"
  | "WINDOWSPOWER"
  | "WINDOWSTINTED"
  | "WINE"
  | "WING"
  | "WRENCH"
  | "X"
  | "XSMALL"
  | "YIELD"
  | "YOUTUBE";

export interface CustomHeaderButton {
  icon?: UberIcons;
  text?: string;
  badgeNumber?: number;
  action: HeaderAction;
}

export interface HeaderPayload {
  title: string;
  customButtons?: Array<CustomHeaderButton>;
}

export interface HeaderAction {
  name: string;
  callback: () => void;
}

export const useUberBridge = () => {
  const [actions, setActions] = useState<Map<string, () => void>>(new Map());

  const triggerAction = useCallback(
    (event: { data: { type: string } }) => {
      const func = actions.get(event?.data?.type);
      if (func) {
        func();
      }
    },
    [actions]
  );

  useEffect(() => {
    window.addEventListener("message", triggerAction);
    return () => {
      setActions(new Map());
      window.removeEventListener("message", triggerAction);
    };
  }, [triggerAction]);

  const setHeader = (payload: HeaderPayload) => {
    try {
      const messagePayload = {
        title: payload.title,
        customButtons: payload.customButtons?.map(
          ({ icon, text, action, badgeNumber }) => {
            setActions(actions.set(action.name, action.callback));
            return {
              icon: icon,
              text: text,
              action: action.name,
              badgeNumber: badgeNumber,
            };
          }
        ),
      };
      window.parent.postMessage(
        {
          type: "CUSTOMIZE_HEADER",
          payload: messagePayload,
        },
        import.meta.env["VITE_UBER_URL"]
      );
    } catch (e) {
      // console.warn(e);
    }
  };

  const postEvent = (messageType: string, payload?: unknown) => {
    try {
      if (getEnvVariables("clientName") === ClientName.UBER) {
        window.parent.postMessage(
          {
            type: "METRIC",
            payload: {
              metricType: messageType,
              payload: payload,
            },
          },
          import.meta.env["VITE_UBER_URL"]
        );
      }
    } catch (e) {
      //console.log(e);
    }
  };

  const filterExperiments = (experiments: ExperimentsType): ExperimentsType => {
    return {
      partnerExperiments: experiments.partnerExperiments,
      "air-cfar-offer": experiments["uber-air-cfar-offer"],
      "air-chfar-offer": experiments["uber-air-chfar-offer"],
      "air-fintech-selection": experiments["uber-air-fintech-selection"],
      "air-hide-fare-details": experiments["uber-air-hide-fare-details"],
      "air-hide-itinerary-review":
        experiments["uber-air-hide-itinerary-review"],
    };
  };

  return { setHeader, postEvent, filterExperiments };
};

import { trackEvent } from "@hopper-b2b/api";
import { ADD_TRAVELER } from "@hopper-b2b/types";

export const trackNewTraveler = () =>
  new Promise((resolve, _reject) => {
    trackEvent({
      eventName: ADD_TRAVELER,
      properties: {},
    })
      .then(() => resolve(null))
      .catch(() => resolve(null));
  });

import { State } from "xstate";
import { FiatPrice, Product } from "@b2bportal/purchase-api";
import { SupportStatus } from "@hopper-b2b/types";

import { ParentState } from "@checkout/types";
import { cartQuoteSelectors } from "@checkout/states/Cart";
import { PartialParentContextWithVipSupport } from "./types";

type VipSupportStateType = State<PartialParentContextWithVipSupport>;
type VipSupportStateTypeWithoutValue = Pick<VipSupportStateType, "context">;

export const getVipSupportStatus = (
  state: VipSupportStateType | VipSupportStateTypeWithoutValue
): SupportStatus => state.context[ParentState.vipSupport].supportStatus;

// TODO: Update when model is confirmed
export const getVipSupportCartUpdateRequest = (
  state: VipSupportStateTypeWithoutValue
) => {
  // TODO: Update to handle multiple products or split it out
  // const selectedTrip = FlightSelectors.getSelectedTripParent(state);
  const tripId = "";
  return { tripId };
};

export const getVipSupportPricing = (
  state: VipSupportStateTypeWithoutValue
): FiatPrice | null => {
  const quoteBreakdown = cartQuoteSelectors.getQuoteBreakdown(state);
  const vipProduct = quoteBreakdown?.products?.find?.(
    (product) => product.product.type === Product.VipSupport
  );

  if (!vipProduct) return null;
  return vipProduct.product.value.price.fiat;
};

export const isVipSupportEnabled = (state: VipSupportStateTypeWithoutValue) =>
  state.context.featureFlags.enableVipSupport &&
  state.context.vipSupport.supportStatus === SupportStatus.vip;

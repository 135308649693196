import {
  Branding,
  ChatAvailability,
  ClientName,
  ContactSupportConfig,
  FlightShopCardType,
  I18nNamespace,
} from "@hopper-b2b/types";

const contactSupport: ContactSupportConfig = {
  chatAvailability: ChatAvailability.None,
  displayIntlSupportNumber: "+1-347-695-4555",
  displaySupportNumber: "+1-833-933-4671",
  displaySupportNumberPhonewords: "+1-833-933-HOP1",
  intlSupportNumber: "13476954555",
  supportNumber: "18339334671",
};

export const branding: Branding = {
  contactSupport,
  merchantPayments: "",
  clientName: ClientName.HOPPER,
  currencies: [
    {
      locale: "en-US",
      code: "USD",
      symbol: "$",
    },
  ],
  supportedLanguages: [
    {
      key: "es",
      name: "Español",
      label: "Español",
    },
    { key: "en", name: "English", label: "English" },
    { key: "fr", name: "Français", label: "Français" },
  ],
  flightShopCardType: FlightShopCardType.MINI,
  [I18nNamespace.translation]: {
    en: {
      portalName: "Hopper Web",
      urls: {
        termsAndConditions: "https://hopper.com/legal/terms-and-conditions",
        privacyPolicy: "https://hopper.com/legal/privacy-policy",
        priceFreezeTerms: "https://hopper.com/legal/Air-Price-Freeze",
        missedConnectionTerms:
          "https://hopper.com/legal/Missed-Connection-Guarantee",
      },
      preferredCountryCode: "US",
      preferredAreaCode: "+1",
      calendarMonthFormat: "MMMM",
      timeFormat: "hh:mm A",
    },
    es: {
      portalName: "Web de Hopper",
      urls: {
        termsAndConditions:
          "https://www.hopper.com/es/legal/terms-and-conditions/",
        privacyPolicy: "https://hopper.com/es/legal/privacy-policy",
        priceFreezeTerms: "https://hopper.com/es/legal/Air-Price-Freeze",
        missedConnectionTerms:
          "https://hopper.com/es/legal/Missed-Connection-Guarantee",
      },
      preferredCountryCode: "US",
      preferredAreaCode: "+1",
      calendarMonthFormat: "MMMM",
      timeFormat: "hh:mm A",
    },
    fr: {
      portalName: "Hopper sur le Web",
      urls: {
        termsAndConditions:
          "https://www.hopper.com/fr/legal/terms-and-conditions/",
        privacyPolicy: "https://hopper.com/fr/legal/privacy-policy",
        priceFreezeTerms: "https://hopper.com/fr/legal/Air-Price-Freeze",
        missedConnectionTerms:
          "https://hopper.com/fr/legal/Missed-Connection-Guarantee",
      },
      supportNumber: "18339334671",
      displaySupportNumber: "+1-833-933-4671",
      displaySupportNumberPhonewords: "+1-833-933-HOP1",
      intlSupportNumber: "13476954555",
      displayIntlSupportNumber: "+1-347-695-4555",
      preferredCountryCode: "US",
      preferredAreaCode: "+1",
      calendarMonthFormat: "MMMM",
      timeFormat: "hh:mm A",
    },
  },
};

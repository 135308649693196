import { useRef, useState } from "react";
import { Popover, Box } from "@material-ui/core";
import clsx from "clsx";
import { ActionLink } from "../../../ActionLink";
import "./styles.scss";

export interface IHeaderMenuDropdownBaseProps {
  className?: string;
  selected?: boolean;
  content: JSX.Element;
  dropdownContent?: JSX.Element | null;
  disabled?: boolean;
}

const HeaderMenuDropdown = (props: IHeaderMenuDropdownBaseProps) => {
  const { className, selected, content, disabled, dropdownContent } = props;

  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(
    null
  );

  const menuPositionRef = useRef(null);
  const togglePopover = () => {
    setAnchorEl(menuPositionRef.current);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={clsx("header-dropdown", className)}>
      <ActionLink
        className={clsx("header-dropdown-link", {
          selected: selected,
          open,
        })}
        content={content}
        onClick={togglePopover}
        disabled={disabled}
        ariaProps={{
          role: "button",
          "aria-haspopup": true,
          "aria-expanded": open || undefined,
          "aria-controls": "menu-content",
        }}
      />
      <div className="header-dropdown-menu-position" ref={menuPositionRef} />
      <Popover
        className="header-dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        elevation={1}
      >
        <ul
          className="header-dropdown-menu-content"
          role="menu"
          aria-labelledby="menubutton"
          id="menu-content"
        >
          {dropdownContent}
        </ul>
      </Popover>
    </Box>
  );
};
export default HeaderMenuDropdown;

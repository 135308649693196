import { NETWORK_CALL_FAILED, IApiConfig } from "@hopper-b2b/types";
import { trackEvent } from "../tracking/trackEvent";
import { axiosInstance } from "../AxiosInterceptor";
import {
  GetSeatMapRequest,
  GetSeatMapResponse,
} from "@b2bportal/air-seats-api";

const END_POINT = "/api/v0/seats/seatMap";

export const fetchSeatMap = (
  req: GetSeatMapRequest,
  apiConfig?: IApiConfig
): Promise<GetSeatMapResponse> =>
  new Promise((resolve, reject) => {
    axiosInstance
      .post(END_POINT, req)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        trackEvent(
          {
            eventName: NETWORK_CALL_FAILED,
            properties: {
              url: window.location.pathname,
            },
          },
          apiConfig
        );
        reject(e);
      });
  });

import { Suspense, lazy, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom-v5-compat";

import { useExperiment } from "@hopper-b2b/experiments";
import { useAuthProvider } from "@hopper-b2b/hopper-auth";
import {
  PATH_FLIGHTS,
  PATH_FLIGHTS_SEARCH,
  PATH_HOME,
} from "@hopper-b2b/common-utils";
import {
  B2B_PORTAL_UNAUTHORIZED_PATH,
  type FlightClientAssetProps,
} from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";

import styles from "./Body.module.scss";
import { lightModeIcons } from "../../assets/icons";
import { FEATURE_FLAGS } from "../../Root";
import { Maintenance } from "../Maintenance";
import { LoadingScreenWithProgress } from "../LoadingScreenWithProgress";
import { Icon, IconName } from "../Icon";
import { TermsAndConditions } from "../TermsAndConditions";
import { PATH_TERMS_HTS, PATH_TERMS_MCP } from "../../utils/constants";
import { MissedConnectionsTermsAndConditions } from "../MissedConnectionsTermsAndConditions";
import { useTrackLaunchedAppEvent } from "./useTrackLaunchedAppEvent";

const AirRouteComponent = lazy(() => import("./AirRouteComponent"));
const FlightSearchRoute = lazy(() => import("./FlightSearchRoute"));

export const Body = () => {
  const { state } = useAuthProvider();
  const sessionInfo = state?.sessionInfo;

  const isInMaintenance = useExperiment(FEATURE_FLAGS.MAINTENANCE);
  const enableTopFlights = useExperiment(FEATURE_FLAGS.ENABLE_TOP_FLIGHTS);
  const enableDisplayBasicFareOnly = useExperiment(
    FEATURE_FLAGS.EXPIREMENT_ZERO_FARE_TARIFA_BASE
  );

  const flightAppClientAssets = useMemo(
    () => ({
      sessionInfo: sessionInfo,
      assets: lightModeIcons,
    }),
    [sessionInfo]
  );

  useTrackLaunchedAppEvent(sessionInfo);

  if (!sessionInfo) return null;

  return (
    <main className={styles.main}>
      {isInMaintenance ? (
        <Maintenance />
      ) : (
        <Routes>
          <Route
            path={PATH_HOME}
            element={
              <Suspense fallback={<Loading />}>
                <FlightSearchRoute />
              </Suspense>
            }
          />
          <Route
            path={PATH_FLIGHTS}
            element={
              <Suspense fallback={<Loading />}>
                <FlightSearchRoute />
              </Suspense>
            }
          />
          <Route
            path={PATH_FLIGHTS_SEARCH}
            element={
              <Suspense fallback={<Loading />}>
                <FlightSearchRoute />
              </Suspense>
            }
          />
          <Route
            path={PATH_TERMS_HTS}
            element={
              <Suspense fallback={<Loading />}>
                <TermsAndConditions />
              </Suspense>
            }
          />
          <Route
            path={PATH_TERMS_MCP}
            element={
              <Suspense fallback={<Loading />}>
                <MissedConnectionsTermsAndConditions />
              </Suspense>
            }
          />
          {/* we want to render an empty div so ExpiredSession modal shows */}
          <Route path={B2B_PORTAL_UNAUTHORIZED_PATH} element={<div />} />
          <Route
            path={PATH_FLIGHTS + "*"}
            element={
              <Suspense fallback={<Loading />}>
                <AirRouteComponent
                  clientAssets={
                    flightAppClientAssets as unknown as FlightClientAssetProps
                  }
                  featureFlags={{
                    enableAirShopV4: true,
                    enableMergeSortingDrodpdownWithFilters: true,
                    enableRemoveRecommendSort: true,
                    enableSetMaxPriceWhenChoosingReturn: true,
                    enableDisplayBasicFareOnly,
                    enableTopFlights,
                  }}
                />
              </Suspense>
            }
          />
          <Route path={"*"} element={<Navigate to={PATH_HOME} />}></Route>
        </Routes>
      )}
    </main>
  );
};

const Loading = () => {
  const { t } = useI18nContext();

  return (
    <LoadingScreenWithProgress
      title={t("loadingFlightDetails")}
      icon={<Icon name={IconName.MultiPlanes} className="loading-icon" />}
    />
  );
};

import {
  LodgingMediaAssetEnum,
  LodgingMediaAsset,
} from "@b2bportal/lodging-api";

enum ImageState {
  Success,
  Failed,
  InProgress,
}

const checkIfReadyToSetArray = (
  currentCheckState: { curIndex: number; isFirstValidImageLoaded: boolean },
  imageStateArray: Array<ImageState>
) => {
  while (
    !currentCheckState.isFirstValidImageLoaded &&
    currentCheckState.curIndex < imageStateArray.length &&
    imageStateArray[currentCheckState.curIndex] !== ImageState.InProgress
  ) {
    if (imageStateArray[currentCheckState.curIndex] === ImageState.Success) {
      currentCheckState.isFirstValidImageLoaded = true;
    } else {
      currentCheckState.curIndex += 1;
    }
  }
};

export const getImagesArray = (
  mediaAssets: LodgingMediaAsset[],
  callBack: (images: string[]) => void
) => {
  const currentCheckState = { curIndex: 0, isFirstValidImageLoaded: false };
  const imageAssets = mediaAssets.filter((mediaAsset) => {
    return mediaAsset.LodgingMediaAsset !== LodgingMediaAssetEnum.Video;
  });
  const imagesArray = new Array<string>(imageAssets.length);
  const imageStateArray = new Array<ImageState>(imageAssets.length).fill(
    ImageState.InProgress
  );

  imageAssets.forEach((img, ind) => {
    const image = new Image();
    image.onload = function () {
      // When it's a blank image, both width and height have a value of 1
      if (image.width > 1 && image.height > 1) {
        imagesArray[ind] = img.url;
        imageStateArray[ind] = ImageState.Success;

        checkIfReadyToSetArray(currentCheckState, imageStateArray);

        if (
          currentCheckState.isFirstValidImageLoaded ||
          currentCheckState.curIndex >= imageAssets.length
        ) {
          callBack([...imagesArray]);
        }
      }
    };
    image.onerror = function () {
      imageStateArray[ind] = ImageState.Failed;
    };

    image.src = img.url;
  });
};

export const getImageUrlsArray = (
  mediaAssets: LodgingMediaAsset[]
): string[] => {
  return mediaAssets
    .filter(
      (mediaAsset) =>
        mediaAsset.LodgingMediaAsset !== LodgingMediaAssetEnum.Video
    )
    .map((img) => img.url);
};

// load images synchronously
const loadImage = async (img: HTMLImageElement) => {
  return new Promise<boolean>((resolve, _) => {
    img.onload = async () => {
      if (img.width > 1 && img.height > 1) {
        resolve(true);
        return;
      }
      resolve(false);
    };
    img.onerror = async () => {
      resolve(false);
    };
  });
};

export enum CarouselMoveDirection {
  Left,
  Right,
}

export const getNextValidIndex = async (
  imagesArray: string[],
  startingIndex: number,
  moveDirection: CarouselMoveDirection
): Promise<number | null> => {
  if (imagesArray.length === 0) {
    return null;
  }

  let i = startingIndex;
  let hasValidImage = false;

  do {
    const image = new Image();
    image.src = imagesArray[i];
    const isValidImage = await loadImage(image);

    if (isValidImage) {
      hasValidImage = true;
      return i;
    }

    i =
      moveDirection === CarouselMoveDirection.Right
        ? (i + 1) % imagesArray.length
        : (i - 1 + imagesArray.length) % imagesArray.length;
  } while (i !== startingIndex);

  // if no valid image was found, return null
  if (!hasValidImage) {
    return null;
  }

  return i;
};

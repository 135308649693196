import {
  COLOR_THEMES,
  installCssConfig,
  getDarkModePreferred,
} from "@hopper-b2b/themes";
import { useWindowSize } from "@hopper-b2b/utilities";
import {
  CssBaseline,
  StylesProvider,
  ThemeProvider,
  createGenerateClassName,
} from "@material-ui/core";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { hopperDarkVariables, muiDarkTheme } from "./darkTheme";
import { hopperVariables, muiTheme } from "./defaultTheme";

const generateClassName = createGenerateClassName({
  productionPrefix: "ptBaseModule",
  seed: "ptBaseModule",
});

const setViewWidthAndHeight = (width: string, height: string) => {
  document.body.style.setProperty(`--vw`, width);
  document.body.style.setProperty(`--vh`, height);
};

export type HopperThemingProviderProps = {
  enableDarkMode?: boolean;
};

export const HopperThemingProvider = ({
  children,
  enableDarkMode,
}: PropsWithChildren<HopperThemingProviderProps>) => {
  const [theme, setTheme] = useState(muiTheme);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const windowSize = useWindowSize();

  useEffect(() => {
    // Add a variable for vh to use for specifying full-screen height
    // 100vh does not work properly on iOS. https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    setViewWidthAndHeight(
      `${windowSize.width * 0.01}px`,
      `${windowSize.height * 0.01}px`
    );
  }, [windowSize.height, windowSize.width]);

  const isDarkModePreferred = useMemo(() => {
    const colorScheme = getDarkModePreferred()
      ? COLOR_THEMES.DARK
      : COLOR_THEMES.LIGHT;
    return isDarkMode && colorScheme === COLOR_THEMES.DARK;
  }, [isDarkMode]);

  useEffect(() => {
    if (enableDarkMode) {
      setIsDarkMode(true);
    }
  }, [enableDarkMode]);

  useEffect(() => {
    if (isDarkModePreferred) {
      setTheme(muiDarkTheme);
    }
  }, [isDarkMode, isDarkModePreferred]);

  useEffect(() => {
    installCssConfig(
      isDarkModePreferred ? hopperDarkVariables : hopperVariables
    );
  }, [isDarkModePreferred]);

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StylesProvider>
  );
};

import { GetPriceFreezeSuccess } from "@b2bportal/price-freeze-api";
import { Payment, PaymentOpaqueValue } from "@b2bportal/purchase-api";
import { ISelectedTrip, TripDetails } from "@hopper-b2b/types";
import { assign, DoneInvokeEvent } from "xstate";

import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { AirPriceFreezeExerciseDiscount, ParentContextWithPF } from "./types";
import dayjs from "dayjs";

export const actions = {
  setPriceFreezeFromData: assign(
    (
      ctx: ParentContextWithPF,
      event: DoneInvokeEvent<GetPriceFreezeSuccess>
    ) => {
      const response = event.data;
      const selectedTrip: ISelectedTrip = {
        tripId: response.priceFreeze.bookingDetails.tripId,
        outgoingSliceId: null,
        outgoingFareId: response.priceFreeze.frozenFare.id,
        ...(response.priceFreeze.tripDetails.slices.length > 1 && {
          returnFareId: response.priceFreeze.frozenFare.id,
        }),
      };

      ctx[ParentState.priceFreeze] = {
        ...(ctx[ParentState.priceFreeze] || {}),
        priceFreezeView: response.priceFreeze,
      };

      const tripDetails = response.priceFreeze
        .tripDetails as unknown as TripDetails;

      const departureDate = dayjs(
        tripDetails.slices?.[0].departureTime
      ).toDate();
      const returnDate =
        tripDetails.slices.length > 1
          ? dayjs(
              tripDetails.slices?.[tripDetails.slices.length - 1].departureTime
            ).toDate()
          : null;
      // TODO fix unknown casting
      ctx.flightShop.tripDetails = tripDetails;
      ctx.flightShop.airports = response.tripContext.airports;
      ctx.flightShop.selectedTrip = selectedTrip;
      ctx.flightSearch.departureDate = departureDate;
      ctx.flightSearch.returnDate = returnDate;
      return ctx;
    }
  ),
  setPriceFreezeDiscount: assign(
    (ctx: ParentContextWithPF, _event: unknown) => {
      const filterOutPayments = (
        ctx[ParentState.cartUpdate].addPayments ?? []
      ).filter(
        (p: PaymentOpaqueValue) =>
          p.type !== Payment.AirPriceFreezeExerciseDiscount
      );

      if (ctx[ParentState.priceFreeze]?.priceFreezeId) {
        const priceFreezeDiscount: AirPriceFreezeExerciseDiscount = {
          type: Payment.AirPriceFreezeExerciseDiscount,
          value: {
            priceFreeze: ctx[ParentState.priceFreeze].priceFreezeId ?? "",
          },
        };
        ctx[ParentState.cartUpdate].addPayments = [
          ...filterOutPayments,
          priceFreezeDiscount,
        ];
      }
      return ctx;
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject({
  ...actions,
});

export enum AirRestrictionStatus {
  UNKNOWN = "Unknown",
  PURCHASABLE = "Purchasable",
  UNAVAILABLE = "Unavailable",
  INCLUDED = "Included",
  GENERIC = "GENERIC",
}

export enum SliceDirection {
  Outbound = "Outbound",
  Return = "Return",
}

export enum TripGrouping {
  DATE = "DateGrouping",
}

export const PRICE_FREEZE_ID_QUERY_PARAM = "priceFreezeId";
export const CFAR_OFFER_QUOTE_ID_QUERY_PARAM = "cfarOfferQuoteId";
export const CHFAR_OFFER_QUOTE_ID_QUERY_PARAM = "chfarOfferQuoteId";
export const CHFAR_ID_QUERY_PARAM = "chfarId";
export const UTM_SOURCE_QUERY_PARAM = "utm_source";

import { useSlots } from "./context";
import type { Slots } from "./slots";

type SlotProps<Id extends keyof Slots> = {
  id: Id;
  component?: JSX.Element;
} & React.ComponentProps<Slots[Id]>;

export function Slot<Id extends keyof Slots>(props: SlotProps<Id>) {
  const slots = useSlots();
  const { id, component, ...componentProps } = props;

  const SlotComponent = slots[id];

  return SlotComponent ? <SlotComponent {...componentProps} /> : component;
}

import { FC } from "react";
import { useI18nContext } from "./hooks/useI18nContext";
import { Trans } from "react-i18next";

export interface Ii18nMarkup {
  tKey: string;
  replacements?: { [key: string]: unknown };
  className?: string;
}

export const I18nMarkup: FC<Ii18nMarkup> = ({
  tKey,
  replacements,
  className,
}: Ii18nMarkup) => {
  const i18n = useI18nContext();
  return (
    <span
      className={className}
      dangerouslySetInnerHTML={{
        __html: i18n.t(tKey, {
          interpolation: { escapeValue: false },
          ...replacements,
        }),
      }}
    />
  );
};

export { Trans };

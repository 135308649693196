import { Box, Typography } from "@material-ui/core";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Gender, Passenger, PassengerTypeEnum } from "@hopper-b2b/types";
import { IconContentView } from "../IconContentView";
import { getGender, pluralize } from "@hopper-b2b/utilities";
import ProfileBunny from "../../assets/bunnies/default_profile.svg";
import "./styles.scss";

const passengerTypeToName: Record<PassengerTypeEnum, [string, string]> = {
  [PassengerTypeEnum.Adult]: ["Adult", "Adults"],
  [PassengerTypeEnum.Child]: ["Child", "Children"],
  [PassengerTypeEnum.LapInfant]: ["Infant in Lap", "Infants in Laps"],
  [PassengerTypeEnum.SeatedInfant]: ["Infant in Seat", "Infants in Seats"],
};

// Used to define explicit order for passenger types
const passengerTypeOrder: Record<PassengerTypeEnum, number> = {
  [PassengerTypeEnum.Adult]: 0,
  [PassengerTypeEnum.Child]: 1,
  [PassengerTypeEnum.LapInfant]: 2,
  [PassengerTypeEnum.SeatedInfant]: 3,
};

type PassengerCount = { [key in PassengerTypeEnum]: number };

const getSummary = (passengers: Passenger[]) => {
  // Group types of passengers into a map with their count
  const passengerCounts: PassengerCount = passengers.reduce(
    (map, passenger) => {
      map[passenger.paxType] = (map[passenger.paxType] ?? 0) + 1;
      return map;
    },
    {} as PassengerCount
  );

  // Combine and pluralize
  return (Object.keys(passengerCounts) as PassengerTypeEnum[])
    .sort((a, b) => passengerTypeOrder[a] - passengerTypeOrder[b])
    .map((key) => {
      const counts = passengerCounts[key];
      const labels = passengerTypeToName[key];
      return `${counts} ${pluralize(counts, labels[0], labels[1])}`;
    })
    .join(", ");
};

const PassengerList = ({ passengers }: PassengerSummaryProps) => {
  const { t } = useI18nContext();
  return (
    <>
      {passengers.map((passenger, i) => (
        <IconContentView
          key={i}
          className="passenger"
          icon={<img src={ProfileBunny} alt="profile bunny" />}
          content={
            <>
              <Typography className="heading">{passenger.name}</Typography>
              <Typography>{passenger.dateOfBirth}</Typography>
              <Typography>
                {t("person", { context: getGender(passenger?.gender) })}
              </Typography>
            </>
          }
        />
      ))}
    </>
  );
};

export interface PassengerSummaryProps {
  passengers: Passenger[];
}
export const PassengerSummary = ({ passengers }: PassengerSummaryProps) => {
  return (
    <Box className="passenger-summary">
      <Typography className="heading">{getSummary(passengers)}</Typography>
      <Typography>
        ⛔️ Name corrections are not permitted after booking. Please be sure
        your passenger details match your travel ID.
      </Typography>
      <PassengerList passengers={passengers} />
    </Box>
  );
};

import React from "react";
import Airplane from "../../assets/client/airplane.svg";
import CirclePlane from "../../assets/client/circle-plane.svg";
import CloseModal from "../../assets/client/close-modal.svg";
import CloseModalPrimary from "../../assets/client/close.svg";
import CrossOutSignIcon from "../../assets/client/cross-out-sign.svg";
import DropdownIcon from "../../assets/client/dropdown.svg";
import ErrorAlertIcon from "../../assets/client/alert-error.svg";
import WarningAlertIcon from "../../assets/client/alert-warning.svg";
import BrandAlertIcon from "../../assets/client/alert-brand.svg";
import FilledClock from "../../assets/client/filled-clock.svg";
import FlightArrival from "../../assets/client/flight-arrival.svg";
import FlightDeparture from "../../assets/client/flight-departure.svg";
import InfoCircleIcon from "../../assets/client/info-icon.svg";
import InfoCircleBrandIcon from "../../assets/client/info-circle-brand.svg";
import SeatSelection from "../../assets/client/seat-selection.svg";
import SelfCheckBag from "../../assets/client/self-check-bag.svg";
import CarryOnBaggage from "../../assets/client/carry-on-baggage.svg";
import TripProtection from "../../assets/client/trip-protection.svg";
import RestrictionsChecked from "../../assets/client/check-circle.svg";
import RestrictionsClose from "../../assets/client/close.svg";
import RestrictionsPayment from "../../assets/client/payment.svg";
import RestrictionsGeneric from "../../assets/client/format-list-bulleted.svg";
import LocationMarker from "../../assets/client/location-marker.svg";
import Guest from "../../assets/client/guest.svg";
import ErrorStateIcon from "../../assets/client/error-state.svg";
import MultiPlanes from "../../assets/client/multi-planes-icon.svg";
import B2BAirplane from "../../assets/client/b2b-airplane-icon.svg";
import ExternalLink from "../../assets/client/external-link.svg";
import Add from "../../assets/client/add.svg";
import Subtract from "../../assets/client/subtract.svg";
import AirplaneDepart from "../../assets/client/airplane-depart.svg";
import AirplaneArrive from "../../assets/client/airplane-arrive.svg";
import Star from "../../assets/client/star.svg";
import Money from "../../assets/client/money.svg";
import QuestionCircled from "../../assets/client/help-center-faq.svg";
import LostFlight from "../../assets/client/lost-flight-faq.svg";

export enum IconName {
  Airplane = "airplane",
  CirclePlane = "circle-plane",
  CloseModal = "close-modal",
  CloseModalPrimary = "close-modal-primary",
  CrossOutSign = "cross-out-sign",
  Dropdown = "dropdown",
  ErrorAlert = "error-alert",
  ErrorState = "error-state",
  WarningAlert = "warning-alert",
  BrandAlert = "brand-alert",
  FilledClock = "filled-clock",
  FlightArrival = "flight-arrival",
  FlightDeparture = "flight-depature",
  InfoCircle = "info-circle",
  InfoCircleBrand = "info-circle-brand",
  SeatSelection = "seat-selection",
  SelfCheckBag = "self-check-bag",
  CarryOnBaggage = "carry-on-baggage",
  TripProtection = "trip-protection",
  RestrictionsChecked = "restrictions-checked",
  RestrictionsClose = "restrictions-close",
  RestrictionsPayment = "restrictions-payment",
  RestrictionsGeneric = "restrictions-generic",
  LocationMarker = "location-marker",
  Guest = "guest",
  MultiPlanes = "multi-planes",
  B2BAirplaneIcon = "b2b-airplane-icon",
  ExternalLink = "external-link-icon",
  Add = "add",
  Subtract = "subtract",
  AirplaneDepart = "airplane-depart",
  AirplaneArrive = "airplane-arrive",
  Star = "star",
  Money = "money",
  QuestionCircled = "question-circled",
  LostFlight = "lost-flight",
}

type IconMap = {
  [name in IconName]: string;
};

export const iconMap: IconMap = {
  [IconName.Airplane]: Airplane,
  [IconName.CirclePlane]: CirclePlane,
  [IconName.CloseModal]: CloseModal,
  [IconName.CloseModalPrimary]: CloseModalPrimary,
  [IconName.CrossOutSign]: CrossOutSignIcon,
  [IconName.Dropdown]: DropdownIcon,
  [IconName.ErrorAlert]: ErrorAlertIcon,
  [IconName.ErrorState]: ErrorStateIcon,
  [IconName.WarningAlert]: WarningAlertIcon,
  [IconName.BrandAlert]: BrandAlertIcon,
  [IconName.FilledClock]: FilledClock,
  [IconName.FlightArrival]: FlightArrival,
  [IconName.FlightDeparture]: FlightDeparture,
  [IconName.InfoCircle]: InfoCircleIcon,
  [IconName.InfoCircleBrand]: InfoCircleBrandIcon,
  [IconName.SeatSelection]: SeatSelection,
  [IconName.SelfCheckBag]: SelfCheckBag,
  [IconName.CarryOnBaggage]: CarryOnBaggage,
  [IconName.TripProtection]: TripProtection,
  [IconName.RestrictionsChecked]: RestrictionsChecked,
  [IconName.RestrictionsClose]: RestrictionsClose,
  [IconName.RestrictionsPayment]: RestrictionsPayment,
  [IconName.RestrictionsGeneric]: RestrictionsGeneric,
  [IconName.LocationMarker]: LocationMarker,
  [IconName.Guest]: Guest,
  [IconName.MultiPlanes]: MultiPlanes,
  [IconName.B2BAirplaneIcon]: B2BAirplane,
  [IconName.ExternalLink]: ExternalLink,
  [IconName.Add]: Add,
  [IconName.Subtract]: Subtract,
  [IconName.AirplaneDepart]: AirplaneDepart,
  [IconName.AirplaneArrive]: AirplaneArrive,
  [IconName.Star]: Star,
  [IconName.Money]: Money,
  [IconName.QuestionCircled]: QuestionCircled,
  [IconName.LostFlight]: LostFlight,
};

export interface IconProps {
  name: IconName;
  className?: string;
  ariaLabel?: string;
}

export const Icon = ({
  name,
  ariaLabel,
  ...props
}: IconProps): React.ReactElement => (
  <img
    src={iconMap[name]}
    alt={name}
    {...props}
    aria-label={ariaLabel ?? name}
  />
);

import { AirRestrictionStatus } from "../constants";
import {
  FareWarningLevel,
  MaybeHasFee,
  IBaggageInfo,
  ICancelPenalty,
  IChangePenalty,
  IDetailBaggageInfo,
  IRestrictions,
  ISlicedRestriction,
  Restriction,
} from "./interfaces";

const penalty = {
  penalty: {
    message: "View on spirit.com",
    MaybeHasFee: MaybeHasFee.NO_FEE,
  },
  fareWarningLevel: {
    link: "https://customersupport.spirit.com/hc/en-us/articles/202097076-How-can-I-change-or-cancel-my-reservation-",
    FareWarningLevel: FareWarningLevel.NORMAL,
  },
  symbol: AirRestrictionStatus.UNKNOWN,
};

export const slicedRestriction = {
  penaltiesInfo: {
    changePenalty: penalty as IChangePenalty,
    cancelPenalty: penalty as ICancelPenalty,
  },
  canFilter: true,
  restrictionsWithInfo: [
    {
      title: "Hopper Customer Support",
      body: "Guaranteed assistance with your trip",
      restrictionWarningLevel: {
        RestrictionWarningLevel: "Normal",
      },
      symbol: "Included",
    },
  ],
  brandType: "BasicEconomy",
  detailBaggageInfo: {
    title: "Checked Bags",
    body: "Check with airlines for detail",
    restrictionWarningLevel: {
      RestrictionWarningLevel: "Normal",
    },
    symbol: "Unknown",
  },
  fetchReq:
    "eyJ0cmlwSWQiOiJqa1pxTkttcldxMHZWOERKQjFyVDN3bGNGMjFRRWQiLCJmYXJlSWQiOiJmNjNjYzFmYi0zMGFkLTRkYmYtOTYxZC1mNmE2Y2E5ZTRmMTciLCJtYXJrZXRpbmdDYXJyaWVycyI6WyJOSyIsIkY5Il19",
  baggageInfo: {
    LocalizedBaggageInfo: "Unknown",
  },
} as ISlicedRestriction;

export const fareRestrictions: IRestrictions = {
  penaltiesInfo: {
    changePenalty: penalty,
    cancelPenalty: penalty,
  },
  canFilter: true,
  restrictionsWithInfo: [
    {
      title: "Hopper Customer Support",
      body: "Guaranteed assistance with your trip",
      restrictionWarningLevel: {
        RestrictionWarningLevel: "Normal",
      },
      symbol: "Included",
    },
  ],
  brandType: "BasicEconomy",
  detailBaggageInfo: {
    title: "Checked Bags",
    body: "Check with airlines for detail",
    restrictionWarningLevel: {
      RestrictionWarningLevel: "Normal",
    },
    symbol: "Unknown",
  } as IDetailBaggageInfo,
  fetchReq:
    "eyJ0cmlwSWQiOiJqa1pxTkttcldxMHZWOERKQjFyVDN3bGNGMjFRRWQiLCJmYXJlSWQiOiJmNjNjYzFmYi0zMGFkLTRkYmYtOTYxZC1mNmE2Y2E5ZTRmMTciLCJtYXJrZXRpbmdDYXJyaWVycyI6WyJOSyIsIkY5Il19",
  baggageInfo: {
    LocalizedBaggageInfo: "Unknown",
  } as IBaggageInfo,
} as IRestrictions;

export const restrictions: Restriction[] = [
  {
    name: "Seat Selection",
    description: "Available",
    symbol: AirRestrictionStatus.INCLUDED,
  },
  {
    name: "Carry-on Bags",
    description: "Overhead bin access",
    symbol: AirRestrictionStatus.INCLUDED,
  },
  {
    name: "Checked Bags",
    description: "Free\n2nd bag: $70",
    symbol: AirRestrictionStatus.INCLUDED,
  },
  {
    name: "Cancellations",
    description: "$25",
    symbol: AirRestrictionStatus.PURCHASABLE,
  },
  {
    name: "Changes",
    description: "$25",
    symbol: AirRestrictionStatus.PURCHASABLE,
  },
];

import {
  CartContext,
  CommonParentContext,
  FlightContextV2,
  ParentState,
} from "@hopper-b2b/checkout";
import { MetaSearchLinkingContext } from "@virtual-interline/checkout";
import { AuthContext } from "../../checkout/states/Auth/types";
import { BagsContext } from "../../checkout/states/Bags/context";

export enum PassportState {
  idle = "idle",
  update = "update",
  updateAndContinue = "updateAndContinue",
  validate = "validate",
}

export interface FlightMachineContext
  extends FlightContextV2,
    CommonParentContext,
    AuthContext,
    CartContext {
  [ParentState.bookingConfirmation]: unknown;
  [ParentState.metaSearchLinking]: MetaSearchLinkingContext;
  [ParentState.bagsSelection]: BagsContext;
}

export enum ModalScreenTypes {
  ContactInformation = "ContactInformation",
  PassengerInformation = "PassengerInformation",
  Review = "Review",
  BookingConfirmation = "BookingConfirmation",
  SeatSelection = "SeatSelection",
  BagsSelection = "BagsSelection",
  GuestCardPayment = "CardPayment",
  CardPayment = "CardPayment",
  InvalidSession = "InvalidSession",
  CartUpdateLoading = "CartUpdateLoading",
  CartQuoteLoading = "CartQuoteLoading",
  Unknown = "Unknown",
}

export enum PaymentErrorModalTypes {
  ListPaymentMethods = "ListPaymentMethods",
  VerifyPayment = "VerifyPayment",
  CardDeclined = "CardDeclined",
  ExcessiveUserActivity = "ExcessiveUserActivity",
  GenericError = "GenericError",
  DeletePayment = "DeletePayment",
  FetchNequiBalance = "FetchNequiBalance",
}

export enum PassportErrorModalTypes {
  TripPricing = "TripPricing",
  UpdatePassenger = "UpdatePassenger",
  ValidatePassengers = "ValidatePassengers",
}

export interface PaymentError {
  type: PaymentErrorModalTypes;
  data: unknown;
}

export interface PassportError {
  type: PassportErrorModalTypes;
  data: unknown;
}

export enum CheckoutQueryParams {
  tripCategory = "tripCategory",
  departureLabel = "departureLabel",
  returnLabel = "returnLabel",
  tripId = "tripId",
  outgoingSliceId = "outgoingSliceId",
  outgoingFareId = "outgoingFareId",
  outgoingFareRating = "outgoingFareRating",
  returnSliceId = "returnSliceId",
  returnFareId = "returnFareId",
  returnFareRating = "returnFareRating",
  contactCountry = "contactCountry",
  contactPhone = "contactPhone",
  contactEmail = "contactEmail",
  selectedLapInfantIds = "selectedLapInfantIds",
  selectedPassengerIds = "selectedPassengerIds",
  selectedPaymentMethodId = "selectedPaymentMethodId",
  checkoutState = "checkoutState",
}

export enum OpenModalEnum {
  DEPARTURE = "DEPARTURE",
  RETURN = "RETURN",
}

export enum PriceScenarioModalType {
  Decreased = "PriceScenarioDecreased",
  Increased = "PriceScenarioIncreased",
}

export type IOpenModal = OpenModalEnum | false;

import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { PartialParentContextWithAirPriceDrop } from "./types";

export const guards = {
  hasPriceDropIdAndEnabled: (
    ctx: PartialParentContextWithAirPriceDrop,
    _event: unknown
  ) => {
    return (
      !!ctx[ParentState.priceDrop]?.candidateId &&
      ctx.featureFlags?.enablePriceDrop
    );
  },
};

export const GuardTypes = getObjectKeysAsObject(guards);

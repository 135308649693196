import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { CipherText, purchaseApi } from "@b2bportal/purchase-api";
import { axiosInstance } from "../AxiosInterceptor";
import { trackEvent } from "../tracking/trackEvent";

export const discardQuotedCart = (
  req: CipherText,
  apiConfig?: IApiConfig
): Promise<unknown> =>
  new Promise<unknown>((resolve, reject) => {
    purchaseApi(axiosInstance)
      .apiV1PurchaseQuoteDiscardPost(req)
      .then((res) => {
        const responseBody = res.data;
        resolve(responseBody);
      })
      .catch((e) => {
        trackEvent(
          {
            eventName: NETWORK_CALL_FAILED,
            properties: {
              url: window.location.pathname,
            },
          },
          apiConfig
        );
        reject(e);
      });
  });

import { useDeviceTypes } from "@hopper-b2b/utilities";
import { useExperiment } from "@hopper-b2b/experiments";

import DesktopHeader from "./components/DesktopHeader";
import MobileHeader from "./components/MobileHeader";
import { FEATURE_FLAGS } from "../../Root";

export const Header = () => {
  const { matchesMobile } = useDeviceTypes();
  const displayDegradedPerformance = useExperiment(
    FEATURE_FLAGS.DEGRADED_PERFORMANCE
  );

  return matchesMobile ? (
    <MobileHeader displayPerfNotice={displayDegradedPerformance} />
  ) : (
    <DesktopHeader displayPerfNotice={displayDegradedPerformance} />
  );
};

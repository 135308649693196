import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { ChildPassengerInformationContext } from "./context";
import { SelectPassengerEvent } from "./events";
import { FlightContext } from "../../types";

export const childGuards = {
  haveAnyPassengersLoaded: (ctx: ChildPassengerInformationContext) => {
    const userPassengers = ctx.userPassengers;
    if (userPassengers?.length && userPassengers.length > 0) {
      return true;
    }
    return false;
  },
  hasAnySelectedPassengers: (ctx: ChildPassengerInformationContext) => {
    const selectedPassengerIds = ctx.selectedPassengerIds;
    return selectedPassengerIds.length > 0;
  },
  willHaveSelectedPassengers: (
    ctx: ChildPassengerInformationContext,
    event: SelectPassengerEvent
  ) => {
    const selectedPassengerIds = ctx.selectedPassengerIds;
    return !(
      selectedPassengerIds.length === 1 &&
      selectedPassengerIds[0] === event.passengerId
    );
  },
  hasCurrentUserPassenger: (ctx: ChildPassengerInformationContext) => {
    const currentUserPassenger = ctx.currentUserPassenger?.id;
    return !!currentUserPassenger;
  },
};

export const guards = {
  notVisitedPassenger: (ctx: FlightContext) =>
    !ctx[ParentState.passengerInformation].visited,
};

export const GuardTypes = getObjectKeysAsObject({ ...childGuards, ...guards });

import { authApi, CreateSessionRequest } from "@b2bportal/auth-api";
import { axiosInstance } from "@hopper-b2b/api";
import { setRefreshToken } from "./localStorage";

export const createSession = async (request: CreateSessionRequest) => {
  try {
    const response = await authApi(axiosInstance).apiV0AuthSessionCreatePost(
      request
    );
    if (response.headers["hopper-refresh"]) {
      setRefreshToken(response.headers["hopper-refresh"]);
    }
    return response.data;
  } catch (error) {
    console.error("Create session error:", error);
    throw Error("Create session error");
  }
};

import { Typography } from "@material-ui/core";
import { AirRestrictionStatus } from "@hopper-b2b/types";
import { Icon, IconName } from "../Icon";
import { IconContentView } from "../IconContentView";
export interface IRestrictionProps {
  symbol: AirRestrictionStatus;
  name: string;
  description?: JSX.Element;
  descriptionString?: string;
}

export const restrictionIcon: { [k in AirRestrictionStatus]: JSX.Element } = {
  [AirRestrictionStatus.INCLUDED]: (
    <Icon className="icon-available" name={IconName.CheckCircleTransparent} />
  ),
  [AirRestrictionStatus.PURCHASABLE]: (
    <Icon className="icon-paid" name={IconName.MoneyOutlineTransparentIcon} />
  ),
  [AirRestrictionStatus.UNAVAILABLE]: (
    <Icon className="icon-paid" name={IconName.NotAllowedSign} />
  ),
  [AirRestrictionStatus.UNKNOWN]: (
    <Icon className="icon-paid" name={IconName.NotAllowedSign} />
  ),
  [AirRestrictionStatus.GENERIC]: (
    <Icon className="icon-paid" name={IconName.Generic} />
  ),
};
export const Restriction = (props: IRestrictionProps) => {
  const { symbol, name, description, descriptionString } = props;

  const icon = restrictionIcon[symbol];

  return (
    <IconContentView
      className="restriction"
      icon={icon}
      content={
        <>
          <Typography className="title">{name}</Typography>
          {description && description}
          {descriptionString && (
            <Typography variant="caption">{descriptionString}</Typography>
          )}
        </>
      }
    />
  );
};

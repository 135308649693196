import { CipherText, purchaseApi, QuoteRequest } from "@b2bportal/purchase-api";
import { NETWORK_CALL_FAILED, IApiConfig } from "@hopper-b2b/types";
import { trackEvent } from "../tracking/trackEvent";
import { axiosInstance } from "../AxiosInterceptor";

export const scheduleQuote = (
  req: QuoteRequest,
  apiConfig?: IApiConfig
): Promise<CipherText> =>
  new Promise((resolve, reject) => {
    purchaseApi(axiosInstance)
      .apiV1PurchaseQuoteSchedulePost(req)
      .then((res) => {
        const responseBody = res.data;
        resolve(responseBody);
      })
      .catch((e) => {
        trackEvent(
          {
            eventName: NETWORK_CALL_FAILED,
            properties: {
              url: window.location.pathname,
            },
          },
          apiConfig
        );
        reject(e);
      });
  });

export interface SchedulePriceQuoteRequest {
  tripId: string;
  seatedPassengers: string[];
  lapInfants: string[];
  fareId: string;
  emailAddress: string;
  phoneNumber: string;
  ancillaryIds: string[];
  priceDropCandidateId?: string;
  delegatedTo?: string;
}

export const HOURS_IN_A_DAY = 24;
export const SECONDS_IN_A_MINUTE = 60;
export const MILLISECONDS_IN_A_SECOND = 1000;
export const MINUTES_IN_AN_HOUR = 60;

export const secondsToDays = (seconds: number) =>
  Math.floor(
    seconds / (SECONDS_IN_A_MINUTE * MINUTES_IN_AN_HOUR * HOURS_IN_A_DAY)
  );

export const secondsToHours = (seconds: number) =>
  Math.floor(seconds / (SECONDS_IN_A_MINUTE * MINUTES_IN_AN_HOUR));

export const millisecondsToSeconds = (ms: number) =>
  (ms / MILLISECONDS_IN_A_SECOND).toFixed(2);

import { Typography } from "@material-ui/core";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { PATH_TERMS_HTS } from "../../utils/constants";

export const EnglishMissedConnectionsTermsAndConditions = () => (
  <>
    <Typography
      variant="h3"
      className={clsx(styles.bold, styles.underline, styles.mb, styles.center)}
    >
      Terms of Service
    </Typography>

    <Typography variant="body2" className={clsx(styles.bold, styles.mb)}>
      Hopper's HTS Connect Missed Connection Guarantee - Volaris Customers
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Hopper Technology Solutions ("<span className={styles.bold}>HTS</span>" or
      "<span className={styles.bold}>Hopper</span>") Missed Connection Guarantee
      Service for Volaris customers (the "
      <span className={styles.bold}>Service</span>," as used in this
      subsection), is included at no additional charge on certain purchased
      itineraries connecting multiple flights from Volaris to unaffiliated
      carriers ("<span className={styles.bold}>HTS Connect Booking</span>"). The
      Service offers the purchaser a free rebooking service in the event you
      miss your connecting flight for a reason outside of your control.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Please note, the Service applies only to HTS Connect Bookings and cannot
      be purchased as a stand-alone service. The HTS Connect Booking and the
      Service are subject to HTS Connect's Terms of Service which can be found{" "}
      <a href={PATH_TERMS_HTS} target="_blank" rel="noreferrer">
        here
      </a>
      .
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      The Service is subject to the following terms, requirements, and
      restrictions:
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      1. <span className={styles.bold}>How it Works</span>. The Service will
      apply to the HTS Connect Booking purchaser and other passengers on the
      same HTS Connect Booking. You may use the Service only if you miss a
      connecting flight in your HTS Connect Booking due to a reason outside your
      control (e.g., the connecting flight is canceled by the airline or departs
      without you on board due to a delay on an earlier flight) ("
      <span className={styles.bold}>Eligible Disruption</span>""). If you
      experience an Eligible Disruption you may use the Service by contacting
      Volaris customer support via the information provided in your booking
      confirmation email, who will then connect you to Hopper (collectively, "
      <span className={styles.bold}>Customer Support</span>") to rebook yourself
      and other eligible passengers on a new connecting flight to your
      destination at no cost, up to a maximum amount for the new flight of
      US$2,000.00 (including taxes and fees) per passenger ("
      <span className={styles.bold}>Service Cap</span>"), and subject to the the
      additional restrictions for new flights explained in paragraph 6 below. If
      you elect to use the Service following an Eligible Disruption and the per
      passenger costs of the new connecting flight exceed the Service Cap, you
      will be required to pay the per passenger costs in excess of the Service
      Cap at the time of re-booking. If no decision to use the Service is made,
      the Service will expire twenty-four (24) hours after the Eligible
      Disruption.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      2. <span className={styles.bold}>Eligible Disruption</span>. To be
      eligible for the Service, the missed connection as part of your HTS
      Connect Booking must be due to an eligible reason outside of your control.
      Eligible reasons include, but are not limited to, delays or cancellations
      due to airline issues, customs issues, or to weather. All determinations
      of whether a missed connecting flight was due to an eligible reason will
      be at the business discretion of Hopper.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      3. <span className={styles.bold}>Passenger Rights Regimes</span>. Certain
      jurisdictions, including Canada and the European Union, have adopted laws
      and/or regulations requiring airlines to compensate passengers in the
      event of certain disruptions to scheduled air travel (a "
      <span className={styles.bold}>Passenger Rights Regime</span>"). In the
      event of such a disruption, you may be eligible for compensation under a
      Passenger Rights Regime, regardless of whether you elect to use the
      Service.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      4. <span className={styles.bold}>Reasonable Rebooking Option</span>. You
      will not be eligible to use the Service in the event the airline cancels
      the connecting flight in your HTS Connect Booking but offers you a
      reasonable rebooking option that you are able to board. A reasonable
      rebooking option means a new connecting flight that departs: (i) within
      one (1) hour of the scheduled departure time of your missed connecting
      flight, or (ii) if your missed connecting flight was scheduled to depart
      later than 8:00PM local time, by 9:00AM local time the following day. All
      determinations of whether a rebooking offered by an airline is a
      "reasonable rebooking option" will be made at the business discretion of
      Hopper.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.{" "}
      <span className={styles.bold}>
        Things to Know About Booking a New Connecting Flight
      </span>
      . If you elect to use the Service following an Eligible Disruption, you
      may rebook via contacting Customer Support on a new connecting flight at
      no cost (subject to Section 1) subject to the following conditions:
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      (I) the new connecting flight must be to the same destination.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      (II) the new connecting flight may be on any airline available provided by
      Customer Support, but must be in the same or lower corresponding fare
      class as the original booking; and you must be able to make it to the gate
      of the new connecting flight in time to board. HHopper is not responsible
      for ensuring you arrive at the gate on time and will not pay any
      additional costs if you miss your new connecting flight.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      (III) if you have checked luggage, you must coordinate with the original
      airline to retrieve your luggage before or after your new connecting
      flight to your destination. Hopper is not responsible for any baggage
      retrieval and will not cover any baggage fees.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      (IV) flights offered for rebooking through the Service will be for the
      same day or the following day only.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      6. <span className={styles.bold}>Who is Included in the Service</span>.
      Hopper provides the Service for all passengers included on the travel
      itinerary at the time of HTS Connect Booking, up to the maximum value of
      the per passenger Service Cap. If there is more than one passenger on the
      booking, the passenger who made the booking shall determine how to
      exercise the Service, which shall apply to any other passenger included on
      the booking.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      7. <span className={styles.bold}>Other Restrictions</span>. Hopper will
      not be responsible to you for any fees and costs incurred by you (e.g.
      baggage fees or upgrades) other than the costs of the new connecting
      flight up to the per passenger Service Cap. The Service is valid for ONE
      missed connecting flight per passenger in connection with the trip for
      which the Service is associated. The Service does not carry over to any
      future bookings made with HTS. In the event you make a voluntary schedule
      change or cancellation of your HTS Connect Booking before your departure
      date, the Service will no longer be valid. If you cancel your flight
      during the window provided by your airline during which you can cancel and
      receive a refund from the airline (referred to as the "void window"), the
      Service will be voided. The Service is non-transferable.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      8. <span className={styles.bold}>Not Insurance</span>. The Service is a
      guarantee that Hopper will rebook you on a new connecting flight to your
      original destination if you miss your connecting flight on a HTS Connect
      Booking for a reason outside of your control, subject to the limitations
      set forth herein. HTS provides its customers with the Service to provide a
      more seamless and flexible experience for HTS Connect Bookings. The
      Service terms are not intended to constitute an offer to insure, do not
      constitute insurance or an insurance contract, and do not take the place
      of insurance obtained or obtainable by you.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Effective Date of these Terms of Service: Version 1.1; August 16, 2024
    </Typography>
  </>
);

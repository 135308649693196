import { Typography, TypographyProps } from "@material-ui/core";
import clsx from "clsx";

import { Icon, IconName } from "../Icon";
import styles from "./styles.module.scss";

export interface IInfoBannerProps {
  className?: string;
  icon?: string;
  text: string;
  textProps?: TypographyProps;
}

const defaultProps: Partial<IInfoBannerProps> = {
  textProps: {},
};

const InfoBanner = (props: IInfoBannerProps): JSX.Element => {
  const { className, icon, text, textProps } = props;

  return (
    <div className={clsx("info-banner", styles.infoBanner, className)}>
      <div className={clsx("banner-icon-container", styles.iconContainer)}>
        {icon ? (
          <img
            alt="info-icon"
            className={clsx("icon", styles.icon)}
            src={icon}
          />
        ) : (
          <Icon
            className={clsx("icon", styles.icon)}
            name={IconName.InfoWhiteCircle}
          />
        )}
      </div>
      <div className={clsx("text-container", styles.textContainer)}>
        <Typography {...textProps} className={clsx("text", styles.text)}>
          {text}
        </Typography>
      </div>
    </div>
  );
};

InfoBanner.defaultProps = defaultProps;

export default InfoBanner;

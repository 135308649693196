import {
  PaymentId,
  PaymentOpaqueValue,
  ProductId,
  ProductOpaqueValue,
} from "@b2bportal/purchase-api";
import { CartContext } from "./context";
import { CartState } from "./types";

export const addQuoteProduct = <GenericCartContext extends CartContext>(
  product: ProductOpaqueValue,
  ctx: GenericCartContext
) => {
  ctx[CartState.cartQuote].products = [
    ...ctx[CartState.cartQuote].products.filter((p) => p.type !== product.type),
    product,
  ];
  return {
    ...ctx,
  };
};

export const addUpdatePayment = <GenericCartContext extends CartContext>(
  payment: PaymentOpaqueValue,
  ctx: GenericCartContext
) => {
  ctx[CartState.cartUpdate].addPayments = [
    ...ctx[CartState.cartUpdate].addPayments.filter(
      (p) => p.type !== payment.type
    ),
    payment,
  ];
  return {
    ...ctx,
  };
};

export const addUpdateProduct = <GenericCartContext extends CartContext>(
  product: ProductOpaqueValue,
  ctx: GenericCartContext
) => {
  ctx[CartState.cartUpdate].addProducts = [
    ...ctx[CartState.cartUpdate].addProducts.filter(
      (p) => p.type !== product.type
    ),
    product,
  ];
  return {
    ...ctx,
  };
};

export const removeUpdateProduct = <GenericCartContext extends CartContext>(
  productId: ProductId,
  ctx: GenericCartContext
) => {
  ctx[CartState.cartUpdate].removeProducts = [
    ...ctx[CartState.cartUpdate].removeProducts.filter(
      (pId) => pId !== productId
    ),
    productId,
  ];
  return {
    ...ctx,
  };
};

export const removeUpdatePayment = <GenericCartContext extends CartContext>(
  paymentId: PaymentId,
  ctx: GenericCartContext
) => {
  ctx[CartState.cartUpdate].removePayments = [
    ...ctx[CartState.cartUpdate].removePayments.filter(
      (pId) => pId !== paymentId
    ),
    paymentId,
  ];
  return {
    ...ctx,
  };
};

export const addFulfillPayment = <GenericCartContext extends CartContext>(
  payment: PaymentOpaqueValue,
  ctx: GenericCartContext
) => {
  ctx[CartState.cartFulfill].fulfillRequestPayments = [
    ...ctx[CartState.cartFulfill].fulfillRequestPayments.filter(
      (p) => p.type !== payment.type
    ),
    payment,
  ];
  return {
    ...ctx,
  };
};

import { basicColors } from "./colors";
import {
  ColorConfig,
  StyleConfig,
  ThemeConfig,
  ThemeConfigWithBasicColors,
} from "../types";

const defaultColorConfig: ColorConfig = {
  primary: basicColors.skyblue,
  "primary-disabled": basicColors["skyblue-disabled"],
  "secondary-blue": basicColors.darkblue,
  "tertiary-blue": basicColors.skyblue,
  background: basicColors.white,
  "background-secondary": basicColors["grey-15"],
  "button-background": basicColors.skyblue,
  "button-background-disabled": basicColors["skyblue-disabled"],
  "button-background-hover": basicColors.darkblue,
  "map-bubble-border": basicColors["grey-20"],
  "map-marker-background": basicColors["white"],
  "map-bubble-selected-border": basicColors["grey-20"],
  "map-marker-selected-background": basicColors.skyblue,
  "user-icon-purple": basicColors.purple,
  "user-name-purple": basicColors["purple-transparent"],
  "anchor-link-blue": basicColors["blue-6"],
  "primary-text": basicColors["grey-100"],
  "secondary-text": basicColors["grey-200"],
  "tertiary-text": basicColors["grey-300"],
  "black-text": basicColors.black,
  "inverse-text": basicColors.white,
  "emphasis-blue": basicColors["blue-7"],
  "inactive-grey": basicColors["grey-10"],
  "inactive-button-icon-grey": basicColors["grey-20"],
  "action-button-inactive-background": basicColors["grey-10"],
  "action-button-inactive-text": basicColors["grey-6"],
  "action-button-inactive-border": basicColors["grey-20"],
  "action-button-active-background": basicColors.white,
  "action-button-active-text": basicColors.skyblue,
  "filter-background-selected": basicColors["blue-5-transparent"],
  "action-link-active-text": basicColors.skyblue,
  "popup-card-header-border": basicColors["black-3-transparent-12"],
  "badge-green-background": basicColors["green-12"],
  "info-details-popup-background": basicColors["white-1"],
  "info-details-popup-border": basicColors["grey-400"],
  "slider-bar-highlighted": basicColors["green-5"],
  "slider-bar-inactive": basicColors["grey-18"],
  "text-green": "#63b67f",
  "text-green-2": basicColors["green-5"],
  "text-green-2-transparent": basicColors["green-5-transparent"],
  "success-color": basicColors["green-5"],
  "alert-background-success": basicColors["green-5-transparent"],
  divider: basicColors["grey-20"],
  "logout-link-text": basicColors["red-4"],
  "mobile-banner": basicColors.coral,
  "banner-background": basicColors["blue-9"],
  "loading-popup": basicColors.coral,
  "link-text": basicColors["grey-200"],
  "hamburger-menu-color": "var(--primary)",
  "icon-color": "var(--primary)",
  "disabled-icon-color": basicColors["grey-400"],
  "card-tag-text": basicColors["blue-9"],
  "card-tag-background": basicColors["blue-10"],
  "secondary-button-background": basicColors["white"],
  "secondary-button-background-disabled": basicColors["grey-3"],
  "secondary-button-background-hover": basicColors["grey-3"],
  "input-background": basicColors["grey-3"],
  "input-label-text": basicColors["grey-200"],
  "card-border-color": basicColors["grey-500"],
  "card-border": "2px solid var(--card-border-color)",
  "price-freeze-card-color": basicColors["blue-2"],
  "price-freeze-card-button": basicColors["blue-3"],
  "disruption-background-color": basicColors["green-6"],
  confirm: basicColors["green-50"],
  "trips-list-background-color": basicColors.white,
  "trips-selected-filter-color": "var(--primary-disabled)",
  "trips-link-color": "var(--primary)",
  "disabled-input-background-color": basicColors["grey-10"],
  "flight-card-icon-color": basicColors["grey-6"],
  // CALENDAR COLORS
  "bucket-0-color": basicColors["green-5"],
  "bucket-1-color": basicColors["calendar-yellow"],
  "bucket-2-color": basicColors["calendar-orange"],
  "bucket-3-color": "#cc2427",
  "calendar-green": "#6fc28b",
  "calendar-yellow": "#f0a02a",
  "calendar-orange": "#e36624",
  "calendar-red": "#d92c2c",
  "grey-font-color": basicColors["grey-100"],
  "date-hover-color": basicColors.skyblue,
  "date-selected-color": basicColors.skyblue,
  "date-range-limit-color": basicColors.skyblue,
  "date-disabled-color": basicColors["grey-11-transluscent"],
  "price-freeze-text": "#31bec7",
  "toggle-grey": "#ebebeb",
  "white-transparent-border": "rgba(255, 255, 255, 0.25)",
  "box-shadow": "rgba(0, 0, 0, 0.05)",
  "backdrop-shadow": "rgba(0, 0, 0, 0.25)",
  // ALGOMERCH COLORS
  "algomerch-airline": "rgba(204, 36, 39, 1.0)",
  "algomerch-fareclass": "rgba(0, 118, 193, 1.0)",
  "algomerch-price": "rgba(0, 129, 64, 1.0)",
  "algomerch-duration": "rgba(240, 160, 42, 1.0)",
  "algomerch-stops": "rgba(1, 61, 88, 1.0)",
  // PRICE PREDICTION COLORS
  "price-prediction-wait": "rgba(199, 1, 22, 1.0)",
  "price-prediction-fair": "rgba(236, 130, 65, 1.0)",
  "price-prediction-good": "rgba(249, 209, 0, 1.0)",
  "price-prediction-great": "rgba(0, 129, 64, 1.0)",
  // PRICE PREDICTION GRADIENT COLORS
  "price-prediction-gradient-wait": "rgba(199, 1, 22, 1.0)",
  "price-prediction-gradient-fair": "rgba(236, 130, 65, 1.0)",
  "price-prediction-gradient-good": "rgba(249, 209, 0, 1.0)",
  "price-prediction-gradient-great": "rgba(0, 129, 64, 1.0)",
  "price-prediction-gradient-great-transparent": "rgba(0, 129, 64, 0.05)",
  "tag-background-grey": "rgba(216, 216, 216, 0.2)",
  // SCHEDULE CHANGE COLORS
  "red-error-color": "rgba(204, 36, 39, 1.0)",
  "red-error-color-transparent": "rgba(252, 213, 209, 1.0)",
  "warning-error-color": "rgba(249, 198, 6, 1.0)",
  "warning-error-color-transparent": "rgba(255, 241, 208, 1.0)",
  "light-spinner-blue": "rgba(58, 188, 253, 1.0)",
  "ftux-background-blue": "rgba(18, 39, 65, 1)",
  "ftux-background-blue-transparent": "rgba(18, 39, 65, 0.95)",
  "takeover-background-blue": "rgba(225, 245, 255, 1)",
  "price-freeze-purchase-banner-background-color": basicColors.black,
  "price-freeze-purchase-banner-text-color": basicColors.white,
  "spinner-border-color": basicColors["grey-10"],
  "spinner-top-border-color": basicColors["blue-10"],
  // WALLET STEP COLORS
  "wallet-offer-banner-background-color": basicColors["blue-60"],
  "wallet-offer-banner-color": basicColors["white"],
  "checkout-banner-background-color": basicColors["blue-9"],
  // #region CUSTOMER CHAT SUPPORT
  "chat-bg-agent": "#F2F2F2",
  "chat-bg-customer": "#1638ea",
  "chat-bg-customer-error": "#EAEAEA",
  "chat-bg-fab": "white",
  "chat-bg-fab-hover": "white",
  "chat-control-border-color": "#c9c9c9",
  "chat-divider-color": "#dcdcdc",
  "chat-fab-icon-color": "var(--primary)",
  "chat-message-border-color": "transparent",
  "chat-text-agent": "rgba(0, 0, 0, 0.92)",
  "chat-text-customer": "#ffffff",
  "chat-text-customer-error": "var(--secondary-text)",
  "chat-text-deeplink": "var(--text-primary)",
  // #endregion
};

const defaultStyleConfig: StyleConfig = {
  "border-radius": "0.5rem",
  "button-border": "2px solid #ededed",
  "button-border-radius": "0.5rem",
  "halfsheet-border-radius": "1rem",
  "breakpoint-mobile-min": "320px",
  "breakpoint-mobile-max": "480px",
  "breakpoint-tablet-min": "480px",
  "breakpoint-tablet-max": "768px",
  "breakpoint-laptop-min": "768px",
  "breakpoint-laptop-max": "960px",
  "breakpoint-desktop-min": "960px",
  "breakpoint-desktop-max": "1200px",
  "breakpoint-large-screen-min": "1200px",
  "breakpoint-macbook-air": "1440px",
  "z-modal": "1300",
  "z-float": "500",
  "font-family": ["Proxima Nova", "sans-serif"].join(","),
  "page-content-desktop-size": "1200px",
};

/** These variables are only configurable for core components **/
export const componentsColorConfig = {
  "homepage-title-primary": "var(--primary)",
  "page-content-desktop-size": "960px",
  "footer-primary": "var(--background)",
  "footer-font-color": "var(--primary)",
  "flight-search-heading-primary": "var(--primary)",
  "header-desktop-height": "64px",
  "footer-desktop-height": "64px",

  // RESTRICTION DETAILS
  "restriction-details-primary": "var(--primary)",
  "restriction-details-secondary": "var(--secondary)",
  "restriction-details-primary-text": "var(--primary-text)",
  "restriction-details-secondary-text": "var(--secondary-text)",

  // Trips
  "trips-breadcrumbs-color-text": "var(--secondary-text)",
  "trips-heading-color-text": "var(--secondary-text)",
};

export const defaultThemeConfig: ThemeConfig = {
  ...defaultColorConfig,
  ...componentsColorConfig,
  ...defaultStyleConfig,
};

export const defaultThemeConfigWithBasicColors: ThemeConfigWithBasicColors = {
  ...basicColors,
  ...defaultColorConfig,
  ...componentsColorConfig,
  ...defaultStyleConfig,
};

import {
  LAUNCHED_APPLICATION,
  LaunchedApplicationProperties,
} from "@hopper-b2b/types";
import { trackEvent } from "@hopper-b2b/api";
import { getLandingScreen } from "@hopper-b2b/utilities";
import { useExperiments, useTrackingProperties } from "@hopper-b2b/experiments";

import { useEffect, useState } from "react";
import { SessionInfo } from "@hopper-b2b/hopper-auth";
import { isEmpty } from "lodash-es";

export const useTrackLaunchedAppEvent = (sessionInfo?: SessionInfo) => {
  const [launchEventSent, setLaunchEventSent] = useState(false);
  const exps = useExperiments();
  const areExperimentsReady = !isEmpty(exps?.experiments);
  const trackingProperties: LaunchedApplicationProperties =
    useTrackingProperties({
      landing_screen: getLandingScreen(),
      url: document.location.pathname,
    });

  useEffect(() => {
    if (!launchEventSent && sessionInfo && areExperimentsReady) {
      trackEvent({
        eventName: LAUNCHED_APPLICATION,
        properties: trackingProperties,
      });
      setLaunchEventSent(true);
    }
  }, [launchEventSent, trackingProperties, sessionInfo, areExperimentsReady]);
};

import { defaultThemeConfigWithBasicColors } from "@hopper-b2b/themes";
import { asB2BMuiTheme } from "@hopper-b2b/themes";
import { ThemeOptions } from "@material-ui/core/styles";
import { LabComponentsPropsList } from "@material-ui/lab/themeAugmentation";
import ProximaNovaBoldOtf from "./assets/fonts/Proxima-Nova-Bold.otf";
import ProximaNovaExtraBoldOtf from "./assets/fonts/Proxima-Nova-Extrabold.otf";
import ProximaNovaRegularOtf from "./assets/fonts/Proxima-Nova-Medium.otf";
import ProximaNovaSemiBoldOtf from "./assets/fonts/Proxima-Nova-Semibold.otf";

export const hopperColors = {
  ...defaultThemeConfigWithBasicColors,
  black: "#000000",
  white: "#ffffff",
  grey: "#eeeeee",
  "gray-100": "#000000",
  "gray-90": "#111111",
  "gray-80": "#505050",
  "gray-70": "#606060",
  "gray-60": "#878787",
  "gray-50": "#9d9d9d",
  "gray-40": "#aaaaaa",
  "gray-30": "#d9d9d9",
  "gray-20": "#ededed",
  "gray-15": "#f5f5f5",
  "gray-10": "#f6f6f6",
  "gray-05": "#fbfbfb",
  "gray-0": "#ffffff",
  "slate-90": "#181d29",
  "slate-80": "#2f394e",
  "slate-60": "#556381",
  "slate-50": "#7b8aa7",
  "slate-40": "#abb7cd",
  "slate-30": "#cad1e0",
  "slate-20": "#e8ecf2",
  "slate-10": "#f4f6f9",
  "purple-60": "#f0edfc",
  "purple-50": "#a380d5",
  "purple-20": "#9166c5",
  "royal-blue-80": "#182672",
  "blue-60": "#008ecc",
  "blue-50": "#01aae4",
  "blue-40": "#55cef8",
  "blue-20": "#dff5ff",
  "teal-60": "#00959d",
  "teal-50": "#00b1bc",
  "teal-40": "#2bc9d2",
  "teal-20": "#e4f9fa",
  "green-60": "#4c9c35",
  "green-50": "#60b955",
  "green-40": "#89ce79",
  "green-20": "#ebf8e9",
  "yellow-60": "#ba7a00",
  "yellow-50": "#d69a00",
  "yellow-40": "#fac624",
  "yellow-20": "#fff4cc",
  "orange-60": "#ea651f",
  "orange-50": "#f28522",
  "orange-40": "#faa424",
  "orange-20": "#ffefd5",
  "coral-60": "#e04846",
  "coral-50": "#fa6866",
  "coral-20": "#ffebea",
  "red-20": "#ffebee",
  "red-30": "#ff5e5b",
  "red-40": "#CC2427",
  "red-50": "#f8576a",
  "red-60": "#de3e52",
};
export const hopperVariables = {
  ...hopperColors,
  primary: "var(--blue-50)",
  secondary: "var(--coral-60)",
  "primary-text": "var(--black)",
  "secondary-text": "var(--gray-80)",
  "primary-disabled": "var(--black-disabled)",
  "primary-hover": "var(--slate-10)",
  "button-background": "var(--secondary)",
  "button-background-disabled": "var(--primary-disabled)",
  "button-background-hover": "var(--primary-hover)",
  "button-border": "2px solid var(--gray-30)",
  "button-border-checked": "0.4rem solid var(--blue-50)",
  "mobile-banner": "var(--primary)",
  "loading-popup": "var(--white)",
  "link-text": "var(--blue-50)",
  "action-link-active-text": "var(--primary)",
  "hamburger-menu-color": "var(--white)",
  "filter-background-selected": "var(--grey)",
  "card-tag-text": "var(--blue-60)",
  "card-tag-background": "var(--blue-20)",
  "bucket-0-color": "var(--green-50)",
  "bucket-1-color": "var(--orange-40)",
  "bucket-2-color": "var(--orange-60)",
  "bucket-3-color": "var(--red-60)",
  "inverse-text": "var(--primary)",
  "input-label-text": "var(--grey-3000)",
  "red-error-color": "var(--red-40)",
  "card-border": "2px solid var(--card-border-color)",
  "price-freeze-card-color": "var(--blue-100)",
  "price-freeze-card-button": "var(--blue-200)",
  "price-freeze-tag": "var(--blue)",
  "success-color": "var(--green-50)",
  "pending-color": "var(--yellow)",
  "price-freeze-purchase-banner-background-color": "var(--blue-300)",
  "price-freeze-purchase-banner-text-color": "var(--primary)",
  "price-prediction-gradient-wait": "var(--red-60)",
  "price-prediction-gradient-fair": "var(--orange-60)",
  "price-prediction-gradient-good": "var(--orange-40)",
  "price-prediction-gradient-great": "var(--green-50)",
  "spinner-border-color": "var(--grey)",
  "spinner-top-border-color": "var(--coral-50)",
  "price-freeze-text": "var(--blue-400)",
  "date-hover-color": "var(--blue-40)",
  "warning-error-color-transparent": "var(--lightest-yellow)",
  "disruption-background-color": "var(--lightest-green)",
  "fare-details-banner": "var(--primary)",
  "fare-details-banner-text": "var(--white)",
  "search-autocomplete-subheader-background": "var(--white)",
  "button-border-radius": "1.25rem",
  "border-radius": "1.25rem",
  "border-radius-sm": "12px",
  "border-radius-md": "14px",
  "border-radius-lg": "20px",
  "border-radius-xl": "24px",
  "profile-background-color": "var(--gray-05)",
  "icon-color": "var(--skyblue)",
  "hopper-box-shadow":
    "0px 1px 6px 0px rgba(80, 80, 80, 0.15), 0px 1px 2px 0px rgba(80, 80, 80, 0.15)",
  //calendar
  "date-selected-color": "var(--blue-40)",
  "date-selected-text-color": "var(--blue-50)",
  "date-text-color": "var(--white)",
  // header
  "header-desktop-height": "64px",
  "header-primary": "var(--white)",
  "header-travel-destination": "var(--secondary-text)",
  "header-travel-passenger": "var(--secondary-text)",
  // footer
  "footer-desktop-height": "50px",
  // search
  "search-field-label": "var(--secondary-text)",
  "search-field-text": "var(--secondary-text)",
  // trips
  "trips-list-background-color": "var(--gray-05)",
  "trips-selected-filter-color": "var(--blue-20)",
  "trips-link-color": "var(--skyblue)",
  "profile-link-color": "var(--skyblue)",
  "secondary-button-background-disabled": "var(--gray-30)",
  "secondary-button-background-hover": "var(--gray-20)",
  "info-details-popup-background": "#FBFBFB",
};

const hopperTheming: ThemeOptions & Partial<LabComponentsPropsList> = {
  palette: {
    type: "light" as any,
    primary: {
      light: "#01AAE4",
      main: "#01AAE4",
      contrastText: hopperColors.white,
    },
    secondary: {
      light: "#FA6866",
      main: "#FA6866",
      contrastText: hopperColors.white,
    },
    text: {
      primary: hopperColors["gray-90"],
      secondary: hopperColors["gray-80"],
      disabled: hopperColors.white,
      hint: hopperColors.white,
    },
    action: {
      disabled: hopperColors["gray-80"],
    },
  },
  typography: {
    fontFamily: ["Proxima Nova", "sans-serif"].join(","),
    fontSize: 16,

    h1: {
      fontSize: 28,
      fontWeight: 700,
      color: hopperColors["gray-90"],
    },
    h2: {
      fontSize: 20,
      fontWeight: 700,
      color: hopperColors["gray-90"],
    },
    h3: {
      fontSize: 18,
      fontWeight: 700,
      color: hopperColors["gray-90"],
    },
    h4: {
      fontSize: 22,
      fontWeight: 700,
      color: hopperColors["gray-90"],
    },
    h5: {
      fontSize: 20,
      fontWeight: 500,
      color: hopperColors["gray-90"],
    },
    h6: {
      fontSize: 16,
      fontWeight: 700,
      color: hopperColors["gray-90"],
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 700,
      color: hopperColors["gray-80"],
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 700,
      color: hopperColors["gray-80"],
    },
    body1: {
      fontSize: 14,
      color: hopperColors["gray-80"],
    },
    body2: {
      fontSize: 16,
      color: hopperColors["gray-80"],
    },
    caption: {
      fontSize: 14,
      color: hopperColors["gray-80"],
    },
    button: {
      lineHeight: "1.25rem",
      textTransform: "none" as const,
    },
  },
  shape: {
    borderRadius: 20,
  },
  spacing: [0, 4, 8, 16, 32, 64],
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          {
            fontFamily: "Proxima Nova",
            fontStyle: "normal",
            fontWeight: 400,
            src: `
              local("Proxima Nova Regular"),
              url(${ProximaNovaRegularOtf}) format("opentype");
            `,
          },
          {
            fontFamily: "Proxima Nova",
            fontStyle: "normal",
            fontWeight: 600,
            src: `
              local("Proxima Nova Semibold"),
              url(${ProximaNovaSemiBoldOtf}) format("opentype");
            `,
          },
          {
            fontFamily: "Proxima Nova",
            fontStyle: "normal",
            fontWeight: 700,
            src: `
              local("Proxima Nova Bold"),
              url(${ProximaNovaBoldOtf}) format("opentype");
            `,
          },
          {
            fontFamily: "Proxima Nova",
            fontStyle: "normal",
            fontWeight: 800,
            src: `
              local("Proxima Nova Extrabold"),
              url(${ProximaNovaExtraBoldOtf}) format("opentype");
            `,
          },
        ],
      },
    },
    MuiButton: {
      root: {
        height: 50,

        fontSize: 16,
        borderRadius: 18,

        "&:active": {
          transform: "scale(0.9)",
        },
      },
      contained: {
        fontWeight: 700,
      },
      sizeSmall: {
        height: 32,
        fontSize: 14,
        borderRadius: 14,
      },
      sizeLarge: {
        height: 60,
        fontSize: 18,
        borderRadius: 20,
      },
      iconSizeSmall: {
        "& > *:first-child": {
          fontSize: 12,
        },
      },
      outlined: {
        fontWeight: 700,
        backgroundColor: hopperVariables.white,
        borderColor: hopperVariables["grey-11"],
      },
      outlinedPrimary: {
        borderColor: hopperVariables["grey-11"],
      },
    },
    MuiLink: {
      underlineHover: {
        "&:hover": {
          "text-decoration": "none",
        },
      },
    },
    MuiFilledInput: {
      root: {
        color: hopperColors["primary-text"],
        height: 60,
        backgroundColor: hopperVariables.white,
        borderRadius: 20,
        border: `1px solid ${hopperVariables["grey-11"]}`,
        "&.Mui-focused, &.Mui-hover": {
          backgroundColor: hopperVariables.white,
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 14,
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        "&[class*='MuiFilledInput-root']": {
          paddingLeft: 36,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        height: 60,
        backgroundColor: hopperVariables.white,
      },
      notchedOutline: {
        borderColor: hopperVariables["grey-11"],
      },
    },
    MuiFormControl: {
      root: {
        borderColor: hopperVariables["grey-11"],
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        "&.Mui-checked": {
          color: hopperVariables.primary,
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: "1rem",
      },
    },
    MuiChip: {
      sizeSmall: {
        fontSize: 14,
      },
    },
  },
};

export const muiTheme = asB2BMuiTheme(hopperTheming);

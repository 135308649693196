import {
  SelectSeatsRequest,
  SelectSeatsRequestV2,
} from "@b2bportal/air-seats-api";
import {
  PaymentId,
  PaymentOpaqueValue,
  Product,
  ProductId,
  ProductOpaqueValue,
} from "@b2bportal/purchase-api";
import { CallState } from "@hopper-b2b/types";
import { CartUpdateError } from "@checkout/types";

/**
 * Mapped from b2b-interfaces
 * https://github.com/hopper-org/b2b-interfaces/blob/ea72bac29a89511ab4aaaf47098c7fb619ce91d5/purchase/src/main/scala/com/hopper/b2b/purchase/Models.scala#L107
 */
export interface SeatProduct extends ProductOpaqueValue {
  value: SelectSeatsRequest | SelectSeatsRequestV2;
  type: Product;
}
export type MappedProducts = SeatProduct | ProductOpaqueValue;

// Params used to populate PurchaseQuoteUpdate request
export interface CartUpdateParams {
  addProducts: Array<MappedProducts>;
  removeProducts: Array<ProductId>;
  removePayments: Array<PaymentId>;
  addPayments: Array<PaymentOpaqueValue>;
  callState?: CallState;
}

export interface CartUpdateContext extends CartUpdateParams {
  error?: CartUpdateError;
}

export const CartUpdateInitialContext: CartUpdateContext = {
  addProducts: [],
  removeProducts: [],
  addPayments: [],
  removePayments: [],
  error: undefined,
  callState: CallState.NotCalled,
};

import { Box } from "@material-ui/core";
import "./styles.scss";
import clsx from "clsx";

export interface IIconContentViewProps {
  topRightButton?: JSX.Element;
  icon: JSX.Element;
  content: JSX.Element;
  className?: string;
  noMargin?: boolean;
  row?: boolean;
}

const defaultProps: Partial<IIconContentViewProps> = {
  row: false,
};

export const IconContentView = (props: IIconContentViewProps) => {
  const { topRightButton, icon, content, className, row, noMargin } = props;
  const directionClass = row ? "row-content" : "column-content";

  return (
    <Box
      className={clsx(
        "icon-content-view-container",
        className,
        directionClass,
        { noMargin }
      )}
    >
      <Box className="icon-container">{icon}</Box>
      <Box className="content-container">{content}</Box>
      {topRightButton && (
        <Box className="top-right-button-container">{topRightButton}</Box>
      )}
    </Box>
  );
};

IconContentView.defaultProps = defaultProps;

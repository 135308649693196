import {
  CipherText,
  FulfillResponse,
  purchaseApi,
} from "@b2bportal/purchase-api";
import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { axiosInstance } from "../AxiosInterceptor";
import { trackEvent } from "../tracking/trackEvent";

export const pollFulfill = async (
  req: CipherText,
  apiConfig?: IApiConfig
): Promise<FulfillResponse> => {
  try {
    const res = await purchaseApi(axiosInstance).apiV1PurchaseFulfillPollPut(
      req
    );
    return res.data;
  } catch (e) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      apiConfig
    );
    throw e;
  }
};

import * as React from "react";
import { TextField, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./styles.scss";
import { getDateTimeWithFormat } from "@hopper-b2b/utilities";

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  input: {
    "&&&:before": {
      overflow: "visible",
    },
    "&&:after": {
      overflow: "visible",
    },
  },
});

const createMenuItems = (days: number): JSX.Element[] =>
  new Array(days).fill(1).map((_, i) => {
    const value = (i + 1).toString();
    return (
      <MenuItem value={value} key={value}>
        {value}
      </MenuItem>
    );
  });

const getDaysInMonth = (month: string, year: string): number =>
  month && year
    ? getDateTimeWithFormat(`${year}-${month}`, "YYYY-M").daysInMonth()
    : month
    ? getDateTimeWithFormat(`${2000}-${month}`, "YYYY-M").daysInMonth()
    : 31;

export const DayDropdown = ({
  month,
  year,
  day,
  setDay,
  onChange,
}: {
  month: string;
  year: string;
  day: string;
  setDay: React.Dispatch<React.SetStateAction<string>>;
  onChange: () => void;
}) => {
  const classes = useStyles();
  const handleInputChange =
    (valueSetter: (value: any) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange();
      valueSetter(event.target.value.toString());
    };
  const [daysForMonth, setDaysForMonth] = React.useState(
    getDaysInMonth(month, year)
  );

  React.useEffect(() => {
    const newDaysForMonth = getDaysInMonth(month, year);

    if (newDaysForMonth !== daysForMonth) {
      setDaysForMonth(newDaysForMonth);
    }
  }, [month, year, daysForMonth]);

  return (
    <TextField
      id="day-select"
      select
      value={parseInt(day)}
      onChange={handleInputChange(setDay)}
      InputProps={{ classes }}
      className={`select-field`}
    >
      {createMenuItems(daysForMonth)}
    </TextField>
  );
};

import {
  postFetchActiveExperiments,
  putFetchActiveExperiments,
} from "@hopper-b2b/api";
import { ExperimentState, IApiConfig } from "@hopper-b2b/types";

export const initExperimentState = async (
  shouldFetchExperiments = false,
  apiConfig: IApiConfig,
  defaultInitState: ExperimentState,
  partnerExperiments?: string
): Promise<ExperimentState> => {
  // FYI: Most tenant BFFs are deprecating the PUT call - send in "none" when partnerExperiments is empty
  const fetchExperiments: () => Promise<ExperimentState> =
    shouldFetchExperiments
      ? partnerExperiments
        ? () => postFetchActiveExperiments(apiConfig, partnerExperiments)
        : () => putFetchActiveExperiments(apiConfig)
      : () => Promise.resolve(defaultInitState);

  return fetchExperiments();
};

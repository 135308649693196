import {
  HotelLoyaltyRequest,
  HotelLoyaltyResponse,
} from "@b2bportal/lodging-loyalty-api";

import { loyaltySubmit } from "@hopper-b2b/api";
import { HotelLoyaltySubmitTypes } from "@checkout/types";
import { CartContext } from "../../context";

import { getCartFulfilledLodgingBooking } from "../selectors";
import { LodgingContext, PassengerSelectors } from "@checkout/states";
import {
  getSelectedLodging,
  getSelectedRoomProduct,
} from "@checkout/states/Products/Lodging/selectors";
import { PartialParentWithPassengerContext } from "@checkout/states/common/PassengerInformation/context";

export const loyaltySubmitService = (context: CartContext, _event: unknown) =>
  new Promise((resolve, reject) => {
    const lodgingId = getSelectedLodging({ context: context as LodgingContext })
      .lodging.id;

    const hopperReservationBookingId = getCartFulfilledLodgingBooking({
      context,
    })?.reservationBookingId;

    const loyaltyProgramCode = getSelectedRoomProduct({
      context: context as LodgingContext,
    }).loyaltyProgramCode;

    const selectedPassengers = PassengerSelectors.getAllSelectedUserPassengers({
      context: context as unknown as PartialParentWithPassengerContext,
    });
    const loyaltyNumber = selectedPassengers.find(
      (passenger) =>
        passenger.hotelLoyalty != null &&
        passenger.hotelLoyalty[loyaltyProgramCode] != null
    )?.hotelLoyalty[loyaltyProgramCode]?.value;

    if (
      lodgingId != null &&
      hopperReservationBookingId != null &&
      loyaltyNumber != null
    ) {
      const hotelLoyaltyRequest: HotelLoyaltyRequest = {
        lodgingId: lodgingId,
        hopperReservationBookingId: hopperReservationBookingId,
        loyaltyNumber: loyaltyNumber,
      };

      loyaltySubmit(hotelLoyaltyRequest)
        .then((result: HotelLoyaltyResponse) => {
          if (result) {
            resolve(result);
          } else {
            reject({
              type: HotelLoyaltySubmitTypes.Failed,
            });
          }
        })
        .catch((e) => {
          reject({
            type: HotelLoyaltySubmitTypes.Failed,
          });
        });
    } else {
      resolve({
        type: HotelLoyaltySubmitTypes.NoLoyaltyNumberAssociatedWithUser,
      });
    }
  });

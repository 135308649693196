import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { Typography } from "@material-ui/core";
import { IconName } from "../Icon";
import { IconComponent } from "../IconComponent";
import styles from "./styles.module.scss";

export const Maintenance = () => {
  const { t } = useI18nContext();
  return (
    <div className={styles.maintenanceContainer}>
      <IconComponent className={styles.icon} name={IconName.ErrorState} />
      <Typography variant="h1" className={styles.title}>
        {t("maintenanceTitle")}
      </Typography>
      <Typography className={styles.subtitle} variant="body1">
        <I18nMarkup tKey="maintenanceSubtitle" />
      </Typography>
    </div>
  );
};

import { ADD_CONTACT_INFO, ProductType } from "@hopper-b2b/types";
import { trackEvent } from "@hopper-b2b/api";
import { PartialParentWithContact } from "../context";

export const trackAddContactEvent = (
  _context: PartialParentWithContact,
  event: { productType: ProductType; tripType?: string }
) =>
  new Promise((resolve) => {
    trackEvent({
      eventName: ADD_CONTACT_INFO,
      properties: {
        product_type: event?.productType,
        trip_type: event?.tripType,
      },
    })
      .then((_result: unknown) => resolve(null))
      .catch((_e: unknown) => resolve(null));
  });

import { ChatPropertiesType } from "@b2bportal/chat-api";
import { ChatTransfer } from "@b2bportal/ivr-api";
import { createContext } from "react";

export interface ISupportContext {
  productSupportChat: (
    productId: string,
    productType: ChatPropertiesType,
    requestType: string,
    chatTransfer?: ChatTransfer
  ) => void;
  generalSupportChat: (chatTransfer?: ChatTransfer) => void;
  openChatWindow: () => void;
  setHopperUserId: (hopperUserId: string) => void;
  setKustomerAccessToken: (kustomerAccessToken: string) => void;
  unreadCount: number;
}

export const SupportContext = createContext<Partial<ISupportContext>>({});

import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentContextWithCardPayment } from "./types";
import { ParentState } from "@checkout/types";

export const guards = {
  notVisitedPayment: (ctx: ParentContextWithCardPayment, _event) =>
    !ctx[ParentState.cardPayment].visited,

  haveAnyCardsLoaded: (ctx: ParentContextWithCardPayment, _event) => {
    const cardPayments = ctx[ParentState.cardPayment].paymentMethods;
    return cardPayments && cardPayments.length > 0;
  },
};

export const GuardTypes = getObjectKeysAsObject(guards);

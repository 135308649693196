import { getObjectKeysAsObject } from "../../../../helpers";
import { getQuoteBreakdownService } from "./getQuoteBreakdown";
import { updateQuoteService } from "./updateQuote";

export const services = {
  getQuoteBreakdownService,
  updateQuoteService,
};

export const ServiceTypes = getObjectKeysAsObject(services);

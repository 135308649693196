import type {
  PriceFreezeOffer,
  PriceFreezeVoucher,
} from "@b2bportal/price-freeze-api";
import type { HotelsPriceFreezeStateWithoutValue } from "./types";

// Offer - before purchase
export const getPriceFreezeOfferCap = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  return state.context.priceFreeze.priceFreezeOffers[0]?.serviceDetails
    .totalServiceCap.fiat;
};

export const getPriceFreezeOffers = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  return state.context.priceFreeze.priceFreezeOffers;
};

export const getSelectedPriceFreezeOffer = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  return state.context.priceFreeze.selectedOffer;
};

export const getSelectedPriceFreezeOfferTotalPrice = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  return state.context.priceFreeze.selectedOffer.serviceDetails.totalServiceFee
    .fiat;
};

export const getPriceFreezeOfferPricePerNight = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  return state.context.priceFreeze.selectedOffer?.serviceDetails
    .perNightServiceFee.fiat;
};

// Quoted Frozen Product
export const getFrozenProduct = (state: HotelsPriceFreezeStateWithoutValue) => {
  return state.context.cartQuote.quoteBreakdown.products
    .filter(({ product }) => product.type === "PriceFreeze")
    .map(
      ({ product }) => (product.value.offer as PriceFreezeOffer).frozenProduct
    )
    .find((product) => !!product);
};

export const getFrozenProductShoppedPrice = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  return getFrozenProduct(state)?.shoppedPrice;
};

export const getFrozenProductQuotedPrice = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  return getFrozenProduct(state)?.quotedPrice;
};

export const getCartQuoteCurrency = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  const price =
    getFrozenProductQuotedPrice(state) ?? getFrozenProductShoppedPrice(state);
  return {
    currencyCode: price.fiat.currencyCode,
    currencySymbol: price.fiat.currencySymbol,
  };
};

export const getShouldShowPriceChangeModal = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  const acknowledged = state.context.cartQuote.priceChange?.acknowledged;
  const hasPriceDifference = state.context.cartQuote.priceChange?.hasDifference;
  return hasPriceDifference && !acknowledged;
};

// Voucher - after purchase
export const getPriceFreezeVoucher = (
  state: HotelsPriceFreezeStateWithoutValue
) => {
  return state.context.cartFulfill.fulfilledProducts
    .filter((product) => product.type === "PriceFreeze")
    .map((product) => product.value.voucher as PriceFreezeVoucher)
    .find((product) => !!product);
};

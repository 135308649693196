import { getObjectKeysAsObject } from "../../../helpers";
import { CartState } from "../types";
import { CartContext } from "../context";

export const guards = {
  hasNoAction: (ctx: CartContext, _event: unknown) =>
    !ctx[CartState.cartFulfill].pendingInteractive ||
    !ctx[CartState.cartFulfill].pendingInteractive.actions ||
    ctx[CartState.cartFulfill].pendingInteractive.actions.length === 0,
};

export const GuardTypes = getObjectKeysAsObject(guards);

import type {
  TripPricingSummarySuccess,
  Failure,
  TripPricingSummaryResponse,
  PassengersForFareRequest,
  ErrorCode,
} from "@b2bportal/air-booking-api";

import { fetchTripPricing } from "@hopper-b2b/api";
import { ParentState } from "@checkout/types";
import type { ChildPassengerInformationContext } from "../states/PassengerInformation/context";
import { getTripPriceParams } from "../states/PassengerInformation/selectors";
import { getTripPriceParamsParent } from "../selectors";
import type { FlightContext } from "../types";

export const fetchTripPricingService = (
  context: ChildPassengerInformationContext | FlightContext
) =>
  new Promise((resolve, reject) => {
    let params: PassengersForFareRequest;
    if ((context as FlightContext)[ParentState.passengerInformation]) {
      params = getTripPriceParamsParent({
        context: context as FlightContext,
      });
    } else {
      params = getTripPriceParams({
        context: context as ChildPassengerInformationContext,
      });
    }

    fetchTripPricing(params)
      .then((response: TripPricingSummaryResponse) => {
        if ((response as Failure).errors) {
          reject(response as Failure);
        } else {
          resolve((response as TripPricingSummarySuccess).value);
        }
      })
      .catch((e) => {
        reject({
          errors: [
            { code: e.code ?? "UNKNOWN_ERROR", details: e.message },
          ] as ErrorCode[],
          Response: e.response,
        } as Failure);
      });
  });

export const uniquePhoneCodes = [
  "1",
  "7",
  "20",
  "27",
  "30",
  "31",
  "32",
  "33",
  "34",
  "36",
  "39",
  "40",
  "41",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "77",
  "81",
  "82",
  "84",
  "86",
  "90",
  "91",
  "92",
  "93",
  "94",
  "95",
  "98",
  "211",
  "212",
  "213",
  "216",
  "218",
  "220",
  "221",
  "222",
  "223",
  "224",
  "225",
  "226",
  "227",
  "228",
  "229",
  "230",
  "231",
  "232",
  "233",
  "234",
  "235",
  "236",
  "237",
  "238",
  "239",
  "240",
  "241",
  "242",
  "243",
  "244",
  "245",
  "246",
  "248",
  "249",
  "250",
  "251",
  "252",
  "253",
  "254",
  "255",
  "256",
  "257",
  "258",
  "260",
  "261",
  "262",
  "263",
  "264",
  "265",
  "266",
  "267",
  "268",
  "269",
  "290",
  "291",
  "297",
  "298",
  "299",
  "345",
  "350",
  "351",
  "352",
  "353",
  "354",
  "355",
  "356",
  "357",
  "358",
  "359",
  "370",
  "371",
  "372",
  "373",
  "374",
  "375",
  "376",
  "377",
  "378",
  "379",
  "380",
  "381",
  "382",
  "385",
  "386",
  "387",
  "389",
  "420",
  "421",
  "423",
  "500",
  "501",
  "502",
  "503",
  "504",
  "505",
  "506",
  "507",
  "508",
  "509",
  "590",
  "591",
  "593",
  "594",
  "595",
  "596",
  "597",
  "598",
  "599",
  "670",
  "672",
  "673",
  "674",
  "675",
  "676",
  "677",
  "678",
  "679",
  "680",
  "681",
  "682",
  "683",
  "685",
  "686",
  "687",
  "688",
  "689",
  "690",
  "691",
  "692",
  "850",
  "852",
  "853",
  "855",
  "856",
  "872",
  "880",
  "886",
  "960",
  "961",
  "962",
  "963",
  "964",
  "965",
  "966",
  "967",
  "968",
  "970",
  "971",
  "972",
  "973",
  "974",
  "975",
  "976",
  "977",
  "992",
  "993",
  "994",
  "995",
  "996",
  "998",
  "1242",
  "1246",
  "1264",
  "1268",
  "1284",
  "1340",
  "1441",
  "1473",
  "1649",
  "1664",
  "1670",
  "1671",
  "1684",
  "1758",
  "1767",
  "1784",
  "1849",
  "1868",
  "1869",
  "1876",
  "1939",
];

export const uniquePhoneCodesWithCountryCode = [
  { areaCode: "1", countryCode: "US" },
  { areaCode: "1", countryCode: "CA" },
  { areaCode: "7", countryCode: "RU" },
  { areaCode: "20", countryCode: "EG" },
  { areaCode: "27", countryCode: "ZA" },
  { areaCode: "30", countryCode: "GR" },
  { areaCode: "31", countryCode: "NL" },
  { areaCode: "32", countryCode: "BE" },
  { areaCode: "33", countryCode: "FR" },
  { areaCode: "34", countryCode: "ES" },
  { areaCode: "36", countryCode: "HU" },
  { areaCode: "39", countryCode: "IT" },
  { areaCode: "40", countryCode: "RO" },
  { areaCode: "41", countryCode: "CH" },
  { areaCode: "43", countryCode: "AT" },
  { areaCode: "44", countryCode: "GB" },
  { areaCode: "45", countryCode: "DK" },
  { areaCode: "46", countryCode: "SE" },
  { areaCode: "47", countryCode: "NO" },
  { areaCode: "48", countryCode: "PL" },
  { areaCode: "49", countryCode: "DE" },
  { areaCode: "51", countryCode: "PE" },
  { areaCode: "52", countryCode: "MX" },
  { areaCode: "53", countryCode: "CU" },
  { areaCode: "54", countryCode: "AR" },
  { areaCode: "55", countryCode: "BR" },
  { areaCode: "56", countryCode: "CL" },
  { areaCode: "57", countryCode: "CO" },
  { areaCode: "58", countryCode: "VE" },
  { areaCode: "60", countryCode: "MY" },
  { areaCode: "61", countryCode: "AU" },
  { areaCode: "62", countryCode: "ID" },
  { areaCode: "63", countryCode: "PH" },
  { areaCode: "64", countryCode: "NZ" },
  { areaCode: "65", countryCode: "SG" },
  { areaCode: "66", countryCode: "TH" },
  { areaCode: "77", countryCode: "KZ" },
  { areaCode: "81", countryCode: "JP" },
  { areaCode: "82", countryCode: "KR" },
  { areaCode: "84", countryCode: "VN" },
  { areaCode: "86", countryCode: "CN" },
  { areaCode: "90", countryCode: "TR" },
  { areaCode: "91", countryCode: "IN" },
  { areaCode: "92", countryCode: "PK" },
  { areaCode: "93", countryCode: "AF" },
  { areaCode: "94", countryCode: "LK" },
  { areaCode: "95", countryCode: "MM" },
  { areaCode: "98", countryCode: "IR" },
  { areaCode: "211", countryCode: "SS" },
  { areaCode: "212", countryCode: "MA" },
  { areaCode: "213", countryCode: "DZ" },
  { areaCode: "216", countryCode: "TN" },
  { areaCode: "218", countryCode: "LY" },
  { areaCode: "220", countryCode: "GM" },
  { areaCode: "221", countryCode: "SN" },
  { areaCode: "222", countryCode: "MR" },
  { areaCode: "223", countryCode: "ML" },
  { areaCode: "224", countryCode: "GN" },
  { areaCode: "225", countryCode: "CI" },
  { areaCode: "226", countryCode: "BF" },
  { areaCode: "227", countryCode: "NE" },
  { areaCode: "228", countryCode: "TG" },
  { areaCode: "229", countryCode: "BJ" },
  { areaCode: "230", countryCode: "MU" },
  { areaCode: "231", countryCode: "LR" },
  { areaCode: "232", countryCode: "SL" },
  { areaCode: "233", countryCode: "GH" },
  { areaCode: "234", countryCode: "NG" },
  { areaCode: "235", countryCode: "TD" },
  { areaCode: "236", countryCode: "CF" },
  { areaCode: "237", countryCode: "CM" },
  { areaCode: "238", countryCode: "CV" },
  { areaCode: "239", countryCode: "ST" },
  { areaCode: "240", countryCode: "GQ" },
  { areaCode: "241", countryCode: "GA" },
  { areaCode: "242", countryCode: "CG" },
  { areaCode: "243", countryCode: "CD" },
  { areaCode: "244", countryCode: "AO" },
  { areaCode: "245", countryCode: "GW" },
  { areaCode: "246", countryCode: "IO" },
  { areaCode: "248", countryCode: "SC" },
  { areaCode: "249", countryCode: "SD" },
  { areaCode: "250", countryCode: "RW" },
  { areaCode: "251", countryCode: "ET" },
  { areaCode: "252", countryCode: "SO" },
  { areaCode: "253", countryCode: "DJ" },
  { areaCode: "254", countryCode: "KE" },
  { areaCode: "255", countryCode: "TZ" },
  { areaCode: "256", countryCode: "UG" },
  { areaCode: "257", countryCode: "BI" },
  { areaCode: "258", countryCode: "MZ" },
  { areaCode: "260", countryCode: "ZM" },
  { areaCode: "261", countryCode: "MG" },
  { areaCode: "262", countryCode: "RE" },
  { areaCode: "263", countryCode: "ZW" },
  { areaCode: "264", countryCode: "NA" },
  { areaCode: "265", countryCode: "MW" },
  { areaCode: "266", countryCode: "LS" },
  { areaCode: "267", countryCode: "BW" },
  { areaCode: "268", countryCode: "SZ" },
  { areaCode: "269", countryCode: "KM" },
  { areaCode: "290", countryCode: "SH" },
  { areaCode: "291", countryCode: "ER" },
  { areaCode: "297", countryCode: "AW" },
  { areaCode: "298", countryCode: "FO" },
  { areaCode: "299", countryCode: "GL" },
  { areaCode: "345", countryCode: "KY" },
  { areaCode: "350", countryCode: "GI" },
  { areaCode: "351", countryCode: "PT" },
  { areaCode: "352", countryCode: "LU" },
  { areaCode: "353", countryCode: "IE" },
  { areaCode: "354", countryCode: "IS" },
  { areaCode: "355", countryCode: "AL" },
  { areaCode: "356", countryCode: "MT" },
  { areaCode: "357", countryCode: "CY" },
  { areaCode: "358", countryCode: "FI" },
  { areaCode: "359", countryCode: "BG" },
  { areaCode: "370", countryCode: "LT" },
  { areaCode: "371", countryCode: "LV" },
  { areaCode: "372", countryCode: "EE" },
  { areaCode: "373", countryCode: "MD" },
  { areaCode: "374", countryCode: "AM" },
  { areaCode: "375", countryCode: "BY" },
  { areaCode: "376", countryCode: "AD" },
  { areaCode: "377", countryCode: "MC" },
  { areaCode: "378", countryCode: "SM" },
  { areaCode: "379", countryCode: "VA" },
  { areaCode: "380", countryCode: "UA" },
  { areaCode: "381", countryCode: "RS" },
  { areaCode: "382", countryCode: "ME" },
  { areaCode: "385", countryCode: "HR" },
  { areaCode: "386", countryCode: "SI" },
  { areaCode: "387", countryCode: "BA" },
  { areaCode: "389", countryCode: "MK" },
  { areaCode: "420", countryCode: "CZ" },
  { areaCode: "421", countryCode: "SK" },
  { areaCode: "423", countryCode: "LI" },
  { areaCode: "500", countryCode: "FK" },
  { areaCode: "501", countryCode: "BZ" },
  { areaCode: "502", countryCode: "GT" },
  { areaCode: "503", countryCode: "SV" },
  { areaCode: "504", countryCode: "HN" },
  { areaCode: "505", countryCode: "NI" },
  { areaCode: "506", countryCode: "CR" },
  { areaCode: "507", countryCode: "PA" },
  { areaCode: "508", countryCode: "PM" },
  { areaCode: "509", countryCode: "HT" },
  { areaCode: "590", countryCode: "GP" },
  { areaCode: "591", countryCode: "BO" },
  { areaCode: "593", countryCode: "EC" },
  { areaCode: "594", countryCode: "GF" },
  { areaCode: "595", countryCode: "PY" },
  { areaCode: "596", countryCode: "MQ" },
  { areaCode: "597", countryCode: "SR" },
  { areaCode: "598", countryCode: "UY" },
  { areaCode: "599", countryCode: "BQ" },
  { areaCode: "670", countryCode: "TL" },
  { areaCode: "672", countryCode: "AQ" },
  { areaCode: "673", countryCode: "BN" },
  { areaCode: "674", countryCode: "NR" },
  { areaCode: "675", countryCode: "PG" },
  { areaCode: "676", countryCode: "TO" },
  { areaCode: "677", countryCode: "SB" },
  { areaCode: "678", countryCode: "VU" },
  { areaCode: "679", countryCode: "FJ" },
  { areaCode: "680", countryCode: "PW" },
  { areaCode: "681", countryCode: "WF" },
  { areaCode: "682", countryCode: "CK" },
  { areaCode: "683", countryCode: "NU" },
  { areaCode: "685", countryCode: "WS" },
  { areaCode: "686", countryCode: "KI" },
  { areaCode: "687", countryCode: "NC" },
  { areaCode: "688", countryCode: "TV" },
  { areaCode: "689", countryCode: "PF" },
  { areaCode: "690", countryCode: "TK" },
  { areaCode: "691", countryCode: "FM" },
  { areaCode: "692", countryCode: "MH" },
  { areaCode: "850", countryCode: "KP" },
  { areaCode: "852", countryCode: "HK" },
  { areaCode: "853", countryCode: "MO" },
  { areaCode: "855", countryCode: "KH" },
  { areaCode: "856", countryCode: "LA" },
  { areaCode: "872", countryCode: "KZ" },
  { areaCode: "880", countryCode: "BD" },
  { areaCode: "886", countryCode: "TW" },
  { areaCode: "960", countryCode: "MV" },
  { areaCode: "961", countryCode: "LB" },
  { areaCode: "962", countryCode: "JO" },
  { areaCode: "963", countryCode: "SY" },
  { areaCode: "964", countryCode: "IQ" },
  { areaCode: "965", countryCode: "KW" },
  { areaCode: "966", countryCode: "SA" },
  { areaCode: "967", countryCode: "YE" },
  { areaCode: "968", countryCode: "OM" },
  { areaCode: "970", countryCode: "PS" },
  { areaCode: "971", countryCode: "AE" },
  { areaCode: "972", countryCode: "IL" },
  { areaCode: "973", countryCode: "BH" },
  { areaCode: "974", countryCode: "QA" },
  { areaCode: "975", countryCode: "BT" },
  { areaCode: "976", countryCode: "MN" },
  { areaCode: "977", countryCode: "NP" },
  { areaCode: "992", countryCode: "TJ" },
  { areaCode: "993", countryCode: "TM" },
  { areaCode: "994", countryCode: "AZ" },
  { areaCode: "995", countryCode: "GE" },
  { areaCode: "996", countryCode: "KG" },
  { areaCode: "998", countryCode: "UZ" },
  { areaCode: "1242", countryCode: "BS" },
  { areaCode: "1246", countryCode: "BB" },
  { areaCode: "1264", countryCode: "AI" },
  { areaCode: "1268", countryCode: "AG" },
  { areaCode: "1284", countryCode: "VG" },
  { areaCode: "1340", countryCode: "VI" },
  { areaCode: "1441", countryCode: "BM" },
  { areaCode: "1473", countryCode: "GD" },
  { areaCode: "1649", countryCode: "TC" },
  { areaCode: "1664", countryCode: "MS" },
  { areaCode: "1670", countryCode: "MP" },
  { areaCode: "1671", countryCode: "GU" },
  { areaCode: "1684", countryCode: "AS" },
  { areaCode: "1758", countryCode: "LC" },
  { areaCode: "1767", countryCode: "DM" },
  { areaCode: "1784", countryCode: "VC" },
  { areaCode: "1849", countryCode: "SX" },
  { areaCode: "1868", countryCode: "TT" },
  { areaCode: "1869", countryCode: "KN" },
  { areaCode: "1876", countryCode: "JM" },
  { areaCode: "1939", countryCode: "PR" },
];

import { FunctionComponent, ReactNode } from "react";
import { Box } from "@material-ui/core";

import dayjs from "dayjs";
import { AirlineIcon } from "../../../AirlineIcon";
import { ItemPrice } from "../../../ItemPrice";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";

interface IAirline {
  code: string;
  name: string;
  iconOnly?: boolean;
  className?: string;
}
export const Airline = (props: IAirline) => (
  <span className={clsx("airline", props.className)}>
    <AirlineIcon airlineCode={props.code} altText={props.name} />{" "}
    {!props.iconOnly ? props.name : null}
  </span>
);

interface IPriceDisplay {
  currentPrice: string;
  discount?: string;
}
export const PriceDisplay = ({ currentPrice, discount }: IPriceDisplay) => (
  <ItemPrice current={currentPrice} discounted={discount} />
);

interface IRewardDisplay {
  reward: string;
}
export const RewardDisplay = ({ reward }: IRewardDisplay) => (
  <span className="reward">{reward}</span>
);

interface IStopSummaryProps {
  airports: string[];
  hideAirports?: boolean;
  separator?: string;
  maxAirports?: number;
  layoverDuration?: string;
}
export const StopSummary = ({
  airports,
  hideAirports,
  separator,
  maxAirports = 5,
  layoverDuration,
}: IStopSummaryProps) => {
  const { t } = useI18nContext();
  const renderAirports =
    !hideAirports && airports.length > 0 && airports.length <= maxAirports;

  return (
    <Box className="stop-summary">
      {airports.length === 0
        ? t("stopDetails.nonstop")
        : t("stopDetails.stop", { count: airports.length })}
      {renderAirports && separator ? separator : null}
      {renderAirports ? <span>{airports.join(", ")}</span> : null}
      {layoverDuration ? (
        <span className="stop-summary-duration">({layoverDuration})</span>
      ) : null}
    </Box>
  );
};

interface IFlightDurationProps {
  duration: string;
}
export const FlightDuration = ({ duration }: IFlightDurationProps) => {
  return <span className="duration">{duration}</span>;
};

interface IBrandNameProps {
  brandName: string;
}

export const BrandName = ({ brandName }: IBrandNameProps) => {
  return <span className="brand-name">{brandName}</span>;
};

export interface IFlightCardFieldsProps {
  children: ReactNode;
  className?: string;
}

export const FlightCardFields: FunctionComponent<IFlightCardFieldsProps> = ({
  children,
  className,
}: IFlightCardFieldsProps) => (
  <Box className={clsx("flight-card-fields", className)}>{children}</Box>
);

interface IFlightLocationsProps {
  originCode: string;
  destinationCode: string;
}
export const FlightLocations = ({
  originCode,
  destinationCode,
}: IFlightLocationsProps) => {
  return (
    <span className="locations">
      {originCode} - {destinationCode}
    </span>
  );
};

import { CreditCard, ListPaymentMethodsRequest } from "@b2bportal/card-api";
import {
  CartSelectors,
  getChildState,
  getNestedChildState,
  getParentState,
  ParentState,
  PaymentInformationChildState,
  PaymentInformationState,
} from "@checkout/index";
import { State } from "xstate";
import { LoadingMessages, ParentContextWithCardPayment } from "./types";

type CommonStateType = State<ParentContextWithCardPayment>;
type CommonStateWithoutValue = Pick<CommonStateType, "context">;

export const getOpenAddPaymentMethodModal = (state: CommonStateType) =>
  PaymentInformationState.add ===
    (getChildState(state.value) as PaymentInformationState) &&
  (getNestedChildState(state.value) as PaymentInformationChildState) ===
    PaymentInformationChildState.idle;

export const getIsPaymentLoading = (state: CommonStateType) => {
  return (
    (getParentState(state.value) === ParentState.cardPayment ||
      getParentState(state.value) === ParentState.guestCardPayment) &&
    // Getting user's cards
    (getChildState(state.value) === PaymentInformationState.loading ||
      // Adding new card
      (getChildState(state.value) === PaymentInformationState.add &&
        getNestedChildState(state.value) ===
          PaymentInformationChildState.verify) ||
      // Deleting card
      getChildState(state.value) === PaymentInformationState.delete)
  );
};

export const getLoadingMessageKey = (state: CommonStateType) => {
  const parentState = getParentState(state.value);
  const childState = getChildState(state.value);

  if (
    parentState === ParentState.cardPayment ||
    parentState === ParentState.guestCardPayment
  ) {
    switch (childState) {
      case PaymentInformationState.delete:
        return LoadingMessages.DELETE;
      case PaymentInformationState.loading:
      case PaymentInformationState.add:
      default:
        return LoadingMessages.ADD;
    }
  }
};

export const getPaymentMethods = (state: CommonStateWithoutValue) =>
  state.context[ParentState.cardPayment].paymentMethods;

export const getSelectedPaymentMethodId = (state: CommonStateWithoutValue) =>
  state.context[ParentState.cardPayment].selectedPaymentMethodId;

export const getPaymentVisited = (state: CommonStateWithoutValue) =>
  state.context[ParentState.cardPayment].visited;

export const getIsPaymentError = (state: CommonStateType) =>
  getParentState(state.value) === ParentState.cardPayment &&
  getChildState(state.value) === PaymentInformationState.error;

export const getPaymentError = (state: CommonStateType) =>
  state.context[ParentState.cardPayment].error;

export const getPaymentValidated = (state: CommonStateWithoutValue) =>
  state.context[ParentState.cardPayment].validated;

export const getPaymentListRequest = (
  state: CommonStateType | CommonStateWithoutValue
): ListPaymentMethodsRequest => {
  const balance = CartSelectors.getCartBreakdownBalance(state);
  return balance
    ? {
        amount: {
          amount: balance?.fiat.value,
          currency: balance?.fiat.currencyCode,
        },
      }
    : {};
};

export const getSelectedPaymentMethod = (
  state: CommonStateWithoutValue
): CreditCard | undefined => {
  const selectedPaymentMethodId = getSelectedPaymentMethodId(state);
  const paymentMethods = getPaymentMethods(state);

  return paymentMethods.find(
    (paymentMethod: CreditCard) => paymentMethod.id === selectedPaymentMethodId
  );
};

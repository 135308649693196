import { useI18nContext } from "@hopper-b2b/i18n";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import { useCallback } from "react";

export const LanguageSelectRadio = () => {
  const { brand, language, setLanguage, t } = useI18nContext();

  const handleLocaleSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLanguage(e.target.value);
    },
    [setLanguage]
  );

  // The component renders something only when the app supports more than a language
  if (brand.supportedLanguages == null || brand.supportedLanguages.length < 2) {
    return null;
  }

  return (
    <FormControl className="b2b-LanguageSelectRadio">
      <legend>{t("languages")}</legend>
      <RadioGroup
        aria-labelledby="lang-radio-buttons-group-label"
        defaultValue={language}
        name="language-buttons-group"
        onChange={handleLocaleSelect}
      >
        {brand.supportedLanguages?.map((l) => (
          <FormControlLabel
            key={l.key}
            name="locale"
            id={l.key}
            value={l.key}
            defaultChecked={language === l.key}
            checked={language === l.key}
            control={<Radio size="small" color="primary" />}
            label={l.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

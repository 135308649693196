import { FiatPrice, Prices, TrackingProperties } from "@b2bportal/purchase-api";

export interface OfferContent {
  name?: string;
  title?: string;
  lineItemDescription?: string;
  restrictions?: string;
  termsAndConditions?: string;
}

export enum OfferPaymentType {
  Credit = "Credit",
  Promotion = "Promotion",
  Offer = "Offer",
}

export interface OfferPaymentValue {
  offerId?: string;
  totalDiscountAmount: Prices;
  paymentType: OfferPaymentType;
}

export interface OfferPayment {
  type?: string;
  value?: OfferPaymentValue;
  offerId?: string;
  name?: string;
  totalDiscountAmount?: Prices;
  paymentType?: OfferPaymentType;
}

export interface WalletOffer {
  offerContent: OfferContent;
  amount: FiatPrice;
  percentage?: number;
  expiresOn: string;
  payment: OfferPayment;
  id: string;
  trackingPropertiesV2: TrackingProperties;
  maxDiscountAmount?: FiatPrice;
}

export enum GetWalletOffersResponseEnum {
  Failure = "Failure",
  Success = "Success",
}

export interface WalletVouchersResponse {
  GetVouchersResponse: GetWalletOffersResponseEnum;
  offers: WalletOffer[];
}

export interface AddTrackingPropertiesEvent {
  trackingProperties: TrackingProperties;
}

export enum WalletVoucherStatusEnum {
  EXPLICITLY_ACCEPTED = "explicitly_accepted",
  EXPLICITLY_DECLINED = "explicitly_declined",
  DEFAULT_OPT_IN = "default_opt_in",
}

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton } from "@material-ui/core";
import clsx from "clsx";
import "./styles.scss";

export interface IBackButtonProps {
  onClick: () => void;
  className?: string;
  icon?: JSX.Element;
}

export const BackButton = (props: IBackButtonProps) => {
  const { onClick, className, icon } = props;

  return (
    <Box className={clsx("back-button-wrapper", className)}>
      <IconButton
        size="small"
        onClick={onClick}
        className={clsx("back-button", className)}
      >
        {icon ?? (
          <FontAwesomeIcon
            className="back-arrow-text"
            icon={faChevronLeft as IconProp}
          />
        )}
      </IconButton>
    </Box>
  );
};

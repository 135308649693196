import { State } from "xstate";
import {
  getChildState,
  getNestedChildState,
  getParentState,
} from "../../../helpers";
import {
  ParentState,
  PassengerInformationChildState,
  PassengerInformationState,
} from "../../../types/common";
import { PartialParentWithPassengerContext } from "./context";

type PassengerStateType = State<PartialParentWithPassengerContext>;
type PassengerStateWithoutValue = Pick<PassengerStateType, "context">;

export const getIsPassengerInformationLoading = (state: PassengerStateType) => {
  const childState = getChildState(state.value) as PassengerInformationState;
  const nestedChildState = getNestedChildState(
    state.value
  ) as PassengerInformationChildState;

  return (
    childState === PassengerInformationState.loading ||
    [
      PassengerInformationChildState.add,
      PassengerInformationChildState.delete,
      PassengerInformationChildState.update,
    ].includes(nestedChildState)
  );
};

export const getNumPassengerAlertDismissed = (
  state: PassengerStateWithoutValue
) =>
  state.context[ParentState.passengerInformation]?.numPassengerAlertDismissed;

export const getCurrentUserPassenger = (state: PassengerStateWithoutValue) =>
  state.context[ParentState.passengerInformation]?.currentUserPassenger;

export const getOpenPassengerFormModal = (state: PassengerStateType) =>
  PassengerInformationState.passengerForm ===
  (getChildState(state.value) as PassengerInformationState);

export const getOpenPassengerPicker = (state: PassengerStateType) =>
  (getChildState(state.value) as PassengerInformationState) ===
  PassengerInformationState.passengerPicker;

export const getPassengerErrorOpen = (state: PassengerStateType) => {
  const parentStateValue = getParentState(state.value);
  const stateValue = getChildState(state.value);
  const isErrorState =
    parentStateValue === ParentState.passengerInformation &&
    PassengerInformationState.error === stateValue;
  return isErrorState;
};
export const getPassengerError = (state: PassengerStateWithoutValue) =>
  state.context[ParentState.passengerInformation]?.error;

export const getPassengerVisited = (state: PassengerStateWithoutValue) =>
  state.context[ParentState.passengerInformation]?.visited;

export const getUserPassengers = (state: PassengerStateWithoutValue) =>
  state.context[ParentState.passengerInformation]?.userPassengers;

export const getSelectedPassengerIds = (state: PassengerStateWithoutValue) =>
  state.context[ParentState.passengerInformation]?.selectedPassengerIds;

export const getAllSelectedUserPassengers = (
  state: PassengerStateWithoutValue
) => {
  const selectedPassengerIds = getSelectedPassengerIds(state);
  const userPassengers = getUserPassengers(state);

  return userPassengers.filter((passenger) =>
    selectedPassengerIds.includes(passenger.id)
  );
};

export const getPassengerStateValidated = (state: PassengerStateWithoutValue) =>
  getSelectedPassengerIds(state).length > 0;

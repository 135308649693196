import {
  AirPriceFreezeStateWithoutValue,
  AirPriceFreezeMachineStateWithoutValue,
} from "../types";
import { getFinalizedPriceFreeze } from "./priceFreeze";

export const getPassengerMap = (state: AirPriceFreezeStateWithoutValue) => {
  return state.context.passengerMap;
};

export const getPassengerCount = (state: AirPriceFreezeStateWithoutValue) => {
  return Object.values(state.context.passengerMap).reduce(
    (total, passengerValue) => {
      return (total += passengerValue);
    }
  );
};

export const getFinalizedPassengerMap = (
  state: AirPriceFreezeMachineStateWithoutValue
) => {
  return getFinalizedPriceFreeze(state)?.offer.passengers;
};

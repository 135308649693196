import { ADD_PAYMENT } from "@hopper-b2b/types";
import {
  CreditCard,
  PaymentVerifyResultEnum,
  ResponseEnum,
} from "@b2bportal/card-api";
import {
  verifyPaymentMethod,
  listPaymentMethods,
  trackEvent,
} from "@hopper-b2b/api";

import {
  ParentContextWithCardPayment,
  PaymentError,
  PaymentErrorModalTypes,
} from "../types";
import { VerifyPaymentMethodEvent } from "../events";
import { CardPaymentSelectors } from "..";

export const verifyPaymentService = (
  context: ParentContextWithCardPayment,
  event: VerifyPaymentMethodEvent
) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise<VerifyPaymentResponse>(async (resolve, reject) => {
    const errorCase = (error) => {
      trackEvent({
        eventName: ADD_PAYMENT,
        properties: { success: false },
      });
      reject({
        type: PaymentErrorModalTypes.VerifyPayment,
        data: error,
      });
    };
    try {
      const verifyRes = await verifyPaymentMethod({
        token: event.token,
        usageType: event.usageType,
      });
      switch (verifyRes.Response) {
        case ResponseEnum.Success: {
          switch (verifyRes.value.PaymentVerifyResult) {
            case PaymentVerifyResultEnum.Success: {
              const listReqBody = CardPaymentSelectors.getPaymentListRequest({
                context,
              });
              const listRes = await listPaymentMethods(listReqBody);
              switch (listRes.Response) {
                case ResponseEnum.Success: {
                  trackEvent({
                    eventName: ADD_PAYMENT,
                    properties: { success: true },
                  });
                  resolve({
                    paymentMethods: listRes.value,
                    selectedPaymentMethodId: verifyRes.value.payment.id,
                  });
                  break;
                }
                case ResponseEnum.Failure: {
                  reject({
                    type: PaymentErrorModalTypes.ListPaymentMethods,
                    data: listRes.errors,
                  });
                  break;
                }
              }
              break;
            }
            default: {
              errorCase(verifyRes.value.PaymentVerifyResult);
            }
          }
          break;
        }
        case ResponseEnum.Failure: {
          errorCase(verifyRes.errors);
          break;
        }
      }
    } catch (e) {
      errorCase(e);
    }
  });

export interface SuccessfulVerifyPaymentResponse {
  paymentMethods: CreditCard[];
  selectedPaymentMethodId: string;
}

export type VerifyPaymentResponse =
  | SuccessfulVerifyPaymentResponse
  | PaymentError;

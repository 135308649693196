import * as React from "react";
import { TextField, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./styles.scss";

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});

const createMenuItems = (start: number, end: number) => {
  const years = Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx);
  const yearItems = years.map((year) => (
    <MenuItem value={year} key={year}>
      {year}
    </MenuItem>
  ));
  yearItems.unshift(
    <MenuItem value={0} key="none">
      {""}
    </MenuItem>
  );
  return yearItems;
};

export const YearDropdown = ({
  year,
  setYear,
  onChange,
}: {
  year: string;
  setYear: React.Dispatch<React.SetStateAction<string>>;
  onChange: () => void;
}) => {
  const handleInputChange =
    (valueSetter: (value: any) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange();
      valueSetter(event.target.value.toString());
    };

  const classes = useStyles();
  const start = 1900;
  const end = new Date().getFullYear();
  const yearItems = createMenuItems(start, end);
  return (
    <TextField
      id="year-select"
      select
      value={year ? parseInt(year) : 0}
      onChange={handleInputChange(setYear)}
      InputProps={{ classes }}
      className={`select-field`}
    >
      {yearItems}
    </TextField>
  );
};

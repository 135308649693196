import {
  CreateSessionResponseEnum,
  GetSessionInfoResponseEnum,
  SessionInfo,
} from "@b2bportal/auth-api";
import { PropsWithChildren, useEffect } from "react";
import { fetchSessionInfo } from "../../api";
import {
  LoginCallback,
  createAnonymousSession,
  fetchSessionAndSetSession,
  useLogin,
  useRefreshToken,
} from "../../util";

interface IProtectedRouteProps extends PropsWithChildren {
  locale?: string;
  protectedUrl?: string;
  sessionInfo?: SessionInfo;
}

const startSession = async (
  onLogin: LoginCallback,
  refreshToken,
  refreshFromToken,
  locale: string
) => {
  try {
    const sessionInfoRes = await fetchSessionInfo();

    if (
      sessionInfoRes.GetSessionInfoResponse ===
        GetSessionInfoResponseEnum.NoSession ||
      sessionInfoRes.GetSessionInfoResponse ===
        GetSessionInfoResponseEnum.SessionRejected
    ) {
      if (refreshToken) {
        refreshFromToken();
      } else {
        const createSessionRes = await createAnonymousSession(locale);
        if (
          createSessionRes.CreateSessionResponse ===
          CreateSessionResponseEnum.CreateSessionSuccess
        ) {
          return fetchSessionAndSetSession(onLogin);
        }
      }
    } else if (
      sessionInfoRes.GetSessionInfoResponse ===
      GetSessionInfoResponseEnum.Session
    ) {
      onLogin(sessionInfoRes.sessionInfo);
    }
  } catch (error) {
    console.error(error);
    // TODO: HANDLE FAILURE
    // TODO: Add error tracking
  }
};

const ProtectedRoute = ({
  children,
  locale,
  sessionInfo,
}: IProtectedRouteProps) => {
  const onLogin = useLogin();
  const { refreshToken, refreshFromToken } = useRefreshToken();

  useEffect(() => {
    startSession(onLogin, refreshToken, refreshFromToken, locale);
  }, [locale, onLogin]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (
    <>{sessionInfo && !sessionInfo.userInfo ? "Unauthorized" : children}</>
  );
};

export default ProtectedRoute;

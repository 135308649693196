import {
  CipherText,
  purchaseApi,
  PurchaseErrorEnum,
  QuoteFailure,
  QuoteResponse,
  QuoteResponseEnum,
} from "@b2bportal/purchase-api";
import {
  IApiConfig,
  NETWORK_CALL_FAILED,
  NO_AVAILABILITY_AIR,
} from "@hopper-b2b/types";
import { axiosInstance } from "../AxiosInterceptor";
import { trackEvent } from "../tracking/trackEvent";

export const pollQuote = (
  req: CipherText,
  apiConfig?: IApiConfig
): Promise<QuoteResponse> =>
  new Promise((resolve, reject) => {
    purchaseApi(axiosInstance)
      .apiV1PurchaseQuotePollPut(req)
      .then((res) => {
        const responseBody = res.data as QuoteResponse;
        if (hasNoAvailabilityError(responseBody)) {
          trackEvent(
            {
              eventName: NO_AVAILABILITY_AIR,
              properties: {
                url: window.location.pathname,
              },
            },
            apiConfig
          );
        }

        resolve(responseBody);
      })
      .catch((e) => {
        trackEvent(
          {
            eventName: NETWORK_CALL_FAILED,
            properties: {
              url: window.location.pathname,
            },
          },
          apiConfig
        );
        reject(e);
      });
  });

const hasNoAvailabilityError = (quoteFailure: QuoteResponse) => {
  if (quoteFailure.QuoteResponse !== QuoteResponseEnum.Failure) return false;
  const hasNoAvailability = (quoteFailure as QuoteFailure).errors.some(
    (error) => error.Error === PurchaseErrorEnum.NoAvailability
  );
  return hasNoAvailability;
};

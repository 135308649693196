import * as React from "react";
import { Link } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleRight,
  faChevronCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";
import clsx from "clsx";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTenantIcons } from "@hopper-b2b/utilities";

export interface ICarouselArrowProps {
  direction: "right" | "left";
  onClickEvent: () => void;
}

export const CarouselArrow = ({
  direction,
  onClickEvent,
}: ICarouselArrowProps) => {
  const icons = useTenantIcons();
  return (
    <Link
      component="button"
      aria-label={direction === "left" ? "previous" : "next"}
      className={clsx(
        "carousel-arrow",
        { "carousel-arrow-left": direction === "left" },
        { "carousel-arrow-right": direction === "right" }
      )}
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        onClickEvent();
      }}
    >
      {icons.carouselArrowLeft && icons.carouselArrowRight ? (
        <img
          className="arrow-icon"
          src={
            direction === "right"
              ? icons.carouselArrowRight
              : icons.carouselArrowLeft
          }
          alt=""
        />
      ) : (
        <FontAwesomeIcon
          className="arrow-icon"
          icon={
            (direction === "right"
              ? faChevronCircleRight
              : faChevronCircleLeft) as IconProp
          }
          size={"2x"}
        />
      )}
    </Link>
  );
};

import { forwardRef } from "react";
import clsx from "clsx";
import "./styles.scss";

// this component's purpose is to convert any clickable element into an actual button
// to deal with accesability, NO MORE CLICKABLE DIVS
/* eslint-disable */
export const ButtonWrap = forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement>
>((props, ref) => {
  const { className, children, ...restProps } = props;
  return (
    <button className={clsx("button-wrap", className)} {...restProps} ref={ref}>
      {children}
    </button>
  );
});
/* eslint-enable */

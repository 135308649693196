import {
  mobileModel,
  browserName,
  osName,
  osVersion,
  mobileVendor,
  browserVersion,
} from "react-device-detect";
import { getDeviceData } from "./getDeviceData";

const getPlatform = () => {
  // In the hopper env ios & android is for the app
  // if (isIOS) return "c1";
  // if (isAndroid) return "m1";
  return "w1";
};

export const getUserDeviceData = () => {
  return {
    browserName,
    browser_version: browserVersion,
    $os: osName,
    $os_version: osVersion,
    platform: getPlatform(),
    deviceType: getDeviceData().type,
    device: mobileModel,
    $brand: mobileVendor,
    $manufacturer: mobileVendor,
    $model: mobileModel,
  };
};

import { getLang } from "./getLang";

export const ROOT_LANGUAGE_REGEX = /([a-z]*)\W?/i;

/**
 * Returns just the root language, excludes country example: `en-US` -> `en`
 */
export const getRootLang = (defaultLng: string): string => {
  const matches = getLang(defaultLng)?.match(ROOT_LANGUAGE_REGEX);
  return matches ? matches[1] : defaultLng;
};

import clsx from "clsx";
import dayjs from "dayjs";
import { ReactElement, useCallback, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";

import { FlightItinerarySlice } from "@b2bportal/air-booking-api";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCircle, faPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  FareSliceDetails,
  TrackingEventEntryPoint,
  TripSegment,
  FlightItinerarySlice as TripsLibFlightItinerarySlice,
} from "@hopper-b2b/types";
import { ActionButton } from "@hopper-b2b/ui-core";
import {
  formatInterval,
  removeTimezone,
  useDeviceTypes,
} from "@hopper-b2b/utilities";

import { FlightTimeDetails } from ".";
import { AirlineIcon } from "../AirlineIcon";
import { ScheduleChangeBadge } from "../ScheduleChangeBadge";
import { SliceToShow } from "../SliceDetails";

import "./styles.scss";

/**
 * Flight Details Summary Card
 * @function FlightDetailsSummary
 *
 */
export interface IFlightDetailsSummaryProps {
  className?: string;
  departureTime: string;
  onClick?: () => void;
  planeInfo: string;
  fareClass: string;
  segments: TripSegment[];
  showTitle?: boolean;
  plusDays?: number;
  header?: JSX.Element;
  fareSlice?: FareSliceDetails;
  isMixedCabinClass?: boolean;
  tripSlice?: TripsLibFlightItinerarySlice;
  onChange?: () => void;
  renderAirlineIconSection?: boolean;
  entryPoint?: TrackingEventEntryPoint;
  hasMajorScheduleChange?: boolean;
  hasMinorScheduleChange?: boolean;
  isOutgoing?: boolean;
  skchSlice?: FlightItinerarySlice;
  sliceToShow?: SliceToShow;
  totalDurationMinutes?: number;
}

const renderMixedCabinClass = (
  idx: number,
  fareClass: string,
  fareSlice?: FareSliceDetails,
  tripSlice?: TripsLibFlightItinerarySlice
) => {
  const segment = fareSlice
    ? fareSlice.fareDetails.segments[idx]
    : tripSlice
    ? tripSlice.segments[idx]
    : undefined;
  if (segment) {
    if (segment.cabinClassName && segment.cabinClassName.includes("Economy")) {
      return segment.cabinClassName;
    } else {
      return fareClass;
    }
  } else {
    return fareClass;
  }
};

// TODO add parameters for localization
export const FlightDetailsSummary = ({
  className,
  departureTime,
  fareClass,
  fareSlice,
  hasMajorScheduleChange,
  hasMinorScheduleChange,
  header,
  isMixedCabinClass,
  onChange,
  planeInfo,
  plusDays,
  renderAirlineIconSection = true,
  segments,
  showTitle = true,
  skchSlice,
  sliceToShow = SliceToShow.original,
  tripSlice,
}: IFlightDetailsSummaryProps): ReactElement => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  const content = useMemo(() => {
    return {
      operatedBy: (segment: TripSegment) =>
        t("operatedBy", {
          airline: segment.operatingAirline.name,
        }),
      layoverWithLocation: (segment: TripSegment) =>
        t("layoverWithLocation", {
          duration: formatInterval(segment.stopoverDurationMinutes || 0),
          location: segment.destinationCode,
        }),
      flightNextDay: t("flightNextDay"),
    };
  }, [t]);

  const renderPlaneInfo = useCallback(
    (segment: TripSegment, index: number) => (
      <Typography variant="body1" className="flight-info">
        {`${segment.airlineName} / ${segment.airlineCode}
                  ${segment.flightNumber} / ${
          isMixedCabinClass
            ? (fareSlice || tripSlice) &&
              renderMixedCabinClass(index, fareClass, fareSlice, tripSlice)
            : fareClass
        }`}
        {planeInfo && ` / ${planeInfo}`}
      </Typography>
    ),
    [fareClass, fareSlice, isMixedCabinClass, planeInfo, tripSlice]
  );

  const renderTripSlice = () => {
    return (
      <Box className="trip-slice">
        {segments.map((segment: TripSegment, index: number) => {
          let scheduledArrival, scheduledDeparture;
          let updatedArrival, updatedDeparture;
          const hasDifferentOperatingAirline =
            segment.operatingAirline?.code !== segment.marketingAirline?.code;
          const skchSegment = skchSlice?.segments[index];
          const hasScheduleChange =
            hasMajorScheduleChange || hasMinorScheduleChange;

          // use values from itinerary segment
          // comes from `getTripSegmentsFromItinerarySegments()`
          ({
            arrivalTime: scheduledArrival,
            departureTime: scheduledDeparture,
          } = segment);

          // use schedule change proposed times if available
          if (skchSegment) {
            ({
              scheduledArrival: updatedArrival,
              scheduledDeparture: updatedDeparture,
            } = skchSegment);

            updatedArrival = dayjs(removeTimezone(updatedArrival));
            updatedDeparture = dayjs(removeTimezone(updatedDeparture));
          }

          scheduledArrival = dayjs(removeTimezone(scheduledArrival));
          scheduledDeparture = dayjs(removeTimezone(scheduledDeparture));

          return (
            <Box key={`trip-segment-${segment.flightNumber}-${index}`}>
              <Box className={`trip-segment-${segment.flightNumber}`}>
                <Box className="flight-time-info-wrapper">
                  <Box className="time-info-line"></Box>
                  <Box className={"flight-time-info-container"}>
                    <Box className="time-details">
                      <FontAwesomeIcon
                        className="start-circle"
                        icon={faCircle as IconProp}
                      />
                      <FlightTimeDetails
                        flightType="departure"
                        hasScheduleChange={!!hasScheduleChange}
                        scheduledTime={scheduledDeparture}
                        segment={segment}
                        sliceToShow={sliceToShow}
                        timeFormat="h:mm A"
                        updatedTime={updatedDeparture}
                      />
                      <ScheduleChangeBadge
                        hasMajorScheduleChange={hasMajorScheduleChange}
                        hasMinorScheduleChange={hasMinorScheduleChange}
                      />
                    </Box>
                    <span className="trip-timing-line" />
                    <Typography variant="body1" className="travel-time">
                      {`${t("travelTime")} ${
                        hasScheduleChange && skchSlice
                          ? formatInterval(
                              dayjs(updatedArrival).diff(
                                dayjs(updatedDeparture),
                                "minutes"
                              )
                            )
                          : formatInterval(
                              dayjs(scheduledArrival).diff(
                                dayjs(scheduledDeparture),
                                "minutes"
                              )
                            )
                      }`}
                    </Typography>
                    <Box className="time-details">
                      <FontAwesomeIcon
                        className="end-circle"
                        icon={faCircle as IconProp}
                      />
                      <FlightTimeDetails
                        flightType="arrival"
                        hasScheduleChange={!!hasScheduleChange}
                        scheduledTime={scheduledArrival}
                        segment={segment}
                        sliceToShow={sliceToShow}
                        timeFormat="h:mm A"
                        updatedTime={updatedArrival}
                      />
                    </Box>
                    {renderAirlineIconSection ? null : (
                      <Box className="flight-info-details no-icon">
                        {renderPlaneInfo(segment, index)}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              {renderAirlineIconSection ? (
                <Box className="flight-info-details">
                  <Box className="airline-details">
                    <AirlineIcon
                      airlineCode={segment.airlineCode}
                      altText={segment.airlineName}
                    />
                  </Box>
                  {renderPlaneInfo(segment, index)}
                </Box>
              ) : null}
              {hasDifferentOperatingAirline && (
                <Typography className="operating-airline-info" variant="body1">
                  {content.operatedBy(segment)}
                </Typography>
              )}
              {segment.stopoverDurationMinutes && (
                <Typography variant="subtitle2" className="stopover-details">
                  {content.layoverWithLocation(segment)}
                </Typography>
              )}
            </Box>
          );
        })}

        {!!plusDays && (
          <div>
            <Typography variant="body2" className="plus-days">
              {content.flightNextDay}
            </Typography>
          </div>
        )}
      </Box>
    );
  };

  return (
    <Box
      className={clsx("flight-details-summary", className, {
        mobile: matchesMobile,
      })}
    >
      <Box className="flight-details-subtitle">
        {header && header}
        {showTitle && (
          <Typography variant="subtitle2">
            <>
              <FontAwesomeIcon icon={faPlane as IconProp} />
              {t("departingOnWithDate", {
                date: dayjs(removeTimezone(departureTime)).format("ddd, MMM D"),
              })}
            </>
          </Typography>
        )}
        {renderTripSlice()}
      </Box>
      {onChange ? (
        <Box>
          <ActionButton
            variant="outlined"
            color="primary"
            className="flight-details-change-cta"
            onClick={onChange}
            message={t("flightShopReview.changeFlight")}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default FlightDetailsSummary;

import airlineIconPLACEHOLDER2x from "../../assets/airlines/PLACEHOLDER/PLACEHOLDER_icon@2x.png";
import airlineIconPLACEHOLDER3x from "../../assets/airlines/PLACEHOLDER/PLACEHOLDER_icon@3x.png";
import airlineIconPLACEHOLDER4x from "../../assets/airlines/PLACEHOLDER/PLACEHOLDER_icon@4x.png";
import airlineIcon0A2x from "../../assets/airlines/0A/0A_icon@2x.png";
import airlineIcon0A3x from "../../assets/airlines/0A/0A_icon@3x.png";
import airlineIcon0A4x from "../../assets/airlines/0A/0A_icon@4x.png";
import airlineIcon2V2x from "../../assets/airlines/2V/2V_icon@2x.png";
import airlineIcon2V3x from "../../assets/airlines/2V/2V_icon@3x.png";
import airlineIcon2V4x from "../../assets/airlines/2V/2V_icon@4x.png";
import airlineIcon3V2x from "../../assets/airlines/3V/3V_icon@2x.png";
import airlineIcon3V3x from "../../assets/airlines/3V/3V_icon@3x.png";
import airlineIcon3V4x from "../../assets/airlines/3V/3V_icon@4x.png";
import airlineIcon4W2x from "../../assets/airlines/4W/4W_icon@2x.png";
import airlineIcon4W3x from "../../assets/airlines/4W/4W_icon@3x.png";
import airlineIcon4W4x from "../../assets/airlines/4W/4W_icon@4x.png";
import airlineIcon5W2x from "../../assets/airlines/5W/5W_icon@2x.png";
import airlineIcon5W3x from "../../assets/airlines/5W/5W_icon@3x.png";
import airlineIcon5W4x from "../../assets/airlines/5W/5W_icon@4x.png";
import airlineIcon7B2x from "../../assets/airlines/7B/7B_icon@2x.png";
import airlineIcon7B3x from "../../assets/airlines/7B/7B_icon@3x.png";
import airlineIcon7B4x from "../../assets/airlines/7B/7B_icon@4x.png";
import airlineIcon8C2x from "../../assets/airlines/8C/8C_icon@2x.png";
import airlineIcon8C3x from "../../assets/airlines/8C/8C_icon@3x.png";
import airlineIcon8C4x from "../../assets/airlines/8C/8C_icon@4x.png";
import airlineIcon9C2x from "../../assets/airlines/9C/9C_icon@2x.png";
import airlineIcon9C3x from "../../assets/airlines/9C/9C_icon@3x.png";
import airlineIcon9C4x from "../../assets/airlines/9C/9C_icon@4x.png";
import airlineIconA52x from "../../assets/airlines/A5/A5_icon@2x.png";
import airlineIconA53x from "../../assets/airlines/A5/A5_icon@3x.png";
import airlineIconA54x from "../../assets/airlines/A5/A5_icon@4x.png";
import airlineIconAS2x from "../../assets/airlines/AS/AS_icon@2x.png";
import airlineIconAS3x from "../../assets/airlines/AS/AS_icon@3x.png";
import airlineIconAS4x from "../../assets/airlines/AS/AS_icon@4x.png";
import airlineIconBJ2x from "../../assets/airlines/BJ/BJ_icon@2x.png";
import airlineIconBJ3x from "../../assets/airlines/BJ/BJ_icon@3x.png";
import airlineIconBJ4x from "../../assets/airlines/BJ/BJ_icon@4x.png";
import airlineIconCD2x from "../../assets/airlines/CD/CD_icon@2x.png";
import airlineIconCD3x from "../../assets/airlines/CD/CD_icon@3x.png";
import airlineIconCD4x from "../../assets/airlines/CD/CD_icon@4x.png";
import airlineIconD72x from "../../assets/airlines/D7/D7_icon@2x.png";
import airlineIconD73x from "../../assets/airlines/D7/D7_icon@3x.png";
import airlineIconD74x from "../../assets/airlines/D7/D7_icon@4x.png";
import airlineIconE62x from "../../assets/airlines/E6/E6_icon@2x.png";
import airlineIconE63x from "../../assets/airlines/E6/E6_icon@3x.png";
import airlineIconE64x from "../../assets/airlines/E6/E6_icon@4x.png";
import airlineIconEX2x from "../../assets/airlines/EX/EX_icon@2x.png";
import airlineIconEX3x from "../../assets/airlines/EX/EX_icon@3x.png";
import airlineIconEX4x from "../../assets/airlines/EX/EX_icon@4x.png";
import airlineIconFN2x from "../../assets/airlines/FN/FN_icon@2x.png";
import airlineIconFN3x from "../../assets/airlines/FN/FN_icon@3x.png";
import airlineIconFN4x from "../../assets/airlines/FN/FN_icon@4x.png";
import airlineIconGE2x from "../../assets/airlines/GE/GE_icon@2x.png";
import airlineIconGE3x from "../../assets/airlines/GE/GE_icon@3x.png";
import airlineIconGE4x from "../../assets/airlines/GE/GE_icon@4x.png";
import airlineIconH92x from "../../assets/airlines/H9/H9_icon@2x.png";
import airlineIconH93x from "../../assets/airlines/H9/H9_icon@3x.png";
import airlineIconH94x from "../../assets/airlines/H9/H9_icon@4x.png";
import airlineIconI52x from "../../assets/airlines/I5/I5_icon@2x.png";
import airlineIconI53x from "../../assets/airlines/I5/I5_icon@3x.png";
import airlineIconI54x from "../../assets/airlines/I5/I5_icon@4x.png";
import airlineIconIW2x from "../../assets/airlines/IW/IW_icon@2x.png";
import airlineIconIW3x from "../../assets/airlines/IW/IW_icon@3x.png";
import airlineIconIW4x from "../../assets/airlines/IW/IW_icon@4x.png";
import airlineIconJM2x from "../../assets/airlines/JM/JM_icon@2x.png";
import airlineIconJM3x from "../../assets/airlines/JM/JM_icon@3x.png";
import airlineIconJM4x from "../../assets/airlines/JM/JM_icon@4x.png";
import airlineIconKF2x from "../../assets/airlines/KF/KF_icon@2x.png";
import airlineIconKF3x from "../../assets/airlines/KF/KF_icon@3x.png";
import airlineIconKF4x from "../../assets/airlines/KF/KF_icon@4x.png";
import airlineIconL82x from "../../assets/airlines/L8/L8_icon@2x.png";
import airlineIconL83x from "../../assets/airlines/L8/L8_icon@3x.png";
import airlineIconL84x from "../../assets/airlines/L8/L8_icon@4x.png";
import airlineIconLX2x from "../../assets/airlines/LX/LX_icon@2x.png";
import airlineIconLX3x from "../../assets/airlines/LX/LX_icon@3x.png";
import airlineIconLX4x from "../../assets/airlines/LX/LX_icon@4x.png";
import airlineIconMO2x from "../../assets/airlines/MO/MO_icon@2x.png";
import airlineIconMO3x from "../../assets/airlines/MO/MO_icon@3x.png";
import airlineIconMO4x from "../../assets/airlines/MO/MO_icon@4x.png";
import airlineIconNE2x from "../../assets/airlines/NE/NE_icon@2x.png";
import airlineIconNE3x from "../../assets/airlines/NE/NE_icon@3x.png";
import airlineIconNE4x from "../../assets/airlines/NE/NE_icon@4x.png";
import airlineIconOE2x from "../../assets/airlines/OE/OE_icon@2x.png";
import airlineIconOE3x from "../../assets/airlines/OE/OE_icon@3x.png";
import airlineIconOE4x from "../../assets/airlines/OE/OE_icon@4x.png";
import airlineIconP32x from "../../assets/airlines/P3/P3_icon@2x.png";
import airlineIconP33x from "../../assets/airlines/P3/P3_icon@3x.png";
import airlineIconP34x from "../../assets/airlines/P3/P3_icon@4x.png";
import airlineIconPS2x from "../../assets/airlines/PS/PS_icon@2x.png";
import airlineIconPS3x from "../../assets/airlines/PS/PS_icon@3x.png";
import airlineIconPS4x from "../../assets/airlines/PS/PS_icon@4x.png";
import airlineIconQK2x from "../../assets/airlines/QK/QK_icon@2x.png";
import airlineIconQK3x from "../../assets/airlines/QK/QK_icon@3x.png";
import airlineIconQK4x from "../../assets/airlines/QK/QK_icon@4x.png";
import airlineIconRB2x from "../../assets/airlines/RB/RB_icon@2x.png";
import airlineIconRB3x from "../../assets/airlines/RB/RB_icon@3x.png";
import airlineIconRB4x from "../../assets/airlines/RB/RB_icon@4x.png";
import airlineIconS32x from "../../assets/airlines/S3/S3_icon@2x.png";
import airlineIconS33x from "../../assets/airlines/S3/S3_icon@3x.png";
import airlineIconS34x from "../../assets/airlines/S3/S3_icon@4x.png";
import airlineIconSR2x from "../../assets/airlines/SR/SR_icon@2x.png";
import airlineIconSR3x from "../../assets/airlines/SR/SR_icon@3x.png";
import airlineIconSR4x from "../../assets/airlines/SR/SR_icon@4x.png";
import airlineIconTI2x from "../../assets/airlines/TI/TI_icon@2x.png";
import airlineIconTI3x from "../../assets/airlines/TI/TI_icon@3x.png";
import airlineIconTI4x from "../../assets/airlines/TI/TI_icon@4x.png";
import airlineIconUA2x from "../../assets/airlines/UA/UA_icon@2x.png";
import airlineIconUA3x from "../../assets/airlines/UA/UA_icon@3x.png";
import airlineIconUA4x from "../../assets/airlines/UA/UA_icon@4x.png";
import airlineIconV32x from "../../assets/airlines/V3/V3_icon@2x.png";
import airlineIconV33x from "../../assets/airlines/V3/V3_icon@3x.png";
import airlineIconV34x from "../../assets/airlines/V3/V3_icon@4x.png";
import airlineIconVV2x from "../../assets/airlines/VV/VV_icon@2x.png";
import airlineIconVV3x from "../../assets/airlines/VV/VV_icon@3x.png";
import airlineIconVV4x from "../../assets/airlines/VV/VV_icon@4x.png";
import airlineIconWN2x from "../../assets/airlines/WN/WN_icon@2x.png";
import airlineIconWN3x from "../../assets/airlines/WN/WN_icon@3x.png";
import airlineIconWN4x from "../../assets/airlines/WN/WN_icon@4x.png";
import airlineIconXJ2x from "../../assets/airlines/XJ/XJ_icon@2x.png";
import airlineIconXJ3x from "../../assets/airlines/XJ/XJ_icon@3x.png";
import airlineIconXJ4x from "../../assets/airlines/XJ/XJ_icon@4x.png";
import airlineIconYL2x from "../../assets/airlines/YL/YL_icon@2x.png";
import airlineIconYL3x from "../../assets/airlines/YL/YL_icon@3x.png";
import airlineIconYL4x from "../../assets/airlines/YL/YL_icon@4x.png";
import airlineIconZI2x from "../../assets/airlines/ZI/ZI_icon@2x.png";
import airlineIconZI3x from "../../assets/airlines/ZI/ZI_icon@3x.png";
import airlineIconZI4x from "../../assets/airlines/ZI/ZI_icon@4x.png";
import airlineIcon0B2x from "../../assets/airlines/0B/0B_icon@2x.png";
import airlineIcon0B3x from "../../assets/airlines/0B/0B_icon@3x.png";
import airlineIcon0B4x from "../../assets/airlines/0B/0B_icon@4x.png";
import airlineIcon2W2x from "../../assets/airlines/2W/2W_icon@2x.png";
import airlineIcon2W3x from "../../assets/airlines/2W/2W_icon@3x.png";
import airlineIcon2W4x from "../../assets/airlines/2W/2W_icon@4x.png";
import airlineIcon3W2x from "../../assets/airlines/3W/3W_icon@2x.png";
import airlineIcon3W3x from "../../assets/airlines/3W/3W_icon@3x.png";
import airlineIcon3W4x from "../../assets/airlines/3W/3W_icon@4x.png";
import airlineIcon4X2x from "../../assets/airlines/4X/4X_icon@2x.png";
import airlineIcon4X3x from "../../assets/airlines/4X/4X_icon@3x.png";
import airlineIcon4X4x from "../../assets/airlines/4X/4X_icon@4x.png";
import airlineIcon5X2x from "../../assets/airlines/5X/5X_icon@2x.png";
import airlineIcon5X3x from "../../assets/airlines/5X/5X_icon@3x.png";
import airlineIcon5X4x from "../../assets/airlines/5X/5X_icon@4x.png";
import airlineIcon7C2x from "../../assets/airlines/7C/7C_icon@2x.png";
import airlineIcon7C3x from "../../assets/airlines/7C/7C_icon@3x.png";
import airlineIcon7C4x from "../../assets/airlines/7C/7C_icon@4x.png";
import airlineIcon8D2x from "../../assets/airlines/8D/8D_icon@2x.png";
import airlineIcon8D3x from "../../assets/airlines/8D/8D_icon@3x.png";
import airlineIcon8D4x from "../../assets/airlines/8D/8D_icon@4x.png";
import airlineIcon9D2x from "../../assets/airlines/9D/9D_icon@2x.png";
import airlineIcon9D3x from "../../assets/airlines/9D/9D_icon@3x.png";
import airlineIcon9D4x from "../../assets/airlines/9D/9D_icon@4x.png";
import airlineIconA62x from "../../assets/airlines/A6/A6_icon@2x.png";
import airlineIconA63x from "../../assets/airlines/A6/A6_icon@3x.png";
import airlineIconA64x from "../../assets/airlines/A6/A6_icon@4x.png";
import airlineIconAT2x from "../../assets/airlines/AT/AT_icon@2x.png";
import airlineIconAT3x from "../../assets/airlines/AT/AT_icon@3x.png";
import airlineIconAT4x from "../../assets/airlines/AT/AT_icon@4x.png";
import airlineIconBK2x from "../../assets/airlines/BK/BK_icon@2x.png";
import airlineIconBK3x from "../../assets/airlines/BK/BK_icon@3x.png";
import airlineIconBK4x from "../../assets/airlines/BK/BK_icon@4x.png";
import airlineIconCE2x from "../../assets/airlines/CE/CE_icon@2x.png";
import airlineIconCE3x from "../../assets/airlines/CE/CE_icon@3x.png";
import airlineIconCE4x from "../../assets/airlines/CE/CE_icon@4x.png";
import airlineIconD82x from "../../assets/airlines/D8/D8_icon@2x.png";
import airlineIconD83x from "../../assets/airlines/D8/D8_icon@3x.png";
import airlineIconD84x from "../../assets/airlines/D8/D8_icon@4x.png";
import airlineIconE72x from "../../assets/airlines/E7/E7_icon@2x.png";
import airlineIconE73x from "../../assets/airlines/E7/E7_icon@3x.png";
import airlineIconE74x from "../../assets/airlines/E7/E7_icon@4x.png";
import airlineIconEY2x from "../../assets/airlines/EY/EY_icon@2x.png";
import airlineIconEY3x from "../../assets/airlines/EY/EY_icon@3x.png";
import airlineIconEY4x from "../../assets/airlines/EY/EY_icon@4x.png";
import airlineIconFO2x from "../../assets/airlines/FO/FO_icon@2x.png";
import airlineIconFO3x from "../../assets/airlines/FO/FO_icon@3x.png";
import airlineIconFO4x from "../../assets/airlines/FO/FO_icon@4x.png";
import airlineIconGF2x from "../../assets/airlines/GF/GF_icon@2x.png";
import airlineIconGF3x from "../../assets/airlines/GF/GF_icon@3x.png";
import airlineIconGF4x from "../../assets/airlines/GF/GF_icon@4x.png";
import airlineIconHA2x from "../../assets/airlines/HA/HA_icon@2x.png";
import airlineIconHA3x from "../../assets/airlines/HA/HA_icon@3x.png";
import airlineIconHA4x from "../../assets/airlines/HA/HA_icon@4x.png";
import airlineIconI72x from "../../assets/airlines/I7/I7_icon@2x.png";
import airlineIconI73x from "../../assets/airlines/I7/I7_icon@3x.png";
import airlineIconI74x from "../../assets/airlines/I7/I7_icon@4x.png";
import airlineIconIX2x from "../../assets/airlines/IX/IX_icon@2x.png";
import airlineIconIX3x from "../../assets/airlines/IX/IX_icon@3x.png";
import airlineIconIX4x from "../../assets/airlines/IX/IX_icon@4x.png";
import airlineIconJO2x from "../../assets/airlines/JO/JO_icon@2x.png";
import airlineIconJO3x from "../../assets/airlines/JO/JO_icon@3x.png";
import airlineIconJO4x from "../../assets/airlines/JO/JO_icon@4x.png";
import airlineIconKG2x from "../../assets/airlines/KG/KG_icon@2x.png";
import airlineIconKG3x from "../../assets/airlines/KG/KG_icon@3x.png";
import airlineIconKG4x from "../../assets/airlines/KG/KG_icon@4x.png";
import airlineIconL92x from "../../assets/airlines/L9/L9_icon@2x.png";
import airlineIconL93x from "../../assets/airlines/L9/L9_icon@3x.png";
import airlineIconL94x from "../../assets/airlines/L9/L9_icon@4x.png";
import airlineIconLY2x from "../../assets/airlines/LY/LY_icon@2x.png";
import airlineIconLY3x from "../../assets/airlines/LY/LY_icon@3x.png";
import airlineIconLY4x from "../../assets/airlines/LY/LY_icon@4x.png";
import airlineIconMP2x from "../../assets/airlines/MP/MP_icon@2x.png";
import airlineIconMP3x from "../../assets/airlines/MP/MP_icon@3x.png";
import airlineIconMP4x from "../../assets/airlines/MP/MP_icon@4x.png";
import airlineIconNF2x from "../../assets/airlines/NF/NF_icon@2x.png";
import airlineIconNF3x from "../../assets/airlines/NF/NF_icon@3x.png";
import airlineIconNF4x from "../../assets/airlines/NF/NF_icon@4x.png";
import airlineIconOF2x from "../../assets/airlines/OF/OF_icon@2x.png";
import airlineIconOF3x from "../../assets/airlines/OF/OF_icon@3x.png";
import airlineIconOF4x from "../../assets/airlines/OF/OF_icon@4x.png";
import airlineIconP42x from "../../assets/airlines/P4/P4_icon@2x.png";
import airlineIconP43x from "../../assets/airlines/P4/P4_icon@3x.png";
import airlineIconP44x from "../../assets/airlines/P4/P4_icon@4x.png";
import airlineIconPT2x from "../../assets/airlines/PT/PT_icon@2x.png";
import airlineIconPT3x from "../../assets/airlines/PT/PT_icon@3x.png";
import airlineIconPT4x from "../../assets/airlines/PT/PT_icon@4x.png";
import airlineIconQL2x from "../../assets/airlines/QL/QL_icon@2x.png";
import airlineIconQL3x from "../../assets/airlines/QL/QL_icon@3x.png";
import airlineIconQL4x from "../../assets/airlines/QL/QL_icon@4x.png";
import airlineIconRC2x from "../../assets/airlines/RC/RC_icon@2x.png";
import airlineIconRC3x from "../../assets/airlines/RC/RC_icon@3x.png";
import airlineIconRC4x from "../../assets/airlines/RC/RC_icon@4x.png";
import airlineIconS42x from "../../assets/airlines/S4/S4_icon@2x.png";
import airlineIconS43x from "../../assets/airlines/S4/S4_icon@3x.png";
import airlineIconS44x from "../../assets/airlines/S4/S4_icon@4x.png";
import airlineIconSS2x from "../../assets/airlines/SS/SS_icon@2x.png";
import airlineIconSS3x from "../../assets/airlines/SS/SS_icon@3x.png";
import airlineIconSS4x from "../../assets/airlines/SS/SS_icon@4x.png";
import airlineIconTJ2x from "../../assets/airlines/TJ/TJ_icon@2x.png";
import airlineIconTJ3x from "../../assets/airlines/TJ/TJ_icon@3x.png";
import airlineIconTJ4x from "../../assets/airlines/TJ/TJ_icon@4x.png";
import airlineIconUB2x from "../../assets/airlines/UB/UB_icon@2x.png";
import airlineIconUB3x from "../../assets/airlines/UB/UB_icon@3x.png";
import airlineIconUB4x from "../../assets/airlines/UB/UB_icon@4x.png";
import airlineIconV42x from "../../assets/airlines/V4/V4_icon@2x.png";
import airlineIconV43x from "../../assets/airlines/V4/V4_icon@3x.png";
import airlineIconV44x from "../../assets/airlines/V4/V4_icon@4x.png";
import airlineIconVW2x from "../../assets/airlines/VW/VW_icon@2x.png";
import airlineIconVW3x from "../../assets/airlines/VW/VW_icon@3x.png";
import airlineIconVW4x from "../../assets/airlines/VW/VW_icon@4x.png";
import airlineIconWO2x from "../../assets/airlines/WO/WO_icon@2x.png";
import airlineIconWO3x from "../../assets/airlines/WO/WO_icon@3x.png";
import airlineIconWO4x from "../../assets/airlines/WO/WO_icon@4x.png";
import airlineIconXK2x from "../../assets/airlines/XK/XK_icon@2x.png";
import airlineIconXK3x from "../../assets/airlines/XK/XK_icon@3x.png";
import airlineIconXK4x from "../../assets/airlines/XK/XK_icon@4x.png";
import airlineIconYN2x from "../../assets/airlines/YN/YN_icon@2x.png";
import airlineIconYN3x from "../../assets/airlines/YN/YN_icon@3x.png";
import airlineIconYN4x from "../../assets/airlines/YN/YN_icon@4x.png";
import airlineIconZJ2x from "../../assets/airlines/ZJ/ZJ_icon@2x.png";
import airlineIconZJ3x from "../../assets/airlines/ZJ/ZJ_icon@3x.png";
import airlineIconZJ4x from "../../assets/airlines/ZJ/ZJ_icon@4x.png";
import airlineIcon0J2x from "../../assets/airlines/0J/0J_icon@2x.png";
import airlineIcon0J3x from "../../assets/airlines/0J/0J_icon@3x.png";
import airlineIcon0J4x from "../../assets/airlines/0J/0J_icon@4x.png";
import airlineIcon2Y2x from "../../assets/airlines/2Y/2Y_icon@2x.png";
import airlineIcon2Y3x from "../../assets/airlines/2Y/2Y_icon@3x.png";
import airlineIcon2Y4x from "../../assets/airlines/2Y/2Y_icon@4x.png";
import airlineIcon3X2x from "../../assets/airlines/3X/3X_icon@2x.png";
import airlineIcon3X3x from "../../assets/airlines/3X/3X_icon@3x.png";
import airlineIcon3X4x from "../../assets/airlines/3X/3X_icon@4x.png";
import airlineIcon4Y2x from "../../assets/airlines/4Y/4Y_icon@2x.png";
import airlineIcon4Y3x from "../../assets/airlines/4Y/4Y_icon@3x.png";
import airlineIcon4Y4x from "../../assets/airlines/4Y/4Y_icon@4x.png";
import airlineIcon5Y2x from "../../assets/airlines/5Y/5Y_icon@2x.png";
import airlineIcon5Y3x from "../../assets/airlines/5Y/5Y_icon@3x.png";
import airlineIcon5Y4x from "../../assets/airlines/5Y/5Y_icon@4x.png";
import airlineIcon7E2x from "../../assets/airlines/7E/7E_icon@2x.png";
import airlineIcon7E3x from "../../assets/airlines/7E/7E_icon@3x.png";
import airlineIcon7E4x from "../../assets/airlines/7E/7E_icon@4x.png";
import airlineIcon8E2x from "../../assets/airlines/8E/8E_icon@2x.png";
import airlineIcon8E3x from "../../assets/airlines/8E/8E_icon@3x.png";
import airlineIcon8E4x from "../../assets/airlines/8E/8E_icon@4x.png";
import airlineIcon9E2x from "../../assets/airlines/9E/9E_icon@2x.png";
import airlineIcon9E3x from "../../assets/airlines/9E/9E_icon@3x.png";
import airlineIcon9E4x from "../../assets/airlines/9E/9E_icon@4x.png";
import airlineIconA72x from "../../assets/airlines/A7/A7_icon@2x.png";
import airlineIconA73x from "../../assets/airlines/A7/A7_icon@3x.png";
import airlineIconA74x from "../../assets/airlines/A7/A7_icon@4x.png";
import airlineIconAU2x from "../../assets/airlines/AU/AU_icon@2x.png";
import airlineIconAU3x from "../../assets/airlines/AU/AU_icon@3x.png";
import airlineIconAU4x from "../../assets/airlines/AU/AU_icon@4x.png";
import airlineIconBL2x from "../../assets/airlines/BL/BL_icon@2x.png";
import airlineIconBL3x from "../../assets/airlines/BL/BL_icon@3x.png";
import airlineIconBL4x from "../../assets/airlines/BL/BL_icon@4x.png";
import airlineIconCG2x from "../../assets/airlines/CG/CG_icon@2x.png";
import airlineIconCG3x from "../../assets/airlines/CG/CG_icon@3x.png";
import airlineIconCG4x from "../../assets/airlines/CG/CG_icon@4x.png";
import airlineIconD92x from "../../assets/airlines/D9/D9_icon@2x.png";
import airlineIconD93x from "../../assets/airlines/D9/D9_icon@3x.png";
import airlineIconD94x from "../../assets/airlines/D9/D9_icon@4x.png";
import airlineIconE92x from "../../assets/airlines/E9/E9_icon@2x.png";
import airlineIconE93x from "../../assets/airlines/E9/E9_icon@3x.png";
import airlineIconE94x from "../../assets/airlines/E9/E9_icon@4x.png";
import airlineIconEZ2x from "../../assets/airlines/EZ/EZ_icon@2x.png";
import airlineIconEZ3x from "../../assets/airlines/EZ/EZ_icon@3x.png";
import airlineIconEZ4x from "../../assets/airlines/EZ/EZ_icon@4x.png";
import airlineIconFP2x from "../../assets/airlines/FP/FP_icon@2x.png";
import airlineIconFP3x from "../../assets/airlines/FP/FP_icon@3x.png";
import airlineIconFP4x from "../../assets/airlines/FP/FP_icon@4x.png";
import airlineIconGI2x from "../../assets/airlines/GI/GI_icon@2x.png";
import airlineIconGI3x from "../../assets/airlines/GI/GI_icon@3x.png";
import airlineIconGI4x from "../../assets/airlines/GI/GI_icon@4x.png";
import airlineIconHB2x from "../../assets/airlines/HB/HB_icon@2x.png";
import airlineIconHB3x from "../../assets/airlines/HB/HB_icon@3x.png";
import airlineIconHB4x from "../../assets/airlines/HB/HB_icon@4x.png";
import airlineIconI82x from "../../assets/airlines/I8/I8_icon@2x.png";
import airlineIconI83x from "../../assets/airlines/I8/I8_icon@3x.png";
import airlineIconI84x from "../../assets/airlines/I8/I8_icon@4x.png";
import airlineIconIY2x from "../../assets/airlines/IY/IY_icon@2x.png";
import airlineIconIY3x from "../../assets/airlines/IY/IY_icon@3x.png";
import airlineIconIY4x from "../../assets/airlines/IY/IY_icon@4x.png";
import airlineIconJP2x from "../../assets/airlines/JP/JP_icon@2x.png";
import airlineIconJP3x from "../../assets/airlines/JP/JP_icon@3x.png";
import airlineIconJP4x from "../../assets/airlines/JP/JP_icon@4x.png";
import airlineIconKH2x from "../../assets/airlines/KH/KH_icon@2x.png";
import airlineIconKH3x from "../../assets/airlines/KH/KH_icon@3x.png";
import airlineIconKH4x from "../../assets/airlines/KH/KH_icon@4x.png";
import airlineIconLA2x from "../../assets/airlines/LA/LA_icon@2x.png";
import airlineIconLA3x from "../../assets/airlines/LA/LA_icon@3x.png";
import airlineIconLA4x from "../../assets/airlines/LA/LA_icon@4x.png";
import airlineIconLZ2x from "../../assets/airlines/LZ/LZ_icon@2x.png";
import airlineIconLZ3x from "../../assets/airlines/LZ/LZ_icon@3x.png";
import airlineIconLZ4x from "../../assets/airlines/LZ/LZ_icon@4x.png";
import airlineIconMQ2x from "../../assets/airlines/MQ/MQ_icon@2x.png";
import airlineIconMQ3x from "../../assets/airlines/MQ/MQ_icon@3x.png";
import airlineIconMQ4x from "../../assets/airlines/MQ/MQ_icon@4x.png";
import airlineIconNG2x from "../../assets/airlines/NG/NG_icon@2x.png";
import airlineIconNG3x from "../../assets/airlines/NG/NG_icon@3x.png";
import airlineIconNG4x from "../../assets/airlines/NG/NG_icon@4x.png";
import airlineIconOG2x from "../../assets/airlines/OG/OG_icon@2x.png";
import airlineIconOG3x from "../../assets/airlines/OG/OG_icon@3x.png";
import airlineIconOG4x from "../../assets/airlines/OG/OG_icon@4x.png";
import airlineIconP52x from "../../assets/airlines/P5/P5_icon@2x.png";
import airlineIconP53x from "../../assets/airlines/P5/P5_icon@3x.png";
import airlineIconP54x from "../../assets/airlines/P5/P5_icon@4x.png";
import airlineIconPU2x from "../../assets/airlines/PU/PU_icon@2x.png";
import airlineIconPU3x from "../../assets/airlines/PU/PU_icon@3x.png";
import airlineIconPU4x from "../../assets/airlines/PU/PU_icon@4x.png";
import airlineIconQM2x from "../../assets/airlines/QM/QM_icon@2x.png";
import airlineIconQM3x from "../../assets/airlines/QM/QM_icon@3x.png";
import airlineIconQM4x from "../../assets/airlines/QM/QM_icon@4x.png";
import airlineIconRD2x from "../../assets/airlines/RD/RD_icon@2x.png";
import airlineIconRD3x from "../../assets/airlines/RD/RD_icon@3x.png";
import airlineIconRD4x from "../../assets/airlines/RD/RD_icon@4x.png";
import airlineIconS52x from "../../assets/airlines/S5/S5_icon@2x.png";
import airlineIconS53x from "../../assets/airlines/S5/S5_icon@3x.png";
import airlineIconS54x from "../../assets/airlines/S5/S5_icon@4x.png";
import airlineIconST2x from "../../assets/airlines/ST/ST_icon@2x.png";
import airlineIconST3x from "../../assets/airlines/ST/ST_icon@3x.png";
import airlineIconST4x from "../../assets/airlines/ST/ST_icon@4x.png";
import airlineIconTK2x from "../../assets/airlines/TK/TK_icon@2x.png";
import airlineIconTK3x from "../../assets/airlines/TK/TK_icon@3x.png";
import airlineIconTK4x from "../../assets/airlines/TK/TK_icon@4x.png";
import airlineIconUC2x from "../../assets/airlines/UC/UC_icon@2x.png";
import airlineIconUC3x from "../../assets/airlines/UC/UC_icon@3x.png";
import airlineIconUC4x from "../../assets/airlines/UC/UC_icon@4x.png";
import airlineIconV52x from "../../assets/airlines/V5/V5_icon@2x.png";
import airlineIconV53x from "../../assets/airlines/V5/V5_icon@3x.png";
import airlineIconV54x from "../../assets/airlines/V5/V5_icon@4x.png";
import airlineIconVX2x from "../../assets/airlines/VX/VX_icon@2x.png";
import airlineIconVX3x from "../../assets/airlines/VX/VX_icon@3x.png";
import airlineIconVX4x from "../../assets/airlines/VX/VX_icon@4x.png";
import airlineIconWP2x from "../../assets/airlines/WP/WP_icon@2x.png";
import airlineIconWP3x from "../../assets/airlines/WP/WP_icon@3x.png";
import airlineIconWP4x from "../../assets/airlines/WP/WP_icon@4x.png";
import airlineIconXL2x from "../../assets/airlines/XL/XL_icon@2x.png";
import airlineIconXL3x from "../../assets/airlines/XL/XL_icon@3x.png";
import airlineIconXL4x from "../../assets/airlines/XL/XL_icon@4x.png";
import airlineIconYO2x from "../../assets/airlines/YO/YO_icon@2x.png";
import airlineIconYO3x from "../../assets/airlines/YO/YO_icon@3x.png";
import airlineIconYO4x from "../../assets/airlines/YO/YO_icon@4x.png";
import airlineIconZK2x from "../../assets/airlines/ZK/ZK_icon@2x.png";
import airlineIconZK3x from "../../assets/airlines/ZK/ZK_icon@3x.png";
import airlineIconZK4x from "../../assets/airlines/ZK/ZK_icon@4x.png";
import airlineIcon0V2x from "../../assets/airlines/0V/0V_icon@2x.png";
import airlineIcon0V3x from "../../assets/airlines/0V/0V_icon@3x.png";
import airlineIcon0V4x from "../../assets/airlines/0V/0V_icon@4x.png";
import airlineIcon2Z2x from "../../assets/airlines/2Z/2Z_icon@2x.png";
import airlineIcon2Z3x from "../../assets/airlines/2Z/2Z_icon@3x.png";
import airlineIcon2Z4x from "../../assets/airlines/2Z/2Z_icon@4x.png";
import airlineIcon3Z2x from "../../assets/airlines/3Z/3Z_icon@2x.png";
import airlineIcon3Z3x from "../../assets/airlines/3Z/3Z_icon@3x.png";
import airlineIcon3Z4x from "../../assets/airlines/3Z/3Z_icon@4x.png";
import airlineIcon4Z2x from "../../assets/airlines/4Z/4Z_icon@2x.png";
import airlineIcon4Z3x from "../../assets/airlines/4Z/4Z_icon@3x.png";
import airlineIcon4Z4x from "../../assets/airlines/4Z/4Z_icon@4x.png";
import airlineIcon5Z2x from "../../assets/airlines/5Z/5Z_icon@2x.png";
import airlineIcon5Z3x from "../../assets/airlines/5Z/5Z_icon@3x.png";
import airlineIcon5Z4x from "../../assets/airlines/5Z/5Z_icon@4x.png";
import airlineIcon7F2x from "../../assets/airlines/7F/7F_icon@2x.png";
import airlineIcon7F3x from "../../assets/airlines/7F/7F_icon@3x.png";
import airlineIcon7F4x from "../../assets/airlines/7F/7F_icon@4x.png";
import airlineIcon8F2x from "../../assets/airlines/8F/8F_icon@2x.png";
import airlineIcon8F3x from "../../assets/airlines/8F/8F_icon@3x.png";
import airlineIcon8F4x from "../../assets/airlines/8F/8F_icon@4x.png";
import airlineIcon9F2x from "../../assets/airlines/9F/9F_icon@2x.png";
import airlineIcon9F3x from "../../assets/airlines/9F/9F_icon@3x.png";
import airlineIcon9F4x from "../../assets/airlines/9F/9F_icon@4x.png";
import airlineIconA82x from "../../assets/airlines/A8/A8_icon@2x.png";
import airlineIconA83x from "../../assets/airlines/A8/A8_icon@3x.png";
import airlineIconA84x from "../../assets/airlines/A8/A8_icon@4x.png";
import airlineIconAV2x from "../../assets/airlines/AV/AV_icon@2x.png";
import airlineIconAV3x from "../../assets/airlines/AV/AV_icon@3x.png";
import airlineIconAV4x from "../../assets/airlines/AV/AV_icon@4x.png";
import airlineIconBM2x from "../../assets/airlines/BM/BM_icon@2x.png";
import airlineIconBM3x from "../../assets/airlines/BM/BM_icon@3x.png";
import airlineIconBM4x from "../../assets/airlines/BM/BM_icon@4x.png";
import airlineIconCI2x from "../../assets/airlines/CI/CI_icon@2x.png";
import airlineIconCI3x from "../../assets/airlines/CI/CI_icon@3x.png";
import airlineIconCI4x from "../../assets/airlines/CI/CI_icon@4x.png";
import airlineIconDD2x from "../../assets/airlines/DD/DD_icon@2x.png";
import airlineIconDD3x from "../../assets/airlines/DD/DD_icon@3x.png";
import airlineIconDD4x from "../../assets/airlines/DD/DD_icon@4x.png";
import airlineIconEB2x from "../../assets/airlines/EB/EB_icon@2x.png";
import airlineIconEB3x from "../../assets/airlines/EB/EB_icon@3x.png";
import airlineIconEB4x from "../../assets/airlines/EB/EB_icon@4x.png";
import airlineIconF02x from "../../assets/airlines/F0/F0_icon@2x.png";
import airlineIconF03x from "../../assets/airlines/F0/F0_icon@3x.png";
import airlineIconF04x from "../../assets/airlines/F0/F0_icon@4x.png";
import airlineIconFR2x from "../../assets/airlines/FR/FR_icon@2x.png";
import airlineIconFR3x from "../../assets/airlines/FR/FR_icon@3x.png";
import airlineIconFR4x from "../../assets/airlines/FR/FR_icon@4x.png";
import airlineIconGJ2x from "../../assets/airlines/GJ/GJ_icon@2x.png";
import airlineIconGJ3x from "../../assets/airlines/GJ/GJ_icon@3x.png";
import airlineIconGJ4x from "../../assets/airlines/GJ/GJ_icon@4x.png";
import airlineIconHC2x from "../../assets/airlines/HC/HC_icon@2x.png";
import airlineIconHC3x from "../../assets/airlines/HC/HC_icon@3x.png";
import airlineIconHC4x from "../../assets/airlines/HC/HC_icon@4x.png";
import airlineIconI92x from "../../assets/airlines/I9/I9_icon@2x.png";
import airlineIconI93x from "../../assets/airlines/I9/I9_icon@3x.png";
import airlineIconI94x from "../../assets/airlines/I9/I9_icon@4x.png";
import airlineIconIZ2x from "../../assets/airlines/IZ/IZ_icon@2x.png";
import airlineIconIZ3x from "../../assets/airlines/IZ/IZ_icon@3x.png";
import airlineIconIZ4x from "../../assets/airlines/IZ/IZ_icon@4x.png";
import airlineIconJQ2x from "../../assets/airlines/JQ/JQ_icon@2x.png";
import airlineIconJQ3x from "../../assets/airlines/JQ/JQ_icon@3x.png";
import airlineIconJQ4x from "../../assets/airlines/JQ/JQ_icon@4x.png";
import airlineIconKI2x from "../../assets/airlines/KI/KI_icon@2x.png";
import airlineIconKI3x from "../../assets/airlines/KI/KI_icon@3x.png";
import airlineIconKI4x from "../../assets/airlines/KI/KI_icon@4x.png";
import airlineIconLB2x from "../../assets/airlines/LB/LB_icon@2x.png";
import airlineIconLB3x from "../../assets/airlines/LB/LB_icon@3x.png";
import airlineIconLB4x from "../../assets/airlines/LB/LB_icon@4x.png";
import airlineIconM02x from "../../assets/airlines/M0/M0_icon@2x.png";
import airlineIconM03x from "../../assets/airlines/M0/M0_icon@3x.png";
import airlineIconM04x from "../../assets/airlines/M0/M0_icon@4x.png";
import airlineIconMR2x from "../../assets/airlines/MR/MR_icon@2x.png";
import airlineIconMR3x from "../../assets/airlines/MR/MR_icon@3x.png";
import airlineIconMR4x from "../../assets/airlines/MR/MR_icon@4x.png";
import airlineIconNH2x from "../../assets/airlines/NH/NH_icon@2x.png";
import airlineIconNH3x from "../../assets/airlines/NH/NH_icon@3x.png";
import airlineIconNH4x from "../../assets/airlines/NH/NH_icon@4x.png";
import airlineIconOH2x from "../../assets/airlines/OH/OH_icon@2x.png";
import airlineIconOH3x from "../../assets/airlines/OH/OH_icon@3x.png";
import airlineIconOH4x from "../../assets/airlines/OH/OH_icon@4x.png";
import airlineIconP62x from "../../assets/airlines/P6/P6_icon@2x.png";
import airlineIconP63x from "../../assets/airlines/P6/P6_icon@3x.png";
import airlineIconP64x from "../../assets/airlines/P6/P6_icon@4x.png";
import airlineIconPV2x from "../../assets/airlines/PV/PV_icon@2x.png";
import airlineIconPV3x from "../../assets/airlines/PV/PV_icon@3x.png";
import airlineIconPV4x from "../../assets/airlines/PV/PV_icon@4x.png";
import airlineIconQN2x from "../../assets/airlines/QN/QN_icon@2x.png";
import airlineIconQN3x from "../../assets/airlines/QN/QN_icon@3x.png";
import airlineIconQN4x from "../../assets/airlines/QN/QN_icon@4x.png";
import airlineIconRF2x from "../../assets/airlines/RF/RF_icon@2x.png";
import airlineIconRF3x from "../../assets/airlines/RF/RF_icon@3x.png";
import airlineIconRF4x from "../../assets/airlines/RF/RF_icon@4x.png";
import airlineIconS62x from "../../assets/airlines/S6/S6_icon@2x.png";
import airlineIconS63x from "../../assets/airlines/S6/S6_icon@3x.png";
import airlineIconS64x from "../../assets/airlines/S6/S6_icon@4x.png";
import airlineIconSU2x from "../../assets/airlines/SU/SU_icon@2x.png";
import airlineIconSU3x from "../../assets/airlines/SU/SU_icon@3x.png";
import airlineIconSU4x from "../../assets/airlines/SU/SU_icon@4x.png";
import airlineIconTL2x from "../../assets/airlines/TL/TL_icon@2x.png";
import airlineIconTL3x from "../../assets/airlines/TL/TL_icon@3x.png";
import airlineIconTL4x from "../../assets/airlines/TL/TL_icon@4x.png";
import airlineIconUD2x from "../../assets/airlines/UD/UD_icon@2x.png";
import airlineIconUD3x from "../../assets/airlines/UD/UD_icon@3x.png";
import airlineIconUD4x from "../../assets/airlines/UD/UD_icon@4x.png";
import airlineIconV72x from "../../assets/airlines/V7/V7_icon@2x.png";
import airlineIconV73x from "../../assets/airlines/V7/V7_icon@3x.png";
import airlineIconV74x from "../../assets/airlines/V7/V7_icon@4x.png";
import airlineIconVY2x from "../../assets/airlines/VY/VY_icon@2x.png";
import airlineIconVY3x from "../../assets/airlines/VY/VY_icon@3x.png";
import airlineIconVY4x from "../../assets/airlines/VY/VY_icon@4x.png";
import airlineIconWQ2x from "../../assets/airlines/WQ/WQ_icon@2x.png";
import airlineIconWQ3x from "../../assets/airlines/WQ/WQ_icon@3x.png";
import airlineIconWQ4x from "../../assets/airlines/WQ/WQ_icon@4x.png";
import airlineIconXM2x from "../../assets/airlines/XM/XM_icon@2x.png";
import airlineIconXM3x from "../../assets/airlines/XM/XM_icon@3x.png";
import airlineIconXM4x from "../../assets/airlines/XM/XM_icon@4x.png";
import airlineIconYP2x from "../../assets/airlines/YP/YP_icon@2x.png";
import airlineIconYP3x from "../../assets/airlines/YP/YP_icon@3x.png";
import airlineIconYP4x from "../../assets/airlines/YP/YP_icon@4x.png";
import airlineIconZL2x from "../../assets/airlines/ZL/ZL_icon@2x.png";
import airlineIconZL3x from "../../assets/airlines/ZL/ZL_icon@3x.png";
import airlineIconZL4x from "../../assets/airlines/ZL/ZL_icon@4x.png";
import airlineIcon1I2x from "../../assets/airlines/1I/1I_icon@2x.png";
import airlineIcon1I3x from "../../assets/airlines/1I/1I_icon@3x.png";
import airlineIcon1I4x from "../../assets/airlines/1I/1I_icon@4x.png";
import airlineIcon3A2x from "../../assets/airlines/3A/3A_icon@2x.png";
import airlineIcon3A3x from "../../assets/airlines/3A/3A_icon@3x.png";
import airlineIcon3A4x from "../../assets/airlines/3A/3A_icon@4x.png";
import airlineIcon4A2x from "../../assets/airlines/4A/4A_icon@2x.png";
import airlineIcon4A3x from "../../assets/airlines/4A/4A_icon@3x.png";
import airlineIcon4A4x from "../../assets/airlines/4A/4A_icon@4x.png";
import airlineIcon5A2x from "../../assets/airlines/5A/5A_icon@2x.png";
import airlineIcon5A3x from "../../assets/airlines/5A/5A_icon@3x.png";
import airlineIcon5A4x from "../../assets/airlines/5A/5A_icon@4x.png";
import airlineIcon6A2x from "../../assets/airlines/6A/6A_icon@2x.png";
import airlineIcon6A3x from "../../assets/airlines/6A/6A_icon@3x.png";
import airlineIcon6A4x from "../../assets/airlines/6A/6A_icon@4x.png";
import airlineIcon7G2x from "../../assets/airlines/7G/7G_icon@2x.png";
import airlineIcon7G3x from "../../assets/airlines/7G/7G_icon@3x.png";
import airlineIcon7G4x from "../../assets/airlines/7G/7G_icon@4x.png";
import airlineIcon8H2x from "../../assets/airlines/8H/8H_icon@2x.png";
import airlineIcon8H3x from "../../assets/airlines/8H/8H_icon@3x.png";
import airlineIcon8H4x from "../../assets/airlines/8H/8H_icon@4x.png";
import airlineIcon9H2x from "../../assets/airlines/9H/9H_icon@2x.png";
import airlineIcon9H3x from "../../assets/airlines/9H/9H_icon@3x.png";
import airlineIcon9H4x from "../../assets/airlines/9H/9H_icon@4x.png";
import airlineIconA92x from "../../assets/airlines/A9/A9_icon@2x.png";
import airlineIconA93x from "../../assets/airlines/A9/A9_icon@3x.png";
import airlineIconA94x from "../../assets/airlines/A9/A9_icon@4x.png";
import airlineIconAW2x from "../../assets/airlines/AW/AW_icon@2x.png";
import airlineIconAW3x from "../../assets/airlines/AW/AW_icon@3x.png";
import airlineIconAW4x from "../../assets/airlines/AW/AW_icon@4x.png";
import airlineIconBO2x from "../../assets/airlines/BO/BO_icon@2x.png";
import airlineIconBO3x from "../../assets/airlines/BO/BO_icon@3x.png";
import airlineIconBO4x from "../../assets/airlines/BO/BO_icon@4x.png";
import airlineIconCJ2x from "../../assets/airlines/CJ/CJ_icon@2x.png";
import airlineIconCJ3x from "../../assets/airlines/CJ/CJ_icon@3x.png";
import airlineIconCJ4x from "../../assets/airlines/CJ/CJ_icon@4x.png";
import airlineIconDE2x from "../../assets/airlines/DE/DE_icon@2x.png";
import airlineIconDE3x from "../../assets/airlines/DE/DE_icon@3x.png";
import airlineIconDE4x from "../../assets/airlines/DE/DE_icon@4x.png";
import airlineIconEC2x from "../../assets/airlines/EC/EC_icon@2x.png";
import airlineIconEC3x from "../../assets/airlines/EC/EC_icon@3x.png";
import airlineIconEC4x from "../../assets/airlines/EC/EC_icon@4x.png";
import airlineIconF22x from "../../assets/airlines/F2/F2_icon@2x.png";
import airlineIconF23x from "../../assets/airlines/F2/F2_icon@3x.png";
import airlineIconF24x from "../../assets/airlines/F2/F2_icon@4x.png";
import airlineIconFS2x from "../../assets/airlines/FS/FS_icon@2x.png";
import airlineIconFS3x from "../../assets/airlines/FS/FS_icon@3x.png";
import airlineIconFS4x from "../../assets/airlines/FS/FS_icon@4x.png";
import airlineIconGK2x from "../../assets/airlines/GK/GK_icon@2x.png";
import airlineIconGK3x from "../../assets/airlines/GK/GK_icon@3x.png";
import airlineIconGK4x from "../../assets/airlines/GK/GK_icon@4x.png";
import airlineIconHD2x from "../../assets/airlines/HD/HD_icon@2x.png";
import airlineIconHD3x from "../../assets/airlines/HD/HD_icon@3x.png";
import airlineIconHD4x from "../../assets/airlines/HD/HD_icon@4x.png";
import airlineIconIA2x from "../../assets/airlines/IA/IA_icon@2x.png";
import airlineIconIA3x from "../../assets/airlines/IA/IA_icon@3x.png";
import airlineIconIA4x from "../../assets/airlines/IA/IA_icon@4x.png";
import airlineIconJ22x from "../../assets/airlines/J2/J2_icon@2x.png";
import airlineIconJ23x from "../../assets/airlines/J2/J2_icon@3x.png";
import airlineIconJ24x from "../../assets/airlines/J2/J2_icon@4x.png";
import airlineIconJR2x from "../../assets/airlines/JR/JR_icon@2x.png";
import airlineIconJR3x from "../../assets/airlines/JR/JR_icon@3x.png";
import airlineIconJR4x from "../../assets/airlines/JR/JR_icon@4x.png";
import airlineIconKJ2x from "../../assets/airlines/KJ/KJ_icon@2x.png";
import airlineIconKJ3x from "../../assets/airlines/KJ/KJ_icon@3x.png";
import airlineIconKJ4x from "../../assets/airlines/KJ/KJ_icon@4x.png";
import airlineIconLC2x from "../../assets/airlines/LC/LC_icon@2x.png";
import airlineIconLC3x from "../../assets/airlines/LC/LC_icon@3x.png";
import airlineIconLC4x from "../../assets/airlines/LC/LC_icon@4x.png";
import airlineIconM22x from "../../assets/airlines/M2/M2_icon@2x.png";
import airlineIconM23x from "../../assets/airlines/M2/M2_icon@3x.png";
import airlineIconM24x from "../../assets/airlines/M2/M2_icon@4x.png";
import airlineIconMS2x from "../../assets/airlines/MS/MS_icon@2x.png";
import airlineIconMS3x from "../../assets/airlines/MS/MS_icon@3x.png";
import airlineIconMS4x from "../../assets/airlines/MS/MS_icon@4x.png";
import airlineIconNI2x from "../../assets/airlines/NI/NI_icon@2x.png";
import airlineIconNI3x from "../../assets/airlines/NI/NI_icon@3x.png";
import airlineIconNI4x from "../../assets/airlines/NI/NI_icon@4x.png";
import airlineIconOI2x from "../../assets/airlines/OI/OI_icon@2x.png";
import airlineIconOI3x from "../../assets/airlines/OI/OI_icon@3x.png";
import airlineIconOI4x from "../../assets/airlines/OI/OI_icon@4x.png";
import airlineIconP82x from "../../assets/airlines/P8/P8_icon@2x.png";
import airlineIconP83x from "../../assets/airlines/P8/P8_icon@3x.png";
import airlineIconP84x from "../../assets/airlines/P8/P8_icon@4x.png";
import airlineIconPW2x from "../../assets/airlines/PW/PW_icon@2x.png";
import airlineIconPW3x from "../../assets/airlines/PW/PW_icon@3x.png";
import airlineIconPW4x from "../../assets/airlines/PW/PW_icon@4x.png";
import airlineIconQO2x from "../../assets/airlines/QO/QO_icon@2x.png";
import airlineIconQO3x from "../../assets/airlines/QO/QO_icon@3x.png";
import airlineIconQO4x from "../../assets/airlines/QO/QO_icon@4x.png";
import airlineIconRG2x from "../../assets/airlines/RG/RG_icon@2x.png";
import airlineIconRG3x from "../../assets/airlines/RG/RG_icon@3x.png";
import airlineIconRG4x from "../../assets/airlines/RG/RG_icon@4x.png";
import airlineIconS72x from "../../assets/airlines/S7/S7_icon@2x.png";
import airlineIconS73x from "../../assets/airlines/S7/S7_icon@3x.png";
import airlineIconS74x from "../../assets/airlines/S7/S7_icon@4x.png";
import airlineIconSV2x from "../../assets/airlines/SV/SV_icon@2x.png";
import airlineIconSV3x from "../../assets/airlines/SV/SV_icon@3x.png";
import airlineIconSV4x from "../../assets/airlines/SV/SV_icon@4x.png";
import airlineIconTM2x from "../../assets/airlines/TM/TM_icon@2x.png";
import airlineIconTM3x from "../../assets/airlines/TM/TM_icon@3x.png";
import airlineIconTM4x from "../../assets/airlines/TM/TM_icon@4x.png";
import airlineIconUE2x from "../../assets/airlines/UE/UE_icon@2x.png";
import airlineIconUE3x from "../../assets/airlines/UE/UE_icon@3x.png";
import airlineIconUE4x from "../../assets/airlines/UE/UE_icon@4x.png";
import airlineIconV82x from "../../assets/airlines/V8/V8_icon@2x.png";
import airlineIconV83x from "../../assets/airlines/V8/V8_icon@3x.png";
import airlineIconV84x from "../../assets/airlines/V8/V8_icon@4x.png";
import airlineIconVZ2x from "../../assets/airlines/VZ/VZ_icon@2x.png";
import airlineIconVZ3x from "../../assets/airlines/VZ/VZ_icon@3x.png";
import airlineIconVZ4x from "../../assets/airlines/VZ/VZ_icon@4x.png";
import airlineIconWR2x from "../../assets/airlines/WR/WR_icon@2x.png";
import airlineIconWR3x from "../../assets/airlines/WR/WR_icon@3x.png";
import airlineIconWR4x from "../../assets/airlines/WR/WR_icon@4x.png";
import airlineIconXO2x from "../../assets/airlines/XO/XO_icon@2x.png";
import airlineIconXO3x from "../../assets/airlines/XO/XO_icon@3x.png";
import airlineIconXO4x from "../../assets/airlines/XO/XO_icon@4x.png";
import airlineIconYQ2x from "../../assets/airlines/YQ/YQ_icon@2x.png";
import airlineIconYQ3x from "../../assets/airlines/YQ/YQ_icon@3x.png";
import airlineIconYQ4x from "../../assets/airlines/YQ/YQ_icon@4x.png";
import airlineIconZM2x from "../../assets/airlines/ZM/ZM_icon@2x.png";
import airlineIconZM3x from "../../assets/airlines/ZM/ZM_icon@3x.png";
import airlineIconZM4x from "../../assets/airlines/ZM/ZM_icon@4x.png";
import airlineIcon2A2x from "../../assets/airlines/2A/2A_icon@2x.png";
import airlineIcon2A3x from "../../assets/airlines/2A/2A_icon@3x.png";
import airlineIcon2A4x from "../../assets/airlines/2A/2A_icon@4x.png";
import airlineIcon3B2x from "../../assets/airlines/3B/3B_icon@2x.png";
import airlineIcon3B3x from "../../assets/airlines/3B/3B_icon@3x.png";
import airlineIcon3B4x from "../../assets/airlines/3B/3B_icon@4x.png";
import airlineIcon4B2x from "../../assets/airlines/4B/4B_icon@2x.png";
import airlineIcon4B3x from "../../assets/airlines/4B/4B_icon@3x.png";
import airlineIcon4B4x from "../../assets/airlines/4B/4B_icon@4x.png";
import airlineIcon5B2x from "../../assets/airlines/5B/5B_icon@2x.png";
import airlineIcon5B3x from "../../assets/airlines/5B/5B_icon@3x.png";
import airlineIcon5B4x from "../../assets/airlines/5B/5B_icon@4x.png";
import airlineIcon6B2x from "../../assets/airlines/6B/6B_icon@2x.png";
import airlineIcon6B3x from "../../assets/airlines/6B/6B_icon@3x.png";
import airlineIcon6B4x from "../../assets/airlines/6B/6B_icon@4x.png";
import airlineIcon7H2x from "../../assets/airlines/7H/7H_icon@2x.png";
import airlineIcon7H3x from "../../assets/airlines/7H/7H_icon@3x.png";
import airlineIcon7H4x from "../../assets/airlines/7H/7H_icon@4x.png";
import airlineIcon8I2x from "../../assets/airlines/8I/8I_icon@2x.png";
import airlineIcon8I3x from "../../assets/airlines/8I/8I_icon@3x.png";
import airlineIcon8I4x from "../../assets/airlines/8I/8I_icon@4x.png";
import airlineIcon9I2x from "../../assets/airlines/9I/9I_icon@2x.png";
import airlineIcon9I3x from "../../assets/airlines/9I/9I_icon@3x.png";
import airlineIcon9I4x from "../../assets/airlines/9I/9I_icon@4x.png";
import airlineIconAA2x from "../../assets/airlines/AA/AA_icon@2x.png";
import airlineIconAA3x from "../../assets/airlines/AA/AA_icon@3x.png";
import airlineIconAA4x from "../../assets/airlines/AA/AA_icon@4x.png";
import airlineIconAY2x from "../../assets/airlines/AY/AY_icon@2x.png";
import airlineIconAY3x from "../../assets/airlines/AY/AY_icon@3x.png";
import airlineIconAY4x from "../../assets/airlines/AY/AY_icon@4x.png";
import airlineIconBP2x from "../../assets/airlines/BP/BP_icon@2x.png";
import airlineIconBP3x from "../../assets/airlines/BP/BP_icon@3x.png";
import airlineIconBP4x from "../../assets/airlines/BP/BP_icon@4x.png";
import airlineIconCK2x from "../../assets/airlines/CK/CK_icon@2x.png";
import airlineIconCK3x from "../../assets/airlines/CK/CK_icon@3x.png";
import airlineIconCK4x from "../../assets/airlines/CK/CK_icon@4x.png";
import airlineIconDG2x from "../../assets/airlines/DG/DG_icon@2x.png";
import airlineIconDG3x from "../../assets/airlines/DG/DG_icon@3x.png";
import airlineIconDG4x from "../../assets/airlines/DG/DG_icon@4x.png";
import airlineIconED2x from "../../assets/airlines/ED/ED_icon@2x.png";
import airlineIconED3x from "../../assets/airlines/ED/ED_icon@3x.png";
import airlineIconED4x from "../../assets/airlines/ED/ED_icon@4x.png";
import airlineIconF32x from "../../assets/airlines/F3/F3_icon@2x.png";
import airlineIconF33x from "../../assets/airlines/F3/F3_icon@3x.png";
import airlineIconF34x from "../../assets/airlines/F3/F3_icon@4x.png";
import airlineIconFT2x from "../../assets/airlines/FT/FT_icon@2x.png";
import airlineIconFT3x from "../../assets/airlines/FT/FT_icon@3x.png";
import airlineIconFT4x from "../../assets/airlines/FT/FT_icon@4x.png";
import airlineIconGL2x from "../../assets/airlines/GL/GL_icon@2x.png";
import airlineIconGL3x from "../../assets/airlines/GL/GL_icon@3x.png";
import airlineIconGL4x from "../../assets/airlines/GL/GL_icon@4x.png";
import airlineIconHF2x from "../../assets/airlines/HF/HF_icon@2x.png";
import airlineIconHF3x from "../../assets/airlines/HF/HF_icon@3x.png";
import airlineIconHF4x from "../../assets/airlines/HF/HF_icon@4x.png";
import airlineIconIB2x from "../../assets/airlines/IB/IB_icon@2x.png";
import airlineIconIB3x from "../../assets/airlines/IB/IB_icon@3x.png";
import airlineIconIB4x from "../../assets/airlines/IB/IB_icon@4x.png";
import airlineIconJ32x from "../../assets/airlines/J3/J3_icon@2x.png";
import airlineIconJ33x from "../../assets/airlines/J3/J3_icon@3x.png";
import airlineIconJ34x from "../../assets/airlines/J3/J3_icon@4x.png";
import airlineIconJS2x from "../../assets/airlines/JS/JS_icon@2x.png";
import airlineIconJS3x from "../../assets/airlines/JS/JS_icon@3x.png";
import airlineIconJS4x from "../../assets/airlines/JS/JS_icon@4x.png";
import airlineIconKL2x from "../../assets/airlines/KL/KL_icon@2x.png";
import airlineIconKL3x from "../../assets/airlines/KL/KL_icon@3x.png";
import airlineIconKL4x from "../../assets/airlines/KL/KL_icon@4x.png";
import airlineIconLD2x from "../../assets/airlines/LD/LD_icon@2x.png";
import airlineIconLD3x from "../../assets/airlines/LD/LD_icon@3x.png";
import airlineIconLD4x from "../../assets/airlines/LD/LD_icon@4x.png";
import airlineIconM32x from "../../assets/airlines/M3/M3_icon@2x.png";
import airlineIconM33x from "../../assets/airlines/M3/M3_icon@3x.png";
import airlineIconM34x from "../../assets/airlines/M3/M3_icon@4x.png";
import airlineIconMT2x from "../../assets/airlines/MT/MT_icon@2x.png";
import airlineIconMT3x from "../../assets/airlines/MT/MT_icon@3x.png";
import airlineIconMT4x from "../../assets/airlines/MT/MT_icon@4x.png";
import airlineIconNK2x from "../../assets/airlines/NK/NK_icon@2x.png";
import airlineIconNK3x from "../../assets/airlines/NK/NK_icon@3x.png";
import airlineIconNK4x from "../../assets/airlines/NK/NK_icon@4x.png";
import airlineIconOK2x from "../../assets/airlines/OK/OK_icon@2x.png";
import airlineIconOK3x from "../../assets/airlines/OK/OK_icon@3x.png";
import airlineIconOK4x from "../../assets/airlines/OK/OK_icon@4x.png";
import airlineIconP92x from "../../assets/airlines/P9/P9_icon@2x.png";
import airlineIconP93x from "../../assets/airlines/P9/P9_icon@3x.png";
import airlineIconP94x from "../../assets/airlines/P9/P9_icon@4x.png";
import airlineIconPX2x from "../../assets/airlines/PX/PX_icon@2x.png";
import airlineIconPX3x from "../../assets/airlines/PX/PX_icon@3x.png";
import airlineIconPX4x from "../../assets/airlines/PX/PX_icon@4x.png";
import airlineIconQP2x from "../../assets/airlines/QP/QP_icon@2x.png";
import airlineIconQP3x from "../../assets/airlines/QP/QP_icon@3x.png";
import airlineIconQP4x from "../../assets/airlines/QP/QP_icon@4x.png";
import airlineIconRH2x from "../../assets/airlines/RH/RH_icon@2x.png";
import airlineIconRH3x from "../../assets/airlines/RH/RH_icon@3x.png";
import airlineIconRH4x from "../../assets/airlines/RH/RH_icon@4x.png";
import airlineIconS82x from "../../assets/airlines/S8/S8_icon@2x.png";
import airlineIconS83x from "../../assets/airlines/S8/S8_icon@3x.png";
import airlineIconS84x from "../../assets/airlines/S8/S8_icon@4x.png";
import airlineIconSW2x from "../../assets/airlines/SW/SW_icon@2x.png";
import airlineIconSW3x from "../../assets/airlines/SW/SW_icon@3x.png";
import airlineIconSW4x from "../../assets/airlines/SW/SW_icon@4x.png";
import airlineIconTN2x from "../../assets/airlines/TN/TN_icon@2x.png";
import airlineIconTN3x from "../../assets/airlines/TN/TN_icon@3x.png";
import airlineIconTN4x from "../../assets/airlines/TN/TN_icon@4x.png";
import airlineIconUG2x from "../../assets/airlines/UG/UG_icon@2x.png";
import airlineIconUG3x from "../../assets/airlines/UG/UG_icon@3x.png";
import airlineIconUG4x from "../../assets/airlines/UG/UG_icon@4x.png";
import airlineIconV92x from "../../assets/airlines/V9/V9_icon@2x.png";
import airlineIconV93x from "../../assets/airlines/V9/V9_icon@3x.png";
import airlineIconV94x from "../../assets/airlines/V9/V9_icon@4x.png";
import airlineIconW22x from "../../assets/airlines/W2/W2_icon@2x.png";
import airlineIconW23x from "../../assets/airlines/W2/W2_icon@3x.png";
import airlineIconW24x from "../../assets/airlines/W2/W2_icon@4x.png";
import airlineIconWS2x from "../../assets/airlines/WS/WS_icon@2x.png";
import airlineIconWS3x from "../../assets/airlines/WS/WS_icon@3x.png";
import airlineIconWS4x from "../../assets/airlines/WS/WS_icon@4x.png";
import airlineIconXP2x from "../../assets/airlines/XP/XP_icon@2x.png";
import airlineIconXP3x from "../../assets/airlines/XP/XP_icon@3x.png";
import airlineIconXP4x from "../../assets/airlines/XP/XP_icon@4x.png";
import airlineIconYR2x from "../../assets/airlines/YR/YR_icon@2x.png";
import airlineIconYR3x from "../../assets/airlines/YR/YR_icon@3x.png";
import airlineIconYR4x from "../../assets/airlines/YR/YR_icon@4x.png";
import airlineIconZN2x from "../../assets/airlines/ZN/ZN_icon@2x.png";
import airlineIconZN3x from "../../assets/airlines/ZN/ZN_icon@3x.png";
import airlineIconZN4x from "../../assets/airlines/ZN/ZN_icon@4x.png";
import airlineIcon2B2x from "../../assets/airlines/2B/2B_icon@2x.png";
import airlineIcon2B3x from "../../assets/airlines/2B/2B_icon@3x.png";
import airlineIcon2B4x from "../../assets/airlines/2B/2B_icon@4x.png";
import airlineIcon3C2x from "../../assets/airlines/3C/3C_icon@2x.png";
import airlineIcon3C3x from "../../assets/airlines/3C/3C_icon@3x.png";
import airlineIcon3C4x from "../../assets/airlines/3C/3C_icon@4x.png";
import airlineIcon4C2x from "../../assets/airlines/4C/4C_icon@2x.png";
import airlineIcon4C3x from "../../assets/airlines/4C/4C_icon@3x.png";
import airlineIcon4C4x from "../../assets/airlines/4C/4C_icon@4x.png";
import airlineIcon5C2x from "../../assets/airlines/5C/5C_icon@2x.png";
import airlineIcon5C3x from "../../assets/airlines/5C/5C_icon@3x.png";
import airlineIcon5C4x from "../../assets/airlines/5C/5C_icon@4x.png";
import airlineIcon6C2x from "../../assets/airlines/6C/6C_icon@2x.png";
import airlineIcon6C3x from "../../assets/airlines/6C/6C_icon@3x.png";
import airlineIcon6C4x from "../../assets/airlines/6C/6C_icon@4x.png";
import airlineIcon7I2x from "../../assets/airlines/7I/7I_icon@2x.png";
import airlineIcon7I3x from "../../assets/airlines/7I/7I_icon@3x.png";
import airlineIcon7I4x from "../../assets/airlines/7I/7I_icon@4x.png";
import airlineIcon8J2x from "../../assets/airlines/8J/8J_icon@2x.png";
import airlineIcon8J3x from "../../assets/airlines/8J/8J_icon@3x.png";
import airlineIcon8J4x from "../../assets/airlines/8J/8J_icon@4x.png";
import airlineIcon9J2x from "../../assets/airlines/9J/9J_icon@2x.png";
import airlineIcon9J3x from "../../assets/airlines/9J/9J_icon@3x.png";
import airlineIcon9J4x from "../../assets/airlines/9J/9J_icon@4x.png";
import airlineIconAB2x from "../../assets/airlines/AB/AB_icon@2x.png";
import airlineIconAB3x from "../../assets/airlines/AB/AB_icon@3x.png";
import airlineIconAB4x from "../../assets/airlines/AB/AB_icon@4x.png";
import airlineIconAZ2x from "../../assets/airlines/AZ/AZ_icon@2x.png";
import airlineIconAZ3x from "../../assets/airlines/AZ/AZ_icon@3x.png";
import airlineIconAZ4x from "../../assets/airlines/AZ/AZ_icon@4x.png";
import airlineIconBR2x from "../../assets/airlines/BR/BR_icon@2x.png";
import airlineIconBR3x from "../../assets/airlines/BR/BR_icon@3x.png";
import airlineIconBR4x from "../../assets/airlines/BR/BR_icon@4x.png";
import airlineIconCL2x from "../../assets/airlines/CL/CL_icon@2x.png";
import airlineIconCL3x from "../../assets/airlines/CL/CL_icon@3x.png";
import airlineIconCL4x from "../../assets/airlines/CL/CL_icon@4x.png";
import airlineIconDH2x from "../../assets/airlines/DH/DH_icon@2x.png";
import airlineIconDH3x from "../../assets/airlines/DH/DH_icon@3x.png";
import airlineIconDH4x from "../../assets/airlines/DH/DH_icon@4x.png";
import airlineIconEF2x from "../../assets/airlines/EF/EF_icon@2x.png";
import airlineIconEF3x from "../../assets/airlines/EF/EF_icon@3x.png";
import airlineIconEF4x from "../../assets/airlines/EF/EF_icon@4x.png";
import airlineIconF42x from "../../assets/airlines/F4/F4_icon@2x.png";
import airlineIconF43x from "../../assets/airlines/F4/F4_icon@3x.png";
import airlineIconF44x from "../../assets/airlines/F4/F4_icon@4x.png";
import airlineIconFU2x from "../../assets/airlines/FU/FU_icon@2x.png";
import airlineIconFU3x from "../../assets/airlines/FU/FU_icon@3x.png";
import airlineIconFU4x from "../../assets/airlines/FU/FU_icon@4x.png";
import airlineIconGM2x from "../../assets/airlines/GM/GM_icon@2x.png";
import airlineIconGM3x from "../../assets/airlines/GM/GM_icon@3x.png";
import airlineIconGM4x from "../../assets/airlines/GM/GM_icon@4x.png";
import airlineIconHH2x from "../../assets/airlines/HH/HH_icon@2x.png";
import airlineIconHH3x from "../../assets/airlines/HH/HH_icon@3x.png";
import airlineIconHH4x from "../../assets/airlines/HH/HH_icon@4x.png";
import airlineIconID2x from "../../assets/airlines/ID/ID_icon@2x.png";
import airlineIconID3x from "../../assets/airlines/ID/ID_icon@3x.png";
import airlineIconID4x from "../../assets/airlines/ID/ID_icon@4x.png";
import airlineIconJ42x from "../../assets/airlines/J4/J4_icon@2x.png";
import airlineIconJ43x from "../../assets/airlines/J4/J4_icon@3x.png";
import airlineIconJ44x from "../../assets/airlines/J4/J4_icon@4x.png";
import airlineIconJT2x from "../../assets/airlines/JT/JT_icon@2x.png";
import airlineIconJT3x from "../../assets/airlines/JT/JT_icon@3x.png";
import airlineIconJT4x from "../../assets/airlines/JT/JT_icon@4x.png";
import airlineIconKM2x from "../../assets/airlines/KM/KM_icon@2x.png";
import airlineIconKM3x from "../../assets/airlines/KM/KM_icon@3x.png";
import airlineIconKM4x from "../../assets/airlines/KM/KM_icon@4x.png";
import airlineIconLF2x from "../../assets/airlines/LF/LF_icon@2x.png";
import airlineIconLF3x from "../../assets/airlines/LF/LF_icon@3x.png";
import airlineIconLF4x from "../../assets/airlines/LF/LF_icon@4x.png";
import airlineIconM42x from "../../assets/airlines/M4/M4_icon@2x.png";
import airlineIconM43x from "../../assets/airlines/M4/M4_icon@3x.png";
import airlineIconM44x from "../../assets/airlines/M4/M4_icon@4x.png";
import airlineIconMU2x from "../../assets/airlines/MU/MU_icon@2x.png";
import airlineIconMU3x from "../../assets/airlines/MU/MU_icon@3x.png";
import airlineIconMU4x from "../../assets/airlines/MU/MU_icon@4x.png";
import airlineIconNL2x from "../../assets/airlines/NL/NL_icon@2x.png";
import airlineIconNL3x from "../../assets/airlines/NL/NL_icon@3x.png";
import airlineIconNL4x from "../../assets/airlines/NL/NL_icon@4x.png";
import airlineIconOL2x from "../../assets/airlines/OL/OL_icon@2x.png";
import airlineIconOL3x from "../../assets/airlines/OL/OL_icon@3x.png";
import airlineIconOL4x from "../../assets/airlines/OL/OL_icon@4x.png";
import airlineIconPA2x from "../../assets/airlines/PA/PA_icon@2x.png";
import airlineIconPA3x from "../../assets/airlines/PA/PA_icon@3x.png";
import airlineIconPA4x from "../../assets/airlines/PA/PA_icon@4x.png";
import airlineIconPY2x from "../../assets/airlines/PY/PY_icon@2x.png";
import airlineIconPY3x from "../../assets/airlines/PY/PY_icon@3x.png";
import airlineIconPY4x from "../../assets/airlines/PY/PY_icon@4x.png";
import airlineIconQQ2x from "../../assets/airlines/QQ/QQ_icon@2x.png";
import airlineIconQQ3x from "../../assets/airlines/QQ/QQ_icon@3x.png";
import airlineIconQQ4x from "../../assets/airlines/QQ/QQ_icon@4x.png";
import airlineIconRI2x from "../../assets/airlines/RI/RI_icon@2x.png";
import airlineIconRI3x from "../../assets/airlines/RI/RI_icon@3x.png";
import airlineIconRI4x from "../../assets/airlines/RI/RI_icon@4x.png";
import airlineIconS92x from "../../assets/airlines/S9/S9_icon@2x.png";
import airlineIconS93x from "../../assets/airlines/S9/S9_icon@3x.png";
import airlineIconS94x from "../../assets/airlines/S9/S9_icon@4x.png";
import airlineIconSX2x from "../../assets/airlines/SX/SX_icon@2x.png";
import airlineIconSX3x from "../../assets/airlines/SX/SX_icon@3x.png";
import airlineIconSX4x from "../../assets/airlines/SX/SX_icon@4x.png";
import airlineIconTO2x from "../../assets/airlines/TO/TO_icon@2x.png";
import airlineIconTO3x from "../../assets/airlines/TO/TO_icon@3x.png";
import airlineIconTO4x from "../../assets/airlines/TO/TO_icon@4x.png";
import airlineIconUI2x from "../../assets/airlines/UI/UI_icon@2x.png";
import airlineIconUI3x from "../../assets/airlines/UI/UI_icon@3x.png";
import airlineIconUI4x from "../../assets/airlines/UI/UI_icon@4x.png";
import airlineIconVA2x from "../../assets/airlines/VA/VA_icon@2x.png";
import airlineIconVA3x from "../../assets/airlines/VA/VA_icon@3x.png";
import airlineIconVA4x from "../../assets/airlines/VA/VA_icon@4x.png";
import airlineIconW32x from "../../assets/airlines/W3/W3_icon@2x.png";
import airlineIconW33x from "../../assets/airlines/W3/W3_icon@3x.png";
import airlineIconW34x from "../../assets/airlines/W3/W3_icon@4x.png";
import airlineIconWT2x from "../../assets/airlines/WT/WT_icon@2x.png";
import airlineIconWT3x from "../../assets/airlines/WT/WT_icon@3x.png";
import airlineIconWT4x from "../../assets/airlines/WT/WT_icon@4x.png";
import airlineIconXQ2x from "../../assets/airlines/XQ/XQ_icon@2x.png";
import airlineIconXQ3x from "../../assets/airlines/XQ/XQ_icon@3x.png";
import airlineIconXQ4x from "../../assets/airlines/XQ/XQ_icon@4x.png";
import airlineIconYS2x from "../../assets/airlines/YS/YS_icon@2x.png";
import airlineIconYS3x from "../../assets/airlines/YS/YS_icon@3x.png";
import airlineIconYS4x from "../../assets/airlines/YS/YS_icon@4x.png";
import airlineIconZP2x from "../../assets/airlines/ZP/ZP_icon@2x.png";
import airlineIconZP3x from "../../assets/airlines/ZP/ZP_icon@3x.png";
import airlineIconZP4x from "../../assets/airlines/ZP/ZP_icon@4x.png";
import airlineIcon2C2x from "../../assets/airlines/2C/2C_icon@2x.png";
import airlineIcon2C3x from "../../assets/airlines/2C/2C_icon@3x.png";
import airlineIcon2C4x from "../../assets/airlines/2C/2C_icon@4x.png";
import airlineIcon3D2x from "../../assets/airlines/3D/3D_icon@2x.png";
import airlineIcon3D3x from "../../assets/airlines/3D/3D_icon@3x.png";
import airlineIcon3D4x from "../../assets/airlines/3D/3D_icon@4x.png";
import airlineIcon4E2x from "../../assets/airlines/4E/4E_icon@2x.png";
import airlineIcon4E3x from "../../assets/airlines/4E/4E_icon@3x.png";
import airlineIcon4E4x from "../../assets/airlines/4E/4E_icon@4x.png";
import airlineIcon5D2x from "../../assets/airlines/5D/5D_icon@2x.png";
import airlineIcon5D3x from "../../assets/airlines/5D/5D_icon@3x.png";
import airlineIcon5D4x from "../../assets/airlines/5D/5D_icon@4x.png";
import airlineIcon6D2x from "../../assets/airlines/6D/6D_icon@2x.png";
import airlineIcon6D3x from "../../assets/airlines/6D/6D_icon@3x.png";
import airlineIcon6D4x from "../../assets/airlines/6D/6D_icon@4x.png";
import airlineIcon7J2x from "../../assets/airlines/7J/7J_icon@2x.png";
import airlineIcon7J3x from "../../assets/airlines/7J/7J_icon@3x.png";
import airlineIcon7J4x from "../../assets/airlines/7J/7J_icon@4x.png";
import airlineIcon8K2x from "../../assets/airlines/8K/8K_icon@2x.png";
import airlineIcon8K3x from "../../assets/airlines/8K/8K_icon@3x.png";
import airlineIcon8K4x from "../../assets/airlines/8K/8K_icon@4x.png";
import airlineIcon9K2x from "../../assets/airlines/9K/9K_icon@2x.png";
import airlineIcon9K3x from "../../assets/airlines/9K/9K_icon@3x.png";
import airlineIcon9K4x from "../../assets/airlines/9K/9K_icon@4x.png";
import airlineIconAC2x from "../../assets/airlines/AC/AC_icon@2x.png";
import airlineIconAC3x from "../../assets/airlines/AC/AC_icon@3x.png";
import airlineIconAC4x from "../../assets/airlines/AC/AC_icon@4x.png";
import airlineIconB02x from "../../assets/airlines/B0/B0_icon@2x.png";
import airlineIconB03x from "../../assets/airlines/B0/B0_icon@3x.png";
import airlineIconB04x from "../../assets/airlines/B0/B0_icon@4x.png";
import airlineIconBS2x from "../../assets/airlines/BS/BS_icon@2x.png";
import airlineIconBS3x from "../../assets/airlines/BS/BS_icon@3x.png";
import airlineIconBS4x from "../../assets/airlines/BS/BS_icon@4x.png";
import airlineIconCM2x from "../../assets/airlines/CM/CM_icon@2x.png";
import airlineIconCM3x from "../../assets/airlines/CM/CM_icon@3x.png";
import airlineIconCM4x from "../../assets/airlines/CM/CM_icon@4x.png";
import airlineIconDK2x from "../../assets/airlines/DK/DK_icon@2x.png";
import airlineIconDK3x from "../../assets/airlines/DK/DK_icon@3x.png";
import airlineIconDK4x from "../../assets/airlines/DK/DK_icon@4x.png";
import airlineIconEG2x from "../../assets/airlines/EG/EG_icon@2x.png";
import airlineIconEG3x from "../../assets/airlines/EG/EG_icon@3x.png";
import airlineIconEG4x from "../../assets/airlines/EG/EG_icon@4x.png";
import airlineIconF52x from "../../assets/airlines/F5/F5_icon@2x.png";
import airlineIconF53x from "../../assets/airlines/F5/F5_icon@3x.png";
import airlineIconF54x from "../../assets/airlines/F5/F5_icon@4x.png";
import airlineIconFV2x from "../../assets/airlines/FV/FV_icon@2x.png";
import airlineIconFV3x from "../../assets/airlines/FV/FV_icon@3x.png";
import airlineIconFV4x from "../../assets/airlines/FV/FV_icon@4x.png";
import airlineIconGP2x from "../../assets/airlines/GP/GP_icon@2x.png";
import airlineIconGP3x from "../../assets/airlines/GP/GP_icon@3x.png";
import airlineIconGP4x from "../../assets/airlines/GP/GP_icon@4x.png";
import airlineIconHK2x from "../../assets/airlines/HK/HK_icon@2x.png";
import airlineIconHK3x from "../../assets/airlines/HK/HK_icon@3x.png";
import airlineIconHK4x from "../../assets/airlines/HK/HK_icon@4x.png";
import airlineIconIE2x from "../../assets/airlines/IE/IE_icon@2x.png";
import airlineIconIE3x from "../../assets/airlines/IE/IE_icon@3x.png";
import airlineIconIE4x from "../../assets/airlines/IE/IE_icon@4x.png";
import airlineIconJ52x from "../../assets/airlines/J5/J5_icon@2x.png";
import airlineIconJ53x from "../../assets/airlines/J5/J5_icon@3x.png";
import airlineIconJ54x from "../../assets/airlines/J5/J5_icon@4x.png";
import airlineIconJU2x from "../../assets/airlines/JU/JU_icon@2x.png";
import airlineIconJU3x from "../../assets/airlines/JU/JU_icon@3x.png";
import airlineIconJU4x from "../../assets/airlines/JU/JU_icon@4x.png";
import airlineIconKN2x from "../../assets/airlines/KN/KN_icon@2x.png";
import airlineIconKN3x from "../../assets/airlines/KN/KN_icon@3x.png";
import airlineIconKN4x from "../../assets/airlines/KN/KN_icon@4x.png";
import airlineIconLG2x from "../../assets/airlines/LG/LG_icon@2x.png";
import airlineIconLG3x from "../../assets/airlines/LG/LG_icon@3x.png";
import airlineIconLG4x from "../../assets/airlines/LG/LG_icon@4x.png";
import airlineIconM52x from "../../assets/airlines/M5/M5_icon@2x.png";
import airlineIconM53x from "../../assets/airlines/M5/M5_icon@3x.png";
import airlineIconM54x from "../../assets/airlines/M5/M5_icon@4x.png";
import airlineIconMV2x from "../../assets/airlines/MV/MV_icon@2x.png";
import airlineIconMV3x from "../../assets/airlines/MV/MV_icon@3x.png";
import airlineIconMV4x from "../../assets/airlines/MV/MV_icon@4x.png";
import airlineIconNO2x from "../../assets/airlines/NO/NO_icon@2x.png";
import airlineIconNO3x from "../../assets/airlines/NO/NO_icon@3x.png";
import airlineIconNO4x from "../../assets/airlines/NO/NO_icon@4x.png";
import airlineIconOM2x from "../../assets/airlines/OM/OM_icon@2x.png";
import airlineIconOM3x from "../../assets/airlines/OM/OM_icon@3x.png";
import airlineIconOM4x from "../../assets/airlines/OM/OM_icon@4x.png";
import airlineIconPB2x from "../../assets/airlines/PB/PB_icon@2x.png";
import airlineIconPB3x from "../../assets/airlines/PB/PB_icon@3x.png";
import airlineIconPB4x from "../../assets/airlines/PB/PB_icon@4x.png";
import airlineIconPZ2x from "../../assets/airlines/PZ/PZ_icon@2x.png";
import airlineIconPZ3x from "../../assets/airlines/PZ/PZ_icon@3x.png";
import airlineIconPZ4x from "../../assets/airlines/PZ/PZ_icon@4x.png";
import airlineIconQR2x from "../../assets/airlines/QR/QR_icon@2x.png";
import airlineIconQR3x from "../../assets/airlines/QR/QR_icon@3x.png";
import airlineIconQR4x from "../../assets/airlines/QR/QR_icon@4x.png";
import airlineIconRJ2x from "../../assets/airlines/RJ/RJ_icon@2x.png";
import airlineIconRJ3x from "../../assets/airlines/RJ/RJ_icon@3x.png";
import airlineIconRJ4x from "../../assets/airlines/RJ/RJ_icon@4x.png";
import airlineIconSA2x from "../../assets/airlines/SA/SA_icon@2x.png";
import airlineIconSA3x from "../../assets/airlines/SA/SA_icon@3x.png";
import airlineIconSA4x from "../../assets/airlines/SA/SA_icon@4x.png";
import airlineIconSY2x from "../../assets/airlines/SY/SY_icon@2x.png";
import airlineIconSY3x from "../../assets/airlines/SY/SY_icon@3x.png";
import airlineIconSY4x from "../../assets/airlines/SY/SY_icon@4x.png";
import airlineIconTP2x from "../../assets/airlines/TP/TP_icon@2x.png";
import airlineIconTP3x from "../../assets/airlines/TP/TP_icon@3x.png";
import airlineIconTP4x from "../../assets/airlines/TP/TP_icon@4x.png";
import airlineIconUJ2x from "../../assets/airlines/UJ/UJ_icon@2x.png";
import airlineIconUJ3x from "../../assets/airlines/UJ/UJ_icon@3x.png";
import airlineIconUJ4x from "../../assets/airlines/UJ/UJ_icon@4x.png";
import airlineIconVB2x from "../../assets/airlines/VB/VB_icon@2x.png";
import airlineIconVB3x from "../../assets/airlines/VB/VB_icon@3x.png";
import airlineIconVB4x from "../../assets/airlines/VB/VB_icon@4x.png";
import airlineIconW42x from "../../assets/airlines/W4/W4_icon@2x.png";
import airlineIconW43x from "../../assets/airlines/W4/W4_icon@3x.png";
import airlineIconW44x from "../../assets/airlines/W4/W4_icon@4x.png";
import airlineIconWU2x from "../../assets/airlines/WU/WU_icon@2x.png";
import airlineIconWU3x from "../../assets/airlines/WU/WU_icon@3x.png";
import airlineIconWU4x from "../../assets/airlines/WU/WU_icon@4x.png";
import airlineIconXR2x from "../../assets/airlines/XR/XR_icon@2x.png";
import airlineIconXR3x from "../../assets/airlines/XR/XR_icon@3x.png";
import airlineIconXR4x from "../../assets/airlines/XR/XR_icon@4x.png";
import airlineIconYT2x from "../../assets/airlines/YT/YT_icon@2x.png";
import airlineIconYT3x from "../../assets/airlines/YT/YT_icon@3x.png";
import airlineIconYT4x from "../../assets/airlines/YT/YT_icon@4x.png";
import airlineIconZR2x from "../../assets/airlines/ZR/ZR_icon@2x.png";
import airlineIconZR3x from "../../assets/airlines/ZR/ZR_icon@3x.png";
import airlineIconZR4x from "../../assets/airlines/ZR/ZR_icon@4x.png";
import airlineIcon2D2x from "../../assets/airlines/2D/2D_icon@2x.png";
import airlineIcon2D3x from "../../assets/airlines/2D/2D_icon@3x.png";
import airlineIcon2D4x from "../../assets/airlines/2D/2D_icon@4x.png";
import airlineIcon3E2x from "../../assets/airlines/3E/3E_icon@2x.png";
import airlineIcon3E3x from "../../assets/airlines/3E/3E_icon@3x.png";
import airlineIcon3E4x from "../../assets/airlines/3E/3E_icon@4x.png";
import airlineIcon4F2x from "../../assets/airlines/4F/4F_icon@2x.png";
import airlineIcon4F3x from "../../assets/airlines/4F/4F_icon@3x.png";
import airlineIcon4F4x from "../../assets/airlines/4F/4F_icon@4x.png";
import airlineIcon5E2x from "../../assets/airlines/5E/5E_icon@2x.png";
import airlineIcon5E3x from "../../assets/airlines/5E/5E_icon@3x.png";
import airlineIcon5E4x from "../../assets/airlines/5E/5E_icon@4x.png";
import airlineIcon6E2x from "../../assets/airlines/6E/6E_icon@2x.png";
import airlineIcon6E3x from "../../assets/airlines/6E/6E_icon@3x.png";
import airlineIcon6E4x from "../../assets/airlines/6E/6E_icon@4x.png";
import airlineIcon7K2x from "../../assets/airlines/7K/7K_icon@2x.png";
import airlineIcon7K3x from "../../assets/airlines/7K/7K_icon@3x.png";
import airlineIcon7K4x from "../../assets/airlines/7K/7K_icon@4x.png";
import airlineIcon8L2x from "../../assets/airlines/8L/8L_icon@2x.png";
import airlineIcon8L3x from "../../assets/airlines/8L/8L_icon@3x.png";
import airlineIcon8L4x from "../../assets/airlines/8L/8L_icon@4x.png";
import airlineIcon9L2x from "../../assets/airlines/9L/9L_icon@2x.png";
import airlineIcon9L3x from "../../assets/airlines/9L/9L_icon@3x.png";
import airlineIcon9L4x from "../../assets/airlines/9L/9L_icon@4x.png";
import airlineIconAD2x from "../../assets/airlines/AD/AD_icon@2x.png";
import airlineIconAD3x from "../../assets/airlines/AD/AD_icon@3x.png";
import airlineIconAD4x from "../../assets/airlines/AD/AD_icon@4x.png";
import airlineIconB22x from "../../assets/airlines/B2/B2_icon@2x.png";
import airlineIconB23x from "../../assets/airlines/B2/B2_icon@3x.png";
import airlineIconB24x from "../../assets/airlines/B2/B2_icon@4x.png";
import airlineIconBT2x from "../../assets/airlines/BT/BT_icon@2x.png";
import airlineIconBT3x from "../../assets/airlines/BT/BT_icon@3x.png";
import airlineIconBT4x from "../../assets/airlines/BT/BT_icon@4x.png";
import airlineIconCN2x from "../../assets/airlines/CN/CN_icon@2x.png";
import airlineIconCN3x from "../../assets/airlines/CN/CN_icon@3x.png";
import airlineIconCN4x from "../../assets/airlines/CN/CN_icon@4x.png";
import airlineIconDL2x from "../../assets/airlines/DL/DL_icon@2x.png";
import airlineIconDL3x from "../../assets/airlines/DL/DL_icon@3x.png";
import airlineIconDL4x from "../../assets/airlines/DL/DL_icon@4x.png";
import airlineIconEH2x from "../../assets/airlines/EH/EH_icon@2x.png";
import airlineIconEH3x from "../../assets/airlines/EH/EH_icon@3x.png";
import airlineIconEH4x from "../../assets/airlines/EH/EH_icon@4x.png";
import airlineIconF62x from "../../assets/airlines/F6/F6_icon@2x.png";
import airlineIconF63x from "../../assets/airlines/F6/F6_icon@3x.png";
import airlineIconF64x from "../../assets/airlines/F6/F6_icon@4x.png";
import airlineIconFW2x from "../../assets/airlines/FW/FW_icon@2x.png";
import airlineIconFW3x from "../../assets/airlines/FW/FW_icon@3x.png";
import airlineIconFW4x from "../../assets/airlines/FW/FW_icon@4x.png";
import airlineIconGQ2x from "../../assets/airlines/GQ/GQ_icon@2x.png";
import airlineIconGQ3x from "../../assets/airlines/GQ/GQ_icon@3x.png";
import airlineIconGQ4x from "../../assets/airlines/GQ/GQ_icon@4x.png";
import airlineIconHM2x from "../../assets/airlines/HM/HM_icon@2x.png";
import airlineIconHM3x from "../../assets/airlines/HM/HM_icon@3x.png";
import airlineIconHM4x from "../../assets/airlines/HM/HM_icon@4x.png";
import airlineIconIF2x from "../../assets/airlines/IF/IF_icon@2x.png";
import airlineIconIF3x from "../../assets/airlines/IF/IF_icon@3x.png";
import airlineIconIF4x from "../../assets/airlines/IF/IF_icon@4x.png";
import airlineIconJ62x from "../../assets/airlines/J6/J6_icon@2x.png";
import airlineIconJ63x from "../../assets/airlines/J6/J6_icon@3x.png";
import airlineIconJ64x from "../../assets/airlines/J6/J6_icon@4x.png";
import airlineIconJV2x from "../../assets/airlines/JV/JV_icon@2x.png";
import airlineIconJV3x from "../../assets/airlines/JV/JV_icon@3x.png";
import airlineIconJV4x from "../../assets/airlines/JV/JV_icon@4x.png";
import airlineIconKO2x from "../../assets/airlines/KO/KO_icon@2x.png";
import airlineIconKO3x from "../../assets/airlines/KO/KO_icon@3x.png";
import airlineIconKO4x from "../../assets/airlines/KO/KO_icon@4x.png";
import airlineIconLH2x from "../../assets/airlines/LH/LH_icon@2x.png";
import airlineIconLH3x from "../../assets/airlines/LH/LH_icon@3x.png";
import airlineIconLH4x from "../../assets/airlines/LH/LH_icon@4x.png";
import airlineIconM62x from "../../assets/airlines/M6/M6_icon@2x.png";
import airlineIconM63x from "../../assets/airlines/M6/M6_icon@3x.png";
import airlineIconM64x from "../../assets/airlines/M6/M6_icon@4x.png";
import airlineIconMW2x from "../../assets/airlines/MW/MW_icon@2x.png";
import airlineIconMW3x from "../../assets/airlines/MW/MW_icon@3x.png";
import airlineIconMW4x from "../../assets/airlines/MW/MW_icon@4x.png";
import airlineIconNP2x from "../../assets/airlines/NP/NP_icon@2x.png";
import airlineIconNP3x from "../../assets/airlines/NP/NP_icon@3x.png";
import airlineIconNP4x from "../../assets/airlines/NP/NP_icon@4x.png";
import airlineIconON2x from "../../assets/airlines/ON/ON_icon@2x.png";
import airlineIconON3x from "../../assets/airlines/ON/ON_icon@3x.png";
import airlineIconON4x from "../../assets/airlines/ON/ON_icon@4x.png";
import airlineIconPC2x from "../../assets/airlines/PC/PC_icon@2x.png";
import airlineIconPC3x from "../../assets/airlines/PC/PC_icon@3x.png";
import airlineIconPC4x from "../../assets/airlines/PC/PC_icon@4x.png";
import airlineIconQ22x from "../../assets/airlines/Q2/Q2_icon@2x.png";
import airlineIconQ23x from "../../assets/airlines/Q2/Q2_icon@3x.png";
import airlineIconQ24x from "../../assets/airlines/Q2/Q2_icon@4x.png";
import airlineIconQS2x from "../../assets/airlines/QS/QS_icon@2x.png";
import airlineIconQS3x from "../../assets/airlines/QS/QS_icon@3x.png";
import airlineIconQS4x from "../../assets/airlines/QS/QS_icon@4x.png";
import airlineIconRK2x from "../../assets/airlines/RK/RK_icon@2x.png";
import airlineIconRK3x from "../../assets/airlines/RK/RK_icon@3x.png";
import airlineIconRK4x from "../../assets/airlines/RK/RK_icon@4x.png";
import airlineIconSB2x from "../../assets/airlines/SB/SB_icon@2x.png";
import airlineIconSB3x from "../../assets/airlines/SB/SB_icon@3x.png";
import airlineIconSB4x from "../../assets/airlines/SB/SB_icon@4x.png";
import airlineIconSZ2x from "../../assets/airlines/SZ/SZ_icon@2x.png";
import airlineIconSZ3x from "../../assets/airlines/SZ/SZ_icon@3x.png";
import airlineIconSZ4x from "../../assets/airlines/SZ/SZ_icon@4x.png";
import airlineIconTQ2x from "../../assets/airlines/TQ/TQ_icon@2x.png";
import airlineIconTQ3x from "../../assets/airlines/TQ/TQ_icon@3x.png";
import airlineIconTQ4x from "../../assets/airlines/TQ/TQ_icon@4x.png";
import airlineIconUK2x from "../../assets/airlines/UK/UK_icon@2x.png";
import airlineIconUK3x from "../../assets/airlines/UK/UK_icon@3x.png";
import airlineIconUK4x from "../../assets/airlines/UK/UK_icon@4x.png";
import airlineIconVE2x from "../../assets/airlines/VE/VE_icon@2x.png";
import airlineIconVE3x from "../../assets/airlines/VE/VE_icon@3x.png";
import airlineIconVE4x from "../../assets/airlines/VE/VE_icon@4x.png";
import airlineIconW52x from "../../assets/airlines/W5/W5_icon@2x.png";
import airlineIconW53x from "../../assets/airlines/W5/W5_icon@3x.png";
import airlineIconW54x from "../../assets/airlines/W5/W5_icon@4x.png";
import airlineIconWV2x from "../../assets/airlines/WV/WV_icon@2x.png";
import airlineIconWV3x from "../../assets/airlines/WV/WV_icon@3x.png";
import airlineIconWV4x from "../../assets/airlines/WV/WV_icon@4x.png";
import airlineIconXU2x from "../../assets/airlines/XU/XU_icon@2x.png";
import airlineIconXU3x from "../../assets/airlines/XU/XU_icon@3x.png";
import airlineIconXU4x from "../../assets/airlines/XU/XU_icon@4x.png";
import airlineIconYU2x from "../../assets/airlines/YU/YU_icon@2x.png";
import airlineIconYU3x from "../../assets/airlines/YU/YU_icon@3x.png";
import airlineIconYU4x from "../../assets/airlines/YU/YU_icon@4x.png";
import airlineIconZT2x from "../../assets/airlines/ZT/ZT_icon@2x.png";
import airlineIconZT3x from "../../assets/airlines/ZT/ZT_icon@3x.png";
import airlineIconZT4x from "../../assets/airlines/ZT/ZT_icon@4x.png";
import airlineIcon2F2x from "../../assets/airlines/2F/2F_icon@2x.png";
import airlineIcon2F3x from "../../assets/airlines/2F/2F_icon@3x.png";
import airlineIcon2F4x from "../../assets/airlines/2F/2F_icon@4x.png";
import airlineIcon3F2x from "../../assets/airlines/3F/3F_icon@2x.png";
import airlineIcon3F3x from "../../assets/airlines/3F/3F_icon@3x.png";
import airlineIcon3F4x from "../../assets/airlines/3F/3F_icon@4x.png";
import airlineIcon4G2x from "../../assets/airlines/4G/4G_icon@2x.png";
import airlineIcon4G3x from "../../assets/airlines/4G/4G_icon@3x.png";
import airlineIcon4G4x from "../../assets/airlines/4G/4G_icon@4x.png";
import airlineIcon5F2x from "../../assets/airlines/5F/5F_icon@2x.png";
import airlineIcon5F3x from "../../assets/airlines/5F/5F_icon@3x.png";
import airlineIcon5F4x from "../../assets/airlines/5F/5F_icon@4x.png";
import airlineIcon6H2x from "../../assets/airlines/6H/6H_icon@2x.png";
import airlineIcon6H3x from "../../assets/airlines/6H/6H_icon@3x.png";
import airlineIcon6H4x from "../../assets/airlines/6H/6H_icon@4x.png";
import airlineIcon7L2x from "../../assets/airlines/7L/7L_icon@2x.png";
import airlineIcon7L3x from "../../assets/airlines/7L/7L_icon@3x.png";
import airlineIcon7L4x from "../../assets/airlines/7L/7L_icon@4x.png";
import airlineIcon8M2x from "../../assets/airlines/8M/8M_icon@2x.png";
import airlineIcon8M3x from "../../assets/airlines/8M/8M_icon@3x.png";
import airlineIcon8M4x from "../../assets/airlines/8M/8M_icon@4x.png";
import airlineIcon9M2x from "../../assets/airlines/9M/9M_icon@2x.png";
import airlineIcon9M3x from "../../assets/airlines/9M/9M_icon@3x.png";
import airlineIcon9M4x from "../../assets/airlines/9M/9M_icon@4x.png";
import airlineIconAE2x from "../../assets/airlines/AE/AE_icon@2x.png";
import airlineIconAE3x from "../../assets/airlines/AE/AE_icon@3x.png";
import airlineIconAE4x from "../../assets/airlines/AE/AE_icon@4x.png";
import airlineIconB32x from "../../assets/airlines/B3/B3_icon@2x.png";
import airlineIconB33x from "../../assets/airlines/B3/B3_icon@3x.png";
import airlineIconB34x from "../../assets/airlines/B3/B3_icon@4x.png";
import airlineIconBU2x from "../../assets/airlines/BU/BU_icon@2x.png";
import airlineIconBU3x from "../../assets/airlines/BU/BU_icon@3x.png";
import airlineIconBU4x from "../../assets/airlines/BU/BU_icon@4x.png";
import airlineIconCO2x from "../../assets/airlines/CO/CO_icon@2x.png";
import airlineIconCO3x from "../../assets/airlines/CO/CO_icon@3x.png";
import airlineIconCO4x from "../../assets/airlines/CO/CO_icon@4x.png";
import airlineIconDM2x from "../../assets/airlines/DM/DM_icon@2x.png";
import airlineIconDM3x from "../../assets/airlines/DM/DM_icon@3x.png";
import airlineIconDM4x from "../../assets/airlines/DM/DM_icon@4x.png";
import airlineIconEI2x from "../../assets/airlines/EI/EI_icon@2x.png";
import airlineIconEI3x from "../../assets/airlines/EI/EI_icon@3x.png";
import airlineIconEI4x from "../../assets/airlines/EI/EI_icon@4x.png";
import airlineIconF72x from "../../assets/airlines/F7/F7_icon@2x.png";
import airlineIconF73x from "../../assets/airlines/F7/F7_icon@3x.png";
import airlineIconF74x from "../../assets/airlines/F7/F7_icon@4x.png";
import airlineIconFX2x from "../../assets/airlines/FX/FX_icon@2x.png";
import airlineIconFX3x from "../../assets/airlines/FX/FX_icon@3x.png";
import airlineIconFX4x from "../../assets/airlines/FX/FX_icon@4x.png";
import airlineIconGR2x from "../../assets/airlines/GR/GR_icon@2x.png";
import airlineIconGR3x from "../../assets/airlines/GR/GR_icon@3x.png";
import airlineIconGR4x from "../../assets/airlines/GR/GR_icon@4x.png";
import airlineIconHN2x from "../../assets/airlines/HN/HN_icon@2x.png";
import airlineIconHN3x from "../../assets/airlines/HN/HN_icon@3x.png";
import airlineIconHN4x from "../../assets/airlines/HN/HN_icon@4x.png";
import airlineIconIG2x from "../../assets/airlines/IG/IG_icon@2x.png";
import airlineIconIG3x from "../../assets/airlines/IG/IG_icon@3x.png";
import airlineIconIG4x from "../../assets/airlines/IG/IG_icon@4x.png";
import airlineIconJ72x from "../../assets/airlines/J7/J7_icon@2x.png";
import airlineIconJ73x from "../../assets/airlines/J7/J7_icon@3x.png";
import airlineIconJ74x from "../../assets/airlines/J7/J7_icon@4x.png";
import airlineIconJX2x from "../../assets/airlines/JX/JX_icon@2x.png";
import airlineIconJX3x from "../../assets/airlines/JX/JX_icon@3x.png";
import airlineIconJX4x from "../../assets/airlines/JX/JX_icon@4x.png";
import airlineIconKP2x from "../../assets/airlines/KP/KP_icon@2x.png";
import airlineIconKP3x from "../../assets/airlines/KP/KP_icon@3x.png";
import airlineIconKP4x from "../../assets/airlines/KP/KP_icon@4x.png";
import airlineIconLI2x from "../../assets/airlines/LI/LI_icon@2x.png";
import airlineIconLI3x from "../../assets/airlines/LI/LI_icon@3x.png";
import airlineIconLI4x from "../../assets/airlines/LI/LI_icon@4x.png";
import airlineIconM72x from "../../assets/airlines/M7/M7_icon@2x.png";
import airlineIconM73x from "../../assets/airlines/M7/M7_icon@3x.png";
import airlineIconM74x from "../../assets/airlines/M7/M7_icon@4x.png";
import airlineIconMX2x from "../../assets/airlines/MX/MX_icon@2x.png";
import airlineIconMX3x from "../../assets/airlines/MX/MX_icon@3x.png";
import airlineIconMX4x from "../../assets/airlines/MX/MX_icon@4x.png";
import airlineIconNQ2x from "../../assets/airlines/NQ/NQ_icon@2x.png";
import airlineIconNQ3x from "../../assets/airlines/NQ/NQ_icon@3x.png";
import airlineIconNQ4x from "../../assets/airlines/NQ/NQ_icon@4x.png";
import airlineIconOO2x from "../../assets/airlines/OO/OO_icon@2x.png";
import airlineIconOO3x from "../../assets/airlines/OO/OO_icon@3x.png";
import airlineIconOO4x from "../../assets/airlines/OO/OO_icon@4x.png";
import airlineIconPD2x from "../../assets/airlines/PD/PD_icon@2x.png";
import airlineIconPD3x from "../../assets/airlines/PD/PD_icon@3x.png";
import airlineIconPD4x from "../../assets/airlines/PD/PD_icon@4x.png";
import airlineIconQ32x from "../../assets/airlines/Q3/Q3_icon@2x.png";
import airlineIconQ33x from "../../assets/airlines/Q3/Q3_icon@3x.png";
import airlineIconQ34x from "../../assets/airlines/Q3/Q3_icon@4x.png";
import airlineIconQT2x from "../../assets/airlines/QT/QT_icon@2x.png";
import airlineIconQT3x from "../../assets/airlines/QT/QT_icon@3x.png";
import airlineIconQT4x from "../../assets/airlines/QT/QT_icon@4x.png";
import airlineIconRL2x from "../../assets/airlines/RL/RL_icon@2x.png";
import airlineIconRL3x from "../../assets/airlines/RL/RL_icon@3x.png";
import airlineIconRL4x from "../../assets/airlines/RL/RL_icon@4x.png";
import airlineIconSC2x from "../../assets/airlines/SC/SC_icon@2x.png";
import airlineIconSC3x from "../../assets/airlines/SC/SC_icon@3x.png";
import airlineIconSC4x from "../../assets/airlines/SC/SC_icon@4x.png";
import airlineIconT22x from "../../assets/airlines/T2/T2_icon@2x.png";
import airlineIconT23x from "../../assets/airlines/T2/T2_icon@3x.png";
import airlineIconT24x from "../../assets/airlines/T2/T2_icon@4x.png";
import airlineIconTR2x from "../../assets/airlines/TR/TR_icon@2x.png";
import airlineIconTR3x from "../../assets/airlines/TR/TR_icon@3x.png";
import airlineIconTR4x from "../../assets/airlines/TR/TR_icon@4x.png";
import airlineIconUL2x from "../../assets/airlines/UL/UL_icon@2x.png";
import airlineIconUL3x from "../../assets/airlines/UL/UL_icon@3x.png";
import airlineIconUL4x from "../../assets/airlines/UL/UL_icon@4x.png";
import airlineIconVF2x from "../../assets/airlines/VF/VF_icon@2x.png";
import airlineIconVF3x from "../../assets/airlines/VF/VF_icon@3x.png";
import airlineIconVF4x from "../../assets/airlines/VF/VF_icon@4x.png";
import airlineIconW62x from "../../assets/airlines/W6/W6_icon@2x.png";
import airlineIconW63x from "../../assets/airlines/W6/W6_icon@3x.png";
import airlineIconW64x from "../../assets/airlines/W6/W6_icon@4x.png";
import airlineIconWW2x from "../../assets/airlines/WW/WW_icon@2x.png";
import airlineIconWW3x from "../../assets/airlines/WW/WW_icon@3x.png";
import airlineIconWW4x from "../../assets/airlines/WW/WW_icon@4x.png";
import airlineIconXY2x from "../../assets/airlines/XY/XY_icon@2x.png";
import airlineIconXY3x from "../../assets/airlines/XY/XY_icon@3x.png";
import airlineIconXY4x from "../../assets/airlines/XY/XY_icon@4x.png";
import airlineIconYV2x from "../../assets/airlines/YV/YV_icon@2x.png";
import airlineIconYV3x from "../../assets/airlines/YV/YV_icon@3x.png";
import airlineIconYV4x from "../../assets/airlines/YV/YV_icon@4x.png";
import airlineIconZW2x from "../../assets/airlines/ZW/ZW_icon@2x.png";
import airlineIconZW3x from "../../assets/airlines/ZW/ZW_icon@3x.png";
import airlineIconZW4x from "../../assets/airlines/ZW/ZW_icon@4x.png";
import airlineIcon2G2x from "../../assets/airlines/2G/2G_icon@2x.png";
import airlineIcon2G3x from "../../assets/airlines/2G/2G_icon@3x.png";
import airlineIcon2G4x from "../../assets/airlines/2G/2G_icon@4x.png";
import airlineIcon3G2x from "../../assets/airlines/3G/3G_icon@2x.png";
import airlineIcon3G3x from "../../assets/airlines/3G/3G_icon@3x.png";
import airlineIcon3G4x from "../../assets/airlines/3G/3G_icon@4x.png";
import airlineIcon4H2x from "../../assets/airlines/4H/4H_icon@2x.png";
import airlineIcon4H3x from "../../assets/airlines/4H/4H_icon@3x.png";
import airlineIcon4H4x from "../../assets/airlines/4H/4H_icon@4x.png";
import airlineIcon5H2x from "../../assets/airlines/5H/5H_icon@2x.png";
import airlineIcon5H3x from "../../assets/airlines/5H/5H_icon@3x.png";
import airlineIcon5H4x from "../../assets/airlines/5H/5H_icon@4x.png";
import airlineIcon6I2x from "../../assets/airlines/6I/6I_icon@2x.png";
import airlineIcon6I3x from "../../assets/airlines/6I/6I_icon@3x.png";
import airlineIcon6I4x from "../../assets/airlines/6I/6I_icon@4x.png";
import airlineIcon7M2x from "../../assets/airlines/7M/7M_icon@2x.png";
import airlineIcon7M3x from "../../assets/airlines/7M/7M_icon@3x.png";
import airlineIcon7M4x from "../../assets/airlines/7M/7M_icon@4x.png";
import airlineIcon8N2x from "../../assets/airlines/8N/8N_icon@2x.png";
import airlineIcon8N3x from "../../assets/airlines/8N/8N_icon@3x.png";
import airlineIcon8N4x from "../../assets/airlines/8N/8N_icon@4x.png";
import airlineIcon9N2x from "../../assets/airlines/9N/9N_icon@2x.png";
import airlineIcon9N3x from "../../assets/airlines/9N/9N_icon@3x.png";
import airlineIcon9N4x from "../../assets/airlines/9N/9N_icon@4x.png";
import airlineIconAF2x from "../../assets/airlines/AF/AF_icon@2x.png";
import airlineIconAF3x from "../../assets/airlines/AF/AF_icon@3x.png";
import airlineIconAF4x from "../../assets/airlines/AF/AF_icon@4x.png";
import airlineIconB62x from "../../assets/airlines/B6/B6_icon@2x.png";
import airlineIconB63x from "../../assets/airlines/B6/B6_icon@3x.png";
import airlineIconB64x from "../../assets/airlines/B6/B6_icon@4x.png";
import airlineIconBW2x from "../../assets/airlines/BW/BW_icon@2x.png";
import airlineIconBW3x from "../../assets/airlines/BW/BW_icon@3x.png";
import airlineIconBW4x from "../../assets/airlines/BW/BW_icon@4x.png";
import airlineIconCP2x from "../../assets/airlines/CP/CP_icon@2x.png";
import airlineIconCP3x from "../../assets/airlines/CP/CP_icon@3x.png";
import airlineIconCP4x from "../../assets/airlines/CP/CP_icon@4x.png";
import airlineIconDO2x from "../../assets/airlines/DO/DO_icon@2x.png";
import airlineIconDO3x from "../../assets/airlines/DO/DO_icon@3x.png";
import airlineIconDO4x from "../../assets/airlines/DO/DO_icon@4x.png";
import airlineIconEJ2x from "../../assets/airlines/EJ/EJ_icon@2x.png";
import airlineIconEJ3x from "../../assets/airlines/EJ/EJ_icon@3x.png";
import airlineIconEJ4x from "../../assets/airlines/EJ/EJ_icon@4x.png";
import airlineIconF82x from "../../assets/airlines/F8/F8_icon@2x.png";
import airlineIconF83x from "../../assets/airlines/F8/F8_icon@3x.png";
import airlineIconF84x from "../../assets/airlines/F8/F8_icon@4x.png";
import airlineIconFY2x from "../../assets/airlines/FY/FY_icon@2x.png";
import airlineIconFY3x from "../../assets/airlines/FY/FY_icon@3x.png";
import airlineIconFY4x from "../../assets/airlines/FY/FY_icon@4x.png";
import airlineIconGS2x from "../../assets/airlines/GS/GS_icon@2x.png";
import airlineIconGS3x from "../../assets/airlines/GS/GS_icon@3x.png";
import airlineIconGS4x from "../../assets/airlines/GS/GS_icon@4x.png";
import airlineIconHO2x from "../../assets/airlines/HO/HO_icon@2x.png";
import airlineIconHO3x from "../../assets/airlines/HO/HO_icon@3x.png";
import airlineIconHO4x from "../../assets/airlines/HO/HO_icon@4x.png";
import airlineIconII2x from "../../assets/airlines/II/II_icon@2x.png";
import airlineIconII3x from "../../assets/airlines/II/II_icon@3x.png";
import airlineIconII4x from "../../assets/airlines/II/II_icon@4x.png";
import airlineIconJ82x from "../../assets/airlines/J8/J8_icon@2x.png";
import airlineIconJ83x from "../../assets/airlines/J8/J8_icon@3x.png";
import airlineIconJ84x from "../../assets/airlines/J8/J8_icon@4x.png";
import airlineIconJY2x from "../../assets/airlines/JY/JY_icon@2x.png";
import airlineIconJY3x from "../../assets/airlines/JY/JY_icon@3x.png";
import airlineIconJY4x from "../../assets/airlines/JY/JY_icon@4x.png";
import airlineIconKQ2x from "../../assets/airlines/KQ/KQ_icon@2x.png";
import airlineIconKQ3x from "../../assets/airlines/KQ/KQ_icon@3x.png";
import airlineIconKQ4x from "../../assets/airlines/KQ/KQ_icon@4x.png";
import airlineIconLJ2x from "../../assets/airlines/LJ/LJ_icon@2x.png";
import airlineIconLJ3x from "../../assets/airlines/LJ/LJ_icon@3x.png";
import airlineIconLJ4x from "../../assets/airlines/LJ/LJ_icon@4x.png";
import airlineIconM82x from "../../assets/airlines/M8/M8_icon@2x.png";
import airlineIconM83x from "../../assets/airlines/M8/M8_icon@3x.png";
import airlineIconM84x from "../../assets/airlines/M8/M8_icon@4x.png";
import airlineIconMY2x from "../../assets/airlines/MY/MY_icon@2x.png";
import airlineIconMY3x from "../../assets/airlines/MY/MY_icon@3x.png";
import airlineIconMY4x from "../../assets/airlines/MY/MY_icon@4x.png";
import airlineIconNR2x from "../../assets/airlines/NR/NR_icon@2x.png";
import airlineIconNR3x from "../../assets/airlines/NR/NR_icon@3x.png";
import airlineIconNR4x from "../../assets/airlines/NR/NR_icon@4x.png";
import airlineIconOP2x from "../../assets/airlines/OP/OP_icon@2x.png";
import airlineIconOP3x from "../../assets/airlines/OP/OP_icon@3x.png";
import airlineIconOP4x from "../../assets/airlines/OP/OP_icon@4x.png";
import airlineIconPE2x from "../../assets/airlines/PE/PE_icon@2x.png";
import airlineIconPE3x from "../../assets/airlines/PE/PE_icon@3x.png";
import airlineIconPE4x from "../../assets/airlines/PE/PE_icon@4x.png";
import airlineIconQ52x from "../../assets/airlines/Q5/Q5_icon@2x.png";
import airlineIconQ53x from "../../assets/airlines/Q5/Q5_icon@3x.png";
import airlineIconQ54x from "../../assets/airlines/Q5/Q5_icon@4x.png";
import airlineIconQU2x from "../../assets/airlines/QU/QU_icon@2x.png";
import airlineIconQU3x from "../../assets/airlines/QU/QU_icon@3x.png";
import airlineIconQU4x from "../../assets/airlines/QU/QU_icon@4x.png";
import airlineIconRM2x from "../../assets/airlines/RM/RM_icon@2x.png";
import airlineIconRM3x from "../../assets/airlines/RM/RM_icon@3x.png";
import airlineIconRM4x from "../../assets/airlines/RM/RM_icon@4x.png";
import airlineIconSD2x from "../../assets/airlines/SD/SD_icon@2x.png";
import airlineIconSD3x from "../../assets/airlines/SD/SD_icon@3x.png";
import airlineIconSD4x from "../../assets/airlines/SD/SD_icon@4x.png";
import airlineIconT32x from "../../assets/airlines/T3/T3_icon@2x.png";
import airlineIconT33x from "../../assets/airlines/T3/T3_icon@3x.png";
import airlineIconT34x from "../../assets/airlines/T3/T3_icon@4x.png";
import airlineIconTS2x from "../../assets/airlines/TS/TS_icon@2x.png";
import airlineIconTS3x from "../../assets/airlines/TS/TS_icon@3x.png";
import airlineIconTS4x from "../../assets/airlines/TS/TS_icon@4x.png";
import airlineIconUM2x from "../../assets/airlines/UM/UM_icon@2x.png";
import airlineIconUM3x from "../../assets/airlines/UM/UM_icon@3x.png";
import airlineIconUM4x from "../../assets/airlines/UM/UM_icon@4x.png";
import airlineIconVH2x from "../../assets/airlines/VH/VH_icon@2x.png";
import airlineIconVH3x from "../../assets/airlines/VH/VH_icon@3x.png";
import airlineIconVH4x from "../../assets/airlines/VH/VH_icon@4x.png";
import airlineIconW82x from "../../assets/airlines/W8/W8_icon@2x.png";
import airlineIconW83x from "../../assets/airlines/W8/W8_icon@3x.png";
import airlineIconW84x from "../../assets/airlines/W8/W8_icon@4x.png";
import airlineIconWX2x from "../../assets/airlines/WX/WX_icon@2x.png";
import airlineIconWX3x from "../../assets/airlines/WX/WX_icon@3x.png";
import airlineIconWX4x from "../../assets/airlines/WX/WX_icon@4x.png";
import airlineIconY22x from "../../assets/airlines/Y2/Y2_icon@2x.png";
import airlineIconY23x from "../../assets/airlines/Y2/Y2_icon@3x.png";
import airlineIconY24x from "../../assets/airlines/Y2/Y2_icon@4x.png";
import airlineIconYW2x from "../../assets/airlines/YW/YW_icon@2x.png";
import airlineIconYW3x from "../../assets/airlines/YW/YW_icon@3x.png";
import airlineIconYW4x from "../../assets/airlines/YW/YW_icon@4x.png";
import airlineIconZX2x from "../../assets/airlines/ZX/ZX_icon@2x.png";
import airlineIconZX3x from "../../assets/airlines/ZX/ZX_icon@3x.png";
import airlineIconZX4x from "../../assets/airlines/ZX/ZX_icon@4x.png";
import airlineIcon2H2x from "../../assets/airlines/2H/2H_icon@2x.png";
import airlineIcon2H3x from "../../assets/airlines/2H/2H_icon@3x.png";
import airlineIcon2H4x from "../../assets/airlines/2H/2H_icon@4x.png";
import airlineIcon3H2x from "../../assets/airlines/3H/3H_icon@2x.png";
import airlineIcon3H3x from "../../assets/airlines/3H/3H_icon@3x.png";
import airlineIcon3H4x from "../../assets/airlines/3H/3H_icon@4x.png";
import airlineIcon4I2x from "../../assets/airlines/4I/4I_icon@2x.png";
import airlineIcon4I3x from "../../assets/airlines/4I/4I_icon@3x.png";
import airlineIcon4I4x from "../../assets/airlines/4I/4I_icon@4x.png";
import airlineIcon5I2x from "../../assets/airlines/5I/5I_icon@2x.png";
import airlineIcon5I3x from "../../assets/airlines/5I/5I_icon@3x.png";
import airlineIcon5I4x from "../../assets/airlines/5I/5I_icon@4x.png";
import airlineIcon6J2x from "../../assets/airlines/6J/6J_icon@2x.png";
import airlineIcon6J3x from "../../assets/airlines/6J/6J_icon@3x.png";
import airlineIcon6J4x from "../../assets/airlines/6J/6J_icon@4x.png";
import airlineIcon7O2x from "../../assets/airlines/7O/7O_icon@2x.png";
import airlineIcon7O3x from "../../assets/airlines/7O/7O_icon@3x.png";
import airlineIcon7O4x from "../../assets/airlines/7O/7O_icon@4x.png";
import airlineIcon8P2x from "../../assets/airlines/8P/8P_icon@2x.png";
import airlineIcon8P3x from "../../assets/airlines/8P/8P_icon@3x.png";
import airlineIcon8P4x from "../../assets/airlines/8P/8P_icon@4x.png";
import airlineIcon9O2x from "../../assets/airlines/9O/9O_icon@2x.png";
import airlineIcon9O3x from "../../assets/airlines/9O/9O_icon@3x.png";
import airlineIcon9O4x from "../../assets/airlines/9O/9O_icon@4x.png";
import airlineIconAG2x from "../../assets/airlines/AG/AG_icon@2x.png";
import airlineIconAG3x from "../../assets/airlines/AG/AG_icon@3x.png";
import airlineIconAG4x from "../../assets/airlines/AG/AG_icon@4x.png";
import airlineIconB72x from "../../assets/airlines/B7/B7_icon@2x.png";
import airlineIconB73x from "../../assets/airlines/B7/B7_icon@3x.png";
import airlineIconB74x from "../../assets/airlines/B7/B7_icon@4x.png";
import airlineIconBX2x from "../../assets/airlines/BX/BX_icon@2x.png";
import airlineIconBX3x from "../../assets/airlines/BX/BX_icon@3x.png";
import airlineIconBX4x from "../../assets/airlines/BX/BX_icon@4x.png";
import airlineIconCQ2x from "../../assets/airlines/CQ/CQ_icon@2x.png";
import airlineIconCQ3x from "../../assets/airlines/CQ/CQ_icon@3x.png";
import airlineIconCQ4x from "../../assets/airlines/CQ/CQ_icon@4x.png";
import airlineIconDP2x from "../../assets/airlines/DP/DP_icon@2x.png";
import airlineIconDP3x from "../../assets/airlines/DP/DP_icon@3x.png";
import airlineIconDP4x from "../../assets/airlines/DP/DP_icon@4x.png";
import airlineIconEK2x from "../../assets/airlines/EK/EK_icon@2x.png";
import airlineIconEK3x from "../../assets/airlines/EK/EK_icon@3x.png";
import airlineIconEK4x from "../../assets/airlines/EK/EK_icon@4x.png";
import airlineIconF92x from "../../assets/airlines/F9/F9_icon@2x.png";
import airlineIconF93x from "../../assets/airlines/F9/F9_icon@3x.png";
import airlineIconF94x from "../../assets/airlines/F9/F9_icon@4x.png";
import airlineIconFZ2x from "../../assets/airlines/FZ/FZ_icon@2x.png";
import airlineIconFZ3x from "../../assets/airlines/FZ/FZ_icon@3x.png";
import airlineIconFZ4x from "../../assets/airlines/FZ/FZ_icon@4x.png";
import airlineIconGT2x from "../../assets/airlines/GT/GT_icon@2x.png";
import airlineIconGT3x from "../../assets/airlines/GT/GT_icon@3x.png";
import airlineIconGT4x from "../../assets/airlines/GT/GT_icon@4x.png";
import airlineIconHP2x from "../../assets/airlines/HP/HP_icon@2x.png";
import airlineIconHP3x from "../../assets/airlines/HP/HP_icon@3x.png";
import airlineIconHP4x from "../../assets/airlines/HP/HP_icon@4x.png";
import airlineIconIJ2x from "../../assets/airlines/IJ/IJ_icon@2x.png";
import airlineIconIJ3x from "../../assets/airlines/IJ/IJ_icon@3x.png";
import airlineIconIJ4x from "../../assets/airlines/IJ/IJ_icon@4x.png";
import airlineIconJ92x from "../../assets/airlines/J9/J9_icon@2x.png";
import airlineIconJ93x from "../../assets/airlines/J9/J9_icon@3x.png";
import airlineIconJ94x from "../../assets/airlines/J9/J9_icon@4x.png";
import airlineIconK12x from "../../assets/airlines/K1/K1_icon@2x.png";
import airlineIconK13x from "../../assets/airlines/K1/K1_icon@3x.png";
import airlineIconK14x from "../../assets/airlines/K1/K1_icon@4x.png";
import airlineIconKR2x from "../../assets/airlines/KR/KR_icon@2x.png";
import airlineIconKR3x from "../../assets/airlines/KR/KR_icon@3x.png";
import airlineIconKR4x from "../../assets/airlines/KR/KR_icon@4x.png";
import airlineIconLK2x from "../../assets/airlines/LK/LK_icon@2x.png";
import airlineIconLK3x from "../../assets/airlines/LK/LK_icon@3x.png";
import airlineIconLK4x from "../../assets/airlines/LK/LK_icon@4x.png";
import airlineIconM92x from "../../assets/airlines/M9/M9_icon@2x.png";
import airlineIconM93x from "../../assets/airlines/M9/M9_icon@3x.png";
import airlineIconM94x from "../../assets/airlines/M9/M9_icon@4x.png";
import airlineIconMZ2x from "../../assets/airlines/MZ/MZ_icon@2x.png";
import airlineIconMZ3x from "../../assets/airlines/MZ/MZ_icon@3x.png";
import airlineIconMZ4x from "../../assets/airlines/MZ/MZ_icon@4x.png";
import airlineIconNS2x from "../../assets/airlines/NS/NS_icon@2x.png";
import airlineIconNS3x from "../../assets/airlines/NS/NS_icon@3x.png";
import airlineIconNS4x from "../../assets/airlines/NS/NS_icon@4x.png";
import airlineIconOQ2x from "../../assets/airlines/OQ/OQ_icon@2x.png";
import airlineIconOQ3x from "../../assets/airlines/OQ/OQ_icon@3x.png";
import airlineIconOQ4x from "../../assets/airlines/OQ/OQ_icon@4x.png";
import airlineIconPF2x from "../../assets/airlines/PF/PF_icon@2x.png";
import airlineIconPF3x from "../../assets/airlines/PF/PF_icon@3x.png";
import airlineIconPF4x from "../../assets/airlines/PF/PF_icon@4x.png";
import airlineIconQ62x from "../../assets/airlines/Q6/Q6_icon@2x.png";
import airlineIconQ63x from "../../assets/airlines/Q6/Q6_icon@3x.png";
import airlineIconQ64x from "../../assets/airlines/Q6/Q6_icon@4x.png";
import airlineIconQV2x from "../../assets/airlines/QV/QV_icon@2x.png";
import airlineIconQV3x from "../../assets/airlines/QV/QV_icon@3x.png";
import airlineIconQV4x from "../../assets/airlines/QV/QV_icon@4x.png";
import airlineIconRO2x from "../../assets/airlines/RO/RO_icon@2x.png";
import airlineIconRO3x from "../../assets/airlines/RO/RO_icon@3x.png";
import airlineIconRO4x from "../../assets/airlines/RO/RO_icon@4x.png";
import airlineIconSE2x from "../../assets/airlines/SE/SE_icon@2x.png";
import airlineIconSE3x from "../../assets/airlines/SE/SE_icon@3x.png";
import airlineIconSE4x from "../../assets/airlines/SE/SE_icon@4x.png";
import airlineIconT42x from "../../assets/airlines/T4/T4_icon@2x.png";
import airlineIconT43x from "../../assets/airlines/T4/T4_icon@3x.png";
import airlineIconT44x from "../../assets/airlines/T4/T4_icon@4x.png";
import airlineIconTU2x from "../../assets/airlines/TU/TU_icon@2x.png";
import airlineIconTU3x from "../../assets/airlines/TU/TU_icon@3x.png";
import airlineIconTU4x from "../../assets/airlines/TU/TU_icon@4x.png";
import airlineIconUN2x from "../../assets/airlines/UN/UN_icon@2x.png";
import airlineIconUN3x from "../../assets/airlines/UN/UN_icon@3x.png";
import airlineIconUN4x from "../../assets/airlines/UN/UN_icon@4x.png";
import airlineIconVI2x from "../../assets/airlines/VI/VI_icon@2x.png";
import airlineIconVI3x from "../../assets/airlines/VI/VI_icon@3x.png";
import airlineIconVI4x from "../../assets/airlines/VI/VI_icon@4x.png";
import airlineIconW92x from "../../assets/airlines/W9/W9_icon@2x.png";
import airlineIconW93x from "../../assets/airlines/W9/W9_icon@3x.png";
import airlineIconW94x from "../../assets/airlines/W9/W9_icon@4x.png";
import airlineIconWY2x from "../../assets/airlines/WY/WY_icon@2x.png";
import airlineIconWY3x from "../../assets/airlines/WY/WY_icon@3x.png";
import airlineIconWY4x from "../../assets/airlines/WY/WY_icon@4x.png";
import airlineIconY42x from "../../assets/airlines/Y4/Y4_icon@2x.png";
import airlineIconY43x from "../../assets/airlines/Y4/Y4_icon@3x.png";
import airlineIconY44x from "../../assets/airlines/Y4/Y4_icon@4x.png";
import airlineIconYX2x from "../../assets/airlines/YX/YX_icon@2x.png";
import airlineIconYX3x from "../../assets/airlines/YX/YX_icon@3x.png";
import airlineIconYX4x from "../../assets/airlines/YX/YX_icon@4x.png";
import airlineIcon2I2x from "../../assets/airlines/2I/2I_icon@2x.png";
import airlineIcon2I3x from "../../assets/airlines/2I/2I_icon@3x.png";
import airlineIcon2I4x from "../../assets/airlines/2I/2I_icon@4x.png";
import airlineIcon3J2x from "../../assets/airlines/3J/3J_icon@2x.png";
import airlineIcon3J3x from "../../assets/airlines/3J/3J_icon@3x.png";
import airlineIcon3J4x from "../../assets/airlines/3J/3J_icon@4x.png";
import airlineIcon4J2x from "../../assets/airlines/4J/4J_icon@2x.png";
import airlineIcon4J3x from "../../assets/airlines/4J/4J_icon@3x.png";
import airlineIcon4J4x from "../../assets/airlines/4J/4J_icon@4x.png";
import airlineIcon5J2x from "../../assets/airlines/5J/5J_icon@2x.png";
import airlineIcon5J3x from "../../assets/airlines/5J/5J_icon@3x.png";
import airlineIcon5J4x from "../../assets/airlines/5J/5J_icon@4x.png";
import airlineIcon6L2x from "../../assets/airlines/6L/6L_icon@2x.png";
import airlineIcon6L3x from "../../assets/airlines/6L/6L_icon@3x.png";
import airlineIcon6L4x from "../../assets/airlines/6L/6L_icon@4x.png";
import airlineIcon7P2x from "../../assets/airlines/7P/7P_icon@2x.png";
import airlineIcon7P3x from "../../assets/airlines/7P/7P_icon@3x.png";
import airlineIcon7P4x from "../../assets/airlines/7P/7P_icon@4x.png";
import airlineIcon8Q2x from "../../assets/airlines/8Q/8Q_icon@2x.png";
import airlineIcon8Q3x from "../../assets/airlines/8Q/8Q_icon@3x.png";
import airlineIcon8Q4x from "../../assets/airlines/8Q/8Q_icon@4x.png";
import airlineIcon9Q2x from "../../assets/airlines/9Q/9Q_icon@2x.png";
import airlineIcon9Q3x from "../../assets/airlines/9Q/9Q_icon@3x.png";
import airlineIcon9Q4x from "../../assets/airlines/9Q/9Q_icon@4x.png";
import airlineIconAH2x from "../../assets/airlines/AH/AH_icon@2x.png";
import airlineIconAH3x from "../../assets/airlines/AH/AH_icon@3x.png";
import airlineIconAH4x from "../../assets/airlines/AH/AH_icon@4x.png";
import airlineIconB82x from "../../assets/airlines/B8/B8_icon@2x.png";
import airlineIconB83x from "../../assets/airlines/B8/B8_icon@3x.png";
import airlineIconB84x from "../../assets/airlines/B8/B8_icon@4x.png";
import airlineIconBY2x from "../../assets/airlines/BY/BY_icon@2x.png";
import airlineIconBY3x from "../../assets/airlines/BY/BY_icon@3x.png";
import airlineIconBY4x from "../../assets/airlines/BY/BY_icon@4x.png";
import airlineIconCU2x from "../../assets/airlines/CU/CU_icon@2x.png";
import airlineIconCU3x from "../../assets/airlines/CU/CU_icon@3x.png";
import airlineIconCU4x from "../../assets/airlines/CU/CU_icon@4x.png";
import airlineIconDQ2x from "../../assets/airlines/DQ/DQ_icon@2x.png";
import airlineIconDQ3x from "../../assets/airlines/DQ/DQ_icon@3x.png";
import airlineIconDQ4x from "../../assets/airlines/DQ/DQ_icon@4x.png";
import airlineIconEM2x from "../../assets/airlines/EM/EM_icon@2x.png";
import airlineIconEM3x from "../../assets/airlines/EM/EM_icon@3x.png";
import airlineIconEM4x from "../../assets/airlines/EM/EM_icon@4x.png";
import airlineIconFA2x from "../../assets/airlines/FA/FA_icon@2x.png";
import airlineIconFA3x from "../../assets/airlines/FA/FA_icon@3x.png";
import airlineIconFA4x from "../../assets/airlines/FA/FA_icon@4x.png";
import airlineIconG02x from "../../assets/airlines/G0/G0_icon@2x.png";
import airlineIconG03x from "../../assets/airlines/G0/G0_icon@3x.png";
import airlineIconG04x from "../../assets/airlines/G0/G0_icon@4x.png";
import airlineIconGU2x from "../../assets/airlines/GU/GU_icon@2x.png";
import airlineIconGU3x from "../../assets/airlines/GU/GU_icon@3x.png";
import airlineIconGU4x from "../../assets/airlines/GU/GU_icon@4x.png";
import airlineIconHR2x from "../../assets/airlines/HR/HR_icon@2x.png";
import airlineIconHR3x from "../../assets/airlines/HR/HR_icon@3x.png";
import airlineIconHR4x from "../../assets/airlines/HR/HR_icon@4x.png";
import airlineIconIK2x from "../../assets/airlines/IK/IK_icon@2x.png";
import airlineIconIK3x from "../../assets/airlines/IK/IK_icon@3x.png";
import airlineIconIK4x from "../../assets/airlines/IK/IK_icon@4x.png";
import airlineIconJA2x from "../../assets/airlines/JA/JA_icon@2x.png";
import airlineIconJA3x from "../../assets/airlines/JA/JA_icon@3x.png";
import airlineIconJA4x from "../../assets/airlines/JA/JA_icon@4x.png";
import airlineIconK32x from "../../assets/airlines/K3/K3_icon@2x.png";
import airlineIconK33x from "../../assets/airlines/K3/K3_icon@3x.png";
import airlineIconK34x from "../../assets/airlines/K3/K3_icon@4x.png";
import airlineIconKS2x from "../../assets/airlines/KS/KS_icon@2x.png";
import airlineIconKS3x from "../../assets/airlines/KS/KS_icon@3x.png";
import airlineIconKS4x from "../../assets/airlines/KS/KS_icon@4x.png";
import airlineIconLM2x from "../../assets/airlines/LM/LM_icon@2x.png";
import airlineIconLM3x from "../../assets/airlines/LM/LM_icon@3x.png";
import airlineIconLM4x from "../../assets/airlines/LM/LM_icon@4x.png";
import airlineIconMD2x from "../../assets/airlines/MD/MD_icon@2x.png";
import airlineIconMD3x from "../../assets/airlines/MD/MD_icon@3x.png";
import airlineIconMD4x from "../../assets/airlines/MD/MD_icon@4x.png";
import airlineIconN02x from "../../assets/airlines/N0/N0_icon@2x.png";
import airlineIconN03x from "../../assets/airlines/N0/N0_icon@3x.png";
import airlineIconN04x from "../../assets/airlines/N0/N0_icon@4x.png";
import airlineIconNT2x from "../../assets/airlines/NT/NT_icon@2x.png";
import airlineIconNT3x from "../../assets/airlines/NT/NT_icon@3x.png";
import airlineIconNT4x from "../../assets/airlines/NT/NT_icon@4x.png";
import airlineIconOR2x from "../../assets/airlines/OR/OR_icon@2x.png";
import airlineIconOR3x from "../../assets/airlines/OR/OR_icon@3x.png";
import airlineIconOR4x from "../../assets/airlines/OR/OR_icon@4x.png";
import airlineIconPG2x from "../../assets/airlines/PG/PG_icon@2x.png";
import airlineIconPG3x from "../../assets/airlines/PG/PG_icon@3x.png";
import airlineIconPG4x from "../../assets/airlines/PG/PG_icon@4x.png";
import airlineIconQ72x from "../../assets/airlines/Q7/Q7_icon@2x.png";
import airlineIconQ73x from "../../assets/airlines/Q7/Q7_icon@3x.png";
import airlineIconQ74x from "../../assets/airlines/Q7/Q7_icon@4x.png";
import airlineIconQW2x from "../../assets/airlines/QW/QW_icon@2x.png";
import airlineIconQW3x from "../../assets/airlines/QW/QW_icon@3x.png";
import airlineIconQW4x from "../../assets/airlines/QW/QW_icon@4x.png";
import airlineIconRQ2x from "../../assets/airlines/RQ/RQ_icon@2x.png";
import airlineIconRQ3x from "../../assets/airlines/RQ/RQ_icon@3x.png";
import airlineIconRQ4x from "../../assets/airlines/RQ/RQ_icon@4x.png";
import airlineIconSF2x from "../../assets/airlines/SF/SF_icon@2x.png";
import airlineIconSF3x from "../../assets/airlines/SF/SF_icon@3x.png";
import airlineIconSF4x from "../../assets/airlines/SF/SF_icon@4x.png";
import airlineIconT52x from "../../assets/airlines/T5/T5_icon@2x.png";
import airlineIconT53x from "../../assets/airlines/T5/T5_icon@3x.png";
import airlineIconT54x from "../../assets/airlines/T5/T5_icon@4x.png";
import airlineIconTV2x from "../../assets/airlines/TV/TV_icon@2x.png";
import airlineIconTV3x from "../../assets/airlines/TV/TV_icon@3x.png";
import airlineIconTV4x from "../../assets/airlines/TV/TV_icon@4x.png";
import airlineIconUO2x from "../../assets/airlines/UO/UO_icon@2x.png";
import airlineIconUO3x from "../../assets/airlines/UO/UO_icon@3x.png";
import airlineIconUO4x from "../../assets/airlines/UO/UO_icon@4x.png";
import airlineIconVJ2x from "../../assets/airlines/VJ/VJ_icon@2x.png";
import airlineIconVJ3x from "../../assets/airlines/VJ/VJ_icon@3x.png";
import airlineIconVJ4x from "../../assets/airlines/VJ/VJ_icon@4x.png";
import airlineIconWA2x from "../../assets/airlines/WA/WA_icon@2x.png";
import airlineIconWA3x from "../../assets/airlines/WA/WA_icon@3x.png";
import airlineIconWA4x from "../../assets/airlines/WA/WA_icon@4x.png";
import airlineIconWZ2x from "../../assets/airlines/WZ/WZ_icon@2x.png";
import airlineIconWZ3x from "../../assets/airlines/WZ/WZ_icon@3x.png";
import airlineIconWZ4x from "../../assets/airlines/WZ/WZ_icon@4x.png";
import airlineIconY52x from "../../assets/airlines/Y5/Y5_icon@2x.png";
import airlineIconY53x from "../../assets/airlines/Y5/Y5_icon@3x.png";
import airlineIconY54x from "../../assets/airlines/Y5/Y5_icon@4x.png";
import airlineIconZ02x from "../../assets/airlines/Z0/Z0_icon@2x.png";
import airlineIconZ03x from "../../assets/airlines/Z0/Z0_icon@3x.png";
import airlineIconZ04x from "../../assets/airlines/Z0/Z0_icon@4x.png";
import airlineIcon2J2x from "../../assets/airlines/2J/2J_icon@2x.png";
import airlineIcon2J3x from "../../assets/airlines/2J/2J_icon@3x.png";
import airlineIcon2J4x from "../../assets/airlines/2J/2J_icon@4x.png";
import airlineIcon3K2x from "../../assets/airlines/3K/3K_icon@2x.png";
import airlineIcon3K3x from "../../assets/airlines/3K/3K_icon@3x.png";
import airlineIcon3K4x from "../../assets/airlines/3K/3K_icon@4x.png";
import airlineIcon4K2x from "../../assets/airlines/4K/4K_icon@2x.png";
import airlineIcon4K3x from "../../assets/airlines/4K/4K_icon@3x.png";
import airlineIcon4K4x from "../../assets/airlines/4K/4K_icon@4x.png";
import airlineIcon5K2x from "../../assets/airlines/5K/5K_icon@2x.png";
import airlineIcon5K3x from "../../assets/airlines/5K/5K_icon@3x.png";
import airlineIcon5K4x from "../../assets/airlines/5K/5K_icon@4x.png";
import airlineIcon6M2x from "../../assets/airlines/6M/6M_icon@2x.png";
import airlineIcon6M3x from "../../assets/airlines/6M/6M_icon@3x.png";
import airlineIcon6M4x from "../../assets/airlines/6M/6M_icon@4x.png";
import airlineIcon7Q2x from "../../assets/airlines/7Q/7Q_icon@2x.png";
import airlineIcon7Q3x from "../../assets/airlines/7Q/7Q_icon@3x.png";
import airlineIcon7Q4x from "../../assets/airlines/7Q/7Q_icon@4x.png";
import airlineIcon8R2x from "../../assets/airlines/8R/8R_icon@2x.png";
import airlineIcon8R3x from "../../assets/airlines/8R/8R_icon@3x.png";
import airlineIcon8R4x from "../../assets/airlines/8R/8R_icon@4x.png";
import airlineIcon9R2x from "../../assets/airlines/9R/9R_icon@2x.png";
import airlineIcon9R3x from "../../assets/airlines/9R/9R_icon@3x.png";
import airlineIcon9R4x from "../../assets/airlines/9R/9R_icon@4x.png";
import airlineIconAI2x from "../../assets/airlines/AI/AI_icon@2x.png";
import airlineIconAI3x from "../../assets/airlines/AI/AI_icon@3x.png";
import airlineIconAI4x from "../../assets/airlines/AI/AI_icon@4x.png";
import airlineIconB92x from "../../assets/airlines/B9/B9_icon@2x.png";
import airlineIconB93x from "../../assets/airlines/B9/B9_icon@3x.png";
import airlineIconB94x from "../../assets/airlines/B9/B9_icon@4x.png";
import airlineIconBZ2x from "../../assets/airlines/BZ/BZ_icon@2x.png";
import airlineIconBZ3x from "../../assets/airlines/BZ/BZ_icon@3x.png";
import airlineIconBZ4x from "../../assets/airlines/BZ/BZ_icon@4x.png";
import airlineIconCV2x from "../../assets/airlines/CV/CV_icon@2x.png";
import airlineIconCV3x from "../../assets/airlines/CV/CV_icon@3x.png";
import airlineIconCV4x from "../../assets/airlines/CV/CV_icon@4x.png";
import airlineIconDR2x from "../../assets/airlines/DR/DR_icon@2x.png";
import airlineIconDR3x from "../../assets/airlines/DR/DR_icon@3x.png";
import airlineIconDR4x from "../../assets/airlines/DR/DR_icon@4x.png";
import airlineIconEN2x from "../../assets/airlines/EN/EN_icon@2x.png";
import airlineIconEN3x from "../../assets/airlines/EN/EN_icon@3x.png";
import airlineIconEN4x from "../../assets/airlines/EN/EN_icon@4x.png";
import airlineIconFB2x from "../../assets/airlines/FB/FB_icon@2x.png";
import airlineIconFB3x from "../../assets/airlines/FB/FB_icon@3x.png";
import airlineIconFB4x from "../../assets/airlines/FB/FB_icon@4x.png";
import airlineIconG22x from "../../assets/airlines/G2/G2_icon@2x.png";
import airlineIconG23x from "../../assets/airlines/G2/G2_icon@3x.png";
import airlineIconG24x from "../../assets/airlines/G2/G2_icon@4x.png";
import airlineIconGV2x from "../../assets/airlines/GV/GV_icon@2x.png";
import airlineIconGV3x from "../../assets/airlines/GV/GV_icon@3x.png";
import airlineIconGV4x from "../../assets/airlines/GV/GV_icon@4x.png";
import airlineIconHS2x from "../../assets/airlines/HS/HS_icon@2x.png";
import airlineIconHS3x from "../../assets/airlines/HS/HS_icon@3x.png";
import airlineIconHS4x from "../../assets/airlines/HS/HS_icon@4x.png";
import airlineIconIL2x from "../../assets/airlines/IL/IL_icon@2x.png";
import airlineIconIL3x from "../../assets/airlines/IL/IL_icon@3x.png";
import airlineIconIL4x from "../../assets/airlines/IL/IL_icon@4x.png";
import airlineIconJB2x from "../../assets/airlines/JB/JB_icon@2x.png";
import airlineIconJB3x from "../../assets/airlines/JB/JB_icon@3x.png";
import airlineIconJB4x from "../../assets/airlines/JB/JB_icon@4x.png";
import airlineIconK42x from "../../assets/airlines/K4/K4_icon@2x.png";
import airlineIconK43x from "../../assets/airlines/K4/K4_icon@3x.png";
import airlineIconK44x from "../../assets/airlines/K4/K4_icon@4x.png";
import airlineIconKT2x from "../../assets/airlines/KT/KT_icon@2x.png";
import airlineIconKT3x from "../../assets/airlines/KT/KT_icon@3x.png";
import airlineIconKT4x from "../../assets/airlines/KT/KT_icon@4x.png";
import airlineIconLN2x from "../../assets/airlines/LN/LN_icon@2x.png";
import airlineIconLN3x from "../../assets/airlines/LN/LN_icon@3x.png";
import airlineIconLN4x from "../../assets/airlines/LN/LN_icon@4x.png";
import airlineIconME2x from "../../assets/airlines/ME/ME_icon@2x.png";
import airlineIconME3x from "../../assets/airlines/ME/ME_icon@3x.png";
import airlineIconME4x from "../../assets/airlines/ME/ME_icon@4x.png";
import airlineIconN22x from "../../assets/airlines/N2/N2_icon@2x.png";
import airlineIconN23x from "../../assets/airlines/N2/N2_icon@3x.png";
import airlineIconN24x from "../../assets/airlines/N2/N2_icon@4x.png";
import airlineIconNU2x from "../../assets/airlines/NU/NU_icon@2x.png";
import airlineIconNU3x from "../../assets/airlines/NU/NU_icon@3x.png";
import airlineIconNU4x from "../../assets/airlines/NU/NU_icon@4x.png";
import airlineIconOS2x from "../../assets/airlines/OS/OS_icon@2x.png";
import airlineIconOS3x from "../../assets/airlines/OS/OS_icon@3x.png";
import airlineIconOS4x from "../../assets/airlines/OS/OS_icon@4x.png";
import airlineIconPH2x from "../../assets/airlines/PH/PH_icon@2x.png";
import airlineIconPH3x from "../../assets/airlines/PH/PH_icon@3x.png";
import airlineIconPH4x from "../../assets/airlines/PH/PH_icon@4x.png";
import airlineIconQ82x from "../../assets/airlines/Q8/Q8_icon@2x.png";
import airlineIconQ83x from "../../assets/airlines/Q8/Q8_icon@3x.png";
import airlineIconQ84x from "../../assets/airlines/Q8/Q8_icon@4x.png";
import airlineIconQX2x from "../../assets/airlines/QX/QX_icon@2x.png";
import airlineIconQX3x from "../../assets/airlines/QX/QX_icon@3x.png";
import airlineIconQX4x from "../../assets/airlines/QX/QX_icon@4x.png";
import airlineIconRR2x from "../../assets/airlines/RR/RR_icon@2x.png";
import airlineIconRR3x from "../../assets/airlines/RR/RR_icon@3x.png";
import airlineIconRR4x from "../../assets/airlines/RR/RR_icon@4x.png";
import airlineIconSG2x from "../../assets/airlines/SG/SG_icon@2x.png";
import airlineIconSG3x from "../../assets/airlines/SG/SG_icon@3x.png";
import airlineIconSG4x from "../../assets/airlines/SG/SG_icon@4x.png";
import airlineIconT62x from "../../assets/airlines/T6/T6_icon@2x.png";
import airlineIconT63x from "../../assets/airlines/T6/T6_icon@3x.png";
import airlineIconT64x from "../../assets/airlines/T6/T6_icon@4x.png";
import airlineIconTW2x from "../../assets/airlines/TW/TW_icon@2x.png";
import airlineIconTW3x from "../../assets/airlines/TW/TW_icon@3x.png";
import airlineIconTW4x from "../../assets/airlines/TW/TW_icon@4x.png";
import airlineIconUP2x from "../../assets/airlines/UP/UP_icon@2x.png";
import airlineIconUP3x from "../../assets/airlines/UP/UP_icon@3x.png";
import airlineIconUP4x from "../../assets/airlines/UP/UP_icon@4x.png";
import airlineIconVL2x from "../../assets/airlines/VL/VL_icon@2x.png";
import airlineIconVL3x from "../../assets/airlines/VL/VL_icon@3x.png";
import airlineIconVL4x from "../../assets/airlines/VL/VL_icon@4x.png";
import airlineIconWB2x from "../../assets/airlines/WB/WB_icon@2x.png";
import airlineIconWB3x from "../../assets/airlines/WB/WB_icon@3x.png";
import airlineIconWB4x from "../../assets/airlines/WB/WB_icon@4x.png";
import airlineIconX32x from "../../assets/airlines/X3/X3_icon@2x.png";
import airlineIconX33x from "../../assets/airlines/X3/X3_icon@3x.png";
import airlineIconX34x from "../../assets/airlines/X3/X3_icon@4x.png";
import airlineIconY72x from "../../assets/airlines/Y7/Y7_icon@2x.png";
import airlineIconY73x from "../../assets/airlines/Y7/Y7_icon@3x.png";
import airlineIconY74x from "../../assets/airlines/Y7/Y7_icon@4x.png";
import airlineIconZ22x from "../../assets/airlines/Z2/Z2_icon@2x.png";
import airlineIconZ23x from "../../assets/airlines/Z2/Z2_icon@3x.png";
import airlineIconZ24x from "../../assets/airlines/Z2/Z2_icon@4x.png";
import airlineIcon2K2x from "../../assets/airlines/2K/2K_icon@2x.png";
import airlineIcon2K3x from "../../assets/airlines/2K/2K_icon@3x.png";
import airlineIcon2K4x from "../../assets/airlines/2K/2K_icon@4x.png";
import airlineIcon3L2x from "../../assets/airlines/3L/3L_icon@2x.png";
import airlineIcon3L3x from "../../assets/airlines/3L/3L_icon@3x.png";
import airlineIcon3L4x from "../../assets/airlines/3L/3L_icon@4x.png";
import airlineIcon4L2x from "../../assets/airlines/4L/4L_icon@2x.png";
import airlineIcon4L3x from "../../assets/airlines/4L/4L_icon@3x.png";
import airlineIcon4L4x from "../../assets/airlines/4L/4L_icon@4x.png";
import airlineIcon5L2x from "../../assets/airlines/5L/5L_icon@2x.png";
import airlineIcon5L3x from "../../assets/airlines/5L/5L_icon@3x.png";
import airlineIcon5L4x from "../../assets/airlines/5L/5L_icon@4x.png";
import airlineIcon6N2x from "../../assets/airlines/6N/6N_icon@2x.png";
import airlineIcon6N3x from "../../assets/airlines/6N/6N_icon@3x.png";
import airlineIcon6N4x from "../../assets/airlines/6N/6N_icon@4x.png";
import airlineIcon7R2x from "../../assets/airlines/7R/7R_icon@2x.png";
import airlineIcon7R3x from "../../assets/airlines/7R/7R_icon@3x.png";
import airlineIcon7R4x from "../../assets/airlines/7R/7R_icon@4x.png";
import airlineIcon8S2x from "../../assets/airlines/8S/8S_icon@2x.png";
import airlineIcon8S3x from "../../assets/airlines/8S/8S_icon@3x.png";
import airlineIcon8S4x from "../../assets/airlines/8S/8S_icon@4x.png";
import airlineIcon9T2x from "../../assets/airlines/9T/9T_icon@2x.png";
import airlineIcon9T3x from "../../assets/airlines/9T/9T_icon@3x.png";
import airlineIcon9T4x from "../../assets/airlines/9T/9T_icon@4x.png";
import airlineIconAJ2x from "../../assets/airlines/AJ/AJ_icon@2x.png";
import airlineIconAJ3x from "../../assets/airlines/AJ/AJ_icon@3x.png";
import airlineIconAJ4x from "../../assets/airlines/AJ/AJ_icon@4x.png";
import airlineIconBA2x from "../../assets/airlines/BA/BA_icon@2x.png";
import airlineIconBA3x from "../../assets/airlines/BA/BA_icon@3x.png";
import airlineIconBA4x from "../../assets/airlines/BA/BA_icon@4x.png";
import airlineIconC22x from "../../assets/airlines/C2/C2_icon@2x.png";
import airlineIconC23x from "../../assets/airlines/C2/C2_icon@3x.png";
import airlineIconC24x from "../../assets/airlines/C2/C2_icon@4x.png";
import airlineIconCX2x from "../../assets/airlines/CX/CX_icon@2x.png";
import airlineIconCX3x from "../../assets/airlines/CX/CX_icon@3x.png";
import airlineIconCX4x from "../../assets/airlines/CX/CX_icon@4x.png";
import airlineIconDS2x from "../../assets/airlines/DS/DS_icon@2x.png";
import airlineIconDS3x from "../../assets/airlines/DS/DS_icon@3x.png";
import airlineIconDS4x from "../../assets/airlines/DS/DS_icon@4x.png";
import airlineIconEO2x from "../../assets/airlines/EO/EO_icon@2x.png";
import airlineIconEO3x from "../../assets/airlines/EO/EO_icon@3x.png";
import airlineIconEO4x from "../../assets/airlines/EO/EO_icon@4x.png";
import airlineIconFC2x from "../../assets/airlines/FC/FC_icon@2x.png";
import airlineIconFC3x from "../../assets/airlines/FC/FC_icon@3x.png";
import airlineIconFC4x from "../../assets/airlines/FC/FC_icon@4x.png";
import airlineIconG32x from "../../assets/airlines/G3/G3_icon@2x.png";
import airlineIconG33x from "../../assets/airlines/G3/G3_icon@3x.png";
import airlineIconG34x from "../../assets/airlines/G3/G3_icon@4x.png";
import airlineIconGX2x from "../../assets/airlines/GX/GX_icon@2x.png";
import airlineIconGX3x from "../../assets/airlines/GX/GX_icon@3x.png";
import airlineIconGX4x from "../../assets/airlines/GX/GX_icon@4x.png";
import airlineIconHT2x from "../../assets/airlines/HT/HT_icon@2x.png";
import airlineIconHT3x from "../../assets/airlines/HT/HT_icon@3x.png";
import airlineIconHT4x from "../../assets/airlines/HT/HT_icon@4x.png";
import airlineIconIM2x from "../../assets/airlines/IM/IM_icon@2x.png";
import airlineIconIM3x from "../../assets/airlines/IM/IM_icon@3x.png";
import airlineIconIM4x from "../../assets/airlines/IM/IM_icon@4x.png";
import airlineIconJC2x from "../../assets/airlines/JC/JC_icon@2x.png";
import airlineIconJC3x from "../../assets/airlines/JC/JC_icon@3x.png";
import airlineIconJC4x from "../../assets/airlines/JC/JC_icon@4x.png";
import airlineIconK52x from "../../assets/airlines/K5/K5_icon@2x.png";
import airlineIconK53x from "../../assets/airlines/K5/K5_icon@3x.png";
import airlineIconK54x from "../../assets/airlines/K5/K5_icon@4x.png";
import airlineIconKU2x from "../../assets/airlines/KU/KU_icon@2x.png";
import airlineIconKU3x from "../../assets/airlines/KU/KU_icon@3x.png";
import airlineIconKU4x from "../../assets/airlines/KU/KU_icon@4x.png";
import airlineIconLO2x from "../../assets/airlines/LO/LO_icon@2x.png";
import airlineIconLO3x from "../../assets/airlines/LO/LO_icon@3x.png";
import airlineIconLO4x from "../../assets/airlines/LO/LO_icon@4x.png";
import airlineIconMF2x from "../../assets/airlines/MF/MF_icon@2x.png";
import airlineIconMF3x from "../../assets/airlines/MF/MF_icon@3x.png";
import airlineIconMF4x from "../../assets/airlines/MF/MF_icon@4x.png";
import airlineIconN32x from "../../assets/airlines/N3/N3_icon@2x.png";
import airlineIconN33x from "../../assets/airlines/N3/N3_icon@3x.png";
import airlineIconN34x from "../../assets/airlines/N3/N3_icon@4x.png";
import airlineIconNX2x from "../../assets/airlines/NX/NX_icon@2x.png";
import airlineIconNX3x from "../../assets/airlines/NX/NX_icon@3x.png";
import airlineIconNX4x from "../../assets/airlines/NX/NX_icon@4x.png";
import airlineIconOT2x from "../../assets/airlines/OT/OT_icon@2x.png";
import airlineIconOT3x from "../../assets/airlines/OT/OT_icon@3x.png";
import airlineIconOT4x from "../../assets/airlines/OT/OT_icon@4x.png";
import airlineIconPI2x from "../../assets/airlines/PI/PI_icon@2x.png";
import airlineIconPI3x from "../../assets/airlines/PI/PI_icon@3x.png";
import airlineIconPI4x from "../../assets/airlines/PI/PI_icon@4x.png";
import airlineIconQ92x from "../../assets/airlines/Q9/Q9_icon@2x.png";
import airlineIconQ93x from "../../assets/airlines/Q9/Q9_icon@3x.png";
import airlineIconQ94x from "../../assets/airlines/Q9/Q9_icon@4x.png";
import airlineIconQY2x from "../../assets/airlines/QY/QY_icon@2x.png";
import airlineIconQY3x from "../../assets/airlines/QY/QY_icon@3x.png";
import airlineIconQY4x from "../../assets/airlines/QY/QY_icon@4x.png";
import airlineIconRS2x from "../../assets/airlines/RS/RS_icon@2x.png";
import airlineIconRS3x from "../../assets/airlines/RS/RS_icon@3x.png";
import airlineIconRS4x from "../../assets/airlines/RS/RS_icon@4x.png";
import airlineIconSH2x from "../../assets/airlines/SH/SH_icon@2x.png";
import airlineIconSH3x from "../../assets/airlines/SH/SH_icon@3x.png";
import airlineIconSH4x from "../../assets/airlines/SH/SH_icon@4x.png";
import airlineIconT72x from "../../assets/airlines/T7/T7_icon@2x.png";
import airlineIconT73x from "../../assets/airlines/T7/T7_icon@3x.png";
import airlineIconT74x from "../../assets/airlines/T7/T7_icon@4x.png";
import airlineIconTX2x from "../../assets/airlines/TX/TX_icon@2x.png";
import airlineIconTX3x from "../../assets/airlines/TX/TX_icon@3x.png";
import airlineIconTX4x from "../../assets/airlines/TX/TX_icon@4x.png";
import airlineIconUQ2x from "../../assets/airlines/UQ/UQ_icon@2x.png";
import airlineIconUQ3x from "../../assets/airlines/UQ/UQ_icon@3x.png";
import airlineIconUQ4x from "../../assets/airlines/UQ/UQ_icon@4x.png";
import airlineIconVM2x from "../../assets/airlines/VM/VM_icon@2x.png";
import airlineIconVM3x from "../../assets/airlines/VM/VM_icon@3x.png";
import airlineIconVM4x from "../../assets/airlines/VM/VM_icon@4x.png";
import airlineIconWC2x from "../../assets/airlines/WC/WC_icon@2x.png";
import airlineIconWC3x from "../../assets/airlines/WC/WC_icon@3x.png";
import airlineIconWC4x from "../../assets/airlines/WC/WC_icon@4x.png";
import airlineIconX42x from "../../assets/airlines/X4/X4_icon@2x.png";
import airlineIconX43x from "../../assets/airlines/X4/X4_icon@3x.png";
import airlineIconX44x from "../../assets/airlines/X4/X4_icon@4x.png";
import airlineIconY82x from "../../assets/airlines/Y8/Y8_icon@2x.png";
import airlineIconY83x from "../../assets/airlines/Y8/Y8_icon@3x.png";
import airlineIconY84x from "../../assets/airlines/Y8/Y8_icon@4x.png";
import airlineIconZ52x from "../../assets/airlines/Z5/Z5_icon@2x.png";
import airlineIconZ53x from "../../assets/airlines/Z5/Z5_icon@3x.png";
import airlineIconZ54x from "../../assets/airlines/Z5/Z5_icon@4x.png";
import airlineIcon2L2x from "../../assets/airlines/2L/2L_icon@2x.png";
import airlineIcon2L3x from "../../assets/airlines/2L/2L_icon@3x.png";
import airlineIcon2L4x from "../../assets/airlines/2L/2L_icon@4x.png";
import airlineIcon3M2x from "../../assets/airlines/3M/3M_icon@2x.png";
import airlineIcon3M3x from "../../assets/airlines/3M/3M_icon@3x.png";
import airlineIcon3M4x from "../../assets/airlines/3M/3M_icon@4x.png";
import airlineIcon4M2x from "../../assets/airlines/4M/4M_icon@2x.png";
import airlineIcon4M3x from "../../assets/airlines/4M/4M_icon@3x.png";
import airlineIcon4M4x from "../../assets/airlines/4M/4M_icon@4x.png";
import airlineIcon5M2x from "../../assets/airlines/5M/5M_icon@2x.png";
import airlineIcon5M3x from "../../assets/airlines/5M/5M_icon@3x.png";
import airlineIcon5M4x from "../../assets/airlines/5M/5M_icon@4x.png";
import airlineIcon6O2x from "../../assets/airlines/6O/6O_icon@2x.png";
import airlineIcon6O3x from "../../assets/airlines/6O/6O_icon@3x.png";
import airlineIcon6O4x from "../../assets/airlines/6O/6O_icon@4x.png";
import airlineIcon7S2x from "../../assets/airlines/7S/7S_icon@2x.png";
import airlineIcon7S3x from "../../assets/airlines/7S/7S_icon@3x.png";
import airlineIcon7S4x from "../../assets/airlines/7S/7S_icon@4x.png";
import airlineIcon8T2x from "../../assets/airlines/8T/8T_icon@2x.png";
import airlineIcon8T3x from "../../assets/airlines/8T/8T_icon@3x.png";
import airlineIcon8T4x from "../../assets/airlines/8T/8T_icon@4x.png";
import airlineIcon9U2x from "../../assets/airlines/9U/9U_icon@2x.png";
import airlineIcon9U3x from "../../assets/airlines/9U/9U_icon@3x.png";
import airlineIcon9U4x from "../../assets/airlines/9U/9U_icon@4x.png";
import airlineIconAK2x from "../../assets/airlines/AK/AK_icon@2x.png";
import airlineIconAK3x from "../../assets/airlines/AK/AK_icon@3x.png";
import airlineIconAK4x from "../../assets/airlines/AK/AK_icon@4x.png";
import airlineIconBB2x from "../../assets/airlines/BB/BB_icon@2x.png";
import airlineIconBB3x from "../../assets/airlines/BB/BB_icon@3x.png";
import airlineIconBB4x from "../../assets/airlines/BB/BB_icon@4x.png";
import airlineIconC32x from "../../assets/airlines/C3/C3_icon@2x.png";
import airlineIconC33x from "../../assets/airlines/C3/C3_icon@3x.png";
import airlineIconC34x from "../../assets/airlines/C3/C3_icon@4x.png";
import airlineIconCY2x from "../../assets/airlines/CY/CY_icon@2x.png";
import airlineIconCY3x from "../../assets/airlines/CY/CY_icon@3x.png";
import airlineIconCY4x from "../../assets/airlines/CY/CY_icon@4x.png";
import airlineIconDT2x from "../../assets/airlines/DT/DT_icon@2x.png";
import airlineIconDT3x from "../../assets/airlines/DT/DT_icon@3x.png";
import airlineIconDT4x from "../../assets/airlines/DT/DT_icon@4x.png";
import airlineIconEP2x from "../../assets/airlines/EP/EP_icon@2x.png";
import airlineIconEP3x from "../../assets/airlines/EP/EP_icon@3x.png";
import airlineIconEP4x from "../../assets/airlines/EP/EP_icon@4x.png";
import airlineIconFD2x from "../../assets/airlines/FD/FD_icon@2x.png";
import airlineIconFD3x from "../../assets/airlines/FD/FD_icon@3x.png";
import airlineIconFD4x from "../../assets/airlines/FD/FD_icon@4x.png";
import airlineIconG42x from "../../assets/airlines/G4/G4_icon@2x.png";
import airlineIconG43x from "../../assets/airlines/G4/G4_icon@3x.png";
import airlineIconG44x from "../../assets/airlines/G4/G4_icon@4x.png";
import airlineIconGY2x from "../../assets/airlines/GY/GY_icon@2x.png";
import airlineIconGY3x from "../../assets/airlines/GY/GY_icon@3x.png";
import airlineIconGY4x from "../../assets/airlines/GY/GY_icon@4x.png";
import airlineIconHU2x from "../../assets/airlines/HU/HU_icon@2x.png";
import airlineIconHU3x from "../../assets/airlines/HU/HU_icon@3x.png";
import airlineIconHU4x from "../../assets/airlines/HU/HU_icon@4x.png";
import airlineIconIN2x from "../../assets/airlines/IN/IN_icon@2x.png";
import airlineIconIN3x from "../../assets/airlines/IN/IN_icon@3x.png";
import airlineIconIN4x from "../../assets/airlines/IN/IN_icon@4x.png";
import airlineIconJD2x from "../../assets/airlines/JD/JD_icon@2x.png";
import airlineIconJD3x from "../../assets/airlines/JD/JD_icon@3x.png";
import airlineIconJD4x from "../../assets/airlines/JD/JD_icon@4x.png";
import airlineIconK62x from "../../assets/airlines/K6/K6_icon@2x.png";
import airlineIconK63x from "../../assets/airlines/K6/K6_icon@3x.png";
import airlineIconK64x from "../../assets/airlines/K6/K6_icon@4x.png";
import airlineIconKW2x from "../../assets/airlines/KW/KW_icon@2x.png";
import airlineIconKW3x from "../../assets/airlines/KW/KW_icon@3x.png";
import airlineIconKW4x from "../../assets/airlines/KW/KW_icon@4x.png";
import airlineIconLP2x from "../../assets/airlines/LP/LP_icon@2x.png";
import airlineIconLP3x from "../../assets/airlines/LP/LP_icon@3x.png";
import airlineIconLP4x from "../../assets/airlines/LP/LP_icon@4x.png";
import airlineIconMG2x from "../../assets/airlines/MG/MG_icon@2x.png";
import airlineIconMG3x from "../../assets/airlines/MG/MG_icon@3x.png";
import airlineIconMG4x from "../../assets/airlines/MG/MG_icon@4x.png";
import airlineIconN42x from "../../assets/airlines/N4/N4_icon@2x.png";
import airlineIconN43x from "../../assets/airlines/N4/N4_icon@3x.png";
import airlineIconN44x from "../../assets/airlines/N4/N4_icon@4x.png";
import airlineIconNY2x from "../../assets/airlines/NY/NY_icon@2x.png";
import airlineIconNY3x from "../../assets/airlines/NY/NY_icon@3x.png";
import airlineIconNY4x from "../../assets/airlines/NY/NY_icon@4x.png";
import airlineIconOU2x from "../../assets/airlines/OU/OU_icon@2x.png";
import airlineIconOU3x from "../../assets/airlines/OU/OU_icon@3x.png";
import airlineIconOU4x from "../../assets/airlines/OU/OU_icon@4x.png";
import airlineIconPJ2x from "../../assets/airlines/PJ/PJ_icon@2x.png";
import airlineIconPJ3x from "../../assets/airlines/PJ/PJ_icon@3x.png";
import airlineIconPJ4x from "../../assets/airlines/PJ/PJ_icon@4x.png";
import airlineIconQB2x from "../../assets/airlines/QB/QB_icon@2x.png";
import airlineIconQB3x from "../../assets/airlines/QB/QB_icon@3x.png";
import airlineIconQB4x from "../../assets/airlines/QB/QB_icon@4x.png";
import airlineIconQZ2x from "../../assets/airlines/QZ/QZ_icon@2x.png";
import airlineIconQZ3x from "../../assets/airlines/QZ/QZ_icon@3x.png";
import airlineIconQZ4x from "../../assets/airlines/QZ/QZ_icon@4x.png";
import airlineIconRT2x from "../../assets/airlines/RT/RT_icon@2x.png";
import airlineIconRT3x from "../../assets/airlines/RT/RT_icon@3x.png";
import airlineIconRT4x from "../../assets/airlines/RT/RT_icon@4x.png";
import airlineIconSI2x from "../../assets/airlines/SI/SI_icon@2x.png";
import airlineIconSI3x from "../../assets/airlines/SI/SI_icon@3x.png";
import airlineIconSI4x from "../../assets/airlines/SI/SI_icon@4x.png";
import airlineIconT92x from "../../assets/airlines/T9/T9_icon@2x.png";
import airlineIconT93x from "../../assets/airlines/T9/T9_icon@3x.png";
import airlineIconT94x from "../../assets/airlines/T9/T9_icon@4x.png";
import airlineIconTY2x from "../../assets/airlines/TY/TY_icon@2x.png";
import airlineIconTY3x from "../../assets/airlines/TY/TY_icon@3x.png";
import airlineIconTY4x from "../../assets/airlines/TY/TY_icon@4x.png";
import airlineIconUR2x from "../../assets/airlines/UR/UR_icon@2x.png";
import airlineIconUR3x from "../../assets/airlines/UR/UR_icon@3x.png";
import airlineIconUR4x from "../../assets/airlines/UR/UR_icon@4x.png";
import airlineIconVN2x from "../../assets/airlines/VN/VN_icon@2x.png";
import airlineIconVN3x from "../../assets/airlines/VN/VN_icon@3x.png";
import airlineIconVN4x from "../../assets/airlines/VN/VN_icon@4x.png";
import airlineIconWD2x from "../../assets/airlines/WD/WD_icon@2x.png";
import airlineIconWD3x from "../../assets/airlines/WD/WD_icon@3x.png";
import airlineIconWD4x from "../../assets/airlines/WD/WD_icon@4x.png";
import airlineIconX52x from "../../assets/airlines/X5/X5_icon@2x.png";
import airlineIconX53x from "../../assets/airlines/X5/X5_icon@3x.png";
import airlineIconX54x from "../../assets/airlines/X5/X5_icon@4x.png";
import airlineIconY92x from "../../assets/airlines/Y9/Y9_icon@2x.png";
import airlineIconY93x from "../../assets/airlines/Y9/Y9_icon@3x.png";
import airlineIconY94x from "../../assets/airlines/Y9/Y9_icon@4x.png";
import airlineIconZ82x from "../../assets/airlines/Z8/Z8_icon@2x.png";
import airlineIconZ83x from "../../assets/airlines/Z8/Z8_icon@3x.png";
import airlineIconZ84x from "../../assets/airlines/Z8/Z8_icon@4x.png";
import airlineIcon2M2x from "../../assets/airlines/2M/2M_icon@2x.png";
import airlineIcon2M3x from "../../assets/airlines/2M/2M_icon@3x.png";
import airlineIcon2M4x from "../../assets/airlines/2M/2M_icon@4x.png";
import airlineIcon3N2x from "../../assets/airlines/3N/3N_icon@2x.png";
import airlineIcon3N3x from "../../assets/airlines/3N/3N_icon@3x.png";
import airlineIcon3N4x from "../../assets/airlines/3N/3N_icon@4x.png";
import airlineIcon4N2x from "../../assets/airlines/4N/4N_icon@2x.png";
import airlineIcon4N3x from "../../assets/airlines/4N/4N_icon@3x.png";
import airlineIcon4N4x from "../../assets/airlines/4N/4N_icon@4x.png";
import airlineIcon5N2x from "../../assets/airlines/5N/5N_icon@2x.png";
import airlineIcon5N3x from "../../assets/airlines/5N/5N_icon@3x.png";
import airlineIcon5N4x from "../../assets/airlines/5N/5N_icon@4x.png";
import airlineIcon6Q2x from "../../assets/airlines/6Q/6Q_icon@2x.png";
import airlineIcon6Q3x from "../../assets/airlines/6Q/6Q_icon@3x.png";
import airlineIcon6Q4x from "../../assets/airlines/6Q/6Q_icon@4x.png";
import airlineIcon7T2x from "../../assets/airlines/7T/7T_icon@2x.png";
import airlineIcon7T3x from "../../assets/airlines/7T/7T_icon@3x.png";
import airlineIcon7T4x from "../../assets/airlines/7T/7T_icon@4x.png";
import airlineIcon8U2x from "../../assets/airlines/8U/8U_icon@2x.png";
import airlineIcon8U3x from "../../assets/airlines/8U/8U_icon@3x.png";
import airlineIcon8U4x from "../../assets/airlines/8U/8U_icon@4x.png";
import airlineIcon9V2x from "../../assets/airlines/9V/9V_icon@2x.png";
import airlineIcon9V3x from "../../assets/airlines/9V/9V_icon@3x.png";
import airlineIcon9V4x from "../../assets/airlines/9V/9V_icon@4x.png";
import airlineIconAL2x from "../../assets/airlines/AL/AL_icon@2x.png";
import airlineIconAL3x from "../../assets/airlines/AL/AL_icon@3x.png";
import airlineIconAL4x from "../../assets/airlines/AL/AL_icon@4x.png";
import airlineIconBC2x from "../../assets/airlines/BC/BC_icon@2x.png";
import airlineIconBC3x from "../../assets/airlines/BC/BC_icon@3x.png";
import airlineIconBC4x from "../../assets/airlines/BC/BC_icon@4x.png";
import airlineIconC42x from "../../assets/airlines/C4/C4_icon@2x.png";
import airlineIconC43x from "../../assets/airlines/C4/C4_icon@3x.png";
import airlineIconC44x from "../../assets/airlines/C4/C4_icon@4x.png";
import airlineIconCZ2x from "../../assets/airlines/CZ/CZ_icon@2x.png";
import airlineIconCZ3x from "../../assets/airlines/CZ/CZ_icon@3x.png";
import airlineIconCZ4x from "../../assets/airlines/CZ/CZ_icon@4x.png";
import airlineIconDV2x from "../../assets/airlines/DV/DV_icon@2x.png";
import airlineIconDV3x from "../../assets/airlines/DV/DV_icon@3x.png";
import airlineIconDV4x from "../../assets/airlines/DV/DV_icon@4x.png";
import airlineIconEQ2x from "../../assets/airlines/EQ/EQ_icon@2x.png";
import airlineIconEQ3x from "../../assets/airlines/EQ/EQ_icon@3x.png";
import airlineIconEQ4x from "../../assets/airlines/EQ/EQ_icon@4x.png";
import airlineIconFE2x from "../../assets/airlines/FE/FE_icon@2x.png";
import airlineIconFE3x from "../../assets/airlines/FE/FE_icon@3x.png";
import airlineIconFE4x from "../../assets/airlines/FE/FE_icon@4x.png";
import airlineIconG52x from "../../assets/airlines/G5/G5_icon@2x.png";
import airlineIconG53x from "../../assets/airlines/G5/G5_icon@3x.png";
import airlineIconG54x from "../../assets/airlines/G5/G5_icon@4x.png";
import airlineIconGZ2x from "../../assets/airlines/GZ/GZ_icon@2x.png";
import airlineIconGZ3x from "../../assets/airlines/GZ/GZ_icon@3x.png";
import airlineIconGZ4x from "../../assets/airlines/GZ/GZ_icon@4x.png";
import airlineIconHV2x from "../../assets/airlines/HV/HV_icon@2x.png";
import airlineIconHV3x from "../../assets/airlines/HV/HV_icon@3x.png";
import airlineIconHV4x from "../../assets/airlines/HV/HV_icon@4x.png";
import airlineIconIO2x from "../../assets/airlines/IO/IO_icon@2x.png";
import airlineIconIO3x from "../../assets/airlines/IO/IO_icon@3x.png";
import airlineIconIO4x from "../../assets/airlines/IO/IO_icon@4x.png";
import airlineIconJE2x from "../../assets/airlines/JE/JE_icon@2x.png";
import airlineIconJE3x from "../../assets/airlines/JE/JE_icon@3x.png";
import airlineIconJE4x from "../../assets/airlines/JE/JE_icon@4x.png";
import airlineIconK72x from "../../assets/airlines/K7/K7_icon@2x.png";
import airlineIconK73x from "../../assets/airlines/K7/K7_icon@3x.png";
import airlineIconK74x from "../../assets/airlines/K7/K7_icon@4x.png";
import airlineIconKX2x from "../../assets/airlines/KX/KX_icon@2x.png";
import airlineIconKX3x from "../../assets/airlines/KX/KX_icon@3x.png";
import airlineIconKX4x from "../../assets/airlines/KX/KX_icon@4x.png";
import airlineIconLQ2x from "../../assets/airlines/LQ/LQ_icon@2x.png";
import airlineIconLQ3x from "../../assets/airlines/LQ/LQ_icon@3x.png";
import airlineIconLQ4x from "../../assets/airlines/LQ/LQ_icon@4x.png";
import airlineIconMH2x from "../../assets/airlines/MH/MH_icon@2x.png";
import airlineIconMH3x from "../../assets/airlines/MH/MH_icon@3x.png";
import airlineIconMH4x from "../../assets/airlines/MH/MH_icon@4x.png";
import airlineIconN52x from "../../assets/airlines/N5/N5_icon@2x.png";
import airlineIconN53x from "../../assets/airlines/N5/N5_icon@3x.png";
import airlineIconN54x from "../../assets/airlines/N5/N5_icon@4x.png";
import airlineIconNZ2x from "../../assets/airlines/NZ/NZ_icon@2x.png";
import airlineIconNZ3x from "../../assets/airlines/NZ/NZ_icon@3x.png";
import airlineIconNZ4x from "../../assets/airlines/NZ/NZ_icon@4x.png";
import airlineIconOV2x from "../../assets/airlines/OV/OV_icon@2x.png";
import airlineIconOV3x from "../../assets/airlines/OV/OV_icon@3x.png";
import airlineIconOV4x from "../../assets/airlines/OV/OV_icon@4x.png";
import airlineIconPK2x from "../../assets/airlines/PK/PK_icon@2x.png";
import airlineIconPK3x from "../../assets/airlines/PK/PK_icon@3x.png";
import airlineIconPK4x from "../../assets/airlines/PK/PK_icon@4x.png";
import airlineIconQC2x from "../../assets/airlines/QC/QC_icon@2x.png";
import airlineIconQC3x from "../../assets/airlines/QC/QC_icon@3x.png";
import airlineIconQC4x from "../../assets/airlines/QC/QC_icon@4x.png";
import airlineIconR22x from "../../assets/airlines/R2/R2_icon@2x.png";
import airlineIconR23x from "../../assets/airlines/R2/R2_icon@3x.png";
import airlineIconR24x from "../../assets/airlines/R2/R2_icon@4x.png";
import airlineIconRU2x from "../../assets/airlines/RU/RU_icon@2x.png";
import airlineIconRU3x from "../../assets/airlines/RU/RU_icon@3x.png";
import airlineIconRU4x from "../../assets/airlines/RU/RU_icon@4x.png";
import airlineIconSJ2x from "../../assets/airlines/SJ/SJ_icon@2x.png";
import airlineIconSJ3x from "../../assets/airlines/SJ/SJ_icon@3x.png";
import airlineIconSJ4x from "../../assets/airlines/SJ/SJ_icon@4x.png";
import airlineIconTA2x from "../../assets/airlines/TA/TA_icon@2x.png";
import airlineIconTA3x from "../../assets/airlines/TA/TA_icon@3x.png";
import airlineIconTA4x from "../../assets/airlines/TA/TA_icon@4x.png";
import airlineIconTZ2x from "../../assets/airlines/TZ/TZ_icon@2x.png";
import airlineIconTZ3x from "../../assets/airlines/TZ/TZ_icon@3x.png";
import airlineIconTZ4x from "../../assets/airlines/TZ/TZ_icon@4x.png";
import airlineIconUS2x from "../../assets/airlines/US/US_icon@2x.png";
import airlineIconUS3x from "../../assets/airlines/US/US_icon@3x.png";
import airlineIconUS4x from "../../assets/airlines/US/US_icon@4x.png";
import airlineIconVO2x from "../../assets/airlines/VO/VO_icon@2x.png";
import airlineIconVO3x from "../../assets/airlines/VO/VO_icon@3x.png";
import airlineIconVO4x from "../../assets/airlines/VO/VO_icon@4x.png";
import airlineIconWE2x from "../../assets/airlines/WE/WE_icon@2x.png";
import airlineIconWE3x from "../../assets/airlines/WE/WE_icon@3x.png";
import airlineIconWE4x from "../../assets/airlines/WE/WE_icon@4x.png";
import airlineIconX72x from "../../assets/airlines/X7/X7_icon@2x.png";
import airlineIconX73x from "../../assets/airlines/X7/X7_icon@3x.png";
import airlineIconX74x from "../../assets/airlines/X7/X7_icon@4x.png";
import airlineIconYB2x from "../../assets/airlines/YB/YB_icon@2x.png";
import airlineIconYB3x from "../../assets/airlines/YB/YB_icon@3x.png";
import airlineIconYB4x from "../../assets/airlines/YB/YB_icon@4x.png";
import airlineIconZ92x from "../../assets/airlines/Z9/Z9_icon@2x.png";
import airlineIconZ93x from "../../assets/airlines/Z9/Z9_icon@3x.png";
import airlineIconZ94x from "../../assets/airlines/Z9/Z9_icon@4x.png";
import airlineIcon2N2x from "../../assets/airlines/2N/2N_icon@2x.png";
import airlineIcon2N3x from "../../assets/airlines/2N/2N_icon@3x.png";
import airlineIcon2N4x from "../../assets/airlines/2N/2N_icon@4x.png";
import airlineIcon3O2x from "../../assets/airlines/3O/3O_icon@2x.png";
import airlineIcon3O3x from "../../assets/airlines/3O/3O_icon@3x.png";
import airlineIcon3O4x from "../../assets/airlines/3O/3O_icon@4x.png";
import airlineIcon4Q2x from "../../assets/airlines/4Q/4Q_icon@2x.png";
import airlineIcon4Q3x from "../../assets/airlines/4Q/4Q_icon@3x.png";
import airlineIcon4Q4x from "../../assets/airlines/4Q/4Q_icon@4x.png";
import airlineIcon5O2x from "../../assets/airlines/5O/5O_icon@2x.png";
import airlineIcon5O3x from "../../assets/airlines/5O/5O_icon@3x.png";
import airlineIcon5O4x from "../../assets/airlines/5O/5O_icon@4x.png";
import airlineIcon6R2x from "../../assets/airlines/6R/6R_icon@2x.png";
import airlineIcon6R3x from "../../assets/airlines/6R/6R_icon@3x.png";
import airlineIcon6R4x from "../../assets/airlines/6R/6R_icon@4x.png";
import airlineIcon7U2x from "../../assets/airlines/7U/7U_icon@2x.png";
import airlineIcon7U3x from "../../assets/airlines/7U/7U_icon@3x.png";
import airlineIcon7U4x from "../../assets/airlines/7U/7U_icon@4x.png";
import airlineIcon8V2x from "../../assets/airlines/8V/8V_icon@2x.png";
import airlineIcon8V3x from "../../assets/airlines/8V/8V_icon@3x.png";
import airlineIcon8V4x from "../../assets/airlines/8V/8V_icon@4x.png";
import airlineIcon9W2x from "../../assets/airlines/9W/9W_icon@2x.png";
import airlineIcon9W3x from "../../assets/airlines/9W/9W_icon@3x.png";
import airlineIcon9W4x from "../../assets/airlines/9W/9W_icon@4x.png";
import airlineIconAM2x from "../../assets/airlines/AM/AM_icon@2x.png";
import airlineIconAM3x from "../../assets/airlines/AM/AM_icon@3x.png";
import airlineIconAM4x from "../../assets/airlines/AM/AM_icon@4x.png";
import airlineIconBD2x from "../../assets/airlines/BD/BD_icon@2x.png";
import airlineIconBD3x from "../../assets/airlines/BD/BD_icon@3x.png";
import airlineIconBD4x from "../../assets/airlines/BD/BD_icon@4x.png";
import airlineIconC52x from "../../assets/airlines/C5/C5_icon@2x.png";
import airlineIconC53x from "../../assets/airlines/C5/C5_icon@3x.png";
import airlineIconC54x from "../../assets/airlines/C5/C5_icon@4x.png";
import airlineIconD02x from "../../assets/airlines/D0/D0_icon@2x.png";
import airlineIconD03x from "../../assets/airlines/D0/D0_icon@3x.png";
import airlineIconD04x from "../../assets/airlines/D0/D0_icon@4x.png";
import airlineIconDX2x from "../../assets/airlines/DX/DX_icon@2x.png";
import airlineIconDX3x from "../../assets/airlines/DX/DX_icon@3x.png";
import airlineIconDX4x from "../../assets/airlines/DX/DX_icon@4x.png";
import airlineIconER2x from "../../assets/airlines/ER/ER_icon@2x.png";
import airlineIconER3x from "../../assets/airlines/ER/ER_icon@3x.png";
import airlineIconER4x from "../../assets/airlines/ER/ER_icon@4x.png";
import airlineIconFG2x from "../../assets/airlines/FG/FG_icon@2x.png";
import airlineIconFG3x from "../../assets/airlines/FG/FG_icon@3x.png";
import airlineIconFG4x from "../../assets/airlines/FG/FG_icon@4x.png";
import airlineIconG72x from "../../assets/airlines/G7/G7_icon@2x.png";
import airlineIconG73x from "../../assets/airlines/G7/G7_icon@3x.png";
import airlineIconG74x from "../../assets/airlines/G7/G7_icon@4x.png";
import airlineIconH12x from "../../assets/airlines/H1/H1_icon@2x.png";
import airlineIconH13x from "../../assets/airlines/H1/H1_icon@3x.png";
import airlineIconH14x from "../../assets/airlines/H1/H1_icon@4x.png";
import airlineIconHW2x from "../../assets/airlines/HW/HW_icon@2x.png";
import airlineIconHW3x from "../../assets/airlines/HW/HW_icon@3x.png";
import airlineIconHW4x from "../../assets/airlines/HW/HW_icon@4x.png";
import airlineIconIQ2x from "../../assets/airlines/IQ/IQ_icon@2x.png";
import airlineIconIQ3x from "../../assets/airlines/IQ/IQ_icon@3x.png";
import airlineIconIQ4x from "../../assets/airlines/IQ/IQ_icon@4x.png";
import airlineIconJF2x from "../../assets/airlines/JF/JF_icon@2x.png";
import airlineIconJF3x from "../../assets/airlines/JF/JF_icon@3x.png";
import airlineIconJF4x from "../../assets/airlines/JF/JF_icon@4x.png";
import airlineIconK82x from "../../assets/airlines/K8/K8_icon@2x.png";
import airlineIconK83x from "../../assets/airlines/K8/K8_icon@3x.png";
import airlineIconK84x from "../../assets/airlines/K8/K8_icon@4x.png";
import airlineIconKY2x from "../../assets/airlines/KY/KY_icon@2x.png";
import airlineIconKY3x from "../../assets/airlines/KY/KY_icon@3x.png";
import airlineIconKY4x from "../../assets/airlines/KY/KY_icon@4x.png";
import airlineIconLR2x from "../../assets/airlines/LR/LR_icon@2x.png";
import airlineIconLR3x from "../../assets/airlines/LR/LR_icon@3x.png";
import airlineIconLR4x from "../../assets/airlines/LR/LR_icon@4x.png";
import airlineIconMI2x from "../../assets/airlines/MI/MI_icon@2x.png";
import airlineIconMI3x from "../../assets/airlines/MI/MI_icon@3x.png";
import airlineIconMI4x from "../../assets/airlines/MI/MI_icon@4x.png";
import airlineIconN72x from "../../assets/airlines/N7/N7_icon@2x.png";
import airlineIconN73x from "../../assets/airlines/N7/N7_icon@3x.png";
import airlineIconN74x from "../../assets/airlines/N7/N7_icon@4x.png";
import airlineIconO32x from "../../assets/airlines/O3/O3_icon@2x.png";
import airlineIconO33x from "../../assets/airlines/O3/O3_icon@3x.png";
import airlineIconO34x from "../../assets/airlines/O3/O3_icon@4x.png";
import airlineIconOW2x from "../../assets/airlines/OW/OW_icon@2x.png";
import airlineIconOW3x from "../../assets/airlines/OW/OW_icon@3x.png";
import airlineIconOW4x from "../../assets/airlines/OW/OW_icon@4x.png";
import airlineIconPL2x from "../../assets/airlines/PL/PL_icon@2x.png";
import airlineIconPL3x from "../../assets/airlines/PL/PL_icon@3x.png";
import airlineIconPL4x from "../../assets/airlines/PL/PL_icon@4x.png";
import airlineIconQD2x from "../../assets/airlines/QD/QD_icon@2x.png";
import airlineIconQD3x from "../../assets/airlines/QD/QD_icon@3x.png";
import airlineIconQD4x from "../../assets/airlines/QD/QD_icon@4x.png";
import airlineIconR32x from "../../assets/airlines/R3/R3_icon@2x.png";
import airlineIconR33x from "../../assets/airlines/R3/R3_icon@3x.png";
import airlineIconR34x from "../../assets/airlines/R3/R3_icon@4x.png";
import airlineIconRV2x from "../../assets/airlines/RV/RV_icon@2x.png";
import airlineIconRV3x from "../../assets/airlines/RV/RV_icon@3x.png";
import airlineIconRV4x from "../../assets/airlines/RV/RV_icon@4x.png";
import airlineIconSK2x from "../../assets/airlines/SK/SK_icon@2x.png";
import airlineIconSK3x from "../../assets/airlines/SK/SK_icon@3x.png";
import airlineIconSK4x from "../../assets/airlines/SK/SK_icon@4x.png";
import airlineIconTB2x from "../../assets/airlines/TB/TB_icon@2x.png";
import airlineIconTB3x from "../../assets/airlines/TB/TB_icon@3x.png";
import airlineIconTB4x from "../../assets/airlines/TB/TB_icon@4x.png";
import airlineIconU22x from "../../assets/airlines/U2/U2_icon@2x.png";
import airlineIconU23x from "../../assets/airlines/U2/U2_icon@3x.png";
import airlineIconU24x from "../../assets/airlines/U2/U2_icon@4x.png";
import airlineIconUT2x from "../../assets/airlines/UT/UT_icon@2x.png";
import airlineIconUT3x from "../../assets/airlines/UT/UT_icon@3x.png";
import airlineIconUT4x from "../../assets/airlines/UT/UT_icon@4x.png";
import airlineIconVP2x from "../../assets/airlines/VP/VP_icon@2x.png";
import airlineIconVP3x from "../../assets/airlines/VP/VP_icon@3x.png";
import airlineIconVP4x from "../../assets/airlines/VP/VP_icon@4x.png";
import airlineIconWF2x from "../../assets/airlines/WF/WF_icon@2x.png";
import airlineIconWF3x from "../../assets/airlines/WF/WF_icon@3x.png";
import airlineIconWF4x from "../../assets/airlines/WF/WF_icon@4x.png";
import airlineIconX82x from "../../assets/airlines/X8/X8_icon@2x.png";
import airlineIconX83x from "../../assets/airlines/X8/X8_icon@3x.png";
import airlineIconX84x from "../../assets/airlines/X8/X8_icon@4x.png";
import airlineIconYC2x from "../../assets/airlines/YC/YC_icon@2x.png";
import airlineIconYC3x from "../../assets/airlines/YC/YC_icon@3x.png";
import airlineIconYC4x from "../../assets/airlines/YC/YC_icon@4x.png";
import airlineIconZA2x from "../../assets/airlines/ZA/ZA_icon@2x.png";
import airlineIconZA3x from "../../assets/airlines/ZA/ZA_icon@3x.png";
import airlineIconZA4x from "../../assets/airlines/ZA/ZA_icon@4x.png";
import airlineIcon2O2x from "../../assets/airlines/2O/2O_icon@2x.png";
import airlineIcon2O3x from "../../assets/airlines/2O/2O_icon@3x.png";
import airlineIcon2O4x from "../../assets/airlines/2O/2O_icon@4x.png";
import airlineIcon3P2x from "../../assets/airlines/3P/3P_icon@2x.png";
import airlineIcon3P3x from "../../assets/airlines/3P/3P_icon@3x.png";
import airlineIcon3P4x from "../../assets/airlines/3P/3P_icon@4x.png";
import airlineIcon4R2x from "../../assets/airlines/4R/4R_icon@2x.png";
import airlineIcon4R3x from "../../assets/airlines/4R/4R_icon@3x.png";
import airlineIcon4R4x from "../../assets/airlines/4R/4R_icon@4x.png";
import airlineIcon5R2x from "../../assets/airlines/5R/5R_icon@2x.png";
import airlineIcon5R3x from "../../assets/airlines/5R/5R_icon@3x.png";
import airlineIcon5R4x from "../../assets/airlines/5R/5R_icon@4x.png";
import airlineIcon6V2x from "../../assets/airlines/6V/6V_icon@2x.png";
import airlineIcon6V3x from "../../assets/airlines/6V/6V_icon@3x.png";
import airlineIcon6V4x from "../../assets/airlines/6V/6V_icon@4x.png";
import airlineIcon7V2x from "../../assets/airlines/7V/7V_icon@2x.png";
import airlineIcon7V3x from "../../assets/airlines/7V/7V_icon@3x.png";
import airlineIcon7V4x from "../../assets/airlines/7V/7V_icon@4x.png";
import airlineIcon8W2x from "../../assets/airlines/8W/8W_icon@2x.png";
import airlineIcon8W3x from "../../assets/airlines/8W/8W_icon@3x.png";
import airlineIcon8W4x from "../../assets/airlines/8W/8W_icon@4x.png";
import airlineIcon9X2x from "../../assets/airlines/9X/9X_icon@2x.png";
import airlineIcon9X3x from "../../assets/airlines/9X/9X_icon@3x.png";
import airlineIcon9X4x from "../../assets/airlines/9X/9X_icon@4x.png";
import airlineIconAN2x from "../../assets/airlines/AN/AN_icon@2x.png";
import airlineIconAN3x from "../../assets/airlines/AN/AN_icon@3x.png";
import airlineIconAN4x from "../../assets/airlines/AN/AN_icon@4x.png";
import airlineIconBE2x from "../../assets/airlines/BE/BE_icon@2x.png";
import airlineIconBE3x from "../../assets/airlines/BE/BE_icon@3x.png";
import airlineIconBE4x from "../../assets/airlines/BE/BE_icon@4x.png";
import airlineIconC62x from "../../assets/airlines/C6/C6_icon@2x.png";
import airlineIconC63x from "../../assets/airlines/C6/C6_icon@3x.png";
import airlineIconC64x from "../../assets/airlines/C6/C6_icon@4x.png";
import airlineIconD22x from "../../assets/airlines/D2/D2_icon@2x.png";
import airlineIconD23x from "../../assets/airlines/D2/D2_icon@3x.png";
import airlineIconD24x from "../../assets/airlines/D2/D2_icon@4x.png";
import airlineIconDY2x from "../../assets/airlines/DY/DY_icon@2x.png";
import airlineIconDY3x from "../../assets/airlines/DY/DY_icon@3x.png";
import airlineIconDY4x from "../../assets/airlines/DY/DY_icon@4x.png";
import airlineIconES2x from "../../assets/airlines/ES/ES_icon@2x.png";
import airlineIconES3x from "../../assets/airlines/ES/ES_icon@3x.png";
import airlineIconES4x from "../../assets/airlines/ES/ES_icon@4x.png";
import airlineIconFH2x from "../../assets/airlines/FH/FH_icon@2x.png";
import airlineIconFH3x from "../../assets/airlines/FH/FH_icon@3x.png";
import airlineIconFH4x from "../../assets/airlines/FH/FH_icon@4x.png";
import airlineIconG82x from "../../assets/airlines/G8/G8_icon@2x.png";
import airlineIconG83x from "../../assets/airlines/G8/G8_icon@3x.png";
import airlineIconG84x from "../../assets/airlines/G8/G8_icon@4x.png";
import airlineIconH22x from "../../assets/airlines/H2/H2_icon@2x.png";
import airlineIconH23x from "../../assets/airlines/H2/H2_icon@3x.png";
import airlineIconH24x from "../../assets/airlines/H2/H2_icon@4x.png";
import airlineIconHX2x from "../../assets/airlines/HX/HX_icon@2x.png";
import airlineIconHX3x from "../../assets/airlines/HX/HX_icon@3x.png";
import airlineIconHX4x from "../../assets/airlines/HX/HX_icon@4x.png";
import airlineIconIR2x from "../../assets/airlines/IR/IR_icon@2x.png";
import airlineIconIR3x from "../../assets/airlines/IR/IR_icon@3x.png";
import airlineIconIR4x from "../../assets/airlines/IR/IR_icon@4x.png";
import airlineIconJG2x from "../../assets/airlines/JG/JG_icon@2x.png";
import airlineIconJG3x from "../../assets/airlines/JG/JG_icon@3x.png";
import airlineIconJG4x from "../../assets/airlines/JG/JG_icon@4x.png";
import airlineIconKA2x from "../../assets/airlines/KA/KA_icon@2x.png";
import airlineIconKA3x from "../../assets/airlines/KA/KA_icon@3x.png";
import airlineIconKA4x from "../../assets/airlines/KA/KA_icon@4x.png";
import airlineIconKZ2x from "../../assets/airlines/KZ/KZ_icon@2x.png";
import airlineIconKZ3x from "../../assets/airlines/KZ/KZ_icon@3x.png";
import airlineIconKZ4x from "../../assets/airlines/KZ/KZ_icon@4x.png";
import airlineIconLS2x from "../../assets/airlines/LS/LS_icon@2x.png";
import airlineIconLS3x from "../../assets/airlines/LS/LS_icon@3x.png";
import airlineIconLS4x from "../../assets/airlines/LS/LS_icon@4x.png";
import airlineIconMJ2x from "../../assets/airlines/MJ/MJ_icon@2x.png";
import airlineIconMJ3x from "../../assets/airlines/MJ/MJ_icon@3x.png";
import airlineIconMJ4x from "../../assets/airlines/MJ/MJ_icon@4x.png";
import airlineIconN92x from "../../assets/airlines/N9/N9_icon@2x.png";
import airlineIconN93x from "../../assets/airlines/N9/N9_icon@3x.png";
import airlineIconN94x from "../../assets/airlines/N9/N9_icon@4x.png";
import airlineIconO62x from "../../assets/airlines/O6/O6_icon@2x.png";
import airlineIconO63x from "../../assets/airlines/O6/O6_icon@3x.png";
import airlineIconO64x from "../../assets/airlines/O6/O6_icon@4x.png";
import airlineIconOY2x from "../../assets/airlines/OY/OY_icon@2x.png";
import airlineIconOY3x from "../../assets/airlines/OY/OY_icon@3x.png";
import airlineIconOY4x from "../../assets/airlines/OY/OY_icon@4x.png";
import airlineIconPM2x from "../../assets/airlines/PM/PM_icon@2x.png";
import airlineIconPM3x from "../../assets/airlines/PM/PM_icon@3x.png";
import airlineIconPM4x from "../../assets/airlines/PM/PM_icon@4x.png";
import airlineIconQE2x from "../../assets/airlines/QE/QE_icon@2x.png";
import airlineIconQE3x from "../../assets/airlines/QE/QE_icon@3x.png";
import airlineIconQE4x from "../../assets/airlines/QE/QE_icon@4x.png";
import airlineIconR52x from "../../assets/airlines/R5/R5_icon@2x.png";
import airlineIconR53x from "../../assets/airlines/R5/R5_icon@3x.png";
import airlineIconR54x from "../../assets/airlines/R5/R5_icon@4x.png";
import airlineIconRW2x from "../../assets/airlines/RW/RW_icon@2x.png";
import airlineIconRW3x from "../../assets/airlines/RW/RW_icon@3x.png";
import airlineIconRW4x from "../../assets/airlines/RW/RW_icon@4x.png";
import airlineIconSL2x from "../../assets/airlines/SL/SL_icon@2x.png";
import airlineIconSL3x from "../../assets/airlines/SL/SL_icon@3x.png";
import airlineIconSL4x from "../../assets/airlines/SL/SL_icon@4x.png";
import airlineIconTC2x from "../../assets/airlines/TC/TC_icon@2x.png";
import airlineIconTC3x from "../../assets/airlines/TC/TC_icon@3x.png";
import airlineIconTC4x from "../../assets/airlines/TC/TC_icon@4x.png";
import airlineIconU32x from "../../assets/airlines/U3/U3_icon@2x.png";
import airlineIconU33x from "../../assets/airlines/U3/U3_icon@3x.png";
import airlineIconU34x from "../../assets/airlines/U3/U3_icon@4x.png";
import airlineIconUU2x from "../../assets/airlines/UU/UU_icon@2x.png";
import airlineIconUU3x from "../../assets/airlines/UU/UU_icon@3x.png";
import airlineIconUU4x from "../../assets/airlines/UU/UU_icon@4x.png";
import airlineIconVQ2x from "../../assets/airlines/VQ/VQ_icon@2x.png";
import airlineIconVQ3x from "../../assets/airlines/VQ/VQ_icon@3x.png";
import airlineIconVQ4x from "../../assets/airlines/VQ/VQ_icon@4x.png";
import airlineIconWG2x from "../../assets/airlines/WG/WG_icon@2x.png";
import airlineIconWG3x from "../../assets/airlines/WG/WG_icon@3x.png";
import airlineIconWG4x from "../../assets/airlines/WG/WG_icon@4x.png";
import airlineIconX92x from "../../assets/airlines/X9/X9_icon@2x.png";
import airlineIconX93x from "../../assets/airlines/X9/X9_icon@3x.png";
import airlineIconX94x from "../../assets/airlines/X9/X9_icon@4x.png";
import airlineIconYE2x from "../../assets/airlines/YE/YE_icon@2x.png";
import airlineIconYE3x from "../../assets/airlines/YE/YE_icon@3x.png";
import airlineIconYE4x from "../../assets/airlines/YE/YE_icon@4x.png";
import airlineIconZB2x from "../../assets/airlines/ZB/ZB_icon@2x.png";
import airlineIconZB3x from "../../assets/airlines/ZB/ZB_icon@3x.png";
import airlineIconZB4x from "../../assets/airlines/ZB/ZB_icon@4x.png";
import airlineIcon2P2x from "../../assets/airlines/2P/2P_icon@2x.png";
import airlineIcon2P3x from "../../assets/airlines/2P/2P_icon@3x.png";
import airlineIcon2P4x from "../../assets/airlines/2P/2P_icon@4x.png";
import airlineIcon3R2x from "../../assets/airlines/3R/3R_icon@2x.png";
import airlineIcon3R3x from "../../assets/airlines/3R/3R_icon@3x.png";
import airlineIcon3R4x from "../../assets/airlines/3R/3R_icon@4x.png";
import airlineIcon4S2x from "../../assets/airlines/4S/4S_icon@2x.png";
import airlineIcon4S3x from "../../assets/airlines/4S/4S_icon@3x.png";
import airlineIcon4S4x from "../../assets/airlines/4S/4S_icon@4x.png";
import airlineIcon5S2x from "../../assets/airlines/5S/5S_icon@2x.png";
import airlineIcon5S3x from "../../assets/airlines/5S/5S_icon@3x.png";
import airlineIcon5S4x from "../../assets/airlines/5S/5S_icon@4x.png";
import airlineIcon6W2x from "../../assets/airlines/6W/6W_icon@2x.png";
import airlineIcon6W3x from "../../assets/airlines/6W/6W_icon@3x.png";
import airlineIcon6W4x from "../../assets/airlines/6W/6W_icon@4x.png";
import airlineIcon7W2x from "../../assets/airlines/7W/7W_icon@2x.png";
import airlineIcon7W3x from "../../assets/airlines/7W/7W_icon@3x.png";
import airlineIcon7W4x from "../../assets/airlines/7W/7W_icon@4x.png";
import airlineIcon8Y2x from "../../assets/airlines/8Y/8Y_icon@2x.png";
import airlineIcon8Y3x from "../../assets/airlines/8Y/8Y_icon@3x.png";
import airlineIcon8Y4x from "../../assets/airlines/8Y/8Y_icon@4x.png";
import airlineIconA12x from "../../assets/airlines/A1/A1_icon@2x.png";
import airlineIconA13x from "../../assets/airlines/A1/A1_icon@3x.png";
import airlineIconA14x from "../../assets/airlines/A1/A1_icon@4x.png";
import airlineIconAO2x from "../../assets/airlines/AO/AO_icon@2x.png";
import airlineIconAO3x from "../../assets/airlines/AO/AO_icon@3x.png";
import airlineIconAO4x from "../../assets/airlines/AO/AO_icon@4x.png";
import airlineIconBF2x from "../../assets/airlines/BF/BF_icon@2x.png";
import airlineIconBF3x from "../../assets/airlines/BF/BF_icon@3x.png";
import airlineIconBF4x from "../../assets/airlines/BF/BF_icon@4x.png";
import airlineIconC72x from "../../assets/airlines/C7/C7_icon@2x.png";
import airlineIconC73x from "../../assets/airlines/C7/C7_icon@3x.png";
import airlineIconC74x from "../../assets/airlines/C7/C7_icon@4x.png";
import airlineIconD32x from "../../assets/airlines/D3/D3_icon@2x.png";
import airlineIconD33x from "../../assets/airlines/D3/D3_icon@3x.png";
import airlineIconD34x from "../../assets/airlines/D3/D3_icon@4x.png";
import airlineIconDZ2x from "../../assets/airlines/DZ/DZ_icon@2x.png";
import airlineIconDZ3x from "../../assets/airlines/DZ/DZ_icon@3x.png";
import airlineIconDZ4x from "../../assets/airlines/DZ/DZ_icon@4x.png";
import airlineIconET2x from "../../assets/airlines/ET/ET_icon@2x.png";
import airlineIconET3x from "../../assets/airlines/ET/ET_icon@3x.png";
import airlineIconET4x from "../../assets/airlines/ET/ET_icon@4x.png";
import airlineIconFI2x from "../../assets/airlines/FI/FI_icon@2x.png";
import airlineIconFI3x from "../../assets/airlines/FI/FI_icon@3x.png";
import airlineIconFI4x from "../../assets/airlines/FI/FI_icon@4x.png";
import airlineIconG92x from "../../assets/airlines/G9/G9_icon@2x.png";
import airlineIconG93x from "../../assets/airlines/G9/G9_icon@3x.png";
import airlineIconG94x from "../../assets/airlines/G9/G9_icon@4x.png";
import airlineIconH42x from "../../assets/airlines/H4/H4_icon@2x.png";
import airlineIconH43x from "../../assets/airlines/H4/H4_icon@3x.png";
import airlineIconH44x from "../../assets/airlines/H4/H4_icon@4x.png";
import airlineIconHY2x from "../../assets/airlines/HY/HY_icon@2x.png";
import airlineIconHY3x from "../../assets/airlines/HY/HY_icon@3x.png";
import airlineIconHY4x from "../../assets/airlines/HY/HY_icon@4x.png";
import airlineIconIS2x from "../../assets/airlines/IS/IS_icon@2x.png";
import airlineIconIS3x from "../../assets/airlines/IS/IS_icon@3x.png";
import airlineIconIS4x from "../../assets/airlines/IS/IS_icon@4x.png";
import airlineIconJH2x from "../../assets/airlines/JH/JH_icon@2x.png";
import airlineIconJH3x from "../../assets/airlines/JH/JH_icon@3x.png";
import airlineIconJH4x from "../../assets/airlines/JH/JH_icon@4x.png";
import airlineIconKB2x from "../../assets/airlines/KB/KB_icon@2x.png";
import airlineIconKB3x from "../../assets/airlines/KB/KB_icon@3x.png";
import airlineIconKB4x from "../../assets/airlines/KB/KB_icon@4x.png";
import airlineIconL22x from "../../assets/airlines/L2/L2_icon@2x.png";
import airlineIconL23x from "../../assets/airlines/L2/L2_icon@3x.png";
import airlineIconL24x from "../../assets/airlines/L2/L2_icon@4x.png";
import airlineIconLT2x from "../../assets/airlines/LT/LT_icon@2x.png";
import airlineIconLT3x from "../../assets/airlines/LT/LT_icon@3x.png";
import airlineIconLT4x from "../../assets/airlines/LT/LT_icon@4x.png";
import airlineIconMK2x from "../../assets/airlines/MK/MK_icon@2x.png";
import airlineIconMK3x from "../../assets/airlines/MK/MK_icon@3x.png";
import airlineIconMK4x from "../../assets/airlines/MK/MK_icon@4x.png";
import airlineIconNA2x from "../../assets/airlines/NA/NA_icon@2x.png";
import airlineIconNA3x from "../../assets/airlines/NA/NA_icon@3x.png";
import airlineIconNA4x from "../../assets/airlines/NA/NA_icon@4x.png";
import airlineIconOA2x from "../../assets/airlines/OA/OA_icon@2x.png";
import airlineIconOA3x from "../../assets/airlines/OA/OA_icon@3x.png";
import airlineIconOA4x from "../../assets/airlines/OA/OA_icon@4x.png";
import airlineIconOZ2x from "../../assets/airlines/OZ/OZ_icon@2x.png";
import airlineIconOZ3x from "../../assets/airlines/OZ/OZ_icon@3x.png";
import airlineIconOZ4x from "../../assets/airlines/OZ/OZ_icon@4x.png";
import airlineIconPN2x from "../../assets/airlines/PN/PN_icon@2x.png";
import airlineIconPN3x from "../../assets/airlines/PN/PN_icon@3x.png";
import airlineIconPN4x from "../../assets/airlines/PN/PN_icon@4x.png";
import airlineIconQF2x from "../../assets/airlines/QF/QF_icon@2x.png";
import airlineIconQF3x from "../../assets/airlines/QF/QF_icon@3x.png";
import airlineIconQF4x from "../../assets/airlines/QF/QF_icon@4x.png";
import airlineIconR62x from "../../assets/airlines/R6/R6_icon@2x.png";
import airlineIconR63x from "../../assets/airlines/R6/R6_icon@3x.png";
import airlineIconR64x from "../../assets/airlines/R6/R6_icon@4x.png";
import airlineIconRY2x from "../../assets/airlines/RY/RY_icon@2x.png";
import airlineIconRY3x from "../../assets/airlines/RY/RY_icon@3x.png";
import airlineIconRY4x from "../../assets/airlines/RY/RY_icon@4x.png";
import airlineIconSM2x from "../../assets/airlines/SM/SM_icon@2x.png";
import airlineIconSM3x from "../../assets/airlines/SM/SM_icon@3x.png";
import airlineIconSM4x from "../../assets/airlines/SM/SM_icon@4x.png";
import airlineIconTE2x from "../../assets/airlines/TE/TE_icon@2x.png";
import airlineIconTE3x from "../../assets/airlines/TE/TE_icon@3x.png";
import airlineIconTE4x from "../../assets/airlines/TE/TE_icon@4x.png";
import airlineIconU42x from "../../assets/airlines/U4/U4_icon@2x.png";
import airlineIconU43x from "../../assets/airlines/U4/U4_icon@3x.png";
import airlineIconU44x from "../../assets/airlines/U4/U4_icon@4x.png";
import airlineIconUX2x from "../../assets/airlines/UX/UX_icon@2x.png";
import airlineIconUX3x from "../../assets/airlines/UX/UX_icon@3x.png";
import airlineIconUX4x from "../../assets/airlines/UX/UX_icon@4x.png";
import airlineIconVR2x from "../../assets/airlines/VR/VR_icon@2x.png";
import airlineIconVR3x from "../../assets/airlines/VR/VR_icon@3x.png";
import airlineIconVR4x from "../../assets/airlines/VR/VR_icon@4x.png";
import airlineIconWI2x from "../../assets/airlines/WI/WI_icon@2x.png";
import airlineIconWI3x from "../../assets/airlines/WI/WI_icon@3x.png";
import airlineIconWI4x from "../../assets/airlines/WI/WI_icon@4x.png";
import airlineIconXC2x from "../../assets/airlines/XC/XC_icon@2x.png";
import airlineIconXC3x from "../../assets/airlines/XC/XC_icon@3x.png";
import airlineIconXC4x from "../../assets/airlines/XC/XC_icon@4x.png";
import airlineIconYG2x from "../../assets/airlines/YG/YG_icon@2x.png";
import airlineIconYG3x from "../../assets/airlines/YG/YG_icon@3x.png";
import airlineIconYG4x from "../../assets/airlines/YG/YG_icon@4x.png";
import airlineIconZD2x from "../../assets/airlines/ZD/ZD_icon@2x.png";
import airlineIconZD3x from "../../assets/airlines/ZD/ZD_icon@3x.png";
import airlineIconZD4x from "../../assets/airlines/ZD/ZD_icon@4x.png";
import airlineIcon2Q2x from "../../assets/airlines/2Q/2Q_icon@2x.png";
import airlineIcon2Q3x from "../../assets/airlines/2Q/2Q_icon@3x.png";
import airlineIcon2Q4x from "../../assets/airlines/2Q/2Q_icon@4x.png";
import airlineIcon3S2x from "../../assets/airlines/3S/3S_icon@2x.png";
import airlineIcon3S3x from "../../assets/airlines/3S/3S_icon@3x.png";
import airlineIcon3S4x from "../../assets/airlines/3S/3S_icon@4x.png";
import airlineIcon4T2x from "../../assets/airlines/4T/4T_icon@2x.png";
import airlineIcon4T3x from "../../assets/airlines/4T/4T_icon@3x.png";
import airlineIcon4T4x from "../../assets/airlines/4T/4T_icon@4x.png";
import airlineIcon5T2x from "../../assets/airlines/5T/5T_icon@2x.png";
import airlineIcon5T3x from "../../assets/airlines/5T/5T_icon@3x.png";
import airlineIcon5T4x from "../../assets/airlines/5T/5T_icon@4x.png";
import airlineIcon6Y2x from "../../assets/airlines/6Y/6Y_icon@2x.png";
import airlineIcon6Y3x from "../../assets/airlines/6Y/6Y_icon@3x.png";
import airlineIcon6Y4x from "../../assets/airlines/6Y/6Y_icon@4x.png";
import airlineIcon7Y2x from "../../assets/airlines/7Y/7Y_icon@2x.png";
import airlineIcon7Y3x from "../../assets/airlines/7Y/7Y_icon@3x.png";
import airlineIcon7Y4x from "../../assets/airlines/7Y/7Y_icon@4x.png";
import airlineIcon8Z2x from "../../assets/airlines/8Z/8Z_icon@2x.png";
import airlineIcon8Z3x from "../../assets/airlines/8Z/8Z_icon@3x.png";
import airlineIcon8Z4x from "../../assets/airlines/8Z/8Z_icon@4x.png";
import airlineIconA22x from "../../assets/airlines/A2/A2_icon@2x.png";
import airlineIconA23x from "../../assets/airlines/A2/A2_icon@3x.png";
import airlineIconA24x from "../../assets/airlines/A2/A2_icon@4x.png";
import airlineIconAP2x from "../../assets/airlines/AP/AP_icon@2x.png";
import airlineIconAP3x from "../../assets/airlines/AP/AP_icon@3x.png";
import airlineIconAP4x from "../../assets/airlines/AP/AP_icon@4x.png";
import airlineIconBG2x from "../../assets/airlines/BG/BG_icon@2x.png";
import airlineIconBG3x from "../../assets/airlines/BG/BG_icon@3x.png";
import airlineIconBG4x from "../../assets/airlines/BG/BG_icon@4x.png";
import airlineIconC82x from "../../assets/airlines/C8/C8_icon@2x.png";
import airlineIconC83x from "../../assets/airlines/C8/C8_icon@3x.png";
import airlineIconC84x from "../../assets/airlines/C8/C8_icon@4x.png";
import airlineIconD42x from "../../assets/airlines/D4/D4_icon@2x.png";
import airlineIconD43x from "../../assets/airlines/D4/D4_icon@3x.png";
import airlineIconD44x from "../../assets/airlines/D4/D4_icon@4x.png";
import airlineIconE22x from "../../assets/airlines/E2/E2_icon@2x.png";
import airlineIconE23x from "../../assets/airlines/E2/E2_icon@3x.png";
import airlineIconE24x from "../../assets/airlines/E2/E2_icon@4x.png";
import airlineIconEU2x from "../../assets/airlines/EU/EU_icon@2x.png";
import airlineIconEU3x from "../../assets/airlines/EU/EU_icon@3x.png";
import airlineIconEU4x from "../../assets/airlines/EU/EU_icon@4x.png";
import airlineIconFJ2x from "../../assets/airlines/FJ/FJ_icon@2x.png";
import airlineIconFJ3x from "../../assets/airlines/FJ/FJ_icon@3x.png";
import airlineIconFJ4x from "../../assets/airlines/FJ/FJ_icon@4x.png";
import airlineIconGA2x from "../../assets/airlines/GA/GA_icon@2x.png";
import airlineIconGA3x from "../../assets/airlines/GA/GA_icon@3x.png";
import airlineIconGA4x from "../../assets/airlines/GA/GA_icon@4x.png";
import airlineIconH62x from "../../assets/airlines/H6/H6_icon@2x.png";
import airlineIconH63x from "../../assets/airlines/H6/H6_icon@3x.png";
import airlineIconH64x from "../../assets/airlines/H6/H6_icon@4x.png";
import airlineIconHZ2x from "../../assets/airlines/HZ/HZ_icon@2x.png";
import airlineIconHZ3x from "../../assets/airlines/HZ/HZ_icon@3x.png";
import airlineIconHZ4x from "../../assets/airlines/HZ/HZ_icon@4x.png";
import airlineIconIT2x from "../../assets/airlines/IT/IT_icon@2x.png";
import airlineIconIT3x from "../../assets/airlines/IT/IT_icon@3x.png";
import airlineIconIT4x from "../../assets/airlines/IT/IT_icon@4x.png";
import airlineIconJJ2x from "../../assets/airlines/JJ/JJ_icon@2x.png";
import airlineIconJJ3x from "../../assets/airlines/JJ/JJ_icon@3x.png";
import airlineIconJJ4x from "../../assets/airlines/JJ/JJ_icon@4x.png";
import airlineIconKC2x from "../../assets/airlines/KC/KC_icon@2x.png";
import airlineIconKC3x from "../../assets/airlines/KC/KC_icon@3x.png";
import airlineIconKC4x from "../../assets/airlines/KC/KC_icon@4x.png";
import airlineIconL32x from "../../assets/airlines/L3/L3_icon@2x.png";
import airlineIconL33x from "../../assets/airlines/L3/L3_icon@3x.png";
import airlineIconL34x from "../../assets/airlines/L3/L3_icon@4x.png";
import airlineIconLU2x from "../../assets/airlines/LU/LU_icon@2x.png";
import airlineIconLU3x from "../../assets/airlines/LU/LU_icon@3x.png";
import airlineIconLU4x from "../../assets/airlines/LU/LU_icon@4x.png";
import airlineIconML2x from "../../assets/airlines/ML/ML_icon@2x.png";
import airlineIconML3x from "../../assets/airlines/ML/ML_icon@3x.png";
import airlineIconML4x from "../../assets/airlines/ML/ML_icon@4x.png";
import airlineIconNB2x from "../../assets/airlines/NB/NB_icon@2x.png";
import airlineIconNB3x from "../../assets/airlines/NB/NB_icon@3x.png";
import airlineIconNB4x from "../../assets/airlines/NB/NB_icon@4x.png";
import airlineIconOB2x from "../../assets/airlines/OB/OB_icon@2x.png";
import airlineIconOB3x from "../../assets/airlines/OB/OB_icon@3x.png";
import airlineIconOB4x from "../../assets/airlines/OB/OB_icon@4x.png";
import airlineIconP02x from "../../assets/airlines/P0/P0_icon@2x.png";
import airlineIconP03x from "../../assets/airlines/P0/P0_icon@3x.png";
import airlineIconP04x from "../../assets/airlines/P0/P0_icon@4x.png";
import airlineIconPO2x from "../../assets/airlines/PO/PO_icon@2x.png";
import airlineIconPO3x from "../../assets/airlines/PO/PO_icon@3x.png";
import airlineIconPO4x from "../../assets/airlines/PO/PO_icon@4x.png";
import airlineIconQG2x from "../../assets/airlines/QG/QG_icon@2x.png";
import airlineIconQG3x from "../../assets/airlines/QG/QG_icon@3x.png";
import airlineIconQG4x from "../../assets/airlines/QG/QG_icon@4x.png";
import airlineIconR72x from "../../assets/airlines/R7/R7_icon@2x.png";
import airlineIconR73x from "../../assets/airlines/R7/R7_icon@3x.png";
import airlineIconR74x from "../../assets/airlines/R7/R7_icon@4x.png";
import airlineIconRZ2x from "../../assets/airlines/RZ/RZ_icon@2x.png";
import airlineIconRZ3x from "../../assets/airlines/RZ/RZ_icon@3x.png";
import airlineIconRZ4x from "../../assets/airlines/RZ/RZ_icon@4x.png";
import airlineIconSN2x from "../../assets/airlines/SN/SN_icon@2x.png";
import airlineIconSN3x from "../../assets/airlines/SN/SN_icon@3x.png";
import airlineIconSN4x from "../../assets/airlines/SN/SN_icon@4x.png";
import airlineIconTF2x from "../../assets/airlines/TF/TF_icon@2x.png";
import airlineIconTF3x from "../../assets/airlines/TF/TF_icon@3x.png";
import airlineIconTF4x from "../../assets/airlines/TF/TF_icon@4x.png";
import airlineIconU52x from "../../assets/airlines/U5/U5_icon@2x.png";
import airlineIconU53x from "../../assets/airlines/U5/U5_icon@3x.png";
import airlineIconU54x from "../../assets/airlines/U5/U5_icon@4x.png";
import airlineIconUZ2x from "../../assets/airlines/UZ/UZ_icon@2x.png";
import airlineIconUZ3x from "../../assets/airlines/UZ/UZ_icon@3x.png";
import airlineIconUZ4x from "../../assets/airlines/UZ/UZ_icon@4x.png";
import airlineIconVS2x from "../../assets/airlines/VS/VS_icon@2x.png";
import airlineIconVS3x from "../../assets/airlines/VS/VS_icon@3x.png";
import airlineIconVS4x from "../../assets/airlines/VS/VS_icon@4x.png";
import airlineIconWJ2x from "../../assets/airlines/WJ/WJ_icon@2x.png";
import airlineIconWJ3x from "../../assets/airlines/WJ/WJ_icon@3x.png";
import airlineIconWJ4x from "../../assets/airlines/WJ/WJ_icon@4x.png";
import airlineIconXE2x from "../../assets/airlines/XE/XE_icon@2x.png";
import airlineIconXE3x from "../../assets/airlines/XE/XE_icon@3x.png";
import airlineIconXE4x from "../../assets/airlines/XE/XE_icon@4x.png";
import airlineIconYH2x from "../../assets/airlines/YH/YH_icon@2x.png";
import airlineIconYH3x from "../../assets/airlines/YH/YH_icon@3x.png";
import airlineIconYH4x from "../../assets/airlines/YH/YH_icon@4x.png";
import airlineIconZF2x from "../../assets/airlines/ZF/ZF_icon@2x.png";
import airlineIconZF3x from "../../assets/airlines/ZF/ZF_icon@3x.png";
import airlineIconZF4x from "../../assets/airlines/ZF/ZF_icon@4x.png";
import airlineIcon2T2x from "../../assets/airlines/2T/2T_icon@2x.png";
import airlineIcon2T3x from "../../assets/airlines/2T/2T_icon@3x.png";
import airlineIcon2T4x from "../../assets/airlines/2T/2T_icon@4x.png";
import airlineIcon3T2x from "../../assets/airlines/3T/3T_icon@2x.png";
import airlineIcon3T3x from "../../assets/airlines/3T/3T_icon@3x.png";
import airlineIcon3T4x from "../../assets/airlines/3T/3T_icon@4x.png";
import airlineIcon4U2x from "../../assets/airlines/4U/4U_icon@2x.png";
import airlineIcon4U3x from "../../assets/airlines/4U/4U_icon@3x.png";
import airlineIcon4U4x from "../../assets/airlines/4U/4U_icon@4x.png";
import airlineIcon5U2x from "../../assets/airlines/5U/5U_icon@2x.png";
import airlineIcon5U3x from "../../assets/airlines/5U/5U_icon@3x.png";
import airlineIcon5U4x from "../../assets/airlines/5U/5U_icon@4x.png";
import airlineIcon6Z2x from "../../assets/airlines/6Z/6Z_icon@2x.png";
import airlineIcon6Z3x from "../../assets/airlines/6Z/6Z_icon@3x.png";
import airlineIcon6Z4x from "../../assets/airlines/6Z/6Z_icon@4x.png";
import airlineIcon7Z2x from "../../assets/airlines/7Z/7Z_icon@2x.png";
import airlineIcon7Z3x from "../../assets/airlines/7Z/7Z_icon@3x.png";
import airlineIcon7Z4x from "../../assets/airlines/7Z/7Z_icon@4x.png";
import airlineIcon9A2x from "../../assets/airlines/9A/9A_icon@2x.png";
import airlineIcon9A3x from "../../assets/airlines/9A/9A_icon@3x.png";
import airlineIcon9A4x from "../../assets/airlines/9A/9A_icon@4x.png";
import airlineIconA32x from "../../assets/airlines/A3/A3_icon@2x.png";
import airlineIconA33x from "../../assets/airlines/A3/A3_icon@3x.png";
import airlineIconA34x from "../../assets/airlines/A3/A3_icon@4x.png";
import airlineIconAQ2x from "../../assets/airlines/AQ/AQ_icon@2x.png";
import airlineIconAQ3x from "../../assets/airlines/AQ/AQ_icon@3x.png";
import airlineIconAQ4x from "../../assets/airlines/AQ/AQ_icon@4x.png";
import airlineIconBH2x from "../../assets/airlines/BH/BH_icon@2x.png";
import airlineIconBH3x from "../../assets/airlines/BH/BH_icon@3x.png";
import airlineIconBH4x from "../../assets/airlines/BH/BH_icon@4x.png";
import airlineIconCA2x from "../../assets/airlines/CA/CA_icon@2x.png";
import airlineIconCA3x from "../../assets/airlines/CA/CA_icon@3x.png";
import airlineIconCA4x from "../../assets/airlines/CA/CA_icon@4x.png";
import airlineIconD52x from "../../assets/airlines/D5/D5_icon@2x.png";
import airlineIconD53x from "../../assets/airlines/D5/D5_icon@3x.png";
import airlineIconD54x from "../../assets/airlines/D5/D5_icon@4x.png";
import airlineIconE42x from "../../assets/airlines/E4/E4_icon@2x.png";
import airlineIconE43x from "../../assets/airlines/E4/E4_icon@3x.png";
import airlineIconE44x from "../../assets/airlines/E4/E4_icon@4x.png";
import airlineIconEV2x from "../../assets/airlines/EV/EV_icon@2x.png";
import airlineIconEV3x from "../../assets/airlines/EV/EV_icon@3x.png";
import airlineIconEV4x from "../../assets/airlines/EV/EV_icon@4x.png";
import airlineIconFK2x from "../../assets/airlines/FK/FK_icon@2x.png";
import airlineIconFK3x from "../../assets/airlines/FK/FK_icon@3x.png";
import airlineIconFK4x from "../../assets/airlines/FK/FK_icon@4x.png";
import airlineIconGB2x from "../../assets/airlines/GB/GB_icon@2x.png";
import airlineIconGB3x from "../../assets/airlines/GB/GB_icon@3x.png";
import airlineIconGB4x from "../../assets/airlines/GB/GB_icon@4x.png";
import airlineIconH72x from "../../assets/airlines/H7/H7_icon@2x.png";
import airlineIconH73x from "../../assets/airlines/H7/H7_icon@3x.png";
import airlineIconH74x from "../../assets/airlines/H7/H7_icon@4x.png";
import airlineIconI22x from "../../assets/airlines/I2/I2_icon@2x.png";
import airlineIconI23x from "../../assets/airlines/I2/I2_icon@3x.png";
import airlineIconI24x from "../../assets/airlines/I2/I2_icon@4x.png";
import airlineIconIU2x from "../../assets/airlines/IU/IU_icon@2x.png";
import airlineIconIU3x from "../../assets/airlines/IU/IU_icon@3x.png";
import airlineIconIU4x from "../../assets/airlines/IU/IU_icon@4x.png";
import airlineIconJK2x from "../../assets/airlines/JK/JK_icon@2x.png";
import airlineIconJK3x from "../../assets/airlines/JK/JK_icon@3x.png";
import airlineIconJK4x from "../../assets/airlines/JK/JK_icon@4x.png";
import airlineIconKD2x from "../../assets/airlines/KD/KD_icon@2x.png";
import airlineIconKD3x from "../../assets/airlines/KD/KD_icon@3x.png";
import airlineIconKD4x from "../../assets/airlines/KD/KD_icon@4x.png";
import airlineIconL62x from "../../assets/airlines/L6/L6_icon@2x.png";
import airlineIconL63x from "../../assets/airlines/L6/L6_icon@3x.png";
import airlineIconL64x from "../../assets/airlines/L6/L6_icon@4x.png";
import airlineIconLV2x from "../../assets/airlines/LV/LV_icon@2x.png";
import airlineIconLV3x from "../../assets/airlines/LV/LV_icon@3x.png";
import airlineIconLV4x from "../../assets/airlines/LV/LV_icon@4x.png";
import airlineIconMM2x from "../../assets/airlines/MM/MM_icon@2x.png";
import airlineIconMM3x from "../../assets/airlines/MM/MM_icon@3x.png";
import airlineIconMM4x from "../../assets/airlines/MM/MM_icon@4x.png";
import airlineIconNC2x from "../../assets/airlines/NC/NC_icon@2x.png";
import airlineIconNC3x from "../../assets/airlines/NC/NC_icon@3x.png";
import airlineIconNC4x from "../../assets/airlines/NC/NC_icon@4x.png";
import airlineIconOC2x from "../../assets/airlines/OC/OC_icon@2x.png";
import airlineIconOC3x from "../../assets/airlines/OC/OC_icon@3x.png";
import airlineIconOC4x from "../../assets/airlines/OC/OC_icon@4x.png";
import airlineIconP12x from "../../assets/airlines/P1/P1_icon@2x.png";
import airlineIconP13x from "../../assets/airlines/P1/P1_icon@3x.png";
import airlineIconP14x from "../../assets/airlines/P1/P1_icon@4x.png";
import airlineIconPQ2x from "../../assets/airlines/PQ/PQ_icon@2x.png";
import airlineIconPQ3x from "../../assets/airlines/PQ/PQ_icon@3x.png";
import airlineIconPQ4x from "../../assets/airlines/PQ/PQ_icon@4x.png";
import airlineIconQH2x from "../../assets/airlines/QH/QH_icon@2x.png";
import airlineIconQH3x from "../../assets/airlines/QH/QH_icon@3x.png";
import airlineIconQH4x from "../../assets/airlines/QH/QH_icon@4x.png";
import airlineIconR92x from "../../assets/airlines/R9/R9_icon@2x.png";
import airlineIconR93x from "../../assets/airlines/R9/R9_icon@3x.png";
import airlineIconR94x from "../../assets/airlines/R9/R9_icon@4x.png";
import airlineIconS02x from "../../assets/airlines/S0/S0_icon@2x.png";
import airlineIconS03x from "../../assets/airlines/S0/S0_icon@3x.png";
import airlineIconS04x from "../../assets/airlines/S0/S0_icon@4x.png";
import airlineIconSP2x from "../../assets/airlines/SP/SP_icon@2x.png";
import airlineIconSP3x from "../../assets/airlines/SP/SP_icon@3x.png";
import airlineIconSP4x from "../../assets/airlines/SP/SP_icon@4x.png";
import airlineIconTG2x from "../../assets/airlines/TG/TG_icon@2x.png";
import airlineIconTG3x from "../../assets/airlines/TG/TG_icon@3x.png";
import airlineIconTG4x from "../../assets/airlines/TG/TG_icon@4x.png";
import airlineIconU62x from "../../assets/airlines/U6/U6_icon@2x.png";
import airlineIconU63x from "../../assets/airlines/U6/U6_icon@3x.png";
import airlineIconU64x from "../../assets/airlines/U6/U6_icon@4x.png";
import airlineIconV02x from "../../assets/airlines/V0/V0_icon@2x.png";
import airlineIconV03x from "../../assets/airlines/V0/V0_icon@3x.png";
import airlineIconV04x from "../../assets/airlines/V0/V0_icon@4x.png";
import airlineIconVT2x from "../../assets/airlines/VT/VT_icon@2x.png";
import airlineIconVT3x from "../../assets/airlines/VT/VT_icon@3x.png";
import airlineIconVT4x from "../../assets/airlines/VT/VT_icon@4x.png";
import airlineIconWK2x from "../../assets/airlines/WK/WK_icon@2x.png";
import airlineIconWK3x from "../../assets/airlines/WK/WK_icon@3x.png";
import airlineIconWK4x from "../../assets/airlines/WK/WK_icon@4x.png";
import airlineIconXF2x from "../../assets/airlines/XF/XF_icon@2x.png";
import airlineIconXF3x from "../../assets/airlines/XF/XF_icon@3x.png";
import airlineIconXF4x from "../../assets/airlines/XF/XF_icon@4x.png";
import airlineIconYI2x from "../../assets/airlines/YI/YI_icon@2x.png";
import airlineIconYI3x from "../../assets/airlines/YI/YI_icon@3x.png";
import airlineIconYI4x from "../../assets/airlines/YI/YI_icon@4x.png";
import airlineIconZG2x from "../../assets/airlines/ZG/ZG_icon@2x.png";
import airlineIconZG3x from "../../assets/airlines/ZG/ZG_icon@3x.png";
import airlineIconZG4x from "../../assets/airlines/ZG/ZG_icon@4x.png";
import airlineIcon2U2x from "../../assets/airlines/2U/2U_icon@2x.png";
import airlineIcon2U3x from "../../assets/airlines/2U/2U_icon@3x.png";
import airlineIcon2U4x from "../../assets/airlines/2U/2U_icon@4x.png";
import airlineIcon3U2x from "../../assets/airlines/3U/3U_icon@2x.png";
import airlineIcon3U3x from "../../assets/airlines/3U/3U_icon@3x.png";
import airlineIcon3U4x from "../../assets/airlines/3U/3U_icon@4x.png";
import airlineIcon4V2x from "../../assets/airlines/4V/4V_icon@2x.png";
import airlineIcon4V3x from "../../assets/airlines/4V/4V_icon@3x.png";
import airlineIcon4V4x from "../../assets/airlines/4V/4V_icon@4x.png";
import airlineIcon5V2x from "../../assets/airlines/5V/5V_icon@2x.png";
import airlineIcon5V3x from "../../assets/airlines/5V/5V_icon@3x.png";
import airlineIcon5V4x from "../../assets/airlines/5V/5V_icon@4x.png";
import airlineIcon7A2x from "../../assets/airlines/7A/7A_icon@2x.png";
import airlineIcon7A3x from "../../assets/airlines/7A/7A_icon@3x.png";
import airlineIcon7A4x from "../../assets/airlines/7A/7A_icon@4x.png";
import airlineIcon8B2x from "../../assets/airlines/8B/8B_icon@2x.png";
import airlineIcon8B3x from "../../assets/airlines/8B/8B_icon@3x.png";
import airlineIcon8B4x from "../../assets/airlines/8B/8B_icon@4x.png";
import airlineIcon9B2x from "../../assets/airlines/9B/9B_icon@2x.png";
import airlineIcon9B3x from "../../assets/airlines/9B/9B_icon@3x.png";
import airlineIcon9B4x from "../../assets/airlines/9B/9B_icon@4x.png";
import airlineIconA42x from "../../assets/airlines/A4/A4_icon@2x.png";
import airlineIconA43x from "../../assets/airlines/A4/A4_icon@3x.png";
import airlineIconA44x from "../../assets/airlines/A4/A4_icon@4x.png";
import airlineIconAR2x from "../../assets/airlines/AR/AR_icon@2x.png";
import airlineIconAR3x from "../../assets/airlines/AR/AR_icon@3x.png";
import airlineIconAR4x from "../../assets/airlines/AR/AR_icon@4x.png";
import airlineIconBI2x from "../../assets/airlines/BI/BI_icon@2x.png";
import airlineIconBI3x from "../../assets/airlines/BI/BI_icon@3x.png";
import airlineIconBI4x from "../../assets/airlines/BI/BI_icon@4x.png";
import airlineIconCC2x from "../../assets/airlines/CC/CC_icon@2x.png";
import airlineIconCC3x from "../../assets/airlines/CC/CC_icon@3x.png";
import airlineIconCC4x from "../../assets/airlines/CC/CC_icon@4x.png";
import airlineIconD62x from "../../assets/airlines/D6/D6_icon@2x.png";
import airlineIconD63x from "../../assets/airlines/D6/D6_icon@3x.png";
import airlineIconD64x from "../../assets/airlines/D6/D6_icon@4x.png";
import airlineIconE52x from "../../assets/airlines/E5/E5_icon@2x.png";
import airlineIconE53x from "../../assets/airlines/E5/E5_icon@3x.png";
import airlineIconE54x from "../../assets/airlines/E5/E5_icon@4x.png";
import airlineIconEW2x from "../../assets/airlines/EW/EW_icon@2x.png";
import airlineIconEW3x from "../../assets/airlines/EW/EW_icon@3x.png";
import airlineIconEW4x from "../../assets/airlines/EW/EW_icon@4x.png";
import airlineIconFM2x from "../../assets/airlines/FM/FM_icon@2x.png";
import airlineIconFM3x from "../../assets/airlines/FM/FM_icon@3x.png";
import airlineIconFM4x from "../../assets/airlines/FM/FM_icon@4x.png";
import airlineIconGC2x from "../../assets/airlines/GC/GC_icon@2x.png";
import airlineIconGC3x from "../../assets/airlines/GC/GC_icon@3x.png";
import airlineIconGC4x from "../../assets/airlines/GC/GC_icon@4x.png";
import airlineIconH82x from "../../assets/airlines/H8/H8_icon@2x.png";
import airlineIconH83x from "../../assets/airlines/H8/H8_icon@3x.png";
import airlineIconH84x from "../../assets/airlines/H8/H8_icon@4x.png";
import airlineIconI42x from "../../assets/airlines/I4/I4_icon@2x.png";
import airlineIconI43x from "../../assets/airlines/I4/I4_icon@3x.png";
import airlineIconI44x from "../../assets/airlines/I4/I4_icon@4x.png";
import airlineIconIV2x from "../../assets/airlines/IV/IV_icon@2x.png";
import airlineIconIV3x from "../../assets/airlines/IV/IV_icon@3x.png";
import airlineIconIV4x from "../../assets/airlines/IV/IV_icon@4x.png";
import airlineIconJL2x from "../../assets/airlines/JL/JL_icon@2x.png";
import airlineIconJL3x from "../../assets/airlines/JL/JL_icon@3x.png";
import airlineIconJL4x from "../../assets/airlines/JL/JL_icon@4x.png";
import airlineIconKE2x from "../../assets/airlines/KE/KE_icon@2x.png";
import airlineIconKE3x from "../../assets/airlines/KE/KE_icon@3x.png";
import airlineIconKE4x from "../../assets/airlines/KE/KE_icon@4x.png";
import airlineIconL72x from "../../assets/airlines/L7/L7_icon@2x.png";
import airlineIconL73x from "../../assets/airlines/L7/L7_icon@3x.png";
import airlineIconL74x from "../../assets/airlines/L7/L7_icon@4x.png";
import airlineIconLW2x from "../../assets/airlines/LW/LW_icon@2x.png";
import airlineIconLW3x from "../../assets/airlines/LW/LW_icon@3x.png";
import airlineIconLW4x from "../../assets/airlines/LW/LW_icon@4x.png";
import airlineIconMN2x from "../../assets/airlines/MN/MN_icon@2x.png";
import airlineIconMN3x from "../../assets/airlines/MN/MN_icon@3x.png";
import airlineIconMN4x from "../../assets/airlines/MN/MN_icon@4x.png";
import airlineIconND2x from "../../assets/airlines/ND/ND_icon@2x.png";
import airlineIconND3x from "../../assets/airlines/ND/ND_icon@3x.png";
import airlineIconND4x from "../../assets/airlines/ND/ND_icon@4x.png";
import airlineIconOD2x from "../../assets/airlines/OD/OD_icon@2x.png";
import airlineIconOD3x from "../../assets/airlines/OD/OD_icon@3x.png";
import airlineIconOD4x from "../../assets/airlines/OD/OD_icon@4x.png";
import airlineIconP22x from "../../assets/airlines/P2/P2_icon@2x.png";
import airlineIconP23x from "../../assets/airlines/P2/P2_icon@3x.png";
import airlineIconP24x from "../../assets/airlines/P2/P2_icon@4x.png";
import airlineIconPR2x from "../../assets/airlines/PR/PR_icon@2x.png";
import airlineIconPR3x from "../../assets/airlines/PR/PR_icon@3x.png";
import airlineIconPR4x from "../../assets/airlines/PR/PR_icon@4x.png";
import airlineIconQI2x from "../../assets/airlines/QI/QI_icon@2x.png";
import airlineIconQI3x from "../../assets/airlines/QI/QI_icon@3x.png";
import airlineIconQI4x from "../../assets/airlines/QI/QI_icon@4x.png";
import airlineIconRA2x from "../../assets/airlines/RA/RA_icon@2x.png";
import airlineIconRA3x from "../../assets/airlines/RA/RA_icon@3x.png";
import airlineIconRA4x from "../../assets/airlines/RA/RA_icon@4x.png";
import airlineIconS22x from "../../assets/airlines/S2/S2_icon@2x.png";
import airlineIconS23x from "../../assets/airlines/S2/S2_icon@3x.png";
import airlineIconS24x from "../../assets/airlines/S2/S2_icon@4x.png";
import airlineIconSQ2x from "../../assets/airlines/SQ/SQ_icon@2x.png";
import airlineIconSQ3x from "../../assets/airlines/SQ/SQ_icon@3x.png";
import airlineIconSQ4x from "../../assets/airlines/SQ/SQ_icon@4x.png";
import airlineIconTH2x from "../../assets/airlines/TH/TH_icon@2x.png";
import airlineIconTH3x from "../../assets/airlines/TH/TH_icon@3x.png";
import airlineIconTH4x from "../../assets/airlines/TH/TH_icon@4x.png";
import airlineIconU82x from "../../assets/airlines/U8/U8_icon@2x.png";
import airlineIconU83x from "../../assets/airlines/U8/U8_icon@3x.png";
import airlineIconU84x from "../../assets/airlines/U8/U8_icon@4x.png";
import airlineIconV22x from "../../assets/airlines/V2/V2_icon@2x.png";
import airlineIconV23x from "../../assets/airlines/V2/V2_icon@3x.png";
import airlineIconV24x from "../../assets/airlines/V2/V2_icon@4x.png";
import airlineIconVU2x from "../../assets/airlines/VU/VU_icon@2x.png";
import airlineIconVU3x from "../../assets/airlines/VU/VU_icon@3x.png";
import airlineIconVU4x from "../../assets/airlines/VU/VU_icon@4x.png";
import airlineIconWM2x from "../../assets/airlines/WM/WM_icon@2x.png";
import airlineIconWM3x from "../../assets/airlines/WM/WM_icon@3x.png";
import airlineIconWM4x from "../../assets/airlines/WM/WM_icon@4x.png";
import airlineIconXG2x from "../../assets/airlines/XG/XG_icon@2x.png";
import airlineIconXG3x from "../../assets/airlines/XG/XG_icon@3x.png";
import airlineIconXG4x from "../../assets/airlines/XG/XG_icon@4x.png";
import airlineIconYK2x from "../../assets/airlines/YK/YK_icon@2x.png";
import airlineIconYK3x from "../../assets/airlines/YK/YK_icon@3x.png";
import airlineIconYK4x from "../../assets/airlines/YK/YK_icon@4x.png";
import airlineIconZH2x from "../../assets/airlines/ZH/ZH_icon@2x.png";
import airlineIconZH3x from "../../assets/airlines/ZH/ZH_icon@3x.png";
import airlineIconZH4x from "../../assets/airlines/ZH/ZH_icon@4x.png";

const iconNameToSourceMap: { [key: string]: string } = {
  "PLACEHOLDER-2x": airlineIconPLACEHOLDER2x,
  "PLACEHOLDER-3x": airlineIconPLACEHOLDER3x,
  "PLACEHOLDER-4x": airlineIconPLACEHOLDER4x,
  "0A-2x": airlineIcon0A2x,
  "0A-3x": airlineIcon0A3x,
  "0A-4x": airlineIcon0A4x,
  "2V-2x": airlineIcon2V2x,
  "2V-3x": airlineIcon2V3x,
  "2V-4x": airlineIcon2V4x,
  "3V-2x": airlineIcon3V2x,
  "3V-3x": airlineIcon3V3x,
  "3V-4x": airlineIcon3V4x,
  "4W-2x": airlineIcon4W2x,
  "4W-3x": airlineIcon4W3x,
  "4W-4x": airlineIcon4W4x,
  "5W-2x": airlineIcon5W2x,
  "5W-3x": airlineIcon5W3x,
  "5W-4x": airlineIcon5W4x,
  "7B-2x": airlineIcon7B2x,
  "7B-3x": airlineIcon7B3x,
  "7B-4x": airlineIcon7B4x,
  "8C-2x": airlineIcon8C2x,
  "8C-3x": airlineIcon8C3x,
  "8C-4x": airlineIcon8C4x,
  "9C-2x": airlineIcon9C2x,
  "9C-3x": airlineIcon9C3x,
  "9C-4x": airlineIcon9C4x,
  "A5-2x": airlineIconA52x,
  "A5-3x": airlineIconA53x,
  "A5-4x": airlineIconA54x,
  "AS-2x": airlineIconAS2x,
  "AS-3x": airlineIconAS3x,
  "AS-4x": airlineIconAS4x,
  "BJ-2x": airlineIconBJ2x,
  "BJ-3x": airlineIconBJ3x,
  "BJ-4x": airlineIconBJ4x,
  "CD-2x": airlineIconCD2x,
  "CD-3x": airlineIconCD3x,
  "CD-4x": airlineIconCD4x,
  "D7-2x": airlineIconD72x,
  "D7-3x": airlineIconD73x,
  "D7-4x": airlineIconD74x,
  "E6-2x": airlineIconE62x,
  "E6-3x": airlineIconE63x,
  "E6-4x": airlineIconE64x,
  "EX-2x": airlineIconEX2x,
  "EX-3x": airlineIconEX3x,
  "EX-4x": airlineIconEX4x,
  "FN-2x": airlineIconFN2x,
  "FN-3x": airlineIconFN3x,
  "FN-4x": airlineIconFN4x,
  "GE-2x": airlineIconGE2x,
  "GE-3x": airlineIconGE3x,
  "GE-4x": airlineIconGE4x,
  "H9-2x": airlineIconH92x,
  "H9-3x": airlineIconH93x,
  "H9-4x": airlineIconH94x,
  "I5-2x": airlineIconI52x,
  "I5-3x": airlineIconI53x,
  "I5-4x": airlineIconI54x,
  "IW-2x": airlineIconIW2x,
  "IW-3x": airlineIconIW3x,
  "IW-4x": airlineIconIW4x,
  "JM-2x": airlineIconJM2x,
  "JM-3x": airlineIconJM3x,
  "JM-4x": airlineIconJM4x,
  "KF-2x": airlineIconKF2x,
  "KF-3x": airlineIconKF3x,
  "KF-4x": airlineIconKF4x,
  "L8-2x": airlineIconL82x,
  "L8-3x": airlineIconL83x,
  "L8-4x": airlineIconL84x,
  "LX-2x": airlineIconLX2x,
  "LX-3x": airlineIconLX3x,
  "LX-4x": airlineIconLX4x,
  "MO-2x": airlineIconMO2x,
  "MO-3x": airlineIconMO3x,
  "MO-4x": airlineIconMO4x,
  "NE-2x": airlineIconNE2x,
  "NE-3x": airlineIconNE3x,
  "NE-4x": airlineIconNE4x,
  "OE-2x": airlineIconOE2x,
  "OE-3x": airlineIconOE3x,
  "OE-4x": airlineIconOE4x,
  "P3-2x": airlineIconP32x,
  "P3-3x": airlineIconP33x,
  "P3-4x": airlineIconP34x,
  "PS-2x": airlineIconPS2x,
  "PS-3x": airlineIconPS3x,
  "PS-4x": airlineIconPS4x,
  "QK-2x": airlineIconQK2x,
  "QK-3x": airlineIconQK3x,
  "QK-4x": airlineIconQK4x,
  "RB-2x": airlineIconRB2x,
  "RB-3x": airlineIconRB3x,
  "RB-4x": airlineIconRB4x,
  "S3-2x": airlineIconS32x,
  "S3-3x": airlineIconS33x,
  "S3-4x": airlineIconS34x,
  "SR-2x": airlineIconSR2x,
  "SR-3x": airlineIconSR3x,
  "SR-4x": airlineIconSR4x,
  "TI-2x": airlineIconTI2x,
  "TI-3x": airlineIconTI3x,
  "TI-4x": airlineIconTI4x,
  "UA-2x": airlineIconUA2x,
  "UA-3x": airlineIconUA3x,
  "UA-4x": airlineIconUA4x,
  "V3-2x": airlineIconV32x,
  "V3-3x": airlineIconV33x,
  "V3-4x": airlineIconV34x,
  "VV-2x": airlineIconVV2x,
  "VV-3x": airlineIconVV3x,
  "VV-4x": airlineIconVV4x,
  "WN-2x": airlineIconWN2x,
  "WN-3x": airlineIconWN3x,
  "WN-4x": airlineIconWN4x,
  "XJ-2x": airlineIconXJ2x,
  "XJ-3x": airlineIconXJ3x,
  "XJ-4x": airlineIconXJ4x,
  "YL-2x": airlineIconYL2x,
  "YL-3x": airlineIconYL3x,
  "YL-4x": airlineIconYL4x,
  "ZI-2x": airlineIconZI2x,
  "ZI-3x": airlineIconZI3x,
  "ZI-4x": airlineIconZI4x,
  "0B-2x": airlineIcon0B2x,
  "0B-3x": airlineIcon0B3x,
  "0B-4x": airlineIcon0B4x,
  "2W-2x": airlineIcon2W2x,
  "2W-3x": airlineIcon2W3x,
  "2W-4x": airlineIcon2W4x,
  "3W-2x": airlineIcon3W2x,
  "3W-3x": airlineIcon3W3x,
  "3W-4x": airlineIcon3W4x,
  "4X-2x": airlineIcon4X2x,
  "4X-3x": airlineIcon4X3x,
  "4X-4x": airlineIcon4X4x,
  "5X-2x": airlineIcon5X2x,
  "5X-3x": airlineIcon5X3x,
  "5X-4x": airlineIcon5X4x,
  "7C-2x": airlineIcon7C2x,
  "7C-3x": airlineIcon7C3x,
  "7C-4x": airlineIcon7C4x,
  "8D-2x": airlineIcon8D2x,
  "8D-3x": airlineIcon8D3x,
  "8D-4x": airlineIcon8D4x,
  "9D-2x": airlineIcon9D2x,
  "9D-3x": airlineIcon9D3x,
  "9D-4x": airlineIcon9D4x,
  "A6-2x": airlineIconA62x,
  "A6-3x": airlineIconA63x,
  "A6-4x": airlineIconA64x,
  "AT-2x": airlineIconAT2x,
  "AT-3x": airlineIconAT3x,
  "AT-4x": airlineIconAT4x,
  "BK-2x": airlineIconBK2x,
  "BK-3x": airlineIconBK3x,
  "BK-4x": airlineIconBK4x,
  "CE-2x": airlineIconCE2x,
  "CE-3x": airlineIconCE3x,
  "CE-4x": airlineIconCE4x,
  "D8-2x": airlineIconD82x,
  "D8-3x": airlineIconD83x,
  "D8-4x": airlineIconD84x,
  "E7-2x": airlineIconE72x,
  "E7-3x": airlineIconE73x,
  "E7-4x": airlineIconE74x,
  "EY-2x": airlineIconEY2x,
  "EY-3x": airlineIconEY3x,
  "EY-4x": airlineIconEY4x,
  "FO-2x": airlineIconFO2x,
  "FO-3x": airlineIconFO3x,
  "FO-4x": airlineIconFO4x,
  "GF-2x": airlineIconGF2x,
  "GF-3x": airlineIconGF3x,
  "GF-4x": airlineIconGF4x,
  "HA-2x": airlineIconHA2x,
  "HA-3x": airlineIconHA3x,
  "HA-4x": airlineIconHA4x,
  "I7-2x": airlineIconI72x,
  "I7-3x": airlineIconI73x,
  "I7-4x": airlineIconI74x,
  "IX-2x": airlineIconIX2x,
  "IX-3x": airlineIconIX3x,
  "IX-4x": airlineIconIX4x,
  "JO-2x": airlineIconJO2x,
  "JO-3x": airlineIconJO3x,
  "JO-4x": airlineIconJO4x,
  "KG-2x": airlineIconKG2x,
  "KG-3x": airlineIconKG3x,
  "KG-4x": airlineIconKG4x,
  "L9-2x": airlineIconL92x,
  "L9-3x": airlineIconL93x,
  "L9-4x": airlineIconL94x,
  "LY-2x": airlineIconLY2x,
  "LY-3x": airlineIconLY3x,
  "LY-4x": airlineIconLY4x,
  "MP-2x": airlineIconMP2x,
  "MP-3x": airlineIconMP3x,
  "MP-4x": airlineIconMP4x,
  "NF-2x": airlineIconNF2x,
  "NF-3x": airlineIconNF3x,
  "NF-4x": airlineIconNF4x,
  "OF-2x": airlineIconOF2x,
  "OF-3x": airlineIconOF3x,
  "OF-4x": airlineIconOF4x,
  "P4-2x": airlineIconP42x,
  "P4-3x": airlineIconP43x,
  "P4-4x": airlineIconP44x,
  "PT-2x": airlineIconPT2x,
  "PT-3x": airlineIconPT3x,
  "PT-4x": airlineIconPT4x,
  "QL-2x": airlineIconQL2x,
  "QL-3x": airlineIconQL3x,
  "QL-4x": airlineIconQL4x,
  "RC-2x": airlineIconRC2x,
  "RC-3x": airlineIconRC3x,
  "RC-4x": airlineIconRC4x,
  "S4-2x": airlineIconS42x,
  "S4-3x": airlineIconS43x,
  "S4-4x": airlineIconS44x,
  "SS-2x": airlineIconSS2x,
  "SS-3x": airlineIconSS3x,
  "SS-4x": airlineIconSS4x,
  "TJ-2x": airlineIconTJ2x,
  "TJ-3x": airlineIconTJ3x,
  "TJ-4x": airlineIconTJ4x,
  "UB-2x": airlineIconUB2x,
  "UB-3x": airlineIconUB3x,
  "UB-4x": airlineIconUB4x,
  "V4-2x": airlineIconV42x,
  "V4-3x": airlineIconV43x,
  "V4-4x": airlineIconV44x,
  "VW-2x": airlineIconVW2x,
  "VW-3x": airlineIconVW3x,
  "VW-4x": airlineIconVW4x,
  "WO-2x": airlineIconWO2x,
  "WO-3x": airlineIconWO3x,
  "WO-4x": airlineIconWO4x,
  "XK-2x": airlineIconXK2x,
  "XK-3x": airlineIconXK3x,
  "XK-4x": airlineIconXK4x,
  "YN-2x": airlineIconYN2x,
  "YN-3x": airlineIconYN3x,
  "YN-4x": airlineIconYN4x,
  "ZJ-2x": airlineIconZJ2x,
  "ZJ-3x": airlineIconZJ3x,
  "ZJ-4x": airlineIconZJ4x,
  "0J-2x": airlineIcon0J2x,
  "0J-3x": airlineIcon0J3x,
  "0J-4x": airlineIcon0J4x,
  "2Y-2x": airlineIcon2Y2x,
  "2Y-3x": airlineIcon2Y3x,
  "2Y-4x": airlineIcon2Y4x,
  "3X-2x": airlineIcon3X2x,
  "3X-3x": airlineIcon3X3x,
  "3X-4x": airlineIcon3X4x,
  "4Y-2x": airlineIcon4Y2x,
  "4Y-3x": airlineIcon4Y3x,
  "4Y-4x": airlineIcon4Y4x,
  "5Y-2x": airlineIcon5Y2x,
  "5Y-3x": airlineIcon5Y3x,
  "5Y-4x": airlineIcon5Y4x,
  "7E-2x": airlineIcon7E2x,
  "7E-3x": airlineIcon7E3x,
  "7E-4x": airlineIcon7E4x,
  "8E-2x": airlineIcon8E2x,
  "8E-3x": airlineIcon8E3x,
  "8E-4x": airlineIcon8E4x,
  "9E-2x": airlineIcon9E2x,
  "9E-3x": airlineIcon9E3x,
  "9E-4x": airlineIcon9E4x,
  "A7-2x": airlineIconA72x,
  "A7-3x": airlineIconA73x,
  "A7-4x": airlineIconA74x,
  "AU-2x": airlineIconAU2x,
  "AU-3x": airlineIconAU3x,
  "AU-4x": airlineIconAU4x,
  "BL-2x": airlineIconBL2x,
  "BL-3x": airlineIconBL3x,
  "BL-4x": airlineIconBL4x,
  "CG-2x": airlineIconCG2x,
  "CG-3x": airlineIconCG3x,
  "CG-4x": airlineIconCG4x,
  "D9-2x": airlineIconD92x,
  "D9-3x": airlineIconD93x,
  "D9-4x": airlineIconD94x,
  "E9-2x": airlineIconE92x,
  "E9-3x": airlineIconE93x,
  "E9-4x": airlineIconE94x,
  "EZ-2x": airlineIconEZ2x,
  "EZ-3x": airlineIconEZ3x,
  "EZ-4x": airlineIconEZ4x,
  "FP-2x": airlineIconFP2x,
  "FP-3x": airlineIconFP3x,
  "FP-4x": airlineIconFP4x,
  "GI-2x": airlineIconGI2x,
  "GI-3x": airlineIconGI3x,
  "GI-4x": airlineIconGI4x,
  "HB-2x": airlineIconHB2x,
  "HB-3x": airlineIconHB3x,
  "HB-4x": airlineIconHB4x,
  "I8-2x": airlineIconI82x,
  "I8-3x": airlineIconI83x,
  "I8-4x": airlineIconI84x,
  "IY-2x": airlineIconIY2x,
  "IY-3x": airlineIconIY3x,
  "IY-4x": airlineIconIY4x,
  "JP-2x": airlineIconJP2x,
  "JP-3x": airlineIconJP3x,
  "JP-4x": airlineIconJP4x,
  "KH-2x": airlineIconKH2x,
  "KH-3x": airlineIconKH3x,
  "KH-4x": airlineIconKH4x,
  "LA-2x": airlineIconLA2x,
  "LA-3x": airlineIconLA3x,
  "LA-4x": airlineIconLA4x,
  "LZ-2x": airlineIconLZ2x,
  "LZ-3x": airlineIconLZ3x,
  "LZ-4x": airlineIconLZ4x,
  "MQ-2x": airlineIconMQ2x,
  "MQ-3x": airlineIconMQ3x,
  "MQ-4x": airlineIconMQ4x,
  "NG-2x": airlineIconNG2x,
  "NG-3x": airlineIconNG3x,
  "NG-4x": airlineIconNG4x,
  "OG-2x": airlineIconOG2x,
  "OG-3x": airlineIconOG3x,
  "OG-4x": airlineIconOG4x,
  "P5-2x": airlineIconP52x,
  "P5-3x": airlineIconP53x,
  "P5-4x": airlineIconP54x,
  "PU-2x": airlineIconPU2x,
  "PU-3x": airlineIconPU3x,
  "PU-4x": airlineIconPU4x,
  "QM-2x": airlineIconQM2x,
  "QM-3x": airlineIconQM3x,
  "QM-4x": airlineIconQM4x,
  "RD-2x": airlineIconRD2x,
  "RD-3x": airlineIconRD3x,
  "RD-4x": airlineIconRD4x,
  "S5-2x": airlineIconS52x,
  "S5-3x": airlineIconS53x,
  "S5-4x": airlineIconS54x,
  "ST-2x": airlineIconST2x,
  "ST-3x": airlineIconST3x,
  "ST-4x": airlineIconST4x,
  "TK-2x": airlineIconTK2x,
  "TK-3x": airlineIconTK3x,
  "TK-4x": airlineIconTK4x,
  "UC-2x": airlineIconUC2x,
  "UC-3x": airlineIconUC3x,
  "UC-4x": airlineIconUC4x,
  "V5-2x": airlineIconV52x,
  "V5-3x": airlineIconV53x,
  "V5-4x": airlineIconV54x,
  "VX-2x": airlineIconVX2x,
  "VX-3x": airlineIconVX3x,
  "VX-4x": airlineIconVX4x,
  "WP-2x": airlineIconWP2x,
  "WP-3x": airlineIconWP3x,
  "WP-4x": airlineIconWP4x,
  "XL-2x": airlineIconXL2x,
  "XL-3x": airlineIconXL3x,
  "XL-4x": airlineIconXL4x,
  "YO-2x": airlineIconYO2x,
  "YO-3x": airlineIconYO3x,
  "YO-4x": airlineIconYO4x,
  "ZK-2x": airlineIconZK2x,
  "ZK-3x": airlineIconZK3x,
  "ZK-4x": airlineIconZK4x,
  "0V-2x": airlineIcon0V2x,
  "0V-3x": airlineIcon0V3x,
  "0V-4x": airlineIcon0V4x,
  "2Z-2x": airlineIcon2Z2x,
  "2Z-3x": airlineIcon2Z3x,
  "2Z-4x": airlineIcon2Z4x,
  "3Z-2x": airlineIcon3Z2x,
  "3Z-3x": airlineIcon3Z3x,
  "3Z-4x": airlineIcon3Z4x,
  "4Z-2x": airlineIcon4Z2x,
  "4Z-3x": airlineIcon4Z3x,
  "4Z-4x": airlineIcon4Z4x,
  "5Z-2x": airlineIcon5Z2x,
  "5Z-3x": airlineIcon5Z3x,
  "5Z-4x": airlineIcon5Z4x,
  "7F-2x": airlineIcon7F2x,
  "7F-3x": airlineIcon7F3x,
  "7F-4x": airlineIcon7F4x,
  "8F-2x": airlineIcon8F2x,
  "8F-3x": airlineIcon8F3x,
  "8F-4x": airlineIcon8F4x,
  "9F-2x": airlineIcon9F2x,
  "9F-3x": airlineIcon9F3x,
  "9F-4x": airlineIcon9F4x,
  "A8-2x": airlineIconA82x,
  "A8-3x": airlineIconA83x,
  "A8-4x": airlineIconA84x,
  "AV-2x": airlineIconAV2x,
  "AV-3x": airlineIconAV3x,
  "AV-4x": airlineIconAV4x,
  "BM-2x": airlineIconBM2x,
  "BM-3x": airlineIconBM3x,
  "BM-4x": airlineIconBM4x,
  "CI-2x": airlineIconCI2x,
  "CI-3x": airlineIconCI3x,
  "CI-4x": airlineIconCI4x,
  "DD-2x": airlineIconDD2x,
  "DD-3x": airlineIconDD3x,
  "DD-4x": airlineIconDD4x,
  "EB-2x": airlineIconEB2x,
  "EB-3x": airlineIconEB3x,
  "EB-4x": airlineIconEB4x,
  "F0-2x": airlineIconF02x,
  "F0-3x": airlineIconF03x,
  "F0-4x": airlineIconF04x,
  "FR-2x": airlineIconFR2x,
  "FR-3x": airlineIconFR3x,
  "FR-4x": airlineIconFR4x,
  "GJ-2x": airlineIconGJ2x,
  "GJ-3x": airlineIconGJ3x,
  "GJ-4x": airlineIconGJ4x,
  "HC-2x": airlineIconHC2x,
  "HC-3x": airlineIconHC3x,
  "HC-4x": airlineIconHC4x,
  "I9-2x": airlineIconI92x,
  "I9-3x": airlineIconI93x,
  "I9-4x": airlineIconI94x,
  "IZ-2x": airlineIconIZ2x,
  "IZ-3x": airlineIconIZ3x,
  "IZ-4x": airlineIconIZ4x,
  "JQ-2x": airlineIconJQ2x,
  "JQ-3x": airlineIconJQ3x,
  "JQ-4x": airlineIconJQ4x,
  "KI-2x": airlineIconKI2x,
  "KI-3x": airlineIconKI3x,
  "KI-4x": airlineIconKI4x,
  "LB-2x": airlineIconLB2x,
  "LB-3x": airlineIconLB3x,
  "LB-4x": airlineIconLB4x,
  "M0-2x": airlineIconM02x,
  "M0-3x": airlineIconM03x,
  "M0-4x": airlineIconM04x,
  "MR-2x": airlineIconMR2x,
  "MR-3x": airlineIconMR3x,
  "MR-4x": airlineIconMR4x,
  "NH-2x": airlineIconNH2x,
  "NH-3x": airlineIconNH3x,
  "NH-4x": airlineIconNH4x,
  "OH-2x": airlineIconOH2x,
  "OH-3x": airlineIconOH3x,
  "OH-4x": airlineIconOH4x,
  "P6-2x": airlineIconP62x,
  "P6-3x": airlineIconP63x,
  "P6-4x": airlineIconP64x,
  "PV-2x": airlineIconPV2x,
  "PV-3x": airlineIconPV3x,
  "PV-4x": airlineIconPV4x,
  "QN-2x": airlineIconQN2x,
  "QN-3x": airlineIconQN3x,
  "QN-4x": airlineIconQN4x,
  "RF-2x": airlineIconRF2x,
  "RF-3x": airlineIconRF3x,
  "RF-4x": airlineIconRF4x,
  "S6-2x": airlineIconS62x,
  "S6-3x": airlineIconS63x,
  "S6-4x": airlineIconS64x,
  "SU-2x": airlineIconSU2x,
  "SU-3x": airlineIconSU3x,
  "SU-4x": airlineIconSU4x,
  "TL-2x": airlineIconTL2x,
  "TL-3x": airlineIconTL3x,
  "TL-4x": airlineIconTL4x,
  "UD-2x": airlineIconUD2x,
  "UD-3x": airlineIconUD3x,
  "UD-4x": airlineIconUD4x,
  "V7-2x": airlineIconV72x,
  "V7-3x": airlineIconV73x,
  "V7-4x": airlineIconV74x,
  "VY-2x": airlineIconVY2x,
  "VY-3x": airlineIconVY3x,
  "VY-4x": airlineIconVY4x,
  "WQ-2x": airlineIconWQ2x,
  "WQ-3x": airlineIconWQ3x,
  "WQ-4x": airlineIconWQ4x,
  "XM-2x": airlineIconXM2x,
  "XM-3x": airlineIconXM3x,
  "XM-4x": airlineIconXM4x,
  "YP-2x": airlineIconYP2x,
  "YP-3x": airlineIconYP3x,
  "YP-4x": airlineIconYP4x,
  "ZL-2x": airlineIconZL2x,
  "ZL-3x": airlineIconZL3x,
  "ZL-4x": airlineIconZL4x,
  "1I-2x": airlineIcon1I2x,
  "1I-3x": airlineIcon1I3x,
  "1I-4x": airlineIcon1I4x,
  "3A-2x": airlineIcon3A2x,
  "3A-3x": airlineIcon3A3x,
  "3A-4x": airlineIcon3A4x,
  "4A-2x": airlineIcon4A2x,
  "4A-3x": airlineIcon4A3x,
  "4A-4x": airlineIcon4A4x,
  "5A-2x": airlineIcon5A2x,
  "5A-3x": airlineIcon5A3x,
  "5A-4x": airlineIcon5A4x,
  "6A-2x": airlineIcon6A2x,
  "6A-3x": airlineIcon6A3x,
  "6A-4x": airlineIcon6A4x,
  "7G-2x": airlineIcon7G2x,
  "7G-3x": airlineIcon7G3x,
  "7G-4x": airlineIcon7G4x,
  "8H-2x": airlineIcon8H2x,
  "8H-3x": airlineIcon8H3x,
  "8H-4x": airlineIcon8H4x,
  "9H-2x": airlineIcon9H2x,
  "9H-3x": airlineIcon9H3x,
  "9H-4x": airlineIcon9H4x,
  "A9-2x": airlineIconA92x,
  "A9-3x": airlineIconA93x,
  "A9-4x": airlineIconA94x,
  "AW-2x": airlineIconAW2x,
  "AW-3x": airlineIconAW3x,
  "AW-4x": airlineIconAW4x,
  "BO-2x": airlineIconBO2x,
  "BO-3x": airlineIconBO3x,
  "BO-4x": airlineIconBO4x,
  "CJ-2x": airlineIconCJ2x,
  "CJ-3x": airlineIconCJ3x,
  "CJ-4x": airlineIconCJ4x,
  "DE-2x": airlineIconDE2x,
  "DE-3x": airlineIconDE3x,
  "DE-4x": airlineIconDE4x,
  "EC-2x": airlineIconEC2x,
  "EC-3x": airlineIconEC3x,
  "EC-4x": airlineIconEC4x,
  "F2-2x": airlineIconF22x,
  "F2-3x": airlineIconF23x,
  "F2-4x": airlineIconF24x,
  "FS-2x": airlineIconFS2x,
  "FS-3x": airlineIconFS3x,
  "FS-4x": airlineIconFS4x,
  "GK-2x": airlineIconGK2x,
  "GK-3x": airlineIconGK3x,
  "GK-4x": airlineIconGK4x,
  "HD-2x": airlineIconHD2x,
  "HD-3x": airlineIconHD3x,
  "HD-4x": airlineIconHD4x,
  "IA-2x": airlineIconIA2x,
  "IA-3x": airlineIconIA3x,
  "IA-4x": airlineIconIA4x,
  "J2-2x": airlineIconJ22x,
  "J2-3x": airlineIconJ23x,
  "J2-4x": airlineIconJ24x,
  "JR-2x": airlineIconJR2x,
  "JR-3x": airlineIconJR3x,
  "JR-4x": airlineIconJR4x,
  "KJ-2x": airlineIconKJ2x,
  "KJ-3x": airlineIconKJ3x,
  "KJ-4x": airlineIconKJ4x,
  "LC-2x": airlineIconLC2x,
  "LC-3x": airlineIconLC3x,
  "LC-4x": airlineIconLC4x,
  "M2-2x": airlineIconM22x,
  "M2-3x": airlineIconM23x,
  "M2-4x": airlineIconM24x,
  "MS-2x": airlineIconMS2x,
  "MS-3x": airlineIconMS3x,
  "MS-4x": airlineIconMS4x,
  "NI-2x": airlineIconNI2x,
  "NI-3x": airlineIconNI3x,
  "NI-4x": airlineIconNI4x,
  "OI-2x": airlineIconOI2x,
  "OI-3x": airlineIconOI3x,
  "OI-4x": airlineIconOI4x,
  "P8-2x": airlineIconP82x,
  "P8-3x": airlineIconP83x,
  "P8-4x": airlineIconP84x,
  "PW-2x": airlineIconPW2x,
  "PW-3x": airlineIconPW3x,
  "PW-4x": airlineIconPW4x,
  "QO-2x": airlineIconQO2x,
  "QO-3x": airlineIconQO3x,
  "QO-4x": airlineIconQO4x,
  "RG-2x": airlineIconRG2x,
  "RG-3x": airlineIconRG3x,
  "RG-4x": airlineIconRG4x,
  "S7-2x": airlineIconS72x,
  "S7-3x": airlineIconS73x,
  "S7-4x": airlineIconS74x,
  "SV-2x": airlineIconSV2x,
  "SV-3x": airlineIconSV3x,
  "SV-4x": airlineIconSV4x,
  "TM-2x": airlineIconTM2x,
  "TM-3x": airlineIconTM3x,
  "TM-4x": airlineIconTM4x,
  "UE-2x": airlineIconUE2x,
  "UE-3x": airlineIconUE3x,
  "UE-4x": airlineIconUE4x,
  "V8-2x": airlineIconV82x,
  "V8-3x": airlineIconV83x,
  "V8-4x": airlineIconV84x,
  "VZ-2x": airlineIconVZ2x,
  "VZ-3x": airlineIconVZ3x,
  "VZ-4x": airlineIconVZ4x,
  "WR-2x": airlineIconWR2x,
  "WR-3x": airlineIconWR3x,
  "WR-4x": airlineIconWR4x,
  "XO-2x": airlineIconXO2x,
  "XO-3x": airlineIconXO3x,
  "XO-4x": airlineIconXO4x,
  "YQ-2x": airlineIconYQ2x,
  "YQ-3x": airlineIconYQ3x,
  "YQ-4x": airlineIconYQ4x,
  "ZM-2x": airlineIconZM2x,
  "ZM-3x": airlineIconZM3x,
  "ZM-4x": airlineIconZM4x,
  "2A-2x": airlineIcon2A2x,
  "2A-3x": airlineIcon2A3x,
  "2A-4x": airlineIcon2A4x,
  "3B-2x": airlineIcon3B2x,
  "3B-3x": airlineIcon3B3x,
  "3B-4x": airlineIcon3B4x,
  "4B-2x": airlineIcon4B2x,
  "4B-3x": airlineIcon4B3x,
  "4B-4x": airlineIcon4B4x,
  "5B-2x": airlineIcon5B2x,
  "5B-3x": airlineIcon5B3x,
  "5B-4x": airlineIcon5B4x,
  "6B-2x": airlineIcon6B2x,
  "6B-3x": airlineIcon6B3x,
  "6B-4x": airlineIcon6B4x,
  "7H-2x": airlineIcon7H2x,
  "7H-3x": airlineIcon7H3x,
  "7H-4x": airlineIcon7H4x,
  "8I-2x": airlineIcon8I2x,
  "8I-3x": airlineIcon8I3x,
  "8I-4x": airlineIcon8I4x,
  "9I-2x": airlineIcon9I2x,
  "9I-3x": airlineIcon9I3x,
  "9I-4x": airlineIcon9I4x,
  "AA-2x": airlineIconAA2x,
  "AA-3x": airlineIconAA3x,
  "AA-4x": airlineIconAA4x,
  "AY-2x": airlineIconAY2x,
  "AY-3x": airlineIconAY3x,
  "AY-4x": airlineIconAY4x,
  "BP-2x": airlineIconBP2x,
  "BP-3x": airlineIconBP3x,
  "BP-4x": airlineIconBP4x,
  "CK-2x": airlineIconCK2x,
  "CK-3x": airlineIconCK3x,
  "CK-4x": airlineIconCK4x,
  "DG-2x": airlineIconDG2x,
  "DG-3x": airlineIconDG3x,
  "DG-4x": airlineIconDG4x,
  "ED-2x": airlineIconED2x,
  "ED-3x": airlineIconED3x,
  "ED-4x": airlineIconED4x,
  "F3-2x": airlineIconF32x,
  "F3-3x": airlineIconF33x,
  "F3-4x": airlineIconF34x,
  "FT-2x": airlineIconFT2x,
  "FT-3x": airlineIconFT3x,
  "FT-4x": airlineIconFT4x,
  "GL-2x": airlineIconGL2x,
  "GL-3x": airlineIconGL3x,
  "GL-4x": airlineIconGL4x,
  "HF-2x": airlineIconHF2x,
  "HF-3x": airlineIconHF3x,
  "HF-4x": airlineIconHF4x,
  "IB-2x": airlineIconIB2x,
  "IB-3x": airlineIconIB3x,
  "IB-4x": airlineIconIB4x,
  "J3-2x": airlineIconJ32x,
  "J3-3x": airlineIconJ33x,
  "J3-4x": airlineIconJ34x,
  "JS-2x": airlineIconJS2x,
  "JS-3x": airlineIconJS3x,
  "JS-4x": airlineIconJS4x,
  "KL-2x": airlineIconKL2x,
  "KL-3x": airlineIconKL3x,
  "KL-4x": airlineIconKL4x,
  "LD-2x": airlineIconLD2x,
  "LD-3x": airlineIconLD3x,
  "LD-4x": airlineIconLD4x,
  "M3-2x": airlineIconM32x,
  "M3-3x": airlineIconM33x,
  "M3-4x": airlineIconM34x,
  "MT-2x": airlineIconMT2x,
  "MT-3x": airlineIconMT3x,
  "MT-4x": airlineIconMT4x,
  "NK-2x": airlineIconNK2x,
  "NK-3x": airlineIconNK3x,
  "NK-4x": airlineIconNK4x,
  "OK-2x": airlineIconOK2x,
  "OK-3x": airlineIconOK3x,
  "OK-4x": airlineIconOK4x,
  "P9-2x": airlineIconP92x,
  "P9-3x": airlineIconP93x,
  "P9-4x": airlineIconP94x,
  "PX-2x": airlineIconPX2x,
  "PX-3x": airlineIconPX3x,
  "PX-4x": airlineIconPX4x,
  "QP-2x": airlineIconQP2x,
  "QP-3x": airlineIconQP3x,
  "QP-4x": airlineIconQP4x,
  "RH-2x": airlineIconRH2x,
  "RH-3x": airlineIconRH3x,
  "RH-4x": airlineIconRH4x,
  "S8-2x": airlineIconS82x,
  "S8-3x": airlineIconS83x,
  "S8-4x": airlineIconS84x,
  "SW-2x": airlineIconSW2x,
  "SW-3x": airlineIconSW3x,
  "SW-4x": airlineIconSW4x,
  "TN-2x": airlineIconTN2x,
  "TN-3x": airlineIconTN3x,
  "TN-4x": airlineIconTN4x,
  "UG-2x": airlineIconUG2x,
  "UG-3x": airlineIconUG3x,
  "UG-4x": airlineIconUG4x,
  "V9-2x": airlineIconV92x,
  "V9-3x": airlineIconV93x,
  "V9-4x": airlineIconV94x,
  "W2-2x": airlineIconW22x,
  "W2-3x": airlineIconW23x,
  "W2-4x": airlineIconW24x,
  "WS-2x": airlineIconWS2x,
  "WS-3x": airlineIconWS3x,
  "WS-4x": airlineIconWS4x,
  "XP-2x": airlineIconXP2x,
  "XP-3x": airlineIconXP3x,
  "XP-4x": airlineIconXP4x,
  "YR-2x": airlineIconYR2x,
  "YR-3x": airlineIconYR3x,
  "YR-4x": airlineIconYR4x,
  "ZN-2x": airlineIconZN2x,
  "ZN-3x": airlineIconZN3x,
  "ZN-4x": airlineIconZN4x,
  "2B-2x": airlineIcon2B2x,
  "2B-3x": airlineIcon2B3x,
  "2B-4x": airlineIcon2B4x,
  "3C-2x": airlineIcon3C2x,
  "3C-3x": airlineIcon3C3x,
  "3C-4x": airlineIcon3C4x,
  "4C-2x": airlineIcon4C2x,
  "4C-3x": airlineIcon4C3x,
  "4C-4x": airlineIcon4C4x,
  "5C-2x": airlineIcon5C2x,
  "5C-3x": airlineIcon5C3x,
  "5C-4x": airlineIcon5C4x,
  "6C-2x": airlineIcon6C2x,
  "6C-3x": airlineIcon6C3x,
  "6C-4x": airlineIcon6C4x,
  "7I-2x": airlineIcon7I2x,
  "7I-3x": airlineIcon7I3x,
  "7I-4x": airlineIcon7I4x,
  "8J-2x": airlineIcon8J2x,
  "8J-3x": airlineIcon8J3x,
  "8J-4x": airlineIcon8J4x,
  "9J-2x": airlineIcon9J2x,
  "9J-3x": airlineIcon9J3x,
  "9J-4x": airlineIcon9J4x,
  "AB-2x": airlineIconAB2x,
  "AB-3x": airlineIconAB3x,
  "AB-4x": airlineIconAB4x,
  "AZ-2x": airlineIconAZ2x,
  "AZ-3x": airlineIconAZ3x,
  "AZ-4x": airlineIconAZ4x,
  "BR-2x": airlineIconBR2x,
  "BR-3x": airlineIconBR3x,
  "BR-4x": airlineIconBR4x,
  "CL-2x": airlineIconCL2x,
  "CL-3x": airlineIconCL3x,
  "CL-4x": airlineIconCL4x,
  "DH-2x": airlineIconDH2x,
  "DH-3x": airlineIconDH3x,
  "DH-4x": airlineIconDH4x,
  "EF-2x": airlineIconEF2x,
  "EF-3x": airlineIconEF3x,
  "EF-4x": airlineIconEF4x,
  "F4-2x": airlineIconF42x,
  "F4-3x": airlineIconF43x,
  "F4-4x": airlineIconF44x,
  "FU-2x": airlineIconFU2x,
  "FU-3x": airlineIconFU3x,
  "FU-4x": airlineIconFU4x,
  "GM-2x": airlineIconGM2x,
  "GM-3x": airlineIconGM3x,
  "GM-4x": airlineIconGM4x,
  "HH-2x": airlineIconHH2x,
  "HH-3x": airlineIconHH3x,
  "HH-4x": airlineIconHH4x,
  "ID-2x": airlineIconID2x,
  "ID-3x": airlineIconID3x,
  "ID-4x": airlineIconID4x,
  "J4-2x": airlineIconJ42x,
  "J4-3x": airlineIconJ43x,
  "J4-4x": airlineIconJ44x,
  "JT-2x": airlineIconJT2x,
  "JT-3x": airlineIconJT3x,
  "JT-4x": airlineIconJT4x,
  "KM-2x": airlineIconKM2x,
  "KM-3x": airlineIconKM3x,
  "KM-4x": airlineIconKM4x,
  "LF-2x": airlineIconLF2x,
  "LF-3x": airlineIconLF3x,
  "LF-4x": airlineIconLF4x,
  "M4-2x": airlineIconM42x,
  "M4-3x": airlineIconM43x,
  "M4-4x": airlineIconM44x,
  "MU-2x": airlineIconMU2x,
  "MU-3x": airlineIconMU3x,
  "MU-4x": airlineIconMU4x,
  "NL-2x": airlineIconNL2x,
  "NL-3x": airlineIconNL3x,
  "NL-4x": airlineIconNL4x,
  "OL-2x": airlineIconOL2x,
  "OL-3x": airlineIconOL3x,
  "OL-4x": airlineIconOL4x,
  "PA-2x": airlineIconPA2x,
  "PA-3x": airlineIconPA3x,
  "PA-4x": airlineIconPA4x,
  "PY-2x": airlineIconPY2x,
  "PY-3x": airlineIconPY3x,
  "PY-4x": airlineIconPY4x,
  "QQ-2x": airlineIconQQ2x,
  "QQ-3x": airlineIconQQ3x,
  "QQ-4x": airlineIconQQ4x,
  "RI-2x": airlineIconRI2x,
  "RI-3x": airlineIconRI3x,
  "RI-4x": airlineIconRI4x,
  "S9-2x": airlineIconS92x,
  "S9-3x": airlineIconS93x,
  "S9-4x": airlineIconS94x,
  "SX-2x": airlineIconSX2x,
  "SX-3x": airlineIconSX3x,
  "SX-4x": airlineIconSX4x,
  "TO-2x": airlineIconTO2x,
  "TO-3x": airlineIconTO3x,
  "TO-4x": airlineIconTO4x,
  "UI-2x": airlineIconUI2x,
  "UI-3x": airlineIconUI3x,
  "UI-4x": airlineIconUI4x,
  "VA-2x": airlineIconVA2x,
  "VA-3x": airlineIconVA3x,
  "VA-4x": airlineIconVA4x,
  "W3-2x": airlineIconW32x,
  "W3-3x": airlineIconW33x,
  "W3-4x": airlineIconW34x,
  "WT-2x": airlineIconWT2x,
  "WT-3x": airlineIconWT3x,
  "WT-4x": airlineIconWT4x,
  "XQ-2x": airlineIconXQ2x,
  "XQ-3x": airlineIconXQ3x,
  "XQ-4x": airlineIconXQ4x,
  "YS-2x": airlineIconYS2x,
  "YS-3x": airlineIconYS3x,
  "YS-4x": airlineIconYS4x,
  "ZP-2x": airlineIconZP2x,
  "ZP-3x": airlineIconZP3x,
  "ZP-4x": airlineIconZP4x,
  "2C-2x": airlineIcon2C2x,
  "2C-3x": airlineIcon2C3x,
  "2C-4x": airlineIcon2C4x,
  "3D-2x": airlineIcon3D2x,
  "3D-3x": airlineIcon3D3x,
  "3D-4x": airlineIcon3D4x,
  "4E-2x": airlineIcon4E2x,
  "4E-3x": airlineIcon4E3x,
  "4E-4x": airlineIcon4E4x,
  "5D-2x": airlineIcon5D2x,
  "5D-3x": airlineIcon5D3x,
  "5D-4x": airlineIcon5D4x,
  "6D-2x": airlineIcon6D2x,
  "6D-3x": airlineIcon6D3x,
  "6D-4x": airlineIcon6D4x,
  "7J-2x": airlineIcon7J2x,
  "7J-3x": airlineIcon7J3x,
  "7J-4x": airlineIcon7J4x,
  "8K-2x": airlineIcon8K2x,
  "8K-3x": airlineIcon8K3x,
  "8K-4x": airlineIcon8K4x,
  "9K-2x": airlineIcon9K2x,
  "9K-3x": airlineIcon9K3x,
  "9K-4x": airlineIcon9K4x,
  "AC-2x": airlineIconAC2x,
  "AC-3x": airlineIconAC3x,
  "AC-4x": airlineIconAC4x,
  "B0-2x": airlineIconB02x,
  "B0-3x": airlineIconB03x,
  "B0-4x": airlineIconB04x,
  "BS-2x": airlineIconBS2x,
  "BS-3x": airlineIconBS3x,
  "BS-4x": airlineIconBS4x,
  "CM-2x": airlineIconCM2x,
  "CM-3x": airlineIconCM3x,
  "CM-4x": airlineIconCM4x,
  "DK-2x": airlineIconDK2x,
  "DK-3x": airlineIconDK3x,
  "DK-4x": airlineIconDK4x,
  "EG-2x": airlineIconEG2x,
  "EG-3x": airlineIconEG3x,
  "EG-4x": airlineIconEG4x,
  "F5-2x": airlineIconF52x,
  "F5-3x": airlineIconF53x,
  "F5-4x": airlineIconF54x,
  "FV-2x": airlineIconFV2x,
  "FV-3x": airlineIconFV3x,
  "FV-4x": airlineIconFV4x,
  "GP-2x": airlineIconGP2x,
  "GP-3x": airlineIconGP3x,
  "GP-4x": airlineIconGP4x,
  "HK-2x": airlineIconHK2x,
  "HK-3x": airlineIconHK3x,
  "HK-4x": airlineIconHK4x,
  "IE-2x": airlineIconIE2x,
  "IE-3x": airlineIconIE3x,
  "IE-4x": airlineIconIE4x,
  "J5-2x": airlineIconJ52x,
  "J5-3x": airlineIconJ53x,
  "J5-4x": airlineIconJ54x,
  "JU-2x": airlineIconJU2x,
  "JU-3x": airlineIconJU3x,
  "JU-4x": airlineIconJU4x,
  "KN-2x": airlineIconKN2x,
  "KN-3x": airlineIconKN3x,
  "KN-4x": airlineIconKN4x,
  "LG-2x": airlineIconLG2x,
  "LG-3x": airlineIconLG3x,
  "LG-4x": airlineIconLG4x,
  "M5-2x": airlineIconM52x,
  "M5-3x": airlineIconM53x,
  "M5-4x": airlineIconM54x,
  "MV-2x": airlineIconMV2x,
  "MV-3x": airlineIconMV3x,
  "MV-4x": airlineIconMV4x,
  "NO-2x": airlineIconNO2x,
  "NO-3x": airlineIconNO3x,
  "NO-4x": airlineIconNO4x,
  "OM-2x": airlineIconOM2x,
  "OM-3x": airlineIconOM3x,
  "OM-4x": airlineIconOM4x,
  "PB-2x": airlineIconPB2x,
  "PB-3x": airlineIconPB3x,
  "PB-4x": airlineIconPB4x,
  "PZ-2x": airlineIconPZ2x,
  "PZ-3x": airlineIconPZ3x,
  "PZ-4x": airlineIconPZ4x,
  "QR-2x": airlineIconQR2x,
  "QR-3x": airlineIconQR3x,
  "QR-4x": airlineIconQR4x,
  "RJ-2x": airlineIconRJ2x,
  "RJ-3x": airlineIconRJ3x,
  "RJ-4x": airlineIconRJ4x,
  "SA-2x": airlineIconSA2x,
  "SA-3x": airlineIconSA3x,
  "SA-4x": airlineIconSA4x,
  "SY-2x": airlineIconSY2x,
  "SY-3x": airlineIconSY3x,
  "SY-4x": airlineIconSY4x,
  "TP-2x": airlineIconTP2x,
  "TP-3x": airlineIconTP3x,
  "TP-4x": airlineIconTP4x,
  "UJ-2x": airlineIconUJ2x,
  "UJ-3x": airlineIconUJ3x,
  "UJ-4x": airlineIconUJ4x,
  "VB-2x": airlineIconVB2x,
  "VB-3x": airlineIconVB3x,
  "VB-4x": airlineIconVB4x,
  "W4-2x": airlineIconW42x,
  "W4-3x": airlineIconW43x,
  "W4-4x": airlineIconW44x,
  "WU-2x": airlineIconWU2x,
  "WU-3x": airlineIconWU3x,
  "WU-4x": airlineIconWU4x,
  "XR-2x": airlineIconXR2x,
  "XR-3x": airlineIconXR3x,
  "XR-4x": airlineIconXR4x,
  "YT-2x": airlineIconYT2x,
  "YT-3x": airlineIconYT3x,
  "YT-4x": airlineIconYT4x,
  "ZR-2x": airlineIconZR2x,
  "ZR-3x": airlineIconZR3x,
  "ZR-4x": airlineIconZR4x,
  "2D-2x": airlineIcon2D2x,
  "2D-3x": airlineIcon2D3x,
  "2D-4x": airlineIcon2D4x,
  "3E-2x": airlineIcon3E2x,
  "3E-3x": airlineIcon3E3x,
  "3E-4x": airlineIcon3E4x,
  "4F-2x": airlineIcon4F2x,
  "4F-3x": airlineIcon4F3x,
  "4F-4x": airlineIcon4F4x,
  "5E-2x": airlineIcon5E2x,
  "5E-3x": airlineIcon5E3x,
  "5E-4x": airlineIcon5E4x,
  "6E-2x": airlineIcon6E2x,
  "6E-3x": airlineIcon6E3x,
  "6E-4x": airlineIcon6E4x,
  "7K-2x": airlineIcon7K2x,
  "7K-3x": airlineIcon7K3x,
  "7K-4x": airlineIcon7K4x,
  "8L-2x": airlineIcon8L2x,
  "8L-3x": airlineIcon8L3x,
  "8L-4x": airlineIcon8L4x,
  "9L-2x": airlineIcon9L2x,
  "9L-3x": airlineIcon9L3x,
  "9L-4x": airlineIcon9L4x,
  "AD-2x": airlineIconAD2x,
  "AD-3x": airlineIconAD3x,
  "AD-4x": airlineIconAD4x,
  "B2-2x": airlineIconB22x,
  "B2-3x": airlineIconB23x,
  "B2-4x": airlineIconB24x,
  "BT-2x": airlineIconBT2x,
  "BT-3x": airlineIconBT3x,
  "BT-4x": airlineIconBT4x,
  "CN-2x": airlineIconCN2x,
  "CN-3x": airlineIconCN3x,
  "CN-4x": airlineIconCN4x,
  "DL-2x": airlineIconDL2x,
  "DL-3x": airlineIconDL3x,
  "DL-4x": airlineIconDL4x,
  "EH-2x": airlineIconEH2x,
  "EH-3x": airlineIconEH3x,
  "EH-4x": airlineIconEH4x,
  "F6-2x": airlineIconF62x,
  "F6-3x": airlineIconF63x,
  "F6-4x": airlineIconF64x,
  "FW-2x": airlineIconFW2x,
  "FW-3x": airlineIconFW3x,
  "FW-4x": airlineIconFW4x,
  "GQ-2x": airlineIconGQ2x,
  "GQ-3x": airlineIconGQ3x,
  "GQ-4x": airlineIconGQ4x,
  "HM-2x": airlineIconHM2x,
  "HM-3x": airlineIconHM3x,
  "HM-4x": airlineIconHM4x,
  "IF-2x": airlineIconIF2x,
  "IF-3x": airlineIconIF3x,
  "IF-4x": airlineIconIF4x,
  "J6-2x": airlineIconJ62x,
  "J6-3x": airlineIconJ63x,
  "J6-4x": airlineIconJ64x,
  "JV-2x": airlineIconJV2x,
  "JV-3x": airlineIconJV3x,
  "JV-4x": airlineIconJV4x,
  "KO-2x": airlineIconKO2x,
  "KO-3x": airlineIconKO3x,
  "KO-4x": airlineIconKO4x,
  "LH-2x": airlineIconLH2x,
  "LH-3x": airlineIconLH3x,
  "LH-4x": airlineIconLH4x,
  "M6-2x": airlineIconM62x,
  "M6-3x": airlineIconM63x,
  "M6-4x": airlineIconM64x,
  "MW-2x": airlineIconMW2x,
  "MW-3x": airlineIconMW3x,
  "MW-4x": airlineIconMW4x,
  "NP-2x": airlineIconNP2x,
  "NP-3x": airlineIconNP3x,
  "NP-4x": airlineIconNP4x,
  "ON-2x": airlineIconON2x,
  "ON-3x": airlineIconON3x,
  "ON-4x": airlineIconON4x,
  "PC-2x": airlineIconPC2x,
  "PC-3x": airlineIconPC3x,
  "PC-4x": airlineIconPC4x,
  "Q2-2x": airlineIconQ22x,
  "Q2-3x": airlineIconQ23x,
  "Q2-4x": airlineIconQ24x,
  "QS-2x": airlineIconQS2x,
  "QS-3x": airlineIconQS3x,
  "QS-4x": airlineIconQS4x,
  "RK-2x": airlineIconRK2x,
  "RK-3x": airlineIconRK3x,
  "RK-4x": airlineIconRK4x,
  "SB-2x": airlineIconSB2x,
  "SB-3x": airlineIconSB3x,
  "SB-4x": airlineIconSB4x,
  "SZ-2x": airlineIconSZ2x,
  "SZ-3x": airlineIconSZ3x,
  "SZ-4x": airlineIconSZ4x,
  "TQ-2x": airlineIconTQ2x,
  "TQ-3x": airlineIconTQ3x,
  "TQ-4x": airlineIconTQ4x,
  "UK-2x": airlineIconUK2x,
  "UK-3x": airlineIconUK3x,
  "UK-4x": airlineIconUK4x,
  "VE-2x": airlineIconVE2x,
  "VE-3x": airlineIconVE3x,
  "VE-4x": airlineIconVE4x,
  "W5-2x": airlineIconW52x,
  "W5-3x": airlineIconW53x,
  "W5-4x": airlineIconW54x,
  "WV-2x": airlineIconWV2x,
  "WV-3x": airlineIconWV3x,
  "WV-4x": airlineIconWV4x,
  "XU-2x": airlineIconXU2x,
  "XU-3x": airlineIconXU3x,
  "XU-4x": airlineIconXU4x,
  "YU-2x": airlineIconYU2x,
  "YU-3x": airlineIconYU3x,
  "YU-4x": airlineIconYU4x,
  "ZT-2x": airlineIconZT2x,
  "ZT-3x": airlineIconZT3x,
  "ZT-4x": airlineIconZT4x,
  "2F-2x": airlineIcon2F2x,
  "2F-3x": airlineIcon2F3x,
  "2F-4x": airlineIcon2F4x,
  "3F-2x": airlineIcon3F2x,
  "3F-3x": airlineIcon3F3x,
  "3F-4x": airlineIcon3F4x,
  "4G-2x": airlineIcon4G2x,
  "4G-3x": airlineIcon4G3x,
  "4G-4x": airlineIcon4G4x,
  "5F-2x": airlineIcon5F2x,
  "5F-3x": airlineIcon5F3x,
  "5F-4x": airlineIcon5F4x,
  "6H-2x": airlineIcon6H2x,
  "6H-3x": airlineIcon6H3x,
  "6H-4x": airlineIcon6H4x,
  "7L-2x": airlineIcon7L2x,
  "7L-3x": airlineIcon7L3x,
  "7L-4x": airlineIcon7L4x,
  "8M-2x": airlineIcon8M2x,
  "8M-3x": airlineIcon8M3x,
  "8M-4x": airlineIcon8M4x,
  "9M-2x": airlineIcon9M2x,
  "9M-3x": airlineIcon9M3x,
  "9M-4x": airlineIcon9M4x,
  "AE-2x": airlineIconAE2x,
  "AE-3x": airlineIconAE3x,
  "AE-4x": airlineIconAE4x,
  "B3-2x": airlineIconB32x,
  "B3-3x": airlineIconB33x,
  "B3-4x": airlineIconB34x,
  "BU-2x": airlineIconBU2x,
  "BU-3x": airlineIconBU3x,
  "BU-4x": airlineIconBU4x,
  "CO-2x": airlineIconCO2x,
  "CO-3x": airlineIconCO3x,
  "CO-4x": airlineIconCO4x,
  "DM-2x": airlineIconDM2x,
  "DM-3x": airlineIconDM3x,
  "DM-4x": airlineIconDM4x,
  "EI-2x": airlineIconEI2x,
  "EI-3x": airlineIconEI3x,
  "EI-4x": airlineIconEI4x,
  "F7-2x": airlineIconF72x,
  "F7-3x": airlineIconF73x,
  "F7-4x": airlineIconF74x,
  "FX-2x": airlineIconFX2x,
  "FX-3x": airlineIconFX3x,
  "FX-4x": airlineIconFX4x,
  "GR-2x": airlineIconGR2x,
  "GR-3x": airlineIconGR3x,
  "GR-4x": airlineIconGR4x,
  "HN-2x": airlineIconHN2x,
  "HN-3x": airlineIconHN3x,
  "HN-4x": airlineIconHN4x,
  "IG-2x": airlineIconIG2x,
  "IG-3x": airlineIconIG3x,
  "IG-4x": airlineIconIG4x,
  "J7-2x": airlineIconJ72x,
  "J7-3x": airlineIconJ73x,
  "J7-4x": airlineIconJ74x,
  "JX-2x": airlineIconJX2x,
  "JX-3x": airlineIconJX3x,
  "JX-4x": airlineIconJX4x,
  "KP-2x": airlineIconKP2x,
  "KP-3x": airlineIconKP3x,
  "KP-4x": airlineIconKP4x,
  "LI-2x": airlineIconLI2x,
  "LI-3x": airlineIconLI3x,
  "LI-4x": airlineIconLI4x,
  "M7-2x": airlineIconM72x,
  "M7-3x": airlineIconM73x,
  "M7-4x": airlineIconM74x,
  "MX-2x": airlineIconMX2x,
  "MX-3x": airlineIconMX3x,
  "MX-4x": airlineIconMX4x,
  "NQ-2x": airlineIconNQ2x,
  "NQ-3x": airlineIconNQ3x,
  "NQ-4x": airlineIconNQ4x,
  "OO-2x": airlineIconOO2x,
  "OO-3x": airlineIconOO3x,
  "OO-4x": airlineIconOO4x,
  "PD-2x": airlineIconPD2x,
  "PD-3x": airlineIconPD3x,
  "PD-4x": airlineIconPD4x,
  "Q3-2x": airlineIconQ32x,
  "Q3-3x": airlineIconQ33x,
  "Q3-4x": airlineIconQ34x,
  "QT-2x": airlineIconQT2x,
  "QT-3x": airlineIconQT3x,
  "QT-4x": airlineIconQT4x,
  "RL-2x": airlineIconRL2x,
  "RL-3x": airlineIconRL3x,
  "RL-4x": airlineIconRL4x,
  "SC-2x": airlineIconSC2x,
  "SC-3x": airlineIconSC3x,
  "SC-4x": airlineIconSC4x,
  "T2-2x": airlineIconT22x,
  "T2-3x": airlineIconT23x,
  "T2-4x": airlineIconT24x,
  "TR-2x": airlineIconTR2x,
  "TR-3x": airlineIconTR3x,
  "TR-4x": airlineIconTR4x,
  "UL-2x": airlineIconUL2x,
  "UL-3x": airlineIconUL3x,
  "UL-4x": airlineIconUL4x,
  "VF-2x": airlineIconVF2x,
  "VF-3x": airlineIconVF3x,
  "VF-4x": airlineIconVF4x,
  "W6-2x": airlineIconW62x,
  "W6-3x": airlineIconW63x,
  "W6-4x": airlineIconW64x,
  "WW-2x": airlineIconWW2x,
  "WW-3x": airlineIconWW3x,
  "WW-4x": airlineIconWW4x,
  "XY-2x": airlineIconXY2x,
  "XY-3x": airlineIconXY3x,
  "XY-4x": airlineIconXY4x,
  "YV-2x": airlineIconYV2x,
  "YV-3x": airlineIconYV3x,
  "YV-4x": airlineIconYV4x,
  "ZW-2x": airlineIconZW2x,
  "ZW-3x": airlineIconZW3x,
  "ZW-4x": airlineIconZW4x,
  "2G-2x": airlineIcon2G2x,
  "2G-3x": airlineIcon2G3x,
  "2G-4x": airlineIcon2G4x,
  "3G-2x": airlineIcon3G2x,
  "3G-3x": airlineIcon3G3x,
  "3G-4x": airlineIcon3G4x,
  "4H-2x": airlineIcon4H2x,
  "4H-3x": airlineIcon4H3x,
  "4H-4x": airlineIcon4H4x,
  "5H-2x": airlineIcon5H2x,
  "5H-3x": airlineIcon5H3x,
  "5H-4x": airlineIcon5H4x,
  "6I-2x": airlineIcon6I2x,
  "6I-3x": airlineIcon6I3x,
  "6I-4x": airlineIcon6I4x,
  "7M-2x": airlineIcon7M2x,
  "7M-3x": airlineIcon7M3x,
  "7M-4x": airlineIcon7M4x,
  "8N-2x": airlineIcon8N2x,
  "8N-3x": airlineIcon8N3x,
  "8N-4x": airlineIcon8N4x,
  "9N-2x": airlineIcon9N2x,
  "9N-3x": airlineIcon9N3x,
  "9N-4x": airlineIcon9N4x,
  "AF-2x": airlineIconAF2x,
  "AF-3x": airlineIconAF3x,
  "AF-4x": airlineIconAF4x,
  "B6-2x": airlineIconB62x,
  "B6-3x": airlineIconB63x,
  "B6-4x": airlineIconB64x,
  "BW-2x": airlineIconBW2x,
  "BW-3x": airlineIconBW3x,
  "BW-4x": airlineIconBW4x,
  "CP-2x": airlineIconCP2x,
  "CP-3x": airlineIconCP3x,
  "CP-4x": airlineIconCP4x,
  "DO-2x": airlineIconDO2x,
  "DO-3x": airlineIconDO3x,
  "DO-4x": airlineIconDO4x,
  "EJ-2x": airlineIconEJ2x,
  "EJ-3x": airlineIconEJ3x,
  "EJ-4x": airlineIconEJ4x,
  "F8-2x": airlineIconF82x,
  "F8-3x": airlineIconF83x,
  "F8-4x": airlineIconF84x,
  "FY-2x": airlineIconFY2x,
  "FY-3x": airlineIconFY3x,
  "FY-4x": airlineIconFY4x,
  "GS-2x": airlineIconGS2x,
  "GS-3x": airlineIconGS3x,
  "GS-4x": airlineIconGS4x,
  "HO-2x": airlineIconHO2x,
  "HO-3x": airlineIconHO3x,
  "HO-4x": airlineIconHO4x,
  "II-2x": airlineIconII2x,
  "II-3x": airlineIconII3x,
  "II-4x": airlineIconII4x,
  "J8-2x": airlineIconJ82x,
  "J8-3x": airlineIconJ83x,
  "J8-4x": airlineIconJ84x,
  "JY-2x": airlineIconJY2x,
  "JY-3x": airlineIconJY3x,
  "JY-4x": airlineIconJY4x,
  "KQ-2x": airlineIconKQ2x,
  "KQ-3x": airlineIconKQ3x,
  "KQ-4x": airlineIconKQ4x,
  "LJ-2x": airlineIconLJ2x,
  "LJ-3x": airlineIconLJ3x,
  "LJ-4x": airlineIconLJ4x,
  "M8-2x": airlineIconM82x,
  "M8-3x": airlineIconM83x,
  "M8-4x": airlineIconM84x,
  "MY-2x": airlineIconMY2x,
  "MY-3x": airlineIconMY3x,
  "MY-4x": airlineIconMY4x,
  "NR-2x": airlineIconNR2x,
  "NR-3x": airlineIconNR3x,
  "NR-4x": airlineIconNR4x,
  "OP-2x": airlineIconOP2x,
  "OP-3x": airlineIconOP3x,
  "OP-4x": airlineIconOP4x,
  "PE-2x": airlineIconPE2x,
  "PE-3x": airlineIconPE3x,
  "PE-4x": airlineIconPE4x,
  "Q5-2x": airlineIconQ52x,
  "Q5-3x": airlineIconQ53x,
  "Q5-4x": airlineIconQ54x,
  "QU-2x": airlineIconQU2x,
  "QU-3x": airlineIconQU3x,
  "QU-4x": airlineIconQU4x,
  "RM-2x": airlineIconRM2x,
  "RM-3x": airlineIconRM3x,
  "RM-4x": airlineIconRM4x,
  "SD-2x": airlineIconSD2x,
  "SD-3x": airlineIconSD3x,
  "SD-4x": airlineIconSD4x,
  "T3-2x": airlineIconT32x,
  "T3-3x": airlineIconT33x,
  "T3-4x": airlineIconT34x,
  "TS-2x": airlineIconTS2x,
  "TS-3x": airlineIconTS3x,
  "TS-4x": airlineIconTS4x,
  "UM-2x": airlineIconUM2x,
  "UM-3x": airlineIconUM3x,
  "UM-4x": airlineIconUM4x,
  "VH-2x": airlineIconVH2x,
  "VH-3x": airlineIconVH3x,
  "VH-4x": airlineIconVH4x,
  "W8-2x": airlineIconW82x,
  "W8-3x": airlineIconW83x,
  "W8-4x": airlineIconW84x,
  "WX-2x": airlineIconWX2x,
  "WX-3x": airlineIconWX3x,
  "WX-4x": airlineIconWX4x,
  "Y2-2x": airlineIconY22x,
  "Y2-3x": airlineIconY23x,
  "Y2-4x": airlineIconY24x,
  "YW-2x": airlineIconYW2x,
  "YW-3x": airlineIconYW3x,
  "YW-4x": airlineIconYW4x,
  "ZX-2x": airlineIconZX2x,
  "ZX-3x": airlineIconZX3x,
  "ZX-4x": airlineIconZX4x,
  "2H-2x": airlineIcon2H2x,
  "2H-3x": airlineIcon2H3x,
  "2H-4x": airlineIcon2H4x,
  "3H-2x": airlineIcon3H2x,
  "3H-3x": airlineIcon3H3x,
  "3H-4x": airlineIcon3H4x,
  "4I-2x": airlineIcon4I2x,
  "4I-3x": airlineIcon4I3x,
  "4I-4x": airlineIcon4I4x,
  "5I-2x": airlineIcon5I2x,
  "5I-3x": airlineIcon5I3x,
  "5I-4x": airlineIcon5I4x,
  "6J-2x": airlineIcon6J2x,
  "6J-3x": airlineIcon6J3x,
  "6J-4x": airlineIcon6J4x,
  "7O-2x": airlineIcon7O2x,
  "7O-3x": airlineIcon7O3x,
  "7O-4x": airlineIcon7O4x,
  "8P-2x": airlineIcon8P2x,
  "8P-3x": airlineIcon8P3x,
  "8P-4x": airlineIcon8P4x,
  "9O-2x": airlineIcon9O2x,
  "9O-3x": airlineIcon9O3x,
  "9O-4x": airlineIcon9O4x,
  "AG-2x": airlineIconAG2x,
  "AG-3x": airlineIconAG3x,
  "AG-4x": airlineIconAG4x,
  "B7-2x": airlineIconB72x,
  "B7-3x": airlineIconB73x,
  "B7-4x": airlineIconB74x,
  "BX-2x": airlineIconBX2x,
  "BX-3x": airlineIconBX3x,
  "BX-4x": airlineIconBX4x,
  "CQ-2x": airlineIconCQ2x,
  "CQ-3x": airlineIconCQ3x,
  "CQ-4x": airlineIconCQ4x,
  "DP-2x": airlineIconDP2x,
  "DP-3x": airlineIconDP3x,
  "DP-4x": airlineIconDP4x,
  "EK-2x": airlineIconEK2x,
  "EK-3x": airlineIconEK3x,
  "EK-4x": airlineIconEK4x,
  "F9-2x": airlineIconF92x,
  "F9-3x": airlineIconF93x,
  "F9-4x": airlineIconF94x,
  "FZ-2x": airlineIconFZ2x,
  "FZ-3x": airlineIconFZ3x,
  "FZ-4x": airlineIconFZ4x,
  "GT-2x": airlineIconGT2x,
  "GT-3x": airlineIconGT3x,
  "GT-4x": airlineIconGT4x,
  "HP-2x": airlineIconHP2x,
  "HP-3x": airlineIconHP3x,
  "HP-4x": airlineIconHP4x,
  "IJ-2x": airlineIconIJ2x,
  "IJ-3x": airlineIconIJ3x,
  "IJ-4x": airlineIconIJ4x,
  "J9-2x": airlineIconJ92x,
  "J9-3x": airlineIconJ93x,
  "J9-4x": airlineIconJ94x,
  "K1-2x": airlineIconK12x,
  "K1-3x": airlineIconK13x,
  "K1-4x": airlineIconK14x,
  "KR-2x": airlineIconKR2x,
  "KR-3x": airlineIconKR3x,
  "KR-4x": airlineIconKR4x,
  "LK-2x": airlineIconLK2x,
  "LK-3x": airlineIconLK3x,
  "LK-4x": airlineIconLK4x,
  "M9-2x": airlineIconM92x,
  "M9-3x": airlineIconM93x,
  "M9-4x": airlineIconM94x,
  "MZ-2x": airlineIconMZ2x,
  "MZ-3x": airlineIconMZ3x,
  "MZ-4x": airlineIconMZ4x,
  "NS-2x": airlineIconNS2x,
  "NS-3x": airlineIconNS3x,
  "NS-4x": airlineIconNS4x,
  "OQ-2x": airlineIconOQ2x,
  "OQ-3x": airlineIconOQ3x,
  "OQ-4x": airlineIconOQ4x,
  "PF-2x": airlineIconPF2x,
  "PF-3x": airlineIconPF3x,
  "PF-4x": airlineIconPF4x,
  "Q6-2x": airlineIconQ62x,
  "Q6-3x": airlineIconQ63x,
  "Q6-4x": airlineIconQ64x,
  "QV-2x": airlineIconQV2x,
  "QV-3x": airlineIconQV3x,
  "QV-4x": airlineIconQV4x,
  "RO-2x": airlineIconRO2x,
  "RO-3x": airlineIconRO3x,
  "RO-4x": airlineIconRO4x,
  "SE-2x": airlineIconSE2x,
  "SE-3x": airlineIconSE3x,
  "SE-4x": airlineIconSE4x,
  "T4-2x": airlineIconT42x,
  "T4-3x": airlineIconT43x,
  "T4-4x": airlineIconT44x,
  "TU-2x": airlineIconTU2x,
  "TU-3x": airlineIconTU3x,
  "TU-4x": airlineIconTU4x,
  "UN-2x": airlineIconUN2x,
  "UN-3x": airlineIconUN3x,
  "UN-4x": airlineIconUN4x,
  "VI-2x": airlineIconVI2x,
  "VI-3x": airlineIconVI3x,
  "VI-4x": airlineIconVI4x,
  "W9-2x": airlineIconW92x,
  "W9-3x": airlineIconW93x,
  "W9-4x": airlineIconW94x,
  "WY-2x": airlineIconWY2x,
  "WY-3x": airlineIconWY3x,
  "WY-4x": airlineIconWY4x,
  "Y4-2x": airlineIconY42x,
  "Y4-3x": airlineIconY43x,
  "Y4-4x": airlineIconY44x,
  "YX-2x": airlineIconYX2x,
  "YX-3x": airlineIconYX3x,
  "YX-4x": airlineIconYX4x,
  "2I-2x": airlineIcon2I2x,
  "2I-3x": airlineIcon2I3x,
  "2I-4x": airlineIcon2I4x,
  "3J-2x": airlineIcon3J2x,
  "3J-3x": airlineIcon3J3x,
  "3J-4x": airlineIcon3J4x,
  "4J-2x": airlineIcon4J2x,
  "4J-3x": airlineIcon4J3x,
  "4J-4x": airlineIcon4J4x,
  "5J-2x": airlineIcon5J2x,
  "5J-3x": airlineIcon5J3x,
  "5J-4x": airlineIcon5J4x,
  "6L-2x": airlineIcon6L2x,
  "6L-3x": airlineIcon6L3x,
  "6L-4x": airlineIcon6L4x,
  "7P-2x": airlineIcon7P2x,
  "7P-3x": airlineIcon7P3x,
  "7P-4x": airlineIcon7P4x,
  "8Q-2x": airlineIcon8Q2x,
  "8Q-3x": airlineIcon8Q3x,
  "8Q-4x": airlineIcon8Q4x,
  "9Q-2x": airlineIcon9Q2x,
  "9Q-3x": airlineIcon9Q3x,
  "9Q-4x": airlineIcon9Q4x,
  "AH-2x": airlineIconAH2x,
  "AH-3x": airlineIconAH3x,
  "AH-4x": airlineIconAH4x,
  "B8-2x": airlineIconB82x,
  "B8-3x": airlineIconB83x,
  "B8-4x": airlineIconB84x,
  "BY-2x": airlineIconBY2x,
  "BY-3x": airlineIconBY3x,
  "BY-4x": airlineIconBY4x,
  "CU-2x": airlineIconCU2x,
  "CU-3x": airlineIconCU3x,
  "CU-4x": airlineIconCU4x,
  "DQ-2x": airlineIconDQ2x,
  "DQ-3x": airlineIconDQ3x,
  "DQ-4x": airlineIconDQ4x,
  "EM-2x": airlineIconEM2x,
  "EM-3x": airlineIconEM3x,
  "EM-4x": airlineIconEM4x,
  "FA-2x": airlineIconFA2x,
  "FA-3x": airlineIconFA3x,
  "FA-4x": airlineIconFA4x,
  "G0-2x": airlineIconG02x,
  "G0-3x": airlineIconG03x,
  "G0-4x": airlineIconG04x,
  "GU-2x": airlineIconGU2x,
  "GU-3x": airlineIconGU3x,
  "GU-4x": airlineIconGU4x,
  "HR-2x": airlineIconHR2x,
  "HR-3x": airlineIconHR3x,
  "HR-4x": airlineIconHR4x,
  "IK-2x": airlineIconIK2x,
  "IK-3x": airlineIconIK3x,
  "IK-4x": airlineIconIK4x,
  "JA-2x": airlineIconJA2x,
  "JA-3x": airlineIconJA3x,
  "JA-4x": airlineIconJA4x,
  "K3-2x": airlineIconK32x,
  "K3-3x": airlineIconK33x,
  "K3-4x": airlineIconK34x,
  "KS-2x": airlineIconKS2x,
  "KS-3x": airlineIconKS3x,
  "KS-4x": airlineIconKS4x,
  "LM-2x": airlineIconLM2x,
  "LM-3x": airlineIconLM3x,
  "LM-4x": airlineIconLM4x,
  "MD-2x": airlineIconMD2x,
  "MD-3x": airlineIconMD3x,
  "MD-4x": airlineIconMD4x,
  "N0-2x": airlineIconN02x,
  "N0-3x": airlineIconN03x,
  "N0-4x": airlineIconN04x,
  "NT-2x": airlineIconNT2x,
  "NT-3x": airlineIconNT3x,
  "NT-4x": airlineIconNT4x,
  "OR-2x": airlineIconOR2x,
  "OR-3x": airlineIconOR3x,
  "OR-4x": airlineIconOR4x,
  "PG-2x": airlineIconPG2x,
  "PG-3x": airlineIconPG3x,
  "PG-4x": airlineIconPG4x,
  "Q7-2x": airlineIconQ72x,
  "Q7-3x": airlineIconQ73x,
  "Q7-4x": airlineIconQ74x,
  "QW-2x": airlineIconQW2x,
  "QW-3x": airlineIconQW3x,
  "QW-4x": airlineIconQW4x,
  "RQ-2x": airlineIconRQ2x,
  "RQ-3x": airlineIconRQ3x,
  "RQ-4x": airlineIconRQ4x,
  "SF-2x": airlineIconSF2x,
  "SF-3x": airlineIconSF3x,
  "SF-4x": airlineIconSF4x,
  "T5-2x": airlineIconT52x,
  "T5-3x": airlineIconT53x,
  "T5-4x": airlineIconT54x,
  "TV-2x": airlineIconTV2x,
  "TV-3x": airlineIconTV3x,
  "TV-4x": airlineIconTV4x,
  "UO-2x": airlineIconUO2x,
  "UO-3x": airlineIconUO3x,
  "UO-4x": airlineIconUO4x,
  "VJ-2x": airlineIconVJ2x,
  "VJ-3x": airlineIconVJ3x,
  "VJ-4x": airlineIconVJ4x,
  "WA-2x": airlineIconWA2x,
  "WA-3x": airlineIconWA3x,
  "WA-4x": airlineIconWA4x,
  "WZ-2x": airlineIconWZ2x,
  "WZ-3x": airlineIconWZ3x,
  "WZ-4x": airlineIconWZ4x,
  "Y5-2x": airlineIconY52x,
  "Y5-3x": airlineIconY53x,
  "Y5-4x": airlineIconY54x,
  "Z0-2x": airlineIconZ02x,
  "Z0-3x": airlineIconZ03x,
  "Z0-4x": airlineIconZ04x,
  "2J-2x": airlineIcon2J2x,
  "2J-3x": airlineIcon2J3x,
  "2J-4x": airlineIcon2J4x,
  "3K-2x": airlineIcon3K2x,
  "3K-3x": airlineIcon3K3x,
  "3K-4x": airlineIcon3K4x,
  "4K-2x": airlineIcon4K2x,
  "4K-3x": airlineIcon4K3x,
  "4K-4x": airlineIcon4K4x,
  "5K-2x": airlineIcon5K2x,
  "5K-3x": airlineIcon5K3x,
  "5K-4x": airlineIcon5K4x,
  "6M-2x": airlineIcon6M2x,
  "6M-3x": airlineIcon6M3x,
  "6M-4x": airlineIcon6M4x,
  "7Q-2x": airlineIcon7Q2x,
  "7Q-3x": airlineIcon7Q3x,
  "7Q-4x": airlineIcon7Q4x,
  "8R-2x": airlineIcon8R2x,
  "8R-3x": airlineIcon8R3x,
  "8R-4x": airlineIcon8R4x,
  "9R-2x": airlineIcon9R2x,
  "9R-3x": airlineIcon9R3x,
  "9R-4x": airlineIcon9R4x,
  "AI-2x": airlineIconAI2x,
  "AI-3x": airlineIconAI3x,
  "AI-4x": airlineIconAI4x,
  "B9-2x": airlineIconB92x,
  "B9-3x": airlineIconB93x,
  "B9-4x": airlineIconB94x,
  "BZ-2x": airlineIconBZ2x,
  "BZ-3x": airlineIconBZ3x,
  "BZ-4x": airlineIconBZ4x,
  "CV-2x": airlineIconCV2x,
  "CV-3x": airlineIconCV3x,
  "CV-4x": airlineIconCV4x,
  "DR-2x": airlineIconDR2x,
  "DR-3x": airlineIconDR3x,
  "DR-4x": airlineIconDR4x,
  "EN-2x": airlineIconEN2x,
  "EN-3x": airlineIconEN3x,
  "EN-4x": airlineIconEN4x,
  "FB-2x": airlineIconFB2x,
  "FB-3x": airlineIconFB3x,
  "FB-4x": airlineIconFB4x,
  "G2-2x": airlineIconG22x,
  "G2-3x": airlineIconG23x,
  "G2-4x": airlineIconG24x,
  "GV-2x": airlineIconGV2x,
  "GV-3x": airlineIconGV3x,
  "GV-4x": airlineIconGV4x,
  "HS-2x": airlineIconHS2x,
  "HS-3x": airlineIconHS3x,
  "HS-4x": airlineIconHS4x,
  "IL-2x": airlineIconIL2x,
  "IL-3x": airlineIconIL3x,
  "IL-4x": airlineIconIL4x,
  "JB-2x": airlineIconJB2x,
  "JB-3x": airlineIconJB3x,
  "JB-4x": airlineIconJB4x,
  "K4-2x": airlineIconK42x,
  "K4-3x": airlineIconK43x,
  "K4-4x": airlineIconK44x,
  "KT-2x": airlineIconKT2x,
  "KT-3x": airlineIconKT3x,
  "KT-4x": airlineIconKT4x,
  "LN-2x": airlineIconLN2x,
  "LN-3x": airlineIconLN3x,
  "LN-4x": airlineIconLN4x,
  "ME-2x": airlineIconME2x,
  "ME-3x": airlineIconME3x,
  "ME-4x": airlineIconME4x,
  "N2-2x": airlineIconN22x,
  "N2-3x": airlineIconN23x,
  "N2-4x": airlineIconN24x,
  "NU-2x": airlineIconNU2x,
  "NU-3x": airlineIconNU3x,
  "NU-4x": airlineIconNU4x,
  "OS-2x": airlineIconOS2x,
  "OS-3x": airlineIconOS3x,
  "OS-4x": airlineIconOS4x,
  "PH-2x": airlineIconPH2x,
  "PH-3x": airlineIconPH3x,
  "PH-4x": airlineIconPH4x,
  "Q8-2x": airlineIconQ82x,
  "Q8-3x": airlineIconQ83x,
  "Q8-4x": airlineIconQ84x,
  "QX-2x": airlineIconQX2x,
  "QX-3x": airlineIconQX3x,
  "QX-4x": airlineIconQX4x,
  "RR-2x": airlineIconRR2x,
  "RR-3x": airlineIconRR3x,
  "RR-4x": airlineIconRR4x,
  "SG-2x": airlineIconSG2x,
  "SG-3x": airlineIconSG3x,
  "SG-4x": airlineIconSG4x,
  "T6-2x": airlineIconT62x,
  "T6-3x": airlineIconT63x,
  "T6-4x": airlineIconT64x,
  "TW-2x": airlineIconTW2x,
  "TW-3x": airlineIconTW3x,
  "TW-4x": airlineIconTW4x,
  "UP-2x": airlineIconUP2x,
  "UP-3x": airlineIconUP3x,
  "UP-4x": airlineIconUP4x,
  "VL-2x": airlineIconVL2x,
  "VL-3x": airlineIconVL3x,
  "VL-4x": airlineIconVL4x,
  "WB-2x": airlineIconWB2x,
  "WB-3x": airlineIconWB3x,
  "WB-4x": airlineIconWB4x,
  "X3-2x": airlineIconX32x,
  "X3-3x": airlineIconX33x,
  "X3-4x": airlineIconX34x,
  "Y7-2x": airlineIconY72x,
  "Y7-3x": airlineIconY73x,
  "Y7-4x": airlineIconY74x,
  "Z2-2x": airlineIconZ22x,
  "Z2-3x": airlineIconZ23x,
  "Z2-4x": airlineIconZ24x,
  "2K-2x": airlineIcon2K2x,
  "2K-3x": airlineIcon2K3x,
  "2K-4x": airlineIcon2K4x,
  "3L-2x": airlineIcon3L2x,
  "3L-3x": airlineIcon3L3x,
  "3L-4x": airlineIcon3L4x,
  "4L-2x": airlineIcon4L2x,
  "4L-3x": airlineIcon4L3x,
  "4L-4x": airlineIcon4L4x,
  "5L-2x": airlineIcon5L2x,
  "5L-3x": airlineIcon5L3x,
  "5L-4x": airlineIcon5L4x,
  "6N-2x": airlineIcon6N2x,
  "6N-3x": airlineIcon6N3x,
  "6N-4x": airlineIcon6N4x,
  "7R-2x": airlineIcon7R2x,
  "7R-3x": airlineIcon7R3x,
  "7R-4x": airlineIcon7R4x,
  "8S-2x": airlineIcon8S2x,
  "8S-3x": airlineIcon8S3x,
  "8S-4x": airlineIcon8S4x,
  "9T-2x": airlineIcon9T2x,
  "9T-3x": airlineIcon9T3x,
  "9T-4x": airlineIcon9T4x,
  "AJ-2x": airlineIconAJ2x,
  "AJ-3x": airlineIconAJ3x,
  "AJ-4x": airlineIconAJ4x,
  "BA-2x": airlineIconBA2x,
  "BA-3x": airlineIconBA3x,
  "BA-4x": airlineIconBA4x,
  "C2-2x": airlineIconC22x,
  "C2-3x": airlineIconC23x,
  "C2-4x": airlineIconC24x,
  "CX-2x": airlineIconCX2x,
  "CX-3x": airlineIconCX3x,
  "CX-4x": airlineIconCX4x,
  "DS-2x": airlineIconDS2x,
  "DS-3x": airlineIconDS3x,
  "DS-4x": airlineIconDS4x,
  "EO-2x": airlineIconEO2x,
  "EO-3x": airlineIconEO3x,
  "EO-4x": airlineIconEO4x,
  "FC-2x": airlineIconFC2x,
  "FC-3x": airlineIconFC3x,
  "FC-4x": airlineIconFC4x,
  "G3-2x": airlineIconG32x,
  "G3-3x": airlineIconG33x,
  "G3-4x": airlineIconG34x,
  "GX-2x": airlineIconGX2x,
  "GX-3x": airlineIconGX3x,
  "GX-4x": airlineIconGX4x,
  "HT-2x": airlineIconHT2x,
  "HT-3x": airlineIconHT3x,
  "HT-4x": airlineIconHT4x,
  "IM-2x": airlineIconIM2x,
  "IM-3x": airlineIconIM3x,
  "IM-4x": airlineIconIM4x,
  "JC-2x": airlineIconJC2x,
  "JC-3x": airlineIconJC3x,
  "JC-4x": airlineIconJC4x,
  "K5-2x": airlineIconK52x,
  "K5-3x": airlineIconK53x,
  "K5-4x": airlineIconK54x,
  "KU-2x": airlineIconKU2x,
  "KU-3x": airlineIconKU3x,
  "KU-4x": airlineIconKU4x,
  "LO-2x": airlineIconLO2x,
  "LO-3x": airlineIconLO3x,
  "LO-4x": airlineIconLO4x,
  "MF-2x": airlineIconMF2x,
  "MF-3x": airlineIconMF3x,
  "MF-4x": airlineIconMF4x,
  "N3-2x": airlineIconN32x,
  "N3-3x": airlineIconN33x,
  "N3-4x": airlineIconN34x,
  "NX-2x": airlineIconNX2x,
  "NX-3x": airlineIconNX3x,
  "NX-4x": airlineIconNX4x,
  "OT-2x": airlineIconOT2x,
  "OT-3x": airlineIconOT3x,
  "OT-4x": airlineIconOT4x,
  "PI-2x": airlineIconPI2x,
  "PI-3x": airlineIconPI3x,
  "PI-4x": airlineIconPI4x,
  "Q9-2x": airlineIconQ92x,
  "Q9-3x": airlineIconQ93x,
  "Q9-4x": airlineIconQ94x,
  "QY-2x": airlineIconQY2x,
  "QY-3x": airlineIconQY3x,
  "QY-4x": airlineIconQY4x,
  "RS-2x": airlineIconRS2x,
  "RS-3x": airlineIconRS3x,
  "RS-4x": airlineIconRS4x,
  "SH-2x": airlineIconSH2x,
  "SH-3x": airlineIconSH3x,
  "SH-4x": airlineIconSH4x,
  "T7-2x": airlineIconT72x,
  "T7-3x": airlineIconT73x,
  "T7-4x": airlineIconT74x,
  "TX-2x": airlineIconTX2x,
  "TX-3x": airlineIconTX3x,
  "TX-4x": airlineIconTX4x,
  "UQ-2x": airlineIconUQ2x,
  "UQ-3x": airlineIconUQ3x,
  "UQ-4x": airlineIconUQ4x,
  "VM-2x": airlineIconVM2x,
  "VM-3x": airlineIconVM3x,
  "VM-4x": airlineIconVM4x,
  "WC-2x": airlineIconWC2x,
  "WC-3x": airlineIconWC3x,
  "WC-4x": airlineIconWC4x,
  "X4-2x": airlineIconX42x,
  "X4-3x": airlineIconX43x,
  "X4-4x": airlineIconX44x,
  "Y8-2x": airlineIconY82x,
  "Y8-3x": airlineIconY83x,
  "Y8-4x": airlineIconY84x,
  "Z5-2x": airlineIconZ52x,
  "Z5-3x": airlineIconZ53x,
  "Z5-4x": airlineIconZ54x,
  "2L-2x": airlineIcon2L2x,
  "2L-3x": airlineIcon2L3x,
  "2L-4x": airlineIcon2L4x,
  "3M-2x": airlineIcon3M2x,
  "3M-3x": airlineIcon3M3x,
  "3M-4x": airlineIcon3M4x,
  "4M-2x": airlineIcon4M2x,
  "4M-3x": airlineIcon4M3x,
  "4M-4x": airlineIcon4M4x,
  "5M-2x": airlineIcon5M2x,
  "5M-3x": airlineIcon5M3x,
  "5M-4x": airlineIcon5M4x,
  "6O-2x": airlineIcon6O2x,
  "6O-3x": airlineIcon6O3x,
  "6O-4x": airlineIcon6O4x,
  "7S-2x": airlineIcon7S2x,
  "7S-3x": airlineIcon7S3x,
  "7S-4x": airlineIcon7S4x,
  "8T-2x": airlineIcon8T2x,
  "8T-3x": airlineIcon8T3x,
  "8T-4x": airlineIcon8T4x,
  "9U-2x": airlineIcon9U2x,
  "9U-3x": airlineIcon9U3x,
  "9U-4x": airlineIcon9U4x,
  "AK-2x": airlineIconAK2x,
  "AK-3x": airlineIconAK3x,
  "AK-4x": airlineIconAK4x,
  "BB-2x": airlineIconBB2x,
  "BB-3x": airlineIconBB3x,
  "BB-4x": airlineIconBB4x,
  "C3-2x": airlineIconC32x,
  "C3-3x": airlineIconC33x,
  "C3-4x": airlineIconC34x,
  "CY-2x": airlineIconCY2x,
  "CY-3x": airlineIconCY3x,
  "CY-4x": airlineIconCY4x,
  "DT-2x": airlineIconDT2x,
  "DT-3x": airlineIconDT3x,
  "DT-4x": airlineIconDT4x,
  "EP-2x": airlineIconEP2x,
  "EP-3x": airlineIconEP3x,
  "EP-4x": airlineIconEP4x,
  "FD-2x": airlineIconFD2x,
  "FD-3x": airlineIconFD3x,
  "FD-4x": airlineIconFD4x,
  "G4-2x": airlineIconG42x,
  "G4-3x": airlineIconG43x,
  "G4-4x": airlineIconG44x,
  "GY-2x": airlineIconGY2x,
  "GY-3x": airlineIconGY3x,
  "GY-4x": airlineIconGY4x,
  "HU-2x": airlineIconHU2x,
  "HU-3x": airlineIconHU3x,
  "HU-4x": airlineIconHU4x,
  "IN-2x": airlineIconIN2x,
  "IN-3x": airlineIconIN3x,
  "IN-4x": airlineIconIN4x,
  "JD-2x": airlineIconJD2x,
  "JD-3x": airlineIconJD3x,
  "JD-4x": airlineIconJD4x,
  "K6-2x": airlineIconK62x,
  "K6-3x": airlineIconK63x,
  "K6-4x": airlineIconK64x,
  "KW-2x": airlineIconKW2x,
  "KW-3x": airlineIconKW3x,
  "KW-4x": airlineIconKW4x,
  "LP-2x": airlineIconLP2x,
  "LP-3x": airlineIconLP3x,
  "LP-4x": airlineIconLP4x,
  "MG-2x": airlineIconMG2x,
  "MG-3x": airlineIconMG3x,
  "MG-4x": airlineIconMG4x,
  "N4-2x": airlineIconN42x,
  "N4-3x": airlineIconN43x,
  "N4-4x": airlineIconN44x,
  "NY-2x": airlineIconNY2x,
  "NY-3x": airlineIconNY3x,
  "NY-4x": airlineIconNY4x,
  "OU-2x": airlineIconOU2x,
  "OU-3x": airlineIconOU3x,
  "OU-4x": airlineIconOU4x,
  "PJ-2x": airlineIconPJ2x,
  "PJ-3x": airlineIconPJ3x,
  "PJ-4x": airlineIconPJ4x,
  "QB-2x": airlineIconQB2x,
  "QB-3x": airlineIconQB3x,
  "QB-4x": airlineIconQB4x,
  "QZ-2x": airlineIconQZ2x,
  "QZ-3x": airlineIconQZ3x,
  "QZ-4x": airlineIconQZ4x,
  "RT-2x": airlineIconRT2x,
  "RT-3x": airlineIconRT3x,
  "RT-4x": airlineIconRT4x,
  "SI-2x": airlineIconSI2x,
  "SI-3x": airlineIconSI3x,
  "SI-4x": airlineIconSI4x,
  "T9-2x": airlineIconT92x,
  "T9-3x": airlineIconT93x,
  "T9-4x": airlineIconT94x,
  "TY-2x": airlineIconTY2x,
  "TY-3x": airlineIconTY3x,
  "TY-4x": airlineIconTY4x,
  "UR-2x": airlineIconUR2x,
  "UR-3x": airlineIconUR3x,
  "UR-4x": airlineIconUR4x,
  "VN-2x": airlineIconVN2x,
  "VN-3x": airlineIconVN3x,
  "VN-4x": airlineIconVN4x,
  "WD-2x": airlineIconWD2x,
  "WD-3x": airlineIconWD3x,
  "WD-4x": airlineIconWD4x,
  "X5-2x": airlineIconX52x,
  "X5-3x": airlineIconX53x,
  "X5-4x": airlineIconX54x,
  "Y9-2x": airlineIconY92x,
  "Y9-3x": airlineIconY93x,
  "Y9-4x": airlineIconY94x,
  "Z8-2x": airlineIconZ82x,
  "Z8-3x": airlineIconZ83x,
  "Z8-4x": airlineIconZ84x,
  "2M-2x": airlineIcon2M2x,
  "2M-3x": airlineIcon2M3x,
  "2M-4x": airlineIcon2M4x,
  "3N-2x": airlineIcon3N2x,
  "3N-3x": airlineIcon3N3x,
  "3N-4x": airlineIcon3N4x,
  "4N-2x": airlineIcon4N2x,
  "4N-3x": airlineIcon4N3x,
  "4N-4x": airlineIcon4N4x,
  "5N-2x": airlineIcon5N2x,
  "5N-3x": airlineIcon5N3x,
  "5N-4x": airlineIcon5N4x,
  "6Q-2x": airlineIcon6Q2x,
  "6Q-3x": airlineIcon6Q3x,
  "6Q-4x": airlineIcon6Q4x,
  "7T-2x": airlineIcon7T2x,
  "7T-3x": airlineIcon7T3x,
  "7T-4x": airlineIcon7T4x,
  "8U-2x": airlineIcon8U2x,
  "8U-3x": airlineIcon8U3x,
  "8U-4x": airlineIcon8U4x,
  "9V-2x": airlineIcon9V2x,
  "9V-3x": airlineIcon9V3x,
  "9V-4x": airlineIcon9V4x,
  "AL-2x": airlineIconAL2x,
  "AL-3x": airlineIconAL3x,
  "AL-4x": airlineIconAL4x,
  "BC-2x": airlineIconBC2x,
  "BC-3x": airlineIconBC3x,
  "BC-4x": airlineIconBC4x,
  "C4-2x": airlineIconC42x,
  "C4-3x": airlineIconC43x,
  "C4-4x": airlineIconC44x,
  "CZ-2x": airlineIconCZ2x,
  "CZ-3x": airlineIconCZ3x,
  "CZ-4x": airlineIconCZ4x,
  "DV-2x": airlineIconDV2x,
  "DV-3x": airlineIconDV3x,
  "DV-4x": airlineIconDV4x,
  "EQ-2x": airlineIconEQ2x,
  "EQ-3x": airlineIconEQ3x,
  "EQ-4x": airlineIconEQ4x,
  "FE-2x": airlineIconFE2x,
  "FE-3x": airlineIconFE3x,
  "FE-4x": airlineIconFE4x,
  "G5-2x": airlineIconG52x,
  "G5-3x": airlineIconG53x,
  "G5-4x": airlineIconG54x,
  "GZ-2x": airlineIconGZ2x,
  "GZ-3x": airlineIconGZ3x,
  "GZ-4x": airlineIconGZ4x,
  "HV-2x": airlineIconHV2x,
  "HV-3x": airlineIconHV3x,
  "HV-4x": airlineIconHV4x,
  "IO-2x": airlineIconIO2x,
  "IO-3x": airlineIconIO3x,
  "IO-4x": airlineIconIO4x,
  "JE-2x": airlineIconJE2x,
  "JE-3x": airlineIconJE3x,
  "JE-4x": airlineIconJE4x,
  "K7-2x": airlineIconK72x,
  "K7-3x": airlineIconK73x,
  "K7-4x": airlineIconK74x,
  "KX-2x": airlineIconKX2x,
  "KX-3x": airlineIconKX3x,
  "KX-4x": airlineIconKX4x,
  "LQ-2x": airlineIconLQ2x,
  "LQ-3x": airlineIconLQ3x,
  "LQ-4x": airlineIconLQ4x,
  "MH-2x": airlineIconMH2x,
  "MH-3x": airlineIconMH3x,
  "MH-4x": airlineIconMH4x,
  "N5-2x": airlineIconN52x,
  "N5-3x": airlineIconN53x,
  "N5-4x": airlineIconN54x,
  "NZ-2x": airlineIconNZ2x,
  "NZ-3x": airlineIconNZ3x,
  "NZ-4x": airlineIconNZ4x,
  "OV-2x": airlineIconOV2x,
  "OV-3x": airlineIconOV3x,
  "OV-4x": airlineIconOV4x,
  "PK-2x": airlineIconPK2x,
  "PK-3x": airlineIconPK3x,
  "PK-4x": airlineIconPK4x,
  "QC-2x": airlineIconQC2x,
  "QC-3x": airlineIconQC3x,
  "QC-4x": airlineIconQC4x,
  "R2-2x": airlineIconR22x,
  "R2-3x": airlineIconR23x,
  "R2-4x": airlineIconR24x,
  "RU-2x": airlineIconRU2x,
  "RU-3x": airlineIconRU3x,
  "RU-4x": airlineIconRU4x,
  "SJ-2x": airlineIconSJ2x,
  "SJ-3x": airlineIconSJ3x,
  "SJ-4x": airlineIconSJ4x,
  "TA-2x": airlineIconTA2x,
  "TA-3x": airlineIconTA3x,
  "TA-4x": airlineIconTA4x,
  "TZ-2x": airlineIconTZ2x,
  "TZ-3x": airlineIconTZ3x,
  "TZ-4x": airlineIconTZ4x,
  "US-2x": airlineIconUS2x,
  "US-3x": airlineIconUS3x,
  "US-4x": airlineIconUS4x,
  "VO-2x": airlineIconVO2x,
  "VO-3x": airlineIconVO3x,
  "VO-4x": airlineIconVO4x,
  "WE-2x": airlineIconWE2x,
  "WE-3x": airlineIconWE3x,
  "WE-4x": airlineIconWE4x,
  "X7-2x": airlineIconX72x,
  "X7-3x": airlineIconX73x,
  "X7-4x": airlineIconX74x,
  "YB-2x": airlineIconYB2x,
  "YB-3x": airlineIconYB3x,
  "YB-4x": airlineIconYB4x,
  "Z9-2x": airlineIconZ92x,
  "Z9-3x": airlineIconZ93x,
  "Z9-4x": airlineIconZ94x,
  "2N-2x": airlineIcon2N2x,
  "2N-3x": airlineIcon2N3x,
  "2N-4x": airlineIcon2N4x,
  "3O-2x": airlineIcon3O2x,
  "3O-3x": airlineIcon3O3x,
  "3O-4x": airlineIcon3O4x,
  "4Q-2x": airlineIcon4Q2x,
  "4Q-3x": airlineIcon4Q3x,
  "4Q-4x": airlineIcon4Q4x,
  "5O-2x": airlineIcon5O2x,
  "5O-3x": airlineIcon5O3x,
  "5O-4x": airlineIcon5O4x,
  "6R-2x": airlineIcon6R2x,
  "6R-3x": airlineIcon6R3x,
  "6R-4x": airlineIcon6R4x,
  "7U-2x": airlineIcon7U2x,
  "7U-3x": airlineIcon7U3x,
  "7U-4x": airlineIcon7U4x,
  "8V-2x": airlineIcon8V2x,
  "8V-3x": airlineIcon8V3x,
  "8V-4x": airlineIcon8V4x,
  "9W-2x": airlineIcon9W2x,
  "9W-3x": airlineIcon9W3x,
  "9W-4x": airlineIcon9W4x,
  "AM-2x": airlineIconAM2x,
  "AM-3x": airlineIconAM3x,
  "AM-4x": airlineIconAM4x,
  "BD-2x": airlineIconBD2x,
  "BD-3x": airlineIconBD3x,
  "BD-4x": airlineIconBD4x,
  "C5-2x": airlineIconC52x,
  "C5-3x": airlineIconC53x,
  "C5-4x": airlineIconC54x,
  "D0-2x": airlineIconD02x,
  "D0-3x": airlineIconD03x,
  "D0-4x": airlineIconD04x,
  "DX-2x": airlineIconDX2x,
  "DX-3x": airlineIconDX3x,
  "DX-4x": airlineIconDX4x,
  "ER-2x": airlineIconER2x,
  "ER-3x": airlineIconER3x,
  "ER-4x": airlineIconER4x,
  "FG-2x": airlineIconFG2x,
  "FG-3x": airlineIconFG3x,
  "FG-4x": airlineIconFG4x,
  "G7-2x": airlineIconG72x,
  "G7-3x": airlineIconG73x,
  "G7-4x": airlineIconG74x,
  "H1-2x": airlineIconH12x,
  "H1-3x": airlineIconH13x,
  "H1-4x": airlineIconH14x,
  "HW-2x": airlineIconHW2x,
  "HW-3x": airlineIconHW3x,
  "HW-4x": airlineIconHW4x,
  "IQ-2x": airlineIconIQ2x,
  "IQ-3x": airlineIconIQ3x,
  "IQ-4x": airlineIconIQ4x,
  "JF-2x": airlineIconJF2x,
  "JF-3x": airlineIconJF3x,
  "JF-4x": airlineIconJF4x,
  "K8-2x": airlineIconK82x,
  "K8-3x": airlineIconK83x,
  "K8-4x": airlineIconK84x,
  "KY-2x": airlineIconKY2x,
  "KY-3x": airlineIconKY3x,
  "KY-4x": airlineIconKY4x,
  "LR-2x": airlineIconLR2x,
  "LR-3x": airlineIconLR3x,
  "LR-4x": airlineIconLR4x,
  "MI-2x": airlineIconMI2x,
  "MI-3x": airlineIconMI3x,
  "MI-4x": airlineIconMI4x,
  "N7-2x": airlineIconN72x,
  "N7-3x": airlineIconN73x,
  "N7-4x": airlineIconN74x,
  "O3-2x": airlineIconO32x,
  "O3-3x": airlineIconO33x,
  "O3-4x": airlineIconO34x,
  "OW-2x": airlineIconOW2x,
  "OW-3x": airlineIconOW3x,
  "OW-4x": airlineIconOW4x,
  "PL-2x": airlineIconPL2x,
  "PL-3x": airlineIconPL3x,
  "PL-4x": airlineIconPL4x,
  "QD-2x": airlineIconQD2x,
  "QD-3x": airlineIconQD3x,
  "QD-4x": airlineIconQD4x,
  "R3-2x": airlineIconR32x,
  "R3-3x": airlineIconR33x,
  "R3-4x": airlineIconR34x,
  "RV-2x": airlineIconRV2x,
  "RV-3x": airlineIconRV3x,
  "RV-4x": airlineIconRV4x,
  "SK-2x": airlineIconSK2x,
  "SK-3x": airlineIconSK3x,
  "SK-4x": airlineIconSK4x,
  "TB-2x": airlineIconTB2x,
  "TB-3x": airlineIconTB3x,
  "TB-4x": airlineIconTB4x,
  "U2-2x": airlineIconU22x,
  "U2-3x": airlineIconU23x,
  "U2-4x": airlineIconU24x,
  "UT-2x": airlineIconUT2x,
  "UT-3x": airlineIconUT3x,
  "UT-4x": airlineIconUT4x,
  "VP-2x": airlineIconVP2x,
  "VP-3x": airlineIconVP3x,
  "VP-4x": airlineIconVP4x,
  "WF-2x": airlineIconWF2x,
  "WF-3x": airlineIconWF3x,
  "WF-4x": airlineIconWF4x,
  "X8-2x": airlineIconX82x,
  "X8-3x": airlineIconX83x,
  "X8-4x": airlineIconX84x,
  "YC-2x": airlineIconYC2x,
  "YC-3x": airlineIconYC3x,
  "YC-4x": airlineIconYC4x,
  "ZA-2x": airlineIconZA2x,
  "ZA-3x": airlineIconZA3x,
  "ZA-4x": airlineIconZA4x,
  "2O-2x": airlineIcon2O2x,
  "2O-3x": airlineIcon2O3x,
  "2O-4x": airlineIcon2O4x,
  "3P-2x": airlineIcon3P2x,
  "3P-3x": airlineIcon3P3x,
  "3P-4x": airlineIcon3P4x,
  "4R-2x": airlineIcon4R2x,
  "4R-3x": airlineIcon4R3x,
  "4R-4x": airlineIcon4R4x,
  "5R-2x": airlineIcon5R2x,
  "5R-3x": airlineIcon5R3x,
  "5R-4x": airlineIcon5R4x,
  "6V-2x": airlineIcon6V2x,
  "6V-3x": airlineIcon6V3x,
  "6V-4x": airlineIcon6V4x,
  "7V-2x": airlineIcon7V2x,
  "7V-3x": airlineIcon7V3x,
  "7V-4x": airlineIcon7V4x,
  "8W-2x": airlineIcon8W2x,
  "8W-3x": airlineIcon8W3x,
  "8W-4x": airlineIcon8W4x,
  "9X-2x": airlineIcon9X2x,
  "9X-3x": airlineIcon9X3x,
  "9X-4x": airlineIcon9X4x,
  "AN-2x": airlineIconAN2x,
  "AN-3x": airlineIconAN3x,
  "AN-4x": airlineIconAN4x,
  "BE-2x": airlineIconBE2x,
  "BE-3x": airlineIconBE3x,
  "BE-4x": airlineIconBE4x,
  "C6-2x": airlineIconC62x,
  "C6-3x": airlineIconC63x,
  "C6-4x": airlineIconC64x,
  "D2-2x": airlineIconD22x,
  "D2-3x": airlineIconD23x,
  "D2-4x": airlineIconD24x,
  "DY-2x": airlineIconDY2x,
  "DY-3x": airlineIconDY3x,
  "DY-4x": airlineIconDY4x,
  "ES-2x": airlineIconES2x,
  "ES-3x": airlineIconES3x,
  "ES-4x": airlineIconES4x,
  "FH-2x": airlineIconFH2x,
  "FH-3x": airlineIconFH3x,
  "FH-4x": airlineIconFH4x,
  "G8-2x": airlineIconG82x,
  "G8-3x": airlineIconG83x,
  "G8-4x": airlineIconG84x,
  "H2-2x": airlineIconH22x,
  "H2-3x": airlineIconH23x,
  "H2-4x": airlineIconH24x,
  "HX-2x": airlineIconHX2x,
  "HX-3x": airlineIconHX3x,
  "HX-4x": airlineIconHX4x,
  "IR-2x": airlineIconIR2x,
  "IR-3x": airlineIconIR3x,
  "IR-4x": airlineIconIR4x,
  "JG-2x": airlineIconJG2x,
  "JG-3x": airlineIconJG3x,
  "JG-4x": airlineIconJG4x,
  "KA-2x": airlineIconKA2x,
  "KA-3x": airlineIconKA3x,
  "KA-4x": airlineIconKA4x,
  "KZ-2x": airlineIconKZ2x,
  "KZ-3x": airlineIconKZ3x,
  "KZ-4x": airlineIconKZ4x,
  "LS-2x": airlineIconLS2x,
  "LS-3x": airlineIconLS3x,
  "LS-4x": airlineIconLS4x,
  "MJ-2x": airlineIconMJ2x,
  "MJ-3x": airlineIconMJ3x,
  "MJ-4x": airlineIconMJ4x,
  "N9-2x": airlineIconN92x,
  "N9-3x": airlineIconN93x,
  "N9-4x": airlineIconN94x,
  "O6-2x": airlineIconO62x,
  "O6-3x": airlineIconO63x,
  "O6-4x": airlineIconO64x,
  "OY-2x": airlineIconOY2x,
  "OY-3x": airlineIconOY3x,
  "OY-4x": airlineIconOY4x,
  "PM-2x": airlineIconPM2x,
  "PM-3x": airlineIconPM3x,
  "PM-4x": airlineIconPM4x,
  "QE-2x": airlineIconQE2x,
  "QE-3x": airlineIconQE3x,
  "QE-4x": airlineIconQE4x,
  "R5-2x": airlineIconR52x,
  "R5-3x": airlineIconR53x,
  "R5-4x": airlineIconR54x,
  "RW-2x": airlineIconRW2x,
  "RW-3x": airlineIconRW3x,
  "RW-4x": airlineIconRW4x,
  "SL-2x": airlineIconSL2x,
  "SL-3x": airlineIconSL3x,
  "SL-4x": airlineIconSL4x,
  "TC-2x": airlineIconTC2x,
  "TC-3x": airlineIconTC3x,
  "TC-4x": airlineIconTC4x,
  "U3-2x": airlineIconU32x,
  "U3-3x": airlineIconU33x,
  "U3-4x": airlineIconU34x,
  "UU-2x": airlineIconUU2x,
  "UU-3x": airlineIconUU3x,
  "UU-4x": airlineIconUU4x,
  "VQ-2x": airlineIconVQ2x,
  "VQ-3x": airlineIconVQ3x,
  "VQ-4x": airlineIconVQ4x,
  "WG-2x": airlineIconWG2x,
  "WG-3x": airlineIconWG3x,
  "WG-4x": airlineIconWG4x,
  "X9-2x": airlineIconX92x,
  "X9-3x": airlineIconX93x,
  "X9-4x": airlineIconX94x,
  "YE-2x": airlineIconYE2x,
  "YE-3x": airlineIconYE3x,
  "YE-4x": airlineIconYE4x,
  "ZB-2x": airlineIconZB2x,
  "ZB-3x": airlineIconZB3x,
  "ZB-4x": airlineIconZB4x,
  "2P-2x": airlineIcon2P2x,
  "2P-3x": airlineIcon2P3x,
  "2P-4x": airlineIcon2P4x,
  "3R-2x": airlineIcon3R2x,
  "3R-3x": airlineIcon3R3x,
  "3R-4x": airlineIcon3R4x,
  "4S-2x": airlineIcon4S2x,
  "4S-3x": airlineIcon4S3x,
  "4S-4x": airlineIcon4S4x,
  "5S-2x": airlineIcon5S2x,
  "5S-3x": airlineIcon5S3x,
  "5S-4x": airlineIcon5S4x,
  "6W-2x": airlineIcon6W2x,
  "6W-3x": airlineIcon6W3x,
  "6W-4x": airlineIcon6W4x,
  "7W-2x": airlineIcon7W2x,
  "7W-3x": airlineIcon7W3x,
  "7W-4x": airlineIcon7W4x,
  "8Y-2x": airlineIcon8Y2x,
  "8Y-3x": airlineIcon8Y3x,
  "8Y-4x": airlineIcon8Y4x,
  "A1-2x": airlineIconA12x,
  "A1-3x": airlineIconA13x,
  "A1-4x": airlineIconA14x,
  "AO-2x": airlineIconAO2x,
  "AO-3x": airlineIconAO3x,
  "AO-4x": airlineIconAO4x,
  "BF-2x": airlineIconBF2x,
  "BF-3x": airlineIconBF3x,
  "BF-4x": airlineIconBF4x,
  "C7-2x": airlineIconC72x,
  "C7-3x": airlineIconC73x,
  "C7-4x": airlineIconC74x,
  "D3-2x": airlineIconD32x,
  "D3-3x": airlineIconD33x,
  "D3-4x": airlineIconD34x,
  "DZ-2x": airlineIconDZ2x,
  "DZ-3x": airlineIconDZ3x,
  "DZ-4x": airlineIconDZ4x,
  "ET-2x": airlineIconET2x,
  "ET-3x": airlineIconET3x,
  "ET-4x": airlineIconET4x,
  "FI-2x": airlineIconFI2x,
  "FI-3x": airlineIconFI3x,
  "FI-4x": airlineIconFI4x,
  "G9-2x": airlineIconG92x,
  "G9-3x": airlineIconG93x,
  "G9-4x": airlineIconG94x,
  "H4-2x": airlineIconH42x,
  "H4-3x": airlineIconH43x,
  "H4-4x": airlineIconH44x,
  "HY-2x": airlineIconHY2x,
  "HY-3x": airlineIconHY3x,
  "HY-4x": airlineIconHY4x,
  "IS-2x": airlineIconIS2x,
  "IS-3x": airlineIconIS3x,
  "IS-4x": airlineIconIS4x,
  "JH-2x": airlineIconJH2x,
  "JH-3x": airlineIconJH3x,
  "JH-4x": airlineIconJH4x,
  "KB-2x": airlineIconKB2x,
  "KB-3x": airlineIconKB3x,
  "KB-4x": airlineIconKB4x,
  "L2-2x": airlineIconL22x,
  "L2-3x": airlineIconL23x,
  "L2-4x": airlineIconL24x,
  "LT-2x": airlineIconLT2x,
  "LT-3x": airlineIconLT3x,
  "LT-4x": airlineIconLT4x,
  "MK-2x": airlineIconMK2x,
  "MK-3x": airlineIconMK3x,
  "MK-4x": airlineIconMK4x,
  "NA-2x": airlineIconNA2x,
  "NA-3x": airlineIconNA3x,
  "NA-4x": airlineIconNA4x,
  "OA-2x": airlineIconOA2x,
  "OA-3x": airlineIconOA3x,
  "OA-4x": airlineIconOA4x,
  "OZ-2x": airlineIconOZ2x,
  "OZ-3x": airlineIconOZ3x,
  "OZ-4x": airlineIconOZ4x,
  "PN-2x": airlineIconPN2x,
  "PN-3x": airlineIconPN3x,
  "PN-4x": airlineIconPN4x,
  "QF-2x": airlineIconQF2x,
  "QF-3x": airlineIconQF3x,
  "QF-4x": airlineIconQF4x,
  "R6-2x": airlineIconR62x,
  "R6-3x": airlineIconR63x,
  "R6-4x": airlineIconR64x,
  "RY-2x": airlineIconRY2x,
  "RY-3x": airlineIconRY3x,
  "RY-4x": airlineIconRY4x,
  "SM-2x": airlineIconSM2x,
  "SM-3x": airlineIconSM3x,
  "SM-4x": airlineIconSM4x,
  "TE-2x": airlineIconTE2x,
  "TE-3x": airlineIconTE3x,
  "TE-4x": airlineIconTE4x,
  "U4-2x": airlineIconU42x,
  "U4-3x": airlineIconU43x,
  "U4-4x": airlineIconU44x,
  "UX-2x": airlineIconUX2x,
  "UX-3x": airlineIconUX3x,
  "UX-4x": airlineIconUX4x,
  "VR-2x": airlineIconVR2x,
  "VR-3x": airlineIconVR3x,
  "VR-4x": airlineIconVR4x,
  "WI-2x": airlineIconWI2x,
  "WI-3x": airlineIconWI3x,
  "WI-4x": airlineIconWI4x,
  "XC-2x": airlineIconXC2x,
  "XC-3x": airlineIconXC3x,
  "XC-4x": airlineIconXC4x,
  "YG-2x": airlineIconYG2x,
  "YG-3x": airlineIconYG3x,
  "YG-4x": airlineIconYG4x,
  "ZD-2x": airlineIconZD2x,
  "ZD-3x": airlineIconZD3x,
  "ZD-4x": airlineIconZD4x,
  "2Q-2x": airlineIcon2Q2x,
  "2Q-3x": airlineIcon2Q3x,
  "2Q-4x": airlineIcon2Q4x,
  "3S-2x": airlineIcon3S2x,
  "3S-3x": airlineIcon3S3x,
  "3S-4x": airlineIcon3S4x,
  "4T-2x": airlineIcon4T2x,
  "4T-3x": airlineIcon4T3x,
  "4T-4x": airlineIcon4T4x,
  "5T-2x": airlineIcon5T2x,
  "5T-3x": airlineIcon5T3x,
  "5T-4x": airlineIcon5T4x,
  "6Y-2x": airlineIcon6Y2x,
  "6Y-3x": airlineIcon6Y3x,
  "6Y-4x": airlineIcon6Y4x,
  "7Y-2x": airlineIcon7Y2x,
  "7Y-3x": airlineIcon7Y3x,
  "7Y-4x": airlineIcon7Y4x,
  "8Z-2x": airlineIcon8Z2x,
  "8Z-3x": airlineIcon8Z3x,
  "8Z-4x": airlineIcon8Z4x,
  "A2-2x": airlineIconA22x,
  "A2-3x": airlineIconA23x,
  "A2-4x": airlineIconA24x,
  "AP-2x": airlineIconAP2x,
  "AP-3x": airlineIconAP3x,
  "AP-4x": airlineIconAP4x,
  "BG-2x": airlineIconBG2x,
  "BG-3x": airlineIconBG3x,
  "BG-4x": airlineIconBG4x,
  "C8-2x": airlineIconC82x,
  "C8-3x": airlineIconC83x,
  "C8-4x": airlineIconC84x,
  "D4-2x": airlineIconD42x,
  "D4-3x": airlineIconD43x,
  "D4-4x": airlineIconD44x,
  "E2-2x": airlineIconE22x,
  "E2-3x": airlineIconE23x,
  "E2-4x": airlineIconE24x,
  "EU-2x": airlineIconEU2x,
  "EU-3x": airlineIconEU3x,
  "EU-4x": airlineIconEU4x,
  "FJ-2x": airlineIconFJ2x,
  "FJ-3x": airlineIconFJ3x,
  "FJ-4x": airlineIconFJ4x,
  "GA-2x": airlineIconGA2x,
  "GA-3x": airlineIconGA3x,
  "GA-4x": airlineIconGA4x,
  "H6-2x": airlineIconH62x,
  "H6-3x": airlineIconH63x,
  "H6-4x": airlineIconH64x,
  "HZ-2x": airlineIconHZ2x,
  "HZ-3x": airlineIconHZ3x,
  "HZ-4x": airlineIconHZ4x,
  "IT-2x": airlineIconIT2x,
  "IT-3x": airlineIconIT3x,
  "IT-4x": airlineIconIT4x,
  "JJ-2x": airlineIconJJ2x,
  "JJ-3x": airlineIconJJ3x,
  "JJ-4x": airlineIconJJ4x,
  "KC-2x": airlineIconKC2x,
  "KC-3x": airlineIconKC3x,
  "KC-4x": airlineIconKC4x,
  "L3-2x": airlineIconL32x,
  "L3-3x": airlineIconL33x,
  "L3-4x": airlineIconL34x,
  "LU-2x": airlineIconLU2x,
  "LU-3x": airlineIconLU3x,
  "LU-4x": airlineIconLU4x,
  "ML-2x": airlineIconML2x,
  "ML-3x": airlineIconML3x,
  "ML-4x": airlineIconML4x,
  "NB-2x": airlineIconNB2x,
  "NB-3x": airlineIconNB3x,
  "NB-4x": airlineIconNB4x,
  "OB-2x": airlineIconOB2x,
  "OB-3x": airlineIconOB3x,
  "OB-4x": airlineIconOB4x,
  "P0-2x": airlineIconP02x,
  "P0-3x": airlineIconP03x,
  "P0-4x": airlineIconP04x,
  "PO-2x": airlineIconPO2x,
  "PO-3x": airlineIconPO3x,
  "PO-4x": airlineIconPO4x,
  "QG-2x": airlineIconQG2x,
  "QG-3x": airlineIconQG3x,
  "QG-4x": airlineIconQG4x,
  "R7-2x": airlineIconR72x,
  "R7-3x": airlineIconR73x,
  "R7-4x": airlineIconR74x,
  "RZ-2x": airlineIconRZ2x,
  "RZ-3x": airlineIconRZ3x,
  "RZ-4x": airlineIconRZ4x,
  "SN-2x": airlineIconSN2x,
  "SN-3x": airlineIconSN3x,
  "SN-4x": airlineIconSN4x,
  "TF-2x": airlineIconTF2x,
  "TF-3x": airlineIconTF3x,
  "TF-4x": airlineIconTF4x,
  "U5-2x": airlineIconU52x,
  "U5-3x": airlineIconU53x,
  "U5-4x": airlineIconU54x,
  "UZ-2x": airlineIconUZ2x,
  "UZ-3x": airlineIconUZ3x,
  "UZ-4x": airlineIconUZ4x,
  "VS-2x": airlineIconVS2x,
  "VS-3x": airlineIconVS3x,
  "VS-4x": airlineIconVS4x,
  "WJ-2x": airlineIconWJ2x,
  "WJ-3x": airlineIconWJ3x,
  "WJ-4x": airlineIconWJ4x,
  "XE-2x": airlineIconXE2x,
  "XE-3x": airlineIconXE3x,
  "XE-4x": airlineIconXE4x,
  "YH-2x": airlineIconYH2x,
  "YH-3x": airlineIconYH3x,
  "YH-4x": airlineIconYH4x,
  "ZF-2x": airlineIconZF2x,
  "ZF-3x": airlineIconZF3x,
  "ZF-4x": airlineIconZF4x,
  "2T-2x": airlineIcon2T2x,
  "2T-3x": airlineIcon2T3x,
  "2T-4x": airlineIcon2T4x,
  "3T-2x": airlineIcon3T2x,
  "3T-3x": airlineIcon3T3x,
  "3T-4x": airlineIcon3T4x,
  "4U-2x": airlineIcon4U2x,
  "4U-3x": airlineIcon4U3x,
  "4U-4x": airlineIcon4U4x,
  "5U-2x": airlineIcon5U2x,
  "5U-3x": airlineIcon5U3x,
  "5U-4x": airlineIcon5U4x,
  "6Z-2x": airlineIcon6Z2x,
  "6Z-3x": airlineIcon6Z3x,
  "6Z-4x": airlineIcon6Z4x,
  "7Z-2x": airlineIcon7Z2x,
  "7Z-3x": airlineIcon7Z3x,
  "7Z-4x": airlineIcon7Z4x,
  "9A-2x": airlineIcon9A2x,
  "9A-3x": airlineIcon9A3x,
  "9A-4x": airlineIcon9A4x,
  "A3-2x": airlineIconA32x,
  "A3-3x": airlineIconA33x,
  "A3-4x": airlineIconA34x,
  "AQ-2x": airlineIconAQ2x,
  "AQ-3x": airlineIconAQ3x,
  "AQ-4x": airlineIconAQ4x,
  "BH-2x": airlineIconBH2x,
  "BH-3x": airlineIconBH3x,
  "BH-4x": airlineIconBH4x,
  "CA-2x": airlineIconCA2x,
  "CA-3x": airlineIconCA3x,
  "CA-4x": airlineIconCA4x,
  "D5-2x": airlineIconD52x,
  "D5-3x": airlineIconD53x,
  "D5-4x": airlineIconD54x,
  "E4-2x": airlineIconE42x,
  "E4-3x": airlineIconE43x,
  "E4-4x": airlineIconE44x,
  "EV-2x": airlineIconEV2x,
  "EV-3x": airlineIconEV3x,
  "EV-4x": airlineIconEV4x,
  "FK-2x": airlineIconFK2x,
  "FK-3x": airlineIconFK3x,
  "FK-4x": airlineIconFK4x,
  "GB-2x": airlineIconGB2x,
  "GB-3x": airlineIconGB3x,
  "GB-4x": airlineIconGB4x,
  "H7-2x": airlineIconH72x,
  "H7-3x": airlineIconH73x,
  "H7-4x": airlineIconH74x,
  "I2-2x": airlineIconI22x,
  "I2-3x": airlineIconI23x,
  "I2-4x": airlineIconI24x,
  "IU-2x": airlineIconIU2x,
  "IU-3x": airlineIconIU3x,
  "IU-4x": airlineIconIU4x,
  "JK-2x": airlineIconJK2x,
  "JK-3x": airlineIconJK3x,
  "JK-4x": airlineIconJK4x,
  "KD-2x": airlineIconKD2x,
  "KD-3x": airlineIconKD3x,
  "KD-4x": airlineIconKD4x,
  "L6-2x": airlineIconL62x,
  "L6-3x": airlineIconL63x,
  "L6-4x": airlineIconL64x,
  "LV-2x": airlineIconLV2x,
  "LV-3x": airlineIconLV3x,
  "LV-4x": airlineIconLV4x,
  "MM-2x": airlineIconMM2x,
  "MM-3x": airlineIconMM3x,
  "MM-4x": airlineIconMM4x,
  "NC-2x": airlineIconNC2x,
  "NC-3x": airlineIconNC3x,
  "NC-4x": airlineIconNC4x,
  "OC-2x": airlineIconOC2x,
  "OC-3x": airlineIconOC3x,
  "OC-4x": airlineIconOC4x,
  "P1-2x": airlineIconP12x,
  "P1-3x": airlineIconP13x,
  "P1-4x": airlineIconP14x,
  "PQ-2x": airlineIconPQ2x,
  "PQ-3x": airlineIconPQ3x,
  "PQ-4x": airlineIconPQ4x,
  "QH-2x": airlineIconQH2x,
  "QH-3x": airlineIconQH3x,
  "QH-4x": airlineIconQH4x,
  "R9-2x": airlineIconR92x,
  "R9-3x": airlineIconR93x,
  "R9-4x": airlineIconR94x,
  "S0-2x": airlineIconS02x,
  "S0-3x": airlineIconS03x,
  "S0-4x": airlineIconS04x,
  "SP-2x": airlineIconSP2x,
  "SP-3x": airlineIconSP3x,
  "SP-4x": airlineIconSP4x,
  "TG-2x": airlineIconTG2x,
  "TG-3x": airlineIconTG3x,
  "TG-4x": airlineIconTG4x,
  "U6-2x": airlineIconU62x,
  "U6-3x": airlineIconU63x,
  "U6-4x": airlineIconU64x,
  "V0-2x": airlineIconV02x,
  "V0-3x": airlineIconV03x,
  "V0-4x": airlineIconV04x,
  "VT-2x": airlineIconVT2x,
  "VT-3x": airlineIconVT3x,
  "VT-4x": airlineIconVT4x,
  "WK-2x": airlineIconWK2x,
  "WK-3x": airlineIconWK3x,
  "WK-4x": airlineIconWK4x,
  "XF-2x": airlineIconXF2x,
  "XF-3x": airlineIconXF3x,
  "XF-4x": airlineIconXF4x,
  "YI-2x": airlineIconYI2x,
  "YI-3x": airlineIconYI3x,
  "YI-4x": airlineIconYI4x,
  "ZG-2x": airlineIconZG2x,
  "ZG-3x": airlineIconZG3x,
  "ZG-4x": airlineIconZG4x,
  "2U-2x": airlineIcon2U2x,
  "2U-3x": airlineIcon2U3x,
  "2U-4x": airlineIcon2U4x,
  "3U-2x": airlineIcon3U2x,
  "3U-3x": airlineIcon3U3x,
  "3U-4x": airlineIcon3U4x,
  "4V-2x": airlineIcon4V2x,
  "4V-3x": airlineIcon4V3x,
  "4V-4x": airlineIcon4V4x,
  "5V-2x": airlineIcon5V2x,
  "5V-3x": airlineIcon5V3x,
  "5V-4x": airlineIcon5V4x,
  "7A-2x": airlineIcon7A2x,
  "7A-3x": airlineIcon7A3x,
  "7A-4x": airlineIcon7A4x,
  "8B-2x": airlineIcon8B2x,
  "8B-3x": airlineIcon8B3x,
  "8B-4x": airlineIcon8B4x,
  "9B-2x": airlineIcon9B2x,
  "9B-3x": airlineIcon9B3x,
  "9B-4x": airlineIcon9B4x,
  "A4-2x": airlineIconA42x,
  "A4-3x": airlineIconA43x,
  "A4-4x": airlineIconA44x,
  "AR-2x": airlineIconAR2x,
  "AR-3x": airlineIconAR3x,
  "AR-4x": airlineIconAR4x,
  "BI-2x": airlineIconBI2x,
  "BI-3x": airlineIconBI3x,
  "BI-4x": airlineIconBI4x,
  "CC-2x": airlineIconCC2x,
  "CC-3x": airlineIconCC3x,
  "CC-4x": airlineIconCC4x,
  "D6-2x": airlineIconD62x,
  "D6-3x": airlineIconD63x,
  "D6-4x": airlineIconD64x,
  "E5-2x": airlineIconE52x,
  "E5-3x": airlineIconE53x,
  "E5-4x": airlineIconE54x,
  "EW-2x": airlineIconEW2x,
  "EW-3x": airlineIconEW3x,
  "EW-4x": airlineIconEW4x,
  "FM-2x": airlineIconFM2x,
  "FM-3x": airlineIconFM3x,
  "FM-4x": airlineIconFM4x,
  "GC-2x": airlineIconGC2x,
  "GC-3x": airlineIconGC3x,
  "GC-4x": airlineIconGC4x,
  "H8-2x": airlineIconH82x,
  "H8-3x": airlineIconH83x,
  "H8-4x": airlineIconH84x,
  "I4-2x": airlineIconI42x,
  "I4-3x": airlineIconI43x,
  "I4-4x": airlineIconI44x,
  "IV-2x": airlineIconIV2x,
  "IV-3x": airlineIconIV3x,
  "IV-4x": airlineIconIV4x,
  "JL-2x": airlineIconJL2x,
  "JL-3x": airlineIconJL3x,
  "JL-4x": airlineIconJL4x,
  "KE-2x": airlineIconKE2x,
  "KE-3x": airlineIconKE3x,
  "KE-4x": airlineIconKE4x,
  "L7-2x": airlineIconL72x,
  "L7-3x": airlineIconL73x,
  "L7-4x": airlineIconL74x,
  "LW-2x": airlineIconLW2x,
  "LW-3x": airlineIconLW3x,
  "LW-4x": airlineIconLW4x,
  "MN-2x": airlineIconMN2x,
  "MN-3x": airlineIconMN3x,
  "MN-4x": airlineIconMN4x,
  "ND-2x": airlineIconND2x,
  "ND-3x": airlineIconND3x,
  "ND-4x": airlineIconND4x,
  "OD-2x": airlineIconOD2x,
  "OD-3x": airlineIconOD3x,
  "OD-4x": airlineIconOD4x,
  "P2-2x": airlineIconP22x,
  "P2-3x": airlineIconP23x,
  "P2-4x": airlineIconP24x,
  "PR-2x": airlineIconPR2x,
  "PR-3x": airlineIconPR3x,
  "PR-4x": airlineIconPR4x,
  "QI-2x": airlineIconQI2x,
  "QI-3x": airlineIconQI3x,
  "QI-4x": airlineIconQI4x,
  "RA-2x": airlineIconRA2x,
  "RA-3x": airlineIconRA3x,
  "RA-4x": airlineIconRA4x,
  "S2-2x": airlineIconS22x,
  "S2-3x": airlineIconS23x,
  "S2-4x": airlineIconS24x,
  "SQ-2x": airlineIconSQ2x,
  "SQ-3x": airlineIconSQ3x,
  "SQ-4x": airlineIconSQ4x,
  "TH-2x": airlineIconTH2x,
  "TH-3x": airlineIconTH3x,
  "TH-4x": airlineIconTH4x,
  "U8-2x": airlineIconU82x,
  "U8-3x": airlineIconU83x,
  "U8-4x": airlineIconU84x,
  "V2-2x": airlineIconV22x,
  "V2-3x": airlineIconV23x,
  "V2-4x": airlineIconV24x,
  "VU-2x": airlineIconVU2x,
  "VU-3x": airlineIconVU3x,
  "VU-4x": airlineIconVU4x,
  "WM-2x": airlineIconWM2x,
  "WM-3x": airlineIconWM3x,
  "WM-4x": airlineIconWM4x,
  "XG-2x": airlineIconXG2x,
  "XG-3x": airlineIconXG3x,
  "XG-4x": airlineIconXG4x,
  "YK-2x": airlineIconYK2x,
  "YK-3x": airlineIconYK3x,
  "YK-4x": airlineIconYK4x,
  "ZH-2x": airlineIconZH2x,
  "ZH-3x": airlineIconZH3x,
  "ZH-4x": airlineIconZH4x,
};

const iconSizeMap = {
  small: "2x",
  medium: "3x",
  large: "4x",
};

export type AirlineIconSize = keyof typeof iconSizeMap;
export { iconNameToSourceMap, iconSizeMap };

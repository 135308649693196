import { Box, LinearProgress, Typography } from "@material-ui/core";
import clsx from "clsx";
import styles from "./styles.module.scss";

interface ILoadingScreenWithProgressProps {
  title: string;
  description?: string;
  icon?: JSX.Element;
  className?: string;
}

const LoadingScreenWithProgress = ({
  title,
  description,
  icon,
  className,
}: ILoadingScreenWithProgressProps) => (
  <Box className={clsx(styles.loadingScreenWithProgress, className)}>
    {icon}
    <LinearProgress className={styles.linearProgressBar} color="primary" />
    <div>
      <Typography className={styles.title}>{title}</Typography>
      <Typography className={styles.description}>{description}</Typography>
    </div>
  </Box>
);

export default LoadingScreenWithProgress;

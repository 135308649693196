import clsx from "clsx";

import { AirlineIconSize, iconNameToSourceMap, iconSizeMap } from "./types";
import "./styles.scss";
import { useMemo } from "react";

export interface IAirlineIconProps {
  // An airline code must consist of two uppercase letters or digits
  airlineCode: string;
  size?: AirlineIconSize;
  className?: string;
  altText?: string;
}

const iconSizes = ["large", "medium", "small"];
const AirlineIcon = ({
  size = "large",
  className,
  airlineCode = "PLACEHOLDER",
  altText,
}: IAirlineIconProps) => {
  const [large, medium, small] = useMemo(
    () =>
      iconSizes.map((s) => {
        return (
          iconNameToSourceMap[`${airlineCode}-${iconSizeMap[s]}`] ||
          iconNameToSourceMap[`PLACEHOLDER-${iconSizeMap[s]}`]
        );
      }),
    [airlineCode]
  );

  return (
    <div className={clsx("airline-icon", size, className)}>
      <img
        alt={altText ?? ""}
        src={small}
        srcSet={`${small} 32w, ${medium} 48w, ${large} 70w`}
        sizes="(max-width: 320px) 32px, (max-width: 375px) 48px, 70px"
      />
    </div>
  );
};

export default AirlineIcon;

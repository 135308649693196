import { CipherText, FulfillRequest } from "@b2bportal/purchase-api";
import { scheduleFulfill } from "@hopper-b2b/api";
import {
  CartFulfillError,
  CartFulfillErrorModal,
  CartFulfillState,
} from "../../../../types";
import { CartContext } from "../../context";
import { getFullFillRequestParams } from "../selectors";

export const scheduleFulfillService = async (
  context: CartContext,
  _event: unknown
) => {
  const fulfillRequest: FulfillRequest = getFullFillRequestParams({
    context,
  });
  const cartFulfillError: Omit<CartFulfillError, "type"> = {
    failedAt: CartFulfillState.schedule,
  };

  try {
    const result: CipherText = await scheduleFulfill(fulfillRequest);

    if (result.value) {
      return result;
    } else {
      throw ""; // Will be caught in the catch
    }
  } catch (e) {
    throw {
      ...cartFulfillError,
      type: CartFulfillErrorModal.NoCipherText,
    };
  }
};

import {
  B2B_CURRENCY_PREFERENCE_KEY,
  DEFAULT_CURRENCY,
  CURRENCY_LABELS,
} from "./constants";

export const getCurrency = <K extends string[]>(): K[number] => {
  const fromQuery = getFromQuery();
  const fromStorage = getFromStorage();

  return fromQuery || fromStorage || DEFAULT_CURRENCY;
};

const getFromStorage = () => {
  try {
    return localStorage?.getItem(B2B_CURRENCY_PREFERENCE_KEY);
  } catch {
    return null;
  }
};

const getFromQuery = () => {
  const param =
    new URLSearchParams(location.search).get("currency")?.toUpperCase() ?? "";

  try {
    return Intl.supportedValuesOf("currency").includes(param) && param;
  } catch {
    return null;
  }
};

export const setCurrency = (curr: string) => {
  const url = new URL(window.location.href);

  if (url.searchParams?.get("currency")) {
    url.searchParams.set("currency", curr);
    window.history.replaceState({}, "", url);
  }

  localStorage.setItem(B2B_CURRENCY_PREFERENCE_KEY, curr);
};

export const getCurrencyDisplayName = (
  currency: keyof typeof CURRENCY_LABELS
): string => CURRENCY_LABELS[currency] ?? CURRENCY_LABELS[DEFAULT_CURRENCY];

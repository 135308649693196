import { useI18nContext } from "@hopper-b2b/i18n";
import { TranslationLanguage } from "@hopper-b2b/types";

import { EnglishMissedConnectionsTermsAndConditions } from "./EnglishMissedConnectionsTermsAndConditions";
import { SpanishMissedConnectionsTermsAndConditions } from "./SpanishMissedConnectionsTermsAndConditions";
import styles from "./styles.module.scss";

const termsAndConditionsLangMapping = {
  [TranslationLanguage.en]: <EnglishMissedConnectionsTermsAndConditions />,
  [TranslationLanguage.es]: <SpanishMissedConnectionsTermsAndConditions />,
};

export const MissedConnectionsTermsAndConditions = () => {
  const { language } = useI18nContext();

  return (
    <div className={styles.termsandConditionsContainer}>
      {termsAndConditionsLangMapping[language]}
    </div>
  );
};

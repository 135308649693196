import {
  Branding,
  ChatAvailability,
  ClientName,
  ContactSupportConfig,
  Currency,
  FlightShopCardType,
  I18nNamespace,
  TranslationLanguage,
} from "@hopper-b2b/types";
import { DEFAULT_LANG } from "../utils/constants";

const contactSupport: ContactSupportConfig = {
  chatAvailability: ChatAvailability.None,
  displayIntlSupportNumber: "+1-347-695-4555",
  displaySupportNumber: "+1-833-933-4671",
  displaySupportNumberPhonewords: "+1-833-933-HOP1",
  intlSupportNumber: "13476954555",
  supportNumber: "18339334671",
};

const supportedLanguages = [
  {
    key: TranslationLanguage.es,
    name: "Español",
    label: "Español",
  },
  {
    key: TranslationLanguage.en,
    name: "English",
    label: "English",
  },
];

export const branding: Branding = {
  default: DEFAULT_LANG,
  contactSupport,
  merchantPayments: "",
  clientName: ClientName.VOLARIS,
  currencies: [
    {
      locale: "es-MX",
      code: Currency.MXN,
      // For more info on symbolOverride, see https://hopper-jira.atlassian.net/wiki/spaces/IEP/pages/6205308931/Tenant-specific+currency+symbols
      // @hopper-i18n/formatter#formatCurrency uses `MX$` by default so there is no need to specify the override here.
      // We add this override just for easier understanding of what is going on
      symbolOverride: "MX$",
    },
    {
      locale: "en-US",
      code: Currency.USD,
      // For more info on symbolOverride, see https://hopper-jira.atlassian.net/wiki/spaces/IEP/pages/6205308931/Tenant-specific+currency+symbols
      // Always use `US$` for USD. The default is to use `$` which is not what we want for Volaris.
      symbolOverride: "US$",
    },
  ],
  supportedLanguages,
  flightShopCardType: FlightShopCardType.MINI,
  [I18nNamespace.translation]: {
    [TranslationLanguage.en]: {
      portalName: "Volaris by HTS",
      urls: {
        termsAndConditions: "https://hopper.com/legal/terms-and-conditions",
        privacyPolicy: "https://hopper.com/legal/privacy-policy",
        missedConnectionTerms:
          "https://hopper.com/legal/Missed-Connection-Guarantee",
        helpCenterBaseUrl: "https://hopper-cloud-volaris.kustomer.help/en_us",
      },
      preferredCountryCode: "US",
      preferredAreaCode: "+1",
      calendarMonthFormat: "MMMM",
      timeFormat: "hh:mm A",
    },
    [TranslationLanguage.es]: {
      portalName: "Volaris por HTS",
      urls: {
        termsAndConditions:
          "https://www.hopper.com/es/legal/terms-and-conditions/",
        privacyPolicy: "https://hopper.com/es/legal/privacy-policy",
        missedConnectionTerms:
          "https://hopper.com/es/legal/Missed-Connection-Guarantee",
        helpCenterBaseUrl: "https://hopper-cloud-volaris.kustomer.help/es",
      },
      preferredCountryCode: "MX",
      preferredAreaCode: "+52",
      calendarMonthFormat: "MMMM",
      timeFormat: "hh:mm A",
    },
  },
};

import { assign, DoneInvokeEvent } from "xstate";
import {
  SeatMapAvailable,
  SeatMapResponseEnum,
  SeatMapUnavailable,
} from "@b2bportal/air-booking-api";
import { GetSeatMapResponse } from "@b2bportal/air-seats-api";
import { Product } from "@b2bportal/purchase-api";

import { getObjectKeysAsObject, setContextWithKey } from "@checkout/helpers";
import { ParentState, SeatSelectionError } from "@checkout/types";
import { SeatProduct } from "@checkout/states/Cart/CartUpdate/context";
import { getQuotedFlightProduct } from "../../selectors";
import { SelectSeat } from "./events";
import { FlightContext, FlightSelectors } from "../..";

export const actions = {
  setSeatMap: assign(
    (
      ctx: FlightContext,
      { data: { cheapestSeat, html } }: DoneInvokeEvent<GetSeatMapResponse>
    ) =>
      setContextWithKey(ctx, ParentState.seatSelection, {
        ...ctx[ParentState.seatSelection],
        seatMapHtml: html,
        cheapestSeat,
      })
  ),
  setSeatError: assign(
    (ctx: FlightContext, event: DoneInvokeEvent<SeatSelectionError>) =>
      setContextWithKey(ctx, `${ParentState.seatSelection}.error`, event.data)
  ),
  clearSeatError: assign((ctx: FlightContext, _event) =>
    setContextWithKey(ctx, `${ParentState.seatSelection}.error`, undefined)
  ),
  selectSeat: assign((context: FlightContext, event: SelectSeat) => {
    setContextWithKey(
      context,
      `${ParentState.seatSelection}.selectedSeats`,
      event.seats
    );
    setContextWithKey(
      context,
      `${ParentState.seatSelection}.selectedSeatSegments`,
      event.seatSegments
    );

    setContextWithKey(
      context,
      `${ParentState.seatSelection}.selectedSeatTotal`,
      event.selectedSeatTotal
    );

    // TODO: when cart is fixed, shoppedTripId will be replaced by shoppedProductId
    // const shoppedProductId = cartQuoteSelectors.getCartQuoteShoppedProductId({
    //   context,
    // });
    const shoppedTripId = FlightSelectors.getQuotedShoppedTripId({
      context,
    });
    const bookingSessionId = FlightSelectors.getQuoteBookingSessionId({
      context,
    });
    const productsToAdd = context[ParentState.cartUpdate].addProducts;
    const filterOutSeatFromProducts =
      productsToAdd?.filter((p) => p.type !== Product.Seats) || [];
    const { reservationId, seatsClientToken } =
      context.cartQuote.quoteBreakdown.products.find(
        (p) =>
          p.product.type === Product.Flight &&
          p.product.value.shoppedTripId === shoppedTripId
      )?.product?.value ?? {};
    const newSeatProduct: SeatProduct = {
      type: Product.Seats,
      value: {
        command: { seats: event.seats },
        shoppedProductId: shoppedTripId as string,
        bookingSessionId,
        seatsClientToken,
        reservationId,
      },
    };
    filterOutSeatFromProducts.push(newSeatProduct);
    context[ParentState.cartUpdate].addProducts = filterOutSeatFromProducts;
    return context;
  }),
  resetSelectedSeats: assign((ctx: FlightContext, _event) => {
    setContextWithKey(ctx, `${ParentState.seatSelection}.selectedSeats`, []);
    setContextWithKey(
      ctx,
      `${ParentState.seatSelection}.selectedSeatSegments`,
      []
    );
    return ctx;
  }),
  setSkipSeatSelection: assign((ctx: FlightContext, _event) =>
    setContextWithKey(
      ctx,
      `${ParentState.seatSelection}.skipSeatSelection`,
      true
    )
  ),
  setSeatsVisited: assign((ctx: FlightContext, _event) =>
    setContextWithKey(ctx, `${ParentState.seatSelection}.visited`, true)
  ),
  resetSkippedSeatSelection: assign((ctx: FlightContext, _event) =>
    setContextWithKey(
      ctx,
      `${ParentState.seatSelection}.skipSeatSelection`,
      false
    )
  ),
  setSeatSelectionComplete: assign((ctx: FlightContext, _event) =>
    setContextWithKey(
      ctx,
      `${ParentState.seatSelection}.seatSelectionComplete`,
      true
    )
  ),
  resetSeatSelectionComplete: assign((ctx: FlightContext, _event) =>
    setContextWithKey(
      ctx,
      `${ParentState.seatSelection}.seatSelectionComplete`,
      false
    )
  ),
  setSeatMapResponse: assign((ctx: FlightContext, _event) => {
    const quotedFlight = getQuotedFlightProduct({ context: ctx });
    const seatMap = quotedFlight?.seatMap;
    if (seatMap) {
      const failedSeatMapResponse = seatMap as SeatMapUnavailable;
      if (
        failedSeatMapResponse.SeatMapResponse ===
        SeatMapResponseEnum.SeatMapUnavailable
      ) {
        return setContextWithKey(
          ctx,
          `${ParentState.seatSelection}.seatMapAvailable`,
          SeatMapResponseEnum.SeatMapUnavailable
        );
      } else {
        const response = seatMap as SeatMapAvailable;
        const seatSelectionContext = ctx[ParentState.seatSelection];
        seatSelectionContext.seatMapAvailable = response.SeatMapResponse;
        seatSelectionContext.seatMapRequest = response.callback;
        seatSelectionContext.seatsInfo = response.seatsInfo;

        return setContextWithKey(
          ctx,
          ParentState.seatSelection,
          seatSelectionContext
        );
      }
    }
    return ctx;
  }),
  resetEditSeatsFromReview: assign((ctx: FlightContext, _event) =>
    setContextWithKey(ctx, `${ParentState.review}.editSeatsFromReview`, false)
  ),
};

export const ActionTypes = getObjectKeysAsObject(actions);

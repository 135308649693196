const config = {
  ENV: import.meta.env.MODE,
  spreedlyKey:
    import.meta.env.VITE_SPREEDLY_ENVIRONMENT_KEY ||
    "MbskPSZ9MMH7vnuAItHyGdqotHe",
  googleMapsApiKey: import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_VERSION: import.meta.env.VITE_REACT_APP_VERSION,
  MOCK: import.meta.env.VITE_MOCK === "true" ?? false,
  SIFT_ACCOUNT: import.meta.env.VITE_SIFT_ACCOUNT,
  googleIdentityClientId: import.meta.env.VITE_GOOGLE_IDENTITY_CLIENT_ID,
};

export default config;

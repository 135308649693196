import { Box, Typography, Button } from "@material-ui/core";
import { Airport } from "@b2bportal/air-booking-api";

import { TagColors, SeatInfo, GordianSeatSegment } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";

import { Icon, IconName } from "../../Icon";
import { StatusTag } from "../../StatusTag";
import * as constants from "./constants";
import "./styles.scss";

export interface ISelectedSeatCardProps {
  selectedSeats: GordianSeatSegment;
  onEditClick?: (idx: number) => void;
  isMobile?: boolean;
  airports: { [key: string]: Airport | undefined };
  idx: number;
}

export const SelectedSeatCard = (props: ISelectedSeatCardProps) => {
  const { t } = useI18nContext();
  const { selectedSeats, onEditClick, isMobile, airports, idx } = props;

  const getLocationString = (location: string) => {
    return airports?.[location]
      ? airports[location]?.cityName + ` (${location})`
      : location;
  };

  return (
    <Box className={clsx("selected-seat-wrapper", { mobile: isMobile })}>
      <Box className="location-and-edit">
        <Typography variant="body1">
          {`${getLocationString(
            selectedSeats.originCode
          )} - ${getLocationString(selectedSeats.destinationCode)}`}
        </Typography>
        {onEditClick &&
          selectedSeats.seats.every(
            (seat) => seat.seat_id !== constants.UNAVAILABLE
          ) && (
            <Button
              className="edit-seats-button"
              onClick={() => onEditClick(idx)}
            >
              {isMobile
                ? t("edit")
                : t("editSeat", { count: selectedSeats.seats.length })}
            </Button>
          )}
      </Box>
      <Box className="passengers-and-seats-info-container">
        {selectedSeats.seats.map((seatInfo: SeatInfo) => {
          return (
            <Box className="passenger-info" key={`${seatInfo.person_id}`}>
              <Typography className="passenger-name" variant="body2">
                {seatInfo.pax}
              </Typography>
              {seatInfo.seat_id ? (
                seatInfo.seat_id !== constants.UNAVAILABLE ? (
                  <Typography className="selected-seat" variant="body2">
                    <Icon name={IconName.SeatSelectionIcon} />
                    {seatInfo.seat_id}
                  </Typography>
                ) : (
                  <StatusTag
                    className="seat-not-avail-tag"
                    tagInfo={{
                      label: isMobile
                        ? t("seatSelectionUnavailableMobile")
                        : t("seatSelectionUnavailable"),
                      type: TagColors.GREY,
                      tooltipCopy: t("seatUnavailableDetail"),
                      iconName: IconName.InfoCircle,
                    }}
                  />
                )
              ) : (
                <StatusTag
                  className="seat-not-selected-tag"
                  tagInfo={{
                    label: isMobile
                      ? t("seatNotSelectedMobile")
                      : t("seatNotSelected"),
                    type: TagColors.YELLOW,
                    tooltipCopy: t("seatNotSelectedDetail"),
                    iconName: IconName.InfoCircle,
                  }}
                />
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

import {
  DisruptionOffer,
  DisruptionQuote,
} from "@b2bportal/air-disruption-api";

export enum DisruptionOfferQuoteSelectionEnum {
  Selected = "Selected",
  Declined = "Declined",
}
// TODO: once disruption-offers is refactored to server "one offer, multiple quotes", we can further simplify how we handle offer/quote in models. We should really simplify this (including the selection logic)
export type DisruptionOfferQuoteSelected = {
  offer: DisruptionOffer;
  quote: DisruptionQuote;
  selection: DisruptionOfferQuoteSelectionEnum.Selected;
};

export type DisruptionOfferDeclined = {
  offer: DisruptionOffer;
  selection: DisruptionOfferQuoteSelectionEnum.Declined;
};

export enum DisruptionTrackingEvent {
  VIEW_DISRUPTION_OFFER = "view_disruption_offer",
  TOGGLE_DISRUPTION_OFFER = "toggle_disruption_offer",
  DISRUPTION_POLICY_EXERCISED = "disruption_policy_exercised",
}

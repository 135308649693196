import { Failure, PersonListSuccess, Person } from "@b2bportal/air-booking-api";
import { deleteUserPassenger, fetchUserPassengers } from "@hopper-b2b/api";

import { PassengerError, PassengerErrorModalTypes } from "@checkout/types";
import { DeletePassengerEvent } from "../events";
import { FlightContext } from "../../../types";

export interface DeleteUserPassengerSuccessResponse {
  userPassengers: Person[];
  deletedPassengerId: string;
}

export type DeleteUserPassengerResponse =
  | DeleteUserPassengerSuccessResponse
  | PassengerError;

export const deleteUserPassengersService = (
  _ctx: FlightContext,
  event: DeletePassengerEvent
) =>
  new Promise<DeleteUserPassengerResponse>((resolve, reject) => {
    const passengerIdToDelete = event.personId;
    deleteUserPassenger({
      personId: passengerIdToDelete,
    })
      .then(() => {
        fetchUserPassengers({}).then((response) => {
          if ((response as Failure).errors) {
            reject({
              type: PassengerErrorModalTypes.DeletePassenger,
              data: (response as Failure).errors,
            });
          } else {
            resolve({
              userPassengers: (response as PersonListSuccess).value,
              deletedPassengerId: passengerIdToDelete,
            });
          }
        });
      })
      .catch((e) => {
        reject({
          type: PassengerErrorModalTypes.DeletePassenger,
          data: e,
        });
      });
  });

export const basicColors = {
  // white
  white: "#ffffff",
  "white-1": "rgb(251, 251, 251)",
  "white-alpha-50": "#FFFFFF7A",
  "white-alpha-80": "#FFFFFFCC",
  "white-alpha-90": "#FFFFFFE0",
  "white-transparent-98": "rgba(255, 255, 255, .98)",
  "white-transparent-50": "rgba(255, 255, 255, 0.5)",
  "white-transparent": "rgba(255, 255, 255, 0.48)",
  // black
  "black-alpha-40": "#00000052",
  "black-alpha-60": "#000000A3",
  "black-alpha-100": "#000000F5",
  black: "#141414",
  "black-1": "#afafaf",
  "black-2": "#121212",
  "black-3": "#000000",
  "black-3-transparent-12": "rgba(0, 0, 0, 0.12)",
  "black-100": "#111111",
  "black-20": "rgba(0, 0, 0, 0.96)",
  "black-30": "rgba(0, 0, 0, 0.64)",
  "black-disabled": "#BBBBBB",
  // blue
  blue: "#266ef1",
  skyblue: "#01aae4",
  "skyblue-disabled": "rgba(1, 170, 228, 0.5)",
  darkblue: "#008ac6",
  "blue-2": "#EFF3FE",
  "blue-3": "#D4E2FC",
  "blue-4": "rgba(0, 179, 241, 0.1)",
  "blue-5": "rgb(36, 96, 130)",
  "blue-5-transparent": "rgba(36, 96, 130, 0.1)",
  "blue-6": "#00b4ff",
  "blue-7": "rgb(0, 195, 203)",
  "blue-9": "rgb(49, 190, 199)",
  "blue-10": "rgba(228, 246, 253, 0.25)",
  "blue-11": "#122741",
  "blue-12": "#00132B",
  "blue-13": "#F0FAFF",
  "blue-14": "#10253F",
  "blue-14-transparent": "rgba(16, 37, 63, 0.9)",
  "blue-20": "#C5E5F6",
  "blue-30": "#73C1E9",
  "blue-50": "#01aae4",
  "blue-60": "#008ecc",
  "blue-100": "#EFF3FE",
  "blue-200": "#D4E2FC",
  "blue-300": "#EFF4FE",
  "blue-400": "#276EF1",
  // grey
  "gray-10": "#EFEFEF",
  "gray-20": "#E1E1E1",
  "gray-30": "#B8B8B8",
  "gray-40": "rgba(0, 0, 0, 0.32)",
  "gray-50": "#6A6A6A",
  "gray-80": "#6A6A6A",
  "gray-100": "#111111",
  grey: "#eeeeee",
  "dark-grey": "#282828",
  "grey-1": "#333333",
  "grey-2": "#232323",
  "grey-3": "#E8E8E8",
  "grey-4": "#979797",
  "grey-5": "#d1d5db",
  "grey-6": "#aaaaaa",
  "grey-7": "#5a5a5a",
  "grey-8": "#f1f1f1",
  "grey-9": "rgb(80,80,80)",
  "grey-10": "#f6f6f6",
  "grey-11": "rgb(224, 224, 224)",
  "grey-11-transluscent": "rgba(224, 224, 224, 0.3)",
  "grey-11-transparent": "rgba(224, 224, 224, 0.1)",
  "grey-12": "rgb(80,80,80)",
  "grey-13": "#f8f7fa",
  "grey-14": "#636363",
  "grey-15": "#f3f3f3",
  "grey-16": "#bababa",
  "grey-17": "#757575",
  "grey-18": "#e0e0e0",
  "grey-19": "#F9F9F9",
  "grey-20": "#d9d9d9",
  "grey-100": "#505050",
  "grey-200": "#878787",
  "grey-300": "#929292",
  "grey-400": "#D8D8D8",
  "grey-500": "#E2E2E2",
  "grey-600": "#606a77",
  "grey-1000": "#f2f2f2",
  "grey-2000": "#f3f3f3",
  "grey-3000": "#5e5e5e",
  "grey-4000": "#4B4B4B",
  "grey-5000": "#a6a6a6",
  "grey-6000": "#545454",
  "grey-7000": "#333",
  // green
  green: "#009A51",
  "light-green": "#7FD99A",
  "lightest-green": "#eaf6ed",
  "green-1": "#38b97a",
  "green-2": "#008140",
  "green-3": "rgb(99, 182, 127)",
  "green-4": "rgb(224, 240, 229)",
  "green-5": "rgb(0, 129, 64)",
  "green-5-transparent": "rgb(0, 129, 64, 0.1)",
  "green-6": "#eaf6ed",
  "green-7": "#EAFAF4",
  "green-8": "#00AA6C",
  "green-9": "#004f32",
  "green-10": "#C9F2E3",
  "green-11": "#84E9BD",
  "green-12": "rgb(240, 245, 243)",
  "green-50": "#60b955",
  "green-200": "#0e8345",
  "green-800": "#0D572D",
  // red
  red: "#F83446",
  "light-red": "#FC7F79",
  coral: "#fa6866",
  "red-1": "#fa6866",
  "red-2": "#e02020",
  "red-3": "#cf212b",
  "red-4": "#D03228",
  "red-5": "#FFCCCC",
  "red-6": "#E06666",
  "red-7": "#FF5D5D",
  "red-8": "#B5262B",
  "red-9": "#FAF1ED",
  "red-10": "#FFF0EE",
  "red-40": "#CC2427",
  "red-100": "#D53939",
  "red-200": "#de1135",
  "red-300": "#cc0000",
  // yellow
  yellow: "#F6BC2F",
  "light-yellow": "#FFD688",
  "lightest-yellow": "#fdf2dc",
  "yellow-1": "#d99422",
  "yellow-2": "#f7b500",
  "yellow-3": "#e69100",
  "yellow-4": "#FFF7E1",
  "yellow-5": "#F7D36E",
  "yellow-6": "#F2B203",
  // orange
  orange: "#E65300",
  "light-orange": "#FFB48C",
  "orange-1": "#f58908",
  "orange-2": "#fdf3e7",
  // purple
  purple: "rgb(98, 54, 255)",
  "purple-transparent": "rgba(98, 54, 255, 0.2)",
};

export type BasicColorsType = typeof basicColors;

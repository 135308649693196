import { CreditCard, CreditCardListSuccess } from "@b2bportal/card-api";
import { deletePaymentMethod, listPaymentMethods } from "@hopper-b2b/api";

import { ParentContextWithCardPayment, PaymentErrorModalTypes } from "../types";
import { DeletePaymentMethodEvent } from "../events";
import { CardPaymentSelectors } from "..";

export const deletePaymentMethodService = (
  context: ParentContextWithCardPayment,
  event: DeletePaymentMethodEvent
) =>
  new Promise((resolve, reject) => {
    const {
      paymentMethod: { paymentId },
    } = event;
    const listReqBody = CardPaymentSelectors.getPaymentListRequest({
      context,
    });
    deletePaymentMethod({ paymentId })
      .then(() => {
        listPaymentMethods(listReqBody).then((paymentMethods) => {
          resolve({
            paymentMethods: (paymentMethods as CreditCardListSuccess).value,
            deletedPaymentId: paymentId,
          });
        });
      })
      .catch((e) => {
        // Refetch payment methods if possible to avoid stale data on list success only
        listPaymentMethods(listReqBody)
          .then((paymentMethods) => {
            reject({
              type: PaymentErrorModalTypes.DeletePayment,
              data: e,
              paymentMethods: (paymentMethods as CreditCardListSuccess).value,
            });
          })
          .catch(() => {
            reject({
              type: PaymentErrorModalTypes.DeletePayment,
              data: e,
            });
          });
      });
  });

export interface DeletePaymentMethodResponse {
  paymentMethods: CreditCard[];
  deletedPaymentId: string;
}

import { State } from "xstate";
import { AirPriceDropQuoteRequest } from "@b2bportal/air-price-drop-api";
import { ParentState } from "../../../types/common";
import { PartialParentContextWithAirPriceDrop } from "./types";
import { FlightPassengerSelectors, FlightSelectors } from "../Flight";
import { getQuoteBreakdown } from "../../Cart/CartQuote/selectors";
import { Product, QuotedProduct } from "@b2bportal/purchase-api";

type PriceDropUpdateStateType = State<PartialParentContextWithAirPriceDrop>;
type PriceDropUpdateStateWithoutValue = Pick<
  PriceDropUpdateStateType,
  "context"
>;

export const getPriceDropCartUpdateRequest = (
  state: PriceDropUpdateStateWithoutValue
) => {
  const selectedTrip = FlightSelectors.getSelectedTripParent(state);
  const tripId = selectedTrip.tripId ?? "";

  const shoppedFlightProductId =
    getQuoteBreakdown(state)?.products?.find(
      (quotedProduct: QuotedProduct) =>
        quotedProduct.product.type === Product.Flight
    )?.shoppedProductId ?? "";

  const totalPassengers =
    FlightPassengerSelectors.getAllSelectedPassengerIdsParent(state).length;
  const lapInfantPassengers =
    state.context.passengerInformation.selectedLapInfantIds.length;

  const candidateId = getCandidateId(state) ?? "";
  const params: AirPriceDropQuoteRequest = {
    candidateId,
    shoppedFlightProductId,
    tripId,
    lapInfantPassengers,
    totalPassengers,
  };

  return params;
};

export const getCandidateId = (
  state: PriceDropUpdateStateType | PriceDropUpdateStateWithoutValue
) => {
  return state.context[ParentState.priceDrop].candidateId;
};

export const getPriceDropEligibility = (
  state: PriceDropUpdateStateWithoutValue
) => {
  return !!state.context[ParentState.priceDrop].candidateId;
};

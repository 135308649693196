import { useMemo } from "react";
import clsx from "clsx";
import { DateTimeFormatStyle, formatDateTime } from "@hopper-i18n/formatter";
import { getEnvVariables } from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import Airplane from "../../../../assets/icons/flight/airplane.svg";

interface IFlightTimingProps {
  departureTime: string;
  arrivalTime: string;
  className?: string;
}

export const FlightTiming = ({
  departureTime,
  arrivalTime,
  className,
}: IFlightTimingProps) => {
  const { language: locale } = useI18nContext();

  const isHopper = getEnvVariables("clientName") === "hopper";

  const departureTimeFormatted = useMemo(
    () =>
      formatDateTime(
        new Date(departureTime),
        locale,
        DateTimeFormatStyle.ShortTime
      ),
    [departureTime]
  );

  const arrivalTimeFormatted = useMemo(
    () =>
      formatDateTime(
        new Date(arrivalTime),
        locale,
        DateTimeFormatStyle.ShortTime
      ),
    [arrivalTime]
  );

  if (isHopper) {
    return (
      <div className={clsx("other-timing", className)}>
        {departureTimeFormatted}
        <img className="airplane" src={Airplane} alt="airplane-icon" />
        {arrivalTimeFormatted}
      </div>
    );
  }

  return (
    <span className={clsx("timing", className)}>
      {departureTimeFormatted} - {arrivalTimeFormatted}
    </span>
  );
};

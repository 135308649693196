import { Checkbox, ListItemText } from "@material-ui/core";
import {
  Gender,
  FrequentFlyerMap,
  AirlineCode,
  FrequentFlyerNumber,
  Assistance,
} from "@hopper-b2b/types";
import clsx from "clsx";

import { StyledMenuItem } from "../StyledMenuItem";

export interface IFrequentFlyer {
  key: AirlineCode;
  value: FrequentFlyerNumber;
  trackingId: number;
}

export type IFrequentFlyerList = IFrequentFlyer[];

export const getFrequentFlyerList = (
  frequentFlyerMap: FrequentFlyerMap
): IFrequentFlyerList => {
  return Object.entries(frequentFlyerMap).map((frequentFlyer, index) => {
    return {
      key: frequentFlyer[0] as AirlineCode,
      value: frequentFlyer[1].value as FrequentFlyerNumber,
      trackingId: index,
    };
  });
};

export const getFrequentFlyerMap = (
  frequentFlyerList: IFrequentFlyerList
): FrequentFlyerMap => {
  const frequentFlyerMap: FrequentFlyerMap = {};
  for (const frequentFlyer of frequentFlyerList) {
    frequentFlyerMap[frequentFlyer.key] = {
      value: frequentFlyer.value,
    };
  }

  return frequentFlyerMap;
};

export const getNextTrackingId = (
  frequentFlyerList: IFrequentFlyerList
): number => {
  return frequentFlyerList.length > 0
    ? Math.max(
        ...frequentFlyerList.map((frequentFlyer) => frequentFlyer.trackingId)
      ) + 1
    : 0;
};

interface IOption {
  label: string | JSX.Element;
  value: any;
}

export const renderMenu = (options: IOption[]): JSX.Element[] =>
  options.map(({ label, value }, index) => (
    <StyledMenuItem key={`${value}-${index}`} value={value}>
      {label}
    </StyledMenuItem>
  ));

export const genderOptions: IOption[] = [
  { label: "Male", value: Gender.M },
  { label: "Female", value: Gender.F },
];

export interface IFlyerOption {
  airline: string;
  program: string;
  value: string;
  label?: JSX.Element;
}

// TODO: find out how to generate all flyer codes (2-character airline codes) and full names;
// also, it has to filter out codes in frequentFlyerMap
export const flyerOptions: IFlyerOption[] = [
  { airline: "Aegean Airlines", program: "Miles+Bonus", value: "A3" },
  { airline: "Aer Lingus", program: "AerClub", value: "EI" },
  { airline: "Aerolíneas Argentinas", program: "Aerolíneas Plus", value: "AR" },
  { airline: "AeroMexico", program: "Club Premier", value: "AM" },
  { airline: "Air Algérie", program: "Air Algérie Plus", value: "AH" },
  { airline: "Air Arabia", program: "AirRewards (Air Arabia)", value: "G9" },
  { airline: "Air Canada", program: "Aeroplan", value: "AC" },
  { airline: "Air China", program: "PhoenixMiles", value: "CA" },
  { airline: "Air Europa", program: "SUMA", value: "UX" },
  { airline: "Air France KLM", program: "Flying Blue", value: "AF, KL" },
  { airline: "Air India", program: "Flying Returns", value: "AI" },
  { airline: "Air Mauritius", program: "Kestrelflyer", value: "MK" },
  { airline: "Air New Zealand", program: "Airpoints", value: "NZ" },
  { airline: "Alaska Airlines", program: "Mileage Plan", value: "AS" },
  { airline: "Alitalia", program: "MilleMiglia", value: "AZ" },
  { airline: "American Airlines", program: "AAdvantage", value: "AA" },
  {
    airline: "ANA - All Nippon Airways",
    program: "ANA Mileage Club",
    value: "NH",
  },
  { airline: "Asiana Airlines", program: "Asiana Club", value: "OZ" },
  { airline: "Avianca", program: "LifeMiles", value: "AV" },
  { airline: "Avior Airlines", program: "Avior Plus", value: "9V" },
  { airline: "Bamboo Airways", program: "Bamboo Club", value: "QH" },
  { airline: "Bangkok Airways", program: "FlyerBonus", value: "PG" },
  { airline: "British Airways", program: "Executive Club", value: "BA" },
  // { airline: "Cathay Pacific", program: "Asia Miles", value: "CX" },
  { airline: "Cathay Pacific", program: "The Marco Polo Club", value: "CX" },
  { airline: "Copa", program: "ConnectMiles", value: "CM" },
  { airline: "Corsair", program: "Club Corsair", value: "SS" },
  { airline: "Delta Air Lines", program: "Delta SkyMiles", value: "DL" },
  { airline: "Egyptair", program: "EgyptAir Plus", value: "MS" },
  { airline: "El Al", program: "Matmid Club", value: "LY" },
  { airline: "Emirates", program: "Skywards", value: "EK" },
  { airline: "Ethiopian Airlines", program: "ShebaMiles", value: "ET" },
  { airline: "Etihad Airways", program: "Etihad Guest", value: "EY" },
  { airline: "Eurowings", program: "Boomerang Club", value: "EW" },
  { airline: "Finnair", program: "Finnair Plus", value: "AY" },
  { airline: "Frontier", program: "Frontier Miles", value: "F9" },
  { airline: "Hawaiian Airlines", program: "Hawaiian Miles", value: "HA" },
  { airline: "Iberia", program: "Iberia Plus", value: "IB" },
  { airline: "Icelandair", program: "Saga Club", value: "FI" },
  { airline: "IranAir", program: "SkyGift", value: "EP" },
  { airline: "Iraqi Airways", program: "SkyMiles (Iraqi Air)", value: "IA" },
  { airline: "Japan Airlines", program: "JAL Mileage Bank", value: "JL" },
  { airline: "Jazeera Airways", program: "Jazeerati", value: "J9" },
  { airline: "jetBlue", program: "TrueBlue", value: "B6" },
  { airline: "Korean Air", program: "Skypass", value: "KE" },
  { airline: "LATAM", program: "LATAM Pass", value: "JJ" },
  { airline: "Lufthansa", program: "Miles & More", value: "LH" },
  { airline: "Norwegian", program: "Norwegian Reward", value: "D8" },
  { airline: "Philippine Airlines", program: "Mabuhay Miles", value: "PR" },
  { airline: "Porter Airlines", program: "VIPorter", value: "PD" },
  { airline: "Qantas", program: "Frequent Flyer", value: "QF" },
  { airline: "Qatar Airways", program: "Privilege Club", value: "QR" },
  { airline: "Saudia", program: "Alfursan", value: "SV" },
  { airline: "Singapore Airlines", program: "KrisFlyer", value: "SQ" },
  { airline: "South African Airways", program: "Voyager", value: "SA" },
  { airline: "Southwest Airlines", program: "Rapid Rewards", value: "WN" },
  { airline: "Spirit Airlines", program: "Free Spirit", value: "NK" },
  {
    airline: "Sun Country Airlines",
    program: "Sun Country Rewards",
    value: "SY",
  },
  { airline: "TAP Air Portugal", program: "TAP Miles&Go", value: "TP" },
  { airline: "Thai Airways", program: "Royal Orchid Plus", value: "TG" },
  { airline: "THY - Turkish Airlines", program: "Miles&Smiles", value: "TK" },
  { airline: "United Airlines", program: "Mileage Plus", value: "UA" },
  { airline: "Vietnam Airlines", program: "Lotusmiles", value: "VN" },
  { airline: "Virgin Atlantic", program: "Flying Club", value: "VS" },
  { airline: "Virgin Australia", program: "Velocity", value: "VA" },
  { airline: "Vistara", program: "Club Vistara", value: "UK" },
  { airline: "Vueling", program: "Vueling Club", value: "VY" },
  { airline: "WestJet", program: "WestJet Rewards", value: "WS" },
];

export const formattedFlyerOptions = flyerOptions.map((option) => ({
  label: (
    <p className="frequent-flyer-option">
      <strong>{option.airline}</strong> - {option.program}
    </p>
  ),
  ...option,
}));

export const assistanceOptions: IOption[] = [
  { label: "Blind passenger", value: Assistance.BLND },
  { label: "Deaf passenger", value: Assistance.DEAF },
  { label: "Stretcher assistance", value: Assistance.STCR },
  { label: "Wheelchair/Passenger can walk up stairs", value: Assistance.WCHR },
  { label: "Wheelchair/Passenger can walk to seat", value: Assistance.WCHS },
  { label: "Wheelchair/Passenger must be carried", value: Assistance.WCHC },
  { label: "Wheelchair with Dry Cell Battery", value: Assistance.WCBD },
  { label: "Wheelchair with Wet Cell Battery", value: Assistance.WCBW },
  { label: "Wheelchair with Lithium Battery", value: Assistance.WCLB },
  { label: "Wheelchair, manual power", value: Assistance.WCMP },
  { label: "Wheelchair, On-Board", value: Assistance.WCOB },
];

export const getAssistanceOptionLabel = (assistance: Assistance) => {
  const option = assistanceOptions.find(
    (option) => option.value === assistance
  );
  return option ? option.label : "Unrecognized assistance";
};

export const mapAssistanceOptionsToMenuItems = (
  options: IOption[],
  assistanceList: Assistance[]
) =>
  options.map(({ label, value }, index) => (
    <StyledMenuItem className="assistance-menu-item" value={value} key={index}>
      <Checkbox
        className={clsx("assistance-checkbox", {
          checked: assistanceList.indexOf(value) !== -1,
        })}
        checked={assistanceList.indexOf(value) !== -1}
      />
      <ListItemText>{label}</ListItemText>
    </StyledMenuItem>
  ));

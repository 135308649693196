import {
  purchaseApi,
  UpdateQuoteRequest,
  UpdateQuoteResponse,
} from "@b2bportal/purchase-api";
import { NETWORK_CALL_FAILED, IApiConfig } from "@hopper-b2b/types";
import { axiosInstance } from "../AxiosInterceptor";
import { trackEvent } from "../tracking/trackEvent";

export const updateQuotedCart = (
  req: UpdateQuoteRequest,
  apiConfig?: IApiConfig
): Promise<UpdateQuoteResponse> =>
  new Promise((resolve, reject) => {
    purchaseApi(axiosInstance)
      .apiV1PurchaseQuoteUpdatePost(req)
      .then((res) => {
        const responseBody = res.data;
        resolve(responseBody);
      })
      .catch((e) => {
        trackEvent(
          {
            eventName: NETWORK_CALL_FAILED,
            properties: {
              url: window.location.pathname,
            },
          },
          apiConfig
        );
        reject(e);
      });
  });

import { getObjectKeysAsObject } from "@checkout/helpers";
import { getCartUpdateParams } from "./selectors";
import { CartContext } from "../context";

export const guards = {
  isUpdateQuoteRequestEmpty: (context: CartContext, _event: unknown) => {
    const cartUpdateCtx = getCartUpdateParams({ context });
    return (
      !cartUpdateCtx.addPayments.length &&
      !cartUpdateCtx.addProducts.length &&
      !cartUpdateCtx.removePayments.length &&
      !cartUpdateCtx.removeProducts.length
    );
  },
};

export const GuardTypes = getObjectKeysAsObject(guards);

import { defaultThemeConfigWithBasicColors } from "@hopper-b2b/themes";
import { asB2BMuiTheme } from "@hopper-b2b/themes";
import { ThemeOptions } from "@material-ui/core/styles";
import { LabComponentsPropsList } from "@material-ui/lab/themeAugmentation";

import LatoLightTtf from "../assets/fonts/Lato-Light.ttf";
import LatoLightItalicTtf from "../assets/fonts/Lato-LightItalic.ttf";
import LatoRegularTtf from "../assets/fonts/Lato-Regular.ttf";
import LatoRegularItalicTtf from "../assets/fonts/Lato-Italic.ttf";
import LatoBoldTtf from "../assets/fonts/Lato-Bold.ttf";
import LatoBoldItalicTtf from "../assets/fonts/Lato-BoldItalic.ttf";
import LatoBlackTtf from "../assets/fonts/Lato-Black.ttf";
import LatoBlackItalicTtf from "../assets/fonts/Lato-BlackItalic.ttf";

const volarisColors = {
  ...defaultThemeConfigWithBasicColors,
  "black-alpha-40": "#00000052",
  "black-alpha-60": "#000000A3",
  "black-alpha-100": "#000000F5",
  black: "#080206",
  "black-20": "rgba(0, 0, 0, 0.96)",
  "black-30": "rgba(0, 0, 0, 0.64)",
  "blue-10": "#E2F2FB",
  "blue-20": "#C5E5F6",
  "blue-30": "#73C1E9",
  "blue-50": "#176CB8",
  "blue-60": "#0058A7",
  "brown-20": "#E6E0DE",
  "brown-30": "#814B00",
  "brown-50": "#816359",
  "citrus-10": "#EBF5BA",
  "citrus-20": "#BCEBCC",
  "citrus-30": "#A0C61B",
  "citrus-50": "#567400",
  "citrus-60": "#486000",
  "cobalt-10": "#EBEFFC",
  "cobalt-20": "#D9E1F9",
  "cobalt-60": "#284EC1",
  "cobalt-50": "#3E62D0",
  "cobalt-70": "#1035A3",
  "gray-10": "#EFEFEF",
  "gray-20": "#E1E1E1",
  "gray-30": "#B8B8B8",
  "gray-40": "rgba(0, 0, 0, 0.32)",
  "gray-50": "#6A6A6A",
  "gray-80": "#6A6A6A",
  "gray-100": "#111111",
  "green-10": "#DDF5E5",
  "green-20": "#BCEBCC",
  "green-30": "#57CE7F",
  "green-50": "#0C7A3A",
  "green-80": "#2E7D32",
  "green-banner": "#EAF2EB",
  "green-system": "#60B955",
  "lavender-10": "#EEEEFF",
  "lavender-20": "#DEDEFF",
  "lavender-50": "#615BCC",
  "lavender-60": "#4F46BE",
  "orange-10": "#FFECD6",
  "orange-20": "#FFDAB1",
  "orange-30": "#FFDAB1",
  "orange-50": "#AF4D0E",
  "pink-10": "#FBEBF4",
  "pink-20": "#F7D7EA",
  "pink-40": "#E063B1",
  "pink-50": "#C41397",
  "pink-60": "#A4107E",
  "purple-10": "#F7EFF5",
  "purple-20": "#E5C2DC",
  "purple-40": "#B97EC9",
  "purple-50": "#A12885",
  "purple-60": "#762867",
  "purple-80": "#6B2159",
  "purple-90": "#4F1B45",
  "red-10": "#FFEAE8",
  "red-20": "#FFD8D5",
  "red-30": "#FF9D98",
  "red-40": "#D32F2F",
  "red-50": "#D01D1C",
  "red-banner": "#FFEBEA",
  "teal-10": "#DBF4F4",
  "teal-20": "#B7EAEA",
  "teal-30": "#4FC9CA",
  "teal-50": "#0D7489",
  "teal-60": "#0B6072",
  "caption-gray": "#505050",
  "dark-gray": "#323232",
  "medium-gray": "#808080",
  "system-coral": "#E04846",
  "system-gray-30": "#D9D9D9",
  "banner-bg": "#F6F6F6",
  white: "#ffffff",
  "white-alpha-50": "#FFFFFF7A",
  "white-alpha-80": "#FFFFFFCC",
  "white-alpha-90": "#FFFFFFE0",
  "yellow-20": "#FFEFC1",
  "yellow-30": "#EBAE2E",
  "black-disabled": "#BBBBBB",
  "background-secondary": "#F7F7F7",
};
export const volarisVariables = {
  ...volarisColors,
  primary: "var(--purple-50)",
  "primary-text": "var(--black)",
  "secondary-text": "var(--black-alpha-60)",
  "secondary-button-background": "var(--grey)",
  "primary-disabled": "var(--black-disabled)",
  "primary-hover": "var(--black-hover)",
  "button-background": "var(--primary)",
  "button-background-disabled": "var(--primary-disabled)",
  "button-background-hover": "var(--primary-hover)",
  "button-border": "2px solid var(--primary)",
  "halfsheet-border-radius": "0.5rem",
  "mobile-banner": "var(--primary)",
  "loading-popup": "var(--white)",
  "link-text": "var(--primary)",
  "action-link-active-text": "var(--primary)",
  "hamburger-menu-color": "var(--primary)",
  "filter-background-selected": "var(--purple-20)",
  "card-tag-text": "var(--green-50)",
  "card-tag-background": "var(--green-10)",
  "bucket-0-color": "var(--white)",
  "bucket-1-color": "var(--white)",
  "bucket-2-color": "var(--white)",
  "bucket-3-color": "var(--white)",
  "info-details-popup-border": "var(--grey-10)",
  "inverse-text": "var(--white)",
  "input-label-text": "var(--primary-text)",
  "red-error-color": "var(--red-50)",
  "card-border": "2px solid var(--gray-10)",
  "card-border-thin": "1px solid var(--gray-10)",
  "success-color": "var(--citrus-20)",
  "pending-color": "var(--yellow-20)",
  "price-prediction-gradient-wait": "var(--red-40)",
  "price-prediction-gradient-fair": "var(--orange-30)",
  "price-prediction-gradient-good": "var(--yellow-20)",
  "price-prediction-gradient-great": "var(--citrus-20)",
  "spinner-border-color": "var(--grey)",
  "spinner-top-border-color": "var(--primary)",
  "date-hover-color": "transparent",
  "warning-error-color-transparent": "var(--yellow-10)",
  "disruption-background-color": "var(--citrus-20)",
  "fare-details-banner": "var(--primary)",
  "fare-details-banner-text": "var(--primary-text)",
  "search-autocomplete-subheader-background": "var(--white)",
  "button-border-radius": "29px",
  "filter-button-border-radius": "6px",
  "border-radius": "6px",
  "border-radius-cards": "12px",
  "border-radius-buttons": "24px",
  "border-default": "var(--gray-10)",
  "content-default": "var(--black-20)",
  "content-subtle": "var(--black-30)",
  "content-disabled": "var(--gray-40)",
  "surface-subtle": "var(--gray-10)",
  // calendar
  "date-selected-color": "var(--purple-10)",
  "date-selected-text-color": "var(--primary-text)",
  "date-text-color": "var(--primary-text)",
  "date-disabled-color": "#F2F2F2",
  "date-calendar-border-radius": "8px",
  // header
  "header-primary": "var(--primary-text)",
  "header-travel-destination": "var(--white)",
  "header-travel-passenger": "var(--grey-10)",
  // search
  "search-field-label": "var(--primary-text)",
  "search-field-text": "var(--secondary-text)",
  // header
  "app-bar-height": "64px",
  "plus-days-color": "var(--system-coral)",
  // dropdown
  "dropdown-box-shadow":
    "0px 1px 5px 0px rgba(0, 0, 0, 0.02), 0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14)",
};

// .ptBaseModule-MuiRadio-colorSecondary.Mui-checked
const volarisTheming: ThemeOptions & Partial<LabComponentsPropsList> = {
  palette: {
    type: "light",
    primary: {
      main: volarisColors["purple-50"],
      contrastText: volarisColors.white,
    },
    text: {
      primary: volarisColors.black,
      secondary: volarisColors["gray-80"],
      disabled: volarisColors.white,
      hint: volarisColors.white,
    },
    action: {
      disabled: volarisColors["gray-80"],
    },
  },
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(","),
    htmlFontSize: 16,
    fontSize: 16,
    h1: {
      fontSize: "2.5rem",
    },
    h2: {
      fontSize: "2.25rem",
    },
    h3: {
      fontSize: "1.75rem",
    },
    h4: {
      fontSize: "1.375rem",
      fontWeight: 700,
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    h6: {
      fontSize: "1rem",
    },
    body1: {
      fontSize: "0.875rem",
    },
    body2: {
      fontSize: "1rem",
    },
    caption: {
      fontSize: "0.75rem",
    },
    overline: {
      fontSize: "1rem",
    },
    subtitle1: {
      fontSize: "0.875rem",
    },
    subtitle2: {
      fontSize: "1rem",
    },
    button: {
      fontSize: "1rem",
      lineHeight: "1.25rem",
      textTransform: "none" as const,
    },
  },
  shape: {
    borderRadius: 20,
  },
  spacing: [0, 4, 8, 16, 32, 64, 128],
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
      rounded: {
        borderRadius: volarisVariables["border-radius-cards"],
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: "6px",
      },
    },
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 300,
            src: `
              local("Lato Light"),
              url(${LatoLightTtf}) format("truetype");
            `,
          },
          {
            fontFamily: "Lato",
            fontStyle: "italic",
            fontWeight: 300,
            src: `
              local("Lato Light Italic"),
              url(${LatoLightItalicTtf}) format("truetype");
            `,
          },
          {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 400,
            src: `
              local("Lato Regular"),
              url(${LatoRegularTtf}) format("truetype");
            `,
          },
          {
            fontFamily: "Lato",
            fontStyle: "italic",
            fontWeight: 400,
            src: `
              local("Lato Italic"),
              url(${LatoRegularItalicTtf}) format("truetype");
            `,
          },
          {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 700,
            src: `
              local("Lato Bold"),
              url(${LatoBoldTtf}) format("truetype");
            `,
          },
          {
            fontFamily: "Lato",
            fontStyle: "italic",
            fontWeight: 700,
            src: `
              local("Lato Bold Italic"),
              url(${LatoBoldItalicTtf}) format("truetype");
            `,
          },
          {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 900,
            src: `
              local("Lato Black"),
              url(${LatoBlackTtf}) format("truetype");
            `,
          },
          {
            fontFamily: "Lato",
            fontStyle: "italic",
            fontWeight: 900,
            src: `
              local("Lato Black Italic"),
              url(${LatoBlackItalicTtf}) format("truetype");
            `,
          },
        ],
      },
    },
    MuiButton: {
      root: {
        height: 50,
        fontWeight: 700,
      },
      sizeSmall: {
        height: 32,
      },
      sizeLarge: {
        height: 60,
        fontSize: "1rem",
        fontWeight: 700,
      },
      iconSizeSmall: {
        "& > *:first-child": {
          fontSize: 12,
        },
      },
      contained: {
        "&:hover": {
          backgroundColor: volarisVariables["purple-80"],
          borderColor: volarisVariables["purple-80"],
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: volarisVariables["purple-80"],
            borderColor: volarisVariables["purple-80"],
          },
        },
        "&:disabled": {
          color: volarisColors.white,
          backgroundColor: volarisVariables["primary-disabled"],
        },
      },
      containedPrimary: {
        backgroundColor: volarisVariables.primary,
        "&:hover": {
          backgroundColor: volarisVariables["purple-80"],
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: volarisVariables["purple-80"],
          },
        },
      },
      outlined: {
        backgroundColor: volarisColors.white,
        borderColor: volarisColors["grey-11"],
      },
      textPrimary: {
        color: volarisColors["purple-50"],
        "&:hover": {
          backgroundColor: volarisColors["purple-10"],
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: volarisColors["purple-10"],
          },
        },
      },
      text: {
        color: volarisColors["purple-50"],
        "&:hover": {
          backgroundColor: volarisColors["purple-10"],
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: volarisColors["purple-10"],
          },
        },
      },
      outlinedPrimary: {
        backgroundColor: volarisColors.white,
        borderColor: volarisVariables.primary,
        color: volarisVariables.primary,
        border: "2px solid",
        borderRadius: "2rem",
        fontWeight: 700,

        "&:hover": {
          backgroundColor: volarisColors["purple-10"],
          border: "2px solid",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: volarisColors["purple-10"],
            border: "2px solid",
          },
        },
      },
    },
    MuiLink: {
      underlineHover: {
        "&:hover": {
          "text-decoration": "none",
        },
      },
    },
    MuiFilledInput: {
      root: {
        color: volarisColors["primary-text"],
        height: 60,
        backgroundColor: volarisColors.white,
        borderRadius: volarisVariables["border-radius"],
        borderTopLeftRadius: volarisVariables["border-radius"],
        borderTopRightRadius: volarisVariables["border-radius"],
        border: `1px solid ${volarisColors["gray-20"]}`,
        "&.Mui-focused, &.Mui-hover": {
          backgroundColor: volarisColors.white,
        },
        "&:hover": {
          backgroundColor: "inherit",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "inherit",
          },
        },
        "&:focus-within": {
          border: "1px solid var(--primary)",
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 14,
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "1rem",
      },
    },
    MuiAutocomplete: {
      popper: {
        borderRadius: volarisVariables["border-radius"],
      },
      popupIndicator: {
        "& path": {
          fill: volarisVariables["purple-50"],
        },
      },
      inputRoot: {
        "&[class*='MuiFilledInput-root']": {
          paddingLeft: 36,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        height: 60,
        backgroundColor: volarisColors.white,
      },
      notchedOutline: {
        borderColor: volarisColors["grey-11"],
      },
    },
    MuiFormControl: {
      root: {
        borderColor: volarisColors["grey-11"],
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: volarisVariables.primary,
        "&.Mui-checked": {
          color: volarisVariables.primary,
        },
      },
      root: {
        color: volarisVariables.primary,
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        "&.Mui-checked": {
          color: volarisVariables.primary,
        },
      },
    },
    MuiSlider: {
      thumb: {
        border: "1px solid rgba(0, 0, 0, 0.14)",
        "&:hover,": {
          boxShadow: "none",
        },
        "&:focusVisible": {
          boxShadow: "none",
        },
        "&:active": {
          boxShadow: "none",
        },
      },
      root: {
        color: volarisVariables.primary,
      },
      rail: {
        color: volarisVariables["gray-50"],
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          color: volarisColors["purple-50"],
          backgroundColor: "white",
        },
      },
    },
    MuiMenuItem: {
      root: {
        "&:hover": {
          color: "inherit",
          backgroundColor: volarisColors["purple-10"],
        },
        "&$selected": {
          color: volarisColors["purple-50"],
          backgroundColor: "white",
        },
      },
    },
    MuiSnackbar: {
      root: {
        borderRadius: "1rem",
      },
    },
  },
};

export const muiTheme = asB2BMuiTheme(volarisTheming);

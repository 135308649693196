import { assign } from "xstate";
import { Product, ProductOpaqueValue } from "@b2bportal/purchase-api";
import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { getCfarCartUpdateRequest } from "./selectors";
import {
  AirCfarProduct,
  InitCfarStateEvent,
  PartialParentContextWithAirCfarV1,
} from "./types";

export const actions = {
  addCfarProduct: assign(
    (context: PartialParentContextWithAirCfarV1, _event: unknown) => {
      if (context[ParentState.cancelForAnyReasonV1]?.selectedOffer != null) {
        const filterOutCfarFromProducts = (
          context[ParentState.cartUpdate].addProducts ?? []
        ).filter((p: ProductOpaqueValue) => p.type !== Product.AirCfar);

        const request = getCfarCartUpdateRequest({ context });
        const newCfarProduct: AirCfarProduct = {
          type: Product.AirCfar,
          value: request,
        };

        context[ParentState.cartUpdate].addProducts = [
          ...filterOutCfarFromProducts,
          newCfarProduct,
        ];
      }
      return context;
    }
  ),
  setCfarState: assign(
    (context: PartialParentContextWithAirCfarV1, event: InitCfarStateEvent) => {
      context[ParentState.cancelForAnyReasonV1].selectedOffer =
        event.state.selectedOffer;
      context[ParentState.cancelForAnyReasonV1].cfarQuoteId =
        event.state.cfarQuoteId;
      context[ParentState.cancelForAnyReasonV1].cfarOffers =
        event.state.cfarOffers;
      context[
        ParentState.cancelForAnyReasonV1
      ].aggregatedShopTrackingProperties =
        event.state.aggregatedShopTrackingProperties;
      return context;
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject({
  ...actions,
});

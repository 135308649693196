import { ThemeOptions } from "@material-ui/core/styles";
import { asB2BMuiTheme } from "@hopper-b2b/themes";
import { defaultThemeConfigWithBasicColors } from "@hopper-b2b/themes";

const hopperColors = {
  ...defaultThemeConfigWithBasicColors,
  black: "#eeeeee",
  white: "#282828",
  grey: "#5e5e5e",
  "black-disabled": "#282828",
  "black-hover": "#ffffff",
  green: "#009A51",
  yellow: "#F6BC2F",
  orange: "#E65300",
  red: "#F83446",
  blue: "#266ef1",
  "red-10": "#FFF0EE",
  "red-200": "#de1135",
  "grey-3": "#5e5e5e",
  "grey-11": "#5e5e5e",
  "grey-15": "#282828",
  "grey-200": "#eeeeee",
  "grey-1000": "#f2f2f2",
  "grey-2000": "#686868",
  "grey-3000": "#5e5e5e",
  "grey-4000": "#4B4B4B",
  "grey-5000": "#a6a6a6",
  "grey-6000": "#545454",
  "blue-100": "#EFF3FE",
  "blue-200": "#D4E2FC",
  "blue-300": "#EFF4FE",
  "blue-400": "#276EF1",
  "green-200": "#0e8345",
  "light-red": "#de1135",
  "light-orange": "#e65300",
  "light-yellow": "#d79900",
  "lightest-yellow": "#fdf2dc",
  "light-green": "#009a51",
  "lightest-green": "#eaf6ed",
  "hopper-spinner-blue": "#5b91f5",
};
export const hopperDarkVariables = {
  ...hopperColors,
  primary: "var(--black)",
  "primary-text": "var(--black)",
  "secondary-text": "var(--grey-5000)",
  "secondary-button-background": "var(--grey)",
  "black-text": "var(--black)",
  "primary-disabled": "var(--black-disabled)",
  "primary-hover": "var(--black-hover)",
  "button-background": "var(--primary)",
  "button-background-disabled": "var(--primary-disabled)",
  "button-background-hover": "var(--primary-hover)",
  "button-border": "2px solid var(--blue-50)",
  "mobile-banner": "var(--primary)",
  "loading-popup": "var(--white)",
  "link-text": "var(--blue-50)",
  "action-link-active-text": "var(--primary)",
  "hamburger-menu-color": "var(--white)",
  "filter-background-selected": "var(--grey)",
  "card-tag-text": "var(--text-green-2)",
  "card-tag-background": "var(--text-green-2-transparent)",
  "bucket-0-color": "var(--light-green)",
  "bucket-1-color": "var(--light-yellow)",
  "bucket-2-color": "var(--light-orange)",
  "bucket-3-color": "var(--light-red)",
  "inverse-text": "var(--white)",
  "input-label-text": "var(--black)",
  "input-background": "var(--grey-2000)",
  "red-error-color": "var(--red-200)",
  "card-border": "3px solid var(--card-border-color)",
  "price-freeze-card-color": "var(--blue-100)",
  "price-freeze-card-button": "var(--blue-200)",
  "price-freeze-tag": "var(--blue)",
  "success-color": "var(--green-200)",
  "pending-color": "var(--yellow)",
  "price-freeze-purchase-banner-background-color": "var(--blue-300)",
  "price-freeze-purchase-banner-text-color": "var(--white)",
  "price-prediction-gradient-wait": "var(--light-red)",
  "price-prediction-gradient-fair": "var(--light-orange)",
  "price-prediction-gradient-good": "var(--light-yellow)",
  "price-prediction-gradient-great": "var(--light-green)",
  "spinner-border-color": "var(--grey)",
  "spinner-top-border-color": "var(--coral)",
  "price-freeze-text": "var(--blue-400)",
  "date-hover-color": "transparent",
  "warning-error-color-transparent": "var(--lightest-yellow)",
  "disruption-background-color": "var(--lightest-green)",
  "fare-details-banner": "var(--grey)",
  "fare-details-banner-text": "var(--black-text)",
  "search-autocomplete-subheader-background": "transparent",
};

const hopperDarkTheming: ThemeOptions = {
  palette: {
    type: "dark",
    primary: {
      light: "#01AAE4",
      main: "#01AAE4",
      contrastText: "#01AAE4",
    },
    secondary: {
      light: "#FA6866",
      main: "#FA6866",
      contrastText: "#FA6866",
    },
    text: {
      primary: "#bbb",
      secondary: "#878787",
      disabled: "#FFF",
      hint: "#FFF",
    },
  },
  typography: {
    fontFamily: "hopper Move Text, sans-serif",
    htmlFontSize: 14,
    fontSize: 14,
    h1: {
      fontFamily: "hopper Move Text, sans-serif",
      fontSize: "2.5rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    h2: {
      fontFamily: "hopper Move Text, sans-serif",
      fontSize: "1.75rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    h3: {
      fontFamily: "hopper Move Text, sans-serif",
      fontSize: "1.5rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    h4: {
      fontFamily: "hopper Move Text, sans-serif",
      fontSize: "1.375rem",
      letterSpacing: 0,
    },
    h5: {
      fontFamily: "hopper Move Text, sans-serif",
      fontSize: "1.25rem",
      letterSpacing: 0,
    },
    h6: {
      fontFamily: "hopper Move Text, sans-serif",
      fontSize: "1rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    body1: {
      fontFamily: "hopper Move Text, sans-serif",
      fontSize: "1rem",
      letterSpacing: 0,
    },
    body2: {
      fontFamily: "hopper Move Text, sans-serif",
      fontSize: "1rem",
      letterSpacing: 0,
    },
    caption: {
      fontFamily: "hopper Move Text, sans-serif",
      fontSize: "0.875rem",
      letterSpacing: 0,
    },
    overline: {
      fontFamily: "hopper Move Text, sans-serif",
      fontSize: "1rem",
      letterSpacing: 0,
    },
    subtitle1: {
      fontFamily: "hopper Move Text, sans-serif",
      fontSize: "0.875rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    subtitle2: {
      fontFamily: "hopper Move Text, sans-serif",
      fontSize: "0.875rem",
      letterSpacing: 0,
      color: "#505050",
    },
    button: {
      fontFamily: "hopper Move Text, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.25rem",
      textTransform: "none" as const,
      letterSpacing: 0,
    },
  },
  overrides: {
    MuiLink: {
      root: {
        color: "#878787",
      },
      underlineHover: {
        "&:hover": {
          "text-decoration": "none",
        },
      },
    },
  },
};

export const muiDarkTheme = asB2BMuiTheme(hopperDarkTheming);

import { useCallback } from "react";

import { useI18nContext } from "@hopper-b2b/i18n";

import styles from "./CurrencySelectForm.module.scss";
import { SupportedCurrencies, supportedCurrencies } from "./constants";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import {
  getCurrency,
  setCurrency,
  getCurrencyDisplayName,
} from "@virtual-interline/utils";

export const CurrencySelectRadio = () => {
  const { t } = useI18nContext();
  const currency = getCurrency<SupportedCurrencies>();
  const handleCurrencySelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrency(e.target.value);
      window?.location?.reload();
    },
    []
  );

  return (
    <FormControl className={styles.currencySelectForm}>
      <legend className={styles.legend}>{t("currencies")}</legend>
      <RadioGroup
        aria-labelledby="currency-radio-buttons-group-label"
        name="currency-buttons-group"
        onChange={handleCurrencySelect}
      >
        {supportedCurrencies?.map((c) => (
          <FormControlLabel
            key={c}
            name="currency"
            id={c}
            value={c}
            defaultChecked={currency === c}
            checked={currency === c}
            control={<Radio size="small" color="primary" />}
            label={t(getCurrencyDisplayName(c))}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

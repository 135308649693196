import { useI18nContext } from "@hopper-b2b/i18n";
import { Typography, Tooltip } from "@material-ui/core";
import { IconName } from "../Icon";
import { IconComponent } from "../IconComponent";
import "./styles.scss";

const MIXED_CABIN_DIVIDER = " - ";

interface IMixedCabinToolTipProps {
  showDivider?: boolean;
}
export const MixedCabinToolTip = ({
  showDivider = true,
}: IMixedCabinToolTipProps) => {
  const { t } = useI18nContext();
  return (
    <>
      {showDivider && <span>{MIXED_CABIN_DIVIDER}</span>}
      <Tooltip
        placement="bottom"
        title={
          <Typography variant="subtitle2" className="tooltip-text">
            {t("mixedCabin.tooltip")}
          </Typography>
        }
        classes={{ tooltip: "mixed-cabin-tooltip-text" }}
        enterTouchDelay={50}
        leaveTouchDelay={2500}
        tabIndex={0}
      >
        <span className="mixed-cabin-tooltip">
          {t("mixedCabin.icon")}
          <IconComponent className="info-icon" name={IconName.InfoCircle} />
        </span>
      </Tooltip>
    </>
  );
};

import { WalletError } from "@b2bportal/hopper-wallet-api";
import {
  Payment,
  PaymentId,
  PaymentOpaqueValue,
} from "@b2bportal/purchase-api";
import {
  WalletVouchersResponse,
  WalletVoucherStatusEnum,
} from "@hopper-b2b/types";
import { assign, DoneInvokeEvent } from "xstate";
import { getObjectKeysAsObject } from "../../../helpers";
import { ParentState } from "../../../types/common";
import { RemoveSelectedOfferEvent, SetSelectedOfferEvent } from "./events";
import { TravelWalletEnum } from "@b2bportal/lodging-api";
import { getWalletOfferFromId, getWalletOffersChoice } from "./selectors";
import { ParentContextWithWallet } from "./types";

export const actions = {
  addDiscounts: assign(
    (context: ParentContextWithWallet, event: SetSelectedOfferEvent) => {
      const currentSelectedOffers = event.offerId
        ? getWalletOfferFromId({ context }, event.offerId)
        : getWalletOffersChoice({ context });
      const getOfferArr = () => {
        return currentSelectedOffers
          .map((offer) => {
            context[ParentState.wallet].offerStatus = event.offerId
              ? WalletVoucherStatusEnum.EXPLICITLY_ACCEPTED
              : WalletVoucherStatusEnum.DEFAULT_OPT_IN;

            if (offer.percentage) {
              // get value before taxes

              // TODO: fix selecting the product without using index
              const totalPriceBeforeTaxes =
                context[ParentState.cartQuote].quoteBreakdown?.products[0]
                  ?.product.value.pricingV1?.tripTotal?.fiat ??
                context[ParentState.cartQuote].quoteBreakdown?.products[0]
                  ?.product.value.pricingSummary?.totalPricing?.total?.fiat;
              if (totalPriceBeforeTaxes) {
                // calculate percent off
                const paymentOffer: PaymentOpaqueValue = {
                  type: Payment.Wallet,
                  value: {
                    offerId: offer.id,
                    totalDiscountAmount: {
                      ...totalPriceBeforeTaxes,
                      value: (
                        offer.percentage * totalPriceBeforeTaxes.value
                      ).toFixed(2),
                    },
                    paymentType: "Offer",
                  },
                };

                return paymentOffer;
              } else {
                return null;
              }
            } else {
              const paymentOffer: PaymentOpaqueValue = {
                type: Payment.Wallet,
                value: {
                  offerId: offer.id,
                  totalDiscountAmount: offer.amount,
                  paymentType: "Offer",
                },
              };

              return paymentOffer;
            }
          })
          .filter((offer) => !!offer);
      };

      const offerArr = getOfferArr();

      context[ParentState.cartUpdate].addPayments = [
        ...(context[ParentState.cartUpdate].addPayments ?? []),
        ...offerArr,
      ];

      context[ParentState.wallet].offerApplied = true;
      context[ParentState.wallet].selectedOffer = currentSelectedOffers?.[0];

      return context;
    }
  ),
  removeDiscounts: assign(
    (context: ParentContextWithWallet, event: RemoveSelectedOfferEvent) => {
      // Validate that the current selected offer matches the id, remove it, then redo breakdown
      const offerToRemove = context[ParentState.wallet].selectedOffer;
      const offerAsPayment: PaymentId = {
        id: offerToRemove.id,
        payment: "Wallet",
      };

      context[ParentState.wallet].offerApplied = false;
      context[ParentState.wallet].offerStatus =
        WalletVoucherStatusEnum.EXPLICITLY_DECLINED;

      context[ParentState.cartUpdate].removePayments = [
        ...(context[ParentState.cartUpdate].removePayments ?? []),
        ...[offerAsPayment],
      ];

      context[ParentState.wallet].selectedOffer = null;

      return context;
    }
  ),
  setWalletVisited: assign(
    (context: ParentContextWithWallet, _event: unknown) => {
      context[ParentState.wallet].visited = true;

      return context;
    }
  ),
  setWalletError: assign(
    (context: ParentContextWithWallet, event: DoneInvokeEvent<WalletError>) => {
      // TODO: Assign error here
      context[ParentState.wallet].fetchedOffers = false;

      context[ParentState.wallet].error = event.data;

      return context;
    }
  ),
  handleGetWalletOffers: assign(
    (
      context: ParentContextWithWallet,
      event: DoneInvokeEvent<WalletVouchersResponse>
    ) => {
      const offers = event.data.offers;
      const walletOffersNoCredits = event.data.offers.filter(
        (offer) => offer.payment.value.paymentType === TravelWalletEnum.Offer
      );
      context[ParentState.wallet].fetchedOffers = true;
      if (offers.length > 0) {
        // we actually have offers
        context[ParentState.wallet].offers = event.data.offers;
        context[ParentState.wallet].selectedOffer = walletOffersNoCredits[0];
      }

      return context;
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject({
  ...actions,
});

import { Typography } from "@material-ui/core";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { PATH_TERMS_HTS } from "../../utils/constants";

export const SpanishMissedConnectionsTermsAndConditions = () => (
  <>
    <Typography
      variant="h3"
      className={clsx(styles.bold, styles.underline, styles.mb, styles.center)}
    >
      Términos de servicio
    </Typography>

    <Typography variant="body2" className={clsx(styles.bold, styles.mb)}>
      Garantía de conexión perdida de HTS Connect de Hopper - Clientes de
      Volaris
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      El servicio de garantía de conexión perdida de Hopper Technology Services
      (“<span className={styles.bold}>HTS</span>” o “
      <span className={styles.bold}>Hopper</span>”) para los clientes de Volaris
      (el “<span className={styles.bold}>Service</span>”, tal como se utiliza en
      esta subsección), se incluye sin cargo adicional en ciertos itinerarios
      comprados que conectan múltiples vuelos de Volaris a aerolíneas no
      afiliadas (“<span className={styles.bold}>Reservaciones HTS Connect</span>
      ”). El Servicio ofrece al comprador un servicio gratuito de cambio de
      reservación en caso de que pierda su vuelo de conexión por una razón fuera
      de su control.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Tenga en cuenta que el Servicio se aplica solo a las Reservaciones HTS
      Connect y no se puede comprar como un servicio independiente. La
      Reservación HTS Connect y el Servicio están sujetos a los Términos de
      servicio de HTS Connect que se pueden consultar{" "}
      <a href={PATH_TERMS_HTS} target="_blank" rel="noreferrer">
        aquí
      </a>
      .
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      El Servicio está sujeto a los siguientes términos, requisitos y
      restricciones:
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      1. <span className={styles.bold}>Cómo funciona</span>. El Servicio se
      aplicará al comprador de la Reservación HTS Connect y a los demás
      pasajeros en la misma Reservación HTS Connect. Puede utilizar el Servicio
      solo si pierde un vuelo de conexión en su Reservación HTS Connect debido a
      una razón fuera de su control (p. ej., la aerolínea cancela el vuelo de
      conexión o sale sin usted a bordo debido a un retraso en un vuelo
      anterior) (“
      <span className={styles.bold}>Interrupción Elegible</span>”). Si
      experimenta una Interrupción Elegible, puede utilizar el Servicio
      contactándose con Atención al cliente de Volaris a través de la
      información facilitada en el correo electrónico de confirmación de la
      reserva, quienes luego lo conectarán con Hopper (colectivamente, “
      <span className={styles.bold}>Atención al cliente</span>”) para cambiar su
      reservación y la de otros pasajeros elegibles en un nuevo vuelo de
      conexión a su destino sin costo alguno, hasta un monto máximo de USD 2000
      (incluidos impuestos y tarifas) por pasajero (“
      <span className={styles.bold}>Límite máximo de servicio</span>”), para el
      nuevo vuelo, y sujeto a las restricciones adicionales para nuevos vuelos
      que se explican en el párrafo 6 a continuación. Si decide utilizar el
      Servicio después de una Interrupción Elegible y los costos por pasajero
      del nuevo vuelo de conexión superan el Límite máximo de servicio, se le
      pedirá que pague los costos por pasajero que superen dicho límite cuando
      realice el cambio de la reservación. Si no se toma una decisión de
      utilizar el Servicio, el Servicio vencerá veinticuatro (24) horas después
      de la Interrupción Elegible.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      2. <span className={styles.bold}>Interrupción Elegible</span>. Para optar
      por el Servicio, la conexión perdida como parte de su Reservación HTS
      Connect debe producirse por una razón permitida fuera de su control. Las
      razones permitidas incluyen, entre otras, retrasos debido a problemas con
      las aerolíneas, problemas de aduana o el clima. Todas las determinaciones
      de si un vuelo de conexión perdido se debió a una razón elegible serán a
      discreción comercial de Hopper.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      3.{" "}
      <span className={styles.bold}>
        Regímenes de derechos de los pasajeros
      </span>
      . Ciertas jurisdicciones, incluidas Canadá y la Unión Europea, han
      adoptado leyes y/o regulaciones que requieren que las aerolíneas compensen
      a los pasajeros en caso de ciertas interrupciones en los viajes aéreos
      regulares (un “
      <span className={styles.bold}>Régimen de derechos de los pasajeros</span>
      ”). En el caso de una interrupción de este tipo, es posible que pueda
      optar por una compensación bajo el Régimen de derechos de los pasajeros,
      independientemente de si elige utilizar el Servicio.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      4.{" "}
      <span className={styles.bold}>
        Opción de cambio de reservación razonable
      </span>
      . No tendrá derecho a utilizar el Servicio en caso de que la aerolínea
      cancele el vuelo de conexión en su Reservación HTS Connect, pero le ofrece
      una opción de cambio de reservación razonable que puede abordar. Una
      opción de cambio de reservación razonable significa un nuevo vuelo de
      conexión que sale: (i) dentro de una (1) hora de la hora de salida
      programada de su vuelo de conexión perdido o (ii) si su vuelo de conexión
      perdido estaba programado para salir más tarde de las 8:00 p. m. hora
      local, antes de las 9:00 a. m. hora local del día siguiente. Todas las
      determinaciones ofrecidas por una aerolínea, sobre si cambiar la
      reservación es una “opción de cambio de reservación razonable”, se
      realizarán a discreción comercial de Hopper.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.{" "}
      <span className={styles.bold}>
        Cosas que debe saber sobre reservar un vuelo de conexión nuevo
      </span>
      . Si elige utilizar el Servicio después de una Interrupción Elegible,
      puede cambiar la reservación contactándose con Atención al Cliente en un
      nuevo vuelo de conexión sin costo (sujeto a la Sección 1) bajo las
      siguientes condiciones:
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      (i) el nuevo vuelo de conexión debe ser al mismo destino.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      (ii) el nuevo vuelo de conexión puede estar en cualquier aerolínea
      disponible proporcionada por Atención al cliente, pero debe estar en la
      misma clase de tarifa o inferior que la reservación original; y debe poder
      llegar a la puerta del nuevo vuelo de conexión a tiempo para abordar.
      Hopper no es responsable de garantizar que llegue a tiempo a la puerta de
      embarque y no pagará ningún costo adicional si pierde su nuevo vuelo de
      conexión.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      (iii) Si facturó el equipaje, debe coordinar con la aerolínea original
      para retirarlo antes o después del nuevo vuelo de conexión a su destino.
      Hopper no es responsable de ninguna recuperación de equipaje y no cubrirá
      ninguna tarifa de equipaje.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      (iv) los vuelos ofrecidos para cambiar la reservación a través del
      Servicio serán para el mismo día o solo al día siguiente.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      6. <span className={styles.bold}>Quién está incluido en el servicio</span>
      . Hopper proporciona el Servicio a todos los pasajeros incluidos en el
      itinerario de viaje en el momento de la Reservación HTS Connect, hasta el
      valor del Límite máximo de servicio por pasajero. Si hay más de un
      pasajero en la reservación, el pasajero que realizó la reservación
      determinará cómo ejercer el Servicio, que se aplicará a cualquier otro
      pasajero incluido en la reservación.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      7. <span className={styles.bold}>Otras restricciones</span>. Hopper no
      será responsable ante usted por las tarifas y costos en los que incurra
      (p. ej., tarifas de equipaje o mejoras) que no sean los costos del nuevo
      vuelo de conexión hasta el Límite máximo de servicio por pasajero. El
      Servicio es válido para un (1) vuelo de conexión perdido por pasajero en
      relación con el viaje al que está asociado el Servicio. El Servicio no se
      transfiere a ninguna reservación futura realizada con HTS. En el caso de
      que realice un cambio de horario voluntario o la cancelación de su
      Reservación HTS Connect antes de su fecha de salida, el Servicio ya no
      será válido. Si cancela su vuelo durante el período proporcionado por su
      aerolínea durante el cual puede cancelar y recibir un reembolso de la
      aerolínea (denominado “período anulado”), el Servicio se anulará. El
      Servicio no es transferible.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      8. <span className={styles.bold}>No es un Seguro</span>. El Servicio es
      una garantía de que Hopper cambiará su reservación para un nuevo vuelo de
      conexión a su destino original si pierde su vuelo de conexión en una
      Reservación HTS Connect por un motivo fuera de su control, sujeto a las
      limitaciones establecidas en este documento. HTS ofrece a sus clientes el
      Servicio para brindar una experiencia flexible y sin contratiempos para
      las Reservaciones de HTS Connect. Los Términos del servicio no pretenden
      constituir una oferta en materia de seguros, no constituyen un seguro ni
      un contrato de seguro, y no sustituyen un seguro que tenga o pueda
      obtener.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Fecha de vigencia de estos Términos de servicio: Versión 1.1; Agosto 16,
      2024
    </Typography>
  </>
);

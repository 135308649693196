import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { PartialParentContextWithAirCfar } from "./types";

export const guards = {
  hasCfarIdAndEnabled: (
    ctx: PartialParentContextWithAirCfar,
    _event: unknown
  ) =>
    !!ctx[ParentState.cancelForAnyReason]?.cfarQuoteId &&
    ctx.featureFlags?.enableCfar,
};

export const GuardTypes = getObjectKeysAsObject(guards);

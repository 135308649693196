import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";

import { SelectPassengerEvent } from "./events";
import { FlightContextV2 } from "../../types";

export const guards = {
  haveAnyPassengersLoaded: (ctx: FlightContextV2) => {
    const userPassengers = ctx[ParentState.passengerInformation].userPassengers;
    if (userPassengers?.length && userPassengers.length > 0) {
      return true;
    }
    return false;
  },
  hasAnySelectedPassengers: (ctx: FlightContextV2) => {
    const selectedPassengerIds =
      ctx[ParentState.passengerInformation].selectedPassengerIds;
    return selectedPassengerIds.length > 0;
  },
  willHaveSelectedPassengers: (
    ctx: FlightContextV2,
    event: SelectPassengerEvent
  ) => {
    const selectedPassengerIds =
      ctx[ParentState.passengerInformation].selectedPassengerIds;
    return !(
      selectedPassengerIds.length === 1 &&
      selectedPassengerIds[0] === event.passengerId
    );
  },
  hasCurrentUserPassenger: (ctx: FlightContextV2) => {
    const currentUserPassenger =
      ctx[ParentState.passengerInformation].currentUserPassenger?.id;
    return !!currentUserPassenger;
  },
  notVisitedPassenger: (ctx: FlightContextV2) =>
    !ctx[ParentState.passengerInformation].visited,
  notValidated: (ctx: FlightContextV2) =>
    !ctx[ParentState.passengerInformation].isValidated,
};

export const GuardTypes = getObjectKeysAsObject(guards);

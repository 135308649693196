import { createContext } from "react";
import { FocusedInput } from "@datepicker-react/hooks";
import { PickerType } from "./types";
import { IMonthBucket } from "@hopper-b2b/types";
import { noop } from "@hopper-b2b/utilities";

export const defaultContext: IDateRangePickerContext = {
  // TODO add date parameter when implement context
  isDateSelected: () => false,
  isDateHovered: () => false,
  isFirstOrLastSelectedDate: () => false,
  isDateBlocked: () => false,
  isDateFocused: () => false,
  focusedDate: null,
  hoveredDate: null,
  onResetDates: noop,
  onDateHover: noop,
  onDateSelect: noop,
  onDateFocus: noop,
  goToPreviousMonths: noop,
  goToNextMonths: noop,
  startDate: null,
  endDate: null,
  focusedInput: null,
  pickerType: PickerType.RANGE,
  months: null,
};

interface IDateRangePickerContext {
  isDateSelected: (date: Date) => boolean;
  isDateHovered: (date: Date) => boolean;
  isFirstOrLastSelectedDate: (date: Date) => boolean;
  isDateBlocked: (date: Date) => boolean;
  isDateFocused: (date: Date) => boolean;
  isDateUnavailable?: (date: Date, isReturnDate: boolean) => boolean;
  focusedDate: Date | null;
  hoveredDate: Date | null;
  onResetDates: () => void;
  onDateHover: (date: Date | null) => void;
  onDateSelect: (date: Date) => void;
  onDateFocus: (date: Date) => void;
  goToPreviousMonths: () => void;
  goToNextMonths: () => void;
  focusedInput: FocusedInput;
  startDate: Date | null;
  endDate: Date | null;
  pickerType: PickerType;
  months: IMonthBucket[] | null;
}

export const context = createContext(defaultContext);

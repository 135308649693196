import { AxiosError } from "axios";
import { createContext, useCallback, useState } from "react";
import AxiosInterceptorWrapper, {
  IAxiosInterceptors,
} from "../AxiosInterceptorWrapper";
import retryOrFail from "./retryOrFail";

export type AuthorizationTokenState = "OK" | "UNAUTHORIZED";

export const UnauthorizedContext = createContext<AuthorizationTokenState>("OK");

export const UnauthorizationProvider = ({
  children,
  recaptchaActionKey,
  version,
}: React.PropsWithChildren<IAxiosInterceptors>) => {
  const [authState, setAuthState] = useState<AuthorizationTokenState>("OK");

  const errorAction = useCallback(
    (e: AxiosError) => {
      return retryOrFail(e)
        .then((res) => {
          setAuthState("OK");
          return res;
        })
        .catch(() => {
          setAuthState("UNAUTHORIZED");
          return Promise.reject(e);
        });
    },
    [setAuthState]
  );

  return (
    <UnauthorizedContext.Provider value={authState}>
      <AxiosInterceptorWrapper
        recaptchaActionKey={recaptchaActionKey}
        version={version}
        errorAction={errorAction}
      />
      {children}
    </UnauthorizedContext.Provider>
  );
};

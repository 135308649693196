import {
  priceFreezeApi,
  GenerateCustomOfferRequest,
  GenerateCustomOfferResponse,
  GenerateCustomOfferResponseEnum,
  Available,
} from "@b2bportal/price-freeze-api";
import axios from "axios";

import {
  getTripId,
  getSelectedOfferData,
  getPassengerMap,
} from "../../selectors";
import { AirPriceFreezeContext } from "../../context";

export const createCustomOffer = (
  context: AirPriceFreezeContext,
  _event: unknown
) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const tripId = getTripId({ context });
    const offerData = getSelectedOfferData({ context });
    const passengers = getPassengerMap({ context });

    if (offerData) {
      const req: GenerateCustomOfferRequest = {
        tripId,
        offerData,
        passengers,
      };

      try {
        const res = await priceFreezeApi(axios).apiV0PricefreezeOfferCustomPost(
          req
        );
        const maybeOffer = res.data as GenerateCustomOfferResponse;
        return maybeOffer.GenerateCustomOfferResponse ===
          GenerateCustomOfferResponseEnum.Available
          ? resolve((maybeOffer as Available).offer)
          : reject(
              `Price Freeze Offer ${maybeOffer.GenerateCustomOfferResponse}`
            );
      } catch (e) {
        reject(e);
      }
    } else {
      reject("Missing offer data");
    }
  });

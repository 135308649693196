//DO NOT CHANGE WITHOUT APPROVAL FROM FOUNDATIONS AND FRAUD
import { AxiosResponseHeaders, RawAxiosResponseHeaders } from "axios";

export const DEVICE_ID_HEADER = "X-Device-Id";
export const LOWERCASE_DEVICE_ID_HEADER = "x-device-id";
export const localStorageKey = "b2bportal-device-id";

export const getDeviceId = () => {
  try {
    return localStorage.getItem(localStorageKey);
  } catch (e) {
    console.error(e);
  }
};

export const setDeviceId = (deviceId: string) => {
  try {
    localStorage.setItem(localStorageKey, deviceId);
  } catch (e) {
    console.error(e);
  }
};

// Save a deviceId from the response's headers if id doesn't match what's in localStorage
export const validateAndSetDeviceId = (
  headers: AxiosResponseHeaders | RawAxiosResponseHeaders
) => {
  const deviceId = getDeviceId();

  const deviceIdHeader =
    headers[LOWERCASE_DEVICE_ID_HEADER] || headers[DEVICE_ID_HEADER];

  if (deviceIdHeader && deviceId !== deviceIdHeader) {
    setDeviceId(deviceIdHeader.toString());
  }
};

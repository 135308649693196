import * as React from "react";
import { TextField, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IMonth, Months } from "../../constants";
import "./styles.scss";

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});

const createMenuItems = (options: IMonth[]) =>
  options.map(({ label, value }, index) => {
    return (
      <MenuItem value={value || 0} key={value ? value + index : index}>
        {label}
      </MenuItem>
    );
  });

export const MonthDropdown = ({
  month,
  setMonth,
  onChange,
}: {
  month: string;
  setMonth: React.Dispatch<React.SetStateAction<string>>;
  onChange: () => void;
}) => {
  const classes = useStyles();
  const monthItems = createMenuItems(Months);

  const handleInputChange =
    (valueSetter: (value: any) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange();
      valueSetter(event.target.value);
    };

  return (
    <TextField
      id="month-select"
      select
      value={month ? parseInt(month) : 0}
      onChange={handleInputChange(setMonth)}
      InputProps={{ classes }}
      className="select-field"
    >
      {monthItems}
    </TextField>
  );
};

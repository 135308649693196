import { ActionButton } from "@hopper-b2b/ui-core";
import { Box, Button, Dialog } from "@material-ui/core";
import clsx from "clsx";
import { IconName } from "../Icon";
import { IconComponent } from "../IconComponent";
import "./styles.scss";

export interface IGenericInfoPopupProps {
  agentSubtitle?: string;
  agentTitle?: string;
  buttons: IGenericInfoButtonProps[];
  className?: string;
  image: JSX.Element;
  isAgent: boolean;
  isMobile?: boolean;
  open: boolean;
  subtitle: string | JSX.Element;
  title: string | JSX.Element;
  zIndex?: number;
  onClose?: () => void;
  closeIcon?: string;
}
export interface IGenericInfoButtonProps {
  buttonText: string;
  buttonWrapperClassName?: string;
  onClick?: () => void;
  defaultStyle?: "h4r-primary" | "h4r-secondary";
}

const defaultProps: Partial<IGenericInfoPopupProps> = {
  isAgent: false,
  zIndex: 1300,
};

export const GenericInfoPopup = (
  props: IGenericInfoPopupProps
): JSX.Element => {
  const {
    agentSubtitle,
    agentTitle,
    buttons,
    className,
    image,
    isMobile,
    isAgent,
    open,
    subtitle,
    title,
    zIndex,
    onClose,
    closeIcon,
  } = props;
  const displayTitle = isAgent && agentTitle ? agentTitle : title;
  const displaySubtitle = isAgent && agentSubtitle ? agentSubtitle : subtitle;

  return (
    <Dialog
      className={clsx(className, { mobile: isMobile }, "generic-info-popup")}
      onClose={onClose}
      open={open}
      style={{
        zIndex,
      }}
    >
      <Box className="generic-info-popup-content">
        {onClose && (
          <Box
            onClick={onClose}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {closeIcon ? (
              <img src={closeIcon} alt="close-icon" />
            ) : (
              <IconComponent
                ariaLabel="Close"
                className={clsx("close-button-icon")}
                name={IconName.Close}
              />
            )}
          </Box>
        )}
        <Box className="generic-info-popup-decor">{image}</Box>
        <Box className="generic-info-popup-title">{displayTitle}</Box>
        <Box className="generic-info-popup-subtitle">{displaySubtitle}</Box>
        <Box className="generic-info-popup-buttons">
          {buttons.map((button) => {
            const {
              buttonText,
              buttonWrapperClassName,
              defaultStyle,
              onClick = () => null,
            } = button;

            if (defaultStyle) {
              return (
                <ActionButton
                  key={buttonText}
                  className={clsx(
                    "generic-info-popup-action-button",
                    buttonWrapperClassName
                  )}
                  onClick={onClick}
                  disabled={false}
                  message={buttonText}
                  defaultStyle={defaultStyle}
                />
              );
            } else {
              return (
                <Button
                  key={buttonText}
                  className={clsx(
                    "generic-info-popup-button",
                    buttonWrapperClassName
                  )}
                  disabled={false}
                  onClick={onClick}
                >
                  {buttonText}
                </Button>
              );
            }
          })}
        </Box>
      </Box>
    </Dialog>
  );
};

GenericInfoPopup.defaultProps = defaultProps;

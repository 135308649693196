import { ParentState } from "@checkout/index";
import { getObjectKeysAsObject } from "../../../helpers";
import { ParentContextWithWallet } from "./types";

export const guards = {
  hasWalletEnabled: (context: ParentContextWithWallet) => {
    return context.featureFlags.enableWallet;
  },
  hasWalletOffers: (context: ParentContextWithWallet, _event: unknown) => {
    return (
      context[ParentState.wallet].selectedOffer ||
      context[ParentState.wallet].offers
    );
  },
};

export const GuardTypes = getObjectKeysAsObject(guards);

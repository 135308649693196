import { fetchSeatMap } from "@hopper-b2b/api";
import { getSeatMapRequest } from "../selectors";
import { SeatSelectionErrorModal } from "@checkout/types";
import { FlightContext } from "../../../types";

export const fetchSeatMapService = (context: FlightContext, _event: unknown) =>
  new Promise((resolve, reject) => {
    const seatMapRequest = getSeatMapRequest({
      context,
    });

    fetchSeatMap({ callback: seatMapRequest })
      .then((seatMap) => {
        if (seatMap?.cheapestSeat && seatMap?.html) {
          resolve(seatMap);
        } else {
          reject({
            type: SeatSelectionErrorModal.GenericError,
          });
        }
      })
      .catch((e) => {
        reject({
          type: SeatSelectionErrorModal.GenericError,
        });
      });
  });

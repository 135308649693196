import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import "./styles.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface MoreInfoButtonProps {
  onClick: () => void;
}

export const MoreInfoButton = ({ onClick }: MoreInfoButtonProps) => (
  <button className="more-info-button" onClick={onClick}>
    <span className="fa-layers fa-fw">
      <FontAwesomeIcon icon={faCircle as IconProp} />
      <FontAwesomeIcon icon={faInfo as IconProp} />
    </span>
  </button>
);

import {
  CipherText,
  ProductOpaqueValue,
  QuoteBreakdown,
  FailedProduct,
  TrackingProperties,
} from "@b2bportal/purchase-api";
import { CallState } from "@hopper-b2b/types";

import { CartQuoteError } from "../../../types";

export interface PriceChangeType {
  hasDifference: boolean;
  predictedTotal: number;
  priceQuoteTotal: number;
  difference?: number;
  differenceString?: string;
  isIncrease?: boolean;
  acknowledged?: boolean;
}

export interface CartQuoteContext {
  error?: CartQuoteError;
  priceQuoteCipherText?: CipherText;
  quoteBreakdown?: QuoteBreakdown;
  cartLocked?: boolean;
  priceChange?: PriceChangeType;
  products: Array<ProductOpaqueValue>;
  customerEmail?: string;
  unavailableProducts: Array<FailedProduct>;
  retries: number;
  trackingProperties: TrackingProperties;
  callState?: CallState;
}

export const CartQuoteInitialContext: CartQuoteContext = {
  error: undefined,
  priceQuoteCipherText: undefined,
  quoteBreakdown: undefined,
  cartLocked: undefined,
  priceChange: undefined,
  products: [],
  customerEmail: undefined,
  unavailableProducts: [],
  retries: 0,
  trackingProperties: {},
  callState: CallState.NotCalled,
};

import clsx from "clsx";
import { Box } from "@material-ui/core";
import "./styles.scss";

export interface IFooterProps {
  className?: string;
  top?: JSX.Element;
  left?: JSX.Element;
  center?: JSX.Element;
  right?: JSX.Element;
  isMobile?: boolean;
}

const Footer = (props: IFooterProps) => {
  const { top, left, center, right, isMobile, className } = props;

  return (
    <footer className={clsx("app-footer", className, { mobile: isMobile })}>
      {top && <Box className="top-section">{top}</Box>}
      <Box className="bottom-section">
        <Box className="left-section">{left}</Box>
        {center && <Box className="center-section">{center}</Box>}
        <Box className="right-section">{right}</Box>
      </Box>
    </footer>
  );
};

export default Footer;

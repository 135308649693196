import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { defaultSlots, type Slots } from "./slots";

export type SlotContextType = {
  slots: Slots;
  addSlotOverrides?: (slotOverrides: Partial<Slots>) => void;
};

export const SlotContext = createContext<SlotContextType>({
  slots: defaultSlots,
});

interface Props {
  children: React.ReactNode;
  slots: Partial<Slots>;
}

export const useSlots = () => {
  const ctx = useContext(SlotContext);
  if (!ctx) throw new Error(`must be used within a SlotProvider`);

  return ctx.slots;
};

export const useAddSlotOverrides = () => {
  const ctx = useContext(SlotContext);
  if (!ctx) throw new Error(`must be used within a SlotProvider`);

  return useCallback(
    (slotOverrides: Partial<Slots>) => {
      ctx.addSlotOverrides(slotOverrides);
    },
    [ctx]
  );
};

export function SlotProvider({ children, slots: slotsProps }: Props) {
  const [slotOverrides, setSlotOverrides] = useState({});

  // Allow for nested SlotProviders
  const outerSlots = useContext(SlotContext);

  const addSlotOverrides = useCallback(
    (newSlotOverrides: Partial<Slots>) => {
      setSlotOverrides({ ...slotOverrides, ...newSlotOverrides });
    },
    [slotOverrides]
  );

  const slots = useMemo(
    () => ({ ...defaultSlots, ...outerSlots, ...slotsProps, ...slotOverrides }),
    [outerSlots, slotOverrides, slotsProps]
  );

  return (
    <SlotContext.Provider
      value={{
        slots,
        addSlotOverrides,
      }}
    >
      {children}
    </SlotContext.Provider>
  );
}

/* eslint-disable no-case-declarations */
import {
  LodgingAddress,
  HotelItinerary,
  HotelItinerarySummary,
} from "@b2bportal/lodging-api";
import {
  BrandNames,
  ItineraryEnum,
  ItinerarySummaryWithType,
  LodgingAddressEnum,
} from "@hopper-b2b/types";
import dayjs from "dayjs";
import { DateTimeFormatStyle } from "@hopper-i18n/formatter";
import { customFormatDateTime, dateStringToNewDate } from "./localeHelpers";

export const DATE_FORMAT = "ddd, MMM D";

export const getTwoLineAddresses = (
  address: LodgingAddress | undefined
): { line1: string; line2: string } => {
  if (!address) {
    return { line1: "", line2: "" };
  }

  switch (address.LodgingAddress) {
    case LodgingAddressEnum.Exact:
      const addresses = address.line1?.split(",");
      const streetAddress = addresses[0] || "";
      const cityStateCountry =
        addresses.slice(1).filter(Boolean).join(", ") || "";
      return { line1: streetAddress, line2: cityStateCountry };
    case LodgingAddressEnum.Obfuscated:
      return {
        line1: "",
        line2: [
          address.city,
          address.stateOrProvince.code || address.stateOrProvince.name,
        ]
          .filter(Boolean)
          .join(", "),
      };
    default:
      return { line1: "", line2: "" };
  }
};

export const getHotelReservationDetails = (
  reservationDetails: HotelItinerary,
  locale: string,
  brand?: BrandNames
) => {
  const { reservation } = reservationDetails;

  const startDate = customFormatDateTime(
    dateStringToNewDate(reservation?.checkInDate),
    locale,
    DateTimeFormatStyle.ShortMonthDayShortWeekday,
    brand?.customDateTimeFormats?.hotelSummary
  );

  const endDate = customFormatDateTime(
    dateStringToNewDate(reservation?.checkOutDate),
    locale,
    DateTimeFormatStyle.ShortMonthDayShortWeekday,
    brand?.customDateTimeFormats?.hotelSummary
  );

  return {
    title: reservation?.lodgingData?.name,
    confirmationCode: `H-${reservation?.reservationId}`,
    startDate,
    endDate,
    subtitle: `${reservation?.lodgingData?.city || ""},
    ${reservation?.lodgingData?.country || ""}`,
  };
};

export const getHotelDetails = (
  hotelSummary: HotelItinerarySummary,
  locale: string,
  brand: BrandNames
) => {
  const { lodgingSummary } = hotelSummary;

  const startDate = customFormatDateTime(
    new Date(hotelSummary?.checkInDate),
    locale,
    DateTimeFormatStyle.ShortMonthDayShortWeekday,
    brand?.customDateTimeFormats?.hotelSummary
  );

  const endDate = customFormatDateTime(
    new Date(hotelSummary?.checkOutDate),
    locale,
    DateTimeFormatStyle.ShortMonthDayShortWeekday,
    brand?.customDateTimeFormats?.hotelSummary
  );

  return {
    confirmationCode: `${hotelSummary?.reservationId}`,
    startDate,
    endDate,
    title: lodgingSummary?.name,
    subtitle: `${lodgingSummary?.city ? `${lodgingSummary?.city}, ` : ""}${
      lodgingSummary?.country || ""
    }`,
    starRating: lodgingSummary?.starRating,
  };
};

export const getHotelStayDuration = (hotel: HotelItinerary) => {
  const nights = dayjs(hotel.reservation.checkOutDate).diff(
    hotel.reservation.checkInDate,
    "day"
  );

  return nights;
};

export const getHotelItinerarySummaryWithType = (
  hotel: HotelItinerary
): ItinerarySummaryWithType => {
  const { ancillaries, reservation } = hotel;
  const { lodgingData } = reservation;
  const lodgingSummary =
    lodgingData == null
      ? undefined
      : {
          city: lodgingData.city,
          country: lodgingData.country,
          state: lodgingData.state,
          id: lodgingData.id,
          name: lodgingData.name,
          starRating: lodgingData.starRating,
        };

  return {
    checkInDate: reservation.checkInDate,
    checkOutDate: reservation.checkOutDate,
    reservationId: reservation.reservationId,
    reservationBookingId: reservation.reservationBookingId,
    state: reservation.state,
    ancillaries: ancillaries,
    type: ItineraryEnum.Hotel,
    lodgingSummary,
  };
};

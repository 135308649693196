import { BannerSeverity, NotificationBanner } from "../../index";
import { Box, Typography } from "@material-ui/core";

export interface OutageBannerProps {
  enabled?: boolean;
  bannerTitle: string;
  bannerSubtitle: string;
}

export const OutageBanner = (props: OutageBannerProps) => {
  const { enabled = false, bannerTitle, bannerSubtitle } = props;

  if (enabled) {
    return (
      <NotificationBanner
        severity={BannerSeverity.ERROR}
        content={
          <Box>
            <Box>
              <Typography variant="h5" color="textPrimary">
                {bannerTitle}
              </Typography>
              <Typography className={"warning-banner-subtitle"} variant="body2">
                <Typography>{bannerSubtitle}</Typography>
              </Typography>
            </Box>
          </Box>
        }
      />
    );
  }
};

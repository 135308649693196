import { LaunchedApplicationProperties } from "@hopper-b2b/types";
import { useExperiments } from "./useExperiments";

export const useTrackingProperties = (
  properties: LaunchedApplicationProperties,
  trackingProperties?: Map<string, string>
): LaunchedApplicationProperties => {
  const { trackingProperties: experimentTrackingProperties } = useExperiments();

  const launchTrackingProperties =
    trackingProperties ?? experimentTrackingProperties;

  if (launchTrackingProperties) {
    properties["experiments"] = Object.keys(launchTrackingProperties).map(
      (ex) => `${ex}_${launchTrackingProperties?.[ex]}`
    );
  }
  return properties;
};

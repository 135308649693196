import { TripDetailsV2 } from "@b2bportal/air-shopping-api";
import {
  PriceFreezeOfferDataResponse,
  GenerateCustomOfferResponse,
  SchedulePriceQuoteRequest,
  Itinerary,
  ItineraryEnum,
} from "@b2bportal/price-freeze-api";
import { Product, ProductOpaqueValue } from "@b2bportal/purchase-api";
import { assign, DoneInvokeEvent } from "xstate";

import {
  setContextWithKey,
  cartHelpers,
  AirPriceFreezeMachineContext,
  AirPriceFreezePurchaseState,
} from "../../../../../index";
import {
  SetContactInfoEvent,
  SetPassengerMapEvent,
  SetPriceFreezeDurationIndex,
} from "./events";

export const Actions = {
  setTripDetails: assign(
    (
      ctx: AirPriceFreezeMachineContext,
      event: DoneInvokeEvent<TripDetailsV2>
    ) => setContextWithKey(ctx, "flightShop.tripDetails", event.data)
  ),
  setPriceFreezeOfferData: assign(
    (
      ctx: AirPriceFreezeMachineContext,
      event: DoneInvokeEvent<PriceFreezeOfferDataResponse>
    ) =>
      setContextWithKey(
        ctx,
        `${AirPriceFreezePurchaseState.priceFreezeOffer}.priceFreezeOfferData`,
        event.data
      )
  ),
  setPriceFreezeDurationIndex: assign(
    (ctx: AirPriceFreezeMachineContext, event: SetPriceFreezeDurationIndex) =>
      setContextWithKey(
        ctx,
        `${AirPriceFreezePurchaseState.priceFreezeOffer}.priceFreezeOfferDataIndex`,
        event.duration
      )
  ),
  resetPriceFreezeOffer: assign(
    (ctx: AirPriceFreezeMachineContext, _event: unknown) =>
      setContextWithKey(
        ctx,
        `${AirPriceFreezePurchaseState.priceFreezeOffer}.priceFreezeOffer`,
        undefined
      )
  ),
  setPriceFreezeOffer: assign(
    (
      ctx: AirPriceFreezeMachineContext,
      event: DoneInvokeEvent<GenerateCustomOfferResponse>
    ) =>
      setContextWithKey(
        ctx,
        `${AirPriceFreezePurchaseState.priceFreezeOffer}.priceFreezeOffer`,
        event.data
      )
  ),
  setContactInfo: assign(
    (ctx: AirPriceFreezeMachineContext, event: SetContactInfoEvent) => {
      setContextWithKey(ctx, "contactInformation", event.contactInfo);

      ctx.cartQuote.customerEmail = event.contactInfo.email;
      return ctx;
    }
  ),
  setPassengerMap: assign(
    (ctx: AirPriceFreezeMachineContext, event: SetPassengerMapEvent) => {
      return setContextWithKey(ctx, "passengerMap", event.passengerMap);
    }
  ),
  addPriceFreezeProduct: assign(
    (ctx: AirPriceFreezeMachineContext, _event: unknown) => {
      const itinerary: Itinerary = {
        tripId: ctx.flightShop.tripId ?? "",
        fareId: ctx.flightShop.fareId ?? "",
        Itinerary: ItineraryEnum.SingleItinerary,
      };

      const priceFreezeProduct: SchedulePriceQuoteRequest = {
        phoneNumber:
          `${ctx.contactInformation?.countryCode}${ctx.contactInformation?.phoneNumber}` ??
          "",
        offerId: ctx.priceFreezeOffer.priceFreezeOffer?.id ?? "",
        passengers: ctx.passengerMap,
        emailAddress: ctx.contactInformation?.email ?? "",
        itinerary,
      };

      const opaqueProduct: ProductOpaqueValue = {
        type: Product.AirPriceFreeze,
        value: priceFreezeProduct,
      };
      cartHelpers.addQuoteProduct(opaqueProduct, ctx);

      return ctx;
    }
  ),
};

type IActionTypes = {
  [K in keyof typeof Actions]: K;
};

export const ActionTypes: IActionTypes = Object.keys(Actions).reduce(
  (prev, cur) => ({ ...prev, [cur]: cur }),
  {} as IActionTypes
);

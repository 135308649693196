import { PassengerTypes } from "@hopper-b2b/types";
import { AirPriceFreezeContext } from "@checkout/states/Products";
import { CartContext } from "@checkout/states/Cart";

export enum AirPriceFreezePurchaseStateEnum {
  loading = "loading",
  priceFreezeOffer = "priceFreezeOffer",
  payment = "payment",
  fulfillFailed = "fulfillFailed",
  confirmation = "confirmation",
  cartQuote = "cartQuote",
  cartUpdate = "cartUpdate",
  cartFulfill = "cartFulfill",
}
export const AirPriceFreezePurchaseState = {
  ...AirPriceFreezePurchaseStateEnum,
};

export type PassengerMap = {
  [key in PassengerTypes]?: number;
};

export interface AirPriceFreezeMachineContext<T = unknown>
  extends CartContext,
    AirPriceFreezeContext {
  //TODO: Figure out if this is the best way to incorporate payment step. Need to be updated with new useCase.
  [AirPriceFreezePurchaseStateEnum.payment]: T;
}

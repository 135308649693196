import {
  CipherText,
  Product,
  QuoteBreakdownResponse,
  QuoteSuccess,
} from "@b2bportal/purchase-api";
import { assign, DoneInvokeEvent } from "xstate";
import { CallState } from "@hopper-b2b/types";
import { setContextWithKey, getObjectKeysAsObject } from "../../../helpers";
import { CartQuoteError, ParentState } from "../../../types";
import { CartContext } from "../context";
import { CartState } from "../types";
import { ClearCartQuoteError } from "./events";

export const actions = {
  setScheduleQuote: assign(
    (ctx: CartContext, event: DoneInvokeEvent<CipherText>) => {
      const cipherText = event.data;
      ctx[CartState.cartQuote].priceQuoteCipherText = cipherText;
      return ctx;
    }
  ),
  setSchedulePriceQuoteError: assign(
    (ctx: CartContext, event: DoneInvokeEvent<CartQuoteError>) =>
      setContextWithKey(ctx, `${CartState.cartQuote}.error`, event.data)
  ),
  setPollQuote: assign(
    (ctx: CartContext, event: DoneInvokeEvent<QuoteSuccess>) => {
      const quoteContext = ctx.cartQuote;
      quoteContext.quoteBreakdown = event.data.quoteBreakdown;
      quoteContext.trackingProperties = event.data.trackingProperties;
      quoteContext.unavailableProducts = event.data.failedProductAdds;
      return setContextWithKey(ctx, CartState.cartQuote, quoteContext);
    }
  ),

  setPollQuoteError: assign(
    (ctx: CartContext, event: DoneInvokeEvent<CartQuoteError>) =>
      setContextWithKey(ctx, `${CartState.cartQuote}.error`, event.data)
  ),
  clearCartQuoteError: assign((ctx: CartContext, _event: ClearCartQuoteError) =>
    setContextWithKey(ctx, `${CartState.cartQuote}.error`, undefined)
  ),
  clearCartContext: assign((ctx: CartContext, _event) => {
    const cartQuoteCtx = ctx[CartState.cartQuote];
    const updatedCartQuoteCtx = {
      ...cartQuoteCtx,
      priceQuoteCipherText: undefined,
      quoteBreakdown: undefined,
      cartLocked: false,
      error: undefined,
      priceChange: undefined,
    };
    const cartUpdateCtx = ctx[CartState.cartUpdate];
    const updatedCartUpdateCtx = {
      ...cartUpdateCtx,
      addProducts: [],
      removeProducts: [],
      removePayments: [],
      addPayments: [],
      error: undefined,
    };
    setContextWithKey(ctx, CartState.cartQuote, updatedCartQuoteCtx);
    return setContextWithKey(ctx, CartState.cartUpdate, updatedCartUpdateCtx);
  }),

  clearCartQuoteProducts: assign((ctx: CartContext, _event) => {
    ctx[CartState.cartQuote].products = [];
    return ctx;
  }),
  lockCart: assign((ctx: CartContext, _event) => {
    const shouldLockCart = ctx[CartState.cartUpdate]?.addProducts?.some(
      (p) => p.type === Product.Seats
    );

    return setContextWithKey(
      ctx,
      `${CartState.cartQuote}.cartLocked`,
      shouldLockCart
    );
  }),
  unlockCart: assign((ctx: CartContext, _event) =>
    setContextWithKey(ctx, `${CartState.cartQuote}.cartLocked`, false)
  ),
  setCartBreakdown: assign(
    (ctx: CartContext, event: DoneInvokeEvent<QuoteBreakdownResponse>) => {
      const cartQuoteContext = ctx[CartState.cartQuote];
      cartQuoteContext.quoteBreakdown = event.data.quoteBreakdown;
      cartQuoteContext.unavailableProducts = event.data.unavailableProducts;
      return ctx;
    }
  ),
  setSeatMapAvailable: assign(
    (ctx: CartContext, event: DoneInvokeEvent<QuoteSuccess>) => {
      const breakdown = event.data.quoteBreakdown;
      const flightProduct = breakdown?.products.find(
        (p) => p.product.type === Product.Flight
      );
      return setContextWithKey(
        ctx,
        `${ParentState.seatSelection}.seatMapAvailable`,
        flightProduct?.product?.value?.seatMap?.SeatMapResponse
      );
    }
  ),
  setQuoteRetries: assign((ctx: CartContext, _event) =>
    setContextWithKey(
      ctx,
      `${ParentState.cartQuote}.retries`,
      ctx.cartQuote.retries + 1
    )
  ),
  resetQuoteRetries: assign((ctx: CartContext, _event) =>
    setContextWithKey(ctx, `${ParentState.cartQuote}.retries`, 0)
  ),
  setCartQuoteCallStateInProcess: assign((ctx: CartContext, _event) =>
    setContextWithKey(
      ctx,
      `${ParentState.cartQuote}.callState`,
      CallState.InProcess
    )
  ),
  setCartQuoteCallStateSuccess: assign((ctx: CartContext, _event) =>
    setContextWithKey(
      ctx,
      `${ParentState.cartQuote}.callState`,
      CallState.Success
    )
  ),
  setCartQuoteCallStateFailed: assign((ctx: CartContext, _event) =>
    setContextWithKey(
      ctx,
      `${ParentState.cartQuote}.callState`,
      CallState.Failed
    )
  ),
  setCartQuoteCallStateNotCalled: assign((ctx: CartContext, _event) =>
    setContextWithKey(
      ctx,
      `${ParentState.cartQuote}.callState`,
      CallState.NotCalled
    )
  ),
  acknowledgePriceChange: assign((ctx: CartContext, _event) =>
    setContextWithKey(
      ctx,
      `${ParentState.cartQuote}.priceChange.acknowledged`,
      true
    )
  ),
  clearCartQuoteCipherText: assign((ctx: CartContext, _event) => {
    const cartQuoteCtx = ctx[CartState.cartQuote];
    const updatedCartQuoteCtx = {
      ...cartQuoteCtx,
      priceQuoteCipherText: undefined,
    };
    return setContextWithKey(ctx, CartState.cartQuote, updatedCartQuoteCtx);
  }),
};

export const ActionTypes = getObjectKeysAsObject(actions);

import {
  AuthMethodEnum,
  CreateSessionRequestEnum,
  CreateSessionResponseEnum,
} from "@b2bportal/auth-api";
import { createSession } from "../../api";
import { AuthMachineContext } from "../context";
import { getAuthStateToken } from "../selectors";
import { VerifyCodeEvent } from "../events";
import {
  AuthTrackingEvents,
  VerifyFailureEvent,
  VerifySuccessEvent,
} from "../../types/tracking";
import { trackEvent } from "@hopper-b2b/api";

export const verifyCodeService = (
  context: AuthMachineContext,
  event: VerifyCodeEvent
) =>
  new Promise((resolve, reject) => {
    const authStateToken = getAuthStateToken({ context }) as string;
    const trackFailure = () =>
      trackEvent({
        eventName: AuthTrackingEvents.VerifyFailure,
        properties: {
          auth_type: event.auth_type,
        },
      } as VerifyFailureEvent);

    createSession({
      authMethod: {
        authStateToken: authStateToken,
        code: event.code,
        AuthMethod: AuthMethodEnum.VerifyCode,
      },
      CreateSessionRequest: CreateSessionRequestEnum.SignIn,
    })
      .then((res) => {
        if (
          res.CreateSessionResponse ===
          CreateSessionResponseEnum.CreateSessionFailed
        ) {
          trackFailure();
          reject(res.reason);
        } else {
          trackEvent({
            eventName: AuthTrackingEvents.VerifySuccess,
            properties: {
              auth_type: event.auth_type,
            },
          } as VerifySuccessEvent);
          resolve(res);
        }
      })
      .catch((error) => {
        trackFailure();
        reject(error);
      });
  });

import { useI18nContext } from "@hopper-b2b/i18n";

interface IEditProps {
  className?: string;
  onClick: () => void;
}

export const Edit = ({ className, onClick }: IEditProps) => {
  const { t } = useI18nContext();
  return (
    <button className={className} onClick={onClick}>
      {t("edit")}
    </button>
  );
};

export default Edit;

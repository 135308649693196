import {
  airBookingApi,
  PassengersForFareRequest,
  TripPricingSummaryResponse,
} from "@b2bportal/air-booking-api";
import { NETWORK_CALL_FAILED, IApiConfig } from "@hopper-b2b/types";
import { axiosInstance } from "../AxiosInterceptor";
import { trackEvent } from "../tracking/trackEvent";

export const fetchTripPricing = (
  req: PassengersForFareRequest,
  apiConfig?: IApiConfig
): Promise<TripPricingSummaryResponse> =>
  new Promise((resolve, reject) => {
    airBookingApi(axiosInstance)
      .apiV0TripPricePost(req)
      .then((res) => {
        const responseBody = res.data as TripPricingSummaryResponse;
        resolve(responseBody);
      })
      .catch((e) => {
        trackEvent(
          {
            eventName: NETWORK_CALL_FAILED,
            properties: {
              url: window.location.pathname,
            },
          },
          apiConfig
        );
        reject(e);
      });
  });

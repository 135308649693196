import { fetchTripDetails } from "./fetchTripDetails";
import { priceFreezeOfferServices } from "./priceFreezeOffer";

export const Services = {
  fetchTripDetails,
  ...priceFreezeOfferServices,
};

type IServiceTypes = {
  [K in keyof typeof Services]: K;
};

export const ServiceTypes: IServiceTypes = Object.keys(Services).reduce(
  (prev, cur) => ({ ...prev, [cur]: cur }),
  {} as IServiceTypes
);

import {
  AlgomerchTag,
  FlightShopCardType,
  IFlightCardProps,
  ClientName,
  TagType,
} from "@hopper-b2b/types";
import { getEnvVariables, removeTimezone } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import { FC, ReactNode } from "react";
import { FlightCard as UberMiniFlightCard } from "../../../uber/FlightCard";
import { FlightCard as HopperMiniFlightCard } from "../../../hopper/FlightCard";

import { FlightCardWrapper } from "../../component";
import {
  Airline,
  BrandName,
  FlightCardFields,
  FlightDuration,
  FlightLocations,
  FlightTiming,
  PriceDisplay,
  RewardDisplay,
  StopSummary,
} from "../../components/FlightCardFields";
import { FlightCardHeader } from "../../components/FlightCardHeader";
import { FlightTags } from "../../components/FlightTag";
import "./styles.scss";

export interface IB2BFlightCardV2Props {
  duration: string;
  tags: {
    value: AlgomerchTag;
    type: TagType;
  }[];
  currentPriceText: string;
  rewardText: string;
  originCode: string;
  destinationCode: string;
  departureTime: string;
  arrivalTime: string;
  airports: string[];
  brandName: string;
  primaryCarrier: string;
  airlineName: string;
  onClickTag?: (label: string) => void;
  onClick?: () => void;
  cardType?: FlightShopCardType;
  additionalInfo?: {
    isExpanded: boolean;
    layoverText?: string;
    plusDays?: string;
  };
  tagsLimit?: number;
}

export const B2BFlightCardV2 = (props: IB2BFlightCardV2Props) => {
  const { onClick, cardType, ...flightCardProps } = props;

  const isHopper = [
    ClientName.HOPPER,
    ClientName.VOLARIS,
    ClientName.FLAIR,
    ClientName.LLOYDS,
  ].includes(getEnvVariables("clientName") as ClientName);

  // TODO: Cleanup and use slots
  const MiniFlightCard = isHopper ? HopperMiniFlightCard : UberMiniFlightCard;

  return (
    <FlightCardWrapper className="b2b-flight-card" onClick={onClick}>
      {cardType === FlightShopCardType.MINI ? (
        <MiniFlightCard {...flightCardProps} />
      ) : (
        <DefaultFlightCard {...flightCardProps} />
      )}
    </FlightCardWrapper>
  );
};

interface IPriceAndRewardDisplay {
  children: ReactNode;
}

export const PriceAndRewardDisplay: FC<IPriceAndRewardDisplay> = ({
  children,
}) => <Box className="price-and-reward-section">{children}</Box>;

const DefaultFlightCard = ({
  currentPriceText,
  rewardText,
  tags,
  onClickTag,
  primaryCarrier,
  airlineName,
  departureTime,
  arrivalTime,
  originCode,
  destinationCode,
  duration,
  brandName,
  airports,
}: IFlightCardProps) => {
  return (
    <>
      <FlightCardHeader>
        <PriceAndRewardDisplay>
          <PriceDisplay currentPrice={currentPriceText} />
          {rewardText && (
            <>
              <Typography variant="inherit" className="separator">
                /
              </Typography>
              <RewardDisplay reward={rewardText} />
            </>
          )}
        </PriceAndRewardDisplay>
        {tags.length > 0 && (
          <FlightTags
            tags={tags}
            mergeTags={true}
            onClickTag={onClickTag}
            showFastest
          />
        )}
      </FlightCardHeader>
      <FlightCardFields>
        <Airline code={primaryCarrier} name={airlineName} />
        <FlightTiming
          departureTime={removeTimezone(departureTime)}
          arrivalTime={removeTimezone(arrivalTime)}
        />
        <FlightLocations
          originCode={originCode}
          destinationCode={destinationCode}
        />
        <FlightDuration duration={duration} />
        <BrandName brandName={brandName ?? ""} />
        <StopSummary airports={airports} hideAirports={true} />
      </FlightCardFields>
    </>
  );
};

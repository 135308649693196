import {
  DiscountType,
  FrozenPriceTag,
  TagType,
  AlgomerchTag,
} from "./constants";

import { ISegment, ITripPricing, Tag } from "./interfaces";

export const multiStopSegments: ISegment[] = [
  {
    duration: "2h 34m",
    departureTime: "2021-01-14T13:49:00.000",
    arrivalTime: "2021-01-14T16:23:00.000",
    origin: "LGA",
    flightNumber: "629",
    destination: "MCO",
    stops: 0,
    marketingCarrier: "NK",
  },
  {
    duration: "2h 41m",
    departureTime: "2021-01-14T18:28:00.000",
    arrivalTime: "2021-01-14T20:09:00.000",
    origin: "MCO",
    flightNumber: "713",
    destination: "IAH",
    stops: 0,
    marketingCarrier: "NK",
  },
  {
    duration: "3h 18m",
    departureTime: "2021-01-14T20:59:00.000",
    arrivalTime: "2021-01-14T22:17:00.000",
    origin: "IAH",
    flightNumber: "193",
    destination: "LAS",
    stops: 0,
    marketingCarrier: "NK",
  },
  {
    duration: "1h 43m",
    departureTime: "2021-01-15T07:00:00.000",
    airlineRelatedWarnings: [],
    plusDays: 0,
    warnings: [],
    arrivalTime: "2021-01-15T08:43:00.000",
    arrivalPlusDays: 1,
    departurePlusDays: 1,
    origin: "LAS",
    flightNumber: "2079",
    destination: "SFO",
    stops: 0,
    marketingCarrier: "F9",
  } as ISegment,
];

export const nonstopSegments: ISegment[] = multiStopSegments.slice(
  multiStopSegments.length - 1,
  multiStopSegments.length
);

export const singleStopSegments: ISegment[] = multiStopSegments.slice(0, 2);

export const pricingUsd = {
  base: "$100",
  tax: "$15",
  subTotal: "$115",
  fee: "$0",
  grandTotal: "$115",
  incrementalTotalDifference: "$0",
};

export const discountedPricingUsd = {
  ...pricingUsd,
  discount: {
    grandTotal: "$90",
    discountAmount: "$25",
    type: DiscountType.PRICE_FREEZE,
  },
};

export const allTags: Tag[] = [
  { value: AlgomerchTag.BestFlight, type: TagType.Algomerch },
  { value: AlgomerchTag.Fastest, type: TagType.Algomerch },
  { value: AlgomerchTag.BestQuality, type: TagType.Algomerch },
  { value: AlgomerchTag.Cheapest, type: TagType.Algomerch },
  { value: FrozenPriceTag, type: TagType.PriceFreeze },
];

export const pricing: ITripPricing = {
  tripPricing: pricingUsd,
};

export const discountedPricing: ITripPricing = {
  tripPricing: discountedPricingUsd,
};

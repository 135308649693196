import { useI18nContext } from "@hopper-b2b/i18n";
import { HeaderMenuDropdown } from "@hopper-b2b/ui";

import clsx from "clsx";
import { useCallback } from "react";
import styles from "./CurrencySelectDropdown.module.scss";
import { SupportedCurrencies, supportedCurrencies } from "./constants";
import {
  getCurrency,
  setCurrency,
  getCurrencyDisplayName,
} from "@virtual-interline/utils";
import { IconName } from "../Icon";
import { IconComponent } from "../IconComponent";

export const CurrencySelectDropdown = () => {
  const { t } = useI18nContext();
  const currency = getCurrency<SupportedCurrencies>();

  const handleCurrencySelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrency(e.target.value);
      window?.location?.reload();
    },
    []
  );

  const dropdownLabel = getCurrencyDisplayName(currency);

  return (
    <HeaderMenuDropdown
      className={styles.currencySelectDropdown}
      content={
        <legend className={styles.currencySelectDropdownLabel}>
          {t(dropdownLabel) || t("currencies")}
          <IconComponent
            name={IconName.Dropdown}
            className={styles.currencySelectDropdownLabelIcon}
          />
        </legend>
      }
      dropdownContent={
        <fieldset className={styles.currencySelectDropdownList}>
          {supportedCurrencies.map((c) => (
            <div
              key={c}
              className={clsx(
                styles.currencySelectDropdownItem,
                currency === c ? styles.selected : ""
              )}
            >
              <input
                type="radio"
                name="locale"
                id={c}
                value={c}
                defaultChecked={currency === c}
                onChange={handleCurrencySelect}
              />
              <label htmlFor={c}>{t(getCurrencyDisplayName(c))}</label>
            </div>
          ))}
        </fieldset>
      }
    />
  );
};

import { ClientName } from "@hopper-b2b/types";
import { getEnvVariables } from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import "./styles.scss";

export interface ITagProps {
  className?: string;
  label: string;
  showInfoIcon?: boolean;
  expanded?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}

export const Tag = ({
  label,
  className,
  expanded,
  onClick,
  children,
}: ITagProps): React.ReactElement => {
  const isHopper =
    getEnvVariables("clientName") === ClientName.HOPPER ||
    getEnvVariables("clientName") === ClientName.LLOYDS;
  return (
    <Box
      role="button"
      tabIndex={0}
      aria-expanded={expanded}
      className={clsx("algomerch-tag", className)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.stopPropagation();
          onClick && onClick();
        }
      }}
      disabled={isHopper}
      onClick={
        !isHopper
          ? (event) => {
              event.stopPropagation();
              onClick && onClick();
            }
          : null
      }
    >
      <span>{label}</span>
      {children}
    </Box>
  );
};

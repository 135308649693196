// Imported as MiniFlightCard
import { UIEvent, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { AlgomerchTag, HalfSheetHashType, TagType } from "@hopper-b2b/types";
import { removeTimezone } from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  FlightCardFields,
  Airline,
  PriceDisplay,
  StopSummary,
  FlightTiming,
  FlightDuration,
  FlightLocations,
} from "../../FlightCard/components/FlightCardFields";
import { IconComponent } from "../../IconComponent";
import { AlgomerchTags } from "../../AlgomerchTags";
import { IconName } from "../../Icon";
import styles from "./styles.module.scss";

export interface IFlightCardProps {
  duration: string;
  currentPriceText: string;
  originCode: string;
  destinationCode: string;
  departureTime: string;
  arrivalTime: string;
  airports: string[];
  brandName: string;
  primaryCarrier: string;
  airlineName: string;
  additionalInfo?: {
    isExpanded: boolean;
    layoverText?: string;
    plusDays?: string;
  };
  onClickTag?: (label: string) => void;
  tags: ITags;
  tagsLimit?: number;
}

type ITags = {
  value: AlgomerchTag;
  type: TagType;
}[];

export const FlightCard = (props: IFlightCardProps) => {
  const {
    duration,
    currentPriceText,
    originCode,
    destinationCode,
    departureTime,
    arrivalTime,
    airports,
    primaryCarrier,
    airlineName,
    additionalInfo,
    tags,
    tagsLimit,
    onClickTag,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const { t } = useI18nContext();

  const onClickHandler = useCallback(
    (e: UIEvent) => {
      // There may be a parent onclick event that would collapse
      // the flight card, don't let the parent know of the event.
      e.stopPropagation();
      history.push(
        `${location.pathname}${location.search}#${HalfSheetHashType.FLIGHT_DETAILS}`
      );
    },
    [history, location.pathname, location.search]
  );

  return (
    <div className={styles.flightCard}>
      <div>
        <div className={clsx(styles["flight-card-center-row"], "badges")}>
          <AlgomerchTags
            tags={tags}
            tagsLimit={tagsLimit}
            onClickTag={onClickTag}
          />
        </div>
      </div>
      <FlightCardFields
        className={clsx(
          styles["flight-card-fields"],
          styles["mini"],
          styles["align-top"],
          additionalInfo.isExpanded && styles["is-expanded"]
        )}
      >
        <div className={styles["flight-card-center"]}>
          <FlightTiming
            className={styles["flight-card-center-row"]}
            departureTime={removeTimezone(departureTime)}
            arrivalTime={removeTimezone(arrivalTime)}
          />
          <div
            className={clsx(
              styles["flight-card-airline-brand"],
              styles["flight-card-center-row"],
              styles["flex"]
            )}
          >
            <Airline
              className={styles["large-size"]}
              code={primaryCarrier}
              name={airlineName}
              iconOnly
            />
            {airlineName}
          </div>
          <div
            className={clsx(
              styles["flight-card-center-row"],
              styles["destination-duration"]
            )}
          >
            <FlightLocations
              originCode={originCode}
              destinationCode={destinationCode}
            />
          </div>
          {additionalInfo.isExpanded ? (
            <div className={clsx(styles["additional-flight-info"])}>
              {additionalInfo.layoverText ? (
                <p>{additionalInfo.layoverText}</p>
              ) : null}
              {additionalInfo.plusDays && (
                <div className={clsx(styles["next-day-container"])}>
                  <IconComponent
                    className={clsx(styles["alert-icon"])}
                    name={IconName.CircleAlert}
                  />
                  <Typography
                    variant="body2"
                    className={clsx(styles["plus-days"])}
                  >
                    {additionalInfo.plusDays}
                  </Typography>
                </div>
              )}
              <div
                onClick={onClickHandler}
                className={clsx(styles["see-more-info"])}
              >
                {t("seeMoreInfo")}
              </div>
            </div>
          ) : null}
        </div>
        <div
          className={clsx(styles["flight-card-right"], styles["other-right"])}
        >
          <PriceDisplay currentPrice={currentPriceText} />
          <div className={clsx(styles["duration-stop"])}>
            <FlightDuration duration={duration} />
            <StopSummary airports={airports} hideAirports={true} />
          </div>
        </div>
      </FlightCardFields>
    </div>
  );
};

import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface IChoiceButtonProps {
  onClick: (e) => void;
  isChosen: boolean;
  disabled: boolean;
  message: string;
  ariaLabel?: string;
  className?: string;
}

const ChoiceButton = ({
  onClick,
  isChosen,
  disabled,
  message,
  ariaLabel,
  className,
}: IChoiceButtonProps) => {
  return (
    <Box className={clsx("choice-button", className)}>
      {isChosen && (
        <Button
          className="chosen"
          {...{ disabled }}
          onClick={onClick}
          variant="contained"
          aria-label={ariaLabel}
          aria-pressed={isChosen}
        >
          <FontAwesomeIcon icon={faCheck as IconProp} />
        </Button>
      )}
      {!isChosen && (
        <Button
          {...{ disabled }}
          aria-pressed={isChosen}
          onClick={onClick}
          variant="contained"
        >
          {message}
        </Button>
      )}
    </Box>
  );
};

export default ChoiceButton;

import { FunctionComponent, ReactNode } from "react";
import { Box } from "@material-ui/core";

export interface IFlightCardHeaderProps {
  children: ReactNode;
}

export const FlightCardHeader: FunctionComponent<IFlightCardHeaderProps> = ({
  children,
}: IFlightCardHeaderProps) => (
  <Box className="flight-card-header">{children}</Box>
);

import { datadogRum } from "@datadog/browser-rum";
import { millisecondsToSeconds } from "../../timeHelper";

export enum CheckoutType {
  AIR = "air",
  AIR_PRICEFREEZE = "air_pricefreeze",
  UNKNOWN = "unknown",
}

export enum PollingType {
  QUOTE = "quote",
  UPDATE = "update",
  FULFILL = "fulfill",
  INTERACTIVEPAYMENTAUTH = "interactivePaymentAuth",
}

export enum PollingResult {
  SUCCESS = "success",
  FAILURE = "failure",
  CHALLENGE = "challenge",
}

const ACTION_NAME = "pollingFinished";

/**
 * TODO: Find a better way to track the checkout type as the
 * checkout lib is not aware of type and URL might be
 * different for different tenants in the future.
 */
const PATH_AIR_PRICE_FREEZE = "/flights/freeze/purchase";
const PATH_AIR_FLIGHTS = "/flights/book";

const getCheckoutType = (): CheckoutType => {
  const windowPath = window.location.pathname;
  if (windowPath.includes(PATH_AIR_PRICE_FREEZE))
    return CheckoutType.AIR_PRICEFREEZE;
  if (windowPath.includes(PATH_AIR_FLIGHTS)) return CheckoutType.AIR;
  return CheckoutType.UNKNOWN;
};

export const startPollingPerformance = () => performance.now();

export const endPollingPerformance = (
  start: number,
  polling_type: PollingType,
  polling_result: PollingResult
) => {
  datadogRum.addAction(ACTION_NAME, {
    duration: millisecondsToSeconds(performance.now() - start),
    checkout_type: getCheckoutType(),
    polling_type,
    polling_result,
  });
};

import { useI18nContext, useI18nCurrency } from "@hopper-b2b/i18n";
import { IPriceRange } from "@hopper-b2b/types";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";

const StyledSlider = withStyles({
  root: {
    marginTop: 28,
    height: 10,
    minWidth: 300,
  },
  thumb: {
    height: 18,
    width: 18,
    border: "2px solid var(--white)",
    marginTop: -6,
    marginLeft: -9,
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.10)",
  },
  valueLabel: {
    left: "calc(-50% - 2px)",
    top: -22,
    fontSize: "0.875rem",
    fontWeight: 600,
    "& *": {
      background: "transparent",
      color: "currentColor",
    },
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    color: "var(--gray-30)",
    height: 8,
    borderRadius: 4,
  },
})(Slider);

interface Props {
  min: number;
  max: number;
  lowest: number;
  highest: number;
  onFilterPriceRangeChange: (
    nextValue: Pick<IPriceRange, "min" | "max">
  ) => void;
}

const optionsToHideFractionDigits = {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
};

export const PriceRange = ({
  min,
  max,
  lowest,
  highest,
  onFilterPriceRangeChange,
}: Props) => {
  const { t } = useI18nContext();
  const { formatCurrency } = useI18nCurrency();

  return (
    <div style={{ padding: "0 0.3rem" }}>
      <StyledSlider
        aria-label={t("priceRange")}
        value={[min, max]}
        min={lowest}
        max={highest}
        step={5}
        valueLabelDisplay="on"
        valueLabelFormat={(price: number) =>
          formatCurrency(price, optionsToHideFractionDigits)
        }
        onChange={(_event, nextValue: number | number[]) => {
          if (Array.isArray(nextValue)) {
            const [min, max] = nextValue;
            onFilterPriceRangeChange({ min, max });
          }
        }}
      />
    </div>
  );
};

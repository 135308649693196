import * as React from "react";
import clsx from "clsx";
import MenuItem from "@material-ui/core/MenuItem";

import "./styles.scss";

interface IProps {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  value?: any;
}

const StyledMenuItem = (props: IProps) => {
  const { children, className, ...other } = props;
  return (
    <MenuItem className={clsx("styled-menu-item", className)} {...other}>
      {children}
    </MenuItem>
  );
};
export default StyledMenuItem;

import { Airport } from "@b2bportal/air-booking-api";
import { PassengerTypes } from "@hopper-b2b/types";
import { AirPriceFreezeStateWithoutValue } from "../types";

export const getTripId = (state: AirPriceFreezeStateWithoutValue) =>
  state.context.flightShop.tripId || "";

export const getFareId = (state: AirPriceFreezeStateWithoutValue) =>
  state.context.flightShop.fareId || "";

export const getTripDetails = (state: AirPriceFreezeStateWithoutValue) =>
  state.context.flightShop.tripDetails;

export const getFareDetails = (state: AirPriceFreezeStateWithoutValue) => {
  const fareId = getFareId(state);
  return state.context.flightShop.tripDetails?.fareDetails.find(
    (fd) => fd.id === fareId
  );
};

export const getDepartureDate = (state: AirPriceFreezeStateWithoutValue) => {
  const tripDetails = getTripDetails(state);
  const departureDateTime = tripDetails?.slices[0].departureTime;
  return departureDateTime;
};

export const getReturnDate = (state: AirPriceFreezeStateWithoutValue) => {
  const tripDetails = getTripDetails(state);
  const returnDateTime = tripDetails?.slices.find(
    (s) => !s.outgoing
  )?.departureTime;
  return returnDateTime;
};

export const getOriginName = (state: AirPriceFreezeStateWithoutValue) => {
  const airportCode =
    state.context.flightShop.tripDetails?.slices[0]?.originCode;
  if (airportCode) {
    const airport: Airport | undefined = state.context.flightShop.airports
      ? state.context.flightShop.airports[airportCode]
      : undefined;
    return airport?.cityName ?? airportCode;
  }
  return "";
};

export const getDestinationName = (state: AirPriceFreezeStateWithoutValue) => {
  const airportCode =
    state.context.flightShop.tripDetails?.slices[0]?.destinationCode;
  if (airportCode) {
    const airport: Airport | undefined = state.context.flightShop.airports
      ? state.context.flightShop.airports[airportCode]
      : undefined;
    return airport?.cityName ?? airportCode;
  }
  return "";
};

export const getIsRoundTrip = (state: AirPriceFreezeStateWithoutValue) =>
  (state.context.flightShop.tripDetails?.slices.length ?? 0) > 1;

export const getFlightPricePerPax = (
  state: AirPriceFreezeStateWithoutValue
) => {
  const fareId = getFareId(state);
  const fare = state.context.flightShop.tripDetails?.fareDetails.find(
    (f) => f.id === fareId
  );
  const adultPassenger = fare?.paxPricings.find(
    (p) => p.paxType === PassengerTypes.Adult
  );
  return adultPassenger?.pricing.total;
};

export const getOutgoingAirline = (state: AirPriceFreezeStateWithoutValue) => {
  const tripDetails = getTripDetails(state);
  return tripDetails?.slices[0].segmentDetails[0].marketingAirline;
};

export const getReturnAirline = (state: AirPriceFreezeStateWithoutValue) => {
  const tripDetails = getTripDetails(state);
  return tripDetails?.slices[1]?.segmentDetails[0].marketingAirline;
};

export const getAirports = (state: AirPriceFreezeStateWithoutValue) =>
  state.context.flightShop.airports;

import {
  PassengersOk,
  PassengersResponse,
  PassengersResponseEnum,
  ProtectionId,
} from "@b2bportal/air-booking-api";
import { PassengerErrorModalTypes } from "@checkout/types";
import { trackEvent, validateUserPassengers } from "@hopper-b2b/api";
import {
  ClientName,
  SEARCH_PASSENGER_COUNT_NOT_REACHED,
} from "@hopper-b2b/types";
import { getEnvVariables } from "@hopper-b2b/utilities";
import { ValidateUserPassengerEvent } from "../events";
import {
  getNumPassengerAlertDismissed,
  getSearchPassengerNumber,
  getSelectedLapInfantIds,
  getSelectedPassengerIds,
  getSelectedTripInPassenger,
  getUserPassengers,
} from "../selectors";
import { FlightContextV2 } from "../../../types";

export const validateUserPassengersService = (
  context: FlightContextV2,
  event?: ValidateUserPassengerEvent
) =>
  new Promise((resolve, reject) => {
    const selectedTrip = getSelectedTripInPassenger({ context });
    const passengers = getUserPassengers({ context });
    const selectedPassengerIds = getSelectedPassengerIds({ context });
    const selectedLapInfantIds = getSelectedLapInfantIds({ context });
    const numPassengers = getSearchPassengerNumber({ context });
    const numPassengerAlertDismissed = getNumPassengerAlertDismissed({
      context,
    });
    const ancillaryIds: ProtectionId[] = []; // ancillaryIdsSelector(state);

    const allowPassengerNumberDiff =
      getEnvVariables("clientName") === ClientName.HOPPER ||
      getEnvVariables("clientName") === ClientName.VOLARIS ||
      getEnvVariables("clientName") === ClientName.FLAIR ||
      getEnvVariables("clientName") === ClientName.LLOYDS;

    if (!selectedTrip || !passengers) {
      type NoSelectedTripOrNoPassengersError = any;
      const errorMessage = "NoSelectedTripOrNoPassengersError";
      const error: NoSelectedTripOrNoPassengersError = {
        message: errorMessage,
      };
      reject(error);
    }

    validateUserPassengers({
      tripId: selectedTrip.tripId,
      fareId: selectedTrip.returnFareId
        ? selectedTrip.returnFareId
        : selectedTrip.outgoingFareId,
      seatedPassengers: passengers
        .filter((p) => selectedPassengerIds.includes(p.id))
        .map((u) => u.id),
      lapInfants: passengers
        .filter((p) => selectedLapInfantIds.includes(p.id))
        .map((u) => u.id),
      ancillaryIds,
    })
      .then((response: PassengersResponse) => {
        switch (response.PassengersResponse) {
          case PassengersResponseEnum.InvalidPassengers: {
            if ("errors" in response || "warnings" in response) {
              reject({
                type:
                  response.errors?.at(0)?.code ||
                  response.warnings?.at(0)?.code,
                data: response.errors?.at(0) || response.warnings?.at(0),
              });
            } else {
              reject({
                type: PassengerErrorModalTypes.ValidatePassengers,
                data: {
                  code: PassengerErrorModalTypes.ValidatePassengers,
                  message: "Unknown InvalidPassenger error",
                },
              });
            }
            break;
          }
          case PassengersResponseEnum.PassengersOk:
            // Do not trigger numPassengerAlert on SELECT_PASSENGER event and trigger alert only once in checkout flow
            if (
              !allowPassengerNumberDiff &&
              selectedPassengerIds.length + selectedLapInfantIds.length <
                numPassengers &&
              !numPassengerAlertDismissed &&
              !event.passengerId
            ) {
              trackEvent({
                eventName: SEARCH_PASSENGER_COUNT_NOT_REACHED,
                properties: {},
              });
              reject({
                type: PassengerErrorModalTypes.SearchPassengerNumNotReached,
              });
            } else {
              resolve(response as PassengersOk);
            }
            break;
        }
      })
      .catch((e) => {
        reject({
          type: PassengerErrorModalTypes.ValidatePassengers,
          data: e,
        });
      });
  });

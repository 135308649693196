import { useI18nContext } from "@hopper-b2b/i18n";
import {
  AlgomerchTag,
  FrozenPriceTag,
  TagValue,
  mapI18nAlgomerchText,
  TagType,
} from "@hopper-b2b/types";
import "./styles.scss";

type ITags = {
  value: AlgomerchTag;
  type: TagType;
}[];

interface IAlgomerchTagsProps {
  onClickTag?: (label: string) => void;
  tags: ITags;
  tagsLimit?: number;
}

export const AlgomerchTags = ({
  onClickTag,
  tags,
  tagsLimit,
}: IAlgomerchTagsProps) => {
  const { t } = useI18nContext();

  const labelToText: {
    [key in TagValue]: string;
  } = {
    ...mapI18nAlgomerchText,
    [FrozenPriceTag]: "frozenPrice",
  };

  const handleAlgomerchOnClick = ({
    event,
    value,
  }: {
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>;
    value: string;
  }) => {
    event.stopPropagation();
    if (onClickTag) onClickTag(value);
  };

  const slicedTags = tags.slice(0, tagsLimit);

  return (
    <div className="algomerch-tags">
      {slicedTags.length !== 0 && (
        <div className="badges">
          {slicedTags.reverse().map(({ value }, index) => (
            <span
              className="badge"
              key={value}
              onClick={(event) =>
                handleAlgomerchOnClick({ value: labelToText[value], event })
              }
            >
              {`${t(labelToText[value])}`}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

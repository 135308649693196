import { ThemeOptions, createTheme } from "@material-ui/core/styles";
import { ThemeConfig, ThemeConfigWithBasicColors } from "../types";

export const defaultTheming: ThemeOptions = {
  palette: {
    type: "light",
    primary: {
      light: "#FA6866",
      main: "#FA6866",
      contrastText: "#FA6866",
    },
    secondary: {
      light: "#01AAE4",
      main: "#01AAE4",
      contrastText: "#01AAE4",
    },
    text: {
      primary: "#505050",
      secondary: "#878787",
    },
  },
  typography: {
    fontFamily: "Proxima Nova, sans-serif",
    htmlFontSize: 14,
    fontSize: 14,
    h1: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "2.5rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    h2: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "1.75rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    h3: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "1.5rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    h4: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "1.375rem",
      letterSpacing: 0,
    },
    h5: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "1.25rem",
      letterSpacing: 0,
    },
    h6: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "1rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    body1: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "1rem",
      letterSpacing: 0,
    },
    body2: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "1rem",
      letterSpacing: 0,
    },
    caption: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "0.875rem",
      letterSpacing: 0,
    },
    overline: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "1rem",
      letterSpacing: 0,
    },
    subtitle1: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "0.875rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    subtitle2: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "0.875rem",
      letterSpacing: 0,
    },
    button: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.25rem",
      textTransform: "none" as const,
      letterSpacing: 0,
    },
  },
};

export const asB2BMuiTheme = (themeOptions?: any) =>
  createTheme(themeOptions ? themeOptions : defaultTheming);

export const getDarkModePreferred = () => {
  return window.matchMedia("(prefers-color-scheme: dark)").matches;
};

// Not all tenants use prefers-color-scheme to determine dark-mode, but should have a class on the body to identify.
export const isDarkModeClassApplied = () => {
  return document.body.classList.contains("dark-mode");
};

export const installCssConfig = (config: ThemeConfigWithBasicColors): void =>
  Object.keys(config).forEach((key) => {
    document.body.style.setProperty(`--${key}`, (config as any)[key]);
  });

/*
  ideally, assuming UI components are solely using keys defined in ThemeConfig, it would require a lot less effort
  to support multiple themes, because we should be able to control theme changes through color configs only.

  in practice, our UI components often use basic colors directly, which makes theme changes more difficult to support;
  for example, if a component uses `var(--white)`, we cannot just provide an opposite hex value for `white` in the color
  config for dark theme.

  using installCssConfigV2 will enforce devs to use proper color variables (that are defined in ThemeConfig) only.
*/
export const installCssConfigV2 = (config: ThemeConfig): void =>
  Object.keys(config).forEach((key) => {
    document.body.style.setProperty(`--${key}`, (config as any)[key]);
  });

import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";

import styles from "./styles.module.scss";

export interface IScheduleChangeBadgeProps {
  hasMajorScheduleChange?: boolean;
  hasMinorScheduleChange?: boolean;
}

const defaultProps: Partial<IScheduleChangeBadgeProps> = {
  hasMajorScheduleChange: false,
  hasMinorScheduleChange: false,
};

const ScheduleChangeBadge = (props: IScheduleChangeBadgeProps): JSX.Element => {
  const { hasMajorScheduleChange, hasMinorScheduleChange } = props;
  const { t } = useI18nContext();
  let context = "alternatives";

  if (!hasMajorScheduleChange && !hasMinorScheduleChange) return null;

  if (hasMajorScheduleChange) {
    context = "major";
  } else if (hasMinorScheduleChange) {
    context = "minor";
  }

  return (
    <Box
      className={clsx(styles.scheduleChangeBadge, "schedule-change-badge", {
        [styles.majorChange]: hasMajorScheduleChange,
        [styles.minorChange]: hasMinorScheduleChange,
      })}
    >
      <Typography className={styles.badgeLabel}>
        {t("scheduleChange.badge", { context })}
      </Typography>
    </Box>
  );
};

ScheduleChangeBadge.defaultProps = defaultProps;

export default ScheduleChangeBadge;

import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import {
  AnyResponse,
  cardApi,
  DeletePaymentMethodRequest,
  Failure,
} from "@b2bportal/card-api";
import { axiosInstance } from "../AxiosInterceptor";
import { trackEvent } from "../tracking/trackEvent";

export const deletePaymentMethod = (
  req: DeletePaymentMethodRequest,
  apiConfig?: IApiConfig
): Promise<AnyResponse> =>
  new Promise((resolve, reject) => {
    cardApi(axiosInstance)
      .apiV0PaymentMethodsDeletePost(req)
      .then((res) => {
        const responseBody: AnyResponse = res.data;
        if ((responseBody as Failure).errors) {
          reject((responseBody as Failure).errors);
        } else {
          resolve(responseBody);
        }
      })
      .catch((e) => {
        trackEvent(
          {
            eventName: NETWORK_CALL_FAILED,
            properties: {
              url: window.location.pathname,
            },
          },
          apiConfig
        );
        reject(e);
      });
  });

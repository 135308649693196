import * as React from "react";
import { Box, Popover, Typography } from "@material-ui/core";
import clsx from "clsx";
import { TagInfo } from "@hopper-b2b/types";
import { IconName } from "../Icon";
import { IconComponent } from "../IconComponent";
import "./styles.scss";

export interface IStatusTagProps {
  className?: string;
  tagInfo: TagInfo;
}

export const StatusTag = ({
  className,
  tagInfo,
}: IStatusTagProps): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    !!tagInfo.tooltipCopy && setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      aria-owns={open ? "mouse-over-popover" : undefined}
      aria-haspopup="true"
      onClick={handlePopoverOpen}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Typography
        variant="caption"
        className={clsx("status-tag", tagInfo.type.toString(), className)}
      >
        {tagInfo.label}
        {tagInfo.iconName && (
          <IconComponent
            className="status-tag-icon"
            name={tagInfo.iconName as IconName}
          />
        )}
      </Typography>
      <Popover
        id="mouse-over-popover"
        open={!!anchorEl}
        anchorEl={anchorEl}
        style={{
          pointerEvents: "none",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography variant="subtitle1" className="status-tag-tooltip">
          <div
            dangerouslySetInnerHTML={{ __html: tagInfo.tooltipCopy || "" }}
          ></div>
        </Typography>
      </Popover>
    </Box>
  );
};

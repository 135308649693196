import { Payment, PaymentOpaqueValue } from "@b2bportal/purchase-api";
import { assign } from "xstate";

import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import {
  AirChfarExerciseDiscount,
  ParentContextWithChfarExerciseDiscount,
} from "./types";

export const actions = {
  setDiscount: assign(
    (ctx: ParentContextWithChfarExerciseDiscount, _event: unknown) => {
      const filterOutPayments = (
        ctx[ParentState.cartUpdate].addPayments ?? []
      ).filter(
        (p: PaymentOpaqueValue) => p.type !== Payment.AirChfarExerciseDiscount
      );

      if (ctx[ParentState.changeForAnyReasonDiscount]?.chfarId) {
        const discount: AirChfarExerciseDiscount = {
          type: Payment.AirChfarExerciseDiscount,
          value: {
            contractId:
              ctx[ParentState.changeForAnyReasonDiscount].chfarId ?? "",
          },
        };
        ctx[ParentState.cartUpdate].addPayments = [
          ...filterOutPayments,
          discount,
        ];
      }
      return ctx;
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject({
  ...actions,
});

import {
  lodgingLoyaltyApi,
  HotelLoyaltyRequest,
  HotelLoyaltyResponse,
} from "@b2bportal/lodging-loyalty-api";
import {
  NETWORK_CALL_FAILED,
  IApiConfig,
  HOTEL_LOYALTY_SUBMITTED,
} from "@hopper-b2b/types";
import { axiosInstance } from "../AxiosInterceptor";
import { trackEvent } from "../tracking/trackEvent";

export const loyaltySubmit = (
  req: HotelLoyaltyRequest,
  apiConfig?: IApiConfig
): Promise<HotelLoyaltyResponse> =>
  new Promise((resolve, reject) => {
    lodgingLoyaltyApi(axiosInstance)
      .apiV0LoyaltySubmitPost(req)
      .then((res) => {
        const responseBody = res.data;
        trackEvent({
          eventName: HOTEL_LOYALTY_SUBMITTED,
          properties: { ...req },
        });
        resolve(responseBody);
      })
      .catch((e) => {
        trackEvent(
          {
            eventName: NETWORK_CALL_FAILED,
            properties: {
              url: window.location.pathname,
            },
          },
          apiConfig
        );
        reject(e);
      });
  });

import dayjs from "dayjs";
import { getEnvVariables } from "@hopper-b2b/utilities";
import { I18nMarkup } from "@hopper-b2b/i18n";
import "./styles.scss";

export interface IFareDetailsSliceHeaderProps {
  origin: string;
  date: Date;
  airline: string;
}

const FareDetailsSliceHeader = ({
  origin,
  date,
  airline,
}: IFareDetailsSliceHeaderProps) => {
  return (
    <>
      <I18nMarkup
        tKey={
          getEnvVariables("clientName") === "uber"
            ? "flightShopReview.outboundCardHeader"
            : "flightShopReview.departureCardHeader"
        }
        replacements={{
          location: origin,
          date: dayjs(date).format("ddd, MMM D"),
        }}
      />
      <p className="fare-slice-header-airline">{airline}</p>
    </>
  );
};

export default FareDetailsSliceHeader;

import { getObjectKeysAsObject, setContextWithKey } from "@checkout/helpers";
import { type DoneInvokeEvent, assign, sendParent } from "xstate";
import type { PartialParentWithPassengerContext } from "./context";
import {
  type SelectPassengerEvent,
  type SetCurrentPassengerEvent,
  PassengerEventTypes,
} from "./events";
import { ParentState } from "@checkout/index";
import type { IPerson } from "@hopper-b2b/types";
import type {
  PassengerError,
  PersonListSuccess,
} from "@b2bportal/air-booking-api";
import type { DeleteUserPassengerSuccessResponse } from "./services/deleteUserPassenger";
import { isAdult } from "@hopper-b2b/utilities";

export const actions = {
  selectPassenger: assign(
    (ctx: PartialParentWithPassengerContext, event: SelectPassengerEvent) => {
      const selectedPassengerIds =
        ctx[ParentState.passengerInformation].selectedPassengerIds;
      const onUpdate = event?.data?.onUpdate || false;

      let passengerId: string, singleTravelerWorkflow: boolean;
      if (event.data) {
        passengerId = event.data.id;
        singleTravelerWorkflow = event.data.singleTravelerWorkflow;
      } else {
        passengerId = event.passengerId;
        singleTravelerWorkflow = event.singleTravelerWorkflow;
      }

      const newPassengerInfoCtx = { ...ctx[ParentState.passengerInformation] };

      if (!onUpdate && selectedPassengerIds.includes(passengerId)) {
        const newSelectedPassengerIds = selectedPassengerIds.filter(
          (id) => passengerId !== id
        );
        newPassengerInfoCtx.selectedPassengerIds = newSelectedPassengerIds;
      } else {
        // If passenger not in either list, add to appropriate list
        const userPassengers =
          ctx[ParentState.passengerInformation].userPassengers;
        const newPassenger = userPassengers.find(
          (passenger) => passenger.id === passengerId
        );
        if (!newPassenger) return ctx;
        if (singleTravelerWorkflow) {
          newPassengerInfoCtx.selectedPassengerIds = [passengerId];
        } else {
          newPassengerInfoCtx.selectedPassengerIds = [
            ...selectedPassengerIds,
            passengerId,
          ];
        }
      }

      return {
        ...ctx,
        [ParentState.passengerInformation]: newPassengerInfoCtx,
      };
    }
  ),

  setUserPassengers: assign(
    (
      ctx: PartialParentWithPassengerContext,
      event: DoneInvokeEvent<PersonListSuccess>
    ) =>
      setContextWithKey(
        ctx,
        `${ParentState.passengerInformation}.userPassengers`,
        event.data?.value || event.data
      )
  ),

  goPrevious: sendParent(PassengerEventTypes.PREVIOUS),

  handleDeletePassenger: assign(
    (
      ctx: PartialParentWithPassengerContext,
      event: DoneInvokeEvent<DeleteUserPassengerSuccessResponse>
    ) => {
      const selectedPassengerIds =
        ctx[ParentState.passengerInformation].selectedPassengerIds;
      const newSelectedPassengerIds = selectedPassengerIds.filter(
        (id) => event.data.deletedPassengerId !== id
      );
      ctx[ParentState.passengerInformation].selectedPassengerIds =
        newSelectedPassengerIds;
      ctx[ParentState.passengerInformation].userPassengers = event.data
        .userPassengers as IPerson[];
      return ctx;
    }
  ),

  setSingleDefaultPassenger: assign(
    (ctx: PartialParentWithPassengerContext) => {
      if (
        ctx[ParentState.passengerInformation].selectedPassengerIds.length > 0
      ) {
        return ctx;
      }

      if (ctx[ParentState.passengerInformation].userPassengers.length > 0) {
        ctx[ParentState.passengerInformation].selectedPassengerIds = [
          ctx[ParentState.passengerInformation].userPassengers[0].id,
        ];
      }
      return ctx;
    }
  ),

  setSingleDefaultAdult: assign((ctx: PartialParentWithPassengerContext) => {
    if (ctx[ParentState.passengerInformation].selectedPassengerIds.length > 0) {
      return ctx;
    }

    if (ctx[ParentState.passengerInformation].userPassengers.length > 0) {
      const firstAdult = ctx[
        ParentState.passengerInformation
      ].userPassengers.find((u) => isAdult(u.dateOfBirth));
      if (firstAdult) {
        ctx[ParentState.passengerInformation].selectedPassengerIds = [
          firstAdult.id,
        ];
      }
    }
    return ctx;
  }),

  setCurrentPassenger: assign(
    (ctx: PartialParentWithPassengerContext, event: SetCurrentPassengerEvent) =>
      setContextWithKey(
        ctx,
        `${ParentState.passengerInformation}.currentUserPassenger`,
        event.passenger
      )
  ),

  dismissNumPassengerAlert: assign(
    (
      ctx: PartialParentWithPassengerContext,
      _event: SetCurrentPassengerEvent
    ) =>
      setContextWithKey(
        ctx,
        `${ParentState.passengerInformation}.numPassengerAlertDismissed`,
        true
      )
  ),

  setPassengersError: assign(
    (
      ctx: PartialParentWithPassengerContext,
      event: DoneInvokeEvent<PassengerError>
    ) =>
      setContextWithKey(
        ctx,
        `${ParentState.passengerInformation}.error`,
        event.data
      )
  ),

  clearPassengerInformationError: assign(
    (ctx: PartialParentWithPassengerContext, _event) => {
      ctx[ParentState.passengerInformation].error = undefined;
      return ctx;
    }
  ),

  unselectAllPassengers: assign(
    (ctx: PartialParentWithPassengerContext, _event) => {
      ctx[ParentState.passengerInformation].selectedPassengerIds = [];
      return ctx;
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject(actions);

import { Box, Typography, TypographyProps } from "@material-ui/core";
import clsx from "clsx";

import "./styles.scss";

export interface IImportantInfoListProps {
  className?: string;
  infoItems: string[];
  infoProps?: TypographyProps;
  ordered?: boolean;
  title: string;
  titleProps?: TypographyProps;
}

const defaultProps: Partial<IImportantInfoListProps> = {
  className: "",
  infoProps: {},
  ordered: false,
  titleProps: {},
};

const ImportantInfoList = (props: IImportantInfoListProps): JSX.Element => {
  const { className, infoItems, infoProps, ordered, title, titleProps } = props;
  const ListElement = ordered ? "ol" : "ul";

  return (
    <Box className={clsx("info-items-container", className)}>
      <Typography
        variant="h4"
        {...titleProps}
        className={clsx("info-items-title", titleProps?.className)}
      >
        {title}
      </Typography>
      <ListElement className="info-list">
        {infoItems.map((infoText) => (
          <li className="info-item" key={infoText}>
            <Typography
              component="span"
              dangerouslySetInnerHTML={{ __html: infoText }}
              key={infoText}
              {...infoProps}
              className={clsx("info-text", infoProps?.className)}
            />
          </li>
        ))}
      </ListElement>
    </Box>
  );
};

ImportantInfoList.defaultProps = defaultProps;

export default ImportantInfoList;

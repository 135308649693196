import { CyTestInterface } from "@hopper-b2b/cypress";
import { Box, Dialog, DialogProps, ModalProps, Slide } from "@material-ui/core";
import clsx from "clsx";
import { FunctionComponent, PropsWithChildren, ReactNode } from "react";

import "./styles.scss";

export type MobilePopoverCardDirection = "up" | "down" | "left" | "right";

export interface IMobilePopoverCardProps extends CyTestInterface {
  BackdropProps?: DialogProps["BackdropProps"];
  id?: string;
  role?: string;
  open: boolean;
  className?: string;
  classes?: DialogProps["classes"];
  container?: DialogProps["container"];
  scroll?: "body" | "paper";
  contentClassName?: string;
  direction?: MobilePopoverCardDirection;
  disablePortal?: DialogProps["disablePortal"];
  fullScreen?: boolean;
  // note: when fullScreen!==true, the PopoverCard will be centered horizontally if centered===true
  centered?: boolean;
  headerElement?: JSX.Element | string;
  topLeftButton?: JSX.Element;
  topRightButton?: JSX.Element;
  bottomButton?: JSX.Element;
  onClose?: ModalProps["onClose"];
  fullScreenWithBanner?: boolean;
  disableEnforceFocus?: boolean;
  children?: ReactNode;
  scrollableContainerRef?: React.RefObject<HTMLElement>;
  paperClassName?: string;
}

export const MobilePopoverCard: FunctionComponent<IMobilePopoverCardProps> = ({
  BackdropProps = { invisible: false },
  id,
  role,
  open,
  onClose,
  className,
  classes,
  container,
  contentClassName,
  direction,
  disablePortal,
  children,
  fullScreen,
  centered = true,
  headerElement,
  topLeftButton,
  topRightButton,
  bottomButton,
  fullScreenWithBanner,
  disableEnforceFocus,
  scrollableContainerRef,
  paperClassName = "",
  dataTest,
}: PropsWithChildren<IMobilePopoverCardProps>) => (
  <Dialog
    id={id}
    role={role}
    hideBackdrop={false}
    BackdropProps={BackdropProps}
    open={open}
    onClose={onClose}
    TransitionComponent={Slide}
    classes={{ ...classes, paper: paperClassName }}
    TransitionProps={{
      // See https://mui.com/material-ui/transitions/#performance-amp-seo
      unmountOnExit: true,
      /* eslint-disable */
      // @ts-ignore: Fix the type definition of TransitionProps to include direction.
      direction: direction ?? "up",
      /* eslint-enable */
    }}
    className={clsx("mobile-popover-card-root", className, {
      "full-screen": fullScreen,
      centered,
      "full-screen-banner": fullScreenWithBanner,
    })}
    disableEnforceFocus={disableEnforceFocus}
    disablePortal={disablePortal}
    paperfullscreen={fullScreen ? "true" : "false"}
    data-test={dataTest}
    container={container}
  >
    <Box ref={scrollableContainerRef} className="mobile-popover-card-container">
      {headerElement && (
        <Box className="mobile-popover-card-header">{headerElement}</Box>
      )}
      <Box className={clsx("mobile-popover-card-top-button", "left")}>
        {topLeftButton}
      </Box>
      <Box className={clsx("mobile-popover-card-top-button", "right")}>
        {topRightButton}
      </Box>
      <Box
        className={clsx(
          "mobile-popover-card-content-container",
          contentClassName
        )}
      >
        {children}
      </Box>
      {bottomButton && (
        <Box className="mobile-popover-card-bottom-button">{bottomButton}</Box>
      )}
    </Box>
  </Dialog>
);

import { getObjectKeysAsObject } from "../../../../helpers";
import { completeInteractiveFulfillService } from "./completeInteractiveFulfill";
import { loyaltySubmitService } from "./loyaltySubmit";
import { pollFulfillService } from "./pollFulfill";
import { scheduleFulfillService } from "./scheduleFulfill";
import { pollInteractivePaymentAuthService } from "./pollInteractivePaymentAuth";

export const services = {
  completeInteractiveFulfillService,
  loyaltySubmitService,
  pollFulfillService,
  scheduleFulfillService,
  pollInteractivePaymentAuthService,
};

export const ServiceTypes = getObjectKeysAsObject(services);

import { useState, useEffect } from "react";
import { Box, styled } from "@material-ui/core";
import "./styles.scss";
import clsx from "clsx";

export interface IDotsIndicatorProps {
  goLeft: boolean;
  setGoLeft: (goLeft: boolean) => void;
  goRight: boolean;
  setGoRight: (goRight: boolean) => void;
  smallVersion?: boolean;
}

const DEFAULT_DOT_SIZE = 6;
const DEFAULT_DOT_SIZE_SMALL = 4;
const DEFAULT_SPACING_SIZE = 7;
const DEFAULT_SPACING_SIZE_SMALL = 5;
// dots number must be odd
const DEFAULT_DOTS_NUMBER = 5;
const DEFAULT_DOTS_NUMBER_SMALL = 3;

export const DotsIndicator = ({
  goLeft,
  setGoLeft,
  goRight,
  setGoRight,
  smallVersion,
}: IDotsIndicatorProps) => {
  const [isMoving, setIsMoving] = useState(false);
  useEffect(() => {
    // the isMoving field is used to prevent the moving effect from taking place twice at the same time
    if ((goLeft || goRight) && !isMoving) {
      setIsMoving(true);
      setTimeout(() => {
        goLeft && setGoLeft(false);
        goRight && setGoRight(false);
        setIsMoving(false);
        // the duration of slide animation is set to 0.2s (see styles.scss)
      }, 200);
    }
  }, [goLeft, goRight, isMoving, setGoLeft, setGoRight]);

  return (
    <Box className={clsx("slider-dots-root", { small: smallVersion })}>
      <Dots goLeft={goLeft} goRight={goRight} smallVersion={smallVersion} />
    </Box>
  );
};

type DotProps = {
  dotwidth: number;
  dotmarginright: number;
};

const Dot = styled(Box)(({ dotwidth, dotmarginright }: DotProps) => ({
  height: `${dotwidth}px`,
  width: `${dotwidth}px`,
  marginRight: `${dotmarginright}px`,
}));

type DotsWrapperProps = {
  dotspaperwidth: number;
};

const DotsWrapper = styled(Box)(({ dotspaperwidth }: DotsWrapperProps) => ({
  width: `${dotspaperwidth}px`,
  left: `calc(50% - ${dotspaperwidth / 2}px)`,
}));

type DotsContainerProps = {
  dotspaperwidth: number;
  dotwidth: number;
};

const DotsContainer = styled(Box)(
  ({ dotspaperwidth, dotwidth }: DotsContainerProps) => ({
    width: `${dotspaperwidth}px`,
    height: `${2.5 * dotwidth}px`,
  })
);

type DotsProps = {
  goLeft: boolean;
  goRight: boolean;
  smallVersion: boolean;
};

function Dots({ goLeft, goRight, smallVersion }: DotsProps) {
  const dotWidth = smallVersion ? DEFAULT_DOT_SIZE_SMALL : DEFAULT_DOT_SIZE;
  const dotMarginRight = smallVersion
    ? DEFAULT_SPACING_SIZE_SMALL
    : DEFAULT_SPACING_SIZE;
  const numberOfDots = smallVersion
    ? DEFAULT_DOTS_NUMBER_SMALL
    : DEFAULT_DOTS_NUMBER;

  const dotsArray: JSX.Element[] = [];
  for (let i = 0; i < numberOfDots; i++) {
    dotsArray.push(
      <Dot
        key={i + 1}
        className={clsx(
          "dot",
          { "left-most": i === 0 },
          { "right-most": i === numberOfDots - 1 },
          { focused: i === Math.floor(numberOfDots / 2) },
          { "focused-left": i === Math.floor(numberOfDots / 2) - 1 },
          { "focused-right": i === Math.floor(numberOfDots / 2) + 1 },
          { "go-left": goLeft },
          { "go-right": goRight }
        )}
        dotwidth={dotWidth}
        dotmarginright={dotMarginRight}
      />
    );
  }
  dotsArray.unshift(
    <Dot
      key={0}
      className={clsx("left-hidden", "dot", { "go-right": goRight })}
      dotwidth={dotWidth}
      dotmarginright={dotMarginRight}
    />
  );
  dotsArray.push(
    <Dot
      key={numberOfDots + 1}
      className={clsx("right-hidden", "dot", { "go-left": goLeft })}
      dotwidth={dotWidth}
      dotmarginright={dotMarginRight}
    />
  );

  const dotsWrapperWidth =
    (numberOfDots + 2) * dotWidth + (numberOfDots + 1) * dotMarginRight;

  return (
    <DotsContainer
      className={clsx("dots-container")}
      dotwidth={dotWidth}
      dotspaperwidth={dotsWrapperWidth}
    >
      <DotsWrapper
        className={clsx(
          "dots-wrapper",
          { "go-left": goLeft },
          { "go-right": goRight },
          { small: smallVersion }
        )}
        dotspaperwidth={dotsWrapperWidth}
      >
        {dotsArray}
      </DotsWrapper>
    </DotsContainer>
  );
}

import { getObjectKeysAsObject } from "../../../../helpers";
import { pollPriceQuoteService } from "./pollPriceQuote";
import { schedulePriceQuoteService } from "./schedulePriceQuote";
import { discardQuotedCartService } from "./discardQuotedCart";

export const services = {
  pollPriceQuoteService,
  schedulePriceQuoteService,
  discardQuotedCartService,
};

export const ServiceTypes = getObjectKeysAsObject(services);

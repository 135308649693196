import { isEmpty } from "lodash-es";
import { State } from "xstate";
import { AirCfarSchedulePriceQuoteRequest } from "@b2bportal/air-cfar-api";
import { ParentState } from "@checkout/types";
import { CompleteBuyAirCfarChoices } from "@hopper-b2b/types";
import { FlightSelectors } from "../Flight";
import { PartialParentContextWithAirCfarV2 } from "./types";

type CfarUpdateStateType = State<PartialParentContextWithAirCfarV2>;
type CfarUpdateStateWithoutValue = Pick<CfarUpdateStateType, "context">;

export const getCfarCartUpdateRequest = (
  state: CfarUpdateStateWithoutValue
) => {
  const selectedTrip = FlightSelectors.getSelectedTripParent(state);
  const tripId = selectedTrip.tripId ?? "";
  const cfarQuoteId = getCfarQuoteId(state) ?? "";
  const seatedPaxCount =
    state.context.passengerInformation.selectedPassengerIds.length;
  const params: AirCfarSchedulePriceQuoteRequest = {
    tripId,
    cfarQuoteId,
    seatedPaxCount,
  };

  return params;
};

export const getCfarQuoteId = (
  state: CfarUpdateStateType | CfarUpdateStateWithoutValue
) => state.context[ParentState.cancelForAnyReasonV2].cfarQuoteId;

export const getSelectedCfarOffer = (state: CfarUpdateStateWithoutValue) => {
  return state.context[ParentState.cancelForAnyReasonV2].selectedOffer;
};

import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { PartialParentContextWithAirDisruption } from "./types";

export const guards = {
  hasDisruptionQuoteIdsAndEnabled: (
    ctx: PartialParentContextWithAirDisruption,
    _event: unknown
  ) =>
    (ctx[ParentState.disruption]?.selectedOffers?.length || 0) > 0 &&
    (ctx.featureFlags?.enableScheduleChange ||
      ctx.featureFlags?.enableMissedConnection),
};

export const GuardTypes = getObjectKeysAsObject(guards);

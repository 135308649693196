import { Currency } from "@hopper-b2b/types";

export const CURRENCY_LABELS = {
  [Currency.MXN]: "mexicanPeso",
  [Currency.USD]: "usDollar",
  [Currency.CAD]: "cadDollar",
};

export const B2B_CURRENCY_PREFERENCE_KEY = "b2b-currency-preference";
export const DEFAULT_CURRENCY = import.meta.env
  .VITE_DEFAULT_CURRENCY as keyof typeof CURRENCY_LABELS;

import { Box } from "@material-ui/core";
import clsx from "clsx";
import { uniqueId } from "lodash-es";
import { ReactNode, useCallback, useEffect, useState } from "react";

import {
  Airline,
  Airport,
  BookedFlightItineraryWithDepartureTime,
  FlightItinerarySlice,
  SingleProvider,
  TravelItineraryEnum,
} from "@b2bportal/air-booking-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ActionButton } from "@hopper-b2b/ui-core";
import { useDeviceTypes } from "@hopper-b2b/utilities";

import { CheckboxCard } from "../CheckboxCard";
import { GenericModalContent } from "../GenericModalContent";
import { InfoBanner } from "../InfoBanner";
import { ItinerarySummary } from "../ItinerarySummary";

import styles from "./styles.module.scss";

export interface ISlicePickerModalContentProps {
  actionLabel?: string;
  airlines: Record<string, Airline>;
  airports: Record<string, Airport>;
  bannerIcon?: string;
  checkboxLabel: string;
  className?: string;
  flight: BookedFlightItineraryWithDepartureTime;
  getExtraContent?: (locator: string) => ReactNode;
  isRadio?: boolean;
  isSliceChecked?: (
    slice: FlightItinerarySlice,
    idx: number,
    locator?: string
  ) => boolean;
  isSliceDisabled?: (
    slice: FlightItinerarySlice,
    idx: number,
    locator?: string
  ) => boolean;
  onContactSupport?: () => void;
  onContinue: (selected: SliceOpt[]) => void;
  onSliceToggle?: (sliceIdx: number, isToggled: boolean) => void;
  subtitle: string;
  title: string;
}

type SliceOpt = {
  checked: boolean;
  disabled: boolean;
  id: string;
  locator: string;
  slice: FlightItinerarySlice;
};

const defaultProps: Partial<ISlicePickerModalContentProps> = {};

const SlicePickerModalContent = (
  props: ISlicePickerModalContentProps
): JSX.Element => {
  const {
    actionLabel,
    airlines,
    airports,
    bannerIcon,
    checkboxLabel,
    className,
    flight,
    getExtraContent,
    isRadio,
    isSliceChecked,
    isSliceDisabled,
    onContactSupport,
    onContinue,
    onSliceToggle,
    subtitle,
    title,
  } = props;
  const { travelItinerary } = flight.bookedItinerary;
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const [showBanner, setShowBanner] = useState(false);
  const [sliceOpts, setSliceOpts] = useState<SliceOpt[]>([]);
  const anyChecked = sliceOpts.some((sliceOpt) => sliceOpt.checked);

  const getSliceOptsFromSlices = useCallback(
    (slices: FlightItinerarySlice[], locators?: SingleProvider) =>
      slices.map((slice, idx) => ({
        slice,
        checked: isSliceChecked?.(slice, idx, locators?.b2b) || false,
        disabled: isSliceDisabled?.(slice, idx, locators?.b2b) || false,
        locator: locators?.b2b,
        id: uniqueId("slice-opt-"),
      })),
    [isSliceChecked, isSliceDisabled]
  );

  const handleContinue = () => {
    const selectedSlices: SliceOpt[] = [];

    for (let i = 0; i < sliceOpts.length; i += 1) {
      const { checked } = sliceOpts[i];

      if (checked) {
        selectedSlices.push(sliceOpts[i]);
      }
    }

    onContinue(selectedSlices);
  };

  const handleSliceToggle = (sliceId: string, isChecked: boolean) => {
    let sliceIdx = -1;
    const newSliceOpts = [...sliceOpts];

    for (let i = 0; i < newSliceOpts.length; i += 1) {
      const { disabled, id } = newSliceOpts[i];

      if (id === sliceId && !disabled) {
        newSliceOpts[i].checked = isChecked;
        sliceIdx = i;
      }

      if (isRadio && isChecked && id !== sliceId) {
        // uncheck other options to simulate radio button behavior
        newSliceOpts[i].checked = false;
      }
    }

    onSliceToggle?.(sliceIdx, isChecked);
    setSliceOpts(newSliceOpts);
  };

  useEffect(() => {
    const { TravelItinerary } = travelItinerary;
    let anyDisabled = false;
    let newSliceOpts: SliceOpt[];

    if (TravelItinerary === TravelItineraryEnum.SingleTravelItinerary) {
      const { locators, slices } = travelItinerary;
      newSliceOpts = getSliceOptsFromSlices(slices, locators);
    } else {
      newSliceOpts = travelItinerary.travelItineraries.flatMap((tI) => {
        const { locators, slices } = tI;
        return getSliceOptsFromSlices(slices, locators);
      });
    }

    anyDisabled = newSliceOpts.some((sO) => sO.disabled);

    setShowBanner(anyDisabled);
    setSliceOpts(newSliceOpts);
  }, [getSliceOptsFromSlices, isSliceDisabled, travelItinerary]);

  return (
    <GenericModalContent
      actions={
        <>
          {onContactSupport && showBanner && (
            <ActionButton
              className={clsx(
                "slice-picker-contact-support-btn",
                styles.contactSupportBtn
              )}
              defaultStyle="h4r-secondary"
              message={t("contactSupport")}
              onClick={onContactSupport}
            />
          )}
          <ActionButton
            className={clsx("slice-picker-continue-btn", styles.continueBtn)}
            defaultStyle="h4r-primary"
            disabled={!anyChecked}
            message={actionLabel || t("continue")}
            onClick={handleContinue}
          />
        </>
      }
      className={clsx(
        "slice-picker-modal-content",
        styles.slicePickerContainer,
        className,
        { mobile: matchesMobile }
      )}
      columnAlign="flex-start"
      content={
        <Box className={clsx("slices-container", styles.slicesContainer)}>
          {showBanner ? (
            <InfoBanner
              className="slice-picker-info-banner"
              icon={bannerIcon}
              text={t("selfServe.slicePickerInfo")}
            />
          ) : null}
          <Box className={clsx("slice-list", styles.sliceList)}>
            {sliceOpts.map((sliceOpt, index) => (
              <CheckboxCard
                checked={sliceOpt.checked}
                className={clsx("slice-option", styles.sliceOption, {
                  [styles.selected]: sliceOpt.checked,
                })}
                content={
                  <>
                    <ItinerarySummary
                      hideMixedCabinTooltip
                      airlineMap={airlines}
                      airportMap={airports}
                      className={clsx("slice", {
                        outbound: index === 0,
                        return: index === sliceOpts.length - 1,
                      })}
                      flight={flight}
                      isOutgoing={index === 0}
                    />
                    {getExtraContent?.(sliceOpt.locator)}
                  </>
                }
                disabled={sliceOpt.disabled}
                key={sliceOpt.id}
                message={checkboxLabel}
                onClick={() =>
                  handleSliceToggle(sliceOpt.id, !sliceOpt.checked)
                }
              />
            ))}
          </Box>
        </Box>
      }
      subtitle={subtitle}
      title={title}
    />
  );
};

SlicePickerModalContent.defaultProps = defaultProps;

export default SlicePickerModalContent;

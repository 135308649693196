export const capitalize = (s: string): string =>
  s
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(" ");

export const camelCaseToTitleCase = (inputString: string) => {
  return inputString.replace(/([a-z])([A-Z])/, "$1 $2");
};

export const inString = (s1: string, s2: string) => {
  return s1.toLowerCase().indexOf(s2.toLowerCase()) !== -1;
};

export function* generateParagraphs(count: number) {
  const texts = [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur feugiat arcu quis massa tristique vestibulum. Quisque rhoncus nibh vel ligula consectetur, in luctus odio ullamcorper. Morbi ut diam vel neque sagittis ornare nec tempor neque. Donec quis dolor scelerisque, pellentesque felis sit amet, porta mi. Aenean posuere elit vel augue cursus tempus. Maecenas lobortis blandit velit id interdum. Curabitur vel elit malesuada, iaculis leo quis, rutrum augue. Aliquam congue ante id odio sollicitudin viverra. Duis ac dignissim sem, eget semper massa. Mauris viverra iaculis nunc, pulvinar aliquam felis convallis at. Quisque sodales nunc eget lacus mollis fermentum.",
    "Morbi volutpat risus et nisl egestas blandit. Mauris aliquam tincidunt sem, mollis mollis purus. Proin ultricies lacus est, eleifend dictum ipsum efficitur sit amet. Duis eu orci feugiat, blandit enim sit amet, hendrerit ipsum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Ut iaculis eu tellus commodo tempus. Vivamus ligula urna, finibus a tincidunt ut, imperdiet vel velit. Sed viverra odio non nulla fringilla, non luctus lectus volutpat. Morbi molestie ante eget risus iaculis, eu tristique ligula condimentum. Sed porttitor fringilla neque ut dictum. Vivamus arcu turpis, tincidunt eu justo sit amet, mattis tempus augue. Vestibulum at nulla sapien. In malesuada pharetra enim id fringilla. Integer bibendum augue eget tellus fermentum, a pretium sapien sodales.",
    "Sed massa felis, efficitur non feugiat eleifend, consequat nec augue. Proin non aliquam ante. Praesent imperdiet non diam ut tempor. In finibus mi tortor, eu elementum massa aliquet nec. Ut quis libero at nisi gravida feugiat sit amet sed quam. Etiam eget malesuada nisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut rutrum eleifend orci a fringilla. Vestibulum odio quam, accumsan vel nulla vel, interdum lacinia elit.",
    "Curabitur et fringilla ex, id vehicula lacus. Fusce dapibus risus ac cursus viverra. Aenean nec sagittis orci, a rhoncus eros. Ut accumsan id mi id commodo. Sed eget eleifend purus. Etiam et gravida nibh, quis posuere velit. Ut dictum felis id felis efficitur sodales. Maecenas rutrum fringilla risus ac mattis. Sed quis tortor bibendum, volutpat velit consequat, ullamcorper mauris. Curabitur ornare turpis eros, at rutrum velit faucibus eu. In in sagittis lorem, nec mollis dolor. Cras auctor volutpat elit.",
    "Donec malesuada leo et sagittis euismod. Aenean tincidunt, justo iaculis convallis eleifend, lacus ante luctus nulla, vitae pellentesque lacus eros vitae lectus. Maecenas iaculis facilisis ante, eu feugiat justo congue sed. Pellentesque sagittis sit amet diam non lacinia. Vestibulum aliquam placerat dictum. Duis pellentesque suscipit metus, ac maximus turpis scelerisque ut. Ut ac sollicitudin nibh. Integer semper sapien odio, sed interdum felis luctus sit amet. Ut suscipit risus a quam aliquam, ut rhoncus dui porttitor. Etiam iaculis aliquet mi ut cursus. Integer eget nibh at dui suscipit ornare.",
  ];
  for (let i = 0; i < count; ++i) {
    yield texts[i % texts.length];
  }
  return texts[count % texts.length];
}

export const pluralize = (n: number, single: string, plural: string) => {
  return n > 1 ? plural : single;
};

export const copyToClipboard = (s: string) => navigator.clipboard.writeText(s);

export const htmlDecode = (s: string) => {
  const parser = new DOMParser();
  const dom = parser.parseFromString("<!doctype html><body>" + s, "text/html");
  const decodedString = dom.body.textContent;
  return decodedString;
};

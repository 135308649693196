import { getObjectKeysAsObject } from "@checkout/helpers";
import { deleteUserPassengersService } from "./deleteUserPassenger";
import { fetchUserPassengersService } from "./fetchUserPassengers";
import { trackEditTravelers } from "./trackEditTravelers";
import { trackNewTraveler } from "./trackNewTraveler";
import { trackViewTravelers } from "./trackViewTravelers";
import { updateUserPassengerService } from "./updateUserPassenger";
import { validateUserPassengersService } from "./validateUserPassengers";
import { validateUserPassengersParentService } from "./validateUserPassengersParent";

export const services = {
  deleteUserPassengersService,
  fetchUserPassengersService,
  trackViewTravelers,
  trackEditTravelers,
  trackNewTraveler,
  updateUserPassengerService,
  validateUserPassengersService,
  validateUserPassengersParentService,
};

export const ServiceTypes = getObjectKeysAsObject(services);

import { assign, DoneInvokeEvent, send, sendParent } from "xstate";
import { setContextWithKey, getObjectKeysAsObject } from "@checkout/helpers";
import {
  ParentState,
  PassengerError,
  PassengerErrorModalTypes,
} from "@checkout/types";
import { ChildPassengerInformationContext } from "./context";
import {
  FetchTripPricing,
  OnInfantModalContinue,
  OpenPassengerFormAndSetCurrentPassenger,
  FlightPassengerEventTypes,
  PassengerInformationCompleteEvent,
  SelectPassengerEvent,
  SetCurrentPassengerEvent,
  ValidateAndFetchTripPricingEvent,
} from "./events";
import {
  Failure,
  PersonListSuccess,
  TripPricingSummary,
} from "@b2bportal/air-booking-api";
import { DeleteUserPassengerSuccessResponse } from "./services/deleteUserPassenger";
import { getIsLapInfant } from "./selectors";
import { FlightContext } from "../../types";

/**
 * ----------------------
 * Fired in child machine
 * ----------------------
 */
export const childActions = {
  selectPassenger: assign(
    (ctx: ChildPassengerInformationContext, event: SelectPassengerEvent) => {
      const selectedFareId =
        ctx.selectedTrip.returnFareId || ctx.selectedTrip.outgoingFareId;
      const fareDetails = ctx.tripDetails.fareDetails;
      const excludeINS = fareDetails
        .filter((fare) => fare.id === selectedFareId)
        .some((fare) => !!fare.excludeINS);

      const { singleTravelerWorkflow } = event;
      const onUpdate = event?.data?.onUpdate || false;

      let passengerId: string;
      if (event.data) {
        passengerId = event.data.id;
      } else {
        passengerId = event.passengerId;
      }
      const selectedPassengerIds = ctx.selectedPassengerIds;
      const selectedLapInfantIds = ctx.selectedLapInfantIds;

      const newPassengerInfoCtx = { ...ctx };

      // Remove passenger from appropriate list if exists and action is not triggered onUpdate
      if (!onUpdate && selectedPassengerIds.includes(passengerId)) {
        const newSelectedPassengerIds = selectedPassengerIds.filter(
          (id) => passengerId !== id
        );
        newPassengerInfoCtx.selectedPassengerIds = newSelectedPassengerIds;
      } else if (!onUpdate && selectedLapInfantIds.includes(passengerId)) {
        const newSelectedLapInfantIds = selectedLapInfantIds.filter(
          (id) => passengerId !== id
        );
        newPassengerInfoCtx.selectedLapInfantIds = newSelectedLapInfantIds;
      } else {
        // If passenger not in either list, add to appropriate list
        const userPassengers = ctx.userPassengers;
        const newPassenger = userPassengers.find(
          (passenger) => passenger.id === passengerId
        );
        if (!newPassenger) return newPassengerInfoCtx;
        const isNewPassengerInfant = getIsLapInfant(newPassenger);
        if (isNewPassengerInfant) {
          // TODO: Figure out if singleTravelerWorkflow is needed
          // Handle adding infants, if singleTravelerWorkflow then we should throw an error
          if (selectedPassengerIds.length === 0 || singleTravelerWorkflow) {
            newPassengerInfoCtx.infantOnlyError = true;
          } else {
            if (excludeINS) {
              // If the excludeINS is true, we only allow infants to be added as an INF
              // TODO: Add a popup to inform the user about this case instead of silently adding the user
              newPassengerInfoCtx.selectedLapInfantIds = [
                ...selectedLapInfantIds,
                passengerId,
              ];
            } else {
              newPassengerInfoCtx.currentInfantId = newPassenger.id;
            }
          }
        } else {
          // Add userPassengers (i.e. Not infants)

          // Hacky way to ensure user can only select a single traveler.
          if (singleTravelerWorkflow) {
            newPassengerInfoCtx.selectedPassengerIds = [passengerId];
          } else {
            newPassengerInfoCtx.selectedPassengerIds = [
              ...selectedPassengerIds.filter((id) => id !== passengerId),
              passengerId,
            ];
          }
        }
      }

      return newPassengerInfoCtx;
    }
  ),

  setUserPassengers: assign(
    (
      ctx: ChildPassengerInformationContext,
      event: DoneInvokeEvent<PersonListSuccess>
    ) =>
      setContextWithKey(ctx, "userPassengers", event.data?.value || event.data)
  ),

  handleDeletePassenger: assign(
    (
      ctx: ChildPassengerInformationContext,
      event: DoneInvokeEvent<DeleteUserPassengerSuccessResponse>
    ) => {
      const selectedPassengerIds = ctx.selectedPassengerIds;
      const newSelectedPassengerIds = selectedPassengerIds.filter(
        (id) => event.data.deletedPassengerId !== id
      );
      ctx.selectedPassengerIds = newSelectedPassengerIds;
      ctx.userPassengers = event.data.userPassengers;
      return ctx;
    }
  ),

  setSingleDefaultPassenger: assign((ctx: ChildPassengerInformationContext) => {
    if (ctx.userPassengers.length === 1) {
      ctx.selectedPassengerIds.push(ctx.userPassengers[0].id);
    }
    return ctx;
  }),

  setCurrentPassenger: assign(
    (ctx: ChildPassengerInformationContext, event: SetCurrentPassengerEvent) =>
      setContextWithKey(ctx, "currentUserPassenger", event.passenger)
  ),

  dismissNumPassengerAlert: assign(
    (ctx: ChildPassengerInformationContext, _event: SetCurrentPassengerEvent) =>
      setContextWithKey(ctx, "numPassengerAlertDismissed", true)
  ),

  setPassengersError: assign(
    (
      ctx: ChildPassengerInformationContext,
      event: DoneInvokeEvent<PassengerError>
    ) => setContextWithKey(ctx, "error", event.data)
  ),

  clearCurrentInfantId: assign(
    (ctx: ChildPassengerInformationContext, _event) =>
      setContextWithKey(ctx, "currentInfantId", undefined)
  ),

  onInfantModalContinue: assign(
    (
      ctx: ChildPassengerInformationContext,
      { seatType }: OnInfantModalContinue
    ) => {
      let selectedLapInfantIds = ctx.selectedLapInfantIds;
      let selectedPassengerIds = ctx.selectedPassengerIds;
      const currentInfantId = ctx.currentInfantId;

      if (seatType === "OwnSeat") {
        selectedLapInfantIds = selectedLapInfantIds.filter(
          (id) => id !== (currentInfantId as string)
        );

        selectedPassengerIds = [
          ...selectedPassengerIds,
          currentInfantId as string,
        ];
      } else {
        selectedLapInfantIds = [
          ...selectedLapInfantIds,
          currentInfantId as string,
        ];
      }

      const updatedContext = ctx;
      updatedContext.selectedPassengerIds = selectedPassengerIds;
      updatedContext.selectedLapInfantIds = selectedLapInfantIds;

      return ctx;
    }
  ),

  onValidateAndTripPricingError: assign(
    (
      ctx: ChildPassengerInformationContext,
      event: DoneInvokeEvent<PassengerError | Failure>
    ) => {
      const errorData = event.data;
      const isValidateError = errorData as PassengerError;
      const tripPricingErrors = (errorData as Failure)?.errors;
      if (isValidateError) {
        return setContextWithKey(ctx, "error", {
          type: (errorData as PassengerError).type,
          data: (errorData as PassengerError).data,
        });
      } else if (tripPricingErrors) {
        return setContextWithKey(ctx, "error", {
          type: PassengerErrorModalTypes.TripPricing,
          data: tripPricingErrors,
        });
      }
      return ctx;
    }
  ),

  clearPassengerInformationError: assign(
    (ctx: ChildPassengerInformationContext, _event) => {
      ctx.infantOnlyError = false;
      ctx.error = undefined;
      return ctx;
    }
  ),

  passengerInformationComplete: sendParent(
    (ctx: ChildPassengerInformationContext) => ({
      type: FlightPassengerEventTypes.COMPLETE,
      passengerContext: {
        selectedPassengerIds: ctx.selectedPassengerIds,
        selectedLapInfantIds: ctx.selectedLapInfantIds,
        userPassengers: ctx.userPassengers,
        addPassport: ctx.addPassport,
        infantOnlyError: ctx.infantOnlyError,
      },
    })
  ),

  goPrevious: sendParent(FlightPassengerEventTypes.PREVIOUS),

  onFetchTripPricing: sendParent(
    (
      _ctx: ChildPassengerInformationContext,
      event: DoneInvokeEvent<ValidateAndFetchTripPricingEvent>
    ) => {
      const [, tripPricingEvent] = event.data;
      return {
        type: FlightPassengerEventTypes.FETCH_TRIP_PRICING,
        tripPricing: tripPricingEvent,
      };
    }
  ),
  openPassportInParent: sendParent(
    (
      ctx: ChildPassengerInformationContext,
      _event: DoneInvokeEvent<ValidateAndFetchTripPricingEvent>
    ) => {
      return {
        type: FlightPassengerEventTypes.OPEN_PASSPORT_FORM,
        passengerContext: {
          selectedPassengerIds: ctx.selectedPassengerIds,
          selectedLapInfantIds: ctx.selectedLapInfantIds,
          userPassengers: ctx.userPassengers,
          addPassport: ctx.addPassport,
          infantOnlyError: ctx.infantOnlyError,
        },
      };
    }
  ),
};

/**
 * ----------------------
 * Fired in parent machine
 * ----------------------
 */
export const actions = {
  setPassengerVisited: assign((ctx: FlightContext, _event) =>
    setContextWithKey(ctx, `${ParentState.passengerInformation}.visited`, true)
  ),
  setPassengerContextFromChild: assign(
    (ctx: FlightContext, event: PassengerInformationCompleteEvent) => {
      const passengerContext = ctx[ParentState.passengerInformation];
      const newPassengerContext = {
        ...passengerContext,
        selectedPassengerIds: event.passengerContext.selectedPassengerIds,
        selectedLapInfantIds: event.passengerContext.selectedLapInfantIds,
        userPassengers: event.passengerContext.userPassengers,
        addPassport: event.passengerContext.addPassport,
        infantOnlyError: event.passengerContext.infantOnlyError,
      };
      return setContextWithKey(
        ctx,
        ParentState.passengerInformation,
        newPassengerContext
      );
    }
  ),
  setTripPricing: assign((ctx: FlightContext, event: FetchTripPricing) =>
    setContextWithKey(ctx, "common.tripPricing", event.tripPricing)
  ),
  setTripPricingFromData: assign(
    (ctx: FlightContext, event: DoneInvokeEvent<TripPricingSummary>) =>
      setContextWithKey(ctx, "common.tripPricing", event.data)
  ),
  setTripPricingError: assign(
    (ctx: FlightContext, event: DoneInvokeEvent<Failure>) =>
      setContextWithKey(ctx, "common.tripPricingError", event.data)
  ),

  openFormAndSetCurrentPassenger: send(
    (_ctx, event: OpenPassengerFormAndSetCurrentPassenger) => ({
      type: FlightPassengerEventTypes.OPEN_FORM_AND_SET_PASSENGER,
      passenger: event.passenger,
    }),
    {
      to: ParentState.passengerInformation,
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject({
  ...actions,
  ...childActions,
});

import { FulfillResponse, FulfillResponseEnum } from "@b2bportal/purchase-api";
import {
  CartFulfillError,
  CartFulfillErrorModal,
  CartFulfillState,
} from "@checkout/types";
import { pollFulfill } from "@hopper-b2b/api";
import {
  PollingResult,
  PollingType,
  endPollingPerformance,
  startPollingPerformance,
} from "@hopper-b2b/utilities";
import { CartContext } from "../../context";
import { getFulfillCipherText } from "../selectors";

export const pollFulfillService = async (
  context: CartContext,
  _event: unknown
): Promise<FulfillResponse | CartFulfillError> => {
  const fulFillCipherText = getFulfillCipherText({ context });
  const cartFulfillError: Omit<CartFulfillError, "type"> = {
    failedAt: CartFulfillState.polling,
  };

  try {
    if (!fulFillCipherText) {
      throw {
        ...cartFulfillError,
        type: CartFulfillErrorModal.NoCipherText,
      } as CartFulfillError;
    }

    const delayTimes = [1000];
    let pollComplete = false;
    let index = 0;
    const startPerformance = startPollingPerformance();

    while (!pollComplete) {
      const currentDelayTime = delayTimes[index];
      if (index !== delayTimes.length - 1) {
        index++;
      }

      await new Promise((resolve) => setTimeout(resolve, currentDelayTime));

      const response: FulfillResponse = await pollFulfill(fulFillCipherText);

      switch (response.FulfillResponse) {
        case FulfillResponseEnum.Failure: {
          pollComplete = true;
          endPollingPerformance(
            startPerformance,
            PollingType.FULFILL,
            PollingResult.FAILURE
          );

          if (response.errors.length > 0) {
            throw {
              ...cartFulfillError,
              type: CartFulfillErrorModal.FailedPolling,
              data: response.errors,
            } as CartFulfillError;
          } else {
            throw {
              ...cartFulfillError,
              type: CartFulfillErrorModal.FailedPollingNoErrorCode,
            } as CartFulfillError;
          }
        }
        case FulfillResponseEnum.Pending:
          continue;
        case FulfillResponseEnum.Success: {
          endPollingPerformance(
            startPerformance,
            PollingType.FULFILL,
            PollingResult.SUCCESS
          );

          pollComplete = true;
          return response;
        }
        case FulfillResponseEnum.PendingInteractivePaymentAuth: {
          if (context.cartFulfill.completeInteractive.results.length > 0) {
            continue;
          }
          endPollingPerformance(
            startPerformance,
            PollingType.FULFILL,
            PollingResult.CHALLENGE
          );

          pollComplete = true;
          return response;
        }
      }
    }
  } catch (e) {
    if (isCartFulfillError(e)) throw e;

    throw {
      ...cartFulfillError,
      type: CartFulfillErrorModal.Unknown,
      data: e,
    };
  }
};
const isCartFulfillError = (
  error: CartFulfillError | unknown
): error is CartFulfillError => (error as CartFulfillError).type !== undefined;

import { SchedulePriceQuoteRequest } from "@b2bportal/air-booking-api";
import { Product } from "@b2bportal/purchase-api";
import {
  getHasPriceChanged,
  getObjectKeysAsObject,
  setContextWithKey,
} from "@checkout/helpers";
import { PriceChangeType, cartHelpers } from "@checkout/states/Cart";
import { ContactSelectors } from "@checkout/states/common";
import { ParentState } from "@checkout/types";
import { getEnvVariables } from "@hopper-b2b/utilities";
import { assign } from "xstate";
import {
  getFlightMultiTicketType,
  getProductType,
  getQuoteBreakdownFlightPricing,
  getSelectedTripParent,
} from "./selectors";
import { FlightPassengerSelectors } from "./states";
import { FlightContext } from "./types";

export const actions = {
  addFlightProduct: assign((context: FlightContext, _event: unknown) => {
    const selectedTrip = getSelectedTripParent({ context });

    const userPassengers = FlightPassengerSelectors.getUserPassengersParent({
      context,
    });
    const selectedUserPassengers =
      FlightPassengerSelectors.getSelectedPassengerIdsParent({ context });
    const selectedLapInfantIds =
      FlightPassengerSelectors.getSelectedLapInfantIdsParent({ context });
    const contactInformation = ContactSelectors.getContactInformation({
      context,
    });
    const formattedPhoneNumber = ContactSelectors.getFormattedPhoneNumber({
      context,
    });

    const tripId = selectedTrip.tripId ?? "";
    const fareId =
      selectedTrip.returnFareId || selectedTrip.outgoingFareId || "";

    const params: SchedulePriceQuoteRequest = {
      tripId,
      fareId,
      seatedPassengers: userPassengers
        .filter((p) => selectedUserPassengers.includes(p.id))
        .map((u) => u.id),
      lapInfants: userPassengers
        .filter((p) => selectedLapInfantIds.includes(p.id))
        .map((u) => u.id),
      emailAddress: contactInformation?.email || "",
      phoneNumber: formattedPhoneNumber,
      ancillaryIds: [],
      rewardPrograms: [],
    };

    const flightProduct = {
      type: Product.Flight,
      value: params,
    };
    const ctxWithFlight = cartHelpers.addQuoteProduct(flightProduct, context);

    return setContextWithKey(
      ctxWithFlight,
      `${ParentState.cartQuote}.customerEmail`,
      contactInformation?.email
    );
  }),
  addMultiProviderProduct: assign((context: FlightContext, _event: unknown) => {
    const HFv2 = context?.featureFlags?.enableHFv2;
    const selectedTrip = getSelectedTripParent({ context });
    const multiTicketType = getFlightMultiTicketType({ context });
    const userPassengers = FlightPassengerSelectors.getUserPassengersParent({
      context,
    });
    const selectedUserPassengers =
      FlightPassengerSelectors.getSelectedPassengerIdsParent({ context });
    const selectedLapInfantIds =
      FlightPassengerSelectors.getSelectedLapInfantIdsParent({ context });
    const contactInformation = ContactSelectors.getContactInformation({
      context,
    });
    const formattedPhoneNumber = ContactSelectors.getFormattedPhoneNumber({
      context,
    });

    const tripId = selectedTrip.tripId ?? "";
    const fareId =
      selectedTrip.returnFareId || selectedTrip.outgoingFareId || "";

    const params: SchedulePriceQuoteRequest = {
      tripId,
      fareId,
      seatedPassengers: userPassengers
        .filter((p) => selectedUserPassengers.includes(p.id))
        .map((u) => u.id),
      lapInfants: userPassengers
        .filter((p) => selectedLapInfantIds.includes(p.id))
        .map((u) => u.id),
      emailAddress: contactInformation?.email || "",
      phoneNumber: formattedPhoneNumber,
      ancillaryIds: [],
      rewardPrograms: [],
    };

    const flightProduct = {
      type: getProductType(multiTicketType, HFv2),
      value: params,
    };

    const ctxWithFlight = cartHelpers.addQuoteProduct(flightProduct, context);

    return setContextWithKey(
      ctxWithFlight,
      `${ParentState.cartQuote}.customerEmail`,
      contactInformation?.email
    );
  }),
  checkForPriceChange: assign((context: FlightContext, _event) => {
    const shopPricing = context.common.tripPricing;
    const priceQuote = getQuoteBreakdownFlightPricing({ context });

    const predictedTotal = shopPricing
      ? shopPricing.totalPricing.total.fiat.value
      : 0;
    const priceQuoteTotal = priceQuote
      ? priceQuote.totalPricing.total.fiat.value
      : 0;

    const difference = Math.abs(priceQuoteTotal - predictedTotal);

    // Tenant defines allowed price change difference in respective .env file
    const tenantDifferenceAllowed = getEnvVariables(
      "flightPriceChangeDiff"
    ) as string;
    const allowed = tenantDifferenceAllowed.split(",");
    const allowedValue = allowed?.[0] ? parseInt(allowed[0], 10) : Infinity;
    const allowedCurrency = allowed?.[1] ? allowed[1] : "USD";
    const allowedDifference = {
      value: allowedValue,
      currencyCode: allowedCurrency,
    };

    const actualDifference = {
      value: difference,
      currencyCode: priceQuote?.totalPricing?.total?.fiat.currencyCode
        ? priceQuote.totalPricing.total.fiat.currencyCode
        : "USD",
    };
    const hasDifference = getHasPriceChanged(
      allowedDifference,
      actualDifference
    );

    const differenceString = difference.toFixed();
    let priceChange: PriceChangeType = {
      hasDifference: false,
      predictedTotal,
      priceQuoteTotal,
    };
    if (hasDifference) {
      priceChange = {
        ...priceChange,
        hasDifference,
        isIncrease: priceQuoteTotal > predictedTotal,
        difference,
        differenceString,
      };
    }
    return setContextWithKey(
      context,
      `${ParentState.cartQuote}.priceChange`,
      priceChange
    );
  }),
};

export const ActionTypes = getObjectKeysAsObject(actions);

import { AirRestrictionStatus } from "../constants";

export enum MaybeHasFee {
  HAS_FEE = "HasFee",
  NO_FEE = "NoFee",
}

export enum FareWarningLevel {
  NORMAL = "Normal",
  WARN = "Warn",
}

export enum LocalizedBaggageInfo {
  UNKNOWN = "Unknown",
  NOT_ALLOWED = "NotAllowed",
  ALLOWED = "Allowed",
}

export interface IShopPenalty {
  symbol: AirRestrictionStatus;
  fareWarningLevel: { FareWarningLevel: FareWarningLevel };
  penalty: {
    MaybeHasFee: MaybeHasFee;
    restrictionPricing?: { fee: string };
    message?: string;
  };
}

export interface IDetailBaggageInfo {
  body: string;
  url?: string;
  restrictionWarningLevel: { RestrictionWarningLevel: FareWarningLevel };
  symbol: AirRestrictionStatus;
  title: string;
}

export interface ICancelPenalty extends IShopPenalty {}
export interface IChangePenalty extends IShopPenalty {}

export interface IPenalties {
  changePenalty: IChangePenalty;
  cancelPenalty: ICancelPenalty;
}

export interface IRestrictions {
  restrictionsWithInfo: any[];
  penaltiesInfo?: IPenalties;
  seatInfo?: ISeatInfo;
  detailBaggageInfo?: IDetailBaggageInfo;
  carryOnInfo?: ICarryOnInfo;
}

export interface ISlicedRestriction extends IRestrictions {}
export interface ITripRestrictions extends IRestrictions {}

export interface ISeatInfo {
  description: string;
  symbol: AirRestrictionStatus;
  isLCC: boolean;
  isBasicEconomy: boolean;
  fareWarningLevel: { FareWarningLevel: FareWarningLevel };
}

export interface IBaggagePrice {
  restrictionPricing: { fee: string };
  MaybeHasFee: string;
  message: string;
}

export interface IBaggageInfo {
  symbol?: AirRestrictionStatus;
  firstBagPrice?: IBaggagePrice;
  secondBagPrice?: IBaggagePrice;
  LocalizedBaggageInfo: LocalizedBaggageInfo;
}

export interface ICarryOnInfo {
  description: string;
  fareWarningLevel: { FareWarningLevel: FareWarningLevel };
  symbol: AirRestrictionStatus;
}

export interface Restriction {
  name: string;
  description: string;
  symbol: AirRestrictionStatus;
}

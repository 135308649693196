import {
  PassengersResponse,
  Person,
  TripPricingSummary,
} from "@b2bportal/air-booking-api";
import { PersonId, ProductType } from "@hopper-b2b/types";

export enum PassengerEventTypes {
  DELETE_PASSENGER = "DELETE_PASSENGER",
  SET_CURRENT_PASSENGER = "SET_CURRENT_PASSENGER",
  CLEAR_PASSENGER_INFORMATION_ERROR = "CLEAR_PASSENGER_INFORMATION_ERROR",
  OPEN_PASSENGER_PICKER = "OPEN_PASSENGER_PICKER",
  OPEN_PASSENGER_FORM = "OPEN_PASSENGER_FORM",

  ADD_PASSENGER = "ADD_PASSENGER",
  COMPLETE = "COMPLETE",
  GO_IDLE = "GO_IDLE",
  NEXT = "NEXT",
  SELECT_PASSENGER = "SELECT_PASSENGER",
  UPDATE_PASSENGER = "UPDATE_PASSENGER",
  PREVIOUS = "PREVIOUS",
  OPEN_FORM_AND_SET_PASSENGER = "OPEN_FORM_AND_SET_PASSENGER",
}

export interface DeletePassengerEvent {
  type: PassengerEventTypes.DELETE_PASSENGER;
  personId: PersonId;
}

export interface SetCurrentPassengerEvent {
  type: PassengerEventTypes.SET_CURRENT_PASSENGER;
  passenger: Person;
}

export interface ClearPassengerInformationError {
  type: PassengerEventTypes.CLEAR_PASSENGER_INFORMATION_ERROR;
}

export interface OpenPassengerPickerEvent {
  type: PassengerEventTypes.OPEN_PASSENGER_PICKER;
}

export interface OpenPassengerFormEvent {
  type: PassengerEventTypes.OPEN_PASSENGER_FORM;
}

export interface SelectPassengerEvent {
  type: PassengerEventTypes.SELECT_PASSENGER;
  singleTravelerWorkflow?: boolean;
  passengerId: PersonId;
  data?: {
    id: string;
    value: Array<Person>;
    onUpdate?: boolean;
    singleTravelerWorkflow?: boolean;
  };
}

export interface AddPassengerEvent {
  type: PassengerEventTypes.ADD_PASSENGER;
}
export interface UpdatePassengerEvent {
  type: PassengerEventTypes.UPDATE_PASSENGER;
  person: Person;
  onUpdate?: boolean;
  singleTravelerWorkflow?: boolean;
}

export interface GoIdleEvent {
  type: PassengerEventTypes.GO_IDLE;
}

export interface NextEventType {
  type: PassengerEventTypes.NEXT;
  productType: ProductType;
}

export type ValidateAndFetchTripPricingEvent = [
  PassengersResponse,
  TripPricingSummary
];

export interface PreviousEventType {
  type: PassengerEventTypes.PREVIOUS;
}

export interface OpenPassengerFormAndSetCurrentPassenger {
  type: PassengerEventTypes.OPEN_FORM_AND_SET_PASSENGER;
  passenger: Person;
}

export type PassengerEvents =
  | AddPassengerEvent
  | ClearPassengerInformationError
  | DeletePassengerEvent
  | GoIdleEvent
  | NextEventType
  | OpenPassengerFormEvent
  | OpenPassengerPickerEvent
  | SelectPassengerEvent
  | SetCurrentPassengerEvent
  | UpdatePassengerEvent
  | PreviousEventType
  | OpenPassengerFormAndSetCurrentPassenger;

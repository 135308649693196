import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState, PartialParentContext } from "@checkout/types";

export const guards = {
  hasChfarId: (ctx: PartialParentContext, _event: unknown) => {
    return !!ctx[ParentState.changeForAnyReasonDiscount]?.chfarId;
  },
};

export const GuardTypes = getObjectKeysAsObject(guards);

import { FlightsFareSlice } from "@b2bportal/air-shopping-api";
import {
  AirRestrictionStatus,
  FareDetails,
  FareId,
  TripDetails,
} from "@hopper-b2b/types";
import {
  getFilteredFareDetails,
  getSortedFareDetails,
} from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import { TFunction } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { FareCardDetails } from "./components";

/**
 * Fare Details Card
 * @function FareDetailsCard
 *
 */

export interface IRestrictionProps {
  symbol: AirRestrictionStatus;
  description: string;
  key?: string;
}

export interface IFareNotice {
  id: string;
  message: string;
  tooltipCopy: string;
}

export interface IFareDetailsCardProps {
  className?: string;
  onClick?: (fareDetails: FareDetails) => void;
  onAlgomerchClick?: (label: string) => void;
  fareDetails: FareDetails[];
  selectedFare?: FareId;
  isOutgoing: boolean;
  isMobile?: boolean;
  fareNotice?: IFareNotice[];
  getEmptyRestrictionsText: (
    fareRating: number | undefined,
    translate: TFunction
  ) => string;
  rewardsKey?: string;
  selectedFareClassFilters: Array<number>;
  selectedOutgoingFareSlice?: FlightsFareSlice;
  getFlightShopFareSlice: (fareId: string) => FlightsFareSlice;
  tripDetails?: TripDetails;
}

export const FareDetailsCard = ({
  className,
  onClick,
  selectedFare,
  fareDetails, // the length of this is the amt of fares we have
  isOutgoing,
  onAlgomerchClick,
  isMobile,
  fareNotice,
  selectedFareClassFilters,
  selectedOutgoingFareSlice,
  getEmptyRestrictionsText = () => "",
  rewardsKey = "fake-account-reference-ID-1",
  getFlightShopFareSlice,
  tripDetails,
}: IFareDetailsCardProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [filteredFares, setFilterFares] = useState<FareDetails[]>(fareDetails);

  const getSelectedFareId = useCallback(() => {
    const fareExists = filteredFares?.some((f) => f.id === selectedFare);
    const firstFareId = filteredFares[0]?.id;
    if (!firstFareId) return undefined;
    if (isOutgoing) {
      if (fareExists) {
        return selectedFare;
      } else {
        return firstFareId;
      }
    } else {
      // check if outgoing is refundable, try to find matching return fare
      if (selectedOutgoingFareSlice?.fareBrandName?.includes("Refundable")) {
        const matchedFare = filteredFares.find(
          (fare) =>
            fare.slices[1]?.fareShelf?.brandName ===
            selectedOutgoingFareSlice?.fareBrandName
        );
        return matchedFare ? matchedFare.id : firstFareId;
      } else {
        return fareExists ? selectedFare : firstFareId;
      }
    }
  }, [
    filteredFares,
    isOutgoing,
    selectedFare,
    selectedOutgoingFareSlice?.fareBrandName,
  ]);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (selectedFare && fareDetails) {
      const onlyFilteredFares = getFilteredFareDetails({
        fareDetails,
        selectedFareClassFilters,
        isOutgoing,
        // Don't filter fares based on fare clicked for mobile, only desktop
        selectedFareId: isMobile ? undefined : getSelectedFareId(),
      });
      const filteredAndSortedFares = getSortedFareDetails(onlyFilteredFares);
      setFilterFares(filteredAndSortedFares);
    }
    // ! Don't add getSelectedFareId to dependency list below
    // ! as this useEffect sets filteredFares and getSelectedFareId depends on it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fareDetails,
    isOutgoing,
    selectedFare,
    selectedFareClassFilters,
    isMobile,
  ]);
  return (
    <Box p={3} className={className} onClick={handlePopoverClose}>
      {filteredFares?.map((fare, index) => (
        <FareCardDetails
          key={fare.id}
          fare={fare}
          index={index}
          anchorEl={anchorEl}
          isLast={index === filteredFares.length - 1}
          getSelectedFareId={getSelectedFareId}
          isMobile={isMobile}
          isOutgoing={isOutgoing}
          fareDetails={fareDetails}
          fareNotice={fareNotice}
          onClick={onClick}
          onAlgomerchClick={onAlgomerchClick}
          getEmptyRestrictionsText={getEmptyRestrictionsText}
          getFlightShopFareSlice={getFlightShopFareSlice}
          tripDetails={tripDetails}
        />
      ))}
    </Box>
  );
};

export default FareDetailsCard;

import CheckedBagImg from "./client/checked-bag.png";
import CarryOn2BagsImg from "./client/carry-on-2bags.png";
import BagsCombination from "./client/bags-combination.png";
import ErrorState from "./client/error-state.svg";
import MoneyImage from "./client/money.svg";

export const images = {
  checkedBag: CheckedBagImg,
  carryOn2Bags: CarryOn2BagsImg,
  bagsCombination: BagsCombination,
  errorState: ErrorState,
  priceDifferenceImage: MoneyImage,
};

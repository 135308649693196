import { TrackingProperties } from "@b2bportal/air-shopping-api";
import {
  CipherText,
  PaymentOpaqueValue,
  ProductOpaqueValue,
  type CompleteInteractiveAuthRequest,
  type FulfillPendingInteractivePaymentAuth,
} from "@b2bportal/purchase-api";
import { CallState } from "@hopper-b2b/types";
import { type CartFulfillError } from "../../../types";

// TODO - types should come from FulfillSuccess
export type CartFulfillSuccessContext = {
  fulfillCipherText?: CipherText;
  fulfilledPayments?: Array<PaymentOpaqueValue>;
  fulfilledProducts?: Array<ProductOpaqueValue>;
  fulfillRequestPayments?: Array<PaymentOpaqueValue>;

  trackingProperties: TrackingProperties;
};

export type CartFulfillPendingInteractiveContext = {
  pendingInteractive: Omit<
    Omit<FulfillPendingInteractivePaymentAuth, "token">,
    "FulfillResponse"
  >;
};

export type CartFulfillCompleteInteractiveContext = {
  completeInteractive: Omit<CompleteInteractiveAuthRequest, "token">;
};

export type CartFulfillErrorContext = {
  error?: CartFulfillError;
};

export type CartFulfillContext = CartFulfillSuccessContext &
  CartFulfillErrorContext &
  CartFulfillPendingInteractiveContext &
  CartFulfillCompleteInteractiveContext & {
    callState?: CallState;
  };

export const CartFulfillInitialContext: CartFulfillContext = {
  fulfillCipherText: undefined,
  fulfilledProducts: [],
  fulfilledPayments: [],
  fulfillRequestPayments: [],
  pendingInteractive: {
    actions: [],
    paymentMetadata: undefined,
  },
  completeInteractive: {
    results: [],
  },
  error: undefined,
  callState: CallState.NotCalled,
  trackingProperties: {},
};

import dayjs from "dayjs";
import { getDateTimeWithFormat } from "@hopper-b2b/utilities";
import { PassengersForFareRequest, Person } from "@b2bportal/air-booking-api";

import { getChildState, getParentState } from "@checkout/helpers";
import {
  ParentState,
  PassengerInformationChildState,
  PassengerInformationState,
} from "@checkout/types";
import { ChildPassengerInformationContext } from "./context";
import { State } from "xstate";
import { FlightContext } from "../../types";

type ChildPassengerStateType = State<ChildPassengerInformationContext>;
type ChildPassengerStateWithoutValue = Pick<ChildPassengerStateType, "context">;

type PassengerStateType = State<FlightContext>;
type PassengerStateWithoutValue = Pick<PassengerStateType, "context">;

/**
 * Child machine selectors
 * ------------------------
 */

export const getSelectedTripInPassenger = (
  state: ChildPassengerStateWithoutValue
) => state.context.selectedTrip;

export const getIsPassengerInformationLoading = (
  state: ChildPassengerStateType
) => {
  const parentState = getParentState(state.value) as PassengerInformationState;
  const childState = getChildState(
    state.value
  ) as PassengerInformationChildState;

  return (
    parentState === PassengerInformationState.loading ||
    [
      PassengerInformationChildState.add,
      PassengerInformationChildState.delete,
      PassengerInformationChildState.update,
    ].includes(childState)
  );
};

export const getUserPassengers = (state: ChildPassengerStateWithoutValue) =>
  state.context.userPassengers;

export const getCurrentInfantId = (state: ChildPassengerStateWithoutValue) =>
  state.context.currentInfantId;

export const getSelectedPassengerIds = (
  state: ChildPassengerStateWithoutValue
) => state.context.selectedPassengerIds;

export const getSelectedLapInfantIds = (
  state: ChildPassengerStateWithoutValue
) => state.context.selectedLapInfantIds;

export const getAllSelectedPassengerIds = (
  state: ChildPassengerStateWithoutValue
) => {
  const selectedPassengerIds = getSelectedPassengerIds(state);
  const selectedLapInfantIds = getSelectedLapInfantIds(state);
  return [...selectedPassengerIds, ...selectedLapInfantIds];
};

export const getAllSelectedUserPassengers = (
  state: ChildPassengerStateWithoutValue
) => {
  const selectedPassengerIds = getSelectedPassengerIds(state);
  const selectedLapInfantIds = getSelectedLapInfantIds(state);
  const userPassengers = getUserPassengers(state);

  return userPassengers.filter((passenger) =>
    [...selectedPassengerIds, ...selectedLapInfantIds].includes(passenger.id)
  );
};

export const getSearchPassengerNumber = (
  state: ChildPassengerStateWithoutValue
) => {
  let passengerCount = 0;
  state.context.tripDetails.fareDetails[0].paxPricings.forEach(
    (pricing) => (passengerCount += pricing.quantity)
  );
  return passengerCount;
};

export const getNumPassengerAlertDismissed = (
  state: ChildPassengerStateWithoutValue
) => state.context.numPassengerAlertDismissed;

export const getCurrentUserPassenger = (
  state: ChildPassengerStateWithoutValue
) => state.context.currentUserPassenger;

export const getOpenPassengerFormModal = (state: ChildPassengerStateType) =>
  PassengerInformationState.passengerForm ===
  (getParentState(state.value) as PassengerInformationState);

export const getOpenPassengerPicker = (state: ChildPassengerStateType) =>
  (getParentState(state.value) as PassengerInformationState) ===
  PassengerInformationState.passengerPicker;

export const getIsLapInfant = (traveler: Person) => {
  const age = dayjs().diff(
    getDateTimeWithFormat(traveler.dateOfBirth, "YYYY-MM-DD"),
    "year"
  );
  return age < 2;
};

export const getPassengerErrorOpen = (state: ChildPassengerStateType) => {
  const stateValue = getParentState(state.value);
  const infantOnlyError = getInfantOnlyError(state);
  const isErrorState = PassengerInformationState.error === stateValue;

  return infantOnlyError || isErrorState;
};

export const getPassengerError = (state: ChildPassengerStateWithoutValue) =>
  state.context.error;

export const getInfantOnlyError = (state: ChildPassengerStateWithoutValue) =>
  state.context.infantOnlyError;

export const getSelectedTrip = (state: ChildPassengerStateWithoutValue) =>
  state.context.selectedTrip;

export const getTripPriceParams = (
  state: ChildPassengerStateWithoutValue
): PassengersForFareRequest => {
  const selectedTrip = getSelectedTrip(state);
  const selectedPassengerIds = getSelectedPassengerIds(state);
  const selectedLapInfants = getSelectedLapInfantIds(state);

  const fareId = selectedTrip.returnFareId
    ? selectedTrip.returnFareId
    : selectedTrip.outgoingFareId || "";
  const defaultPricingParams = {
    tripId: selectedTrip.tripId || "",
    seatedPassengers: selectedPassengerIds,
    lapInfants: selectedLapInfants,
    fareId: fareId,
    // TODO: Add ancilliary ids here
    ancillaryIds: [],
  };

  return defaultPricingParams;
};

/**
 * Parent machine selectors
 * ------------------------
 */
export const getPassengerVisited = (state: PassengerStateWithoutValue) =>
  state.context[ParentState.passengerInformation].visited;

export const getIsInfantSeatModalLoading = (
  state: PassengerStateWithoutValue
) => state.context.common.tripPricing === undefined;

export const getUserPassengersParent = (state: PassengerStateWithoutValue) =>
  state.context[ParentState.passengerInformation].userPassengers;

export const getSelectedPassengerIdsParent = (
  state: PassengerStateWithoutValue
) => state.context[ParentState.passengerInformation].selectedPassengerIds;

export const getSelectedLapInfantIdsParent = (
  state: PassengerStateWithoutValue
) => state.context[ParentState.passengerInformation].selectedLapInfantIds;

export const getAllSelectedPassengerIdsParent = (
  state: PassengerStateWithoutValue
) => {
  const selectedPassengerIds = getSelectedPassengerIdsParent(state);
  const selectedLapInfantIds = getSelectedLapInfantIdsParent(state);
  return [...selectedPassengerIds, ...selectedLapInfantIds];
};

export const getAllSelectedUserPassengersParent = (
  state: PassengerStateWithoutValue
) => {
  const selectedPassengerIds = getAllSelectedPassengerIdsParent(state);
  const userPassengers = getUserPassengersParent(state);

  return userPassengers.filter((passenger) =>
    selectedPassengerIds.includes(passenger.id)
  );
};

export const getSeatedPassengersParent = (
  state: PassengerStateWithoutValue
) => {
  const selectedPassengerIds = getSelectedPassengerIdsParent(state);
  const userPassengers = getUserPassengersParent(state);

  return userPassengers.filter((passenger) =>
    selectedPassengerIds.includes(passenger.id)
  );
};

import { AirPriceFreezeStateWithoutValue } from "../types";

export const getPriceFreezeOfferData = (
  state: AirPriceFreezeStateWithoutValue
) => {
  return state.context.priceFreezeOffer.priceFreezeOfferData?.offerData;
};

export const getPriceFreezeOfferDataTrackingProperties = (
  state: AirPriceFreezeStateWithoutValue
) => {
  return state.context.priceFreezeOffer.priceFreezeOfferData
    ?.trackingProperties;
};

export const getPriceFreezeOfferCap = (
  state: AirPriceFreezeStateWithoutValue
) => {
  return state.context.priceFreezeOffer.priceFreezeOfferData?.offerData[0]?.cap;
};

export const getSelectedOfferData = (
  state: AirPriceFreezeStateWithoutValue
) => {
  const slectedOfferDataIndex = getSelectedPriceFreezeDurationIndex(state);
  return state.context.priceFreezeOffer.priceFreezeOfferData?.offerData[
    slectedOfferDataIndex
  ];
};

export const getSelectedPriceFreezeDurationIndex = (
  state: AirPriceFreezeStateWithoutValue
) => state.context.priceFreezeOffer.priceFreezeOfferDataIndex;

export const getDefaultPriceFreezeOffer = (
  state: AirPriceFreezeStateWithoutValue
) => state.context.defaultPriceFreezeOffer;

export const getPriceFreezeOffer = (state: AirPriceFreezeStateWithoutValue) =>
  state.context.priceFreezeOffer.priceFreezeOffer;

export const getPriceFreezeOfferId = (state: AirPriceFreezeStateWithoutValue) =>
  state.context.priceFreezeOffer.priceFreezeOffer?.id;

export const getPriceFreezeOfferPricePerPax = (
  state: AirPriceFreezeStateWithoutValue
) => {
  const offerData = getSelectedOfferData(state);
  return offerData?.price;
};

export const getPriceFreezeOfferTotalPrice = (
  state: AirPriceFreezeStateWithoutValue
) => {
  const offer = getPriceFreezeOffer(state);
  return offer?.totalAmount;
};

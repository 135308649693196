import { ActionButton } from "@hopper-b2b/ui-core";
import clsx from "clsx";
import { FloatingBox } from "../FloatingBox";
import "./styles.scss";

export interface IMobileFloatingButtonProps {
  children: string | JSX.Element;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  wrapperClassName?: string;
  wide?: boolean;
  bold?: boolean;
  floatingBoxClassName?: string;
  topContent?: JSX.Element;
  disableElevation?: boolean;
  buttonProps?: object;
}

export const MobileFloatingButton = (props: IMobileFloatingButtonProps) => {
  const {
    className,
    wrapperClassName,
    disabled = false,
    wide = false,
    bold = false,
    children,
    onClick,
    floatingBoxClassName,
    topContent,
    disableElevation = true,
    buttonProps,
  } = props;

  return (
    <FloatingBox className={floatingBoxClassName}>
      {topContent ? topContent : null}
      <ActionButton
        className={clsx("mobile-floating-button-wrapper", wrapperClassName)}
        buttonClassName={clsx("mobile-floating-button", className, {
          "mobile-floating-button-wide": wide,
          "mobile-floating-button-bold": bold,
        })}
        disabled={disabled}
        message={children}
        onClick={onClick}
        disableElevation={disableElevation}
        {...buttonProps}
      />
    </FloatingBox>
  );
};

import { listPaymentMethodsService as listPaymentMethods } from "./listPaymentMethods";
import { verifyPaymentService as verifyPayment } from "./verifyPayment";
import { deletePaymentMethodService as deletePaymentMethod } from "./deletePaymentMethod";
import { getObjectKeysAsObject } from "../../../../helpers";

export const services = {
  listPaymentMethods,
  verifyPayment,
  deletePaymentMethod,
};

export const ServiceTypes = getObjectKeysAsObject(services);

import { assign } from "xstate";
import { Product, ProductOpaqueValue } from "@b2bportal/purchase-api";
import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { getCfarCartUpdateRequest } from "./selectors";
import {
  AirCfarProductV2,
  InitCfarStateEventV2,
  PartialParentContextWithAirCfarV2,
} from "./types";
import { SelectedStatus } from "@overrides/types";

export const actions = {
  addCfarProduct: assign(
    (context: PartialParentContextWithAirCfarV2, _event: unknown) => {
      if (
        context[ParentState.cancelForAnyReasonV2]?.selectedOffer.status ===
        SelectedStatus.SELECTED
      ) {
        const filterOutCfarFromProducts = (
          context[ParentState.cartUpdate].addProducts ?? []
        ).filter((p: ProductOpaqueValue) => p.type !== Product.AirCfar);

        const request = getCfarCartUpdateRequest({ context });
        const newCfarProduct: AirCfarProductV2 = {
          type: Product.AirCfar,
          value: request,
        };

        context[ParentState.cartUpdate].addProducts = [
          ...filterOutCfarFromProducts,
          newCfarProduct,
        ];
      }
      return context;
    }
  ),
  setCfarState: assign(
    (
      context: PartialParentContextWithAirCfarV2,
      event: InitCfarStateEventV2
    ) => {
      context[ParentState.cancelForAnyReasonV2].selectedOffer =
        event.state.selectedOffer;
      context[ParentState.cancelForAnyReasonV2].cfarQuoteId =
        event.state.cfarQuoteId;
      context[ParentState.cancelForAnyReasonV2].cfarOffers =
        event.state.cfarOffers;
      context[
        ParentState.cancelForAnyReasonV2
      ].aggregatedShopTrackingProperties =
        event.state.aggregatedShopTrackingProperties;
      return context;
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject({
  ...actions,
});

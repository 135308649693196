import {
  AxiosInterceptors,
  createRequestLog,
  createResponseErrorLog,
  createResponseLog,
} from "@hopper-b2b/api";
import { useExperiments } from "@hopper-b2b/experiments";
import { useAuthProvider } from "@hopper-b2b/hopper-auth";
import { useI18nContext } from "@hopper-b2b/i18n";

import config from "../../utils/config";
import { getCurrency } from "@virtual-interline/utils";
import { SESSION_ID_KEY } from "../../utils/constants";
import { useUtmTracking } from "@hopper-b2b/utilities";

interface IAxiosInterceptors {
  children?: React.ReactNode;
  hopperSessionToken?: string;
}

const TENANT = "volaris";

const AxiosInterceptorWrapper = ({
  children,
  hopperSessionToken,
}: IAxiosInterceptors) => {
  const { locale } = useI18nContext();
  const userInfoContext = useAuthProvider();
  const { trackingProperties } = useExperiments();
  const { userCampaign, userContent, userMedium, userSource } =
    useUtmTracking();
  const currency = getCurrency();

  const sessionId = sessionStorage.getItem(SESSION_ID_KEY);

  const headers = {
    "X-Hopper-Currency": currency,
  };

  if (hopperSessionToken) headers["Hopper-Session"] = hopperSessionToken;
  if (sessionId) headers["Riskified-Session-Id"] = sessionId;

  return (
    <AxiosInterceptors
      trackingProperties={trackingProperties}
      userSource={userSource}
      userMedium={userMedium}
      userCampaign={userCampaign}
      userContent={userContent}
      tenant={TENANT}
      locale={locale}
      currency={currency}
      isAgentPortal={!!userInfoContext?.state?.sessionInfo?.delegatedTo}
      isSignedIn={false}
      // volaris users are always guests
      isGuestUser
      delegatedTo={userInfoContext?.state?.sessionInfo?.delegatedTo}
      requestHeaders={headers}
      version={config.REACT_APP_VERSION}
      logResponse={createResponseLog}
      logRequest={createRequestLog}
      logError={createResponseErrorLog}
      retryTransientErrors
    >
      {children}
    </AxiosInterceptors>
  );
};

export default AxiosInterceptorWrapper;

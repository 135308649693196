import { getObjectKeysAsObject } from "../../../helpers";
import { PartialParentWithPassengerContext } from "./context";
import { ParentState } from "../../../index";
import { SelectPassengerEvent } from "./events";

export const guards = {
  haveAnyPassengersLoaded: (ctx: PartialParentWithPassengerContext) => {
    const userPassengers = ctx[ParentState.passengerInformation].userPassengers;
    if (userPassengers?.length && userPassengers.length > 0) {
      return true;
    }
    return false;
  },
  hasAnySelectedPassengers: (ctx: PartialParentWithPassengerContext) => {
    const selectedPassengerIds =
      ctx[ParentState.passengerInformation].selectedPassengerIds;
    return selectedPassengerIds.length > 0;
  },
  willHaveSelectedPassengers: (
    ctx: PartialParentWithPassengerContext,
    event: SelectPassengerEvent
  ) => {
    const selectedPassengerIds =
      ctx[ParentState.passengerInformation].selectedPassengerIds;
    return !(
      selectedPassengerIds.length === 1 &&
      selectedPassengerIds[0] === event.passengerId
    );
  },
  hasCurrentUserPassenger: (ctx: PartialParentWithPassengerContext) => {
    const currentUserPassenger =
      ctx[ParentState.passengerInformation].currentUserPassenger?.id;
    return !!currentUserPassenger;
  },
  notVisitedPassenger: (ctx: PartialParentWithPassengerContext) =>
    !ctx[ParentState.passengerInformation].visited,
};

export const GuardTypes = getObjectKeysAsObject(guards);

import { Typography } from "@material-ui/core";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { PATH_TERMS_MCP } from "../../utils/constants";

export const SpanishTermsAndConditions = () => (
  <>
    <Typography
      variant="h3"
      className={clsx(styles.bold, styles.underline, styles.mb, styles.center)}
    >
      Términos de servicio
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Hopper Inc. y sus afiliadas (“<span className={styles.bold}>Hopper</span>
      ”) proporcionan los Servicios Hopper Technology Solutions Connect ( “
      <span className={styles.bold}>los servicios de HTS Connect</span>”) en
      nombre de los usuarios de Concesionaria Vuela Compañía de Aviación
      S.A.P.I. de C.V. D/B/A Volaris ( “
      <span className={styles.bold}>Volaris</span>”) para su uso (el “
      <span className={styles.bold}>Usuario</span>”). Los servicios de HTS
      Connect incluyen, entre otros, la utilización de datos de Hopper y
      algoritmos predictivos patentados para buscar y recibir notificaciones
      sobre vuelos y otros servicios de viaje; reservar y pagar dichos servicios
      de viaje; comprar productos de Hopper y/o de terceros; utilizar informes
      publicados por Hopper ( “
      <span className={styles.bold}>Datos de investigación de Hopper</span>”); y
      otros servicios relacionados. Hopper le proporciona acceso a los servicios
      de HTS Connect, sujeto a la aceptación de su parte de los siguientes
      Términos y Condiciones ( “<span className={styles.bold}>Terms</span>”),
      que constituyen un acuerdo legal vinculante entre usted y Hopper. Al
      acceder, descargar, copiar y/o utilizar los servicios de HTS Connect,
      acepta estar sujeto a estos Términos.
    </Typography>
    <Typography className={styles.mb}>
      REVISE CUIDADOSAMENTE LA “
      <span className={styles.bold}>RESOLUCIÓN DE DISPUTAS</span>” DE ESTOS
      TÉRMINOS (SECCIÓN 13), YA QUE REQUIERE QUE USTED RESUELVA CUALQUIER
      DISPUTA FUTURA CON HOPPER A TRAVÉS DE UN ARBITRAJE INDIVIDUAL VINCULANTE.
      USTED RECONOCE Y ACEPTA QUE RENUNCIA AL DERECHO A UN JUICIO POR JURADO Y A
      SU DERECHO A PARTICIPAR COMO DEMANDANTE O MIEMBRO DE UN GRUPO DEMANDANTE
      EN CUALQUIER DEMANDA COLECTIVA O EN UN PROCEDIMIENTO REPRESENTATIVO CONTRA
      HOPPER.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      También debería leer el{" "}
      <a
        href="https://hopper.com/legal/privacy-policy"
        target="_blank"
        rel="noreferrer"
      >
        AVISO DE PRIVACIDAD
      </a>{" "}
      de Hopper, el cual explica cómo Hopper maneja y utiliza los datos que
      recopila cuando usted utiliza los servicios de HTS Connect, y que se
      incorporan como referencia en estos Términos. Al aceptar estos Términos,
      también acepta la recopilación y el uso de los datos según se establece en
      el Aviso de privacidad de Hopper.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Si necesita contactarse con Atención al cliente de Hopper, primero debe
      contactarse con Atención al cliente de Volaris, quien le redirigirá a
      Hopper. Puede ponerse en contacto con el servicio de atención al cliente
      de Volaris a través de la información facilitada en el correo electrónico
      de confirmación de la reserva.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Además de estos Términos, Hopper ofrece productos y servicios individuales
      para la compra, que podrán estar sujetos a términos y condiciones
      adicionales, y se proporcionarán tanto antes como después de la compra.
      Los servicios de viaje proporcionados por terceros están sujetos a sus
      propios términos. Revise cuidadosamente todos los términos adicionales
      antes de cualquier compra o reservación.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      La Solución HTS Connect está protegida por reCAPTCHA Enterprise, un
      servicio de terceros proporcionado por Google. La{" "}
      <a
        href="https://policies.google.com/privacy"
        target="_blank"
        rel="noreferrer"
      >
        Política de privacidad
      </a>{" "}
      y los{" "}
      <a
        href="https://policies.google.com/privacy"
        target="_blank"
        rel="noreferrer"
      >
        Términos de servicio
      </a>{" "}
      de Google se aplican a reCAPTCHA Enterprise. Al utilizar el sitio web de
      Hopper, usted acepta el uso de este servicio y los términos de Google.
    </Typography>
    <Typography
      variant="body2"
      className={clsx(styles.bold, styles.mb, styles.center)}
    >
      1. Uso de las Apps y Servicios; restricciones
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      1.1. <span className={styles.underline}>Uso aceptable</span>. Puede
      utilizar los servicios de HTS Connect solo para fines personales legítimos
      y, de acuerdo con estos Términos, para buscar y reservar viajes y/o hacer
      compras relacionadas para usted o en nombre de una persona que le haya
      autorizado sin fines comerciales. Hopper podrá, a su sola discreción,
      rescindir su derecho a utilizar los servicios de HTS Connect en cualquier
      momento y podrá hacer uso de los recursos legales disponibles por
      violaciones reales o sospechadas de estos Términos, incluida la
      cancelación de reservaciones hechas en violación a estos Términos.
      Cualquier demora por parte de Hopper en tomar tales medidas no constituye
      una renuncia a sus derechos para hacer cumplir estos Términos. Al poner
      los servicios de HTS Connect a disposición para su uso, Hopper no da su
      consentimiento para actuar como su agente o fiduciario.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      1.2. <span className={styles.underline}>Restricciones de uso</span>. Los
      servicios de HTS Connect no están destinados a utilizarse por ninguna
      persona que sea, y a usted no se le permite utilizar los servicios de HTS
      Connect si es menor de 16 años o si no tiene la mayoría de edad en la
      jurisdicción en la que reside. Además de la información requerida para
      completar una reservación, Hopper no recopila deliberadamente información
      de identificación personal de menores de 16 años. Si un menor de 16 años
      nos proporciona información personal sin el consentimiento de los padres o
      tutores, el padre o tutor puede contactarnos por correo electrónico a{" "}
      <a href="mailto:privacy@hopper.com">privacy@hopper.com</a>, para que
      eliminemos la información y cancelemos la suscripción del menor a
      cualquiera de nuestras listas de marketing electrónico.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      No puede utilizar los servicios de HTS Connect si usted está identificado
      en cualquier lista de personas prohibidas, sancionadas o restringidas del
      gobierno de los EE. UU. o Canadá; o si se encuentra en un país sujeto a un
      embargo del gobierno de los EE. UU. o Canadá; o que haya sido designado
      por el gobierno de los EE. UU. o Canadá como un “país que apoya al
      terrorismo”.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Si bien puede hacer copias de la información relacionada con sus
      reservaciones, no puede intentar acceder, copiar, descompilar, desmontar o
      realizar ingeniería inversa, distribuir, otorgar sublicencias, publicar,
      interrumpir, destruir o interferir con el código fuente, las bibliotecas,
      las bases de datos, los algoritmos, los datos o los sistemas o redes de
      Hopper que conforman los servicios de HTS Connect, ni intentar eludir
      ningún control de seguridad incluido en los servicios de HTS Connect. No
      puede intentar acceder a los servicios de HTS Connect utilizando ninguna
      herramienta automatizada, incluidos, entre otros, el uso de robots,
      arañas, rastreadores, raspadores u otras herramientas similares u otra
      tecnología no autorizada por Hopper. No puede incrustar enlaces profundos
      a ninguna porción de los servicios de HTS Connect (incluida, entre otras,
      la ruta de compra de cualquier servicio de viajes) ni enmarcar, reflejar o
      incorporar de otro modo cualquier parte de los servicios de HTS Connect en
      cualquier otra aplicación o sitio sin la autorización expresa previa y por
      escrito de Hopper. No puede utilizar los servicios de HTS Connect para
      ningún propósito fraudulento o engañoso. Si Hopper revocó previamente su
      derecho a acceder a los servicios de HTS Connect, tiene prohibido
      cualquier uso de los servicios de HTS Connect.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      1.3. <span className={styles.underline}>Actualizaciones</span>. Hopper
      puede actualizar los servicios de HTS Connect oportunamente con el fin de
      mejorar los servicios de HTS Connect. Si demora o rechaza las
      actualizaciones de los servicios de HTS Connect, esto puede comprometer o
      impedir la prestación de los servicios de HTS Connect.
    </Typography>
    <Typography
      variant="body2"
      className={clsx(styles.bold, styles.mb, styles.center)}
    >
      2. Autorizaciones de pago
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      2.1. <span className={styles.underline}>Pago</span>. Para realizar compras
      con los servicios de HTS Connect de Hopper o de proveedores externos de
      servicios de viajes (p. ej., aerolíneas) deberá brindar cierta información
      a Hopper. Usted acepta: (i) brindar y mantener información precisa,
      actualizada y completa para su reservación, (ii) mantener la seguridad de
      su información al no compartir, divulgar o poner a disposición las
      credenciales de acceso a ninguna persona no autorizada, y (iii) notificar
      a Hopper de inmediato si descubre o sospecha cualquier violación de
      seguridad relacionada con el uso de los servicios de HTS Connect. Acepta
      ser responsable de todas las actividades y compras que se realicen durante
      el uso de los servicios de HTS Connect y acepta todos los riesgos de
      acceso no autorizado a los servicios de HTS Connect en su nombre como
      resultado de cualquier acto u omisión por su parte en violación de estos
      términos. La información que se ingrese en relación con una reservación a
      través de los servicios de HTS Connect se almacenará solo para esa otra y
      no se vinculará a ninguna otra anterior o futura.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      2.2. <span className={styles.underline}>Métodos de pago</span>. Hopper le
      permite realizar compras en Hopper, y/o a proveedores externos de
      servicios de viajes, al permitirle proporcionar una tarjeta de débito, de
      crédito, de crédito prepago válida y autorizada u otro método de pago
      autorizado durante el pago (“
      <span className={styles.bold}>Instrumento de Pago</span>”). Al ingresar un
      método de pago en relación con su reservación, declara que es un usuario
      autorizado del Instrumento de Pago. Usted es responsable de garantizar que
      cualquier Instrumento de Pago asociado a su reservación se encuentre
      vigente, no haya expirado y sea válido para los pagos en todo momento.
      Hopper no es un banco y no ofrece servicios bancarios o de “transferencia
      de dinero” y no es un “negocio de servicios financieros”, ya que dichos
      términos los define el Departamento del Tesoro de los Estados Unidos.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      2.3. <span className={styles.underline}>Autorización de pago</span>.
      Autoriza a Hopper, y/o a los proveedores externos de servicios de viajes
      pertinentes, a cargar sus Instrumentos de Pago ingresados por el monto
      total de todas las compras u otras transacciones de pago iniciadas
      mientras utiliza los servicios de HTS Connect durante el proceso de pago,
      según sea necesario para completar la compra, el pago u otra transacción
      financiera solicitada o acordada por usted, incluidas, entre otras, todas
      las tarifas e impuestos. En algunos casos, Hopper puede transferir sus
      Instrumentos de Pago a un proveedor de servicios de viajes externo (como
      una aerolínea) para procesar su pago autorizado. Al hacer una reservación,
      autoriza la transferencia de dicho Instrumento de Pago. En el caso de que
      tenga derecho a una anulación, reembolso, devolución de cargo u otro
      ajuste asociado a una transacción que haya realizado con su Instrumento de
      Pago, también autoriza a Hopper a realizar un abono en cualquier
      Instrumento de Pago según sea necesario para ejecutar dicha transacción.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      2.4. <span className={styles.underline}>Transacciones fallidas</span>. En
      caso de que un cargo en cualquier Instrumento de Pago falle, acepta
      vincular un Instrumento de Pago nuevo y válido dentro de los tres (3) días
      laborables posteriores a la notificación por parte de Hopper. Sus
      Productos Hopper y los servicios externos de viajes ya comprados podrán
      cancelarse, hasta que se proporcione un Instrumento de Pago válido y se
      resuelva el saldo pendiente no cobrado. Además, autoriza a Hopper a
      intentar volver a enviar cualquier cargo fallido a cualquier Instrumento
      de Pago asociado con su cuenta en momentos posteriores hasta que tenga
      éxito. Hopper no es responsable de ningún cargo impuesto por el emisor de
      su Instrumento de Pago como resultado de cualquier cargo fallido o recargo
      por parte de Hopper. En la medida en que Hopper no pueda cobrar con éxito
      ningún Instrumento de Pago ingresado como pago por transacciones
      autorizadas durante más de treinta (30) días, usted entiende y acepta que
      Hopper puede emplear una agencia de cobros u otra empresa en un esfuerzo
      por cobrar cualquier deuda pendiente y las tarifas asociadas con su
      reservación y no impugnará el uso de un tercero para cobrar la deuda y las
      tarifas adeudadas a Hopper. Acepta que será responsable de cualquier
      tarifa legal, judicial, de arbitraje o de agencia de cobranza asociada con
      la rectificación de su cuenta de Hopper y todo el dinero adeudado en
      virtud de la misma. Acepta que Hopper, o cualquier agencia o empresa
      empleada por Hopper, tiene el derecho de contactarse con usted y sus
      herederos por teléfono, correo electrónico o (si no está prohibido por la
      ley) en persona utilizando la información que proporcionó durante
      cualquier contacto con Hopper, o cualquier otra información disponible de
      manera pública o accesible, en un esfuerzo por cobrar cualquier dinero y
      honorarios adeudados a Hopper, ya sea que se haga referencia
      específicamente en estos Términos o no; y dicho contacto se puede hacer de
      manera manual o automatizada.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      2.5.{" "}
      <span className={styles.underline}>
        Transacciones no autorizadas; dispositivos perdidos
      </span>
      . Si algún recibo de transacción recibido por usted parece incorrecto o si
      no fuera autorizado o fuera fraudulento, deberá notificar a Hopper de
      inmediato, contactándose con Volaris, que le redirigirá a Hopper Support,
      enviando una solicitud a través de la información disponible en el correo
      electrónico de confirmación de su reserva.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      2.6. <span className={styles.underline}>Conversiones de moneda</span>. Es
      posible que algunos precios o tarifas proporcionados por los servicios de
      HTS Connect para las compras se muestran de forma predeterminada, o en la
      moneda de su preferencia o seleccionada, aunque el cargo realizado a su
      Instrumento de Pago por un proveedor externo sea en una moneda local. En
      estos casos, el monto que se carga a su Instrumento de Pago para la compra
      podría variar ligeramente del monto mostrado, debido a las fluctuaciones
      en la tasa de cambio, fuera del control de Hopper. Su proveedor de
      Instrumento de Pago también podría incluir una tarifa para procesar la
      transacción o conversión de moneda, en adición a cualquier impuesto
      aplicable a las compras internacionales.
    </Typography>
    <Typography
      variant="body2"
      className={clsx(styles.bold, styles.mb, styles.center)}
    >
      3. Consentimiento para comunicaciones electrónicas, divulgaciones y
      avisos; copias en papel
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      3.1. Al aceptar estos Términos, acepta que Hopper proporcione los
      Términos, su Política de Privacidad y todos los demás términos aplicables,
      y cualquier modificación realizada por Hopper a los mismos, así como todas
      las divulgaciones, avisos, recibos de transacciones, autorizaciones de
      transacciones y todas las declaraciones de transacciones u otra
      información requerida por la ley (“
      <span className={styles.bold}>Divulgaciones</span>”), electrónicamente a
      la dirección de correo electrónico que proporcione. Su aceptación o
      recepción electrónica de las Divulgaciones, y/o su uso continuado de los
      servicios de HTS Connect después de la publicación de las Divulgaciones
      por parte de Hopper, tiene el mismo efecto que si las recibiera en papel o
      las firmara con tinta. Excepto que las leyes aplicables exijan lo
      contrario, las Divulgaciones se considerarán recibidas y aceptadas por
      usted en un plazo de 24 horas a partir del momento en que se le envíen por
      correo electrónico o mensaje personal, independientemente de que decida
      leerlas o no. Para acceder y retener las Divulgaciones electrónicas
      necesita y declara que tiene, lo siguiente: (i) una computadora o
      dispositivo móvil con conexión a Internet; (ii) una dirección de correo
      electrónico válida que pueda recibir correos electrónicos de @hopper.com y
      (iii) suficiente espacio de almacenamiento para guardar las Divulgaciones
      anteriores y/o una impresora instalada para imprimirlas. Tiene derecho a
      recibir Divulgaciones en forma impresa. Si desea recibir una copia en
      papel de cualquier Divulgación, o cualquier recibo de transacción, puede
      solicitar una copia dentro de los 180 días posteriores a su fecha de
      publicación o emisión. Hopper proporcionará copias en papel de forma
      gratuita. Puede enviar solicitudes por escrito para recibir una copia en
      papel, o para retirar su consentimiento a futuras Divulgaciones
      electrónicas a Hopper (USA), Inc., a la atención de: Departamento Legal,
      265 Franklin Street, Suite 1702, Boston, MA 02110. Las solicitudes para
      cancelar su consentimiento para futuras Divulgaciones electrónicas se
      completarán en un tiempo razonable.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      3.2.{" "}
      <span className={styles.underline}>
        Consentimiento para comunicaciones y contacto
      </span>
      . Al realizar cualquier compra o reservación utilizando los servicios de
      HTS Connect, acepta que Hopper, y/o el proveedor externo de servicios de
      viajes reservados, se contacte con usted electrónicamente, lo que incluye,
      el correo electrónico, teléfono, SMS, mensajería en la app u otro servicio
      de mensajería digital disponible, para cualquier propósito relacionado con
      el uso de los servicios de HTS Connect, y/o cualquier reservación o
      compras que realice a través de los servicios de HTS Connect.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      3.3. Su computadora y/o dispositivo móvil deben estar conectados a
      Internet para acceder a los servicios de HTS Connect y para realizar y
      recibir comunicaciones hacia/desde Hopper, incluido el contacto con el
      equipo de servicio al cliente de Hopper. Usted es responsable de hacer
      todos los arreglos necesarios para que su dispositivo tenga conectividad a
      Internet y de todos los cargos de terceros relacionados con dicho acceso
      y/o la transmisión y recepción de datos.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      3.4. Al reservar con Hopper, acepta que tanto usted como el equipo de
      Atención al cliente de Hopper pueden comunicarse con relación a su
      reservación a través de cualquier canal, como teléfono, SMS o correo
      electrónico.
    </Typography>
    <Typography
      variant="body2"
      className={clsx(styles.bold, styles.mb, styles.center)}
    >
      4. Orden de clasificación de Hopper
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      4.2. Cuando busque vuelos utilizando los servicios de HTS Connect, Hopper
      le ofrece una serie de opciones para mostrar, filtrar y clasificar los
      resultados, que incluyen el precio y la duración.
    </Typography>
    <Typography
      variant="body2"
      className={clsx(styles.bold, styles.mb, styles.center)}
    >
      5. Servicios de viaje de terceros
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.1. Los servicios de HTS Connect proporcionan acceso a información de
      viajes, como precios y disponibilidad de vuelos, características y
      servicios; y términos y condiciones, proporcionados y mantenidos por
      terceros. Estos proveedores externos son contratistas independientes y no
      agentes de Hopper. Su contrato para estos servicios de viaje es con el
      proveedor externo y no con Hopper. Si bien Hopper trabaja duro para
      garantizar la corrección, no puede verificar ni es responsable de la
      exactitud de la información proporcionada por terceros con respecto a sus
      respectivos servicios. Tampoco es responsable de los actos, errores,
      retrasos, omisiones, representaciones, garantías, incumplimientos,
      quiebras o negligencias de dichos proveedores externos o de cualquier
      lesión personal, muerte, daño a la propiedad u otros daños o gastos
      resultantes de los servicios prestados por terceros. Hopper no es
      responsable de hacer ningún reembolso en caso de demora, falla,
      incumplimiento, cancelación, sobreventa, huelga, fuerza mayor u otras
      causas fuera de su control directo. Al ofrecer servicios de viajes de
      terceros para reservar a través de los servicios de HTS Connect, Hopper no
      respalda, ni hace ninguna declaración o garantía sobre la calidad de
      ningún proveedor de servicios. Además, a menos que se establezca
      expresamente lo contrario, Hopper no pretende estar afiliado ni respaldado
      por ningún proveedor de servicios externo.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.2. La compra de productos y servicios de viajes de terceros a través de
      los servicios de HTS Connect y el derecho a cancelación o reembolso por
      dichos servicios, incluyendo la elegibilidad de programas de fidelización,
      se regirá y estará sujeto a términos y condiciones adicionales de dichos
      proveedores y sujeto a las leyes y regulaciones impuestas por entidades
      gubernamentales, y usted acepta cumplir con todos estos términos, leyes y
      regulaciones. Siempre que sea posible, Hopper pondrá estos términos a su
      disposición. Sin embargo, se pueden proporcionar términos aplicables
      adicionales en el sitio web del proveedor externo. Lea detenidamente los
      términos y condiciones adicionales del proveedor antes de comprar a
      cualquier proveedor externo de servicios que utilice los servicios de HTS
      Connect y asegúrese de que entiende todos los términos aplicables a su
      pasaje aéreo u otro servicio de terceros. Si realiza reservaciones en
      nombre de otras personas con su autorización, se compromete a informarles
      sobre estos Términos y cualquier término, regla y restricción de terceros
      que se aplique a las reservaciones realizadas en su nombre. Entiende y
      acepta que cualquier violación de los términos de servicio de terceros
      puede resultar en la cancelación de sus reservaciones, la denegación de
      servicio y la pérdida de cualquier monto pagado. Hopper no es responsable
      de ningún daño resultante de su incumplimiento de los términos de
      terceros.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.3. <span className={styles.underline}>Reservaciones aéreas</span>: las
      reservaciones de viajes aéreos solo están garantizadas una vez que se haya
      completado la compra y se hayan emitido los boletos, según lo confirmado
      por Hopper por correo electrónico después de su compra. Hasta ese momento,
      es posible que no pueda ver información sobre su reservación en el sitio
      web de la aerolínea.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.3.1.{" "}
      <span className={styles.underline}>Viajes con boletos múltiples</span>. Su
      reservación de viajes aéreos a través de los servicios de HTS Connect será
      combinando múltiples boletos de solo ida, en una o más aerolíneas, en
      lugar de un único boleto de solo ida o ida y vuelta. La combinación de
      boletos de solo ida de esta manera puede ofrecer una mayor variedad de
      vuelos, a un costo menor. Sin embargo, cada boleto de solo ida en su viaje
      estará sujeto a las reglas, restricciones y tarifas propias de la
      aerolínea. Para dicho viaje, si uno de sus vuelos se ve afectado por una
      irregularidad de vuelo (p. ej., una cancelación, retraso o cambio de
      horario iniciado por la aerolínea) y dicha irregularidad le hace perder la
      salida de otro vuelo con un boleto por separado, la aerolínea de ese otro
      vuelo no tiene ninguna obligación de volver a acomodarle y, sujeto a los
      costos cubiertos por la Garantía de conexión perdida de Hopper que se
      incluye con los servicios de HTS Connect, usted sigue siendo responsable
      de cualquier cargo debido a cambios (cuando se permitan) en la ruta,
      fechas u horarios de viaje o aerolíneas para la parte de su viaje cubierta
      por el boleto separado. Algunos boletos podrían no ser elegibles para
      cambios iniciados por el pasajero bajo las reglas de las tarifas
      aplicables y “no presentarse” a un vuelo puede resultar en la cancelación
      del boleto y la pérdida de todo el valor restante. Sus vuelos comprados
      utilizando los servicios de HTS Connect incluirán la Garantía de conexión
      perdida de Hopper, que está sujeta a sus propios términos y condiciones
      que se encuentran{" "}
      <a href={PATH_TERMS_MCP} target="_blank" rel="noreferrer">
        aquí
      </a>{" "}
      e incorporados aquí como referencia.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Los viajes con boletos múltiples pueden requerir cambiar de terminal y/o
      retirar el equipaje y volver a registrar el equipaje antes del próximo
      vuelo programado en el itinerario. Esto puede requerir la posible salida y
      reingreso de la seguridad. Antes de comprar un itinerario de boletos
      múltiples, tenga en cuenta cualquier consideración de tiempo que pueda
      afectar su capacidad para completar el viaje. Si bien los itinerarios de
      boletos múltiples incluyen la Garantía de conexión perdida de Hopper,
      usted es el único responsable de recuperar su equipaje entre destinos y de
      estar al tanto de cualquier obligación de equipaje y seguridad entre
      conexiones.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.3.2.{" "}
      <span className={styles.underline}>Viajes aéreos internacionales</span>.
      Usted es el único responsable de garantizar que cumpla con todos los
      requisitos de entrada impuestos por las autoridades gubernamentales en el
      destino, incluidos los países por los que puede estar en tránsito, los
      requisitos de salud y pasaporte/visa; y de que sus documentos de viaje,
      como pasaportes y visas (tránsito, negocios, turismo y otros) se
      encuentren en orden. Hopper no proporciona asesoramiento con respecto a
      los requisitos de entrada para ningún destino. Debe consultar a la
      embajada o consulado correspondiente para obtener dicha información. Los
      requisitos pueden cambiar y debe consultar la información actualizada
      antes de reservar y salir. Hopper no es responsable si se le niega la
      entrada en un vuelo o en cualquier país debido a su incumplimiento de los
      requisitos de entrada. AL OFRECER RESERVACIONES PARA PRODUCTOS DE VIAJE EN
      DESTINOS PARTICULARES, HOPPER NO DECLARA NI GARANTIZA QUE EL VIAJE A
      DICHAS ÁREAS SEA ACONSEJABLE O SIN RIESGO Y NO SE HACE RESPONSABLE DE LOS
      DAÑOS O PÉRDIDAS QUE PUEDAN RESULTAR DEL VIAJE A DICHOS DESTINOS.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.3.3.{" "}
      <span className={styles.underline}>
        Asesoramiento a pasajeros internacionales sobre limitaciones de
        responsabilidad
      </span>
      . SE ADVIERTE A LOS PASAJEROS QUE SE EMBARQUEN EN UN VIAJE QUE IMPLIQUE UN
      DESTINO FINAL O UNA PARADA EN UN PAÍS DISTINTO DEL PAÍS DE PARTIDA QUE LAS
      DISPOSICIONES DE UN TRATADO INTERNACIONAL (EL CONVENIO DE VARSOVIA, EL
      CONVENIO DE MONTREAL DE 1999 U OTRO TRATADO), ASÍ COMO LAS DISPOSICIONES
      DEL PROPIO CONTRATO DE TRANSPORTE O TARIFA DEL TRANSPORTISTA, PUEDEN SER
      APLICABLES A TODO SU VIAJE, INCLUIDA CUALQUIER PARTE TOTALMENTE DENTRO DE
      LOS PAÍSES DE SALIDA Y DESTINO. EL TRATADO APLICABLE RIGE Y PUEDE LIMITAR
      LA RESPONSABILIDAD DE LOS TRANSPORTISTAS A LOS PASAJEROS POR MUERTE O
      LESIONES PERSONALES, DESTRUCCIÓN O PÉRDIDA, O DAÑOS, AL EQUIPAJE, Y POR EL
      RETRASO DE LOS PASAJEROS Y EL EQUIPAJE.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      POR LO GENERAL, SE PUEDE OBTENER PROTECCIÓN ADICIONAL COMPRANDO UN SEGURO
      DE UNA EMPRESA PRIVADA. ESTE SEGURO NO SE VERÁ AFECTADO POR NINGUNA
      LIMITACIÓN DE LA RESPONSABILIDAD DEL TRANSPORTISTA EN VIRTUD DE UN TRATADO
      INTERNACIONAL. PARA OBTENER MÁS INFORMACIÓN, CONSULTE A SU AEROLÍNEA O
      REPRESENTANTE DE LA COMPAÑÍA DE SEGUROS.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.3.4.{" "}
      <span className={styles.underline}>
        Aviso de términos del contrato incorporados por referencia
      </span>
      . Su contrato de transporte con el transportista que le proporciona el
      transporte aéreo, ya sea internacional, nacional o el tramo nacional de un
      viaje internacional, está sujeto a este aviso; a cualquier aviso o
      comprobante del transportista; y a los términos y condiciones individuales
      del transportista (Condiciones), reglas, regulaciones y políticas
      relacionadas (Reglamentos) y cualquier tarifa aplicable. Si su transporte
      será provisto por más de un transportista, pueden aplicarse diferentes
      Condiciones, Reglamentos y cualquier tarifa aplicable para cada
      transportista. Las Condiciones, Reglamentos y cualquier tarifa aplicable
      de cada transportista se incorporan, por referencia, a su contrato de
      transporte y forman parte de él. Las Condiciones pueden incluir, entre
      otras: (a) condiciones y límites a la responsabilidad del transportista
      por lesiones corporales o muerte de pasajeros; (b) condiciones y límites a
      la responsabilidad del transportista por la pérdida, daño o retraso de
      mercancías y equipaje, incluidas mercancías frágiles o perecederas; (c)
      reglas para declarar un valor más alto para el equipaje y para pagar
      cualquier tarifa suplementaria que pueda aplicarse; (d) aplicación de las
      condiciones y límites de responsabilidad del transportista a los actos de
      los agentes, empleados y representantes del transportista, lo que incluye
      a cualquier persona que proporcione equipo o servicios al transportista;
      (e) restricciones de reclamaciones, incluidos los plazos en los que los
      pasajeros deben presentar reclamaciones o entablar acciones contra el
      transportista; (f) reglas sobre reconfirmaciones o reservaciones; horarios
      de facturación; el uso, la duración y la validez de los servicios de
      transporte aéreo; y el derecho del transportista a rechazar el transporte;
      (g) derechos del transportista y límites a la responsabilidad del
      transportista por demora o incumplimiento de un servicio, incluidos
      cambios de horario, sustitución de otros transportistas o aeronaves y el
      cambio de ruta y, cuando así lo exija la legislación aplicable, la
      obligación del transportista de notificar a los pasajeros la identidad del
      transportista operativo o de la aeronave sustituida; y h) los derechos del
      transportista a denegar el transporte a los pasajeros que no cumplan la
      legislación aplicable o que no presenten todos los documentos de viaje
      necesarios.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Puede obtener información adicional sobre los puntos (a) a (h) anteriores
      y sobre su contrato de transporte (incluso, la forma de solicitar una
      copia del mismo) en los lugares donde se vende el transporte en el
      transportista. Muchos operadores también tienen esta información en sus
      sitios web. Cuando lo exija la ley aplicable, tiene derecho a inspeccionar
      el texto completo de su contrato de transporte en el aeropuerto y las
      oficinas de ventas del transportista y, previa solicitud, a recibir una
      copia por correo u otro servicio de entrega de cada transportista de forma
      gratuita. Si un transportista vende servicios de transporte aéreo o
      registra el equipaje especificando el transporte en otro transportista, lo
      hace únicamente como agente del otro transportista.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.3.5.{" "}
      <span className={styles.underline}>
        Cambios/cancelaciones del itinerario
      </span>
      . Los cambios o cancelaciones de itinerario que solicite (es decir,
      reembolsos voluntarios), si lo permiten las reglas de tarifas de la
      aerolínea aplicables a su boleto, pueden estar sujetos a tarifas impuestas
      por la aerolínea, además de cualquier diferencia de tarifas en la
      facturación. Dichas tarifas varían según la aerolínea. Su aerolínea puede
      permitir que una parte de la tarifa pagada por un boleto no reembolsable
      no utilizado se aplique a la compra de futuros viajes. Para muchas
      aerolíneas, los boletos no cambiados o cancelados antes de la salida no
      tienen valor residual.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.3.6. <span className={styles.underline}>Devoluciones de impuestos</span>
      . En caso de no presentarse o cancelar una reservación aérea, es posible
      que tenga derecho a un reembolso de los impuestos y tarifas
      gubernamentales incluidos en el precio del boleto adquirido. Si tiene
      derecho a un reembolso, puede solicitarlo al servicio de atención al
      cliente de Hopper, que enviará su solicitud a la aerolínea en su nombre.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.3.7.{" "}
      <span className={styles.underline}>
        Derechos de los viajeros de la UE
      </span>
      . Si viaja hacia o desde la Unión Europea, o en una aerolínea con sede en
      la UE, puede tener derechos en el caso de que su vuelo se cancele, se
      retrase o se le niegue el embarque. Consulte el Reglamento (CE) n.º
      261/2004 para obtener más información.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.3.8. <span className={styles.underline}>Materiales peligrosos</span>. La
      ley federal de Estados Unidos prohíbe el transporte de materiales
      peligrosos a bordo de aeronaves en su equipaje o en su persona. Una
      violación puede dar lugar a penas de cinco años de prisión y multas de USD
      250 000 o más (49 U.S.C. artículo 5124). Los materiales peligrosos
      incluyen explosivos, gases comprimidos, líquidos y sólidos inflamables,
      oxidantes, venenos, corrosivos y materiales radiactivos. Ejemplos:
      pinturas, líquido encendedor, fuegos artificiales, gases lacrimógenos,
      botellas de oxígeno y radiofármacos. Hay excepciones especiales para
      pequeñas cantidades (hasta 70 onzas en total) de medicamentos y artículos
      de tocador que se llevan en su equipaje, baterías de litio de repuesto y
      ciertos materiales para fumar que se llevan en su persona. Se requiere que
      lleves ciertos artículos contigo a bordo del avión. Las baterías de litio
      de repuesto y los encendedores de cigarrillos deben retirarse del equipaje
      facturado o controlado por la puerta y llevarse a bordo del avión.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.3.9.{" "}
      <span className={styles.underline}>
        Prácticas prohibidas por las aerolíneas
      </span>
      . Muchas compañías aéreas prohíben eludir sus reglas, incluidas prácticas
      como la emisión de boletos consecutivos (reservar dos o más boletos con
      fechas de viaje superpuestas para eludir los requisitos de estadía mínima)
      y la emisión de boletos en ciudades ocultas, más allá o desechables
      (reservar boletos que incluyan segmentos que no tiene intención de
      utilizar para eludir la estructura de precios de una compañía aérea).
      Acepta que no usará las Apps o Servicios para participar en estas
      actividades u otras similares si una aerolínea lo prohíbe. LOS
      TRANSPORTISTAS SE RESERVAN EL DERECHO DE RECHAZAR EL TRANSPORTE A
      CUALQUIER PERSONA QUE HAYA ADQUIRIDO UN BOLETO EN VIOLACIÓN DE LA LEY
      APLICABLE O DE LAS TARIFAS, REGLAS O REGULACIONES DEL TRANSPORTISTA.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.3.10. <span className={styles.underline}>Información requerida</span>.
      La Administración de Seguridad del Transporte (TSA, en inglés), parte del
      Departamento de Seguridad Nacional de los Estados Unidos, requiere que
      Hopper recopile su información para fines de revisión de la lista de
      vigilancia, de conformidad con 49 U.S.C. Sec. 114 y la Ley de reforma de
      los servicios de inteligencia y prevención del terrorismo de 2004. La TSA
      puede compartir la información proporcionada por el pasajero con agencias
      de seguridad pública o de inteligencia u otras que se encuentren bajo la
      notificación publicada de su sistema de registro de datos. Para obtener
      más información, visite{" "}
      <a href="https://www.tsa.gov" target="_blank" rel="noreferrer">
        www.tsa.gov
      </a>
      .
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.3.11. <span className={styles.underline}>Transferencia de datos</span>.
      LOS GOBIERNOS PODRÁN REQUERIR QUE SU AEROLÍNEA PROPORCIONE INFORMACIÓN O
      PERMITA EL ACCESO A LOS DATOS DE LOS PASAJEROS, LO QUE INCLUYE LA
      INFORMACIÓN QUE PODRÍAMOS ESTAR OBLIGADOS A RECOPILAR DE SU PARTE DURANTE
      EL PROCESO DE RESERVACIÓN.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.3.12.{" "}
      <span className={styles.underline}>
        Aviso para los EE. UU.: sobreventa de vuelos
      </span>
      . Los vuelos de las aerolíneas pueden estar sobrevendidos y existe una
      pequeña posibilidad de que no haya un asiento disponible en un vuelo para
      el que una persona tenga una reservación confirmada. Si el vuelo está
      sobrevendido, no se negará un asiento a ninguna persona hasta que el
      personal de la aerolínea primero pida voluntarios dispuestos a renunciar a
      su reservación a cambio de un pago a la elección de la aerolínea. Si no
      hay suficientes voluntarios, la aerolínea denegará el embarque a otras
      personas de acuerdo con su prioridad de embarque particular. Con pocas
      excepciones, las personas a las que se deniega el embarque
      involuntariamente tienen derecho a una indemnización. Las reglas completas
      para el pago de la indemnización y las prioridades de embarque de cada
      aerolínea están disponibles en todos los mostradores de boletos de
      aeropuerto y lugares de embarque.{" "}
      <span className={styles.bold}>
        Algunas aerolíneas no aplican estas protecciones al consumidor para
        viajar desde algunos países extranjeros, aunque pueden estar disponibles
        otras protecciones al consumidor. Consulte con la aerolínea o con su
        agente de viajes.
      </span>
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.3.13. <span className={styles.underline}>Equipaje</span>. Puede
      declararse un valor superior para ciertos tipos de objetos de valor. Los
      transportistas pueden aplicar reglas especiales para artículos frágiles,
      valiosos o perecederos. Consulte con su transportista. Equipaje
      registrado: los transportistas pueden permitir una franquicia gratuita de
      equipaje registrado, que es establecida por el transportista y puede
      diferir según la clase y/o la ruta. Los transportistas pueden aplicar
      cargos adicionales por equipaje registrado que exceda el límite permitido.
      Consulte con su transportista. Equipaje de cabina (no despachado): los
      transportistas pueden permitir una franquicia gratuita de equipaje de
      mano, que es establecida por el transportista y puede diferir según la
      clase, ruta y/o tipo de avión. Se recomienda reducir al mínimo el equipaje
      de mano. Consulte con su aerolínea.{" "}
      <span className={styles.bold}>
        Si el transporte para su viaje se proporciona por más de una aerolínea,
        cada una de estas puede aplicar diferentes reglas sobre el equipaje
        (tanto registrado como de mano)
      </span>
      . LIMITACIONES DE RESPONSABILIDAD POR EQUIPAJE ESPECIAL PARA VIAJES EN LOS
      EE. UU.: para viajes nacionales totalmente entre puntos de dentro de los
      EE. UU., las reglas federales requieren que cualquier límite en la
      responsabilidad por equipaje de una aerolínea sea de al menos USD 3800 por
      pasajero o el monto actualmente exigido por 14 CFR § 254.5. Si está
      registrando equipaje en un itinerario con varios boletos, debe salir de
      seguridad, recuperar su equipaje y volver a facturar su equipaje antes de
      su próximo vuelo (si corresponde). Usted es el único responsable de
      comprender las responsabilidades de su equipaje en los itinerarios de
      boletos múltiples.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.3.14. <span className={styles.underline}>Horario de check-in</span>. La
      hora que se muestra en el itinerario/recibo es la hora de salida del
      avión. La hora de salida del vuelo no es la misma que la hora en que debe
      registrarse o la hora en que debe estar disponible para embarcar. El
      transportista puede negarse a transportarlo si llega tarde. Los horarios
      de check-in, según lo aconsejado por su transportista, son los últimos
      horarios en los que se puede aceptar a los pasajeros para viajar; los
      horarios de embarque, según lo aconsejado por su transportista, son los
      últimos horarios en los que los pasajeros deben presentarse para embarcar.
      Los vuelos no se pueden retener para los pasajeros que llegan tarde y
      Hopper no acepta ninguna responsabilidad en tales casos.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.3.15.{" "}
      <span className={styles.underline}>Desinfección de aeronaves</span>.
      Algunos países pueden requerir tratamiento insecticida en la cabina de la
      aeronave para vuelos internacionales entrantes. Una lista de dichos países
      está disponible en{" "}
      <a
        href="https://www.transportation.gov/airconsumer/spray"
        target="_blank"
        rel="noreferrer"
      >
        www.transportation.gov/airconsumer/spray
      </a>
      .
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.3.16. <span className={styles.underline}>Secuencia de viajes</span>. Las
      aerolíneas exigen que los pasajeros viajen según el itinerario que figura
      en su boleto, desde el lugar de salida a través de cualquier punto de
      conexión acordado hasta su destino final, y viceversa. Si no se utilizan
      todos los segmentos en secuencia, el boleto en su conjunto perderá su
      validez en la mayoría de las circunstancias.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.3.17. Hopper podría recibir comisiones u otros incentivos pecuniarios de
      las aerolíneas en relación con su reservación aérea a través de los
      servicios de HTS Connect. En algunos casos, este incentivo puede ser
      realizado por Hopper como un recargo incluido en el precio que usted paga
      a Hopper por dicha reservación y ese precio puede diferir del precio que
      se refleja en su itinerario o boleto de la aerolínea.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.3.18. Los vuelos vendidos en el Reino Unido por Hopper se venden como
      agentes de la aerolínea mencionada en su boleto y no están protegidos por
      el esquema de Licencias de Organizadores de Viajes Aéreos.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      5.6. <span className={styles.underline}>Sitios web de terceros</span>. Los
      servicios de HTS Connect pueden contener enlaces a páginas web de terceros
      que no son operados por Hopper. Estos enlaces se proporcionan únicamente
      como referencia. Hopper no es responsable por los contenidos o la
      privacidad u otras prácticas de dichas páginas web. Debe revisar los
      términos aplicables a cualquier página web de terceros al que elija
      acceder.
    </Typography>
    <Typography
      variant="body2"
      className={clsx(styles.bold, styles.mb, styles.center)}
    >
      6. Entidades de Hopper; vendedores de registros de viaje y divulgaciones
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      A menos que se establezca lo contrario a continuación, Hopper (USA), Inc.,
      una subsidiaria de propiedad total de Hopper Inc., realiza todas las
      reservaciones. Hopper (USA), Inc. es un vendedor de viajes registrado en
      cada uno de los siguientes estados de los EE. UU.:
    </Typography>
    <Typography variant="body2" className={styles.mb}></Typography>
    <Typography variant="body2" className={styles.center}>
      California*, número de registro:{" "}
      <span className={styles.bold}>2141604-70</span>
    </Typography>
    <Typography variant="body2" className={styles.center}>
      Hawái, número de registro: <span className={styles.bold}>TAR 7387</span>
    </Typography>
    <Typography variant="body2" className={clsx(styles.mb, styles.center)}>
      Washington, número de registro:{" "}
      <span className={styles.bold}>604516639</span>
    </Typography>
    <Typography variant="body2" className={clsx(styles.mb, styles.italic)}>
      *El registro como vendedor de viajes en California no constituye la
      aprobación por parte del estado de Hopper. Hopper no participa en el Fondo
      de Restitución de Consumidores de Viajes (en inglés, Travel Consumer
      Restitution Fund).
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      <span className={styles.underline}>
        Divulgaciones adicionales para residentes de California
      </span>
      : una vez cancelados los servicios de transporte o viaje, cuando no exista
      falta por parte del pasajero y no haya cancelado en violación de los
      términos y condiciones previamente divulgados de manera clara y evidente
      al pasajero y aceptado por este, todas las sumas pagadas al vendedor de
      viajes por servicios no prestados serán reembolsadas de inmediato al
      pasajero. Esta disposición no aplica cuando el vendedor de viajes haya
      remitido el pago del cliente a otro vendedor mayorista de viajes o a un
      transportista registrado sin obtener un reembolso y tal proveedor no
      cumple en proporcionar el transporte o servicio acordado. En esta
      situación, el vendedor de viajes inicial debe proporcionar al pasajero una
      declaración por escrito acompañada por los registros bancarios que
      demuestran el desembolso del pago y, si se ha pagado a un vendedor
      mayorista de viajes, prueba del registro actual de dicho vendedor
      mayorista.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      <span className={styles.underline}>
        Divulgaciones adicionales para residentes del estado de Washington
      </span>
      : si el transporte u otros servicios son cancelados por el vendedor de
      viajes, todas las sumas pagadas al vendedor de viajes por los servicios no
      realizados de acuerdo con el contrato entre el vendedor de viajes y el
      comprador se reembolsarán dentro de los 30 días posteriores a la recepción
      de los fondos del proveedor con el que se organizaron los servicios o, si
      los fondos no se enviaron al vendedor, los fondos se devolverán dentro de
      los 14 días posteriores a la cancelación por parte del vendedor del viaje
      al comprador, a menos que el comprador solicite al vendedor del viaje que
      aplique el dinero a otro producto de viaje y/o fecha.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      <span className={styles.underline}>
        Divulgaciones adicionales para residentes de la Unión Europea
      </span>
      : para residentes en la Unión Europea, las reservaciones de viajes a
      través de los servicios de HTS Connect son realizadas por Hopper Travel
      (Ireland) Ltd., Mespil Business Centre, Mespil House, Sussex Road, Dublín
      4, Irlanda (“Hopper Ireland”). Si, después de seleccionar y pagar un
      servicio de viaje, reserva servicios adicionales para su viaje o
      vacaciones utilizando los servicios de HTS Connect, NO se beneficiará de
      los derechos que se aplican a los paquetes en virtud de la Ley de Viajes
      Combinados y Vacaciones de 1995. Por lo tanto, Hopper Ireland no será
      responsable del correcto desempeño de los servicios de viaje individuales.
      En caso de problemas, contáctese con el proveedor de servicios
      correspondiente. Sin embargo, si reserva algún servicio de viaje adicional
      durante la misma visita utilizando los servicios de HTS Connect, los
      servicios de viaje pasarán a formar parte de un acuerdo de viaje
      vinculado. En ese caso, Hopper Ireland tiene, según lo exige la Ley de
      Viajes Combinados y Vacaciones de 1995, protección para reembolsar sus
      pagos a Hopper Ireland por servicios no realizados debido a la insolvencia
      de Hopper Ireland. Tenga en cuenta que esto no proporciona un reembolso en
      caso de insolvencia del proveedor de servicios correspondiente.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      En{" "}
      <a
        href="https://drive.google.com/file/d/13tZwUmxfmogu-bJreEVgBJZdYNWMP-WW/view"
        target="_blank"
        rel="noreferrer"
      >
        este enlace
      </a>
      se proporciona más información sobre la protección contra la insolvencia
      para Hopper Ireland. Hopper Ireland ha suscrito una protección contra la
      insolvencia con Protección Internacional de Pasajeros (IPP, por sus siglas
      en inglés). Quienes viajan pueden contactarse con IPP o, si fuese el caso,
      con la Autoridad de Aviación Irlandesa (IAA), The Times Building, 11-12
      D'Olier Street, Dublín 2. D02 T449, correo electrónico:
      traveltrade@iaa.ie, y número de teléfono: +353-1-603-1100, si se niegan
      los servicios debido a la insolvencia de Hopper Ireland. Nota: Esta
      protección contra la insolvencia no cubre los contratos con partes
      distintas a Hopper Ireland, que se pueden realizar a pesar de la
      insolvencia de Hopper Ireland.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Puede acceder a la Ley de Viajes Combinados y Vacaciones de 1995 en este
      enlace.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Para los residentes de Canadá, las reservaciones las realiza Hopper
      (Canadá), Inc., una subsidiaria de propiedad total de Hopper Inc.,
      constituida según las leyes de Alberta, Canadá.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Para los residentes en México, las reservaciones las realiza Hopper App
      Travel Mexico, S.A. de R.L., Av. Paseo de la Reforma #403, oficina 806,
      Cuauhtèmoc, México.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Para los residentes en Colombia, las reservaciones las realiza Hopper
      Colombia SAS, Cra. 9 N.º 116-06 Piso 17 - Complejo Tierra Firme, Bogotá,
      Colombia.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Para los residentes en Chile, las reservaciones las realiza Hopper Chile,
      Marchant Pereira 150, Of. 1002, Providencia, Santiago, Chile.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Para los residentes en el Reino Unido, las reservaciones las realiza
      Hopper UK Inc., Ltd., 58-60 Kensington Church St., Kensington, Londres W8
      4DB, Reino Unido.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Su pago puede ser procesado por una entidad afiliada a Hopper que no sea
      la entidad responsable de su reservación.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Cualquier consulta de servicio al cliente dirigida puede comunicarse a
      Hopper a través la información facilitada en el correo electrónico de
      confirmación de la reserva.
    </Typography>
    <Typography
      variant="body2"
      className={clsx(styles.bold, styles.mb, styles.center)}
    >
      7. Propiedad intelectual
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      7.1. Los servicios de HTS Connect a los que accede de conformidad con
      estos Términos, incluidos, entre otros, la totalidad del texto, imágenes,
      sonido, marcas, logotipos, compilaciones (es decir, la recopilación,
      adaptación y montaje de información) y otro contenido constatado en los
      mismos, y todo software, código fuente u objeto, sistemas, datos y
      algoritmos incorporados o compatibles con los servicios de HTS Connect, o
      utilizados de otro modo por Hopper para prestar los servicios de HTS
      Connect, y todos los derechos de propiedad intelectual en los mismos,
      incluidos, entre otros, patentes, derechos de autor, derechos de marca
      registrada (en conjunto, la “
      <span className={styles.bold}>Propiedad de Hopper</span>
      "), son propiedad única y exclusiva de Hopper y/o de terceros otorgados
      bajo licencia a o utilizados con permiso por Hopper, y están protegidos
      conforme a las leyes de los Estados Unidos, Canadá y las leyes
      internacionales. Hopper se reserva todos los derechos sobre la Propiedad
      de Hopper, incluidos, sin limitación, los derechos de autor, marca
      registrada, patente y secreto comercial y dichos derechos sobre la
      Propiedad de Hopper no son otorgados, salvo lo expresamente dispuesto en
      este documento. Por el presente se le otorga un derecho limitado,
      personal, no exclusivo, intransferible, no sublicenciable y revocable para
      acceder, descargar y utilizar los servicios de HTS Connect únicamente para
      los fines expresamente autorizados por estos Términos, siempre que cumpla
      con estos Términos.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      7.2. <span className={styles.underline}>Marcas</span>. Las marcas
      “HOPPER”, las imágenes de conejito y conejito corriendo, entre otras
      marcas, son marcas registradas o no registradas de Hopper, protegidas bajo
      las leyes de los Estados Unidos, Canadá y otros países de todo el mundo y
      no se pueden utilizar en relación con ningún servicio o productos que no
      sean los proporcionados o expresamente autorizados por Hopper, de ningún
      modo que pueda causar confusión entre los clientes o de cualquier manera
      que menosprecie o desacredite a Hopper. Los servicios de HTS Connect
      también pueden incluir marcas comerciales protegidas de terceros y el uso
      de dichas marcas comerciales no constituye una afiliación, respaldo o
      patrocinio de Hopper por parte de ningún tercero.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      7.3. <span className={styles.underline}>Propiedad de terceros</span>. Los
      servicios de HTS Connect muestran las marcas comerciales, marcas de
      servicio, imágenes, logotipos y datos de terceros, incluidos los de los
      socios de servicios de viajes de Hopper (“
      <span className={styles.bold}>Propiedad de Terceros</span>”). Cada
      propietario externo conserva todos sus derechos sobre dicha Propiedad de
      Terceros. Cualquier uso de dicha Propiedad de Terceros proporcionada o
      mostrada en los servicios de HTS Connect, redunda únicamente en el
      beneficio de sus respectivos propietarios. El uso de dicha Propiedad de
      Terceros por parte de Hopper no implica ninguna afiliación o relación
      entre Hopper y el tercero, ni promoción alguna de Hopper o los servicios
      de HTS Connect por parte de dicho tercero.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      7.4.{" "}
      <span className={styles.underline}>Uso de datos de investigación</span>.
      El uso por su parte de los datos de investigación que se encuentran en
      media.hopper.com/research o que ponemos a disposición por otros medios o
      en otras ubicaciones físicas o electrónicas, incluidos, entre otros,
      datos, bases de datos, informes, documentos técnicos, análisis o
      compilaciones producidas y/o distribuidas por Hopper (“
      <span className={styles.bold}>Datos de Investigación</span>”) se rige
      adicionalmente por los términos de esta Sección. Hopper le otorga un
      derecho de carácter no exclusivo, limitado, intransferible, no
      sublicenciable y revocable para usar, reproducir, mostrar y redistribuir
      los Datos de Investigación, sujeto a su cumplimiento de los siguientes
      términos. Debe citar a Hopper como la fuente de cualquier Dato de
      Investigación en cualquier publicación que produzca que incluya, cite o se
      base en Datos de Investigación de cualquier modo. Si su publicación es en
      formato electrónico, debe enlazar a Hopper.com y cualquier backlink
      existente. Además de acreditar a Hopper como la Fuente de los Datos de
      Investigación, no debe usar ninguna marca comercial de Hopper ni ninguna
      variante confusamente similar. No debe afirmar ni insinuar que alguna
      entidad de Hopper respalda el uso de su parte de los Datos de
      Investigación o las conclusiones o trabajos derivados que pueda crear.
      Usted reconoce que los Datos de Investigación se proporcionan “en las
      condiciones en que se encuentran”. Hopper no garantiza que los datos y las
      publicaciones estén libres de errores o que estén completos y no puede
      asumir la responsabilidad de su uso o de la remisión a los Datos de
      Investigación. Usted reconoce nuestro derecho a modificar, eliminar o
      remover Datos de Investigación en cualquier momento y sin previo aviso.
      Usted reconoce que mantenemos todos los derechos, títulos e intereses,
      incluyendo la propiedad de todos los Datos de Investigación.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      7.5.{" "}
      <span className={styles.underline}>
        Ley de Derechos de Autor para Medios Digitales en el Nuevo Milenio
      </span>
      . Hopper respeta la propiedad intelectual de los demás. Si usted considera
      que su obra protegida por derechos de autor se copió y está disponible en
      los servicios de HTS Connect de modo que constituya una infracción a los
      derechos de autor, por favor, envíe a Hopper una notificación que contenga
      la siguiente información requerida por la Ley de Derechos de Autor para
      Medios Digitales en el Nuevo Milenio, 17 U.S.C. §512 (“
      <span className={styles.bold}>DMCA</span>”) a Hopper, Atención: División
      Legal, 265 Franklin Street, Suite 1702, Boston, MA 02110:
    </Typography>
    <span className={clsx(styles.gridCenter, styles.mb)}>
      <ul>
        <li>
          Información razonablemente suficiente que nos permita contactar a la
          parte que alega la infracción, incluyendo una dirección de correo
          electrónico;
        </li>
        <li>
          Identificación de la obra protegida por derechos de autor que
          supuestamente se ha infringido o, si se alega que se han infringido
          varias obras protegidas por derechos de autor, una lista
          representativa de dichas obras protegidas por derechos de autor;
        </li>
        <li>
          Identificación del material que supuestamente se está infringiendo y
          que debe eliminarse o cuyo acceso debe deshabilitarse, así como
          información razonablemente suficiente que nos permita ubicar el
          supuesto material violado, p. ej., la captura de pantalla específica
          del Servicio HTS Connect;
        </li>
        <li>
          Una declaración de que la parte que alega la infracción cree, de buena
          fe, que el uso de la obra protegida por derechos de autor del modo
          reclamado no está autorizado por el propietario de los derechos de
          autor o su agente, o no está permitido por la ley;
        </li>
        <li>
          Una declaración de que la información en la notificación es exacta y,
          bajo pena de perjurio, que la parte que alega la infracción está
          autorizada a actuar en nombre del propietario de los derechos de autor
          de la obra que supuestamente ha sido violada; y
        </li>
        <li>
          Una firma física o electrónica de una persona autorizada para actuar
          en nombre del propietario de los derechos de autor que certifique las
          declaraciones y la información proporcionada.
        </li>
      </ul>
    </span>
    <Typography variant="body2" className={styles.mb}>
      EN LOS TÉRMINOS DE LA LEGISLACIÓN FEDERAL, SI USTED TERGIVERSA A SABIENDAS
      QUE EL MATERIAL EN LÍNEA ESTÁ INFRINGIENDO, PUEDE SER SUJETO A UN PROCESO
      PENAL POR PERJURIO Y SANCIONES CIVILES, INCLUYENDO DAÑOS MONETARIOS,
      COSTAS JUDICIALES Y HONORARIOS DE ABOGADOS.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      7.6 <span className={styles.underline}>Comentarios</span>. No se le obliga
      a proporcionar a Hopper ideas, sugerencias, recomendaciones, solicitudes
      de mejora, testimonios, aprobaciones, reseñas de productos o información
      sobre su experiencia en el uso del Servicio HTS Connect (“
      <span className={styles.bold}>Comentarios</span>”). Sin embargo, si envía
      Comentarios a Hopper o sobre Hopper, ya sea directa o indirectamente a
      través de una página web de reseña de terceros o de una encuesta iniciada
      por Hopper, entonces otorga a Hopper una licencia no revocable, no
      exclusiva, mundial y libre de regalías que es sublicenciable y
      transferible, para hacer, utilizar, reproducir, mostrar públicamente,
      distribuir, vender, modificar o ejecutar públicamente los Comentarios en
      cualquier forma sin ninguna obligación, regalía o restricción basada en
      derechos de propiedad intelectual o de otro modo.
    </Typography>
    <Typography
      variant="body2"
      className={clsx(styles.bold, styles.mb, styles.center)}
    >
      8. Productos patentados de Hopper
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Los servicios de HTS Connect pueden ofrecer una serie de productos
      patentados (“
      <span className={styles.bold}>Productos</span>”), que tienen como objetivo
      aumentar la flexibilidad de los artículos de viaje comprados. La compra de
      cada Producto está sujeta a los Términos y Condiciones especificados que
      se pondrán a su disposición antes de la compra. Estos productos NO son
      productos financieros regulados, como los seguros y los Términos y
      Condiciones especificados no son ofertas en materia de seguros ni
      contratos de seguros y no sustituyen los seguros que usted obtenga o que
      pueda obtener. La Solución HTS Connect incluye el producto Garantía de
      conexión perdida de Hopper. Este producto está sujeto a términos y
      condiciones específicos que se pueden encontrar{" "}
      <a href={PATH_TERMS_MCP} target="_blank" rel="noreferrer">
        aquí
      </a>{" "}
      y que se incorporan aquí como referencia.
    </Typography>
    <Typography
      variant="body2"
      className={clsx(styles.bold, styles.mb, styles.center)}
    >
      9. Exención de garantías
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      9.1. Los servicios de HTS Connect, incluidas sin limitación, las
      recomendaciones y la predicción de precios de Hopper, están previstas “TAL
      CUAL” y “SEGÚN LA DISPONIBILIDAD”. EN LA MAYOR MEDIDA PERMITIDA POR LAS
      LEYES APLICABLES, HOPPER RENUNCIA, CON RESPECTO A LOS SERVICIOS DE HTS
      CONNECT Y TODO SU CONTENIDO, A TODAS LAS GARANTÍAS, EXPRESAS O IMPLÍCITAS,
      LEGALES O DE OTRO TIPO, INCLUIDAS, ENTRE OTRAS, LAS GARANTÍAS IMPLÍCITAS
      DE COMERCIABILIDAD, IDONEIDAD PARA UN PROPÓSITO PARTICULAR Y SIN
      INFRACCIÓN. Hopper no garantiza que los servicios de HTS Connect funcionen
      sin errores o sin tiempo de inactividad. Hopper puede pausar o interrumpir
      los servicios de HTS Connect en cualquier momento y los usuarios deberán
      esperar tiempos de inactividad periódica para actualizaciones. Hopper no
      garantiza ni hace ninguna afirmación con respecto al uso o los resultados
      de los servicios de HTS Connect respecto a su corrección, precisión,
      confiabilidad, puntualidad, integridad, actualidad o de otro modo,
      incluidos, sin limitación, la seguridad, calidad y/o tiempo de cualquier
      servicio de viaje comprado a terceros a través de los servicios de HTS
      Connect. Hopper no será responsable por las demoras, daños o
      incumplimientos en el desempeño que resulten de causas fuera del control
      razonable de Hopper, incluidos, entre otro, demoras y otros problemas
      inherentes al uso de Internet y de las comunicaciones electrónicas, fuerza
      mayor o de las acciones u omisiones de terceros. Todo el contenido de los
      servicios de HTS Connect se proporciona solo con fines informativos. El
      uso de cualquier información proporcionada a través de los servicios de
      HTS Connect queda bajo su exclusivo riesgo, incluidos, sin limitación
      alguna, la información de servicios de viajes de terceros y las
      predicciones y recomendaciones de Hopper. Aunque no se espera, pueden
      ocurrir errores y fallas en los servicios de HTS Connect ocasionalmente
      que pueden afectar su reservación. Esto puede resultar en precios u otras
      ofertas incorrectos. Hopper se reserva el derecho de corregir cualquier
      error de precios en los servicios de HTS Connect y/o reservaciones
      pendientes realizadas bajo un precio incorrecto, y es a discreción
      exclusiva de Hopper honrar cualquier error de precios incorrecto.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      9.2. LAS LEYES APLICABLES PUEDEN NO PERMITIR LA EXENCIÓN DE GARANTÍAS
      IMPLÍCITAS, POR LO QUE ALGUNAS O TODAS LAS EXCLUSIONES ANTERIORES PUEDEN
      NO SER APLICABLES EN SU TOTALIDAD.
    </Typography>
    <Typography
      variant="body2"
      className={clsx(styles.bold, styles.mb, styles.center)}
    >
      10. Limitación de responsabilidad civil
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      10.1. EN LA MAYOR MEDIDA PERMITIDA POR LAS LEYES APLICABLES, EN NINGÚN
      CASO HOPPER SERÁ RESPONSABLE ANTE USTED POR NINGÚN DAÑO SEA INDIRECTO,
      ESPECIAL, INCIDENTAL, PUNITIVO (MULTA CIVIL), EJEMPLAR O EMERGENTE, O
      CUALQUIER PÉRDIDA O DAÑO (INCLUIDAS LESIONES PERSONALES, PÉRDIDA DE DATOS,
      INGRESOS, GANANCIAS, REPUTACIÓN, USO U DE OTRA VENTAJA ECONÓMICA), INCLUSO
      SI HOPPER HUBIERA SIDO ADVERTIDO PREVIAMENTE DE LA POSIBILIDAD DE DICHOS
      DAÑOS, QUE SURJAN DE UNA GARANTÍA, CONTRATO, NEGLIGENCIA, RESPONSABILIDAD
      CIVIL EXTRACONTRACTUAL U OTRA ACCIÓN QUE DE CUALQUIER MANERA SURJA DE O EN
      RELACIÓN CON EL USO, INCAPACIDAD DE USO O RENDIMIENTO DE LOS SERVICIOS HTS
      CONNECT. HOPPER NO ASUME NINGUNA RESPONSABILIDAD DE NINGUNA MANERA QUE
      SURJA DE O EN RELACIÓN CON CUALQUIER INFORMACIÓN, CONTENIDO, PRODUCTOS,
      VIAJES AÉREOS U OTRO SERVICIO O MATERIAL DE VIAJE DISPONIBLE EN O A TRAVÉS
      DE LOS SERVICIOS HTS CONNECT, O A TRAVÉS DE PÁGINAS DE SITIOS WEB DE
      TERCEROS O SITIOS WEB ADICIONALES VINCULADOS A LOS SERVICIOS HTS CONNECT.
      ESTAS LIMITACIONES SE APLICARÁN A PESAR DE CUALQUIER FALLA EN EL PROPÓSITO
      ESENCIAL DE CUALQUIER RECURSO LIMITADO. EXCEPTO DONDE LO PROHÍBAN LAS
      LEYES, EN NINGÚN CASO LA RESPONSABILIDAD TOTAL DE HOPPER ANTE USTED POR
      CUALQUIER DAÑO, PÉRDIDA Y/O CAUSA DE ACCIÓN, YA SEA POR GARANTÍA, CONTRATO
      O RESPONSABILIDAD EXTRACONTRACTUAL, PODRÁ EXCEDER (A) EL MONTO PAGADO POR
      USTED A HOPPER O A UN PROVEEDOR EXTERNO DE SERVICIOS DE VIAJES A TRAVÉS DE
      LOS SERVICIOS HTS CONNECT, SI LOS HUBIERA, O (B) USD 1000 (LO QUE SEA
      MAYOR). EN NINGÚN CASO HOPPER SERÁ RESPONSABLE DE CUALQUIER INCUMPLIMIENTO
      DE ESTAS CLÁUSULAS O DE LA FALLA EN EL SUMINISTRO DE LOS SERVICIOS HTS
      CONNECT, EN LA MEDIDA EN QUE DICHO INCUMPLIMIENTO SURJA DE FACTORES FUERA
      DEL CONTROL RAZONABLE DE HOPPER.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      10.2. DEBIDO A QUE ALGUNAS JURISDICCIONES NO PERMITEN LA EXENCIÓN O
      LIMITACIÓN DE LA RESPONSABILIDAD POR DAÑOS Y PERJUICIOS, EN DICHAS
      JURISDICCIONES LA RESPONSABILIDAD ESTARÁ LIMITADA HASTA LA MAYOR MEDIDA
      PERMITIDA POR LA LEY.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      10.3. USTED ACEPTA QUE LAS EXCLUSIONES DE GARANTÍA Y LAS EXENCIONES DE
      RESPONSABILIDAD EN ESTA SECCIÓN SON ESENCIALES, QUE HAN SIDO NEGOCIADAS Y
      ACEPTADAS POR LAS PARTES, QUE SE HAN TENIDO EN CUENTA PARA DETERMINAR LA
      CONTRAPRESTACIÓN QUE DEBE DAR CADA PARTE EN EL PRESENTE DOCUMENTO Y EN LA
      DECISIÓN DE CADA PARTE DE CONTRATAR CON LA OTRA. USTED ACEPTA QUE LAS
      EXENCIONES Y LIMITACIONES DE RESPONSABILIDAD DE LA GARANTÍA DE ACUERDO CON
      ESTOS TÉRMINOS DE SERVICIO SON JUSTAS Y RAZONABLES. SALVO QUE SE DISPONGA
      LO CONTRARIO EN ESTA SECCIÓN, SU ÚNICA Y EXCLUSIVA ACCIÓN O RECURSO SERÁ
      DEJAR DE UTILIZAR LOS SERVICIOS HTS CONNECT.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      10.4. NOTA IMPORTANTE PARA LOS CONSUMIDORES DE NUEVA JERSEY: SI USTED ES
      UN CONSUMIDOR RESIDENTE EN NUEVA JERSEY, LAS SIGUIENTES DISPOSICIONES DE
      ESTAS CONDICIONES DE USO NO SE APLICAN A USTED (Y NO LIMITAN NINGÚN
      DERECHO QUE PUEDA TENER) EN LA MEDIDA EN QUE NO PUEDAN CUMPLIRSE DE
      CONFORMIDAD CON LAS LEYES DE NUEVA JERSEY: (A) LA EXENCIÓN DE
      RESPONSABILIDAD POR CUALQUIER DAÑO Y PERJUICIO, EMERGENTE, ESPECIAL,
      INCIDENTAL, PUNITIVO, EJEMPLAR O CONSECUENTE DE CUALQUIER TIPO (POR
      EJEMPLO, EN LA MEDIDA EN QUE NO SE PUEDAN CUMPLIR CONFORME A LA LEY DE
      DAÑOS PUNITIVOS DE NUEVA JERSEY, LEY DE RESPONSABILIDAD DE PRODUCTOS DE
      NUEVA JERSEY, CÓDIGO COMERCIAL UNIFORME DE NUEVA JERSEY Y LEY DE FRAUDE AL
      CONSUMIDOR DE NUEVA JERSEY); (B) LA LIMITACIÓN DE RESPONSABILIDAD POR
      PÉRDIDA DE GANANCIAS O POR PÉRDIDA O USO INDEBIDO DE CUALQUIER DATO
      PERSONAL (POR EJEMPLO, EN LA MEDIDA EN QUE NO SE PUEDA HACER CUMPLIR
      CONFORME A LA LEY DE PROTECCIÓN CONTRA ROBO DE IDENTIDAD DE NUEVA JERSEY Y
      LA LEY DE FRAUDE AL CONSUMIDOR DE NUEVA JERSEY); (C) APLICACIÓN DE LAS
      LIMITACIONES DE RESPONSABILIDAD A LA RECUPERACIÓN DE DAÑOS Y PERJUICIOS
      QUE SURJAN BAJO CONTRATO Y RESPONSABILIDAD EXTRACONTRACTUAL, INCLUIDOS,
      ENTRE OTROS, NEGLIGENCIA, RESPONSABILIDAD OBJETIVA O CUALQUIER OTRA TEORÍA
      (POR EJEMPLO, EN LA MEDIDA EN QUE DICHOS DAÑOS Y PERJUICIOS SEAN
      RECUPERABLES POR UN CONSUMIDOR CONFORME A LAS LEYES DE NUEVA JERSEY,
      INCLUIDAS, ENTRE OTROS, LA LEY DE RESPONSABILIDAD DE PRODUCTOS DE NUEVA
      JERSEY); Y (D) LA DISPOSICIÓN DE LA LEY DE MASSACHUSETTS VIGENTE (POR
      EJEMPLO, EN LA MEDIDA EN QUE SUS DERECHOS COMO CONSUMIDOR QUE RESIDE EN
      NUEVA JERSEY DEBAN REGIRSE POR LA LEY DE NUEVA JERSEY).
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      10.5. Con respecto a cualquier transacción fraudulenta o no autorizada en
      su nombre realizada mientras utiliza la Solución de HTS Connect, en
      ausencia de cualquier violación de estos Términos por su parte, su derecho
      al reembolso por cualquier pérdida directa (es decir, pérdidas no
      reembolsadas por su entidad emisora del Instrumento de Pago) como se
      establece en este documento será su único y exclusivo derecho y recurso
      ante cualquier pérdida o daño incurrido por usted que surja de o en
      relación con cualquier uso fraudulento o no autorizado de la Solución HTS
      Connect. Asimismo, Hopper puede condicionar el pago de cualquier fraude o
      reembolso de uso no autorizado a la exención de Hopper de cualquier
      reclamo adicional con respecto a los mismos.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      10.6. Cualquier acción o procedimiento relacionado con cualquier
      reclamación que surja de los servicios de HTS Connect deberá realizarse de
      conformidad con la Sección 13.1 dentro del plazo más breve de acuerdo a la
      prescripción aplicable o un año después de que se haya originado la causa
      de la acción, o se renunciará a ella.
    </Typography>
    <Typography
      variant="body2"
      className={clsx(styles.bold, styles.mb, styles.center)}
    >
      11. Indemnización
    </Typography>
    <Typography variant="body2" className={styles.mb}></Typography>
    <Typography variant="body2" className={styles.mb}>
      11.1. Si utiliza los servicios de HTS Connect con fines comerciales y/o
      para realizar reservaciones de viajes en nombre de cualquier tercero,
      acepta eximir y liberar de responsabilidad a Hopper y a sus funcionarios,
      directores, empleados, agentes y compañías afiliadas contra cualquier
      reclamación, demanda, procedimiento, disputa, recurso, responsabilidad,
      daños y perjuicios, pérdidas, costos y gastos, incluidos los honorarios
      legales y contables razonables, causados por, que surjan de, o que estén
      relacionados con su uso de los servicios de HTS Connect, el incumplimiento
      de estos Términos o el uso de cualquier producto o servicio comprado a
      través de los servicios de HTS Connect.
    </Typography>
    <Typography
      variant="body2"
      className={clsx(styles.bold, styles.mb, styles.center)}
    >
      12. RESOLUCIÓN DE DISPUTAS; ARBITRAJE; RENUNCIA DE DERECHOS
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      12.1.{" "}
      <span className={styles.underline}>
        ARBITRAJE Y RENUNCIA A LA ACCIÓN DE CLASE
      </span>
      . Usted acepta que todas las reclamaciones, disputas o desacuerdos que
      puedan surgir de la interpretación o el cumplimiento de estos Términos,
      cualquier pago a Hopper, o que estén relacionados de alguna manera con su
      uso de los servicios de HTS Connect y/o el contenido de los mismos, se
      someterán exclusivamente a arbitraje vinculante, excepto que cada parte
      conserve: (i) el derecho a presentar una acción individual ante un
      tribunal de causas menores por disputas y acciones dentro del alcance de
      la jurisdicción de dicho tribunal; y (ii) el derecho a solicitar medidas
      cautelares u otras medidas de equidad ante un tribunal competente para
      evitar una infracción, apropiación indebida o infracción real o amenaza de
      los derechos de autor, marcas comerciales, secretos comerciales, patentes
      u otros derechos de propiedad intelectual de una parte. El arbitraje
      significa que un árbitro y no un juez o jurado decidirá la reclamación.
      Los derechos al intercambio de información previas a la audiencia y las
      apelaciones pueden verse limitadas en el arbitraje. USTED ACEPTA Y
      RECONOCE QUE TANTO USTED COMO HOPPER RENUNCIAN AL DERECHO A UN JUICIO CON
      JURADO. Además, usted reconoce y acepta que renuncia a su derecho a
      participar como demandante o miembro de una clase en cualquier acción de
      clase o procedimiento similar. Asimismo, salvo que tanto usted como Hopper
      acuerden lo contrario por escrito, el árbitro no podrá unificar las
      pretensiones de más de una persona y no podrá participar de ninguna manera
      en una acción de clase o procedimiento similar. Si esta renuncia a la
      acción de clase fuera considera inaplicable, entonces la totalidad de esta
      sección de “Resolución de disputas” se considerará nula. Con la salvedad
      establecida en la oración precedente, esta cláusula de “Resolución de
      disputas” continuará vigente tras la extinción o rescisión de estos
      Términos.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      12.2. <span className={styles.underline}>Reglas de arbitraje</span>.
      Cualquier arbitraje será administrado por la American Arbitration
      Association (“AAA”). Excepto por lo modificado por esta disposición de
      “Resolución de disputas”, la AAA administrará el arbitraje de acuerdo con
      (A) las Reglas de Arbitraje Comercial vigentes en ese momento, o (B) las
      Reglas de Arbitraje del Consumidor vigentes si el asunto involucra a un
      “contrato de consumo” como se define en las Reglas de Arbitraje del
      Consumidor R-1 (en su conjunto, las “Reglas Aplicables de la AAA”). (Las
      Reglas Aplicables de la AAA están disponibles en{" "}
      <a href="https://www.adr.org/Rules" target="_blank" rel="noreferrer">
        https://www.adr.org/Rules
      </a>{" "}
      o llamando a la AAA al 1-800-778-7879). La Ley Federal de Arbitraje regirá
      la interpretación y aplicación de esta cláusula de “Resolución de
      disputas”.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      12.3. <span className={styles.underline}>Proceso de arbitraje</span>. La
      parte que desee iniciar el arbitraje debe proporcionar a la otra parte una
      demanda de arbitraje por escrito como se especifica en las Reglas de la
      AAA. (La AAA proporciona formularios aplicables para las Demandas de
      Arbitraje en{" "}
      <a
        href="https://www.adr.org/sites/default/files/Demand_for_Arbitration_0.pdf"
        target="_blank"
        rel="noreferrer"
      >
        https://www.adr.org/sites/default/files/Demand_for_Arbitration_0.pdf
      </a>{" "}
      (Reglas de Arbitraje Comercial) y{" "}
      <a
        href="https://www.adr.org/sites/default/files/Consumer_Demand_for_Arbitration_Form_1.pdf"
        target="_blank"
        rel="noreferrer"
      >
        https://www.adr.org/sites/default/files/Consumer_Demand_for_Arbitration_Form_1.pdf
      </a>{" "}
      (Reglas de Arbitraje del Consumidor), y una declaración jurada separada
      para la exención de tarifas solo para residentes de California está
      disponible en{" "}
      <a
        href="https://www.adr.org/sites/default/files/AAA%20Affidavit%20for%20Waiver%20of%20Fees%20Notice%20California%20Consumers.pdf"
        target="_blank"
        rel="noreferrer"
      >
        https://www.adr.org/sites/default/files/AAA%20Affidavit%20for%20Waiver%20of%20Fees%20Notice%20California%20Consumers.pdf.
      </a>{" "}
      ) El árbitro será o bien un juez jubilado o bien un abogado con licencia
      vigente para el ejercicio de la abogacía en el condado en el que reside y
      será seleccionado de acuerdo con las Reglas de la AAA. Si las partes no
      pueden ponerse de acuerdo en el nombramiento de un árbitro dentro de los
      catorce (14) días posteriores a la notificación de la AAA a las partes de
      la recepción de la Demanda de Arbitraje, entonces la AAA nombrará al
      árbitro de acuerdo con las Reglas de la AAA.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      12.4.{" "}
      <span className={styles.underline}>
        Lugar y procedimiento del arbitraje
      </span>
      . A menos que Hopper y usted acuerden lo contrario, el arbitraje se
      llevará a cabo en el condado donde usted reside. Si su reclamación no
      excede los USD 10 000, el arbitraje se llevará a cabo únicamente sobre la
      base de los documentos que Hopper y usted presenten al árbitro, a menos
      que usted solicite una audiencia o que el árbitro determine que una
      audiencia es necesaria. Si su reclamo excede de USD 10 000, su derecho a
      solicitar una audiencia quedará encuadrado por las Reglas de la AAA. Con
      sujeción a estas Reglas, el árbitro podrá instruir un intercambio
      razonable de información entre las Partes, en consonancia con la celeridad
      requerida por el arbitraje.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      12.5. <span className={styles.underline}>Decisión del árbitro</span>. El
      árbitro dictará el laudo dentro los plazos especificados en las Reglas de
      la AAA. El laudo arbitral incluirá las determinaciones y conclusiones en
      las que se fundamenta. El laudo podrá ser registrado en cualquier tribunal
      competente. La determinación de los daños y perjuicios debe estar de
      acuerdo con la cláusula de “Limitación de responsabilidad” precedente en
      lo que respecta a los tipos y montos en concepto de daños y perjuicios por
      cuales puede hacerse responsable a una de las partes. El árbitro solo
      podrá conceder medidas declarativas o cautelares a favor del demandante y
      solo en la medida necesaria para proporcionar la reparación que justifique
      la demanda individual del demandante. Si usted prevalece en el arbitraje y
      solo si le autoriza la ley aplicable, el árbitro podrá ordenar el pago de
      los honorarios y las costas judiciales que sean razonables. Si Hopper
      prevalece en el arbitraje, Hopper por la presente renuncia y no hará valer
      todos los derechos que Hopper pudiera tener de acuerdo con la ley
      aplicable para recuperar los honorarios y las costas judiciales, a menos
      que el árbitro encuentre que la sustancia de su reclamo o la reparación
      solicitada en su Demanda de Arbitraje fue frívola o se presentó con un
      propósito indebido (según lo determinado por los estándares de la Regla
      Federal de Procedimiento Civil 11(b) de los EE. UU.).
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      12.6. <span className={styles.underline}>Honorarios</span>. Su
      responsabilidad de pagar la petición a AAA, los gastos administrativos y
      de arbitraje estará limitado a lo establecido en las Reglas de la AAA. Si
      su reclamación por daños y perjuicios no excede de USD 25 000, Hopper
      pagará todos los honorarios a menos que el árbitro encuentre que la
      sustancia de su reclamo o la reparación solicitada en su Demanda de
      Arbitraje fue frívola o se presentó con un propósito indebido (según lo
      determinado por los estándares de la Regla Federal de Procedimiento Civil
      11(b) de los EE. UU.).
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      12.7. <span className={styles.underline}>Modificaciones</span>. Hopper se
      reserva el derecho de modificar esta sección de “Resolución de disputas”.
      Sin embargo, dichas modificaciones no se aplicarán a las disputas que
      surjan antes de la fecha de vigencia de dicha modificación. Sin perjuicio
      de las disposiciones anteriores relativas a las modificaciones, si Hopper
      modifica esta sección de “Resolución de disputas” después de la fecha en
      que aceptó por primera vez los Términos (o aceptó cualquier cambio
      posterior a dichos Términos), acepta que su uso continuado de los
      servicios de HTS Connect después de dicho cambio se considerará como
      aceptación de dichas modificaciones. Si no está de acuerdo con dicha
      modificación, podrá rechazarla enviando una notificación por escrito a
      Hopper, por correo o entrega en mano a: Hopper, Atención: División Legal,
      265 Franklin Street, Suite 1702, Boston, MA 02110 o por correo electrónico
      desde la dirección de correo electrónico asociada con su cuenta a:
      legal@hopper.com, dentro de los 30 días posteriores a la fecha en que
      dicha modificación entró en vigencia, como se indica en este documento.
      Para que sea efectiva, la notificación debe incluir su nombre completo e
      indicar claramente su intención de rechazar las modificaciones a la
      sección de “Resolución de disputas”. Al rechazar las modificaciones, usted
      acepta que cualquier disputa entre usted y Hopper, de acuerdo con las
      disposiciones de esta sección de “Resolución de disputas”, a partir de la
      fecha en que aceptó por primera vez estos Términos (o aceptó cualquier
      modificación posterior a estos Términos, según aplique) será resuelta
      mediante arbitraje.
    </Typography>
    <Typography
      variant="body2"
      className={clsx(styles.bold, styles.mb, styles.center)}
    >
      13. Términos adicionales
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      13.1. Este contrato constituye el acuerdo completo entre las partes en
      relación con el objeto del mismo y sustituye a todos los acuerdos y
      compromisos anteriores, tanto escritos como orales. Los Términos se
      regirán por las leyes del Estado de Massachusetts con exclusión de los
      principios de derecho internacional privado, salvo que se disponga lo
      contrario de acuerdo con la Sección 13 anterior. Ningún retraso u omisión
      por parte de Hopper en el ejercicio de cualquiera de sus derechos en caso
      de incumplimiento por su parte de estos Términos perjudicará dichos
      derechos o se interpretará como una renuncia a los mismos. La renuncia por
      parte de Hopper a cualquiera de los convenios, condiciones o acuerdos que
      usted debe cumplir no se interpretará como una renuncia ante un
      incumplimiento futuro de otro convenio, condición o acuerdo contenido en
      el presente. Usted no puede ceder ninguno de sus derechos emanados de
      estos Términos sin el consentimiento por escrito de Hopper. Salvo lo
      dispuesto en la Sección 13 anterior (Resolución de disputas), si un
      tribunal competente determina que alguna disposición de estos Términos es
      inválida o inaplicable, estos Términos conservarán pleno vigor y efecto y
      se reformarán para que sean válidos y aplicables, al tiempo que reflejan
      la intención de las partes en la mayor medida permitida por la ley. Estos
      Términos se establecen para su beneficio y el de Hopper. Ningún tercero
      podrá beneficiarse de estos Términos. En caso de conflicto entre estos
      Términos y cualquier otro acuerdo, condiciones o política que Hopper le
      proporcione, directa o indirectamente, estos Términos prevalecerán.
    </Typography>
    <Typography
      variant="body2"
      className={clsx(styles.bold, styles.mb, styles.center)}
    >
      14. Idioma
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      14.1 Usted y Hopper han acordado que los Términos principales y los
      acuerdos relacionados entre usted y Hopper, y todos los documentos
      relacionados, se redacten en inglés.{" "}
      <span className={styles.bold}>
        Aunque esta versión está en español, solo se ha traducido el original
      </span>
      .
    </Typography>
    <Typography
      variant="body2"
      className={clsx(styles.bold, styles.mb, styles.center)}
    >
      15. Actualizaciones y modificaciones
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      15.1 Hopper puede realizar modificaciones o actualizaciones a estos
      Términos en cualquier momento, a su discreción. Usted acepta que si no
      está de acuerdo con dicha modificación, no deberá reservar a través de los
      servicios de HTS Connect. Su incumplimiento, o el uso continuado de
      cualquier Servicio HTS Connect, constituirá la aceptación de estos
      Términos tal como fueron modificados, excepto según lo dispuesto en la
      Sección 13.7.
    </Typography>
    <Typography variant="body2" className={styles.mb}>
      Fecha de vigencia de estos Términos de servicio: Versión 1.1; Agosto 16,
      2024
    </Typography>
  </>
);

import { trackEvent } from "@hopper-b2b/api";
import { EDIT_TRAVELERS } from "@hopper-b2b/types";

export const trackEditTravelers = () =>
  new Promise((resolve, _reject) => {
    trackEvent({
      eventName: EDIT_TRAVELERS,
      properties: {},
    })
      .then(() => resolve(null))
      .catch(() => resolve(null));
  });

export * from "./restrictions/fixtures";
export * from "./shopping/fixtures";

import { Gender, PassengerTypeEnum } from "../common";
import { ItineraryData, ItineraryRouteData, Passenger } from "./interfaces";

// TODO: add proper logic to convert a FOFOResponse to ItineraryData
// const getItineraryData = (itinerary: FOFOResponse) => {
export const getItineraryData = (): ItineraryData => {
  return {
    outboundFlights: [
      {
        departureDateTime: "2020-08-08T23:20:00",
        arrivalDateTime: "2020-08-09T12:40:00",
        origin: "Newark",
        originAirportCode: "EWR",
        destination: "Paris",
        destinationAirportCode: "ORY",
        airlineCode: "IB",
        airlineName: "Iberia Airlines",
        flightNumber: "IB2690",
        flightDuration: "4h",
        layoverDuration: "2h 30m",
      },
    ],
    returnFlights: [
      {
        departureDateTime: "2020-08-16T12:10:00",
        arrivalDateTime: "2020-08-16T20:40:00",
        origin: "Paris",
        originAirportCode: "ORY",
        destination: "Madrid",
        destinationAirportCode: "MAD",
        airlineCode: "IB",
        airlineName: "Iberia Airlines",
        flightNumber: "IB433",
        flightDuration: "10h",
        layoverDuration: "2h 30m",
      },
      {
        departureDateTime: "2020-08-17T22:50:00",
        arrivalDateTime: "2020-08-18T07:10:00",
        origin: "Madrid",
        originAirportCode: "MAD",
        destination: "New York City",
        destinationAirportCode: "JFK",
        airlineCode: "IB",
        airlineName: "Iberia Airlines",
        flightNumber: "IB207",
        flightDuration: "10h",
      },
    ],
  };
};

export const getItineraryRouteData = (): ItineraryRouteData => ({
  outboundFlights: [
    {
      departureDateTime: "2020-08-08T23:20:00",
      origin: "Newark",
      destination: "Paris",
    },
  ],
  returnFlights: [
    {
      departureDateTime: "2020-08-16T12:10:00",
      origin: "Paris",
      destination: "Madrid",
    },
  ],
});

export const passengers: Passenger[] = [
  {
    name: "Sydney Crosby",
    dateOfBirth: "1986-03-19",
    gender: Gender.M,
    paxType: PassengerTypeEnum.Adult,
  },
  {
    name: "Alice Crosby",
    dateOfBirth: "2010-03-12",
    gender: Gender.F,
    paxType: PassengerTypeEnum.Child,
  },
];

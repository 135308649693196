import { NETWORK_CALL_FAILED, IApiConfig } from "@hopper-b2b/types";
import {
  cardApi,
  Failure,
  PaymentVerifyResultResponse,
  ResponseEnum,
  VerifyPaymentMethodRequest,
} from "@b2bportal/card-api";
import { axiosInstance } from "../AxiosInterceptor";
import { trackEvent } from "../tracking/trackEvent";

export const verifyPaymentMethod = (
  req: VerifyPaymentMethodRequest,
  apiConfig?: IApiConfig
): Promise<PaymentVerifyResultResponse> =>
  new Promise((resolve, reject) => {
    cardApi(axiosInstance)
      .apiV0PaymentMethodsVerifyPost(req)
      .then((res) => {
        const responseBody: PaymentVerifyResultResponse = res.data;
        if (responseBody.Response === ResponseEnum.Failure) {
          reject((responseBody as Failure).errors);
        } else {
          resolve(responseBody);
        }
      })
      .catch((e) => {
        trackEvent(
          {
            eventName: NETWORK_CALL_FAILED,
            properties: {
              url: window.location.pathname,
            },
          },
          apiConfig
        );
        reject(e);
      });
  });

import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import "./styles.scss";

export enum BannerSeverity {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
  NOTICE = "notice",
  IMPROVEMENT = "improvement",
}

export interface NotificationBannerProps {
  icon?: JSX.Element;
  strongLabel?: string;
  label?: string;
  className?: string;
  cta?: string;
  html?: string;
  content?: JSX.Element;
  severity?: BannerSeverity;
  onClick?: () => void;
}

export const NotificationBanner = ({
  className,
  icon,
  label,
  cta,
  html,
  content,
  severity = BannerSeverity.INFO,
  onClick,
  strongLabel,
}: NotificationBannerProps): JSX.Element => {
  return (
    <Box
      className={clsx("notification-banner", className, severity)}
      onClick={onClick}
      role="alert"
    >
      {icon && <Box className="notification-icon">{icon}</Box>}
      {content ? (
        content
      ) : html ? (
        <Typography
          className="label"
          variant="subtitle1"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      ) : strongLabel ? (
        <Typography className="label" variant="subtitle1">
          <strong>{strongLabel}</strong> {label}
        </Typography>
      ) : (
        <Typography className="label" variant="subtitle1">
          {label}
          <span className="cta">{cta}</span>
        </Typography>
      )}
    </Box>
  );
};

export const UNAVAILABLE = "Unavailable";
export const EDIT = "Edit";
export const EDIT_SEAT = "Edit Seat";
export const EDIT_SEATS = "Edit Seats";
export const SELECTION_UNAVAILABLE = "Selection Unavailable";
export const SEAT_SELECTION_UNAVAILABLE = "Seat Selection Unavailable";
export const NOT_SELECTED = "Not Selected";
export const SEAT_NOT_SELECTED = "Seat Not Selected";
export const SELECTION_NOT_AVAIL_TOOLTIP =
  "Seat selection is currently unavailable for this segment of your journey.";
export const NOT_SELECTED_TOOLTIP =
  "You have not selected seats for this passenger and flight. Edit your selection to add seats to your journey.";

import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import clsx from "clsx";
import "./styles.scss";
import { useState } from "react";

export interface SwitchButtonProps {
  checked?: boolean;
  onClick?: () => void;
  className?: string;
  content?: string;
}

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

const B2BSwitch = withStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: 64,
      height: 32,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(32px)",
        color: "#fff",
        "& + $track": {
          backgroundColor: "#000000",
          opacity: 1,
        },
      },
      "&$focusVisible $thumb": {
        color: "#D8D8D8",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 30,
      height: 30,
    },
    track: {
      borderRadius: 30 / 2,
      backgroundColor: `#D8D8D8`,
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  });
})(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const SwitchButton = ({
  checked = false,
  content,
  onClick,
  className,
}: SwitchButtonProps) => {
  const [toggle, setToggle] = useState(checked);
  return (
    <button className={clsx("switch-button", className)} aria-label={content}>
      <B2BSwitch
        checked={toggle}
        tabIndex={-1}
        onClick={() => {
          setToggle(!toggle);
          onClick();
        }}
      />
    </button>
  );
};

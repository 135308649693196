export interface Language {
  key: string;
  name: string;
  label?: string;
}

export enum TranslationLanguage {
  en = "en",
  "en-US" = "en-US",
  "en-AU" = "en-AU",
  "en-GB" = "en-GB",
  "en-SG" = "en-SG",
  es = "es",
  "es-MX" = "es-MX",
  fr = "fr",
  pt = "pt",
  ja = "ja",
  ar = "ar",
  "zh-Hans" = "zh-Hans",
  "zh-Hant" = "zh-Hant",
}

export type Translation = {
  [key in TranslationLanguage]?: unknown;
};

import { SeatMapResponseEnum } from "@b2bportal/air-booking-api";
import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { FlightContext } from "../../types";

export const guards = {
  hasSeatMapAvailability: (ctx: FlightContext, _event: unknown) =>
    ctx[ParentState.seatSelection].seatMapAvailable ===
    SeatMapResponseEnum.SeatMapAvailable,
  hasNoSeatMapAvailability: (ctx: FlightContext, _event: unknown) =>
    ctx[ParentState.seatSelection].seatMapAvailable ===
    SeatMapResponseEnum.SeatMapUnavailable,
  hasSeats: (ctx: FlightContext, _event: unknown) =>
    ctx[ParentState.seatSelection].selectedSeats.length > 0,
  notVisitedSeats: (ctx: FlightContext, _event: unknown) =>
    !ctx[ParentState.seatSelection].visited,
  notSkippedSeatSelection: (ctx: FlightContext, _event: unknown) =>
    !ctx[ParentState.seatSelection].skipSeatSelection,
};

export const GuardTypes = getObjectKeysAsObject(guards);

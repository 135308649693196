import { Button, ButtonProps } from "@material-ui/core";
import clsx from "clsx";

// eslint-disable-next-line @nx/enforce-module-boundaries
import { IActionButtonProps } from "libs/ui/core/src/lib/ActionButton";

export const ActionButton = ({
  className,
  fill,
  message,
  icon,
  variant,
  defaultStyle,
  disableElevation = true,
  size = "large",
  submit = false,
  ...rest
}: IActionButtonProps & ButtonProps) => {
  const buttonVariant = defaultStyle
    ? defaultStyle === "h4r-primary"
      ? "contained"
      : defaultStyle === "h4r-secondary"
      ? "outlined"
      : "contained"
    : "contained";
  return (
    <Button
      className={clsx(
        "action-button",
        className,
        defaultStyle,
        fill,
        "hopper-button",
        defaultStyle === "h4r-secondary" ? "secondary" : "primary",
        size ? size : "large"
      )}
      variant={variant ?? buttonVariant}
      color="primary"
      size={size}
      disableElevation={disableElevation}
      startIcon={icon}
      {...(submit ? { type: "submit" } : {})}
      {...rest}
    >
      {message}
    </Button>
  );
};

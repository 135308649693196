import { assign } from "xstate";
import { getEnvVariables } from "@hopper-b2b/utilities";
import { cartHelpers, ParentState } from "../../../index";
import {
  getHasPriceChanged,
  getObjectKeysAsObject,
  setContextWithKey,
} from "../../../helpers";
import type { HotelsPriceFreezeMachineContext } from "./types";
import type { UpdateSelectedHotelsPriceFreezeOffer } from "./events";
import { Product, type ProductOpaqueValue } from "@b2bportal/purchase-api";
import {
  getFrozenProductQuotedPrice,
  getFrozenProductShoppedPrice,
} from "./selectors";

export const actions = {
  updateSelectedPriceFreezeOffer: assign(
    (
      context: HotelsPriceFreezeMachineContext,
      event: UpdateSelectedHotelsPriceFreezeOffer
    ) => {
      const currentSelectedOffer = context.priceFreeze.selectedOffer;
      const selectedOffer = context.priceFreeze.priceFreezeOffers.find(
        (offer) =>
          offer.serviceDetails.serviceDurationDays ===
          event.selectedOfferDuration
      );
      return setContextWithKey(
        context,
        `${ParentState.priceFreeze}.selectedOffer`,
        selectedOffer ? selectedOffer : currentSelectedOffer
      );
    }
  ),
  addHotelPriceFreezeProduct: assign(
    (context: HotelsPriceFreezeMachineContext, _event: unknown) => {
      const selectedOfferId = context.priceFreeze.selectedOffer?.offerId;
      const email = context.sessionInfo.userInfo.email;

      const priceFreezeProduct: ProductOpaqueValue = {
        type: Product.PriceFreeze,
        value: { offerId: selectedOfferId },
      };
      const ctxWithPriceFreeze = cartHelpers.addQuoteProduct(
        priceFreezeProduct,
        context
      );

      // Quoting request requires customer email in the cartQuote context
      return setContextWithKey(
        ctxWithPriceFreeze,
        `${ParentState.cartQuote}.customerEmail`,
        email
      );
    }
  ),
  checkForFrozenProductPriceChange: assign(
    (context: HotelsPriceFreezeMachineContext, _event) => {
      const frozenProductShoppedPrice = getFrozenProductShoppedPrice({
        context,
      });
      const frozenProductQuotedPrice = getFrozenProductQuotedPrice({
        context,
      });

      const predictedTotal = frozenProductShoppedPrice
        ? frozenProductShoppedPrice.fiat.value
        : 0;

      const priceQuoteTotal = frozenProductQuotedPrice
        ? frozenProductQuotedPrice.fiat.value
        : 0;

      /**
       * In the past we only froze the price which came back from the "shop" call to Paris.
       * Now we are also attempting to run a provider quote on the underlying frozen hotel stay to get the most accurate price and availability.
       * If for some reason this fails, we want to allow the purchase on the shop price.
       */
      const difference = priceQuoteTotal
        ? Math.abs(priceQuoteTotal - predictedTotal)
        : 0;

      const isIncrease = priceQuoteTotal && priceQuoteTotal > predictedTotal;

      // Tenant defines allowed price change difference in respective .env file.
      const tenantDifferenceAllowed = getEnvVariables(
        "hotelPriceChangeDiff"
      ) as string;
      const allowed = tenantDifferenceAllowed.split(",");
      const allowedValue = allowed?.[0] ? parseInt(allowed[0], 10) : Infinity;
      const allowedCurrency = allowed?.[1] ? allowed[1] : "USD";
      const allowedDifference = {
        value: allowedValue,
        currencyCode: allowedCurrency,
      };

      const currencyCode = frozenProductShoppedPrice?.fiat?.currencyCode
        ? frozenProductShoppedPrice?.fiat?.currencyCode
        : "USD";

      const actualDifference = {
        value: difference,
        currencyCode: currencyCode,
      };

      const hasDifference = getHasPriceChanged(
        allowedDifference,
        actualDifference
      );

      const priceChange = {
        hasDifference,
        predictedTotal,
        priceQuoteTotal,
        difference,
        isIncrease,
      };

      return setContextWithKey(
        context,
        `${ParentState.cartQuote}.priceChange`,
        priceChange
      );
    }
  ),

  acknowledgePriceChange: assign((context: HotelsPriceFreezeMachineContext) => {
    return setContextWithKey(
      context,
      `${ParentState.cartQuote}.priceChange.acknowledged`,
      true
    );
  }),
};

export const ActionTypes = getObjectKeysAsObject(actions);

import { CipherText } from "@b2bportal/purchase-api";
import { discardQuotedCart } from "@hopper-b2b/api";
import { CartContext } from "../../context";

import { getPriceQuoteCipherText } from "../selectors";

export const discardQuotedCartService = (
  context: CartContext,
  _event: unknown
) =>
  new Promise((resolve) => {
    const cipherText = getPriceQuoteCipherText({ context });

    if (cipherText === undefined) {
      resolve(null);
    }

    discardQuotedCart(cipherText as CipherText)
      .then((_result: unknown) => {
        resolve(null);
      })
      .catch((e) => {
        resolve(null);
      });
  });

import { Divider, Grid } from "@material-ui/core";
import { IconName } from "../Icon";
import { IconComponent } from "../IconComponent";
import styles from "./FlightAirplaneIcon.module.scss";
import clsx from "clsx";

export type FlightAirplaneIconProps = {
  size?: "small" | "large";
  className?: string;
  icon?: JSX.Element;
};

export const FlightAirplaneIcon = ({
  size,
  className,
  icon = null,
}: FlightAirplaneIconProps) => (
  <Grid
    className={className}
    container
    alignItems="center"
    spacing={1}
    wrap="nowrap"
  >
    <Grid item>
      <Divider className={styles.divider} />
    </Grid>
    <Grid item>
      {icon ?? (
        <IconComponent
          name={IconName.B2BAirplaneIcon}
          className={clsx(styles.icon, styles[size])}
        />
      )}
    </Grid>
    <Grid item>
      <Divider className={styles.divider} />
    </Grid>
  </Grid>
);

import {
  Failure,
  PersonListResponse,
  PersonListSuccess,
} from "@b2bportal/air-booking-api";
import { fetchUserPassengers } from "@hopper-b2b/api";

import { PassengerErrorModalTypes } from "@checkout/types";

export const fetchUserPassengersService = () =>
  new Promise((resolve, reject) => {
    fetchUserPassengers({})
      .then((response: PersonListResponse) => {
        if ((response as Failure).errors) {
          reject({
            type: PassengerErrorModalTypes.FetchPassengers,
            data: (response as Failure).errors,
          });
        } else {
          resolve((response as PersonListSuccess).value);
        }
      })
      .catch((e) => {
        reject({
          type: PassengerErrorModalTypes.FetchPassengers,
          data: e,
        });
      });
  });

import { CardPayment, CreditCard } from "@b2bportal/card-api";
import { Payment, PaymentOpaqueValue } from "@b2bportal/purchase-api";
import { PartialParentContext, ParentState } from "@checkout/types";
import { ErrorCode, TokenizeCardErrors } from "@hopper-b2b/types";
import { RequiredField } from "@hopper-b2b/utilities";

export interface ICardPayment extends PaymentOpaqueValue {
  type: typeof Payment.Card;
  value: CardPayment;
}

export type ParentContextWithCardPayment = RequiredField<
  PartialParentContext,
  ParentState.cardPayment
>;

export enum PaymentErrorModalTypes {
  ListPaymentMethods = "ListPaymentMethods",
  VerifyPayment = "VerifyPayment",
  CardDeclined = "CardDeclined",
  ExcessiveUserActivity = "ExcessiveUserActivity",
  GenericError = "GenericError",
  DeletePayment = "DeletePayment",
  FetchNequiBalance = "FetchNequiBalance",
}

export interface PaymentError {
  type: PaymentErrorModalTypes;
  data: ErrorCode[] | TokenizeCardErrors[];
  paymentMethods?: CreditCard[];
}
export enum LoadingMessages {
  ADD = "paymentForm.addingPaymentMethod",
  DELETE = "paymentForm.removingPaymentMethod",
}

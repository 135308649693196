import { useEffect, useId } from "react";
import clsx from "clsx";
import {
  Dialog,
  DialogContent,
  Typography,
  DialogTitle,
  Box,
} from "@material-ui/core";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ActionLink } from "@hopper-b2b/ui";
import { ActionButton } from "@hopper-b2b/hopper-ui-core";
import {
  MODAL_ALERT,
  ModalAlertProperties,
  ModalCategoryType,
  ProductType,
} from "@hopper-b2b/types";
import { trackEvent } from "@hopper-b2b/api";

import styles from "./styles.module.scss";
import { IconName } from "../Icon";
import { IconComponent } from "../IconComponent";
import { Transition } from "../Transition";
import { images } from "../../assets/images";
import { ModalScreenTypes } from "../../modules/flight-checkout/types";

type ErrorModal = {
  open: boolean;
  onClose?: () => void;
  primaryButtonText: string;
  primaryOnClick: () => void;
  secondaryButtonText?: string;
  secondaryOnClick?: () => void;
  title?: string;
  subtitle?: string;
  trackingEventProperties?: ModalAlertProperties;
  productType?: ProductType;
  modalType?: string;
  imageSrc?: string;
  modalScreen?: ModalScreenTypes;
  modalCategory?: ModalCategoryType;
};

export function ErrorModal({
  open,
  onClose,
  title,
  subtitle,
  primaryButtonText,
  primaryOnClick,
  secondaryButtonText,
  secondaryOnClick,
  trackingEventProperties,
  productType,
  modalType,
  imageSrc = images.errorState,
  modalScreen,
  modalCategory = ModalCategoryType.TROUBLE,
}: ErrorModal) {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const titleId = useId();

  const hasSecondaryButton = secondaryButtonText && secondaryOnClick;

  useEffect(() => {
    if (open) {
      const modalEventProperties = {
        ...trackingEventProperties,
        primary_button: primaryButtonText,
        secondary_button: hasSecondaryButton ? secondaryButtonText : undefined,
        screen: modalScreen,
        category: modalCategory,
        product_type: productType,
        modal_type: modalType,
      };

      trackEvent({
        eventName: MODAL_ALERT,
        properties: modalEventProperties,
      });
    }
  }, [
    open,
    hasSecondaryButton,
    primaryButtonText,
    secondaryButtonText,
    trackingEventProperties,
    productType,
    modalType,
    modalScreen,
    modalCategory,
  ]);

  return (
    <Dialog
      maxWidth="xs"
      scroll="paper"
      aria-labelledby={titleId}
      fullScreen={matchesMobile}
      open={open}
      onClose={onClose}
      fullWidth
      className={clsx(styles.container, {
        [styles.mobileContainer]: matchesMobile,
      })}
      {...(matchesMobile ? { TransitionComponent: Transition } : null)}
    >
      <DialogTitle disableTypography>
        <section className={styles.upperSection}>
          {onClose && (
            <ActionLink
              className={styles.btnContainer}
              content={
                <IconComponent
                  ariaLabel={t("closeFiltersModal") as string}
                  name={IconName.CloseModal}
                  className={styles.closeBtn}
                />
              }
              onClick={onClose}
            />
          )}
          <img alt="error state" src={imageSrc} />
          <Typography className={styles.headerTitle} variant="h1" id={titleId}>
            {title || t("checkoutError.genericErrorTitle")}
          </Typography>
          <Typography className={styles.subtitle} variant="subtitle2">
            {subtitle || t("checkoutError.genericErrorSubtitle")}
          </Typography>
        </section>
      </DialogTitle>
      <DialogContent>
        <Box className={styles.contentSection}>
          <ActionButton
            className={styles.ctaBtnStyle}
            message={primaryButtonText}
            onClick={primaryOnClick}
          />
          {hasSecondaryButton && (
            <ActionButton
              defaultStyle="h4r-secondary"
              className={styles.ctaBtnStyle}
              message={secondaryButtonText}
              onClick={secondaryOnClick}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

import { IContactInfo } from "@checkout/index";
import { throttle } from "lodash-es";

const UPDATE_THROTTLE_DURATION = 1000;

type UpdateContactInfoArgs = Partial<IContactInfo> & {
  callback?: (contactInfo: Partial<IContactInfo>) => void;
};

export const updateContactInfo = throttle(
  ({ callback, ...contactInfo }: UpdateContactInfoArgs) => {
    callback?.(contactInfo);
  },
  UPDATE_THROTTLE_DURATION
);

import {
  airShoppingApi,
  TripDetailsV2,
  TripId,
} from "@b2bportal/air-shopping-api";
import axios from "axios";

import { AirPriceFreezeContext } from "../context";
import { getTripId } from "../selectors";

export const fetchTripDetails = (
  context: AirPriceFreezeContext,
  _event: unknown
) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const tripId = getTripId({ context });
    const req: TripId = { value: tripId };
    try {
      const res = await airShoppingApi(axios).apiV0TripDetailPost(req);
      resolve(res.data as TripDetailsV2);
    } catch (e) {
      reject(e);
    }
  });

import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";
import "./styles.scss";
import { Slot } from "../../../../../Slots";

export interface IPriceRangeTagsProps {
  priceTags: string[];
  currency?: string;
  className?: string;
}

export const PriceRangeTags = ({
  priceTags,
  className,
}: IPriceRangeTagsProps) => {
  const { t, brand } = useI18nContext();
  const legend = [
    t("calendarLegend.lowest"),
    t("calendarLegend.low"),
    t("calendarLegend.medium"),
    t("calendarLegend.high"),
  ];
  const highInflationCurrency = brand?.currency?.highInflationCurrency;

  return (
    <div className={clsx("price-range-tags-root", className)}>
      <div className="price-range-tags-container">
        <div className="price-tags-section">
          {priceTags?.map((priceTag: string, index: number) => {
            const isNotGoingToFit =
              highInflationCurrency || priceTag.length >= 6;
            return (
              <div key={index} className="price-tag-root">
                <Slot
                  id="price-range-tag"
                  value={
                    isNotGoingToFit
                      ? legend[index]
                      : priceTag.slice(0, -1) + "+"
                  }
                  index={index}
                  component={
                    <div
                      className={clsx(
                        "price-tag-container",
                        `price-index-${index}`
                      )}
                    >
                      {/* Do not render priceTags for higher inflation currency such as COP, as the values are too long to render */}
                      {isNotGoingToFit
                        ? legend[index]
                        : priceTag.slice(0, -1) + "+"}
                    </div>
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

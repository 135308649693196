import { Box } from "@material-ui/core";
import dayjs from "dayjs";
import { DateTime, SliceDirection, TripCategory } from "@hopper-b2b/types";
import "./styles.scss";

interface ISliceSummaryProps {
  departureTime: DateTime;
  direction: SliceDirection;
  destinationName: string;
  tripCategory: TripCategory;
}

type CategoryTextMapType = {
  [key in TripCategory]: string;
};

const categoryTextMap: CategoryTextMapType = {
  [TripCategory.ONE_WAY]: "One Way",
  [TripCategory.ROUND_TRIP]: "Round Trip",
};

export const SliceSummary = ({
  departureTime,
  direction,
  destinationName,
  tripCategory,
}: ISliceSummaryProps) => {
  return (
    <Box className="slice-summary" component="section">
      <h2>
        <em>{direction}</em> to {destinationName}
      </h2>
      <Box className="subheading">
        <time>{dayjs(departureTime).format("ddd, MMM D")}</time>
        <span>{categoryTextMap[tripCategory]}, Per Traveler</span>
      </Box>
    </Box>
  );
};

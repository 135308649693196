import { createContext, useEffect, useMemo, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import {
  CssBaseline,
  StylesProvider,
  ThemeProvider,
  createGenerateClassName,
} from "@material-ui/core";
import { merge } from "lodash-es";

import {
  AuthProvider,
  SessionInfo,
  useAuthProvider,
} from "@hopper-b2b/hopper-auth";
import {
  ITenantContextProps,
  TenantContextProvider,
  useWindowSize,
  UtmTrackingProvider,
} from "@hopper-b2b/utilities";
import I18nProvider, {
  en_defaultTranslations,
  en_volarisTranslations,
  es_defaultTranslations,
  es_volarisTranslations,
} from "@hopper-b2b/i18n";
import { installCssConfig } from "@hopper-b2b/themes";
import { apiConfig } from "@hopper-b2b/common-utils";
import { Translation, TranslationLanguage } from "@hopper-b2b/types";
import { ExperimentsProvider } from "@hopper-b2b/experiments";

import { App } from "./App";
import { lightModeIcons } from "./assets/icons";
import { branding } from "./modules/branding";
import { muiTheme, volarisVariables } from "./modules/defaultTheming";
import { DEFAULT_LANG } from "./utils/constants";
import AxiosInterceptorWrapper from "./components/AxiosInterceptorWrapper";
import { ExpiredSession } from "./components/ExpiredSession";

/**
 * @deprecated
 * @see AuthProvider
 */
type UserInfoContext = {
  sessionInfo: SessionInfo;
  updateSessionInfo: (info: SessionInfo) => void;
};
/**
 * @deprecated
 * @see AuthProvider
 */
export const UserContext = createContext<Partial<UserInfoContext>>({});

export enum FEATURE_FLAGS {
  MAINTENANCE = "volaris-maintenance",
  DEGRADED_PERFORMANCE = "volaris-degraded-performance",
  EXPIREMENT_ZERO_FARE_TARIFA_BASE = "ExpirementZeroFareTarifaBase",
  ENABLE_TOP_FLIGHTS = "EnableTopFlightsSection",
  ENABLE_REMOVE_FARE_DETAILS = "EnableRemoveFareDetails",
  MOBILE_PRICE_BREAKDOWN_CLOSE_ICON = "mobile-price-breakdown-close-icon",
  SEARCH_CONTROL_FORCE_CLEARING = "search-control-force-clearing",
  REMOVE_DATE_BAR_MOBILE_CALENDAR = "remove-date-bar-mobile-calendar",
}

const generateClassName = createGenerateClassName({
  productionPrefix: "ptBaseModule",
  seed: "ptBaseModule",
});

const setViewWidthAndHeight = (width: string, height: string) => {
  document.body.style.setProperty(`--vw`, width);
  document.body.style.setProperty(`--vh`, height);
};

export const Root = () => {
  return (
    <BrowserRouter>
      <CompatRouter>
        <AuthProvider>
          <AppWrapper />
        </AuthProvider>
      </CompatRouter>
    </BrowserRouter>
  );
};

const AppWrapper = () => {
  const [theme] = useState(muiTheme);
  const { state } = useAuthProvider();
  const windowSize = useWindowSize();

  useEffect(() => {
    // Add a variable for vh to use for specifying full-screen height
    // 100vh does not work properly on iOS. https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    setViewWidthAndHeight(
      `${windowSize.width * 0.01}px`,
      `${windowSize.height * 0.01}px`
    );
  }, [windowSize.height, windowSize.width]);

  useEffect(() => {
    installCssConfig(volarisVariables);
  }, []);

  const tenantContext: ITenantContextProps = useMemo(
    () => ({
      icons: lightModeIcons,
    }),
    []
  );

  const tenantTranslation: Translation = useMemo(() => {
    return {
      [TranslationLanguage.en]: merge(
        en_defaultTranslations,
        en_volarisTranslations
      ),
      [TranslationLanguage.es]: merge(
        es_defaultTranslations,
        es_volarisTranslations
      ),
    };
  }, []);

  return (
    <I18nProvider
      defaultLng={DEFAULT_LANG}
      branding={branding}
      tenantTranslation={tenantTranslation}
    >
      <UtmTrackingProvider>
        <StylesProvider generateClassName={generateClassName}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <TenantContextProvider tenantContext={tenantContext}>
              <ExperimentsProvider
                apiConfig={apiConfig}
                isLoggedIn={Boolean(state?.sessionInfo)}
              >
                <AxiosInterceptorWrapper />
                <App />
                <ExpiredSession />
              </ExperimentsProvider>
            </TenantContextProvider>
          </ThemeProvider>
        </StylesProvider>
      </UtmTrackingProvider>
    </I18nProvider>
  );
};

import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { PartialParentContextWithAirChfar } from "./types";

export const guards = {
  hasChfarIdAndEnabled: (
    ctx: PartialParentContextWithAirChfar,
    _event: unknown
  ) =>
    !!ctx[ParentState.changeForAnyReason]?.chfarQuoteId &&
    ctx.featureFlags?.enableChfar,
};

export const GuardTypes = getObjectKeysAsObject(guards);

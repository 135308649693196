import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { airBookingApi, PersonListResponse } from "@b2bportal/air-booking-api";
import { axiosInstance } from "../AxiosInterceptor";
import { trackEvent } from "../tracking/trackEvent";

export const fetchUserPassengers = (
  body?: Record<string, never>,
  apiConfig?: IApiConfig
): Promise<PersonListResponse> =>
  new Promise((resolve, reject) => {
    airBookingApi(axiosInstance)
      .apiV0FlightPassengersListPut(body)
      .then((res) => {
        const responseBody = res.data as PersonListResponse;
        resolve(responseBody);
      })
      .catch((e) => {
        trackEvent(
          {
            eventName: NETWORK_CALL_FAILED,
            properties: {
              url: window.location.pathname,
            },
          },
          apiConfig
        );
        reject(e);
      });
  });

import {
  ExperimentState,
  ExperimentsType,
  IApiConfig,
  TrackingPropertiesType,
} from "@hopper-b2b/types";
import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import { initExperimentState } from "./utils/initExperimentState";

export const HIDE_CC = "ctr-marketplace-hidecreditcard";

const defaultInitState: ExperimentState = {
  experiments: {},
};

export const ExperimentsContext =
  createContext<ExperimentState>(defaultInitState);

type ExperimentsProviderProps = {
  apiConfig: IApiConfig;
  initState?: ExperimentState;
  isLoggedIn?: boolean;
  partnerExperiments?: string;
};

export const ExperimentsProvider: FC<
  ExperimentsProviderProps & PropsWithChildren
> = ({
  apiConfig,
  initState = defaultInitState,
  isLoggedIn = false,
  children,
  partnerExperiments,
}) => {
  const [experiments, setExperiments] = useState<ExperimentsType>(
    initState.experiments
  );
  const [trackingProperties, setTrackingProperties] =
    useState<TrackingPropertiesType>(initState.trackingProperties ?? {});

  const shouldFetchExperiments = useMemo(
    (): boolean => !initState?.experiments?.size && isLoggedIn,
    [initState?.experiments?.size, isLoggedIn]
  );

  const value: ExperimentState = useMemo(
    () => ({ experiments, trackingProperties }),
    [experiments, trackingProperties]
  );

  useEffect(() => {
    initExperimentState(
      shouldFetchExperiments,
      apiConfig,
      defaultInitState,
      partnerExperiments
    ).then((newState: ExperimentState) => {
      setExperiments(
        (currentState: ExperimentsType) =>
          ({
            ...currentState,
            ...newState.experiments,
            ...(partnerExperiments ? { partnerExperiments } : {}),
          } as ExperimentsType)
      );
      setTrackingProperties(
        (currentState: ExperimentsType) =>
          ({
            ...currentState,
            ...newState.trackingProperties,
            ...(partnerExperiments ? { partnerExperiments } : {}),
          } as ExperimentsType)
      );
    });
  }, [apiConfig, shouldFetchExperiments, partnerExperiments, isLoggedIn]);

  return <ExperimentsContext.Provider value={value} children={children} />;
};

import { TripSegment } from "@hopper-b2b/types";
import { Typography } from "@material-ui/core";
import { Dayjs } from "dayjs";
import { ReactNode } from "react";
import { SliceToShow } from "../../../SliceDetails";

interface FlightTimeDetailsProps {
  flightType: "arrival" | "departure";
  hasScheduleChange: boolean;
  scheduledTime: Dayjs;
  segment: TripSegment;
  sliceToShow?: SliceToShow;
  timeFormat?: string;
  updatedTime?: Dayjs;
}

export const FlightTimeDetails: React.FC<FlightTimeDetailsProps> = ({
  flightType = "departure",
  hasScheduleChange,
  scheduledTime,
  segment: { originName, originCode, destinationName, destinationCode },
  sliceToShow = SliceToShow.original,
  timeFormat = "hh:mm A ddd DD MMM YYYY",
  updatedTime,
}) => {
  const isOutgoing = flightType === "departure";
  const cityName = isOutgoing ? originName : destinationName;
  const cityCode = isOutgoing ? originCode : destinationCode;
  const fmtScheduledTime = scheduledTime.format(timeFormat);
  let SegmentTime: ReactNode = <span>{fmtScheduledTime}</span>;

  if (hasScheduleChange) {
    const fmtUpdatedTime = updatedTime?.format(timeFormat);

    switch (sliceToShow) {
      case SliceToShow.both:
        SegmentTime = (
          <>
            <s>{fmtScheduledTime}</s>
            <b>{fmtUpdatedTime}</b>
          </>
        );
        break;
      case SliceToShow.original:
        SegmentTime = <s>{fmtScheduledTime}</s>;
        break;
      case SliceToShow.updated:
        SegmentTime = <b>{fmtUpdatedTime}</b>;
        break;
    }
  }

  return (
    <Typography className={`${flightType}-details`} variant="h6">
      {SegmentTime}
      <Typography variant="body1">{` - ${cityName} (${cityCode})`}</Typography>
    </Typography>
  );
};

import { validateUserPassengers } from "@hopper-b2b/api";
import {
  PassengersResponse,
  PassengersResponseEnum,
  PassengersOk,
  ProtectionId,
} from "@b2bportal/air-booking-api";

import {
  getSelectedLapInfantIdsParent,
  getSelectedPassengerIdsParent,
  getUserPassengersParent,
} from "../selectors";
import { PassengerErrorModalTypes } from "@checkout/types";
import { FlightContext } from "../../../types";
import { FlightSelectors } from "../../../index";

export const validateUserPassengersParentService = (context: FlightContext) =>
  new Promise((resolve, reject) => {
    const selectedTrip = FlightSelectors.getSelectedTripParent({
      context: context as any,
    });
    const passengers = getUserPassengersParent({ context });
    const selectedPassengerIds = getSelectedPassengerIdsParent({ context });
    const selectedLapInfantIds = getSelectedLapInfantIdsParent({ context });
    const ancillaryIds: ProtectionId[] = []; // ancillaryIdsSelector(state);

    if (!selectedTrip || !passengers) {
      type NoSelectedTripOrNoPassengersError = any;
      const errorMessage = "NoSelectedTripOrNoPassengersError";
      const error: NoSelectedTripOrNoPassengersError = {
        message: errorMessage,
      };
      reject(error);
    }

    validateUserPassengers({
      tripId: selectedTrip.tripId,
      fareId: selectedTrip.returnFareId
        ? selectedTrip.returnFareId
        : selectedTrip.outgoingFareId,
      seatedPassengers: passengers
        .filter((p) => selectedPassengerIds.includes(p.id))
        .map((u) => u.id),
      lapInfants: passengers
        .filter((p) => selectedLapInfantIds.includes(p.id))
        .map((u) => u.id),
      ancillaryIds,
    })
      .then((response: PassengersResponse) => {
        switch (response.PassengersResponse) {
          case PassengersResponseEnum.InvalidPassengers: {
            if ("errors" in response || "warnings" in response) {
              reject({
                type:
                  response.errors?.at(0)?.code ||
                  response.warnings?.at(0)?.code,
                data: response.errors?.at(0) || response.warnings?.at(0),
              });
            } else {
              reject({
                type: PassengerErrorModalTypes.ValidatePassengers,
                data: {
                  code: PassengerErrorModalTypes.ValidatePassengers,
                  message: "Unknown InvalidPassenger error",
                },
              });
            }
            break;
          }
          case PassengersResponseEnum.PassengersOk:
            resolve(response as PassengersOk);
            break;
        }
      })
      .catch((e) => {
        reject({
          type: PassengerErrorModalTypes.ValidatePassengers,
          data: e,
        });
      });
  });

import {
  CartQuoteServices,
  CartQuoteActions,
  cartQuoteSelectors,
  CartQuoteGuards,
} from "./CartQuote";
import {
  CartUpdateServices,
  CartUpdateActions,
  cartUpdateSelectors,
  CartUpdateGuards,
} from "./CartUpdate";
import {
  CartFulfillServices,
  CartFulfillActions,
  cartFulfillSelectors,
  CartFulfillGuards,
} from "./CartFulfill";

export * as cartHelpers from "./helpers";
export * from "./CartQuote";
export * from "./CartUpdate";
export * from "./CartFulfill";
export * from "./context";
export * from "./types";

export const CartActions = {
  ...CartQuoteActions,
  ...CartUpdateActions,
  ...CartFulfillActions,
};
export const CartServices = {
  ...CartQuoteServices,
  ...CartUpdateServices,
  ...CartFulfillServices,
};

export const CartSelectors = {
  ...cartQuoteSelectors,
  ...cartUpdateSelectors,
  ...cartFulfillSelectors,
};

export const CartGuards = {
  ...CartQuoteGuards,
  ...CartUpdateGuards,
  ...CartFulfillGuards,
};

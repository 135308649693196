import { Box, Typography, TypographyProps } from "@material-ui/core";
import clsx from "clsx";
import { isString } from "lodash-es";
import { ReactNode } from "react";

import "./styles.scss";

export interface IGenericModalContentProps {
  actions?: ReactNode | ReactNode[];
  actionsClassName?: string;
  className?: string;
  columnAlign?: "center" | "flex-start";
  image?: ReactNode;
  content?: ReactNode;
  subtitle?: ReactNode;
  subtitleProps?: TypographyProps;
  testId?: string;
  title?: ReactNode;
  titleProps?: TypographyProps;
  header?: string | ReactNode;
  headerProps?: TypographyProps;
}

/**
 * @description Renders an image, title, subtitle, and actions in a column.
 * Can be used in a modal or any container element.
 * @return {ReactNode}
 */
const GenericModalContent = (props: IGenericModalContentProps): JSX.Element => {
  const {
    actions,
    actionsClassName,
    className,
    columnAlign = "center",
    content,
    image,
    subtitleProps,
    testId = "",
    title,
    titleProps,
    header,
    headerProps,
  } = props;
  const updatedSubtitleProps: TypographyProps = subtitleProps ?? {};
  let { subtitle } = props;

  // if subtitle is string[], format to show as sentences
  if (
    subtitle &&
    Array.isArray(subtitle) &&
    subtitle.every((s) => isString(s)) &&
    !subtitleProps?.dangerouslySetInnerHTML
  ) {
    updatedSubtitleProps.dangerouslySetInnerHTML = {
      __html: subtitle.join(" "),
    };
    subtitle = null;
  }

  return (
    <Box
      alignItems={columnAlign}
      className={clsx("generic-modal-content-container", className)}
      data-testid={testId}
      flexDirection="column"
      textAlign={columnAlign === "center" ? "center" : "left"}
    >
      {image && <Box className="image-container">{image}</Box>}
      {header && (
        <span className={`modal-header-text ${headerProps?.className ?? ""}`}>
          {header}
        </span>
      )}
      {title && (
        <Typography
          variant="h2"
          {...titleProps}
          className={`modal-title ${titleProps?.className ?? ""}`}
        >
          {title}
        </Typography>
      )}
      {(subtitle || updatedSubtitleProps.dangerouslySetInnerHTML) && (
        <Typography
          {...updatedSubtitleProps}
          className={`subtitle ${updatedSubtitleProps?.className ?? ""}`}
        >
          {subtitle}
        </Typography>
      )}
      {content}
      {actions && (
        <Box
          alignItems={columnAlign}
          className={clsx("actions-container", actionsClassName)}
          flexDirection="column"
        >
          {actions}
        </Box>
      )}
    </Box>
  );
};

export default GenericModalContent;

import { ParentState } from "@checkout/types";
import {
  isEmailValid,
  isPhoneNumberValid,
  parsePhoneNumber,
} from "@hopper-b2b/utilities";
import { State } from "xstate";
import { PartialParentWithContact } from "./context";

type ContactStateType = State<PartialParentWithContact>;
type ContactStateWithoutValue = Pick<ContactStateType, "context">;

export const getContactInformationState = ({
  context,
}: ContactStateType | ContactStateWithoutValue) => {
  return context[ParentState.contactInformation];
};

export const getContactVisited = (state: ContactStateWithoutValue) =>
  getContactInformationState(state)?.visited;

export const getContactInformation = (
  state: ContactStateType | ContactStateWithoutValue
) => getContactInformationState(state).contactInfo;

export const getContactInformationCountryCode = (
  state: ContactStateWithoutValue
) => getContactInformation(state)?.countryCode;

export const getContactInformationPhoneNumber = (
  state: ContactStateWithoutValue
) => getContactInformation(state)?.phoneNumber;

export const getContactInformationEmail = (state: ContactStateWithoutValue) =>
  getContactInformation(state)?.email;

export const getFormattedPhoneNumber = (
  state: ContactStateType | ContactStateWithoutValue
) => {
  const ctxCountryCode = getContactInformationCountryCode(state);
  const ctxPhoneNumber = getContactInformationPhoneNumber(state);

  if (!ctxCountryCode || !ctxPhoneNumber) return "";

  // Remove any local prefixes/suffixes such as leading zeros
  const { countryCode, nationalNumber } = parsePhoneNumber(
    `${ctxCountryCode}${ctxPhoneNumber}`
  );
  return `+${countryCode}${nationalNumber}`;
};

export const getContactInformationValidated = (
  state: ContactStateType | ContactStateWithoutValue
): boolean => {
  const contactInfo = getContactInformation(state);
  return (
    contactInfo &&
    !!contactInfo.email &&
    !!contactInfo.phoneNumber &&
    isEmailValid(contactInfo.email) &&
    isPhoneNumberValid(contactInfo.phoneNumber)
  );
};

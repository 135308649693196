import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { isEmailValid, isPhoneNumberValid } from "@hopper-b2b/utilities";
import { PartialParentWithContact } from "./context";
import { SetContactInfoEvent } from "./events";

export const guards = {
  notVisitedContact: (ctx: PartialParentWithContact) =>
    !ctx[ParentState.contactInformation].visited,
  contactContinue: (ctx: PartialParentWithContact) => {
    const contactInfo = ctx[ParentState.contactInformation].contactInfo;
    return (
      contactInfo?.email &&
      contactInfo.phoneNumber &&
      isEmailValid(contactInfo.email) &&
      isPhoneNumberValid(contactInfo.phoneNumber)
    );
  },
  isContactValid: (
    _ctx: PartialParentWithContact,
    event: SetContactInfoEvent
  ) => {
    const contactInfo = event.contactInfo;
    return (
      contactInfo?.email &&
      contactInfo.phoneNumber &&
      isEmailValid(contactInfo.email) &&
      isPhoneNumberValid(contactInfo.phoneNumber)
    );
  },
};

export const GuardTypes = getObjectKeysAsObject(guards);

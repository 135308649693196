import { Product, TrackingProperties } from "@b2bportal/purchase-api";
import { DisruptionOffer, DisruptionProductType } from "@hopper-b2b/types";
import { ParentState } from "../../../types";

// TODO: Update from b2bportal lib when ready
import {
  DisruptionQuoteRequest,
  DisruptionUpdateStateType,
  DisruptionUpdateStateWithoutValue,
} from "./types";
import { cartQuoteSelectors } from "@checkout/states/Cart";
import { FlightSelectors } from "../Flight";

export const getDisruptionCartUpdateParams = (
  state: DisruptionUpdateStateWithoutValue
): DisruptionQuoteRequest => {
  const selectedTrip = FlightSelectors.getSelectedTripParent(state);
  const tripId = selectedTrip.tripId ?? "";
  const fareId = selectedTrip.returnFareId || selectedTrip.outgoingFareId || "";
  const quoteIds = getDisruptionQuoteIds(state);
  return {
    quoteIds,
    tripId,
    fareId,
  };
};

export const getDisruptionQuoteIds = (
  state: DisruptionUpdateStateType | DisruptionUpdateStateWithoutValue
): Array<string> =>
  state.context[ParentState.disruption].selectedOffers?.reduce(
    (quoteIds, { quotes }) => [...quoteIds, ...quotes.map((q) => q.quoteId)],
    [] as Array<string>
  ) || [];

export const getDisruptionOfferProductTypes = (
  state: DisruptionUpdateStateType | DisruptionUpdateStateWithoutValue
): Array<DisruptionProductType> =>
  state.context[ParentState.disruption].selectedOffers?.map(
    ({ quotes }) => quotes[0].productType
  ) || [];

export const getDisruptionFulfillTrackingProperties = (
  state: DisruptionUpdateStateType | DisruptionUpdateStateWithoutValue
): TrackingProperties => {
  const offersShown =
    state.context[ParentState.disruption].offersResponse || [];
  const mappedOfferedProducts = offersShown?.map(
    (p) => p?.quotes?.[0]?.productType
  );

  const quotedProducts =
    cartQuoteSelectors.getQuoteBreakdown(state)?.products || [];
  const quotedDisruptionProduct = quotedProducts.find(
    (p) => p?.product?.type === Product.AirDisruption
  )?.product?.value as DisruptionOffer | undefined;

  const getProtectionChoice = (
    type: DisruptionProductType
  ): 0 | 1 | undefined => {
    if (mappedOfferedProducts.includes(type)) {
      if (
        quotedDisruptionProduct?.quotes.find(
          (quote) => quote.productType === type
        )
      ) {
        // fulfilled
        return 1;
      }
      // offered
      return 0;
    }
    return undefined;
  };

  /**
   * choice options
   * 0 = offered, not attached
   * 1 = attached/fulfilled
   * none = not offered to user
   */
  return {
    properties: {
      delay_protection_choice: getProtectionChoice(
        DisruptionProductType.ScheduleChange
      ),
      missed_connection_choice: getProtectionChoice(
        DisruptionProductType.MissedConnection
      ),
    },
  };
};

export const getPurchasedDisruptionProducts = (
  state: DisruptionUpdateStateType | DisruptionUpdateStateWithoutValue
) => {
  const products = state.context[ParentState.cartFulfill].fulfilledProducts;
  const purchasedDisruptionProducts = products?.find(
    (p) => p.type === Product.AirDisruption
  );
  const contractIds = purchasedDisruptionProducts?.value?.contractIds;
  const cartQuoteDisruptionProducts =
    state.context[ParentState.cartQuote]?.quoteBreakdown?.products;
  const quotedDisruptionProductTypes = cartQuoteDisruptionProducts?.find(
    (product) => product?.product?.type === Product.AirDisruption
  )?.product?.value?.quotes;
  const purchasedDisruptionProductTypes = quotedDisruptionProductTypes?.filter(
    (product) => contractIds.includes(product.quoteId)
  );

  return purchasedDisruptionProductTypes || null;
};

export const getMissedConnectionOffer = (
  state: DisruptionUpdateStateType | DisruptionUpdateStateWithoutValue
): DisruptionOffer | undefined =>
  state.context[ParentState.disruption].selectedOffers?.find(
    ({ quotes }) =>
      quotes[0].productType === DisruptionProductType.MissedConnection
  );

export const getScheduleChangeOffer = (
  state: DisruptionUpdateStateType | DisruptionUpdateStateWithoutValue
): DisruptionOffer | undefined =>
  state.context[ParentState.disruption].selectedOffers?.find(
    ({ quotes }) =>
      quotes[0].productType === DisruptionProductType.ScheduleChange
  );

export const getAirDisruptionOffer = (
  state: DisruptionUpdateStateType | DisruptionUpdateStateWithoutValue
): DisruptionOffer | undefined =>
  state.context[ParentState.disruption].selectedOffers?.find(
    ({ quotes }) =>
      quotes[0].productType === DisruptionProductType.AirDisruptionProtection
  );

export const getDisruptionOffersResponse = (
  state: DisruptionUpdateStateType | DisruptionUpdateStateWithoutValue
) => state.context[ParentState.disruption].offersResponse;

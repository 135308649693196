import { UAParser } from "ua-parser-js";

const parser = new UAParser();

export enum UserAgentDeviceTypeEnum {
  Desktop = "desktop",
  Tablet = "tablet",
  Mobile = "mobile",
}

export function getDeviceData() {
  return {
    platform: {
      type: "web",
    },
    type: parser.getDevice()?.type || UserAgentDeviceTypeEnum.Desktop,
  };
}
